import { Component, OnInit, Input } from '@angular/core';
import { Capture } from 'app/core/models/capture.model';
import { AutoVerifyPrescriber } from 'app/core/models/auto-verify-prescriber.model';

@Component({
  selector: 'app-capture-client-prescriber',
  templateUrl: './capture-client-prescriber.component.html',
  styleUrls: ['./capture-client-prescriber.component.scss'],
})
export class CaptureClientPrescriberComponent implements OnInit {
  @Input() capture: Capture;
  autoVerifyPrescriber: AutoVerifyPrescriber;

  constructor() {}

  ngOnInit() {
    this.autoVerifyPrescriber = this.capture.autoVerifyPrescriber;
  }
}
