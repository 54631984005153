import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Capture } from '../../../core/models/capture.model';
import { PatientPrescriber } from '../../../core/models/patient-prescriber.model';
import { Prescription } from '../../../core/models/prescription.model';
import { VerificationOutcome } from '../../../core/models/verification-outcome.model';
import { CaptureTransition } from '../../../core/enums/capture-transition.enum';
import { CaptureStatusReason } from '../../../core/enums/capture-status-reason.enum';
import { NextResult } from '../../../core/models/paged-results/cursor-based-paging.model';
import { CaptureService } from '../../../core/services/capture.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { CaptureValidationService } from '../../../core/services/capture-validation.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { CaptureQueueService } from '../../../core/services/capture-queue.service';
import { anyVerificationRequestsPending } from '../../../core/lib/verification-utils';
import { PendingHttpRequestsService } from '../../../core/services/pending-http-requests.service';
import { PatientPrescriberService } from '../../../core/services/patient-prescriber.service';
import { CaptureQueueFilters } from '../../../core/models/user-settings/capture-queue-settings.model';

@Component({
  selector: 'app-needs-referral-match-approval',
  templateUrl: './needs-referral-match-approval.component.html',
  styleUrls: ['./needs-referral-match-approval.component.scss'],
})
export class NeedsReferralMatchApprovalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;
  @Input() patientPrescriptions: Prescription[];

  canTransition = false;
  pendingRequestsChangedSubscription: Subscription = null;
  nextCaptureResult: NextResult;

  constructor(
    private captureService: CaptureService,
    private patientPrescriberService: PatientPrescriberService,
    private captureQueueService: CaptureQueueService,
    private authService: AuthenticationService,
    private captureValidationService: CaptureValidationService,
    private navigationService: NavigationService,
    private pendingHttpRequestsService: PendingHttpRequestsService
  ) {}

  ngOnInit() {
    this.prefetchNextCapture();
  }

  ngAfterViewInit() {
    this.pendingRequestsChangedSubscription = this.pendingHttpRequestsService.requestsChanged.subscribe(() => {
      this.canTransition = !anyVerificationRequestsPending(this.pendingHttpRequestsService);
    });
  }

  ngOnDestroy() {
    if (this.pendingRequestsChangedSubscription) {
      this.pendingRequestsChangedSubscription.unsubscribe();
    }

    this.captureValidationService.clearErrors();
  }

  onSaveClick() {
    if (this.captureValidationService.validateNeedsReferralMatchApproval(this.capture, this.patientPrescriber)) {
      this.advancePatientPrescriber();
    }
  }

  onSendToCeReviewClick() {
    const outcome = new VerificationOutcome();
    outcome.statusReason = CaptureStatusReason.additionalReferralDocumentationRequired;
    this.performTransition(CaptureTransition.ceReview, outcome);
  }

  onRejectClick() {
    const outcome = new VerificationOutcome();
    outcome.statusReason = CaptureStatusReason.prescriberSpecialtyDoesNotReceiveReferrals;
    this.performTransition(CaptureTransition.reject, outcome);
  }

  private performTransition(event: string, outcome: VerificationOutcome = null) {
    this.captureValidationService.clearErrors();

    this.captureService.transition(this.capture, event, outcome).subscribe(capture => {
      this.navigateToNextCapture(capture);
    });
  }

  private advancePatientPrescriber() {
    this.captureValidationService.clearErrors();

    this.patientPrescriberService.advanceCapturesRequiringReferral(this.capture.patientPrescriberId).subscribe(() => {
      this.navigateToNextCapture(this.capture);
    });
  }

  private prefetchNextCapture() {
    const additionalFilters = new CaptureQueueFilters();
    additionalFilters.excludedPatientPrescriberIds = [this.capture.patientPrescriberId];
    this.captureQueueService.getNextResult(this.capture, additionalFilters).subscribe((nextResult: NextResult) => {
      this.nextCaptureResult = nextResult;
    });
  }

  private navigateToNextCapture(capture: Capture) {
    if (this.nextCaptureResult) {
      this.navigationService.navigateTo(`/capture-admin/captures/${this.nextCaptureResult.id}`);
    } else {
      this.navigationService.navigateTo(`/capture-admin/captures`);
    }
  }
}
