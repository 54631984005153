import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { ReportOption } from 'app/core/models/report-option.model';

export class ReportDateRangeOption implements ReportOption {
  from: string;
  to: string;

  constructor(values) {
    if (values) {
      this.from = values.from;
      this.to = values.to;
    }
  }

  get values() {
    return {
      from: this.from,
      to: this.to,
    };
  }

  toPostData(options = { paramName: 'createdAt' }) {
    const postData = {};

    if (this.from) {
      postData[`${options.paramName}From`] = this.from;
    }

    if (this.to) {
      postData[`${options.paramName}To`] = this.to;
    }

    return postData;
  }
}

@Component({
  selector: 'app-report-date-range-option',
  templateUrl: './report-date-range-option.component.html',
  styleUrls: ['./report-date-range-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportDateRangeOptionComponent),
    },
  ],
})
export class ReportDateRangeOptionComponent extends ReportAbstractOptionComponent {
  static readonly blankValues = {
    from: null,
    to: null,
  };

  @Input() label = 'Dates';

  values = { ...ReportDateRangeOptionComponent.blankValues };

  protected updateInternalState(model: ReportDateRangeOption) {
    if (model) {
      this.values = model.values;
    } else {
      this.values = { ...ReportDateRangeOptionComponent.blankValues };
    }
  }

  protected determineModelStateOnChange(): ReportDateRangeOption {
    if (this.values.from || this.values.to) {
      return new ReportDateRangeOption(this.values);
    } else {
      return null;
    }
  }
}
