<app-capture-history-item-title>
  {{ title }}
</app-capture-history-item-title>

<app-capture-history-item-body>
  <p>
    {{ description }}
  </p>

  <p *ngIf="claimType">
    Claim Type: {{ claimType }}
  </p>

  <p *ngIf="matchConfidence">
    Confidence Ratio: {{ matchConfidence }}
  </p>
</app-capture-history-item-body>
