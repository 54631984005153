import { Component } from '@angular/core';
import { CaptureHistoryItemTypeUpdatedComponent } from '../capture-history-item-type-updated/capture-history-item-type-updated.component';

@Component({
  selector: 'app-capture-history-item-type-transitioned',
  templateUrl: './capture-history-item-type-transitioned.component.html',
})
export class CaptureHistoryItemTypeTransitionedComponent extends CaptureHistoryItemTypeUpdatedComponent {
  get description() {
    const parts = [`Capture transitioned to ${this.displayStatus}`];

    const statusReasons = this.mapStatusReasons();
    if (statusReasons.length > 0) {
      parts.push(statusReasons.join(', '));
    }

    return parts.join(': ');
  }

  get verifiedDuringNoReferralPeriod() {
    return this.captureHistoryItem.data.verifiedDuringNoReferralPeriod;
  }
}
