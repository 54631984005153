import { Component, Input } from '@angular/core';
import { AppliedReportOptions } from 'app/core/models/report.model';

@Component({
  selector: 'app-report-applied-client-entity-type-option',
  templateUrl: './report-applied-client-entity-type-option.component.html',
})
export class ReportAppliedClientEntityTypeOptionComponent {
  @Input() name = 'clientEntityTypes';
  @Input() label = 'Entity Type';
  @Input() options: AppliedReportOptions;

  get values() {
    const values = this.options[this.name];
    if (values) {
      return values.join(', ');
    } else {
      return null;
    }
  }
}