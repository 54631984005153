import { Component, Input } from '@angular/core';
import { AppliedReportOptions, AppliedReportOptionAssociations } from 'app/core/models/report.model';

@Component({
  selector: 'app-report-applied-client-lead-option',
  templateUrl: './report-applied-client-lead-option.component.html',
})
export class ReportAppliedClientLeadOptionComponent {
  @Input() name = 'assignedClientLeadsIds';
  @Input() label = 'Client Leads';
  @Input() options: AppliedReportOptions;
  @Input() optionAssociations: AppliedReportOptionAssociations;

  constructor() {}

  get userNames() {
    const userIds = [this.options[this.name]].filter(Boolean).flat();

    if (userIds && userIds.length > 0) {
      return userIds.map(userId => {
        const optionAssociations = this.optionAssociations[this.name] || {};
        const user = optionAssociations[userId];
        return user && user.username;
      });
    } else {
      return ['ALL']
    }
  }

  get names() {
    return this.userNames.join(', ');
  }
}
