<form
  *ngIf="!loading; else: spinner"
  [formGroup]="formGroup"
  (submit)="onSubmit()">

  <div class="mb-2">
    <app-verification-validation [key]="validationKey" />
  </div>

  <p>
    Select the offices(s) for {{ prescriber.fullName }} with relevant outreach attempts:
  </p>

  <table class="table table-sm">
    <thead>
      <tr>
        <th>Office Details</th>
        <th>Fax Status</th>
        <th>Provider Fax Status</th>
      </tr>
    </thead>
    <tbody formArrayName="offices" *ngIf="providerOffices.length > 0">
      <tr *ngFor="let providerOffice of providerOffices; let i = index"
          [class.selected]="officeSelectedInArray(i)">
        <td>
          <div class="form-check">
            <label class="form-check-label">
              <input
                type="checkbox"
                [formControlName]="i"
                class="form-check-input" />

              <app-office [office]="providerOffice.office" />
            </label>
          </div>
        </td>
        <td>
          {{ keyedOfficeStatusOptions[providerOffice.office.status]?.display }}
        </td>
        <td [class.status-not-verified]="providerOffice.status !== verifiedProviderOfficeStatus">
          {{ keyedProviderOfficeStatusOptions[providerOffice.status]?.display }}
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="providerOffices.length === 0">
      <tr>
        <td colspan="99">
          <div class="no-applicable-offices">
            Provider does not have any applicable offices with outbound faxes.
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="mt-2">
    <div>
      Effective Date:
    </div>
    <div>
      <input
        type="date"
        class="form-control last-outreach-date"
        formControlName="lastOutreachDate" />
    </div>
  </div>

  <div class="mt-2">
    <div>
      Justification:
    </div>
    <div>
      <textarea class="form-control" formControlName="notes"></textarea>
    </div>
  </div>

  <div class="alert alert-warning p-1 mt-2 mb-0">
    Note: Saving outreach attempt evidence will automatically verify this capture and its peers, if applicable.
  </div>

  <div class="actions mt-2">
    <button type="submit" class="btn btn-primary" [disabled]="!formValid">
      Save
    </button>

    <button type="button" class="btn btn-secondary" (click)="onCancelClick()">
      Cancel
    </button>
  </div>
</form>

<ng-template #spinner> <mat-spinner /> </ng-template>
