/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { Prescription } from '../../../core/models/prescription.model';

@Component({
  selector: '[app-prescription-list-row-detail]',
  templateUrl: './prescription-list-row-detail.component.html',
  styleUrls: ['./prescription-list-row-detail.component.scss'],
})
export class PrescriptionListRowDetailComponent implements OnInit {
  @Input() activeCaptureId: number;
  @Input() isOpen: boolean;
  @Input() prescription: Prescription;

  constructor() {}

  ngOnInit() {}
}
