<div>
  <label for="notes">ADDITIONAL DETAIL</label>
  <fa-icon
    class="toggle-button"
    *ngIf="!showNotes"
    [icon]="['fas', 'plus-square']"
    size="sm"
    (click)="onPlusClick()" />
  <fa-icon
    class="toggle-button"
    *ngIf="showNotes"
    [icon]="['fas', 'minus-square']"
    size="sm"
    (click)="onMinusClick()" />
  <textarea
    tabindex="-1"
    id="notes"
    name="notes"
    class="form-control"
    [hidden]="!showNotes"
    [disabled]="notesFrozen"
    (change)="onNotesChange()"
    [(ngModel)]="notes"
    rows="5">
  </textarea>
</div>
