import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-portal',
  templateUrl: './partner-portal.component.html',
  styleUrls: ['./partner-portal.component.scss'],
})
export class PartnerPortalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
