import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { LlmPlaygroundComponent } from './llm-playground.component';
import { LlmPlaygroundBatchComponent } from './pages/llm-playground-batch/llm-playground-batch.component';
import { LlmPlaygroundProjectsComponent } from './pages/llm-playground-projects/llm-playground-projects.component';
import { LlmPlaygroundProjectComponent } from './pages/llm-playground-project/llm-playground-project.component';
import { LlmPlaygroundAddBatchModalComponent } from './components/llm-playground-add-batch-modal/llm-playground-add-batch-modal.component';
import { LlmPlaygroundBatchesTableComponent } from './components/llm-playground-batches-table/llm-playground-batches-table.component';
import { LlmPlaygroundShareModalComponent } from './components/llm-playground-share-modal/llm-playground-share-modal.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    LlmPlaygroundComponent,
    LlmPlaygroundBatchComponent,
    LlmPlaygroundProjectsComponent,
    LlmPlaygroundProjectComponent,
    LlmPlaygroundAddBatchModalComponent,
    LlmPlaygroundBatchesTableComponent,
    LlmPlaygroundShareModalComponent,
  ],
  providers: [],
})
export class LlmPlaygroundModule {}
