import { Component, Input, OnInit } from '@angular/core';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ProviderOutboundFaxQueueSettings } from 'app/core/models/user-settings/provider-outbound-fax-search-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { Provider } from 'app/core/models/provider.model';
import { OutboundFax } from 'app/core/models/outbound-fax.model';
import { OutboundFaxesService, PagedOutboundFaxes } from 'app/core/services/outbound-faxes.service';
import { OutboundFaxStatus } from 'app/core/enums/outbound-fax-status.enum';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';

const trackFaxItemsBy = (index: number, item: OutboundFax) => `${item.id}-${item.status}`;

@Component({
  selector: 'app-provider-outbound-faxes',
  templateUrl: './provider-outbound-faxes.component.html',
  styleUrls: ['./provider-outbound-faxes.component.scss'],
})

export class ProviderOutboundFaxesComponent implements OnInit {
  @Input() provider: Provider;

  outboundFaxes: OutboundFax[] = [];
  outboundFaxStatuses = OutboundFaxStatus;
  refreshing = false;
  paging: LimitOffsetPaging;
  eligible = true;
  currentSection: string;
  trackItemsBy = trackFaxItemsBy;
  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;

  constructor(
    private outboundFaxesService: OutboundFaxesService,
    private userSettingsService: UserSettingsService,
  ) {}

  get viewing() {
    return Math.min(this.paging.pageSize, this.paging.count);
  }

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.saveQueueSettings();
    this.loadOutboundFaxes(this.paging.currentPage);
  }

  onPageChange(page) {
    this.saveQueueSettings();
    this.loadOutboundFaxes(page);
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.loadQueueSettings();
    this.paging.currentPage = 1;
    this.loadOutboundFaxes(this.paging.currentPage);
  }

  private loadQueueSettings() {
    const queueSettings: ProviderOutboundFaxQueueSettings =
      this.userSettingsService.get<ProviderOutboundFaxQueueSettings>(
        UserSettings.captureAdminProviderOutboundFaxQueueSettings
      );

    if (queueSettings) {
      this.paging = queueSettings.paging;
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new ProviderOutboundFaxQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    this.userSettingsService.save<ProviderOutboundFaxQueueSettings>(
      UserSettings.captureAdminProviderOutboundFaxQueueSettings, queueSettings
    );
  }

  private loadOutboundFaxes(page, refreshing = true) {
    const paging = { page, pageSize: this.paging.pageSize };

    this.refreshing = refreshing;

    this.outboundFaxesService.providerOutboundFaxes(
      this.provider.npi, paging
    ).subscribe((result: PagedOutboundFaxes) => {
      this.outboundFaxes = result.outboundFaxes || [];
      this.paging = result.meta.paging;
      this.refreshing = false;
    });
  }

  cancel($event, outboundFax) {
    $event.preventDefault();

    this.outboundFaxesService.cancel(outboundFax.id).subscribe((result: OutboundFax) => {
      this.loadOutboundFaxes(this.paging.currentPage, false);
    });
  }

  onSectionClick(section: string) {
    this.currentSection = section;
  }

  navClick($event: Event, eligible: boolean) {
    $event.preventDefault();
    this.eligible = eligible;
    this.loadOutboundFaxes(1);
  }
}
