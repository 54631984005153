import { Component, Input, OnInit } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { CaptureService } from '../../../core/services/capture.service';
import { PatientMatchStatus } from '../../../core/enums/patient-match-status.enum';
import { NavigationService } from '../../../core/services/navigation.service';
import { CaptureQueueService } from '../../../core/services/capture-queue.service';
import { CaptureQueueFilters } from '../../../core/models/user-settings/capture-queue-settings.model';
import { NextResult } from '../../../core/models/paged-results/cursor-based-paging.model';

@Component({
  selector: 'app-needs-patient-match-approval',
  templateUrl: './needs-patient-match-approval.component.html',
  styleUrls: ['./needs-patient-match-approval.component.scss'],
})
export class NeedsPatientMatchApprovalComponent implements OnInit {
  @Input() capture: Capture;

  approvalType = null;
  matchName = '';
  matchName2 = '';
  hasMultipleApprovals = false;
  isFirstType = false;

  nextCaptureResult: NextResult;

  constructor(
    private captureService: CaptureService,
    private captureQueueService: CaptureQueueService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.init();
    this.prefetchNextCapture();
  }

  onRejectClick() {
    this.processPatientMatch(PatientMatchStatus.rejected);
  }

  onApproveClick() {
    this.processPatientMatch(PatientMatchStatus.approved);
  }

  private init() {
    const patientMatchData = this.capture.patientMatchApproval;
    const nameMatchApprovals = patientMatchData.needsApproval || [];

    if (nameMatchApprovals.length > 0) {
      this.approvalType = nameMatchApprovals[0];
      this.hasMultipleApprovals = nameMatchApprovals.length > 1;
      this.isFirstType = this.approvalType === 'first';

      const patientProperty = this.approvalType + 'Name';
      const candidateProperty =
        'patient' + this.approvalType[0].toUpperCase() + this.approvalType.substr(1).toLowerCase();
      const nameMatchData = patientMatchData[this.approvalType + 'MatchData'];
      if (nameMatchData.matchNamePatient) {
        // New captures will include matchName and matchModel information
        this.matchName = nameMatchData.matchNamePatient;
        this.matchName2 = nameMatchData.matchNameClaim;
      } else {
        this.matchName = this.capture.patient[patientProperty];
        this.matchName2 = this.capture.candidate[candidateProperty];
      }
    }
  }

  private processPatientMatch(status: PatientMatchStatus) {
    this.captureService.processPatientMatch(this.capture, status, this.approvalType).subscribe(() => {
      this.goToNextCapture(status);
    });
  }

  private prefetchNextCapture() {
    const additionalFilters = new CaptureQueueFilters();
    additionalFilters.excludedPatientIds = [this.capture.patient.id];
    this.captureQueueService.getNextResult(this.capture, additionalFilters).subscribe((nextResult: NextResult) => {
      this.nextCaptureResult = nextResult;
    });
  }

  private goToNextCapture(status) {
    // If this capture needs multiple approvals, show the next approval for it
    if (this.hasMultipleApprovals && status === PatientMatchStatus.approved) {
      this.navigationService.reloadComponent();
    } else if (this.nextCaptureResult) {
      this.navigationService.navigateTo(`/capture-admin/captures/${this.nextCaptureResult.id}`);
    } else {
      this.navigationService.navigateTo(`/capture-admin/captures`);
    }
  }
}
