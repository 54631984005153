import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './capture-admin-capture-billing-state-reports.component.html',
})
export class CaptureAdminCaptureBillingStateReportsComponent {
  reportsUrl = `${environment.captureApi.url}/capture_billing_state_reports`;
  reportsCountUrl = `${environment.captureApi.url}/capture_billing_state_reports/count`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    clientIds: [null],
    mostRecentStateChange: [null],
    originalBillingDate: [null],
    requiresInvoiceOrCredit: [null],
  });
}
