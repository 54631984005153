import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Prescription } from '../../../core/models/prescription.model';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.scss'],
})
export class PrescriptionListComponent implements OnInit, OnChanges {
  @Input() prescriptions: Prescription[];
  @Input() activeCaptureId: number;
  @Input() activePrescriptionId: number;
  @Input() fixedHeader = true;
  @Input() dark = false;
  @Input() small = false;
  @Input() forProvider = false;

  allCollapsed = null;
  collapsedPrescriptions: { [key: number]: boolean } = {};

  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  isPrescriptionCollapsed(prescription: Prescription): boolean {
    return this.collapsedPrescriptions[prescription.id];
  }

  get showEstimatedValue(): boolean {
    return this.authService.isCaptureAdminUser;
  }

  onToggleClick(prescription: Prescription) {
    this.collapsedPrescriptions[prescription.id] = !this.collapsedPrescriptions[prescription.id];
    this.refreshAllCollapsed();
  }

  private init() {
    this.togglePrescriptions(true);
  }

  private togglePrescriptions(collapsed: boolean) {
    const collapsedPrescriptions = {};

    this.prescriptions.forEach(p => {
      collapsedPrescriptions[p.id] = collapsed && this.activePrescriptionId !== p.id;
    });

    this.collapsedPrescriptions = collapsedPrescriptions;
    this.refreshAllCollapsed();
  }

  private refreshAllCollapsed() {
    this.allCollapsed = Object.keys(this.collapsedPrescriptions).every(k => this.collapsedPrescriptions[k] === true);
  }

  prescriptionTrackedBy(index: number, prescription: Prescription) {
    return prescription.id;
  }
}
