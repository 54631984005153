import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { PrescriberIncorrectInformationRequest } from '../models/prescriber-incorrect-information-request.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';
import { environment } from '../../../environments/environment';

export class PagedPrescriberIncorrectInformationRequests implements PagedResults {
  prescriberIncorrectInformationRequests: PrescriberIncorrectInformationRequest[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.prescriberIncorrectInformationRequests;
  }
}

@Injectable()
export class PrescriberIncorrectInformationRequestsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    page: number,
    pageSize: number,
    clientIds: Array<string> = []
  ): Observable<PagedPrescriberIncorrectInformationRequests> {
    const searchParams = new URLSearchParams(`page=${page}&per_page=${pageSize}`);

    clientIds.forEach(clientId => searchParams.append('client_ids[]', clientId));

    return this.http.get<PagedPrescriberIncorrectInformationRequests>(
      `${environment.captureApi.url}/prescriber_incorrect_information_requests?${searchParams.toString()}`
    );
  }

  create(prescriberIncorrectInformationRequest: {
    clientId: number;
    relatedRecordType: string;
    relatedRecordId: number;
    prescriberId: number;
    comments: string;
  }): Observable<PrescriberIncorrectInformationRequest> {
    return this.http.post<PrescriberIncorrectInformationRequest>(
      `${environment.captureApi.url}/prescriber_incorrect_information_requests`,
      {
        prescriberIncorrectInformationRequest,
      }
    );
  }

  update(
    prescriberIncorrectInformationRequest: PrescriberIncorrectInformationRequest,
    params: { resolved: boolean }
  ): Observable<PrescriberIncorrectInformationRequest> {
    return this.http.patch<PrescriberIncorrectInformationRequest>(
      `${environment.captureApi.url}/prescriber_incorrect_information_requests/${
        prescriberIncorrectInformationRequest.id
      }`,
      params
    );
  }
}
