import { SimpleChanges } from '@angular/core';

const handleSimpleChanges = (changes: SimpleChanges, cb) => {
  for (const propName in changes) {
    if (changes.hasOwnProperty(propName)) {
      cb(propName, changes[propName]);
    }
  }
};

export { handleSimpleChanges };
