import { User } from './user.model';

export class EhrConsultNoteReference {
  id: number;
  name: string;
  notes: string;
  user: User;
  createdAt: Date;
  frozen: boolean;
}
