import { Injectable } from '@angular/core';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { UserSettings } from '../enums/user-settings.enum';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class UserSettingsService {
  constructor(private localStorageService: LocalStorageService, private authService: AuthenticationService) {}

  get<T>(key: UserSettings): T {
    return this.localStorageService.get(this.keyToUserScopedKey(key));
  }

  save<T>(key: UserSettings, obj: T) {
    this.localStorageService.set(this.keyToUserScopedKey(key), obj);
  }

  private keyToUserScopedKey(key: UserSettings): string {
    return `${this.authService.currentUsername}_${key}`;
  }
}
