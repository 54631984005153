<div class="col-12 px-0">
  <h3>User Details Change Log: {{ user?.email }}</h3>
</div>
<ng-container *ngIf="!loading else: spinner">
  <div class="col-12">
    <app-pagination-header [paging]="paging" [label]="'audits'" (pageSizeChange)="onPageSizeChange($event)" />
  </div>
   <div class="audit-list-wrapper col-12">
    <table class="audit-list table table-bordered table-hover">
      <thead class="table-dark">
        <tr>
          <th>Changed At</th>
          <th>Action</th>
          <th>New Values</th>
          <th>Previous Values</th>
          <th>User</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let parsedAudit of parsedAudits">
          <tr>
            <td>{{ parsedAudit.createdAt | date: 'M/d/yyyy h:mm a' }}</td>
            <td>{{ modelNameStrings[parsedAudit.auditableType] }} {{ parsedAudit.action }}</td>
            <td>
              <div class="overflow-auto" style="max-height: 600px;" [innerHTML]="parsedAudit.changesHtml[1]"></div>
            </td>
            <td>
              <div class="overflow-auto" style="max-height: 600px;" [innerHTML]="parsedAudit.changesHtml[0]"></div>
            </td>
            <td>{{ parsedAudit.user?.email }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </div>
</ng-container>
<ng-template #spinner> <mat-spinner /></ng-template>