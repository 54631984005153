import { fromEvent as observableFromEvent, merge, Subscription, Subject } from 'rxjs';

class ActivityEvent {
  constructor(public active: boolean, public triggeringEvent: Event) {}
}

export class WindowActivityService {
  private active = false;

  private activityEventsSubscription: Subscription;
  private blurEventSubscription: Subscription;
  private visibilityChangedSubscription: Subscription;

  private focusLostTimeout;

  public activity: Subject<ActivityEvent> = new Subject();

  readonly windowActivityEvents = ['focus', 'click', 'mousemove', 'scroll', 'keypress', 'resize'];

  constructor() {
    this.subscribeToEvents();
  }

  public destroy() {
    this.activityEventsSubscription.unsubscribe();
    this.blurEventSubscription.unsubscribe();
    this.visibilityChangedSubscription.unsubscribe();
  }

  private emitActivity(event) {
    const activityEvent = new ActivityEvent(this.active, event);

    this.activity.next(activityEvent);
  }

  private setActive(event) {
    this.active = true;
    this.emitActivity(event);
  }

  private setInactive(event) {
    this.active = false;
    this.emitActivity(event);
  }

  private subscribeToEvents() {
    const activityObservables = this.windowActivityEvents.map(event => observableFromEvent(window, event));

    this.activityEventsSubscription = merge(...activityObservables).subscribe(event => {
      this.setActive(event);
    });

    this.blurEventSubscription = observableFromEvent(window, 'blur').subscribe(event => {
      this.setInactive(event);
    });

    this.visibilityChangedSubscription = observableFromEvent(window, 'visibilitychange').subscribe(event => {
      if (document.hidden) {
        this.setInactive(event);
      } else {
        this.setActive(event);
      }
    });
  }
}
