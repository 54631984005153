import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IccCatalogedDrugImportsService } from 'app/core/services/icc/icc-cataloged-drug-imports.service';
import { ImportUploadResult } from '../../components/icc-import-upload/icc-import-upload.component';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-icc-import-cataloged-drugs',
  templateUrl: './icc-import-cataloged-drugs.component.html',
  styleUrls: ['./icc-import-cataloged-drugs.component.scss'],
})
export class IccImportCatalogedDrugsComponent {
  errors: string[] = [];

  constructor(private catalogedDrugImportsService: IccCatalogedDrugImportsService, private router: Router) {}

  onUploadComplete({ clientId, name, upload }: ImportUploadResult) {
    this.catalogedDrugImportsService.create(clientId, name, upload.signedId).subscribe(
      () => {
        this.router.navigate(['/capture-admin/icc-claims/imports/cataloged-drugs/history']);
      },
      (error: HttpErrorResponse) => {
        this.errors = error.error.errors;
      }
    );
  }

  onUploadStart() {
    this.errors = [];
  }

  public get hasError() {
    return !isEmpty(this.errors);
  }
}
