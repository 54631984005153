import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-portal-direct-consult-notes',
  templateUrl: './client-portal-direct-consult-notes.component.html',
  styleUrls: ['./client-portal-direct-consult-notes.component.scss'],
})
export class ClientPortalDirectConsultNotesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
