import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-validate-patient-match',
  templateUrl: './validate-patient-match.component.html',
  styleUrls: ['./validate-patient-match.component.scss'],
})
export class ValidatePatientMatchComponent implements OnInit {
  collapsed = false;

  @Input() patientAttachment: PatientAttachment;

  constructor() {}

  ngOnInit() {}

  onCollapseChange(collapsed) {
    this.collapsed = collapsed;
  }

  collapse() {
    this.collapsed = true;
  }

  expand() {
    this.collapsed = false;
  }
}
