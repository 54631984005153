import { Directive, ElementRef, OnInit, Input, Injector, Renderer2, Host, SkipSelf } from '@angular/core';
import { FormGroupDirective, ControlContainer, FormControl, FormGroup, FormArray } from '@angular/forms';

@Directive({
  selector: '[appFormControlValidityClass]',
})
export class FormControlValidityClassDirective implements OnInit {
  @Input() formControlName = null;

  formGroupDirective: FormGroupDirective;
  formControl: FormControl;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private injector: Injector,
    @Host() @SkipSelf() private parentContainer: ControlContainer,
  ) { }

  ngOnInit() {
    this.formGroupDirective = this.injector.get(FormGroupDirective, null);

    const parentControl = this.parentContainer.control as FormGroup | FormArray;
    this.formControl = parentControl.controls[this.formControlName];

    this.formGroupDirective.form.statusChanges.subscribe(() => this.update());
    this.formGroupDirective.ngSubmit.subscribe(() => this.update());
  }

  private update() {
    if (this.isControlInvalid()) {
      this.renderer.addClass(this.element.nativeElement, 'is-invalid');
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'is-invalid');
    }
  }

  private isControlInvalid(): boolean {
    if (this.formGroupDirective && this.formGroupDirective.submitted) {
      return this.formControl && this.formControl.invalid;
    } {
      return false;
    }
  }
}
