import { Component, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadService } from '../../../core/services/download.service';
import { IccImportHistoryComponent } from '../icc-import-history/icc-import-history.component';
import { IccCatalogedDrugImportsService } from '../../../core/services/icc/icc-cataloged-drug-imports.service';
import { ClientsService } from '../../../core/services/clients.service';

@Component({
  selector: 'app-icc-import-cataloged-drugs-history',
  templateUrl: '../icc-import-history/icc-import-history.component.html',
})
export class IccImportCatalogedDrugsHistoryComponent extends IccImportHistoryComponent {
  pageTitle = 'Drug Catalog Import History';

  constructor(
    protected downloadService: DownloadService,
    protected modalService: NgbModal,
    protected clientsService: ClientsService,
    private catalogedDrugImportsService: IccCatalogedDrugImportsService
  ) {
    super(downloadService, modalService, clientsService);
  }

  getImports(filters) {
    return this.catalogedDrugImportsService.getList(filters);
  }

  prepareImportResults(data) {
    return data.catalogedDrugImports;
  }

  getImport(importId) {
    return this.catalogedDrugImportsService.get(importId);
  }

  updateImport(importId, name) {
    return this.catalogedDrugImportsService.update(importId, name);
  }

  cancelImport(importId) {
    return this.catalogedDrugImportsService.cancel(importId);
  }
}
