/* eslint-disable @angular-eslint/component-selector */

import { Component, Input, OnInit } from '@angular/core';
import { Audit } from 'app/core/models/audit.model';
import { obj2Html } from '../../../core/lib/js-utils';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { ProviderOfficeService } from 'app/core/services/provider-office.service';

@Component({
  selector: '[app-audit-provider-office-update-row]',
  templateUrl: './audit-provider-office-update-row.component.html',
  styleUrls: ['./audit-provider-office-update-row.component.scss'],
})
export class AuditProviderOfficeUpdateRowComponent implements OnInit {
  @Input() audit: Audit;
  @Input() displayNpi = false;
  @Input() displayFax = false;

  providerOffice: ProviderOffice;
  loadingProviderOffice = true;
  newValueHtml;
  previousValueHtml;

  constructor(
    private providerOfficeService: ProviderOfficeService,
  ) {}

  ngOnInit() {
    const newValues = {};
    const previousValues = {};

    for(const [key, value] of Object.entries(this.audit.auditedChanges)) {
      newValues[key] = value[1] || '--';
      previousValues[key] = value[0] || '--';
    }

    this.newValueHtml = obj2Html(newValues);
    this.previousValueHtml = obj2Html(previousValues);
    this.loadOfficeProvider();
  }

  private loadOfficeProvider() {
    this.providerOfficeService.get(this.audit.auditableId).subscribe(providerOffice => {
      this.providerOffice = providerOffice;
      this.loadingProviderOffice = false;
    });
  }
}
