import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent implements OnInit, OnChanges {
  // the phone number to display
  @Input() number: number | string;

  // if true, then the phone number will create a
  // dialpad link.
  @Input() dialpad = false;

  // the value to display if the number is nil
  @Input() noValue: string = null;

  // set to true if you want to allow copying to clipboard
  @Input() allowCopyToClipboard = false;

  // if present the phone number will open a new tab
  // with the given link.
  //
  // NOTE: dialpad setting takes precedence
  @Input() linkTo;

  parsedNumber = null;

  constructor(protected sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.initParsedNumber();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initParsedNumber();
  }

  public get humanizedPhone() : string {
    return this.parsedNumber ? this.parsedNumber.formatNational() : this.number.toString();
  }

  // returns a dialpad phone url, not a common tel url
  public get phoneURI() {
    const uri = this.parsedNumber ? `dialpad://${this.parsedNumber.formatNational()}` : '';
    return this.sanitizer.bypassSecurityTrustUrl(uri);
  }

  private initParsedNumber() {
    if (this.number) {
      try {
        this.parsedNumber = parsePhoneNumber(this.number.toString());
      } catch (error) {
      }
    }
  }
}

