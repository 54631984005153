import { remove, uniq } from 'lodash-es';
import { Component, Input } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { SpecialistEncounterVerification } from '../../../core/models/specialist-encounter-verification.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-validate-specialist-encounter-form',
  templateUrl: './validate-specialist-encounter-form.component.html',
  styleUrls: ['./validate-specialist-encounter-form.component.scss'],
})
export class ValidateSpecialistEncounterFormComponent {
  @Input() patientAttachment: PatientAttachment;
  @Input() collapsed: boolean;

  matchingPrescriberDrugsChangedSubscription: Subscription;
  matchingPrescriberDrugsWrittenDates: Date[];

  constructor(
    private authService: AuthenticationService,
    private patientAttachmentsService: PatientAttachmentsService
  ) {
    this.matchingPrescriberDrugsChangedSubscription =
      this.patientAttachmentsService.matchingPrescriberDrugsChanged.subscribe(
        ({ matchingPrescriberDrugs }) => {
          this.matchingPrescriberDrugsWrittenDates =
            uniq(matchingPrescriberDrugs.flatMap(mpd => mpd.relatedWrittenDates));
        }
      );
  }

  onVerificationChange(change: { verification: SpecialistEncounterVerification; tempId?: string }) {
    this.updateCaptureSpecialistEncounterVerification(change.verification, change.tempId);
  }

  onVerificationRemove(sev: SpecialistEncounterVerification) {
    this.removeCaptureSpecialistEncounterVerification(sev);
  }

  private updateCaptureSpecialistEncounterVerification(
    specialistEncounterVerification: SpecialistEncounterVerification,
    tempId?: string
  ) {
    const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);

    if (!modifiedPatientAttachment.specialistEncounterVerifications) {
      modifiedPatientAttachment.specialistEncounterVerifications = [];
    }

    const paIndex = modifiedPatientAttachment.specialistEncounterVerifications.findIndex(
      paSev => paSev.id === specialistEncounterVerification.id || paSev.id === tempId
    );

    if (paIndex > -1) {
      modifiedPatientAttachment.specialistEncounterVerifications[paIndex] = specialistEncounterVerification;
    } else {
      modifiedPatientAttachment.specialistEncounterVerifications.push(specialistEncounterVerification);
    }

    this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
  }

  private removeCaptureSpecialistEncounterVerification(sev: SpecialistEncounterVerification) {
    const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);
    remove(modifiedPatientAttachment.specialistEncounterVerifications, captureSev => captureSev.id === sev.id);
    this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
  }
}
