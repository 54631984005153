import { DateUtils } from '../lib/date-utils';

export class PatientSearchQuery {
  public clientId: number;
  public id: string;
  public firstName: string;
  public lastName: string;
  public dob: string;
  public gender: string;
  public withOutboundFax: boolean;

  public canPerform(...fields): boolean {
    const hasOne = fields.some(k => this[k]);

    if (!hasOne) {
      return false;
    }

    if (this.lastName && this.lastName.length < 3) {
      return false;
    }

    return !(this.dob && !DateUtils.isValid(this.dob));
  }

  public isDirty(): boolean {
    return Object.keys(this).some(k => this[k]);
  }
}
