import { Component, OnInit } from '@angular/core';
import { Audit, ParsedAudit } from '../../../core/models/audit.model';
import { NavigationService } from 'app/core/services/navigation.service';
import { AuditsService } from 'app/core/services/audits.service';
import { DatePipe } from '@angular/common';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { UsersService } from 'app/core/services/users.service';
import { User } from 'app/core/models/user.model';
import { createParsedAudit } from 'app/core/lib/audit-utils';

@Component({
  selector: 'app-capture-admin-user-audit',
  templateUrl: './capture-admin-user-audit.component.html',
  styleUrls: ['./capture-admin-user-audit.component.scss'],
})
export class CaptureAdminUserAuditComponent implements OnInit {
  userId = parseInt(this.navigationService.currentId, 10);
  user: User;
  paging: LimitOffsetPaging;
  loading = true;
  parsedAudits: ParsedAudit[];
  audits: Audit[];
  notSetString = 'not set';

  modelNameStrings = {
    User: 'User',
    UserRole: 'User Role',
    UserAssignedClient: 'User Assigned Client',
  };

  constructor(
    private auditsService: AuditsService,
    private navigationService: NavigationService,
    private userService: UsersService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.loadUser();
    this.loadAudits(this.paging.currentPage);
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadAudits(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadAudits(page);
  }

  private loadUser() {
    this.userService.get(this.userId).subscribe(u => this.user = u)
  }

  private loadAudits(page: number) {
    const paging = { page, pageSize: this.paging.pageSize };

    this.loading = true;
    this.auditsService.getList(paging, 'User', [this.userId], true).subscribe(response => {
      this.paging = response.meta.paging;
      this.parsedAudits = response.audits.map(audit => this.auditToParseAudit(audit));
      this.loading = false;
    });
  }

  private auditToParseAudit(audit: Audit): ParsedAudit | null {
    const {
      id,
      ...changes
    } = audit.auditedChanges;

    return createParsedAudit(audit, changes, this.notSetString);
  }
}