export class UserListFilters {
  constructor(
    public userFirstName = null,
    public userLastName = null,
    public userEmail = null,
    public userClients = [],
    public userTypes = [],
    public userStatuses = []
  ) {}
}
