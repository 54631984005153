<div class="card mt-1">
  <div class="card-body">
    <app-verification-heading
      [patientAttachment]="patientAttachment"
      title="Validate Drug Details"
    />
    <app-drug-details [capture]="capture" />
    <app-drug-match-form [capture]="capture" [patientAttachment]="patientAttachment" />
  </div>
</div>
