<app-reports
  title="NDCs with Missing Data Reports"
  [filtersForm]="filtersForm"
  [reportsUrl]="reportsUrl"
  [reportsCountUrl]="reportsCountUrl">

  <ng-template appNewReportOptionsTemplate let-filtersForm="filtersForm" />

  <ng-template appReportDetailsTemplate let-report="report" />

</app-reports>
