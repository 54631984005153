import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isNull } from 'lodash-es';
import { environment } from 'environments/environment';
import { ApiService } from '../api.service';
import { IccCapture } from 'app/core/models/icc/icc-capture.model';
import { PagedResults } from 'app/core/models/paged-results/paged-results.model';
import { Cursor, CursorBasedPagingMeta } from 'app/core/models/paged-results/cursor-based-paging.model';
import { IccCaptureQueueCursorType } from 'app/core/enums/icc-capture-queue-cursor-type.enum';
import { CursorParams } from 'app/core/enums/cursor-params.enum';

export class PagedIccCaptureQueueResults
  implements PagedResults<IccCapture, CursorBasedPagingMeta<IccCaptureQueueCursorType>> {
  iccCaptures: IccCapture[] = new Array<IccCapture>();
  meta: CursorBasedPagingMeta<IccCaptureQueueCursorType> = new CursorBasedPagingMeta<IccCaptureQueueCursorType>();

  get records() {
    return this.iccCaptures;
  }
}

@Injectable({
  providedIn: 'root'
})
export class IccCaptureService extends ApiService {

  constructor(private http: HttpClient) {
    super();
  }

  getCapture(id: string | number): Observable<IccCapture> {
    return this.http.get<IccCapture>(`${environment.captureApi.url}/icc/captures/${id}`);
  }

  getQueue(cursor: Cursor<IccCaptureQueueCursorType> = null): Observable<PagedIccCaptureQueueResults> {
    let cursorParams: URLSearchParams = null;
    if (cursor) {
      cursorParams = this.buildCursorParams(cursor.name, cursor.id, cursor.value);
    } else {
      cursorParams = this.buildCursorParams(IccCaptureQueueCursorType.mostRecentlyCreated);
    }

    return this.http.get<PagedIccCaptureQueueResults>(
      `${environment.captureApi.url}/icc/captures?${cursorParams.toString()}`
    );
  }

  private buildCursorParams(cursorType: IccCaptureQueueCursorType, id: number = null, value: string = null) {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append(CursorParams.name, cursorType);

    if (!isNull(id)) {
      urlSearchParams.append(CursorParams.id, id.toString());
    }

    if (!isNull(value)) {
      urlSearchParams.append(CursorParams.value, value);
    }

    return urlSearchParams;
  }
}
