export enum ClaimSource {
  bioPlus = 'bio',
  equiscripts = 'eqs',
  walgreens = 'wag',
  wellPartner = 'cvs',
  hudsonHeadwaters = 'hud',
  pdmi = 'pdmi',
  captureRx = "crx",
  verity = "vty",
  louvirVerity = "louvty",
  louvir340Basics = "loubasic",
  macrohelix = "macro",
}
