import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { debounce, isEmpty } from 'lodash-es';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { DrugMatchVerification } from '../../../core/models/drug-match-verification.model';
import { DrugMatchVerificationsService } from '../../../core/services/drug-match-verifications.service';
import { Capture } from '../../../core/models/capture.model';
import { CaptureValidationService, ValidationKeys } from '../../../core/services/capture-validation.service';
import {
  isPatientAttachmentVerificationFrozen,
  resolveMostRelevantDrugMatchVerificationFromCapture,
} from '../../../core/lib/verification-utils';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { CaptureService } from '../../../core/services/capture.service';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';

@Component({
  selector: 'app-drug-match-form',
  templateUrl: './drug-match-form.component.html',
  styleUrls: ['./drug-match-form.component.scss'],
})
export class DrugMatchFormComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;

  drugMatchVerification: DrugMatchVerification;
  verificationFrozen = false;
  validationKey = ValidationKeys.drugMatchVerification;
  showAdditionalDetails = false;

  debouncedSaveVerification = debounce(
    () => {
      this.saveDrugMatchVerification();
    },
    250,
    { maxWait: 1000 }
  );

  constructor(
    private drugMatchVerificationService: DrugMatchVerificationsService,
    private authService: AuthenticationService,
    private captureService: CaptureService,
    private patientAttachmentsService: PatientAttachmentsService,
    private captureValidationService: CaptureValidationService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'capture' || inputName === 'patientAttachment') {
        this.initDrugMatchVerification();
      }
    });
  }

  onDrugMatchesChange() {
    this.captureValidationService.clearError(this.validationKey);
    this.debouncedSaveVerification();
  }

  onAdditionalTextChange() {
    this.debouncedSaveVerification();
  }

  onPlusClick() {
    this.showAdditionalDetails = true;
  }

  onMinusClick() {
    this.showAdditionalDetails = false;
  }

  private saveDrugMatchVerification() {
    if (this.drugMatchVerification.id) {
      this.updateDrugMatchVerification();
    } else {
      this.createDrugMatchVerification();
    }
  }

  private createDrugMatchVerification() {
    this.drugMatchVerificationService
      .create(this.patientAttachment.id, this.capture.id, this.drugMatchVerification)
      .subscribe((drugMatchVerification: DrugMatchVerification) => {
        this.initCaptureDrugMatchVerificationId(drugMatchVerification);
        this.updatePatientAttachmentDrugMatchVerification(drugMatchVerification);
      });
  }

  private updateDrugMatchVerification() {
    this.drugMatchVerificationService
      .update(this.patientAttachment.id, this.capture.id, this.drugMatchVerification)
      .subscribe((drugMatchVerification: DrugMatchVerification) => {
        this.updatePatientAttachmentDrugMatchVerification(drugMatchVerification);
      });
  }

  private initCaptureDrugMatchVerificationId(drugMatchVerification: DrugMatchVerification) {
    const modifiedCapture: Capture = Object.assign({}, this.capture);
    modifiedCapture.drugMatchVerificationId = drugMatchVerification.id;
    this.captureService.notifyCaptureChanged(modifiedCapture);
  }

  private updatePatientAttachmentDrugMatchVerification(dmv: DrugMatchVerification) {
    const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);

    const index = modifiedPatientAttachment.drugMatchVerifications.findIndex(paDmv => paDmv.id === dmv.id);

    if (index > -1) {
      modifiedPatientAttachment.drugMatchVerifications[index] = dmv;
    } else {
      modifiedPatientAttachment.drugMatchVerifications.push(dmv);
    }

    this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
  }

  private initDrugMatchVerification() {
    const mostRelevantDmv = resolveMostRelevantDrugMatchVerificationFromCapture(this.capture, this.patientAttachment);

    this.drugMatchVerification = mostRelevantDmv
      ? Object.assign({}, mostRelevantDmv)
      : this.buildNewDrugMatchVerification();

    this.initFormState();
  }

  private initFormState() {
    this.showAdditionalDetails = !isEmpty(this.drugMatchVerification.notes);

    this.verificationFrozen = isPatientAttachmentVerificationFrozen(
      this.authService,
      this.capture,
      this.patientAttachment,
      this.drugMatchVerification
    );
  }

  private buildNewDrugMatchVerification() {
    const candidate = this.capture.candidate;
    const dmv = new DrugMatchVerification();

    dmv.ndc = candidate.ndc;
    dmv.drugGroupId = candidate.drugGroup ? candidate.drugGroup.id : null;
    dmv.referenceNumber = candidate.referenceNumber;

    return dmv;
  }
}
