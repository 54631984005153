<h3>Add New Provider</h3>

<form [formGroup]="formGroup" (submit)="onSubmit()" #form="ngForm">
  <div class="row">
    <div class="col-4">
      <div class="mb-3">
        <label>Provider First Name (Required)</label>
        <input
          class="form-control"
          formControlName="first"
          appFormControlValidityClass>
        <app-form-control-server-errors
          [form]="form"
          controlName="first" />
      </div>
    </div>
    <div class="col-4">
      <div class="mb-3">
        <label>Provider Last Name (Required)</label>
        <input
          class="form-control"
          formControlName="last"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="last" />
      </div>
    </div>
    <div class="col-2">
      <div class="mb-3">
        <label>Provider Credential</label>
        <ng-select
          bindValue="value"
          bindLabel="display"
          formControlName="credentials"
          [items]="providerCredentialOptions"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="credentials" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="mb-3">
        <label>NPI (Required)</label>
        <input
          class="form-control"
          formControlName="npi"
          appFormControlValidityClass
          maxlength="10">
        <app-form-control-server-errors
          [form]="form"
          controlName="npi" />
      </div>
    </div>
    <div class="col-4">
      <div class="mb-3">
        <label>Provider Status (Required)</label>
        <ng-select
          bindValue="value"
          bindLabel="display"
          formControlName="status"
          [items]="providerStatusOptions"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="status" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="mb-3">
        <label>Provider Specialties</label>
        <ng-select
          bindValue="name"
          bindLabel="name"
          formControlName="specialties"
          [items]="providerSpecialties"
          [multiple]="true"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="specialties" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8">
      <div class="mb-3">
        <label>Note</label>
        <textarea
          class="form-control"
          formControlName="note"
          rows="5"
          appFormControlValidityClass>
        </textarea>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-10 text-end actions">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="form.submitted && formGroup.invalid || saving">
        Save
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="onCancelClick()"
        [disabled]="saving">
        Cancel
      </button>
    </div>
  </div>

</form>
