import { Component, OnInit } from '@angular/core';
import { ClientPrescriberTask } from 'app/core/models/client-prescriber-task.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import {
  ClientPrescriberTasksService,
  PagedClientPrescriberTasks,
} from 'app/core/services/client-prescriber-tasks.service';
import { TaskFilters, TaskQueueSettings } from '../../../core/models/user-settings/task-settings.model';
import { ClientPrescriberTaskStatus } from '../../../core/enums/client-prescriber-task-status.enum';
import { UserSettings } from '../../../core/enums/user-settings.enum';
import { UserSettingsService } from '../../../core/services/user-settings.service';
import { Sort } from '../../../core/models/paged-results/limit-offset-paging.model';
import { ClientsService } from 'app/core/services/clients.service';
import { displayTaskStatus } from 'app/core/options/task-status.opts';

@Component({
  templateUrl: './client-portal-client-prescriber-tasks.component.html',
  styleUrls: ['./client-portal-client-prescriber-tasks.component.scss'],
})
export class ClientPortalClientPrescriberTasksComponent implements OnInit {
  tasks: ClientPrescriberTask[] = [];
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  sort: Sort = new Sort();
  filters: TaskFilters = new TaskFilters();
  taskStatuses = [];
  specialties = [];
  manufacturers = [];
  sources = [];

  loading = true;

  exportUrl = "/ce-portal/reports/client-prescriber-task-reports";

  constructor(
    private clientPrescriberTasksService: ClientPrescriberTasksService,
    private userSettingsService: UserSettingsService,
    private clientsService: ClientsService
  ) {}

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(pageSize) {
    this.paging.pageSize = pageSize;
    this.loadTasks(this.paging.currentPage);
    this.saveQueueSettings();
  }

  onPageChange(page) {
    this.loadTasks(page);
    this.saveQueueSettings();
  }

  filtersChange(filters) {
    this.filters = filters;
    this.loadTasks();
    this.saveQueueSettings();
  }

  onSortChange(sort: Sort) {
    this.sort = sort;
    this.saveQueueSettings();
    this.loadTasks();
  }

  onViewTask(position: number) {
    this.saveQueueSettings(position);
  }

  private init() {
    this.loadStatuses();
    this.loadQueueSettings();
    this.loadSpecialties();
    this.loadManufacturers();
    this.loadSources();
    this.loadTasks();
  }

  private loadTasks(page = this.paging.currentPage) {
    this.loading = true;

    const paging = { page, pageSize: this.paging.pageSize };

    this.clientPrescriberTasksService
      .getList(paging, this.filters, this.sort)
      .subscribe((result: PagedClientPrescriberTasks) => {
        this.tasks = result.clientPrescriberTasks;
        this.paging = result.meta.paging;
        this.sort = result.meta.sort || new Sort();
        this.loading = false;
      });
  }

  private loadSpecialties() {
    this.clientsService.getSpecialties().subscribe(result => {
      this.specialties = result;
    });
  }

  private loadManufacturers() {
    this.clientPrescriberTasksService.getManufacturers().subscribe(result => {
      this.manufacturers = result;
    });
  }

  private loadSources() {
    this.clientPrescriberTasksService.getSources().subscribe(result => {
      this.sources = result;
    });
  }

  private loadQueueSettings() {
    const queueSettings: TaskQueueSettings = this.userSettingsService.get<TaskQueueSettings>(
      UserSettings.clientPortalPrescriberTaskQueueSettings
    );

    if (queueSettings) {
      this.paging = queueSettings.paging;
      this.filters = queueSettings.filters;
      this.sort = queueSettings.sort;
    }
  }

  private loadStatuses() {
    this.taskStatuses = Object.values(ClientPrescriberTaskStatus).map(v => ({
      value: v,
      display: displayTaskStatus(v),
    }));
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new TaskQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    queueSettings.filters = this.filters;
    queueSettings.sort = this.sort;
    this.userSettingsService.save<TaskQueueSettings>(
      UserSettings.clientPortalPrescriberTaskQueueSettings,
      queueSettings
    );
  }
}
