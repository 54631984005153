import { Component, Input, OnInit } from '@angular/core';
import { ProviderOffice } from 'app/core/models/provider-office.model';

@Component({
  selector: 'app-provider-office',
  templateUrl: './provider-office.component.html',
  styleUrls: ['./provider-office.component.scss'],
})
export class ProviderOfficeComponent {
  @Input() providerOffice: ProviderOffice;
}
