<ng-select
  class="w-100"
  bindValue="id"
  bindLabel="fullName"
  name="userId"
  placeholder="Select User"
  [multiple]="false"
  [items]="consultNoteUsers"
  [(ngModel)]="selectedUser"
  (change)="handleChange()"
/>
