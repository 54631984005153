import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { IccImportEligibleProvidersComponent } from './pages/icc-import-eligible-providers/icc-import-eligible-providers.component';
import { IccImportEligibleProvidersHistoryComponent } from './pages/icc-import-eligible-providers-history/icc-import-eligible-providers-history.component';
import { IccImportMedicaidCodesComponent } from './pages/icc-import-medicaid-codes/icc-import-medicaid-codes.component';
import { IccImportMedicaidCodesHistoryComponent } from './pages/icc-import-medicaid-codes-history/icc-import-medicaid-codes-history.component';
import { IccImportIneligibleDrugsComponent } from './pages/icc-import-ineligible-drugs/icc-import-ineligible-drugs.component';
import { IccImportIneligibleDrugsHistoryComponent } from './pages/icc-import-ineligible-drugs-history/icc-import-ineligible-drugs-history.component';
import { IccImportCatalogedDrugsComponent } from './pages/icc-import-cataloged-drugs/icc-import-cataloged-drugs.component';
import { IccImportCatalogedDrugsHistoryComponent } from './pages/icc-import-cataloged-drugs-history/icc-import-cataloged-drugs-history.component';
import { IccImportOrphanExemptionDrugsComponent } from './pages/icc-import-orphan-exemption-drugs/icc-import-orphan-exemption-drugs.component';
import { IccImportOrphanExemptionDrugsHistoryComponent } from './pages/icc-import-orphan-exemption-drugs-history/icc-import-orphan-exemption-drugs-history.component';
import { IccImportPharmaciesComponent } from './pages/icc-import-pharmacies/icc-import-pharmacies.component';
import { IccImportPharmaciesHistoryComponent } from './pages/icc-import-pharmacies-history/icc-import-pharmacies-history.component';
import { IccImportClaimsComponent } from './pages/icc-import-claims/icc-import-claims.component';
import { IccImportClaimsHistoryComponent } from './pages/icc-import-claims-history/icc-import-claims-history.component';
import { IccImportCaptureEligibilityFindingsComponent } from './pages/icc-import-capture-eligibility-findings/icc-import-capture-eligibility-findings.component';
import { IccImportCaptureEligibilityFindingsHistoryComponent } from './pages/icc-import-capture-eligibility-findings-history/icc-import-capture-eligibility-findings-history.component';
import { IccExportClaimsComponent } from './pages/icc-export-claims/icc-export-claims.component';
import { IccImportUploadComponent } from './components/icc-import-upload/icc-import-upload.component';
import { IccImportRenameModalComponent } from './components/icc-import-rename-modal/icc-import-rename-modal.component';
import { IccReportClaimImportOptionComponent } from './components/icc-report-claim-import-option/icc-report-claim-import-option.component';
import { IccReportAppliedClaimImportOptionComponent } from './components/icc-report-applied-claim-import-option/icc-report-applied-claim-import-option.component';

import { IccEligibleProviderImportsService } from '../core/services/icc/icc-eligible-provider-imports.service';
import { IccMedicaidCodeImportsService } from '../core/services/icc/icc-medicaid-code-imports.service';
import { IccIneligibleDrugImportsService } from '../core/services/icc/icc-ineligible-drug-imports.service';
import { IccCatalogedDrugImportsService } from '../core/services/icc/icc-cataloged-drug-imports.service';
import { IccOrphanExemptionDrugImportsService } from '../core/services/icc/icc-orphan-exemption-drug-imports.service';
import { IccPharmacyImportsService } from '../core/services/icc/icc-pharmacy-imports.service';
import { IccClaimImportsService } from '../core/services/icc/icc-claim-imports.service';
import { IccCaptureEligibilityFindingsImportsService } from '../core/services/icc/icc-capture-eligibility-findings-imports.service';
import { IccCapturesComponent } from './pages/icc-captures/icc-captures.component';
import { IccCaptureDetailComponent } from './pages/icc-capture-detail/icc-capture-detail.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    IccImportEligibleProvidersComponent,
    IccImportEligibleProvidersHistoryComponent,
    IccImportMedicaidCodesComponent,
    IccImportMedicaidCodesHistoryComponent,
    IccImportIneligibleDrugsComponent,
    IccImportIneligibleDrugsHistoryComponent,
    IccImportCatalogedDrugsComponent,
    IccImportCatalogedDrugsHistoryComponent,
    IccImportOrphanExemptionDrugsComponent,
    IccImportOrphanExemptionDrugsHistoryComponent,
    IccImportPharmaciesComponent,
    IccImportPharmaciesHistoryComponent,
    IccImportClaimsComponent,
    IccImportClaimsHistoryComponent,
    IccImportCaptureEligibilityFindingsComponent,
    IccImportCaptureEligibilityFindingsHistoryComponent,
    IccExportClaimsComponent,
    IccImportUploadComponent,
    IccImportRenameModalComponent,
    IccReportClaimImportOptionComponent,
    IccReportAppliedClaimImportOptionComponent,
    IccCapturesComponent,
    IccCaptureDetailComponent,
  ],
  providers: [
    IccEligibleProviderImportsService,
    IccMedicaidCodeImportsService,
    IccIneligibleDrugImportsService,
    IccCatalogedDrugImportsService,
    IccOrphanExemptionDrugImportsService,
    IccPharmacyImportsService,
    IccClaimImportsService,
    IccCaptureEligibilityFindingsImportsService,
  ],
})
export class IccModule {}
