<h5>Consult Notes</h5>
<ng-container *ngIf="!loading; else: consultNotesSpinner">
  <div *ngIf="patientAttachments.length > 0; else: noConsultNotes">
    <p>Consult notes associated with this patient and prescriber.</p>
    <div class="scrollable">
      <table class="table table-sm table-striped">
        <thead>
          <th>DOCUMENT NAME</th>
          <th class="text-center">SPECIALIST VISIT DATE</th>
        </thead>
        <tbody>
          <tr *ngFor="let patientAttachment of patientAttachments">
            <td>
              <a target="_blank" [routerLink]="'/ce-portal/consult-note-viewer/' + patientAttachment.id"> {{ patientAttachment.fileName }} </a>
            </td>
            <td class="text-center">
              <div *ngFor="let verification of patientAttachment.specialistEncounterVerifications">
                {{ verification.encounterDate | date: 'MM/dd/yyyy' }}
              </div>
            </td>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<ng-template #noConsultNotes>
  <p>There are no consult notes associated with this patient and prescriber yet.</p>
</ng-template>
<ng-template #consultNotesSpinner> <mat-spinner [diameter]="30" /> </ng-template>
