import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-drug',
  templateUrl: './candidate-drug.component.html',
  styleUrls: ['./candidate-drug.component.scss'],
})
export class CandidateDrugComponent implements OnInit {
  @Input() candidate: { product: string; ndc: string, flaggedForEsp: boolean };
  @Input() wrapProductName = false;
  @Input() showCopy = false;
  @Input() displayFlaggedForEsp = false;

  constructor() {}

  ngOnInit() {}

  public get isFlaggedForEsp() : boolean {
    return this.displayFlaggedForEsp && this.candidate.flaggedForEsp;
  }
}
