import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ClientConsultNoteVerification } from '../../../core/models/client-consult-note-verification.model';
import { DateUtils } from 'app/core/lib/date-utils';

@Component({
  selector: 'app-client-consult-note-task-date',
  templateUrl: './client-consult-note-task-date.component.html',
  styleUrls: ['./client-consult-note-task-date.component.scss'],
})
export class ClientConsultNoteTaskDateComponent implements OnInit, OnChanges {
  encounterDate = null;
  disabled = false;

  @Input() position = -1;
  @Input() verification: ClientConsultNoteVerification;
  @Input() formDisabled: boolean;
  @Output() removeVerification = new EventEmitter<number>();
  @Output() updateVerification = new EventEmitter<{ id: number; encounterDate: string }>();

  constructor() {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  onDateClearClick() {
    this.encounterDate = null;
    const obj = { id: this.verification.id, encounterDate: null };
    this.updateVerification.emit(obj);
  }

  onEncounterDateBlur() {
    if (!this.isValidEncounterDate()) {
      this.encounterDate = null;
    }

    this.updateVerification.emit({ id: this.verification.id, encounterDate: this.encounterDate });
  }

  private isValidEncounterDate() {
    return DateUtils.isValid(this.encounterDate);
  }

  onRemoveClick($event, verification: ClientConsultNoteVerification) {
    $event.preventDefault();
    this.removeVerification.emit(verification.id);
  }

  private init() {
    this.encounterDate = this.verification.encounterDate;
    this.disabled = this.formDisabled || this.verification.frozen;
  }
}
