import { Component, Input, OnInit } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';

@Component({
  selector: 'app-inbound-fax-header',
  templateUrl: './inbound-fax-header.component.html',
  styleUrls: ['./inbound-fax-header.component.scss'],
})
export class InboundFaxHeaderComponent implements OnInit {
  @Input() inboundFax: InboundFax;

  constructor() {}

  ngOnInit() {}
}
