<app-reports
  title="Outgoing Fax Reports"
  [filtersForm]="filtersForm"
  [reportsUrl]="reportsUrl"
  [reportsCountUrl]="reportsCountUrl">

  <ng-template appNewReportOptionsTemplate let-filtersForm="filtersForm">
    <ng-container [formGroup]="filtersForm" />
  </ng-template>

  <ng-template appReportDetailsTemplate let-report="report" />

</app-reports>
