import { Component, Input } from '@angular/core';
import { Patient } from 'app/core/models/patient.model';

@Component({
  selector: 'app-patient-nav',
  templateUrl: './patient-nav.component.html',
  styleUrls: ['./patient-nav.component.scss'],
})
export class PatientNavComponent {
  @Input() patient: Patient;
 }
