import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Provider } from 'app/core/models/provider.model';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ProviderOfficeService } from 'app/core/services/provider-office.service';
import { of, concat } from 'rxjs';
import { catchError, toArray } from 'rxjs/operators';
import { applyServerErrorsToFormGroup } from 'app/core/lib/form-utils';

const providerOfficeErrorMap = {
  office: "officeId",
  providerId: "officeId",
}

@Component({
  selector: 'app-provider-office-new-form',
  templateUrl: './provider-office-new-form.component.html',
  styleUrls: ['./provider-office-new-form.component.scss'],
})
export class ProviderOfficeNewFormComponent implements OnInit {
  @Input() provider: Provider;
  @Input() providerOffices: ProviderOffice[];

  @Output() completed = new EventEmitter<void>();
  @Output() providerAdded = new EventEmitter<void>();

  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private providerOfficeService: ProviderOfficeService,
  ) { }

  get providerOfficeFormArray() {
    return this.formGroup.controls["providerOffices"] as FormArray;
  }

  providerOfficeFormByIndex(index: number) {
    return this.providerOfficeFormArray.controls[index] as FormGroup;
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      providerOffices: this.formBuilder.array([])
    })

    this.addNewProviderOffice();
  }

  onAddNewProviderOfficeClick() {
    this.addNewProviderOffice();
  }

  onDeleteNewProviderOfficeClick(index: number) {
    this.providerOfficeFormArray.removeAt(index);
  }

  onSubmit() {
    const allAttributes =
      this.providerOfficeFormArray.value.map((value: any) => ({ ...value, providerId: this.provider.id }));

    const serviceCalls =
      allAttributes.
        map((attributes: any) => this.providerOfficeService.create(attributes).pipe(catchError(e => of(e))));

    concat(...serviceCalls).
      pipe(toArray()).
      subscribe(
        results => {
          let anySuccessful = false;

          // Iterate in reverse so that items can be removed without messing up indexing
          for (let index = results.length - 1; index >= 0; index--) {
            const result: any = results[index];

            if (result === true) {
              anySuccessful = true;

              this.providerOfficeFormArray.removeAt(index);

              if (this.providerOfficeFormArray.length === 0) {
                this.completed.emit();
              }
            } else {
              const formGroup = this.providerOfficeFormByIndex(index);

              applyServerErrorsToFormGroup(result, formGroup, providerOfficeErrorMap);
            }
          }

          if (anySuccessful) {
            this.providerAdded.emit();
          }
        },
        error => { console.log(error) });
  }

  onCancelClick() {
    this.completed.emit();
  }

  private addNewProviderOffice() {
    const providerOffice = this.formBuilder.group({
      officeId: [],
      status: [{ value: null, disabled: true }],
      note: [{ value: "", disabled: true }],
    });

    this.providerOfficeFormArray.push(providerOffice)
  }
}
