import { Component } from '@angular/core';
import { ConsultNoteHistoryItemTypeBaseComponent } from '../consult-note-history-item-type-base/consult-note-history-item-type-base.component';
import { displayPatientAttachmentStatus } from 'app/core/options/patient-attachment-status.opts';

@Component({
  selector: 'app-consult-note-history-item-type-transitioned',
  templateUrl: './consult-note-history-item-type-transitioned.component.html',
})
export class ConsultNoteHistoryItemTypeTransitionedComponent extends ConsultNoteHistoryItemTypeBaseComponent {
  constructor() {
    super();
  }

  get displayStatus() {
    return displayPatientAttachmentStatus(this.patientAttachmentHistoryItem.data.status);
  }

  get description() {
    return `Consult note transitioned to ${this.displayStatus}`;
  }
}
