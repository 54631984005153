import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinList',
})
export class JoinListPipe implements PipeTransform {
  transform(list: string[], delimiter: string = ', ', emptyValue: string = ''): any {
    if (list && list.length > 0) {
      return list.join(delimiter);
    } else {
      return emptyValue;
    }
  }
}
