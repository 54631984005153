import { Component, Input, OnInit } from '@angular/core';
import { InboundCall } from 'app/core/models/inbound-call.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { InboundCallsService } from 'app/core/services/inbound-calls.service';
import { keyedReasonOptions } from 'app/core/options/inbound-call-reasons.opts';

@Component({
  selector: 'app-inbound-call-log',
  templateUrl: './inbound-call-log.component.html',
  styleUrls: ['./inbound-call-log.component.scss'],
})
export class InboundCallLogComponent implements OnInit {
  @Input() outboundFaxNumber: string;
  @Input() npi: string;

  inboundCalls: InboundCall[] = [];
  paging: LimitOffsetPaging;
  refreshing = false;

  keyedInboundCallReasonOptions = keyedReasonOptions;

  constructor(private inboundCallsService: InboundCallsService) {}

  ngOnInit() {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.loadInboundCalls();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.loadInboundCalls();
  }

  onPageChange(page) {
    this.paging.currentPage = page;
    this.loadInboundCalls();
  }

  private loadInboundCalls() {
    this.refreshing = true;
    this.inboundCallsService.getList(
      this.paging.currentPage,
      this.paging.pageSize,
      [],
      this.npi,
      this.outboundFaxNumber
    ).subscribe(result => {
      console.log(result);
      this.inboundCalls = result.inboundCalls;
      this.paging = result.meta.paging;
      this.refreshing = false;
    });
  }
}
