import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Prescription } from '../../../core/models/prescription.model';
import { NavigationService } from '../../../core/services/navigation.service';
import { PrescriptionsService } from '../../../core/services/prescriptions.service';
import { PrescriptionCapture } from '../../../core/models/capture-lists/prescription-capture-model';

@Component({
  selector: 'app-prescription-captures',
  templateUrl: './prescription-captures.component.html',
  styleUrls: ['./prescription-captures.component.scss'],
})
export class PrescriptionCapturesComponent implements OnInit {
  @Input() prescription: Prescription;
  @Input() activeCaptureId: number;

  loading = true;
  showEstimatedValue = false;
  baseCaptureUrl = null;
  captures: PrescriptionCapture[] = [];

  constructor(
    private prescriptionsService: PrescriptionsService,
    private navigationService: NavigationService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.showEstimatedValue = this.authService.isCaptureAdminUser;
    this.baseCaptureUrl = this.navigationService.baseShowUrl('captures');
    this.loadCaptures();
  }

  private loadCaptures() {
    this.prescriptionsService.getCaptures(this.prescription).subscribe((captures: PrescriptionCapture[]) => {
      this.loading = false;
      this.captures = captures.sort((a: PrescriptionCapture, b: PrescriptionCapture) =>
        a.id === this.activeCaptureId ? -1 : b.id === this.activeCaptureId ? 1 : 0);
    });
  }
}
