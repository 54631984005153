import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReferralPrescriberSpecialtyMappingImport } from '../../../core/models/referral-prescriber-specialty-mapping-import.model';
import { ReferralPrescriberSpecialtyMappingService } from '../../../core/services/referral-prescriber-specialty-mapping.service';

@Component({
  selector: 'app-capture-admin-import-referral-prescriber-specialty-mappings-history',
  templateUrl: './capture-admin-import-referral-prescriber-specialty-mappings-history.component.html',
})
export class CaptureAdminImportReferralPrescriberSpecialtyMappingHistoryComponent implements OnInit, OnDestroy {
  loading = false;
  referralPrescriberSpecialtyMappingImports: ReferralPrescriberSpecialtyMappingImport[] = [];
  nextCheckTimeout = null;

  constructor(private referralPrescriberSpecialtyMappingService: ReferralPrescriberSpecialtyMappingService) {}

  ngOnInit() {
    this.loadImports(true);
  }

  ngOnDestroy() {
    clearTimeout(this.nextCheckTimeout);
  }

  private loadImports(loading) {
    this.loading = loading;
    this.referralPrescriberSpecialtyMappingService.getList().subscribe(data => {
      this.referralPrescriberSpecialtyMappingImports = data.referralPrescriberSpecialtyMappingImports;
      this.loading = false;

      const anyActive: boolean = this.referralPrescriberSpecialtyMappingImports.some(record => record.active);

      if (anyActive) {
        this.nextCheckTimeout = setTimeout(() => this.loadImports(false), 500);
      }
    });
  }
}
