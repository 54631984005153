import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ReportOption } from 'app/core/models/report-option.model';

@Component({ template: '' })
export class ReportAbstractOptionComponent implements ControlValueAccessor {
  model: ReportOption;

  @Input() disabled = false;

  public onChangeFn = (_: any) => {};

  public onTouchedFn = () => {};

  public registerOnChange(fn: any) {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public writeValue(model: ReportOption) {
    this.model = model;

    this.updateInternalState(this.model);
  }

  protected updateInternalState(model: ReportOption): void {}

  public onChange() {
    this.model = this.determineModelStateOnChange();

    this.onChangeFn(this.model);
  }

  // Subclass implementations should only return a model state when its internal value is present.
  // This allows for correct use of Validators.required in the filters FormGroup.
  protected determineModelStateOnChange(): ReportOption {
    return null;
  }
}
