import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportOption } from 'app/core/models/report-option.model';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { PatientInteractionLocationEligibilityStatusOptions } from 'app/core/options/patient-interaction-location-eligibility-status.opts';

export class ReportLocationEligibilityStatusOption implements ReportOption {
  value: any = null;

  constructor(value) {
    this.value = value;
  }

  toPostData(options = { paramName: 'eligibilityStatuses' }) {
    return { [options.paramName]: this.value };
  }
}

@Component({
  selector: 'app-report-location-eligibility-status-option',
  templateUrl: './report-location-eligibility-status-option.component.html',
  styleUrls: ['./report-location-eligibility-status-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportLocationEligibilityStatusOptionComponent),
    },
  ],
})
export class ReportLocationEligibilityStatusOptionComponent extends ReportAbstractOptionComponent {
  @Input() label = 'Filter by Eligibility Status';
  @Input() multiple = true;

  eligibilityStatusFilterOptions = PatientInteractionLocationEligibilityStatusOptions;

  selectedStatuses: any;

  protected updateInternalState(model: ReportLocationEligibilityStatusOption) {
    if (model) {
      this.selectedStatuses = model.value;
    }
  }

  protected determineModelStateOnChange(): ReportLocationEligibilityStatusOption {
    if (this.selectedStatuses && (!Array.isArray(this.selectedStatuses) || this.selectedStatuses.length > 0)) {
      return new ReportLocationEligibilityStatusOption(this.selectedStatuses);
    } else {
      return null;
    }
  }
}
