import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Upload } from '../../../core/models/upload.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LlmPlaygroundBatchesService } from '../../../core/services/llm-playground-batches.service';

@Component({
  selector: 'app-llm-playground-add-batch-modal',
  templateUrl: './llm-playground-add-batch-modal.component.html',
  styleUrls: ['./llm-playground-add-batch-modal.component.scss'],
})
export class LlmPlaygroundAddBatchModalComponent implements OnInit {
  @Input() projectId: number;
  formGroup: FormGroup;
  loading = false;
  upload: Upload;
  errorMessage = '';

  constructor(
    public activeModal: NgbActiveModal,
    private llmPlaygroundBatchesService: LlmPlaygroundBatchesService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  onUploadStart() {
    this.loading = true;
  }

  onUploadCancel() {
    this.formGroup.reset();
    this.activeModal.close();
  }

  onUploadComplete(upload: Upload) {
    this.upload = upload;
    this.createBatch();
  }

  onUploadError(err: Error): void {
    this.loading = false;
    this.errorMessage = err.message;
  }

  private createBatch() {
    this.llmPlaygroundBatchesService
      .create(this.projectId, this.formGroup.get('name').value, this.upload.signedId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        batch => {
          this.activeModal.close(batch);
        },
        (err: HttpErrorResponse) => {
          this.errorMessage = err.error.join('\n');
          this.upload = undefined;
        }
      );
  }
}
