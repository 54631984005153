import { nanoid } from 'nanoid';
import objectHash from 'object-hash';

export class UserTiming {
  static readonly orphanTimingMilliseconds = 10 * 1000;

  public id: string;
  public stopCode: string;

  constructor(public hash, public data, public startedAt, public endedAt) {
    this.id = nanoid();
  }

  static fromObject(obj) {
    const timing = new UserTiming(obj.hash, obj.data, new Date(obj.startedAt), new Date(obj.endedAt));

    if (obj.id) {
      timing.id = obj.id;
    }

    return timing;
  }

  static dataHash(data) {
    return objectHash(data);
  }

  update() {
    this.endedAt = new Date();
  }

  get timeElapsed() {
    return this.endedAt - this.startedAt;
  }

  get isOrphan() {
    const age = +new Date() - this.endedAt;
    return this.endedAt && age > UserTiming.orphanTimingMilliseconds;
  }

  get postData() {
    return {
      id: this.id,
      hash: this.hash,
      data: this.data,
      startedAt: this.startedAt,
      endedAt: this.endedAt,
      stopCode: this.stopCode,
      isOrphan: this.isOrphan,
    };
  }
}
