import { Component } from '@angular/core';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';

@Component({
  selector: 'app-capture-history-item-type-location-added',
  templateUrl: './capture-history-item-type-location-added.component.html',
})
export class CaptureHistoryItemTypeLocationAddedComponent extends CaptureHistoryItemTypeBaseComponent {
  get note() {
    return this.captureHistoryItem.data?.note;
  }
}
