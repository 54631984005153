import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-additional-details-input',
  templateUrl: './additional-details-input.component.html',
  styleUrls: ['./additional-details-input.component.scss'],
})
export class AdditionalDetailsInputComponent implements OnInit {
  @Input() id = 'notes';
  @Input() value: string;
  @Input() frozen: boolean;
  @Output() valueChange = new EventEmitter<string>();

  show = false;

  constructor() {}

  ngOnInit(): void {
    this.show = !isEmpty(this.value);
  }

  onAdditionalTextChange() {
    this.valueChange.emit(this.value);
  }

  onPlusClick() {
    this.show = true;
  }

  onMinusClick() {
    this.show = false;
  }
}
