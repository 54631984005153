import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IccCaptureEligibilityFindingsImportsService } from 'app/core/services/icc/icc-capture-eligibility-findings-imports.service';
import { ImportUploadResult } from '../../components/icc-import-upload/icc-import-upload.component';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-icc-import-capture-eligibility-findings',
  templateUrl: './icc-import-capture-eligibility-findings.component.html',
  styleUrls: ['./icc-import-capture-eligibility-findings.component.scss'],
})
export class IccImportCaptureEligibilityFindingsComponent {
  errors: string[] = [];

  constructor(
    private captureEligibilityFindingsImportsService: IccCaptureEligibilityFindingsImportsService,
    private router: Router
  ) {}

  onUploadComplete({ name, upload }: ImportUploadResult) {
    this.captureEligibilityFindingsImportsService.create(name, upload.signedId).subscribe(
      () => {
        this.router.navigate(['/capture-admin/icc-claims/imports/capture-eligibility-findings/history']);
      },
      (error: HttpErrorResponse) => {
        this.errors = error.error.errors;
      }
    );
  }

  onUploadStart() {
    this.errors = [];
  }

  public get hasError() {
    return !isEmpty(this.errors);
  }
}
