<dl class="row" *ngIf="monthRange">
  <dt class="col-sm-1">{{ label }}:</dt>
  <dd class="col-sm-11">
    <ng-container *ngIf="monthRange.fromMonth && monthRange.toMonth">
      {{ monthRange.fromMonth }}/{{ monthRange.fromYear }} to {{ monthRange.toMonth }}/{{ monthRange.toYear }}
    </ng-container>

    <ng-container *ngIf="monthRange.fromMonth && !monthRange.toMonth">
      Starting {{ monthRange.fromMonth }}/{{ monthRange.fromYear }}
    </ng-container>

    <ng-container *ngIf="!monthRange.fromMonth && monthRange.toMonth">
      Ending {{ monthRange.toMonth }}/{{ monthRange.toYear }}
    </ng-container>
  </dd>
</dl>
