import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Note } from 'app/core/models/note.model';
import { NotesService } from 'app/core/services/notes.service';

@Component({
  selector: 'app-notes-for-model-add',
  templateUrl: './notes-for-model-add.component.html',
  styleUrls: ['./notes-for-model-add.component.scss'],
})
export class NotesForModelAddComponent implements OnInit {
  @Input() model: any;
  @Input() modelType: string;
  @Input() minimizeControls = false;

  @Output() noteAdded = new EventEmitter<Note>();

  adding = false;
  newNote: FormControl;

  constructor(
    private notesService: NotesService,
  ) {}

  ngOnInit() {
    this.initFormControl();
  }

  onAdd($event: MouseEvent) {
    $event.preventDefault();
    this.adding = true;
  }

  onCancelAdd($event: MouseEvent) {
    $event.preventDefault();
    this.adding = false;
    this.initFormControl();
  }

  onSubmit() {
    this.notesService.create(this.model.id, this.modelType, this.newNote.value).subscribe(
      note => this.handleSuccess(note)
    );
  }

  private handleSuccess(note: Note) {
    this.adding = false;
    this.noteAdded.emit(note);
    this.initFormControl();
  }

  private initFormControl() {
    this.newNote = new FormControl('');
  }
}