import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { ClientChoreStatus } from 'app/core/enums/client-chore-status.enum';
import { AvailableClientChore } from 'app/core/models/available-client-chore.model';
import { Client } from 'app/core/models/client.model';
import { MinimalClient } from 'app/core/models/minimal-client.model';
import { ClientChoreFilters } from 'app/core/models/user-settings/client-chore-settings.model';
import { User } from 'app/core/models/user.model';
import { UserType } from 'app/core/enums/user-type.enum';
import { ClientChoresService } from 'app/core/services/client-chores.service';
import { ClientsService } from 'app/core/services/clients.service';
import { sortBy } from 'lodash-es';

class ClientChoreStatusOption {
  value: string;
  display: string
}

class ClientUserSubmittedByOption {
  fullName: string;
  id: string;
}

@Component({
  selector: 'app-client-chore-filters',
  templateUrl: './client-chore-filters.component.html',
  styleUrls: ['./client-chore-filters.component.scss'],
})
export class ClientChoreFiltersComponent implements OnInit {
  @Input() filters: ClientChoreFilters = new ClientChoreFilters();
  @Output() filterChange = new EventEmitter<ClientChoreFilters>();

  @Input() clients: (Client | MinimalClient)[];
  @Input() statuses: ClientChoreStatus[];
  @Input() showSubmittedByFilter = true;

  submittedByOptions: (User | ClientUserSubmittedByOption)[] = [];
  submittedBySelectedIds: (number | string)[] = [];
  clientUserIds: number[] = [];
  availableChores: AvailableClientChore[];
  statusOptions: ClientChoreStatusOption[];

  constructor(
    private clientChoresService: ClientChoresService,
    private clientsService: ClientsService,
  ) { }

  ngOnInit() {
    this.loadClients();
    this.loadUsers();
    this.loadAvailableChores();
    this.mapStatusOptions();
  }

  private loadClients() {
    if (!this.clients) {
      this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
        this.clients = result.clients;
      });
    }
  }

  private loadUsers() {
    this.clientChoresService.getSubmittingUsers().subscribe(result => {
      this.partitionUsers(result)
      this.submittedByOptions = sortBy(this.submittedByOptions, user => user.fullName.toLowerCase());
    })
  }

  private partitionUsers(users: User[]) {
    users.forEach(user => {
      if (user.userType === UserType.captureAdmin) {
        this.submittedByOptions.push(user);
      } else {
        this.clientUserIds.push(user.id);
      }
    });
    if (this.clientUserIds.length > 0) {
      this.submittedByOptions.push(
        { fullName: "Client User", id: 'client_user' }
      )
    }
  }

  private mapStatusOptions() {
    const titleCasePipe = new TitleCasePipe();
    this.statusOptions =
      this.statuses.map(v => ({ value: v, display: titleCasePipe.transform(v) }));
  }

  private loadAvailableChores() {
    this.clientChoresService.getAvailableChores().subscribe(result => {
      this.availableChores = sortBy(result.availableClientChores, availableChore => availableChore.displayName);
    });
  }

  onSubmittedByFilterChange() {
    const clientUserSelected = this.submittedBySelectedIds.includes('client_user');

    this.filters.selectedUserIds = clientUserSelected
      ? [
        // Replace "Client User" with actual user IDs for filtering
        ...this.submittedBySelectedIds.filter(id => id !== 'client_user'),
        ...this.clientUserIds
      ]
      : this.submittedBySelectedIds;

    this.filterChange.emit(this.filters);
  }

  onFilterChange() {
    this.filterChange.emit(this.filters);
  }
}
