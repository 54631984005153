import { Component, Input } from '@angular/core';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { keyedStatusOptions as keyedOfficeStatusOptions } from 'app/core/options/office-status.opts';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';

@Component({
  selector: 'app-provider-office-list',
  templateUrl: './provider-office-list.component.html',
  styleUrls: ['./provider-office-list.component.scss'],
})
export class ProviderOfficeListComponent {
  @Input() providerOffices: ProviderOffice[];
  @Input() loading: boolean;

  keyedOfficeStatusOptions = keyedOfficeStatusOptions;
  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;
}
