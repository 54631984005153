import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { IccClaimImport } from '../../models/icc/icc-claim-import.model';
import { IccImportFilters } from '../../models/icc/icc-import-settings.model';

@Injectable()
export class IccClaimImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<IccClaimImport> {
    return this.http.get<IccClaimImport>(`${environment.captureApi.url}/icc/claim_imports/${id}`);
  }

  getList(filters: IccImportFilters = null): Observable<{ claimImports: IccClaimImport[] }> {
    const params = new URLSearchParams();

    if (filters && filters.selectedClientIds) {
      filters.selectedClientIds.forEach(clientId => params.append('client_ids[]', clientId));
    }

    return this.http.get<{ claimImports: IccClaimImport[] }>(
      `${environment.captureApi.url}/icc/claim_imports?${params.toString()}`
    );
  }

  create(clientId: number, thirdPartyAdministerId: number, name: string, signedId: string): Observable<IccClaimImport> {
    return this.http.post<IccClaimImport>(`${environment.captureApi.url}/icc/claim_imports`, {
      claimImport: { clientId, thirdPartyAdministerId, name, signedId },
    });
  }

  update(id, name): Observable<IccClaimImport> {
    return this.http.put<IccClaimImport>(`${environment.captureApi.url}/icc/claim_imports/${id}`, {
      claimImport: { name },
    });
  }

  cancel(id): Observable<IccClaimImport> {
    return this.http.patch<IccClaimImport>(`${environment.captureApi.url}/icc/claim_imports/${id}/cancel`, {});
  }
}
