import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './capture-admin-specialty-store-claim-patient-match-reports.component.html',
})
export class CaptureAdminSpecialtyStoreClaimPatientMatchReportsComponent {
  reportsUrl = `${environment.captureApi.url}/specialty_store_claim_patient_match_reports`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    createdDate: [null],
  });
}
