import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ConfirmNewUserComponent } from './pages/confirm-new-user/confirm-new-user.component';
import { ConfirmPasswordResetComponent } from './pages/confirm-password-reset/confirm-password-reset.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { AuthPaths } from './enums/auth-paths';
import { ConfirmForcePasswordChangeComponent } from './pages/confirm-force-password-change/confirm-force-password-change.component';
import { ConfirmMfaComponent } from './pages/confirm-mfa/confirm-mfa.component';
import { SamlComponent } from './pages/saml/saml.component';
import { AuthGuard } from './guards/auth.guard';

const authGuard = (route: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(route);

const routes: Routes = [
  {
    path: AuthPaths.login,
    component: LoginComponent,
    canActivate: [authGuard],
  },
  {
    path: AuthPaths.confirmNewUser,
    component: ConfirmNewUserComponent,
    canActivate: [authGuard],
  },
  {
    path: AuthPaths.confirmPasswordReset,
    component: ConfirmPasswordResetComponent,
    canActivate: [authGuard],
  },
  {
    path: AuthPaths.confirmForcePasswordChange,
    component: ConfirmForcePasswordChangeComponent,
    canActivate: [authGuard],
  },
  {
    path: AuthPaths.confirmMfa,
    component: ConfirmMfaComponent,
    canActivate: [authGuard],
  },
  {
    path: AuthPaths.forgotPassword,
    component: ForgotPasswordComponent,
    canActivate: [authGuard],
  },
  {
    path: AuthPaths.saml,
    component: SamlComponent,
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
