import { Component, Input, OnInit } from '@angular/core';
import { Prescriber } from 'app/core/models/prescriber.model';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { ProviderService } from 'app/core/services/provider.service';
import { OutreachAttemptEvidence } from 'app/core/models/outreach-attempt-evidence.model';
import { keyedStatusOptions as keyedOfficeStatusOptions } from 'app/core/options/office-status.opts';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';

@Component({
  selector: 'app-outreach-attempt-evidence',
  templateUrl: './outreach-attempt-evidence.component.html',
  styleUrls: ['./outreach-attempt-evidence.component.scss'],
})
export class OutreachAttemptEvidenceComponent implements OnInit {
  @Input() outreachAttemptEvidence: OutreachAttemptEvidence;
  @Input() prescriber: Prescriber;
  @Input() patientPrescriberId: number;

  loading = true;
  formValid = false;

  selectedProviderOffices: ProviderOffice[] = [];

  keyedOfficeStatusOptions = keyedOfficeStatusOptions;
  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;

  constructor(
    private provideerService: ProviderService,
  ) { }

  ngOnInit() {
    this.provideerService.getProviderOffices(this.prescriber.providerId).
      subscribe(providerOffices => {
        this.selectedProviderOffices = providerOffices.filter(providerOffice =>
          this.outreachAttemptEvidence.officeIds.includes(providerOffice.office.id)
        );

        this.loading = false;
      });
  }

}
