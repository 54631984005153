export enum ProviderCallLogOutcome {
  faxNumberConfirmed = 'Fax Number Confirmed',
  noAnswer = 'No Answer',
  leftVoicemail = 'Left Voicemail',
  leftLiveMessage = 'Left Live Message',
  providerNotAtPractice = 'Provider not at Practice',
  patientNotAtPractice = 'Patient not at practice',
  practiceRequiresRelease = 'Practice Requires Release',
  recordsPending = 'Records Pending',
  ceHasConsultNoteCeReview = 'CE Has Consult Note/CE Review',
  badNumber = 'Bad Number',
  providerConfirmed = 'Provider Confirmed',
  recordsSent = 'Records Sent',
  pendingCallBackOrReturnEmail = 'Pending Call Back or Return Email',
  hangUp = 'Hang Up',
  verifiedFaxNumber = 'Verified Fax Number',
  other = 'Other'
}
