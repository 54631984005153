import { Component, Input } from '@angular/core';
import { ListProviderOffice } from 'app/core/models/list-provider-office.model';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
})
export class ProviderComponent {
  @Input()
  provider: {
    npi: string;
    name: string;
    specialty: string;
    relevantProviderOffice: ListProviderOffice;
  };
  @Input() showFaxStatus = false;

  constructor() {}

  onNoteClick($event) {
    $event.preventDefault();
  }
}
