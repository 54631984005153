import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { Capture } from '../../../core/models/capture.model';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { sortBy } from 'lodash-es';

class PatientAttachmentOption {
  constructor(
    public id: number,
    public patientAttachment: PatientAttachment,
    public markedAsInvalid: boolean,
    public verified: boolean
  ) { }
}

@Component({
  selector: 'app-patient-document-selector',
  templateUrl: './patient-document-selector.component.html',
  styleUrls: ['./patient-document-selector.component.scss'],
})
export class PatientDocumentSelectorComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() selectedAttachment: PatientAttachment;
  @Input() allPatientAttachments: PatientAttachment[];

  @Output() selectionChange = new EventEmitter<number>();

  selectedAttachmentId: number = null;
  patientAttachmentOptions: PatientAttachmentOption[] = [];

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'selectedAttachment' && this.selectedAttachment) {
        const attachmentIsAvailableForSelection =
          this.allPatientAttachments.find(attachment => attachment.id == this.selectedAttachment.id);

        if (attachmentIsAvailableForSelection) {
          this.selectedAttachmentId = this.selectedAttachment.id;
        }
      }

      if (inputName === 'allPatientAttachments') {
        this.initPatientAttachmentOptions();
      }

      if (inputName === 'capture') {
        this.refreshPatientAttachmentOptions();
      }
    });
  }

  onSelectionChange() {
    this.selectionChange.emit(this.selectedAttachmentId);
  }

  private initPatientAttachmentOptions() {
    this.patientAttachmentOptions =
      sortBy(this.allPatientAttachments, 'markedAsInvalid').
        map((pa: PatientAttachment) => new PatientAttachmentOption(
          pa.id,
          pa,
          pa.markedAsInvalid,
          pa.id === this.capture.verifyingPatientAttachmentId
        ));
  }

  private refreshPatientAttachmentOptions() {
    this.patientAttachmentOptions.forEach((pao: PatientAttachmentOption) => {
      pao.verified = pao.id === this.capture.verifyingPatientAttachmentId;
    });
  }
}
