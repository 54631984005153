<div *ngIf="approvalType; else: noNameMatchApprovals">
  Tips
  <br/>
  <span class="material-icons-round">lightbulb</span>
  Say the names out loud. Do they sound the same even if the spelling is slightly different?
  <br/>
  <span class="material-icons-round">lightbulb</span>
  Could this be a typo? Is a letter position switched or missing?
  <div *ngIf="isFirstType">
    <span class="material-icons-round">lightbulb</span>
    Could this name be a nickname for the other name? e.g. Robert and Bob
  </div>
  <br/>
  <br/>
  <p>Would you consider the <strong>{{ approvalType.toUpperCase() }}</strong> name pair below a match?</p>
  <div class="row">
    <div class="col-2">
      <p>
        <strong>{{ matchName }}</strong>
      </p>
      <p>
        <strong>{{ matchName2 }}</strong>
      </p>
    </div>
  </div>
  <div class="actions row mt-4">
    <div class="col-2">
      <button type="button" class="btn btn-danger" (click)="onRejectClick()">REJECT</button>
      <small class="form-text text-muted"> <strong>NO</strong> - they don't match </small>
    </div>
    <div class="col-2">
      <button type="button" class="btn btn-primary" (click)="onApproveClick()">APPROVE</button>
      <small class="form-text text-muted"> <strong>YES</strong> - they match </small>
    </div>
  </div>
</div>
<ng-template #noNameMatchApprovals>
  <div>
    <p>No name approvals are required for this patient. If the DOBs match, this capture may currently be processing.</p>
    <p>If this message persists after a refresh, please reprocess the capture.</p>
  </div>
</ng-template>
