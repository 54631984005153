import { ClientReferralTaskChoice } from '../enums/client-referral-task-choice.enum';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';
import { ClientTaskRejectionReason } from '../enums/client-task-rejection-reason.enum';

export class ClientReferralTaskChoiceOption {
  constructor(
    public value: ClientReferralTaskChoice,
    public label: string,
    public helpText: string,
    public reasons: Array<ReferralVerificationReason | ClientTaskRejectionReason> = []
  ) { }
}

const choiceOptions = [
  new ClientReferralTaskChoiceOption(
    ClientReferralTaskChoice.yes,
    'Yes, there is supporting documentation in the EHR',
    'Select <b>ALL</b> that apply',
    [
      ReferralVerificationReason.prescriberReferral,
      ReferralVerificationReason.prescriberOfficeReferral,
      ReferralVerificationReason.specialityReferral,
      ReferralVerificationReason.other,
    ]
  ),
  new ClientReferralTaskChoiceOption(
    ClientReferralTaskChoice.notYet,
    'Not yet, but a qualifying referral or other supporting documentation may be entered in the future',
    'This task will be removed from your task list and presented again in 6 months if a referral has still not been identified by the system.'
  ),
  new ClientReferralTaskChoiceOption(
    ClientReferralTaskChoice.no,
    'No, and there is no possibility of a qualifying referral or other supporting documentation being entered in the future',
    'All prescriptions currently listed on this task will be rejected and this task will be closed. If future prescriptions come in for this patient and prescriber, this task will reopen',
    [
      ClientTaskRejectionReason.ignorePatient,
      ClientTaskRejectionReason.ignorePrescriber,
      ClientTaskRejectionReason.ignorePatientPrescriber,
    ]
  ),
  new ClientReferralTaskChoiceOption(
    ClientReferralTaskChoice.prescriberIsCeProvider,
    'Prescriber is a client provider',
    'This prescriber has encounters in the client EHR and the med is listed on the patient\'s med list. Selecting this option will close this task and create a Client Prescriber Check task'
  ),
];

export { choiceOptions };
