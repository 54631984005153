<h3>Client Referral Tasks</h3>
<app-client-tasks-filter
  [filters]="filters"
  (filtersChange)="filtersChange($event)"
  [loading]="loading"
  [exportUrl]="exportUrl"
  [enableExport]="tasks.length > 0"
  [taskStatuses]="taskStatuses"
  [specialties]="specialties"
  [manufacturers]="manufacturers"
  [sources]="sources"
  [clients]="clients"
  [allowTaskIdSearch]="true"
  />
<div *ngIf="!loading; else: spinner">
  <div *ngIf="tasks.length > 0; else: noTasks">
    <app-client-referral-task-list
      [tasks]="tasks"
      [paging]="paging"
      (pageChange)="onPageChange($event)"
      (pageSizeChange)="onPageSizeChange($event)"
    />
  </div>
</div>
<ng-template #noTasks>
  <section><p>There are no tasks.</p></section>
</ng-template>
<ng-template #spinner> <mat-spinner diameter="30" /> </ng-template>
