<app-capture-history-item-title>
  Capture Record Created
</app-capture-history-item-title>

<app-capture-history-item-body>
  <span *ngIf="captureSnapshot.processing; else: snapshotLink"> Processing... </span>
  <ng-template #snapshotLink>
    <a *ngIf="captureSnapshot.url; else: snapshotStatus" [href]="captureSnapshot.url" target="_blank">
      {{ captureSnapshot.filename }}
    </a>
  </ng-template>
  <ng-template #snapshotStatus>
    <span> {{ captureSnapshot.status | titlecase }} </span>
  </ng-template>
</app-capture-history-item-body>
