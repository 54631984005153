import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { keyedPatientAttachmentStatusOptions } from '../../../core/options/patient-attachment-status.opts';

@Component({
  selector: 'app-consult-note-header',
  templateUrl: './consult-note-header.component.html',
  styleUrls: ['./consult-note-header.component.scss'],
})
export class ConsultNoteHeaderComponent implements OnInit {
  @Input() patientAttachment: PatientAttachment;

  statusOptions = keyedPatientAttachmentStatusOptions;

  constructor() {}

  ngOnInit() {}
}
