import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { UserTimingService } from 'app/core/services/user-timing.service';
import { PatientAttachmentHistoryItemActions } from 'app/core/enums/patient-attachment-history-item-actions.enum';
import { PatientAttachmentHistoryItem } from 'app/core/models/patient-attachment-history-item.model';
import { UserPresenceService } from 'app/core/services/user-presence.service';

@Component({
  selector: 'app-consult-note-history',
  templateUrl: './consult-note-history.component.html',
  styleUrls: ['./consult-note-history.component.scss'],
})
export class ConsultNoteHistoryComponent implements OnInit, OnDestroy {
  patientAttachmentId: string;
  patientAttachment: PatientAttachment;
  refreshing = true;

  loadingHistoryItems = false;
  patientAttachmentHistoryItems: PatientAttachmentHistoryItem[] = [];
  patientAttachmentHistoryItemActions = PatientAttachmentHistoryItemActions;

  constructor(
    private route: ActivatedRoute,
    private patientAttachmentsService: PatientAttachmentsService,
    private navigationService: NavigationService,
    private titleService: Title,
    private authService: AuthenticationService,
    private userTimingService: UserTimingService,
    private router: Router,
    private userPresenceService: UserPresenceService
  ) {
    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  ngOnInit() {
    this.patientAttachmentId = this.route.snapshot.paramMap.get('id');
    this.loadPatientAttachment(this.patientAttachmentId);
    this.loadHistoryItems(this.patientAttachmentId);
    this.userPresenceService.subscribeAndJoin("consultNote", this.patientAttachmentId)
  }

  ngOnDestroy() {
    this.stopTimeTracking();
    this.userPresenceService.leaveAndUnsubscribe("consultNote", this.patientAttachmentId)
  }

  private loadPatientAttachment(patientAttachmentId): void {
    this.refreshing = true;
    this.patientAttachmentsService.get(patientAttachmentId).subscribe(
      (patientAttachment: PatientAttachment) => {
        this.refreshing = false;
        this.patientAttachment = patientAttachment;
        this.startTimeTracking(this.patientAttachment);
        this.setTitle();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.navigationService.notFound();
        } else {
          console.error(err);
        }
      }
    );

    window.scrollTo(0, 0);
  }

  private loadHistoryItems(patientAttachmentId): void {
    this.loadingHistoryItems = true;
    this.patientAttachmentsService.getPatientAttachmentHistoryItems(patientAttachmentId).subscribe(
      patientAttachmentHistoryItems => {
        this.loadingHistoryItems = false;
        this.patientAttachmentHistoryItems = patientAttachmentHistoryItems.reverse();
      },
      err => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
  }

  private setTitle() {
    const title = `R1 340B Recovery - ${this.patientAttachment.id} | History`;
    this.titleService.setTitle(title);
  }

  private handleRouteChange(paramsMap) {
    const params = paramsMap['params'];
    const patientAttachmentId = params && params['id'];

    if (this.navigationService.shouldReloadPage(this.patientAttachment, patientAttachmentId)) {
      this.loadPatientAttachment(patientAttachmentId);
    }
  }

  private startTimeTracking(patientAttachment) {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.track({
        page: 'consult_note_prescriptions',
        recordId: patientAttachment.id,
        status: patientAttachment.status,
      });
    }
  }

  private stopTimeTracking() {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.stop();
    }
  }
}
