import { Component, OnInit, Input, HostBinding, Host, SkipSelf } from '@angular/core';
import { NgForm, FormGroupDirective, FormGroup, ControlContainer, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-form-control-server-errors',
  templateUrl: './form-control-server-errors.component.html',
  styleUrls: ['./form-control-server-errors.component.scss'],
})
export class FormControlServerErrorsComponent implements OnInit {
  @Input() form?: FormGroupDirective | NgForm;

  @Input() controlName: string;

  formControl: FormControl;

  @HostBinding('class') classes = 'invalid-feedback';

  constructor(
    @Host() @SkipSelf() private parentContainer: ControlContainer,
  ) { }

  ngOnInit() {
    if (!this.form) {
      const parentControl = this.parentContainer.control as FormGroup | FormArray;
      this.formControl = parentControl.controls[this.controlName];
    }
  }

  controlErrors(): string[] {
    let formControl: FormControl;

    if (this.form) {
      const formGroup = this.form.form;
      formControl = formGroup.controls[this.controlName] as FormControl;
    } else {
      formControl = this.formControl;
    }

    if (formControl && formControl.errors) {
      return formControl.errors.server;
    }
  }
}
