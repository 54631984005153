import { Component, OnInit } from '@angular/core';
import { CreatePasswordMode } from '../../components/create-password-form/create-password-form.component';

@Component({
  selector: 'app-confirm-force-password-change',
  templateUrl: './confirm-force-password-change.component.html',
  styleUrls: ['./confirm-force-password-change.component.scss'],
})
export class ConfirmForcePasswordChangeComponent implements OnInit {
  createPasswordModes = CreatePasswordMode;

  constructor() {}

  ngOnInit() {}
}
