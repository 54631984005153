import { Component, Input, OnInit } from '@angular/core';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';
import { ClientConsultNoteTaskStatus } from '../../../core/enums/client-consult-note-task-status.enum';

@Component({
  selector: 'app-client-consult-note-task-header',
  templateUrl: './client-consult-note-task-header.component.html',
  styleUrls: ['./client-consult-note-task-header.component.scss'],
})
export class ClientConsultNoteTaskHeaderComponent implements OnInit {
  @Input() task: ClientConsultNoteTask;
  @Input() displayUserPresence = true;

  consultNoteTaskStatuses = ClientConsultNoteTaskStatus;

  constructor() {}

  ngOnInit() {}
}
