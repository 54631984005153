<div class="mb-3">
  <div class="expand-collapse" *ngIf="!expanded" (click)="onExpandOrCollapseClick(true)">
    <mat-icon class="icon">chevron_right</mat-icon>
    <strong>Expand for Location Eligibility Details</strong>
  </div>

  <div class="expand-collapse" *ngIf="expanded" (click)="onExpandOrCollapseClick(false)">
    <mat-icon class="icon">expand_more</mat-icon>
    <strong>Location Eligibility Details</strong>
  </div>

  <div *ngIf="expanded" class="content">
    <p *ngIf="forClient">
      The locations listed below are sourced from the EHR feeds we’ve set up with your organization.
    </p>

    <p>
      If a location is <strong class="emphasized">marked eligible</strong>, then for the duration of its eligibility period(s):
    </p>

    <ul>
      <li>Encounters from this location <strong>will be considered</strong> to satisfy patient care ownership</li>
      <li>Referrals from this location <strong>will be considered</strong> as valid referral evidence</li>
    </ul>

    <p>
      If a location is <strong class="emphasized">NEW, not marked eligible, or during periods outside of a location’s
      eligibility timeframe</strong>, encounters and referrals from the location will not be used in the workflow to satisfy
      patient care ownership or as referral evidence.
    </p>

    <p>
      If an existing location’s eligibility changes, please update its eligibility status and indicate the date when that change occurred.
    </p>
  </div>
</div>

