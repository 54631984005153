import { Component } from '@angular/core';
import { ConsultNoteHistoryItemTypeBaseComponent } from '../consult-note-history-item-type-base/consult-note-history-item-type-base.component';

@Component({
  selector: 'app-consult-note-history-item-type-assignment-new',
  templateUrl: '../consult-note-history-item-type-base/consult-note-history-item-type-base.component.html',
})
export class ConsultNoteHistoryItemTypeAssignmentNewComponent extends ConsultNoteHistoryItemTypeBaseComponent {
  get title() {
    return 'Assigned';
  }

  get description() {
    return `Consult Note assigned to ${this.getAssignedTo()}`;
  }

  private getAssignedTo() {
    return this.patientAttachmentHistoryItem.data.assignedToFullname;
  }
}
