import { Component, OnInit } from '@angular/core';
import { LimitOffsetPaging, Sort } from '../../../core/models/paged-results/limit-offset-paging.model';
import { User } from '../../../core/models/user.model';
import { PagedUsers, UsersService } from '../../../core/services/users.service';
import { ActivatedRoute } from '@angular/router';
import { UserSettings } from '../../../core/enums/user-settings.enum';
import { UserListFilters } from '../../../core/models/user-settings/user-management-settings.model';
import { UserSettingsService } from '../../../core/services/user-settings.service';

@Component({
  selector: 'app-capture-admin-users',
  templateUrl: './capture-admin-users.component.html',
  styleUrls: ['./capture-admin-users.component.scss'],
})
export class CaptureAdminUsersComponent implements OnInit {
  filters: UserListFilters = new UserListFilters();
  sort: Sort;
  paging: LimitOffsetPaging;
  users: User[] = [];
  refreshing = false;

  constructor(
    private usersService: UsersService,
    private userSettingsService: UserSettingsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.loadUsers();
  }

  onPageChange(page) {
    this.paging.currentPage = page;
    this.loadUsers();
  }

  onSortChange(sort: Sort) {
    this.sort = sort;
    this.loadUsers();
  }

  onFiltersChange(filters: UserListFilters) {
    this.filters = filters;
    this.saveFilters();
    this.loadUsers();
  }

  onUserChange(user: User) {
    const index = this.users.findIndex(u => u.id === user.id);
    if (index > -1) {
      this.users[index] = user;
    }
  }

  private init() {
    this.sort = Sort.fromQueryParams(this.route.snapshot.queryParams, { col: 'fullName', dir: 'asc' });
    this.paging = LimitOffsetPaging.fromQueryParams(this.route.snapshot.queryParams, { pageSize: 100 });
    this.loadFilters();
    this.loadUsers();
  }

  private loadUsers() {
    this.refreshing = true;

    const { currentPage, pageSize } = this.paging;

    this.usersService.getList(currentPage, pageSize, this.sort, this.filters).subscribe((pageUsers: PagedUsers) => {
      this.paging = pageUsers.meta.paging;
      this.users = pageUsers.users || [];
      this.refreshing = false;
    });
  }

  private loadFilters() {
    const filters = this.userSettingsService.get<UserListFilters>(UserSettings.captureAdminUserListFilters);

    if (filters) {
      this.filters = { ...filters };
    }
  }

  private saveFilters() {
    const newFilters = { ...this.filters };
    this.userSettingsService.save<UserListFilters>(UserSettings.captureAdminUserListFilters, newFilters);
  }
}
