import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OutboundFax } from '../../../core/models/outbound-fax.model';
import { OutboundFaxesResult, OutboundFaxesService } from '../../../core/services/outbound-faxes.service';
import { OutboundFaxStatus } from '../../../core/enums/outbound-fax-status.enum';
import { InboundFaxStatus } from '../../../core/enums/inbound-fax-status.enum';
import { sortBy } from 'lodash-es';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';

@Component({
  selector: 'app-outbound-fax-selector',
  templateUrl: './outbound-fax-selector.component.html',
  styleUrls: ['./outbound-fax-selector.component.scss'],
})
export class OutboundFaxSelectorComponent implements OnInit {
  hasQueried = false;
  hasResults = false;
  refreshing = false;
  outboundFaxes: OutboundFax[] = [];
  inboundFaxStatuses = InboundFaxStatus;
  outboundFaxStatuses = OutboundFaxStatus;
  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;

  @Output() public selectionChange = new EventEmitter<OutboundFax>();

  constructor(private outboundFaxesService: OutboundFaxesService) {}

  ngOnInit() {}

  onOutboundFaxChange(outboundFax: OutboundFax) {
    this.selectionChange.emit(outboundFax);
  }

  onSearch(claimIdentifier: string) {
    this.refreshing = true;
    this.outboundFaxesService.getList(claimIdentifier).subscribe((result: OutboundFaxesResult) => {
      this.refreshing = false;
      this.hasQueried = true;
      this.hasResults = result.outboundFaxes && result.outboundFaxes.length > 0;
      this.outboundFaxes = result.outboundFaxes;
    });
  }

  mostRecentInboundFax(outboundFax: OutboundFax) {
    return sortBy(outboundFax.inboundFaxes, [inboundFax => new Date(inboundFax.fileCreatedAt)]).reverse()[0];
  }
}
