import { Component, Input, OnInit } from '@angular/core';
import { Encounter } from 'app/core/models/encounter.model';
import { Referral } from 'app/core/models/referral.model';
import { Patient } from 'app/core/models/patient.model';
import { PatientService } from 'app/core/services/patient.service';

@Component({
  selector: 'app-client-task-encounters',
  templateUrl: './client-task-encounters.component.html',
  styleUrls: ['./client-task-encounters.component.scss'],
})
export class ClientTaskEncountersComponent implements OnInit {
  _patient: Patient;
  patientId: number = null;

  eligibleEncounters: Encounter[] = [];
  ineligibleEncounters: Encounter[] = [];
  currentTab = 'eligibleEncounters';
  showIneligibleEncounters = false;

  loadingEncounters = true;

  constructor(private patientService: PatientService) {}

  ngOnInit() {
    this.init();
  }

  get patient(): Patient {
    return this._patient;
  }

  @Input()
  set patient(patient: Patient) {
    this._patient = patient;

    if (this.patientId !== patient.id) {
      this.patientId = patient.id;
      this.loadEncounters();
    }
  }

  private init() {
    this.loadEncounters();
  }

  private loadEncounters() {
    this.loadingEncounters = true;

    this.patientService.getEncounters(this.patient.id).subscribe(result => {
      this.loadingEncounters = false;

      if (result.meta.eligibilityBasedOnLocation) {
        this.showIneligibleEncounters = true;

        this.eligibleEncounters =
          result.encounters.filter(encounter => encounter.locationEligibleDuringServiceDate);

        this.ineligibleEncounters =
          result.encounters.filter(encounter => !encounter.locationEligibleDuringServiceDate);
      } else {
        this.showIneligibleEncounters = false;
        this.eligibleEncounters = result.encounters;
      }
    });
  }

  tabClick($event, tabName) {
    $event.preventDefault();
    this.currentTab = tabName;
  }
}
