import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-consult-note-nav',
  templateUrl: './consult-note-nav.component.html',
  styleUrls: ['./consult-note-nav.component.scss'],
})
export class ConsultNoteNavComponent implements OnInit {
  @Input() currentSection: string;
  @Input() patientAttachment: PatientAttachment;
  @Input() canAdmin = false;
  @Output() sectionClick = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  onNavClick($event, sectionId) {
    $event.preventDefault();
    this.sectionClick.emit(sectionId);
  }
}
