import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Base64 } from 'js-base64';
import { CaptureQueueCursorType } from 'app/core/enums/capture-queue-cursor-type.enum';
import { CaptureQueueResult } from 'app/core/models/capture-lists/capture-queue-result.model';
import { Cursor } from 'app/core/models/paged-results/cursor-based-paging.model';
import { Patient } from 'app/core/models/patient.model';
import { PatientCapturesSearchFilter, PatientCapturesSearchSettings } from 'app/core/models/user-settings/patient-captures-search-settings.model';
import { NavigationService, PageChangeEvent } from 'app/core/services/navigation.service';
import { PagedCaptureResults, PatientCapturesService } from 'app/core/services/patient-captures.service';
import { PatientService } from 'app/core/services/patient.service';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-capture-admin-patient-captures',
  templateUrl: './capture-admin-patient-captures.component.html',
  styleUrls: ['./capture-admin-patient-captures.component.scss'],
})
export class CaptureAdminPatientCapturesComponent implements OnInit {
  getCaptures$: Subject<void> = new Subject();

  patient: Patient;
  loading: boolean;

  settings: PatientCapturesSearchSettings;
  captureQueueResults: CaptureQueueResult[] = [];
  cursor: Cursor<CaptureQueueCursorType>;
  loadingCaptures = true;
  hasMoreCaptures = false;
  limit = 10;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private patientCapturesService: PatientCapturesService,
    private navigationService: NavigationService,
  ) {
    this.navigationService.pageChanged.subscribe((pageChangeEvent: PageChangeEvent) => {
      if (pageChangeEvent.navigatedBack && !this.loading) {
        this.initCursorFromParams();
        this.getCaptures$.next();
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  onSettingsChange(settings: PatientCapturesSearchSettings) {
    this.settings = settings;
    this.resetCursor();
    this.getCaptures();
  }

  onNextClick($event) {
    $event.preventDefault();
    this.updateQueryParams();
    this.getCaptures();
    window.scrollTo(0, 0);
  }

  private init() {
    const patientId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.loading = true;
    this.patientService.get(patientId).subscribe(patient => {
      this.patient = patient;
      this.loading = false;

      this.resetCursor();
      this.initSettings();
      this.configureGetCaptures();
      this.getCaptures();
    });
  }

  private initSettings() {
    this.settings = new PatientCapturesSearchSettings(
      new PatientCapturesSearchFilter(),
      CaptureQueueCursorType.highestEstimatedValue
    );
  }

  private getCaptures() {
    this.getCaptures$.next();
  }

  private configureGetCaptures() {
    // https://www.credera.com/blog/technology-solutions/using-rxjs-switchmap-angular-7-reactive-forms-cancel-pending-requests/
    this.getCaptures$
      .pipe(
        tap(() => {
          this.loadingCaptures = true;
          this.captureQueueResults = [];
        }),
        switchMap(() => this.fetchCaptures())
      )
      .subscribe((results: PagedCaptureResults) => {
        this.captureQueueResults = results.captureQueueResults;
        this.cursor = results.meta.cursor;
        this.loadingCaptures = false;
        this.hasMoreCaptures = !!results.meta.cursor;
      });
  }

  private fetchCaptures() {
    return this.patientCapturesService.getCaptures(
      this.patient.id, this.cursor, this.settings, this.limit
    );
  }

  private resetCursor() {
    this.cursor = null;
    this.updateQueryParams();
  }

  private updateQueryParams() {
    if (this.cursor) {
      const encodedCursor = encodeURIComponent(Base64.encode(JSON.stringify(this.cursor)));
      this.navigationService.updateQueryParams({ cursor: encodedCursor });
    } else {
      this.navigationService.updateQueryParams({}, true);
    }
  }

  private initCursorFromParams() {
    const queryParams = this.navigationService.queryParams;

    if ('cursor' in queryParams) {
      this.cursor = this.decodeCursor(queryParams);
    } else {
      this.cursor = null;
    }
  }

  private decodeCursor(queryParams) {
    try {
      const cursorParam = decodeURIComponent(queryParams['cursor']);
      return JSON.parse(Base64.decode(cursorParam));
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}