<nav class="nav nav-pills">
  <a
    class="nav-item nav-link"
    href="#"
    (click)="navClick($event, 'officeDetails')"
    [class.active]="currentSection === 'officeDetails'"
  >
    Office Details
  </a>
  <a
    class="nav-item nav-link"
    href="#"
    (click)="navClick($event, 'communications')"
    [class.active]="currentSection === 'communications'"
  >
    Communications
  </a>
</nav>
