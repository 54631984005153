import { Component, Input, OnInit } from '@angular/core';
import { PatientPrescriberNote } from 'app/core/models/patient-prescriber-note.model';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { PatientPrescriberService } from 'app/core/services/patient-prescriber.service';

@Component({
  selector: 'app-patient-prescriber-active-note',
  templateUrl: './patient-prescriber-active-note.component.html',
  styleUrls: ['./patient-prescriber-active-note.component.scss'],
})
export class PatientPrescriberActiveNoteComponent implements OnInit {
  @Input() patientPrescriberId: number;
  note: PatientPrescriberNote;

  isClientPortal = false;

  constructor(
    private patientPrescriberService: PatientPrescriberService,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.patientPrescriberService.note(this.patientPrescriberId).subscribe(note => { this.note = note });
    this.isClientPortal = this.authService.isClientUser;
  }

  public get header() {
    return this.isClientPortal ? 'NOTES FOR TASK FROM R1' : 'NOTES FOR REFERRAL AND CONSULT NOTE TASKS';
  }
}
