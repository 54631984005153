import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PatientSearchQuery } from '../../../core/models/patient-search-query.model';
import { DateUtils } from 'app/core/lib/date-utils';

@Component({
  selector: 'app-patient-search-form',
  templateUrl: './patient-search-form.component.html',
  styleUrls: ['./patient-search-form.component.scss'],
})
export class PatientSearchFormComponent implements OnInit, AfterViewInit {
  patientSearchQuery: PatientSearchQuery;
  dobMinDate: string;

  @Input() formDisabled: boolean;
  @Input() suggestedSearch: PatientSearchQuery;

  @Output() public patientSearch = new EventEmitter<PatientSearchQuery>();
  @Output() public resetSearch = new EventEmitter();

  @ViewChild('patientLastName', { static: true })
  patientLastName: ElementRef;

  constructor() {}

  get canSearch(): boolean {
    return !this.formDisabled && this.patientSearchQuery.canPerform('firstName', 'lastName', 'dob');
  }

  get canReset(): boolean {
    return !this.formDisabled && this.patientSearchQuery.isDirty();
  }

  ngOnInit() {
    this.dobMinDate = DateUtils.toServerFormat(new Date(1900, 1, 1));
    if (!this.formDisabled && this.suggestedSearch) {
      this.patientSearchQuery = this.suggestedSearch;
      this.onSubmit();
    } else {
      this.patientSearchQuery = new PatientSearchQuery();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.patientLastName.nativeElement.focus());
  }

  onDateClearClick() {
    this.patientSearchQuery.dob = null;
  }

  resetSearchForm() {
    this.patientSearchQuery = new PatientSearchQuery();
  }

  onSubmit() {
    this.patientSearch.emit(this.patientSearchQuery);
  }

  onReset() {
    this.patientSearchQuery = new PatientSearchQuery();
    this.resetSearch.emit();
  }
}
