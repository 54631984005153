import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Report, AppliedReportOptions } from '../models/report.model';
import { ApiService } from './api.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ReportsService extends ApiService {
  constructor(authService: AuthenticationService, private http: HttpClient) {
    super();
  }

  create(reportsUrl: string, filters: AppliedReportOptions, outputOptions: AppliedReportOptions): Observable<Report> {
    return this.http.post<Report>(reportsUrl, { filters, outputOptions });
  }

  get(reportsUrl: string, id): Observable<Report> {
    return this.http.get<Report>(`${reportsUrl}/${id}`);
  }

  cancel(reportsUrl: string, id): Observable<Report> {
    return this.http.patch<Report>(`${reportsUrl}/${id}/cancel`, {});
  }

  getList(reportsUrl): Observable<Report[]> {
    return this.http.get<Report[]>(reportsUrl);
  }
}
