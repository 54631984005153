<ng-container *ngIf="!loading; else: spinner">
  <app-patient-header [patient]="patient" />

  <div class="page-container">
    <div class="mt-3"><app-patient-demographics [patient]="patient" /></div>
    <div class="mt-3"><app-patient-grouped-patients [patient]="patient" /></div>
  </div>
</ng-container>

<ng-template #spinner> <mat-spinner /> </ng-template>
