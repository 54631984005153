import { TitleCasePipe } from '@angular/common';

export const displayUserStatus = (value: any, _args?: any): any => {
  if (value) {
    value = value.replace(new RegExp('_', 'g'), ' ');
    value = new TitleCasePipe().transform(value);
    return value;
  } else {
    return value;
  }
}
