import { Component } from '@angular/core';
import { Upload } from '../../../core/models/upload.model';

import { ReferralPrescriberSpecialtyMappingService } from '../../../core/services/referral-prescriber-specialty-mapping.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty } from 'lodash-es';

const emptyForm = { file: null };

@Component({
  selector: 'app-capture-admin-import-referral-prescriber-specialty-mapping',
  templateUrl: './capture-admin-import-referral-prescriber-specialty-mapping.component.html',
})
export class CaptureAdminImportReferralPrescriberSpecialtyMappingComponent {
  uploading = false;
  formModel = { ...emptyForm };
  errors: string[] = [];

  constructor(
    private referralPrescriberSpecialtyMappingService: ReferralPrescriberSpecialtyMappingService,
    private router: Router
  ) {}

  get uploadButtonDisabled(): boolean {
    return !this.formModel.file;
  }

  onUploadStart() {
    this.uploading = true;
    this.errors = [];
  }

  onUploadCancel() {
    this.reset();
  }

  onUploadComplete(upload: Upload) {
    this.referralPrescriberSpecialtyMappingService.create(upload.signedId).subscribe(
      () => {
        this.router.navigate(['/capture-admin/tools/referral-prescriber-specialty-mapping/history']);
        this.uploading = false;
        this.reset();
      },
      (error: HttpErrorResponse) => {
        this.uploading = false;
        this.errors = error.error.errors;
      }
    );
  }

  onUploadError($event) {
    this.reset();
  }

  onFormEnter($event) {
    $event.preventDefault();
  }

  private reset() {
    this.uploading = false;
    this.formModel = { ...emptyForm };
  }

  public get hasError() {
    return !isEmpty(this.errors);
  }
}
