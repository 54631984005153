import { Directive, OnInit, Renderer2, Input, ElementRef } from "@angular/core";

// When rendering an HTML table, Chrome uses some unknown logic to figure out
// the width of each column. The widths are, of course, related to the content
// in the cells of the column, the direct width attribute on the cells, and the
// css width settings.
//
// It prioritizes preserving the width of columns that have a width specified
// via CSS. We exploit that here in order to allow dragging of column
// boundaries to behave similar to a spreadsheet. If you drag the boundary
// between two header cells, the cell to the left will get resized. The cells
// to the left of *that* cell will keep their current size. And the cells to
// the right will be resized to fill the space.
//
// If the user has customized the size of *some* of the cells to the right, the
// width of those will be preserved until the point where it might not make
// sense (i.e. another column is squished too much). For minor adjustments,
// this works. If you try to drag a column width a very large amount, Chrome
// will disallow crunching other columns too small.
//
// This is not perfect but is hopefully sufficient to allow users to make
// adjustments to see table data a little more clearly.
//
// A table with resizable headers requires the class name "table-resizable-columns"
// and the resizable <th> headers require these attributes
// [appResizableColumn]="true"
// class="resizable-header"
//
// The scss file for the component/page with a resizable table requires
// @import '../../../../sass/components/_table_resizable-columns.scss';
//
// Note that this won't work with a table that has nested tables (a <td> that
// contains another table)

@Directive({
  selector: "[appResizableColumn]"
})
export class ResizableTableColumnDirective implements OnInit {
  @Input("appResizableColumn") resizable: boolean;
  private startX: number;
  private startWidth: number;
  private column: HTMLElement;
  private table: HTMLElement;
  private pressed: boolean;

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.column = this.el.nativeElement;
  }

  ngOnInit() {
    if (this.resizable) {
      const row = this.renderer.parentNode(this.column);
      const thead = this.renderer.parentNode(row);
      this.table = this.renderer.parentNode(thead);

      const resizer = this.renderer.createElement("span");
      this.renderer.addClass(resizer, "resize-holder");
      this.renderer.appendChild(this.column, resizer);
      this.renderer.listen(resizer, "mousedown", this.onMouseDown);
      this.renderer.listen(this.table, "mousemove", this.onMouseMove);
      this.renderer.listen("document", "mouseup", this.onMouseUp);
    }
  }

  onMouseDown = (event: MouseEvent) => {
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.column.offsetWidth;

    // fix the width of all columns to the left
    const headers = Array.from(this.table.querySelectorAll("th"))
    for (const header of headers) {
      if (header === this.column) {
        break;
      } else {
        this.renderer.setStyle(header, "width", `${header.offsetWidth}px`)
      }
    }
  }

  onMouseMove = (event: MouseEvent) => {
    if (this.pressed && event.buttons) {
      this.renderer.addClass(this.table, "resizing");
      const width = this.startWidth + (event.pageX - this.startX);
      this.renderer.setStyle(this.column, "width", `${width}px`);
    }
  };

  onMouseUp = () => {
    if (this.pressed) {
      this.pressed = false;
      this.renderer.removeClass(this.table, "resizing");
    }
  };
}
