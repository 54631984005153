<dl class="row" *ngIf="dateRange">
  <dt class="col-sm-1">{{ label }}:</dt>
  <dd class="col-sm-11">
    <ng-container *ngIf="dateRange.from && dateRange.to">
      {{ dateRange.from }} to {{ dateRange.to }}
    </ng-container>

    <ng-container *ngIf="dateRange.from && !dateRange.to">
      Starting {{ dateRange.from }}
    </ng-container>

    <ng-container *ngIf="!dateRange.from && dateRange.to">
      Ending {{ dateRange.to }}
    </ng-container>
  </dd>
</dl>
