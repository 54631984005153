import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CollaboratorPermission } from '../models/llm-playground.model';

@Injectable()
export class LlmPlaygroundCollaboratorPermissionsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  index(projectId: number): Observable<CollaboratorPermission[]> {
    return this.http.get<CollaboratorPermission[]>(
      `${environment.captureApi.url}/llm_playground/projects/${projectId}/collaborator_permissions/`
    );
  }

  create(projectId: number, userId: number): Observable<CollaboratorPermission> {
    return this.http.post<CollaboratorPermission>(
      `${environment.captureApi.url}/llm_playground/projects/${projectId}/collaborator_permissions/`,
      { collaborator_permission: { user_id: userId }, }
    );
  }

  destroy(id: number): Observable<CollaboratorPermission> {
    return this.http.delete<CollaboratorPermission>(
      `${environment.captureApi.url}/llm_playground/collaborator_permissions/${id}`
    );
  }
}
