import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Office } from "../models/office.model";
import { OfficeStatusReview } from "../models/office-status-review.model";
import { LimitOffsetPagingMeta, Sort } from "../models/paged-results/limit-offset-paging.model";
import { PagedResults } from "../models/paged-results/paged-results.model";
import { OfficeFilters } from "../models/user-settings/office-search-settings.model";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ProviderOffice } from "../models/provider-office.model";
import { Provider } from "../models/provider.model";
import { OfficeStatusReviewFilters } from 'app/core/models/user-settings/office-status-review-settings.model';
import { Audit } from "../models/audit.model";

export class PagedOffices implements PagedResults {
  private offices: Office[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.offices;
  }
}

export class PagedOfficeStatusReviews implements PagedResults {
  private officeStatusReviews: OfficeStatusReview[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.officeStatusReviews;
  }
}

export class PagedOfficeAudits implements PagedResults {
  private audits: Audit[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.audits;
  }
}

@Injectable()
export class OfficeService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    paging: { page: number; pageSize: number },
    filters: OfficeFilters = null
  ): Observable<PagedOffices> {
    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);

    if (filters) {
      if (filters.name) {
        searchParams.append('name', filters.name);
      }

      if (filters.phone) {
        searchParams.append('phone', filters.phone);
      }

      if (filters.fax) {
        searchParams.append('fax', filters.fax);
      }

      if (filters.city) {
        searchParams.append('city', filters.city);
      }

      if (filters.state) {
        searchParams.append('state', filters.state);
      }

      if (filters.status && filters.status.length > 0) {
        filters.status.forEach(v => searchParams.append('status[]', v.toString()));
      }
    }

    return this.http.get<PagedOffices>(
      `${environment.captureApi.url}/offices?${searchParams.toString()}`
    ).pipe(
      map(result => Object.assign(new PagedOffices(), result))
    );
  }

  getStatusReviewList(
    paging: { page: number; pageSize: number },
    filters: OfficeStatusReviewFilters = null,
    sort: Sort
  ): Observable<PagedOfficeStatusReviews> {
    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);

    if (filters) {
      if (filters.reviewStatus && filters.reviewStatus.length > 0) {
        filters.reviewStatus.forEach(v => searchParams.append('review_status[]', v.toString()));
      }
    }

    if (sort.col) {
      searchParams.append('sort_col', sort.col);

      if (sort.dir) {
        searchParams.append('sort_dir', sort.dir);
      }
    }

    return this.http.get<PagedOfficeStatusReviews>(
      `${environment.captureApi.url}/offices/status_reviews?${searchParams.toString()}`
    ).pipe(
      map(result => Object.assign(new PagedOfficeStatusReviews(), result))
    );
  }

  getAudits(
    office: Office,
    paging: { page: number; pageSize: number },
  ): Observable<PagedOfficeAudits> {
    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);

    return this.http.get<PagedOfficeAudits>(
      `${environment.captureApi.url}/offices/${office.id}/audits?${searchParams.toString()}`
    ).pipe(
      map(result => Object.assign(new PagedOfficeAudits(), result))
    );
  }

  searchFax(faxNumber: string) {
    return this.http.get<Office>(`${environment.captureApi.url}/offices/search_fax?fax=${faxNumber}`);
  }

  get(id: string): Observable<Office> {
    return this.http.get<Office>(`${environment.captureApi.url}/offices/${id}`);
  }

  getProviderOffices(officeId: number): Observable<ProviderOffice[]> {
    return this.http.get<ProviderOffice[]>(
      `${environment.captureApi.url}/offices/${officeId}/provider_offices`
    );
  }

  getProviders(officeId: number): Observable<Provider[]> {
    return this.http.get<Provider[]>(
      `${environment.captureApi.url}/offices/${officeId}/providers`
    );
  }

  create(attributes: any): Observable<Office> {
    return this.http.post<Office>(`${environment.captureApi.url}/offices`, {
      office: attributes,
    });
  }

  update(office: Office, attributes: any): Observable<Office> {
    return this.http.patch<Office>(`${environment.captureApi.url}/offices/${office.id}`, {
      office: attributes,
    });
  }
}
