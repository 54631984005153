import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Prescriber } from 'app/core/models/prescriber.model';
import { PatientService } from 'app/core/services/patient.service';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { ProviderService } from 'app/core/services/provider.service';
import { PatientPrescriberOffice } from 'app/core/models/patient-prescriber-office.model';
import { PatientPrescriberOfficesService } from 'app/core/services/patient-prescriber-offices.service';
import { Capture } from 'app/core/models/capture.model';
import { OutboundFaxTemplate } from 'app/core/enums/outbound-fax-template.enum';
import { CaptureService } from 'app/core/services/capture.service';
import { SelectableOutboundFaxTemplateOptions, keyedOutboundFaxTemplateOptions } from 'app/core/options/outbound-fax-template-opts';
import { Office } from 'app/core/models/office.model';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';

const defaultFormModel = {
  selectedPrescriberId: null,
  selectedTemplate: null,
  selectedProviderOfficeId: null,
  customNote: null,
};

@Component({
  selector: 'app-send-fax',
  templateUrl: './send-fax.component.html',
  styleUrls: ['./send-fax.component.scss'],
})
export class SendFaxComponent implements OnInit {
  @Input() capture: Capture;
  @Output() faxSent: EventEmitter<void> = new EventEmitter<void>();

  loadingProviderOffices = false;
  sendFaxEnabled = false;
  sendingFax = false;
  maxCustomNoteLength = 350;

  prescribers: Prescriber[] = [];
  faxTemplateOptions = SelectableOutboundFaxTemplateOptions;
  faxTemplates = OutboundFaxTemplate;
  suggestedFaxTemplate = OutboundFaxTemplate.firstAttempt;
  providerOffices: ProviderOffice[] = [];
  patientPrescriberOffices: { [providerOfficeId: string]: PatientPrescriberOffice } = {};
  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;

  formModel = { ...defaultFormModel };

  constructor(
    private patientService: PatientService,
    private providerService: ProviderService,
    private patientPrescriberOfficesService: PatientPrescriberOfficesService,
    private captureService: CaptureService
  ) {}

  ngOnInit() {
    this.loadPrescribers();
  }

  onPrescriberChange() {
    this.loadAvailableOffices();
  }

  onOfficeSelectionChange() {
    const office = this.findOfficeByProviderOfficeId(this.formModel.selectedProviderOfficeId);
    this.determineSendFaxEnabled();
    this.loadOfficeFaxSuggestion(office);
  }

  private loadOfficeFaxSuggestion(office: Office) {
    this.captureService.officeFaxSuggestion(this.capture, office).subscribe((result) => {
      this.suggestedFaxTemplate = result.template as OutboundFaxTemplate;
    });
  }

  onTemplateChange() {
    this.formModel.customNote = null;
    this.determineSendFaxEnabled();
  }

  onCustomNoteChange() {
    this.determineSendFaxEnabled();
  }

  onSendFaxClick() {
    this.sendFaxEnabled = false;
    this.sendingFax = true;
    const { selectedTemplate, selectedProviderOfficeId, customNote } = this.formModel;

    this.captureService.sendFaxes(
      this.capture,
      selectedTemplate,
      [selectedProviderOfficeId],
      customNote
    ).subscribe(() => {
      this.resetFormAfterSend();
      this.determineSendFaxEnabled();
      this.sendingFax = false;
      this.faxSent.emit();
    });
  }

  patientPrescriberOfficeStatus(providerOffice) {
    const ppo = this.patientPrescriberOffices[providerOffice.id];

    if (ppo) {
      return ppo.status;
    }
  }

  public get displayCustomNote() {
    return this.isCustmoNoteTemplateSelected();
  }

  public get outboundFaxSuggestedTemplate() {
    return keyedOutboundFaxTemplateOptions[this.suggestedFaxTemplate].display;
  }

  private loadAvailableOffices() {
    this.loadProviderOffices();
    this.loadPatientPrescriberOffices();
  }

  private loadPrescribers() {
    this.patientService.getPrescribers(this.capture.patient.id).subscribe((prescribers: Prescriber[]) => {
      this.prescribers = prescribers;
      this.formModel.selectedPrescriberId = this.capture.prescriber.id;
      this.loadAvailableOffices();
    });
  }

  private loadProviderOffices() {
    const index = this.prescribers.findIndex(p => p.id === this.formModel.selectedPrescriberId);
    this.providerOffices = [];

    if (index > -1) {
      const prescriber = this.prescribers[index];

      if (prescriber.providerId) {
        this.loadingProviderOffices = true;

        this.providerService
          .getProviderOffices(prescriber.providerId)
          .subscribe((providerOffices: ProviderOffice[]) => {
            this.providerOffices = providerOffices;
            this.loadingProviderOffices = false;
          });
      }
    }
  }

  private loadPatientPrescriberOffices() {
    const index = this.prescribers.findIndex(p => p.id === this.formModel.selectedPrescriberId);
    this.patientPrescriberOffices = {};

    if (index > -1) {
      const prescriber = this.prescribers[index];

      if (prescriber.providerId) {
        this.patientPrescriberOfficesService.getList(this.capture.patient.id, prescriber.id).subscribe(response => {
          response.patientPrescriberOffices.forEach(ppo => {
            if (ppo.providerOfficeId) {
              this.patientPrescriberOffices[ppo.providerOfficeId] = ppo;
            }
          });
        });
      }
    }
  }

  private resetFormAfterSend() {
    this.formModel.selectedTemplate = null;
    this.formModel.selectedProviderOfficeId = null;
  }

  private determineSendFaxEnabled() {
    const hasRequiredSelections =
      this.formModel.selectedTemplate && this.formModel.selectedProviderOfficeId;

    if (this.isCustmoNoteTemplateSelected()) {
      const { customNote } = this.formModel;
      this.sendFaxEnabled = hasRequiredSelections && customNote && customNote.trim().length > 0;
    } else {
      this.sendFaxEnabled = hasRequiredSelections;
    }
  }

  private isCustmoNoteTemplateSelected() {
    return this.formModel.selectedTemplate === OutboundFaxTemplate.customNote ||
           this.formModel.selectedTemplate === OutboundFaxTemplate.thirdPartyCustomNote;
  }

  private findOfficeByProviderOfficeId(providerOfficeId: number): Office {
    const providerOffice = this.providerOffices.find(po => po.id === providerOfficeId);
    return providerOffice ? providerOffice.office : null;
  }
}
