import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

const loadingText = 'Loading...';

@Component({
  selector: 'app-sensitive-string',
  templateUrl: './sensitive-string.component.html',
  styleUrls: ['./sensitive-string.component.scss'],
})
export class SensitiveStringComponent implements OnInit {
  @Output() valueAccessed: EventEmitter<string> = new EventEmitter<string>();
  @Input() value: string;
  @Input() hiddenTextLength = 10;
  @Input() hiddenTextCharacter = '∗'; // Low asterisk

  visible = false;
  hiddenTextString: string;
  hiddenTextWidth: number;
  hiddenTextHeight: number;
  valueLoading: string;

  ngOnInit() {
    this.hiddenTextString = this.hiddenTextCharacter.repeat(this.hiddenTextLength);
    this.valueLoading = loadingText;
  }

  public toggleVisibility(): void {
    this.visible = !this.visible;

    if (this.visible) {
      this.valueAccessed.emit();
    }
  }
}
