import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserPasswordCredentials } from '../../../core/models/user-password-credentials.model';
import { LoginAttemptStatus } from '../../../core/enums/login-attempt-status.enum';
import { LogoutReason } from '../../../core/enums/logout-reason.enum';
import { NavigationService } from '../../../core/services/navigation.service';
import { AuthenticationService, LoginAttemptResult, LoginTypeResult } from '../../../core/services/authentication.service';
import { AuthPaths } from '../../enums/auth-paths';
import { LoginType } from '../../../core/enums/login-type.enum';
import { environment } from 'environments/environment';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare let pendo: any;

const pendoIsEnabled = () => pendo && environment.pendo.enabled;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  userCredentials: UserPasswordCredentials = new UserPasswordCredentials();
  logoutReason = null;
  logoutReasons = LogoutReason;
  returnUrl = null;
  submitted = false;
  emailSubmitted = false;
  submitting = false;
  loginStatus = null;
  loginStatuses = LoginAttemptStatus;

  constructor(private authService: AuthenticationService, private navigationService: NavigationService) {}

  ngOnInit() {
    this.logoutReason = this.navigationService.queryParams['logoutReason'];
    this.returnUrl = this.navigationService.queryParams['returnUrl'];
    if (pendoIsEnabled()) {
      pendo.initialize({ excludeAllText: true });
    }
  }

  onSubmit(loginForm: NgForm) {
    this.submitted = true;
    this.submitting = true;
    this.loginStatus = null;

    if (loginForm.valid) {
      if (this.emailSubmitted) {
        this.loginWithPassword();
      } else {
        this.authService.determineLoginType(this.userCredentials).subscribe((loginTypeResult: LoginTypeResult) => {
          if (loginTypeResult.type === LoginType.Saml) {
            this.navigationService.goToExternalUrl(loginTypeResult.data);
          } else if (loginTypeResult.type === LoginType.Password) {
            this.submitted = false;
            this.submitting = false;
            this.emailSubmitted = true;
          }
        })
      }
    } else {
      this.submitting = false;
    }
  }

  loginWithPassword() {
    this.authService.login(this.userCredentials).subscribe((loginAttemptResult: LoginAttemptResult) => {
      if (loginAttemptResult.status === LoginAttemptStatus.ForceResetPassword) {
        // @ts-ignore
        const { code, name, username } = loginAttemptResult.data;
        const params = new URLSearchParams({
          code,
          first_name: name,
          username,
        });

        this.appendReturnUrl(params);

        this.navigationService.goToUrl(`/${AuthPaths.confirmForcePasswordChange}?${params.toString()}`);
      } else if (loginAttemptResult.status === LoginAttemptStatus.MFARequired) {
        // @ts-ignore
        const { username, session, destination } = loginAttemptResult.data;
        const params = new URLSearchParams({
          username,
          session,
          destination,
        });

        this.appendReturnUrl(params);

        this.navigationService.goToUrl(`/${AuthPaths.confirmMfa}?${params.toString()}`);
      } else {
        this.loginStatus = loginAttemptResult.status;
        this.submitted = false;
        this.submitting = false;
      }
    });
  }

  appendReturnUrl(params: URLSearchParams) {
    if (this.returnUrl) {
      params.append('returnUrl', this.returnUrl);
    }
  }

  invalidCss(controlName: string, loginForm: NgForm) {
    if (this.submitted) {
      const control = loginForm.controls[controlName];
      return {
        'is-invalid': this.submitted && control && control.hasError('required'),
      };
    } else {
      return {};
    }
  }
}
