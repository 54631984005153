import { Component, Input } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-validate-prescriber-match',
  templateUrl: './validate-prescriber-match.component.html',
  styleUrls: ['./validate-prescriber-match.component.scss'],
})
export class ValidatePrescriberMatchComponent {
  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;

  constructor() {}
}
