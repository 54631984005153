import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { NavigationService } from './navigation.service';
import { CaptureSearchService } from './capture-search.service';
import { Client } from '../models/client.model';
import { CaptureSearchParamSet } from '../models/capture-search-param-set.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ViewAsClientService {
  constructor(
    private authService: AuthenticationService,
    private navigationService: NavigationService,
    private captureSearchService: CaptureSearchService
  ) {}

  public viewAsClient($event, client: Client) {
    $event.preventDefault();
    this.captureSearchService.saveSearchParams(new CaptureSearchParamSet()).subscribe(
      () => {
        this.authService.startViewingAsClient(client);
        this.navigationService.navigateHome();
        window.scroll(0, 0);
      },
      (err: HttpErrorResponse) => {
        console.error(err);
      }
    );
  }
}
