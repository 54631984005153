import { Component, Input, OnInit } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-validate-specialist-encounter',
  templateUrl: './validate-specialist-encounter.component.html',
  styleUrls: ['./validate-specialist-encounter.component.scss'],
})
export class ValidateSpecialistEncounterComponent implements OnInit {
  constructor() {}

  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;

  ngOnInit() {}
}
