import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IccCapture } from 'app/core/models/icc/icc-capture.model';
import { IccCaptureService } from 'app/core/services/icc/icc-capture.service';
import { NavigationService } from 'app/core/services/navigation.service';

@Component({
  selector: 'app-icc-capture-detail',
  templateUrl: './icc-capture-detail.component.html',
  styleUrls: ['./icc-capture-detail.component.scss']
})
export class IccCaptureDetailComponent implements OnInit {
  public refreshing = true;
  public captureId: string;
  public capture: IccCapture;

  constructor(
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private iccCaptureService: IccCaptureService
  ) {}

  ngOnInit(): void {
    this.captureId = this.route.snapshot.paramMap.get('id');

    this.loadCapture(this.captureId);
  }

  private loadCapture(id: string): void {
    this.refreshing = true;
    this.iccCaptureService.getCapture(id).subscribe(
      (capture) => {
        this.capture = capture;
        this.refreshing = false;
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.navigationService.notFound();
        } else {
          console.error(err);
        }
      }
    )
  }

}
