<ng-container *ngIf="isOpen">
  <td></td>
  <td colspan="100%">
    <strong>Captures</strong>
    <app-prescription-captures
      [prescription]="prescription"
      [activeCaptureId]="activeCaptureId"
    />
  </td>
</ng-container>
