import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: './app-version-update-modal',
  templateUrl: './version-update-modal.component.html',
  styleUrls: ['./version-update-modal.component.scss'],
})
export class VersionUpdateModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  onCancel() {
    this.activeModal.close();
  }

  onUpdate() {
    window.location.href = window.location.href + '?nocache=' + new Date().getTime();
  }
}
