import { Component, Input, OnInit } from '@angular/core';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';

@Component({
  selector: 'app-client-consult-note-task-closed',
  templateUrl: './client-consult-note-task-closed.component.html',
  styleUrls: ['./client-consult-note-task-closed.component.scss'],
})
export class ClientConsultNoteTaskClosedComponent implements OnInit {
  @Input() task: ClientConsultNoteTask;

  constructor() {}

  ngOnInit() {}
}
