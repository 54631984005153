import { Component, OnDestroy, OnInit } from '@angular/core';
import { CuratedSpecialtiesImport } from '../../../core/models/curated-specialties-import.model';
import { CuratedSpecialtiesService } from '../../../core/services/curated-specialties.service';

@Component({
  selector: 'app-capture-admin-import-curated-specialties-history',
  templateUrl: './capture-admin-import-curated-specialties-history.component.html',
  styleUrls: ['./capture-admin-import-curated-specialties-history.component.scss'],
})
export class CaptureAdminImportCuratedSpecialtiesHistoryComponent implements OnInit, OnDestroy {
  loading = false;
  curatedSpecialtiesImports: CuratedSpecialtiesImport[] = [];
  nextCheckTimeout = null;

  constructor(private curatedSpecialtiesService: CuratedSpecialtiesService) {}

  ngOnInit() {
    this.loadImports(true);
  }

  ngOnDestroy() {
    clearTimeout(this.nextCheckTimeout);
  }

  private loadImports(loading) {
    this.loading = loading;
    this.curatedSpecialtiesService.getList().subscribe(data => {
      this.curatedSpecialtiesImports = data.curatedSpecialtiesImports;
      this.loading = false;

      const anyActive: boolean = this.curatedSpecialtiesImports.some(record => record.active);

      if (anyActive) {
        this.nextCheckTimeout = setTimeout(() => this.loadImports(false), 500);
      }
    });
  }
}
