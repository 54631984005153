import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Prescription } from '../../../core/models/prescription.model';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';
import { Drug } from '../../../core/models/drug.model';
import { PrescriptionFamily } from '../../../core/models/prescription-family.model';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { ClientConsultNoteTasksService } from '../../../core/services/client-consult-note-tasks.service';
import { initRejection } from '../client-task-reject-prescription/client-task-reject-prescription.component';
import { TaskSubmission } from '../../../core/models/task-submission.model';

class PrescriptionOption {
  constructor(
    public id: number,
    public drugs: Drug[],
    public prescriptionFamily: PrescriptionFamily,
    public writtenDate: Date,
    public verified: boolean,
    public rejected: boolean,
    public submission: TaskSubmission,
    public verification: { valid: boolean; encounterDate: Date },
    public rejection: { checked: boolean; disabled: boolean }
  ) {}

  get submitted() {
    return this.verified || this.rejected;
  }
}

const initVerification = (prescription: Prescription): { valid: boolean; encounterDate: Date } => {
  if (prescription.clientRejected || !prescription.nearestClientConsultNoteVerification) {
    return null;
  }

  return {
    valid: !prescription.nearestClientConsultNoteVerificationInvalid,
    encounterDate: prescription.nearestClientConsultNoteVerification.encounterDate,
  };
};

@Component({
  selector: 'app-client-consult-note-task-prescriptions',
  templateUrl: './client-consult-note-task-prescriptions.component.html',
  styleUrls: ['./client-consult-note-task-prescriptions.component.scss'],
})
export class ClientConsultNoteTaskPrescriptionsComponent implements OnInit, OnChanges {
  @Input() task: ClientConsultNoteTask;
  @Input() prescriptions: Prescription[];
  @Input() formDisabled: boolean;

  @Output() addVerification = new EventEmitter();
  @Output() taskChange = new EventEmitter<ClientConsultNoteTask>();

  prescriptionOptions: PrescriptionOption[] = [];
  specialistEncounterTimeframeInMonths: number;

  constructor(private clientConsultNoteTasksService: ClientConsultNoteTasksService) {
    this.clientConsultNoteTasksService = clientConsultNoteTasksService;
  }

  ngOnInit() {
    this.specialistEncounterTimeframeInMonths = this.task.client.specialistEncounterTimeframeInMonths;
  }

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'task' || inputName === 'prescriptions') {
        this.initPrescriptionOptions();
      }
    });
  }

  onPrescriptionRejectionChange({ prescriptionId, checked }: { prescriptionId: number; checked: boolean }) {
    const { id, choice, notes, choiceReasons } = this.task;
    let modifiedPrescriptionIds = [...this.task.rejectedPrescriptionIds];

    if (checked) {
      modifiedPrescriptionIds.push(prescriptionId);
    } else {
      modifiedPrescriptionIds = modifiedPrescriptionIds.filter(pId => pId !== prescriptionId);
    }

    this.clientConsultNoteTasksService
      .updateRejectedPrescription(id, modifiedPrescriptionIds)
      .subscribe((task: ClientConsultNoteTask) => {
        this.taskChange.emit(task);
      });
  }

  onAddConsultNoteClick($event) {
    $event.preventDefault();
    this.addVerification.emit();
  }

  private initPrescriptionOptions() {
    this.prescriptionOptions = this.prescriptions.map(prescription => new PrescriptionOption(
        prescription.id,
        prescription.drugs,
        prescription.prescriptionFamily,
        prescription.writtenDate,
        prescription.clientVerifiedConsultNote,
        prescription.clientRejected,
        prescription.clientConsultNoteTaskSubmission,
        initVerification(prescription),
        initRejection(this.task, prescription)
      ));
  }
}
