import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewAsClientService } from 'app/core/services/view-as-client.service';
import { ClientsService } from '../../../core/services/clients.service';
import { Client } from 'app/core/models/client.model';
import { ClientStatus } from '../../../core/enums/client-status.enum';

@Component({
  selector: 'app-client-actions',
  templateUrl: './client-actions.component.html',
  styleUrls: ['./client-actions.component.scss'],
})
export class ClientActionsComponent implements OnInit {
  clientStatuses = ClientStatus;
  processing = false;

  @Input() client: Client;
  @Output() public clientChange = new EventEmitter<Client>();

  constructor(private clientsService: ClientsService, private viewAsClientService: ViewAsClientService) {}

  ngOnInit() {}

  onViewPortalClick($event) {
    this.viewAsClientService.viewAsClient($event, this.client);
  }

  onDeactivateClick($event) {
    $event.preventDefault();
    if (confirm(`Are you sure you want to deactivate ${this.client.humanizedName} client?`)) {
      this.processing = true;
      this.clientsService.deactivate(this.client.id).subscribe(client => {
        this.processing = false;
        this.client = client;
        this.clientChange.emit(client);
      });
    }
  }

  onReactivateClick($event) {
    $event.preventDefault();
    if (confirm(`Are you sure you want to activate ${this.client.humanizedName} client?`)) {
      this.processing = true;
      this.clientsService.activate(this.client.id).subscribe(client => {
        this.processing = false;
        this.client = client;
        this.clientChange.emit(client);
      });
    }
  }
}
