import { Component, Input, OnInit } from '@angular/core';
import { ClientReferralTask } from '../../../core/models/client-referral-task.model';

@Component({
  selector: 'app-client-referral-task-closed',
  templateUrl: './client-referral-task-closed.component.html',
  styleUrls: ['./client-referral-task-closed.component.scss'],
})
export class ClientReferralTaskClosedComponent implements OnInit {
  @Input() task: ClientReferralTask;

  constructor() {}

  ngOnInit() {}
}
