import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  generateConfigIdentifier,
  SuboptionChoice,
  SuboptionTypeChoices,
  SuboptionTypeDescriptions,
} from '../../lib/client-configuration';

@Component({
  selector: 'app-client-configuration-suboptions',
  templateUrl: './client-configuration-suboptions.component.html',
  styleUrls: ['./client-configuration-suboptions.component.scss'],
})
export class ClientConfigurationSuboptionsComponent implements OnInit {
  @Input() configId: any;
  @Input() definition: { option: string; dataType: string; editable: boolean; suboptionType: string };
  _suboptionChoices: SuboptionChoice[];

  @Output() choicesChange: EventEmitter<SuboptionChoice[]> = new EventEmitter<SuboptionChoice[]>();

  nextIndex = 0;
  canAdd = true;
  trackBySuboptionChoice = (index: number, choice: SuboptionChoice) => choice.identifier;
  suboptionTypeDescriptions = SuboptionTypeDescriptions;
  suboptionTypeChoices = [];

  constructor() {}

  get suboptionChoices(): SuboptionChoice[] {
    return this._suboptionChoices;
  }

  @Input()
  set suboptionChoices(choices: SuboptionChoice[]) {
    this._suboptionChoices = choices;
    this.refreshCanAdd();
  }

  ngOnInit() {
    this.initializeSuboptions();
  }

  onChoiceChange(choice: SuboptionChoice) {
    this.updateSuboptionChoice(choice);
  }

  onChoiceRemove(choice: SuboptionChoice) {
    this.removeSuboptionChoice(choice);
  }

  onAddClick($event) {
    $event.preventDefault();
    this.addSuboptionChoice();
  }

  private initializeSuboptions() {
    this.nextIndex = this.suboptionChoices.length;
    this.suboptionTypeChoices = this.getSuboptionTypeChoices();
    this.refreshCanAdd();
  }

  private getSuboptionTypeChoices() {
    const suboptionChoices = [{ display: '', value: null }];

    if (this.definition.suboptionType in SuboptionTypeChoices) {
      return suboptionChoices.concat(SuboptionTypeChoices[this.definition.suboptionType]);
    } else {
      return suboptionChoices;
    }
  }

  private addSuboptionChoice() {
    const identifier = generateConfigIdentifier(this.definition.option, this.configId, this.nextIndex);
    const suboptionChoice = new SuboptionChoice(identifier);
    this.nextIndex++;
    this.updateSuboptionChoice(suboptionChoice);
  }

  private updateSuboptionChoice(choice: SuboptionChoice) {
    const modifiedChoices = [...this.suboptionChoices];
    const index = this.suboptionChoices.findIndex(ch => ch.identifier === choice.identifier);

    if (index > -1) {
      modifiedChoices[index] = choice;
    } else {
      modifiedChoices.push(choice);
    }

    this.choicesChange.emit(modifiedChoices);
  }

  private removeSuboptionChoice(choice: SuboptionChoice) {
    const modifiedChoices = [...this.suboptionChoices];
    const index = this.suboptionChoices.findIndex(ch => ch.identifier === choice.identifier);

    if (index > -1) {
      modifiedChoices.splice(index, 1);
    }

    this.choicesChange.emit(modifiedChoices);
  }

  private refreshCanAdd() {
    if (this.definition.editable) {
      const count = (this.suboptionChoices && this.suboptionChoices.length) || 0;
      this.canAdd = count < this.suboptionTypeChoices.length - 1;
    } else {
      this.canAdd = false;
    }
  }
}
