import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ReferralMatchVerificationsClientBaseComponent } from "../referral-match-verifications-client-base/referral-match-verifications-client-base.component";
import { ClientReferralMatchOption } from "app/core/models/client-referral-match-option.model";
import { ReferralMatchVerification } from "app/core/models/referral-match-verification.model";

@Component({
  selector: 'app-referral-match-verifications-client-edit',
  templateUrl: './referral-match-verifications-client-edit.component.html',
  styleUrls: ['./referral-match-verifications-client-edit.component.scss'],
})
export class ReferralMatchVerificationsClientEditComponent
  extends ReferralMatchVerificationsClientBaseComponent
  implements OnInit {

  @Output() clientReferralMatchOptionsChanged = new EventEmitter<ClientReferralMatchOption[]>();

  onReferralVerificationChange(_option: ClientReferralMatchOption) {
    this.clientReferralMatchOptionsChanged.emit(this.referralMatchOptions);
  }

  isReferralMatchVerificationDisabledOrFormDisabled(
    _allSelectedRmvs: ReferralMatchVerification[],
    _referralMatchVerification: ReferralMatchVerification
  ) {
    return false;
  }
}
