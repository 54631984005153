import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { CaptureSearchParamSet } from '../models/capture-search-param-set.model';
import { CaptureSearchResult } from '../models/capture-lists/capture-search-result.model';
import { environment } from '../../../environments/environment';
import { LimitOffsetPaging, LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';

// IMPORTANT: for the capture search service used by capture admins see CaptureQueueService
export class PagedCaptureSearchResults implements PagedResults<CaptureSearchResult> {
  captures: CaptureSearchResult[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.captures;
  }

  static get empty(): PagedCaptureSearchResults {
    const pagedCaptures = new PagedCaptureSearchResults();
    pagedCaptures.captures = new Array<CaptureSearchResult>();
    pagedCaptures.meta = new LimitOffsetPagingMeta();
    pagedCaptures.meta.paging = LimitOffsetPaging.empty;
    return pagedCaptures;
  }
}

@Injectable()
export class CaptureSearchService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  search(
    captureSearchParams: CaptureSearchParamSet,
    currentPage: number = null,
    pageSize: number = null
  ): Observable<PagedCaptureSearchResults> {
    const queryParams: any = this.toParams(captureSearchParams);

    if (currentPage) {
      queryParams.page = currentPage;
    }

    if (pageSize) {
      queryParams.per_page = pageSize;
    }

    return this.http.get<PagedCaptureSearchResults>(`${environment.captureApi.url}/captures/search`, {
      params: queryParams,
    });
  }

  getSavedSearchParams(): Observable<CaptureSearchParamSet> {
    return this.http.get<CaptureSearchParamSet>(`${environment.captureApi.url}/me/capture_search_param_set`);
  }

  saveSearchParams(captureSearchParams: CaptureSearchParamSet) {
    return this.http.patch<void>(`${environment.captureApi.url}/me/capture_search_param_set`, captureSearchParams);
  }
}
