import { Component, Input } from '@angular/core';
import { ClientPrescriberTask } from '../../../core/models/client-prescriber-task.model';

@Component({
  selector: 'app-ce-review-client-prescriber-task',
  templateUrl: './ce-review-client-prescriber-task.component.html',
})
export class CeReviewClientPrescriberTaskComponent {
  @Input() clientPrescriberTask: ClientPrescriberTask;
}
