import { Component, OnInit } from '@angular/core';
import { Upload } from '../../../core/models/upload.model';
import { Client } from '../../../core/models/client.model';
import { ClientsService } from '../../../core/services/clients.service';
import { FileChecksService } from '../../../core/services/file-checks.service';
import { FilePreviewService } from '../../../core/services/file-preview.service';
import { FilePreview } from '../../../core/models/file-preview.model';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty } from 'lodash-es';

const emptyForm = { clientId: null, fileType: null, file: null };

@Component({
  selector: 'app-capture-admin-file-check',
  templateUrl: './capture-admin-file-check.component.html',
})
export class CaptureAdminFileCheckComponent implements OnInit {
  uploading = false;
  formModel = { ...emptyForm };
  errors: string[] = [];

  clients: Client[] = [];
  filePreview: FilePreview = null;

  constructor(
    private clientsService: ClientsService,
    private fileChecksService: FileChecksService,
    private filePreviewService: FilePreviewService,
    private router: Router
  ) {}

  ngOnInit() {
    this.init();
  }

  get uploadButtonDisabled(): boolean {
    return !(this.formModel.clientId && this.formModel.fileType && this.formModel.file);
  }

  onFileSelected(file) {
    this.formModel.file = file;

    this.filePreviewService.preview(file).subscribe(filePreview => {
      this.filePreview = filePreview;
    });
  }

  onUploadStart() {
    this.uploading = true;
  }

  onUploadCancel() {
    this.reset();
  }

  onUploadComplete(upload: Upload) {
    const { fileType, clientId } = this.formModel;

    this.fileChecksService.create(fileType, clientId, upload.signedId).subscribe(
      () => {
        this.router.navigate(['capture-admin/tools/file-check/results']);
        this.uploading = false;
        this.reset();
      },
      (error: HttpErrorResponse) => {
        this.uploading = false;
        this.errors = error.error.errors;
      }
    );
  }

  onUploadError() {
    this.reset();
  }

  onFormEnter($event) {
    $event.preventDefault();
  }

  private reset() {
    this.uploading = false;
    this.formModel = { ...emptyForm };
    this.filePreview = null;
  }

  private init() {
    this.loadClients();
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  public get hasError() {
    return !isEmpty(this.errors);
  }
}
