import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Office } from 'app/core/models/office.model';
import { OfficeService } from 'app/core/services/office.service';
import { UserTimingService } from 'app/core/services/user-timing.service';

@Component({
  selector: 'app-capture-admin-office',
  templateUrl: './capture-admin-office.component.html',
  styleUrls: ['./capture-admin-office.component.scss'],
})
export class CaptureAdminOfficeComponent implements OnInit, OnDestroy {
  office: Office;
  loading: boolean;
  editing: boolean;
  currentSection = 'officeDetails';

  constructor(
    private officeService: OfficeService,
    private route: ActivatedRoute,
    private userTimingService: UserTimingService,
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.userTimingService.stop();
  }

  onSectionClick(section: string) {
    this.currentSection = section;
  }

  onEditDetails() {
    this.editing = true;
  }

  onEditDetailsCancel() {
    this.editing = false;
  }

  onUpdatedDetails() {
    this.editing = false;
    this.init();
  }

  private init() {
    const id = this.route.snapshot.paramMap.get('id');
    this.startUserTimingService(id);
    this.loading = true;
    this.editing = false;
    this.officeService.get(id).subscribe(office => {
      this.office = office;
      this.loading = false;
    });
  }

  private startUserTimingService(id) {
    this.userTimingService.track({
      page: 'capture_admin_office',
      recordId: id,
    });
  }
}
