export class NameMapping {
  constructor(
    public id: number,
    public status: string,
    public name: string,
    public alternate: string,
    public nameType: string,
    public highValue: boolean,
    public estimatedValue: number,
    public createdAt: string,
    public updatedAt: string,
    public patientId: number,
    public captureId: number,
    public userId: number,
    public needsPropagation: boolean,
    public statusUpdatedAt: string,
    public statusUpdatedById: number,
    public levenshteinRatio: number,
  ) {}
}

export class NameMappingSummary {
  constructor(
    public allCount: number,
    public highValueCount: number,
  ) {}
}

export class NameMappingUpdate {
  constructor(
    public approve: number[],
    public reject: number[],
  ) {}
}

export class NameMappingFilters {
  constructor(
    public createdDateFrom: any = null,
    public createdDateTo: any = null,
    public matchType: string = null,
    public hasHighValue: string = null,
    public hasNpmaCaptures: string = null,
  ) {}
}

