import { keyBy } from 'lodash-es';
import { PatientAttachmentStatus } from '../enums/patient-attachment-status.enum';

export const PatientAttachmentStatusOptions = [
  { value: PatientAttachmentStatus.open, display: 'Open' },
  { value: PatientAttachmentStatus.markedAsInvalid, display: 'Invalid' },
  { value: PatientAttachmentStatus.closed, display: 'Closed' },
  // this isn't in the enum because it's not selectable as a filter
  { value: 'in_ocr', display: 'OCR Processing' },
];

export const keyedPatientAttachmentStatusOptions = keyBy(PatientAttachmentStatusOptions, 'value');

export const displayPatientAttachmentStatus = (value: string): string => {
  const option = keyedPatientAttachmentStatusOptions[value];

  if (option) {
    return option.display;
  } else {
    return value;
  }
}
