import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { EhrReferralVerification } from '../models/ehr-referral-verification.model';

export class EhrReferralVerificationParams {
  public id?: number;
  public patientPrescriberId?: number;
  public referralDate: Date;
  public referralSpecialty: string;
  public reasons: string[];
  public notes: string;
  public otherReason: string;
}

export class EhrReferralVerificationUpdate {
  ehrReferralVerification: EhrReferralVerification;
  transitionedCaptureIds: number[];
}

@Injectable()
export class EhrReferralVerificationsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    filters: {
      patientId?: number,
    },
  ): Observable<{ ehrReferralVerifications: EhrReferralVerification[] }> {
    const url = `${environment.captureApi.url}/ehr_referral_verifications`;

    const queryParams: URLSearchParams = new URLSearchParams();

    if (filters.patientId) {
      queryParams.append('patient_id', filters.patientId.toString());
    }

    return this.http.get<{ ehrReferralVerifications: EhrReferralVerification[] }>(`${url}?${queryParams.toString()}`);
  }

  create(
    ehrReferralVerificationParams: EhrReferralVerificationParams
  ): Observable<EhrReferralVerificationUpdate> {
    return this.http.post<EhrReferralVerificationUpdate>(
      `${environment.captureApi.url}/ehr_referral_verifications`,
      { ehrReferralVerification: ehrReferralVerificationParams }
    );
  }

  update(
    id: number,
    ehrReferralVerificationParams: EhrReferralVerificationParams
  ): Observable<EhrReferralVerificationUpdate> {
    return this.http.patch<EhrReferralVerificationUpdate>(
      `${environment.captureApi.url}/ehr_referral_verifications/${id}`,
      { ehrReferralVerification: ehrReferralVerificationParams }
    );
  }
}
