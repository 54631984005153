import { keyBy } from 'lodash-es';
import { InboundFaxStatus } from '../enums/inbound-fax-status.enum';

export class InboundFaxStatusOption {
  constructor(
    public value: string,
    public display: string,
  ) {}
}

const statusOptions = [
  new InboundFaxStatusOption(InboundFaxStatus.inOcr, 'In OCR'),
  new InboundFaxStatusOption(InboundFaxStatus.llmProcessing, 'LLM Processing'),
  new InboundFaxStatusOption(InboundFaxStatus.fresh, 'New'),
  new InboundFaxStatusOption(InboundFaxStatus.needsManualIntervention, 'PHI Trash'),
  new InboundFaxStatusOption(InboundFaxStatus.notValidEvidence, 'Not Valid Evidence'),
  new InboundFaxStatusOption(InboundFaxStatus.validEvidence, 'Valid Evidence')
];

const keyedStatusOptions = keyBy(statusOptions, 'value');

const displayInboundFaxStatus = (value: string): string => {
  if (value) {
    return keyedStatusOptions[value].display;
  } else {
    return value;
  }
}


export {
  statusOptions,
  keyedStatusOptions,
  displayInboundFaxStatus,
};
