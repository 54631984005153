export enum InboundFaxStatusReason {
  patientNotAtPractice = 'patient_not_at_practice',
  prescriberNotAtPractice = 'prescriber_not_at_practice',
  patientReleaseRequired = 'patient_release_required',
  prescriberSpecialtyDoesNotReceiveReferrals = 'prescriber_specialty_does_not_receive_referrals',
  faxReceivedHasAnIssue = 'fax_received_has_an_issue',
  officeOptedOut = 'office_opted_out',
  needsPayment = 'needs_payment',
  needsMailingAddress = 'needs_mailing_address',
  ceHasConsultNote = 'ce_has_consult_note',
  practiceWorkingOnRequest = 'practice_working_on_request',
  noRecordForDateOfService = 'no_record_for_date_of_service',
  additionalInformationRequested = 'additional_information_requested',
  junkNonMedicalDocument = 'junk_non_medical_document',
  other = 'other',
}
