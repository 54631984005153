import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ProviderOffice } from "../models/provider-office.model";
import { ProviderOfficeStatusReview } from "../models/provider-office-status-review.model";
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta, Sort } from '../models/paged-results/limit-offset-paging.model';
import { ProviderOfficeStatusReviewFilters } from 'app/core/models/user-settings/provider-office-status-review-settings.model';

export class PagedProviderOfficeStatusReviews implements PagedResults {
  private providerOfficeStatusReviews: ProviderOfficeStatusReview[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.providerOfficeStatusReviews;
  }
}

@Injectable()
export class ProviderOfficeService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id: number): Observable<ProviderOffice> {
    return this.http.get<ProviderOffice>(`${environment.captureApi.url}/provider_offices/${id}`);
  }

  getStatusReviewList(
    paging: { page: number; pageSize: number },
    filters: ProviderOfficeStatusReviewFilters = null,
    sort: Sort
  ): Observable<PagedProviderOfficeStatusReviews> {
    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);

    if (filters) {
      if (filters.reviewStatus && filters.reviewStatus.length > 0) {
        filters.reviewStatus.forEach(v => searchParams.append('review_status[]', v.toString()));
      }
    }

    if (sort.col) {
      searchParams.append('sort_col', sort.col);

      if (sort.dir) {
        searchParams.append('sort_dir', sort.dir);
      }
    }

    return this.http.get<PagedProviderOfficeStatusReviews>(
      `${environment.captureApi.url}/provider_offices/status_reviews?${searchParams.toString()}`
    ).pipe(
      map(result => Object.assign(new PagedProviderOfficeStatusReviews(), result))
    );
  }

  create(attributes: any): Observable<ProviderOffice> {
    return this.http.post<ProviderOffice>(`${environment.captureApi.url}/provider_offices`, {
      providerOffice: attributes,
    });
  }

  update(providerOffice: ProviderOffice, attributes: any): Observable<ProviderOffice> {
    return this.http.patch<ProviderOffice>(`${environment.captureApi.url}/provider_offices/${providerOffice.id}`, {
      providerOffice: attributes,
    });
  }
}
