import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaptureStatus } from 'app/core/enums/capture-status.enum';
import {
  ProviderCapturesSearchFilter,
  ProviderCapturesSearchSettings
} from 'app/core/models/user-settings/provider-captures-search-settings.model';
import { CaptureQueueCursorType } from 'app/core/enums/capture-queue-cursor-type.enum';
import { reasonsForStatus, transitionOptions } from 'app/core/options/capture-status.opts';
import { debounce, snakeCase, flatten, isEmpty, uniq } from 'lodash-es';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Provider } from 'app/core/models/provider.model';
import { ProviderCapturesService } from 'app/core/services/provider-captures.service';
import { displayCaptureStatus } from 'app/core/options/capture-status.opts';

const CursorTypeOptions = [
  { value: CaptureQueueCursorType.highestEstimatedValue, display: 'Estimated Value' },
  { value: CaptureQueueCursorType.highestPendingEstimatedValue, display: 'Pending Estimated Value' },
  { value: CaptureQueueCursorType.highestWipEstimatedValue, display: 'WIP Estimated Value' },
  { value: CaptureQueueCursorType.mostRecentlyFaxed, display: 'Manual Fax Date: Newest' },
  { value: CaptureQueueCursorType.leastRecentlyFaxed, display: 'Manual Fax Date: Oldest' },
  { value: CaptureQueueCursorType.mostRecentlyCreated, display: 'Created On' },
  { value: CaptureQueueCursorType.mostRecentlyVerified, display: 'Verified On' },
];

const formatReasonOptions = (reasonOptions: { value: string; display: string }[]) =>
  reasonOptions.map(ro => ({ value: snakeCase(ro.value), display: ro.display }));

@Component({
  selector: 'app-provider-captures-search-settings',
  templateUrl: './provider-captures-search-settings.component.html',
  styleUrls: ['./provider-captures-search-settings.component.scss'],
})
export class ProviderCapturesSearchSettingsComponent implements OnInit {
  @Input() provider: Provider;

  refreshCount$: Subject<void> = new Subject();

  settings: ProviderCapturesSearchSettings;
  filters: ProviderCapturesSearchFilter = new ProviderCapturesSearchFilter();
  cursorType: CaptureQueueCursorType = CaptureQueueCursorType.highestEstimatedValue;

  statusFilterOptions = [];
  statusReasonFilterOptions = [];
  cursorTypeOptions = CursorTypeOptions;
  count: number;

  debouncedHandleSettingsChange = debounce(
    () => {
      this.handleSettingsChange();
    },
    250,
    { maxWait: 1000 }
  );

  @Output() public settingsChange = new EventEmitter();

  constructor(
    private providerCapturesService: ProviderCapturesService,
  ) { }

  ngOnInit() {
    this.init();
  }

  onStatusFilterChange() {
    this.initStatusReasonFilterOptions();
    this.fireSettingsChange();
  }

  onStatusReasonFilterChange() {
    this.fireSettingsChange();
  }

  onFilterChange() {
    this.fireSettingsChange();
  }

  onCursorTypeChange() {
    this.settings.cursorType = this.cursorType;
    this.fireSettingsChange();
  }

  onResetFilters() {
    this.init();
    this.handleSettingsChange();
  }

  private init() {
    this.initSettings();
    this.initStatusFilterOptions();
    this.initStatusReasonFilterOptions();

    this.configureRefreshCount();
    this.refreshCount();
  }

  private initSettings() {
    this.filters = new ProviderCapturesSearchFilter();
    this.cursorType = CaptureQueueCursorType.highestEstimatedValue;
    this.settings = new ProviderCapturesSearchSettings(
      this.filters,
      this.cursorType
    );
  }

  private initStatusFilterOptions() {
    this.statusFilterOptions = Object.values(CaptureStatus)
      .filter(v => v !== CaptureStatus.discarded)
      .map(v => ({
        value: v,
        display: displayCaptureStatus(v),
      }));
  }

  private initStatusReasonFilterOptions() {
    let statusReasonFilterOptions;

    if (isEmpty(this.filters.statuses)) {
      statusReasonFilterOptions = transitionOptions.map(to => formatReasonOptions(to.reasonOptions));
    } else {
      statusReasonFilterOptions = this.filters.statuses.map(status => formatReasonOptions(reasonsForStatus(status)));
    }

    this.statusReasonFilterOptions = uniq(flatten(statusReasonFilterOptions));

    this.filters.reasons = this.filters.reasons.filter(selected =>
      this.statusReasonFilterOptions.find(r => r.value === selected)
    );
  }

  private handleSettingsChange() {
    this.refreshCount();
    this.settingsChange.emit(this.settings);
  }

  private fireSettingsChange() {
    this.debouncedHandleSettingsChange();
  }

  private refreshCount() {
    this.refreshCount$.next();
  }

  private configureRefreshCount() {
    this.refreshCount$
      .pipe(
        tap(() => {
          this.count = null;
        }),
        switchMap(() => this.fetchCount())
      )
      .subscribe(({ count }) => {
        this.count = count;
      });
  }

  private fetchCount() {
    return this.providerCapturesService.getCount(this.provider.id, this.settings);
  }

}