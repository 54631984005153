<div class="row">
  <div class="col-5">
    <app-inbound-fax-info [inboundFax]="inboundFaxForInfo" />
    <ng-content />
  </div>
  <div class="col-7">
    <app-inbound-fax-possible-patients [inboundFax]="inboundFax" />

    <app-pdf-file-viewer *ngIf="!usePdfKitViewer"
      [pdfFile]="inboundFax"
      [pdfFileType]="pdfFileType"
      height="80vh"
      [initialPage]="1"
    />
    <app-pdfkit-file-viewer *ngIf="usePdfKitViewer"
      class="card"
      [pdfFile]="inboundFax"
      [pdfFileType]="pdfFileType"
      height="107.5vh"
    />
  </div>
</div>
