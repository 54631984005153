import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blankValueDash',
})
export class BlankValueDashPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      return value;
    } else {
      return '--';
    }
  }
}
