import { ProviderStatus } from '../enums/provider-status.enum';
import { keyBy } from 'lodash-es';

export class ProviderStatusOption {
  constructor(
    public value: string,
    public display: string,
    public hide: boolean,
    public deprecated: boolean
  ) { }
}

const providerStatusOptions = [
  new ProviderStatusOption(ProviderStatus.new, "New", false, false),
  new ProviderStatusOption(ProviderStatus.verified, "Verified", false, false),
  new ProviderStatusOption(ProviderStatus.reviewed, "Reviewed", false, false),
  new ProviderStatusOption(ProviderStatus.mechanicalTurkReview, "Mechanical Turk Review", false, false),
  new ProviderStatusOption(ProviderStatus.dataCollection, "Data Collection", true, false),
  new ProviderStatusOption(ProviderStatus.providerNotValid, "Provider Not Valid", false, false),
  new ProviderStatusOption(ProviderStatus.notAReferralSpecialty, "Not a Referral Specialty", false, false),
];

const keyedStatusOptions = keyBy(providerStatusOptions, 'value');

const filterProviderStatusOptions = (providerStatus: string) =>
  providerStatusOptions.filter(statusOption => !statusOption.hide || statusOption.value === providerStatus)

const searchableProviderStatusOptions =
  providerStatusOptions.filter(statusOption => !statusOption.deprecated);

export { keyedStatusOptions, providerStatusOptions, filterProviderStatusOptions, searchableProviderStatusOptions };
