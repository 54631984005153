import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  constructor(private http: HttpClient) { }

  getAvailableFrontendVersion(): Observable<string> {
    const timestamp = new Date().getTime();
    return this.http.get(`${window.location.origin}/version.txt?nocache=${timestamp}`, { responseType: 'text' });
  }

  private versionAlertSource = new BehaviorSubject<boolean>(false);
  versionAlertState$ = this.versionAlertSource.asObservable();

  showVersionAlert() {
    this.versionAlertSource.next(true);
  }

  hideVersionAlert() {
    this.versionAlertSource.next(false);
  }
}
