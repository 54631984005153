import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { ApiService } from './api.service';
import { LimitOffsetPagingMeta, Sort } from '../models/paged-results/limit-offset-paging.model';
import { ClientReferralTask } from '../models/client-referral-task.model';
import { NextQueueItem } from '../models/next-queue-item.model';
import { UserSettings } from '../enums/user-settings.enum';
import { NextQueueItemProvider } from '../interfaces/next-queue-item-provider';
import { QueueProcessingService } from './queue-processing.service';
import { TaskFilters, taskFiltersToURLSearchParams } from '../models/user-settings/task-settings.model';
import { ClientTaskHistoryItem } from '../models/client-task-history-item.model';

export class PagedClientReferralTasks implements PagedResults {
  clientReferralTasks: ClientReferralTask[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.clientReferralTasks;
  }
}

const LegacySortColumn = 'needsReferralDocumentation';

// we'll switch over to cursor based paging as part of:
// https://www.pivotaltracker.com/story/show/174781090
class NextClientReferralTaskProvider implements NextQueueItemProvider {
  constructor(private clientReferralTasksService: ClientReferralTasksService) {}

  nextItemInQueueRequest(currentPage: number, pageSize: number, filters: TaskFilters) {
    return this.nextClientTaskRequest(currentPage, pageSize, filters);
  }

  nextItemFromStartRequest() {
    return this.nextClientTaskRequest(1, 1, null);
  }

  private nextClientTaskRequest(currentPage: number, pageSize: number, filters: TaskFilters) {
    const paging = { page: currentPage, pageSize };

    return this.clientReferralTasksService.getList(paging, filters).pipe(
      map(resultsData => {
        const results = new PagedClientReferralTasks();
        return Object.assign(results, resultsData);
      })
    );
  }
}

@Injectable()
export class ClientReferralTasksService extends ApiService {
  constructor(private http: HttpClient, private queueProcessingService: QueueProcessingService) {
    super();
  }

  get(id: number): Observable<ClientReferralTask> {
    return this.http.get<ClientReferralTask>(`${environment.captureApi.url}/client_referral_tasks/${id}`);
  }

  getList(
    paging: { page: number; pageSize: number },
    filters: TaskFilters = null,
    sort: Sort = null
  ): Observable<PagedClientReferralTasks> {
    const searchParams = taskFiltersToURLSearchParams(filters, paging, sort, LegacySortColumn);
    return this.http.get<PagedClientReferralTasks>(
      `${environment.captureApi.url}/client_referral_tasks?${searchParams.toString()}`
    );
  }

  getSources(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.captureApi.url}/client_referral_tasks/sources`);
  }

  getManufacturers(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.captureApi.url}/client_referral_tasks/manufacturers`);
  }

  update(id: number, attributes: Record<string, unknown>): Observable<ClientReferralTask> {
    return this.http.patch<ClientReferralTask>(`${environment.captureApi.url}/client_referral_tasks/${id}`, {
      task: attributes,
    });
  }

  updateChoices(
    id: number,
    choice: string,
    choiceReasons: string[],
    choiceOtherReasonNote: string,
    choiceSpecialityReferralSpeciality: string
  ): Observable<ClientReferralTask> {
    return this.update(id, { choice, choiceReasons, choiceOtherReasonNote, choiceSpecialityReferralSpeciality });
  }

  updateRejectedPrescription(id: number, rejectedPrescriptionIds: number[]): Observable<ClientReferralTask> {
    return this.update(id, { rejectedPrescriptionIds });
  }

  updateNotes(id: number, notes: string): Observable<ClientReferralTask> {
    return this.update(id, { notes });
  }

  submit(
    id: number,
    collectConsultNote: boolean
  ): Observable<{ clientReferralTask: ClientReferralTask; clientConsultNoteTaskId: number }> {
    return this.http.patch<{ clientReferralTask: ClientReferralTask; clientConsultNoteTaskId: number }>(
      `${environment.captureApi.url}/client_referral_tasks/${id}/submit`,
      {
        collectConsultNote,
      }
    );
  }

  skipToNextTask(currentTask: ClientReferralTask): Observable<NextQueueItem<ClientReferralTask>> {
    const provider = new NextClientReferralTaskProvider(this);
    return this.queueProcessingService.skipToNextItem<ClientReferralTask>(
      UserSettings.clientPortalReferralTaskQueueSettings,
      provider,
      currentTask
    );
  }

  getClientTaskHistoryItems(id: number): Observable<ClientTaskHistoryItem[]> {
    return this.http.get<ClientTaskHistoryItem[]>(
      `${environment.captureApi.url}/client_referral_tasks/${id}/history_items`
    );
  }
}
