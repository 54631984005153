<div *ngIf="outboundFax.inboundFaxes.length > 0; else: noFaxes">
  Yes -
  <br />
  <div *ngFor="let inboundFax of outboundFax.inboundFaxes">
    {{ inboundFax.status | faxStatus }}:
    <span *ngIf="inboundFax.status === inboundFaxStatuses.notValidEvidence">{{ inboundFax.statusReason | capitalize }}</span>
    <a target="_blank" routerLink="/capture-admin/consult-notes/{{ inboundFax.patientAttachmentId }}">
      {{ inboundFax.receivedAt | date: 'M/d/yyyy' }}
    </a>
  </div>
</div>

<ng-template #noFaxes>No</ng-template>
