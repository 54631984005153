import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Provider } from "app/core/models/provider.model";
import { ProviderService } from 'app/core/services/provider.service';

@Component({
  selector: 'app-capture-admin-provider-communications',
  templateUrl: './capture-admin-provider-communications.component.html',
  styleUrls: ['./capture-admin-provider-communications.component.scss'],
})
export class CaptureAdminProviderCommunicationsComponent implements OnInit {

  provider: Provider;
  loading: boolean;

  constructor(
    private providerService: ProviderService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.init();
  }

  private init() {
    const npi = this.route.snapshot.paramMap.get('npi');
    this.loading = true;

    this.providerService.getByNpi(npi).subscribe(provider => {
      this.provider = provider;
      this.loading = false;
    });
  }
}