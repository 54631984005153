import { Capture } from '../models/capture.model';
import { CaptureStatus } from '../enums/capture-status.enum';
import { CaptureStatusReason } from '../enums/capture-status-reason.enum';

const prescriberIsCeProviderInternalConsult = (capture: Capture) =>
  (capture.ehrAccess &&
    capture.status === CaptureStatus.ceReview &&
    capture.statusReason === CaptureStatusReason.prescriberIsCeProvider) ||
  (capture.status === CaptureStatus.verifiedClientPrescriptionInternalConsultant) ||
  (capture.status === CaptureStatus.rejected &&
    capture.statusReason === CaptureStatusReason.notAValidCePrescriptionInternalConsultant);

export const showValidateReferral = (capture: Capture) =>
  capture.status !== CaptureStatus.verifiedClientPrescriberList &&
  !prescriberIsCeProviderInternalConsult(capture);

export const showClientPrescriberTask = (capture: Capture) =>
  !!capture.clientPrescriberTask && capture.status === CaptureStatus.verifiedClientPrescription;

export const showClientReferralTask = (capture: Capture) => capture.clientReferralTask?.submitted;

export const showClientConsultNoteTask = (capture: Capture) =>
  !!capture.clientConsultNoteTask && capture.status === CaptureStatus.verifiedClientHasConsultNote;

export const showCeReviewDetails = (capture: Capture) =>
  showClientPrescriberTask(capture) || showClientConsultNoteTask(capture) || showClientReferralTask(capture);

export const showPatientDocuments = (capture: Capture) =>
  capture.status !== CaptureStatus.verifiedClientPrescription &&
  capture.status !== CaptureStatus.verifiedClientHasConsultNote &&
  capture.status !== CaptureStatus.verifiedClientPrescriberList &&
  !prescriberIsCeProviderInternalConsult(capture);

export const showClientPrescriptionMatches = (capture: Capture) =>
  !!capture.supportsClientPrescriptionMatches;

export const showConsultNoteVerification = (capture: Capture) =>
  capture.status === CaptureStatus.verifiedClientHasConsultNote;

export const showValidatePrescriptionWrittenAtCe = (capture: Capture) =>
  prescriberIsCeProviderInternalConsult(capture);
