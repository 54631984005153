import { UserQueueSettings } from '../../interfaces/user-queue-settings';
import { LimitOffsetPaging } from '../paged-results/limit-offset-paging.model';

export class OfficeFilters {
  name: string = null;
  phone: string = null;
  fax: string = null;
  city: string = null;
  state: string = null;
  status: string[] = [];
}

export class OfficeListSettings {
  filters: OfficeFilters = new OfficeFilters();
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
}

export class OfficeQueueSettings extends OfficeListSettings implements UserQueueSettings {
  position: number;
}
