import { Component, Input, OnInit } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { InboundFaxesService } from '../../../core/services/inbound-faxes.service';
import { InboundFaxCapture } from '../../../core/models/capture-lists/inbound-fax-capture.model';

@Component({
  selector: 'app-inbound-fax-captures',
  templateUrl: './inbound-fax-captures.component.html'
})
export class InboundFaxCapturesComponent implements OnInit {
  @Input() inboundFax: InboundFax;

  loading = true;
  captures: InboundFaxCapture[] = [];

  constructor(private inboundFaxesService: InboundFaxesService) {}

  ngOnInit() {
    this.loadCaptures();
  }

  private loadCaptures() {
    this.inboundFaxesService.getCaptures(this.inboundFax).subscribe((captures: InboundFaxCapture[]) => {
      this.loading = false;
      this.captures = captures;
    });
  }
}
