import { Injectable } from '@angular/core';
import { interval, Observable, Subscription, EMPTY } from 'rxjs';
import { forEach } from 'lodash-es';
import { WebsocketChannelHandle } from '../models/websocket-channel-handle.model';
import { AuthenticationService } from './authentication.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class UserPresenceService {
  private channels: Record<string, WebsocketChannelHandle> = {};
  private timers: Record<string, Subscription> = {};

  constructor(
    private authService: AuthenticationService,
    private websocketService: WebsocketService
  ) {}

  subscribe(category: string, id: string | number): void {
    if (!this.isInternalUser) return;

    const name = this.getName(category, id)
    if (!this.channels[name]) {
      const channel = this.websocketService.channelHandle("UserPresenceChannel", { category, id });
      this.channels[name] = channel;
    }
  }

  unsubscribe(category: string, id: string | number): void {
    if (!this.isInternalUser) return;

    const name = this.getName(category, id)
    this.channels[name]?.unsubscribe();
    delete this.channels[name];
  }

  unsubscribeAll(): void {
    if (!this.isInternalUser) return;

    forEach(this.channels, (channel: WebsocketChannelHandle) => channel.unsubscribe());
    this.channels = {};
  }

  join(category: string, id: string | number): void {
    if (!this.isInternalUser) return;

    const name = this.getName(category, id)
    const channel = this.channels[name];
    channel?.connected.subscribe(() => {
      channel.perform("join");
      const timer = interval(30000).subscribe(() => channel.perform("present"));
      this.timers[name] = timer;
    });
  }

  subscribeAndJoin(category: string, id: string | number): void {
    this.subscribe(category, id);
    this.join(category, id);
  }

  leave(category: string, id: string | number): void {
    if (!this.isInternalUser) return;

    const name = this.getName(category, id)
    this.channels[name]?.perform("leave");
    this.timers[name]?.unsubscribe();
    delete this.timers[name];
  }

  leaveAndUnsubscribe(category: string, id: string | number): void {
    this.leave(category, id);
    this.unsubscribe(category, id);
  }

  getMessages(category: string, id: string | number): Observable<any> {
    if (!this.isInternalUser) return EMPTY;

    // ensure we are subscribed to the channel
    this.subscribe(category, id);

    const name = this.getName(category, id)
    return this.channels[name]?.messageReceived;
  }

  private getName(category: string, id: string | number): string {
    return `${category}-${id}`;
  }

  private get isInternalUser(): boolean {
    return this.authService.isCaptureAdminUser ||
           this.authService.isInternalConsultantUser ||
           !!this.authService.viewingAsClient;
  }

}
