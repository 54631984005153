import { Component, Input, OnInit } from '@angular/core';
import { ClientPrescriberTask } from '../../../core/models/client-prescriber-task.model';

@Component({
  selector: 'app-client-prescriber-task-header',
  templateUrl: './client-prescriber-task-header.component.html',
  styleUrls: ['./client-prescriber-task-header.component.scss'],
})
export class ClientPrescriberTaskHeaderComponent implements OnInit {
  @Input() task: ClientPrescriberTask;
  @Input() displayUserPresence = true;

  constructor() {}

  ngOnInit() {}
}
