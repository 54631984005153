import { Component, Input } from '@angular/core';
import { Patient } from 'app/core/models/patient.model';

@Component({
  selector: 'app-patient-communications',
  templateUrl: './patient-communications.component.html',
  styleUrls: ['./patient-communications.component.scss'],
})
export class PatientCommunicationsComponent {
  @Input() patient: Patient;
}