const { versionInfo } = require('../../../version');

export class AppVersion {
  public static humanize(withDate = false): string {
    if (withDate) {
      return `${versionInfo.version}-${versionInfo.hash}-${versionInfo.commitDate}`;
    } else {
      return `${versionInfo.version}-${versionInfo.hash}`;
    }
  }
}
