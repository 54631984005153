import { Component, Input } from '@angular/core';
import { Report, AppliedReportOptions, AppliedReportOptionAssociations } from 'app/core/models/report.model';

@Component({
  selector: 'app-report-applied-client-option',
  templateUrl: './report-applied-client-option.component.html',
})
export class ReportAppliedClientOptionComponent {
  @Input() name = 'clientIds';
  @Input() label = 'Clients';
  @Input() options: AppliedReportOptions;
  @Input() optionAssociations: AppliedReportOptionAssociations;

  constructor() {}

  get clientNames() {
    const clientIds = [this.options[this.name]].filter(Boolean).flat();

    if (clientIds && clientIds.length > 0) {
      return clientIds.map(clientId => {
        const optionAssociations = this.optionAssociations[this.name] || {};
        const client = optionAssociations[clientId];
        return client && client.humanizedName;
      });
    } else {
      return ['ALL'];
    }
  }

  get names() {
    return this.clientNames.join(', ');
  }
}
