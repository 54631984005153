import { Component, Input, OnInit } from '@angular/core';
import { TaskSubmission } from '../../../core/models/task-submission.model';

@Component({
  selector: 'app-client-task-submitted-prescription',
  templateUrl: './client-task-submitted-prescription.component.html',
  styleUrls: ['./client-task-submitted-prescription.component.scss'],
})
export class ClientTaskSubmittedPrescriptionComponent implements OnInit {
  @Input() verified: boolean;
  @Input() rejected: boolean;
  @Input() submission: TaskSubmission;

  constructor() {}

  ngOnInit() {}
}
