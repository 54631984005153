import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PatientPrescriberOffice } from '../models/patient-prescriber-office.model';
import { ApiService } from './api.service';

@Injectable()
export class PatientPrescriberOfficesService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    patientId: number,
    prescriberId: number
  ): Observable<{ patientPrescriberOffices: PatientPrescriberOffice[] }> {
    const filterParams: URLSearchParams = new URLSearchParams();
    filterParams.append('patient_id', patientId.toString());
    filterParams.append('prescriber_id', prescriberId.toString());

    return this.http.get<{ patientPrescriberOffices: PatientPrescriberOffice[] }>(
      `${environment.captureApi.url}/patient_prescriber_offices?${filterParams.toString()}`
    );
  }
}
