<form (ngSubmit)="onSubmit()">
  <div class="d-flex align-items-center" *ngIf="showOutcomeReasons">
    <div class="mb-3 w-50 me-2">
      <select class="form-select" [(ngModel)]="formModel.transition" (change)="onTransitionChange()" name="transition">
        <option [ngValue]="null" [selected]="true"> -- Select Outcome -- </option>
        <option *ngFor="let statusOption of transitionOptions" [ngValue]="statusOption.value">
          {{ statusOption.display }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <button type="submit" class="btn btn-primary" [disabled]="!allowSubmit" [hidden]="!formModel.transition">
        <span [hidden]="verificationRequestPending">Process Capture</span>
        <span [hidden]="!verificationRequestPending">
          <fa-icon [icon]="['fas', 'hourglass-half']" size="sm" /> Process Capture
        </span>
      </button>
    </div>
  </div>

  <div class="alert alert-danger" *ngIf="showBlockingReversalExemptionPrompt">
    <strong>There is a reversed capture in the pool of this capture.</strong>

    <div class="form-check">
      <input
        type="checkbox"
        name="blockingReversalExempt"
        [(ngModel)]="formModel.verificationOutcome.blockingReversalExempt"
        (change)="onBlockingReversalExemptChange()"
        class="form-check-input"
        id="blockingReversalExempt" />

      <label class="form-check-label" for="blockingReversalExempt">
        Verify this capture and all related WIP captures even though there is a reversal in the pool.
      </label>
    </div>
  </div>

  <app-verification-validation [key]="validationKey" />

  <div class="alert alert-danger" *ngIf="showValidationSummary">
    Capture cannot be verified. Please fix the issues above.
  </div>

  <div [hidden]="!formModel.transition">
    <div [hidden]="!reasonRequired">
      <div class="mb-3">
        <strong>Select Reason</strong>
        <div class="form-check my-2" *ngFor="let reasonOption of reasonOptions">
          <input
            (change)="onRadioStatusReasonChange(reasonOption.value)"
            [id]="reasonOption.value"
            [value]="reasonOption.value"
            [(ngModel)]="formModel.verificationOutcome.statusReason"
            class="form-check-input"
            name="statusReason"
            type="radio"
          />
          <label class="form-check-label" [for]="reasonOption.value"> {{ reasonOption.display }} </label>
        </div>
      </div>
    </div>

    <div class="mb-3" *ngIf="showCeReviewClientNoteInput">
      <label for="ceReviewNote">NOTES FOR REFERRAL AND CONSULT NOTE TASKS</label>
      <textarea
        class="form-control"
        id="ceReviewNote"
        name="ceReviewNote"
        [(ngModel)]="formModel.verificationOutcome.ceReviewNote">
      </textarea>
    </div>
  </div>
  <div class="mb-3">
    <label for="additionalDetails">
      ADDITIONAL DETAILS <span class="text-danger" *ngIf="additionalDetailsRequired">(required)</span>
    </label>
    <div class="mb-2"><em>All additional details for this capture are available on Capture History.</em></div>
    <textarea
      class="form-control"
      id="additionalDetails"
      rows="3"
      name="additionalDetails"
      [(ngModel)]="formModel.verificationOutcome.additionalDetails"
      (input)="onAdditionalDetailsChange()"
      placeholder="{{ additionalDetailsPlaceholder }}"
    ></textarea>
    <div [hidden]="formModel.transition" class="mt-3">
      <button class="btn btn-primary" [disabled]="!hasAdditionalDetails">Submit Details</button>
    </div>
  </div>
</form>
