import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { UserTimingService } from '../../../core/services/user-timing.service';
import { UserPresenceService } from 'app/core/services/user-presence.service';

@Component({
  selector: 'app-consult-note-prescriptions',
  templateUrl: './consult-note-prescriptions.component.html',
  styleUrls: ['./consult-note-prescriptions.component.scss'],
})
export class ConsultNotePrescriptionsComponent implements OnInit, OnDestroy {
  patientAttachmentId: string;
  patientAttachment: PatientAttachment;
  refreshing = true;

  constructor(
    private route: ActivatedRoute,
    private patientAttachmentsService: PatientAttachmentsService,
    private navigationService: NavigationService,
    private titleService: Title,
    private authService: AuthenticationService,
    private userTimingService: UserTimingService,
    private userPresenceService: UserPresenceService
  ) {
    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  ngOnInit() {
    this.patientAttachmentId = this.route.snapshot.paramMap.get('id');
    this.loadPatientAttachment(this.patientAttachmentId);
    this.userPresenceService.subscribeAndJoin("consultNote", this.patientAttachmentId)
  }

  ngOnDestroy() {
    this.stopTimeTracking();
    this.userPresenceService.leaveAndUnsubscribe("consultNote", this.patientAttachmentId)
  }

  private loadPatientAttachment(patientAttachmentId): void {
    this.refreshing = true;
    this.patientAttachmentsService.get(patientAttachmentId).subscribe(
      (patientAttachment: PatientAttachment) => {
        this.refreshing = false;
        this.patientAttachment = patientAttachment;
        this.startTimeTracking(this.patientAttachment);
        this.setTitle();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.navigationService.notFound();
        } else {
          console.error(err);
        }
      }
    );

    window.scrollTo(0, 0);
  }

  private setTitle() {
    const title = `R1 340B Recovery - ${this.patientAttachment.id} | Prescriptions`;
    this.titleService.setTitle(title);
  }

  private handleRouteChange(paramsMap) {
    const params = paramsMap['params'];
    const patientAttachmentId = params && params['id'];

    if (this.navigationService.shouldReloadPage(this.patientAttachment, patientAttachmentId)) {
      this.loadPatientAttachment(patientAttachmentId);
    }
  }

  private startTimeTracking(patientAttachment) {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.track({
        page: 'consult_note_prescriptions',
        recordId: patientAttachment.id,
        status: patientAttachment.status,
      });
    }
  }

  private stopTimeTracking() {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.stop();
    }
  }
}
