import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportOption } from 'app/core/models/report-option.model';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { ClientsService } from 'app/core/services/clients.service';

export class ReportClientEntityTypeOption implements ReportOption {
  value: string;

  constructor(value) {
    this.value = value;
  }

  toPostData(options = { paramName: 'clientEntityTypesClientIds' }) {
    return { [options.paramName]: this.value };
  }
}

@Component({
  selector: 'app-report-client-entity-type-option',
  templateUrl: './report-client-entity-type-option.component.html',
  styleUrls: ['./report-client-entity-type-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportClientEntityTypeOptionComponent),
    },
  ],
})
export class ReportClientEntityTypeOptionComponent extends ReportAbstractOptionComponent implements OnInit {
  @Input() label = 'Filter by Entity Type';
  @Input() multiple = true;

  selectedEntityTypes: string[] | string;
  entityTypes: string[];

  constructor(private clientsService: ClientsService) {
    super();
  }

  ngOnInit() {
    this.loadEntityTypes();
  }

  private loadEntityTypes() {
    this.clientsService.getEntityTypes().subscribe(result => {
      this.entityTypes = result;
    });
  }

  protected updateInternalState(model: ReportClientEntityTypeOption) {
    if (model) {
      this.selectedEntityTypes = model.value;
    }
  }

  protected determineModelStateOnChange(): ReportClientEntityTypeOption {
    if (this.selectedEntityTypes && (!Array.isArray(this.selectedEntityTypes) || this.selectedEntityTypes.length > 0)) {
      return new ReportClientEntityTypeOption(this.selectedEntityTypes);
    } else {
      return null;
    }
  }
}
