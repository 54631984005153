import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService } from 'app/core/services/clients.service';
import { Client } from 'app/core/models/client.model';
import { InboundFax } from 'app/core/models/inbound-fax.model';
import { Upload } from '../../../core/models/upload.model';
import { InboundFaxesService } from 'app/core/services/inbound-faxes.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';

@Component({
  templateUrl: './capture-admin-fax-upload.component.html',
})
export class CaptureAdminFaxUploadComponent implements OnInit {
  selectedClientId: number = null;
  clients: Client[] = [];
  callerNumber: string = null;
  calledNumber: string = null;
  pages = 1;
  assignToMe: boolean;
  uploadingFile = false;
  hasError = false;
  errors: string[] = [];


  constructor(
    private clientsService: ClientsService,
    private authService: AuthenticationService,
    private inboundFaxesService: InboundFaxesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  canUpload(): boolean {
    return this.authService.isCaptureAdminUser && this.hasClient() && this.hasCallerNumber() && this.hasCalledNumber();
  }

  hasClient(): boolean {
    return this.selectedClientId != null;
  }

  hasCallerNumber(): boolean {
    return this.callerNumber != null && this.callerNumber.length > 0;
  }

  hasCalledNumber(): boolean {
    return this.calledNumber != null && this.calledNumber.length > 0;
  }

  onClientChange() {
    const client = this.clients.find(c => c.id === this.selectedClientId);
    this.calledNumber = client.faxLocal || client.faxTollfree || '5551234567';
  }

  onUploadStart(): void {
    this.uploadingFile = true;
  }

  onUploadComplete(upload: Upload): void {
    this.inboundFaxesService
      .create(this.selectedClientId, this.calledNumber, this.callerNumber, this.pages, this.assignToMe, upload.signedId)
      .subscribe(
        (inboundFax: InboundFax) => {
          this.handleFaxCreation(inboundFax);
        },
        (error: HttpErrorResponse) => {
          this.handleErrorResponse(error);
        }
      );
  }

  onUploadError(error: Error): void {
    this.uploadingFile = false;
    console.error(error);
  }

  public get displayAssignToMe(): boolean {
    return this.authService.hasPermissionTo(UserRoleName.faxQueue);
  }

  private handleFaxCreation(_inboundFax: InboundFax) {
    this.router.navigate(['/capture-admin/communications/inbound-faxes']);
  }

  private handleErrorResponse(error: HttpErrorResponse) {
    this.hasError = true;
    this.errors = error.error.errors;
    this.uploadingFile = false;
  }
}
