import { Component, Input } from '@angular/core';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { checkWrittenDatesAgainstEncounterDates, WrittenDateWithInRangeStatus } from 'app/core/lib/verification-utils';
import { DateUtils } from 'app/core/lib/date-utils';
import { uniq } from 'lodash-es';

@Component({
  selector: 'app-validate-specialist-encounter-relevant-written-dates',
  templateUrl: './validate-specialist-encounter-relevant-written-dates.component.html',
  styleUrls: ['./validate-specialist-encounter-relevant-written-dates.component.scss'],
})
export class ValidateSpecialistEncounterRelevantWrittenDatesComponent {
  @Input() collapsed: boolean;

  encounterDates: string[] = [];
  _writtenDates: Date[];
  _patientAttachment: PatientAttachment;
  checkedWrittenDates: WrittenDateWithInRangeStatus[]

  get writtenDates(): Date[] {
    return this._writtenDates || [];
  }

  @Input()
  set writtenDates(dates: Date[]) {
    this._writtenDates = dates;
    this.updateDateChecks();
  }

  @Input()
  set patientAttachment(patientAttachment: PatientAttachment) {
    this._patientAttachment = patientAttachment;
    this.encounterDates =
      uniq(patientAttachment.specialistEncounterVerifications.
           map(verification => DateUtils.toServerFormat(verification.encounterDate)));

    this.updateDateChecks();
  }

  get patientAttachment() {
    return this._patientAttachment;
  }

  private updateDateChecks() {
    const timeframe = this.patientAttachment.client.specialistEncounterTimeframeInMonths;
    this.checkedWrittenDates = checkWrittenDatesAgainstEncounterDates(this.writtenDates,
                                                                      this.encounterDates,
                                                                      timeframe);
  }
}
