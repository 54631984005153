import { UserQueueSettings } from '../../interfaces/user-queue-settings';
import { LimitOffsetPaging, Sort } from '../paged-results/limit-offset-paging.model';
import { OfficeStatus } from 'app/core/enums/office-status.enum';

export class OfficeStatusReviewFilters {
  reviewStatus: OfficeStatus[] = []
}

export class OfficeStatusReviewReviewListSettings {
  filters: OfficeStatusReviewFilters;
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  sort: Sort;
}

export class OfficeStatusReviewQueueSettings
  extends OfficeStatusReviewReviewListSettings implements UserQueueSettings {

  position: number;
}
