import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './capture-admin-user-timing-reports.component.html',
})
export class CaptureAdminUserTimingReportsComponent {
  reportsUrl = `${environment.captureApi.url}/user_timing_reports`;
  reportsCountUrl = `${environment.captureApi.url}/user_timing_reports/count`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    startedDate: [null],
  });
}
