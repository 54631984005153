import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countWithMax',
})
export class CountWithMaxPipe implements PipeTransform {
  transform(count: number, max: number): string | number {
    if (count > max) {
      return `${max}+`
    } else {
      return count;
    }
  }
}
