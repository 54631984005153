import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './partner-portal-match-reports.component.html',
})
export class PartnerPortalMatchReportsComponent {
  reportsUrl = `${environment.captureApi.url}/partner_capture_reports`;
  reportsCountUrl = `${environment.captureApi.url}/partner_capture_reports/count`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    verifiedDate: [null],
  });
}
