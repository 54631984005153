<app-ce-review-task-content [task]="clientPrescriberTask">
  <div class="col">
    <div><strong>CE REVIEW OUTCOME</strong></div>
    <div>{{ clientPrescriberTask.humanizedStatus }}</div>
  </div>
  <div class="col">
    <div><strong>REVIEW DATE</strong></div>
    <div>{{ clientPrescriberTask.processedAt | date: 'MM/dd/yyyy hh:mm a' }}</div>
  </div>
  <div class="col">
    <div><strong>REVIEWED BY</strong></div>
    <div>{{ clientPrescriberTask.processedBy ? clientPrescriberTask.processedBy.username : null | blankValueDash }}</div>
  </div>
</app-ce-review-task-content>
