import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Patient } from 'app/core/models/patient.model';
import { PatientService } from 'app/core/services/patient.service';

@Component({
  selector: 'app-patient-deactivate-button',
  templateUrl: './patient-deactivate-button.component.html',
  styleUrls: ['./patient-deactivate-button.component.scss'],
})
export class PatientDeactivateButtonComponent {
  @Input() patient: Patient;

  @Output() public patientDeactivated = new EventEmitter<Patient>();

  enableDeactivateDate: boolean;
  showDeactivateDateIsRequiredMessage: boolean;
  deactivateDate: Date;

  constructor(private patientService: PatientService) {}

  public get active(): boolean {
    return this.patient && this.patient.inactiveDate === null;
  }

  public showDeactivateDate($event) {
    $event.preventDefault();
    this.enableDeactivateDate = true;
  }

  public hideDeactivateDate($event) {
    $event.preventDefault();
    this.enableDeactivateDate = false;
  }

  public saveDeactivateDate($event) {
    $event.preventDefault();

    if (this.deactivateDate) {
      this.showDeactivateDateIsRequiredMessage = false;
      this.patientService.deactivate(this.patient.id, this.deactivateDate).subscribe(patient => {
        this.patient = patient;
        this.patientDeactivated.emit(patient);
      });
    } else {
      this.showDeactivateDateIsRequiredMessage = true;
    }
  }
}
