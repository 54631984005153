import { InternalConsultantCaptureCursorType } from 'app/core/enums/internal-consultant-capture-cursor-type.enum';
import { CaptureStatusReason } from '../../enums/capture-status-reason.enum';
import { ClaimSource } from '../../enums/claim-source.enum';

export class InternalConsultantCaptureFilters {
  constructor(
    public reasons: CaptureStatusReason[] = [],
    public clientIds: number[] = [],
    public sources: ClaimSource[] = [],
    public specialtyStore: boolean = false,
    public highValue: boolean = false,
    public activeClientsOnly: boolean = true,
    public expiringWithinDays: string | null = null,
    public assignedUserIds: number[] = [],
  ) {}
}

export class InternalConsultantCaptureSettings {
  constructor(
    public filters: InternalConsultantCaptureFilters = new InternalConsultantCaptureFilters(),
    public cursorType: InternalConsultantCaptureCursorType =
      InternalConsultantCaptureCursorType.highestPatientEstimatedValue
  ) {}
}
