import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ClientPrescriberTask } from '../../../core/models/client-prescriber-task.model';
import { ClientPrescriberTaskStatus } from '../../../core/enums/client-prescriber-task-status.enum';
import { isNull } from 'lodash-es';
import { ClientPrescriberTasksService } from '../../../core/services/client-prescriber-tasks.service';
import { TaskTransition } from '../../../core/enums/task-transition.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-client-prescriber-task-verification',
  templateUrl: './client-prescriber-task-verification.component.html',
})
export class ClientPrescriberTaskVerificationComponent implements OnInit, OnChanges {
  formModel = null;
  serverError = false;
  serverErrorMsg: string;
  disabled = false;

  @Input() task: ClientPrescriberTask;
  @Input() formDisabled: boolean;

  @Output() public taskTransition = new EventEmitter<ClientPrescriberTask>();

  constructor(private clientPrescriberTasksService: ClientPrescriberTasksService) {}

  get saveDisabled(): boolean {
    return isNull(this.formModel.yesNo) || this.task.status !== ClientPrescriberTaskStatus.open;
  }

  onSubmit() {
    if (!isNull(this.formModel.yesNo)) {
      const taskTransition = this.formModel.yesNo === true ? TaskTransition.accept : TaskTransition.reject;
      this.clientPrescriberTasksService.transition(this.task.id, taskTransition, this.formModel.notes).subscribe(
        task => {
          this.serverError = false;
          this.serverErrorMsg = '';
          this.taskTransition.emit(task);
        },
        (err: HttpErrorResponse) => {
          this.serverError = true;
          this.serverErrorMsg = this.resolveErrorMessage(err);
          console.error(err);
        }
      );
    }
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  private init() {
    const { status, notes } = this.task;

    let yesNo = null;
    let locked = false;

    if (status === ClientPrescriberTaskStatus.accepted) {
      yesNo = true;
      locked = true;
    } else if (status === ClientPrescriberTaskStatus.rejected) {
      yesNo = false;
      locked = true;
    }

    this.formModel = { yesNo, notes, locked };

    this.disabled = this.formDisabled || locked;
  }

  private resolveErrorMessage(error: HttpErrorResponse): string {
    return error.error['errors'] || this.reversedClaimError;
  }

  public get additionalDetailsRequired() {
    return this.formModel.yesNo === false;
  }

  private get reversedClaimError() {
    return "We're sorry, this task cannot be approved, because it includes a claim that \
      has been reversed. Please contact your 340B Support team if you have any questions.";
  }
}
