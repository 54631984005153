import { LimitOffsetPaging } from '../paged-results/limit-offset-paging.model';

export class InboundCallFilters {
  selectedClientIds = [];
}

export class InboundCallListSettings {
  filters: InboundCallFilters = new InboundCallFilters();
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
}
