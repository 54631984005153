import { Component, OnInit } from '@angular/core';
import { LimitOffsetPaging, Sort } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ProviderMechanicalTurkReview } from 'app/core/models/provider-mechanical-turk-review.model';
import { ProviderMechanicalTurkReviewQueueSettings } from 'app/core/models/user-settings/provider-mechanical-turk-review-settings.model';
import { ProviderInformationCollectionDetails } from 'app/core/models/provider-information-collection-details.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { PagedProviderMechanicalTurkReviews, ProviderService } from 'app/core/services/provider.service';
import { keyedStatusOptions as keyedProviderStatusOptions } from 'app/core/options/provider-status.opts';
import { keyedResultOptions } from 'app/core/options/provider-information-collection-result-issue.opts';
import { keyedStatusOptions as keyedOfficeStatusOptions } from 'app/core/options/office-status.opts';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Provider } from 'app/core/models/provider.model';

enum ProviderMechanicalTurkReviewSortBy {
  estimatedValue = 'prescriber_estimated_value',
  nextClaimExpirationDate = 'prescriber_next_claim_expiration_date',
  mechanicalTurkTaskCreatedAt = 'mechanical_turk_task_created_at',
}

@Component({
  selector: 'app-capture-admin-providers-mechanical-turk-queue',
  templateUrl: './capture-admin-providers-mechanical-turk-queue.component.html',
  styleUrls: ['./capture-admin-providers-mechanical-turk-queue.component.scss'],
})
export class CaptureAdminProvidersMechanicalTurkQueueComponent implements OnInit {
  sort = new Sort();
  providers: ProviderMechanicalTurkReview[] = [];
  loading = false;
  paging = LimitOffsetPaging.empty;
  keyedProviderStatusOptions = keyedProviderStatusOptions;
  keyedOfficeStatusOptions = keyedOfficeStatusOptions;

  formGroup: FormGroup;

  sortByOptions = [
    { value: ProviderMechanicalTurkReviewSortBy.estimatedValue, display: 'Estimated Value' },
    { value: ProviderMechanicalTurkReviewSortBy.nextClaimExpirationDate, display: 'Nearest Expiration' },
    { value: ProviderMechanicalTurkReviewSortBy.mechanicalTurkTaskCreatedAt, display: 'HIT Submitted On' },
  ];

  constructor(
    private providerService: ProviderService,
    private userSettingsService: UserSettingsService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.loadQueueSettings();
    this.loadProviders();
    this.initFormGroup();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadProviders();
    this.saveQueueSettings();
  }

  onPageChange() {
    this.loadProviders();
    this.saveQueueSettings();
  }

  onProviderStatusChange(_provider: Provider) {
    this.loadProviders(false);
  }

  collectionIssues(informationCollectionDetails: ProviderInformationCollectionDetails) {
    if (informationCollectionDetails?.resultIssues) {
      return informationCollectionDetails.
        resultIssues.
        map(result => keyedResultOptions[result]?.display).
        join(', ');
    }
  }

  private loadQueueSettings() {
    const queueSettings: ProviderMechanicalTurkReviewQueueSettings =
      this.userSettingsService.get<ProviderMechanicalTurkReviewQueueSettings>(
        UserSettings.captureAdminProviderMechanicalTurkReviewQueueSettings
      );

    if (queueSettings) {
      this.paging = queueSettings.paging;
      this.sort = queueSettings.sort;
    } else {
      this.sort = new Sort(ProviderMechanicalTurkReviewSortBy.estimatedValue);
    }
  }

  private saveQueueSettings() {
    const queueSettings = new ProviderMechanicalTurkReviewQueueSettings();
    queueSettings.paging = this.paging;
    queueSettings.sort = new Sort(this.formGroup.value.sort);

    this.userSettingsService.
      save<ProviderMechanicalTurkReviewQueueSettings>(
        UserSettings.captureAdminProviderMechanicalTurkReviewQueueSettings,
        queueSettings
      );
  }

  private loadProviders(showLoading = true) {
    const paging = {
      page: this.paging.currentPage,
      pageSize: this.paging.pageSize
    };

    if (showLoading) {
      this.loading = true;
    }

    this.providerService.getMechanicalTurkReviewList(paging, this.sort).
      subscribe((result: PagedProviderMechanicalTurkReviews) => {
        this.providers = result.records || [];
        this.paging = result.meta.paging;
        this.loading = false;
      });
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      sort: this.sort.col
    });

    this.formGroup.valueChanges.subscribe(_value => {
      this.paging.currentPage = 1;
      this.sort = new Sort(this.formGroup.value.sort);

      this.saveQueueSettings();

      this.loadProviders();
    })
  }
}
