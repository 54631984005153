<nav class="nav nav-pills">
  <a
    class="nav-item nav-link"
    routerLink="/capture-admin/providers/{{provider.npi}}"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >Provider Details</a>
  <a
    class="nav-item nav-link"
    routerLink="/capture-admin/providers/{{provider.npi}}/communications"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >Communications</a>
  <a
    class="nav-item nav-link"
    routerLink="/capture-admin/providers/{{provider.npi}}/prescriptions"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >Prescriptions</a>
  <a
    class="nav-item nav-link"
    routerLink="/capture-admin/providers/{{provider.npi}}/encounters"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >Encounters</a>
  <a
    class="nav-item nav-link"
    routerLink="/capture-admin/providers/{{provider.npi}}/captures"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >Captures</a>
</nav>
