<div *ngIf="!loadingCapture; else: spinner" class="capture-detail">
  <div class="capture-fixed mb-1"><app-capture-header [capture]="capture" /></div>
  <div *ngIf="!loadingActivities; else: spinner" class="capture-history">
    <table class="table table-striped mt-3">
      <tbody>
        <tr *ngFor="let captureActivity of captureActivities">
          <td class="highlighted">
            <div>{{ captureActivity.time | date: 'MM/dd/yyyy' }}</div>
            <div>{{ captureActivity.time | date: 'hh:mm a' }}</div>
          </td>
          <td>
            <strong>{{ captureActivity.title }}</strong>
            <p [ngSwitch]="captureActivity.action">
              <app-capture-snapshot-activity
                *ngSwitchCase="captureActivityActions.captureSnapshotCreated"
                [capture]="capture"
                [captureSnapshot]="captureActivity.activity"
                (snapshotActivityComplete)="onSnapshotActivityComplete($event)"
              />
              <span *ngSwitchDefault>{{ captureActivity.description }}</span>
            </p>
          </td>
          <td class="highlighted">{{ getUserNameFromCaptureActivity(captureActivity) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
