<dl class="row" *ngIf="claimImport">
  <dt class="col-sm-1">{{ label }}:</dt>
  <dd class="col-sm-11">
    {{ claimImport.name }}
    <div class="import-details">
      ({{ claimImport.imported || 0 | number }} Rows
      | Uploaded {{ claimImport.createdAt | date: 'MM/dd/yyyy' }} by {{ claimImport.user.email }})
    </div>
  </dd>
</dl>
