import { Component, Input, OnInit } from '@angular/core';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { OfficeOutboundFaxQueueSettings } from 'app/core/models/user-settings/office-outbound-fax-search-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { OutboundFax } from 'app/core/models/outbound-fax.model';
import { OutboundFaxesService, PagedOutboundFaxes } from 'app/core/services/outbound-faxes.service';
import { OutboundFaxStatus } from 'app/core/enums/outbound-fax-status.enum';
import { Office } from 'app/core/models/office.model';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';

const trackFaxItemsBy = (index: number, item: OutboundFax) => `${item.id}-${item.status}`;

@Component({
  selector: 'app-office-outbound-faxes',
  templateUrl: './office-outbound-faxes.component.html',
  styleUrls: ['./office-outbound-faxes.component.scss'],
})

export class OfficeOutboundFaxesComponent implements OnInit {
  @Input() office: Office;

  outboundFaxes: OutboundFax[] = [];
  outboundFaxStatuses = OutboundFaxStatus;
  refreshing = false;
  paging: LimitOffsetPaging;
  eligible = true;
  currentSection: string;
  trackItemsBy = trackFaxItemsBy;
  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;

  constructor(
    private outboundFaxesService: OutboundFaxesService,
    private userSettingsService: UserSettingsService,
  ) {}

  get viewing() {
    return Math.min(this.paging.pageSize, this.paging.count);
  }

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.saveQueueSettings();
    this.loadOutboundFaxes(this.paging.currentPage);
  }

  onPageChange(page) {
    this.saveQueueSettings();
    this.loadOutboundFaxes(page);
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.loadQueueSettings();
    this.paging.currentPage = 1;
    this.loadOutboundFaxes(this.paging.currentPage);
  }

  private loadQueueSettings() {
    const queueSettings: OfficeOutboundFaxQueueSettings =
      this.userSettingsService.get<OfficeOutboundFaxQueueSettings>(
        UserSettings.captureAdminOfficeOutboundFaxQueueSettings
      );

    if (queueSettings) {
      this.paging = queueSettings.paging;
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new OfficeOutboundFaxQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    this.userSettingsService.save<OfficeOutboundFaxQueueSettings>(
      UserSettings.captureAdminOfficeOutboundFaxQueueSettings, queueSettings
    );
  }

  private loadOutboundFaxes(page, refreshing = true) {
    const paging = { page, pageSize: this.paging.pageSize };

    this.refreshing = refreshing;

    this.outboundFaxesService.outboundFaxesByFax(
      this.office.fax, paging
    ).subscribe((result: PagedOutboundFaxes) => {
      this.outboundFaxes = result.outboundFaxes || [];
      this.paging = result.meta.paging;
      this.refreshing = false;
    });
  }

  cancel($event, outboundFax) {
    $event.preventDefault();

    this.outboundFaxesService.cancel(outboundFax.id).subscribe((result: OutboundFax) => {
      this.loadOutboundFaxes(this.paging.currentPage, false);
    });
  }

  onSectionClick(section: string) {
    this.currentSection = section;
  }

  navClick($event: Event, eligible: boolean) {
    $event.preventDefault();
    this.eligible = eligible;
    this.loadOutboundFaxes(1);
  }
}
