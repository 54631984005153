export enum CaptureQueueFilterName {
  claimIdentifier = 'claim_identifier',
  captureId = 'capture_id',
  ndc = 'ndc',
  rxReferenceNumber = 'rx_reference_number',
  prescriberNpi = 'prescriber_npi',
  prescriberLastName = 'prescriber_last_name',
  prescriberFirstName = 'prescriber_first_name',
  patientLastName = 'patient_last_name',
  patientFirstName = 'patient_first_name',
  patientDob = 'patient_dob',
  patientPrescriberUuid = 'patient_prescriber_uuid',
  clientIds = 'client_ids[]',
  clientStates = 'client_states[]',
  statuses = 'statuses[]',
  reasons = 'reasons[]',
  sources = 'sources[]',
  specialtyStore = 'specialty_store',
  expiringWithinDays = 'expiring_within_days',
  highValue = 'high_value',
  manualFaxSent = 'manual_fax_sent',
  excludedPatientIds = 'excluded_patient_ids[]',
  excludedPatientPrescriberIds = 'excluded_patient_prescriber_ids[]',
  assignedUserIds = 'assigned_user_ids[]',
  activeClientsOnly = 'active_clients_only',
}
