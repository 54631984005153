import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppVersion } from '../../../core/lib/app-version';
import { supportEmailAddress } from '../../../../app/core/constants';

@Component({
  selector: 'app-fat-footer',
  templateUrl: './fat-footer.component.html',
  styleUrls: ['./fat-footer.component.scss'],
})
export class FatFooterComponent {
  today = new Date();
  version = AppVersion.humanize();
  isProduction = environment.production;
  supportEmailAddress = supportEmailAddress;
  constructor() {}
}
