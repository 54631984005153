import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LlmFeedbackService extends ApiService {

  constructor(private http: HttpClient) {
    super();
  }

  submitCommentFeedback(llmResultId: number, comment: string): Observable<Record<string, number>> {
    return this.http.post<Record<string, number>>(
      `${environment.captureApi.url}/llm_feedbacks`,
      { llmResultId, comment }
    )
  }

  submitResultSetFeedback(llmResultSetId: number, comment: string): Observable<Record<string, number>> {
    return this.http.post<Record<string, number>>(
      `${environment.captureApi.url}/llm_feedbacks/create_result_set_feedback`,
      { llmResultSetId, comment }
    )
  }

}
