import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Batch, BatchDetail, Prompt } from '../models/llm-playground.model';
import { map } from 'rxjs/operators';

@Injectable()
export class LlmPlaygroundBatchesService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  create(projectId: number, name: string, fileSignedId: string): Observable<Batch> {
    return this.http.post<Batch>(`${environment.captureApi.url}/llm_playground/projects/${projectId}/batches`, {
      batch: { name, fileSignedId },
    });
  }

  get(id: number, detail: false): Observable<Batch>;
  get(id: number, detail: true): Observable<BatchDetail>;

  get(id: number, detail = false): Observable<Batch | BatchDetail> {
    const params = new HttpParams().set('detail', detail.toString());
    const url = `${environment.captureApi.url}/llm_playground/batches/${id}`;
    if (detail) {
      return this.http.get<BatchDetail>(url, { params });
    } else {
      return this.http.get<Batch>(url, { params });
    }
  }

  csv(id: number): Observable<{ blob: Blob; filename: string }> {
    return this.http
      .get(`${environment.captureApi.url}/llm_playground/batches/${id}.csv`, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(
        map(response => {
          const contentDisposition = response.headers.get('Content-Disposition') || '';
          const matches = /filename\*=UTF-8''([^"]+)|filename="([^"]+)"/.exec(contentDisposition);
          const filename = matches && (matches[2] ? decodeURIComponent(matches[2]) : 'default-filename.csv');
          return { blob: response.body as Blob, filename };
        })
      );
  }

  destroy(id: number): Observable<Batch> {
    return this.http.delete<Batch>(`${environment.captureApi.url}/llm_playground/batches/${id}`);
  }

  prompt(id: number): Observable<Prompt> {
    return this.http.get<Prompt>(`${environment.captureApi.url}/llm_playground/batches/${id}/prompt`);
  }
}
