import { Component, Input } from '@angular/core';
import { Report, AppliedReportOptions } from 'app/core/models/report.model';

@Component({
  selector: 'app-report-applied-sources-option',
  templateUrl: './report-applied-sources-option.component.html',
})
export class ReportAppliedSourcesOptionComponent {
  @Input() name = 'sources';
  @Input() label = 'Sources';
  @Input() options: AppliedReportOptions;

  get values() {
    const values = this.options[this.name];
    if (values) {
      return values.join(', ');
    }
  }
}
