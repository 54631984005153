<div class="row border py-3 rounded m-0">
  <div class="col-md-auto">
    <strong>MRN</strong>
    <div>{{ task.patient.mrn }}</div>
    <app-user-presence *ngIf="displayUserPresence" category="clientPrescriberTask" [targetId]="task.id" />
  </div>
  <div class="col-md-auto">
    <strong>Patient Name</strong>
    <div>{{ task.patient.fullName }}</div>
    <div *ngIf="task.patient.alternateNames.length > 0">
      <div class="alternate-names"><strong>ALTERNATE NAMES</strong></div>
      <div *ngFor='let alternateName of task.patient.alternateNames'>
        {{ alternateName }}
      </div>
    </div>
  </div>
  <div class="col-md-auto">
    <strong>DOB</strong>
    <div>{{ task.patient.dob | date: 'MM/dd/yyyy' }}</div>
  </div>
  <div class="col-md-auto">
    <strong>Task Type</strong>
    <div>{{ task.humanizedType }}</div>
  </div>
  <div class="col-md-auto">
    <strong>Status</strong>
    <div>{{ task.humanizedStatus }}</div>
  </div>
  <div class="col-md-auto">
    <strong># of Linked Claims</strong>
    <div>{{ task.captureCount }}</div>
  </div>
  <div class="col-md-auto">
    <strong>Value</strong>
    <div><app-task-estimated-value [rank]="task.estimatedValueRank" [value]="task.estimatedValue" /></div>
  </div>
  <div class="col-md-auto" *ngIf="task.processedBy">
    <strong>Submitted By</strong>
    <div>{{ task.processedBy ? task.processedBy.username : null | blankValueDash }}</div>
  </div>
  <div class="col-md-auto" *ngIf="task.processedBy">
    <strong>Submitted At</strong>
    <div>{{ task.processedAt | date: 'MM/dd/yyyy' }}</div>
  </div>
</div>
