<div class="row ps-2 align-items-center">
  <div class="filter select col-2 px-1">
    <ng-select
      class="w-100"
      bindValue="id"
      bindLabel="humanizedName"
      name="clientIds"
      placeholder="Filter by Client"
      [multiple]="true"
      [items]="clients"
      (remove)="onFilterChange()"
      (change)="onFilterChange()"
      [(ngModel)]="filters.selectedClientIds" />
  </div>
  <div class="filter select col-2 px-1">
    <ng-select
      class="w-100"
      bindValue="name"
      bindLabel="displayName"
      name="names"
      placeholder="Filter by Type"
      [multiple]="true"
      [items]="availableChores"
      (remove)="onFilterChange()"
      (change)="onFilterChange()"
      [(ngModel)]="filters.selectedNames" />
  </div>
  <div class="filter select col-2 px-1">
    <ng-select
      class="w-100"
      bindValue="value"
      bindLabel="display"
      name="statuses"
      placeholder="Filter by Status"
      [multiple]="true"
      [items]="statusOptions"
      (remove)="onFilterChange()"
      (change)="onFilterChange()"
      [(ngModel)]="filters.selectedStatuses" />
  </div>
  <div *ngIf="showSubmittedByFilter"
       class="filter select col-2 px-1">
    <ng-select
      class="w-100"
      bindValue="id"
      bindLabel="fullName"
      name="userIds"
      placeholder="Filter by Submitting User"
      [multiple]="true"
      [items]="submittedByOptions"
      (remove)="onSubmittedByFilterChange()"
      (change)="onSubmittedByFilterChange()"
      [(ngModel)]="submittedBySelectedIds" />
  </div>
  <div class="filter col-auto px-1">
    <label>
      Inactive:
      <mat-slide-toggle
        name="inactive"
        [color]="'primary'"
        [(ngModel)]="filters.inactive"
        [disableRipple]="false"
        (change)="onFilterChange()" />
    </label>
  </div>
  <div class="filter col-auto px-1">
    <label>
      Requires Review:
      <mat-slide-toggle
        name="requiresReview"
        [color]="'primary'"
        [(ngModel)]="filters.requiresReview"
        [disableRipple]="false"
        (change)="onFilterChange()" />
    </label>
  </div>
</div>
