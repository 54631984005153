<button *ngIf="showButton"
  type="button"
  class="btn btn-sm mt-1"
  [ngClass]="{ 'btn-warning': !ocrDone, 'btn-success': ocrDone }"
  [disabled]="annotationsRunning"
  (click)="onOcrBackfillClick($event)"
  [ngbTooltip]="toolTip"
  placement="bottom"
  >
  <mat-spinner diameter="16" class="mat-spinner" [hidden]="!annotationsRunning" />
  <fa-icon [hidden]="annotationsRunning" [icon]="['fas', 'server']" />
  <span>Check Consult Note</span>
</button>
