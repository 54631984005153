import { Component, OnInit } from '@angular/core';
import { concatMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { NavigationService } from '../../../core/services/navigation.service';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ClientsService } from '../../../core/services/clients.service';
import { Client } from '../../../core/models/client.model';
import { ThirdPartyAdministersService } from '../../../core/services/third-party-administers.service';
import { ThirdPartyAdminister } from '../../../core/models/third-party-administer.model';
import { AuditsService } from '../../../core/services/audits.service';
import { Audit, ParsedAudit } from '../../../core/models/audit.model';
import { createParsedAudit } from 'app/core/lib/audit-utils';

@Component({
  selector: 'app-capture-admin-client-audit',
  templateUrl: './capture-admin-client-audit.component.html',
  styleUrls: ['./capture-admin-client-audit.component.scss'],
})
export class CaptureAdminClientAuditComponent implements OnInit {
  clientId = parseInt(this.navigationService.currentId, 10);
  client: Client;
  paging: LimitOffsetPaging;
  loading = true;
  parsedAudits: ParsedAudit[];
  superClientIds: number[];
  modelNameStrings = {
    Client: 'Client',
    ClientSftpCredential: 'SFTP Credentials',
    ClientThirdPartyAdminister: 'Third Party Administer',
    HrsaId: 'HRSA ID',
  };
  notSetString = 'not set';

  constructor(
    private clientsService: ClientsService,
    private thirdPartyAdministersService: ThirdPartyAdministersService,
    private auditsService: AuditsService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.loadClient();
    this.loadAudits(this.paging.currentPage);
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadAudits(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadAudits(page);
  }

  private loadClient() {
    this.clientsService.get(this.clientId).subscribe(client => {
      this.client = client;
    });
  }

  private loadAudits(page: number) {
    this.loading = true;
    const paging = { page, pageSize: this.paging.pageSize };

    const auditsRequest = this.auditsService.getList(paging, 'Client', [this.clientId], true);

    const followUpRequests = auditsRequest.pipe(
      concatMap((auditsResponse: any) => {
        this.processClientAudits(auditsResponse);
        const clientRequest = this.clientsService.getList(null, { ids: this.superClientIds });
        const thirdPartyAdministerRequest = this.thirdPartyAdministersService.getList();
        return forkJoin([clientRequest, thirdPartyAdministerRequest]);
      })
    );

    followUpRequests.subscribe(([clientsResponse, thirdPartyAdministerResponse]) => {
      this.addSuperClientNames(clientsResponse);
      this.addThirdPartyAdministerNames(thirdPartyAdministerResponse);
      this.loading = false;
    });
  }

  private addSuperClientNames(response: any): void {
    this.parsedAudits.forEach((parsedAudit: ParsedAudit) => {
      for (let i = 0; i < 2; i++) {
        const clientId = parsedAudit.changes[i]['superClientId'];
        if (typeof clientId === 'number') {
          const foundClient = response.clients.find((client: Client) => client.id === clientId);
          if (foundClient) {
            parsedAudit.changes[i]['superClient'] = foundClient.name;
            delete parsedAudit.changes[i]['superClientId'];
          }
        }
      }
    });
  }

  private addThirdPartyAdministerNames(response: any): void {
    this.parsedAudits.forEach((parsedAudit: ParsedAudit) => {
      for (let i = 0; i < 2; i++) {
        const thirdPartyAdministerId = parsedAudit.changes[i]['thirdPartyAdministerId'];
        if (typeof thirdPartyAdministerId === 'number') {
          const foundThirdPartyAdminister = response.thirdPartyAdministers.find(
            (thirdPartyAdminister: ThirdPartyAdminister) => thirdPartyAdminister.id === thirdPartyAdministerId
          );
          if (foundThirdPartyAdminister) {
            parsedAudit.changes[i]['thirdPartyAdminister'] = foundThirdPartyAdminister.name;
            delete parsedAudit.changes[i]['thirdPartyAdministerId'];
          }
        }
      }
    });
  }

  private processClientAudits(response: any): void {
    this.paging = response.meta.paging;

    this.parsedAudits = response.audits
      .map((audit: Audit) => this.parseAudit(audit))
      .filter((parsedAudit: ParsedAudit | null) => parsedAudit !== null);

    this.setUniqueSuperClientIds();
  }

  private setUniqueSuperClientIds(): void {
    const superClientIdSet: Set<number> = new Set();

    this.parsedAudits.forEach((parsedAudit: ParsedAudit) => {
      const current = parsedAudit.changes[0]['superClientId'];
      const previous = parsedAudit.changes[1]['superClientId'];
      if (typeof current === 'number') superClientIdSet.add(current);
      if (typeof previous === 'number') superClientIdSet.add(previous);
    });

    this.superClientIds = [...superClientIdSet];
  }

  private parseAudit(audit: Audit): ParsedAudit | null {
    // remove changes to ignore
    const {
      clientId,
      entityTypes,
      humanEditedAt,
      updatedAt,
      encryptedDirectSendingPassword,
      ...changes
    } = audit.auditedChanges;

    return createParsedAudit(audit, changes, this.notSetString);
  }
}
