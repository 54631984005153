import { Component } from '@angular/core';
import { CaptureHistoryItemTypeClientChoreUpdatedComponent } from '../capture-history-item-type-client-chore-updated/capture-history-item-type-client-chore-updated.component';


@Component({
  selector: 'app-capture-history-item-type-client-chore-started',
  templateUrl: '../capture-history-item-type-client-chore-updated/capture-history-item-type-client-chore-updated.component.html',
})
export class CaptureHistoryItemTypeClientChoreStartedComponent
  extends CaptureHistoryItemTypeClientChoreUpdatedComponent {

  get title() {
    return "Chore Update Started";
  }
}
