import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Audit } from 'app/core/models/audit.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { PagedProviderAudits, ProviderService } from 'app/core/services/provider.service';
import { Provider } from 'app/core/models/provider.model';

@Component({
  selector: 'app-capture-admin-provider-audit',
  templateUrl: './capture-admin-provider-audit.component.html',
  styleUrls: ['./capture-admin-provider-audit.component.scss'],
})
export class CaptureAdminProviderAuditComponent implements OnInit {
  provider: Provider;
  audits: Audit[];

  loadingProvider: boolean;
  loadingAudits: boolean;

  paging: LimitOffsetPaging;

  constructor(
    private providerService: ProviderService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.init();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadAudits(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadAudits(page);
  }

  private init() {
    const npi = this.route.snapshot.paramMap.get('npi');
    this.loadingProvider = true;
    this.providerService.getByNpi(npi).subscribe(provider => {
      this.provider = provider;
      this.loadingProvider = false;
      this.loadAudits(this.paging.currentPage);
    });
  }

  private loadAudits(page: number) {
    const paging = { page, pageSize: this.paging.pageSize };

    this.loadingAudits = true;

    this.providerService.getAudits(this.provider, paging).subscribe((result: PagedProviderAudits) => {
      this.audits = result.records;
      this.paging = result.meta.paging;
      this.loadingAudits = false
    })
  }

}