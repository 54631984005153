<app-reports
  title="Capture Billing Reports"
  [filtersForm]="filtersForm"
  [forceReportDetailsDisplay]="true"
  [reportsUrl]="reportsUrl"
  [reportsCountUrl]="reportsCountUrl">

  <ng-template appNewReportOptionsTemplate let-filtersForm="filtersForm">
    <ng-container [formGroup]="filtersForm">

      <app-report-client-option
        formControlName="clientIds"
        [clientFilters]="{ applicableToReferralClaims: true }" />

      <app-report-date-range-option
        label="Last Update"
        formControlName="mostRecentStateChange" />

      <app-report-date-range-option
        label="Original Billing Date"
        formControlName="originalBillingDate" />

      <app-report-boolean-option
        label="Requires Invoice or Credit"
        formControlName="requiresInvoiceOrCredit" />

    </ng-container>
  </ng-template>

  <ng-template appReportDetailsTemplate let-report="report">

    <app-report-applied-client-option
      [options]="report.filters"
      [optionAssociations]="report.filterAssociations" />

    <app-report-applied-date-range-option
      [options]="report.filters"
      name="mostRecentStateChange"
      label="Last Update" />

    <app-report-applied-date-range-option
      [options]="report.filters"
      name="originalBillingDate"
      label="Original Billing Date" />

    <app-report-applied-boolean-option
      [options]="report.filters"
      name="requiresInvoiceOrCredit"
      label="Requires Invoice or Credit" />

  </ng-template>

</app-reports>
