import { Component, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { OfficeService } from 'app/core/services/office.service';
import { Office } from 'app/core/models/office.model';

export class NewOfficeModalOptions {
  fax = '';
}

@Component({
  selector: 'app-new-office-modal',
  templateUrl: './new-office-modal.component.html',
  styleUrls: ['./new-office-modal.component.scss'],
})
export class NewOfficeModalComponent implements OnInit {
  formGroup: FormGroup;
  existingOffice: Office;
  errorMessage: string;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private officeService: OfficeService,
    private router: Router,
    @Optional() private options: NewOfficeModalOptions,
  ) {
    if (!this.options) {
      this.options = new NewOfficeModalOptions();
    }
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      faxNumber: [this.options.fax, Validators.required],
    });
  }

  onCloseClick() {
    this.activeModal.close();
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    const faxNumber = this.formGroup.get('faxNumber').value;

    this.existingOffice = null;
    this.errorMessage = null;

    this.officeService.searchFax(faxNumber).subscribe(
      (existingOffice: Office) => {
        if (existingOffice) {
          this.existingOffice = existingOffice;
        } else {
          const normalizedFaxNumber = faxNumber.replace(/\D/g, '');
          const url = `/capture-admin/offices/new?fax=${normalizedFaxNumber}`;

          this.router.navigateByUrl(url).then(() => {
            this.activeModal.close();
          });
        }
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = error.error.error;
      });
  }

  onExistingOfficeClick($event: MouseEvent) {
    $event.preventDefault();

    const url = `/capture-admin/offices/${this.existingOffice.id}`

    this.router.navigateByUrl(url).then(() => {
      this.activeModal.close();
    });
  }

}
