import { Component, OnInit } from '@angular/core';
import { debounce } from 'lodash-es';
import { PatientInteractionLocationsService } from 'app/core/services/patient-interaction-locations.service';
import { PatientInteractionLocation } from 'app/core/models/patient-interaction-location.model';
import { PatientInteractionLocationEligibilityStatus } from 'app/core/enums/patient-interaction-location-eligibility-status.enum';
import {
  PatientInteractionLocationFilters,
} from 'app/core/models/user-settings/patient-interaction-location-settings.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { PatientInteractionLocationEligibilityStatusOptions } from 'app/core/options/patient-interaction-location-eligibility-status.opts';

@Component({
  selector: 'app-patient-interaction-locations-current-locations-section',
  templateUrl: './patient-interaction-locations-current-locations-section.component.html',
})
export class PatientInteractionLocationsCurrentLocationsSectionComponent implements OnInit {
  patientInteractionLocations: PatientInteractionLocation[] = [];
  loading = true;
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  filters = new PatientInteractionLocationFilters();

  eligibilityStatusFilterOptions =
    PatientInteractionLocationEligibilityStatusOptions.
    filter(option => option.value !== PatientInteractionLocationEligibilityStatus.new);

  allowedEligibilityStatusFilterValues = [
    PatientInteractionLocationEligibilityStatus.eligible,
    PatientInteractionLocationEligibilityStatus.ineligible,
  ];

  constructor(
    private patientInteractionLocationsService: PatientInteractionLocationsService,
  ) {
    this.paging.pageSize = 20;
  }

  ngOnInit() {
    this.loadPatientInteractionLocations();
  }

  onPageChange(_page: number) {
    this.loadPatientInteractionLocations();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadPatientInteractionLocations();
  }

  onFilterChange() {
    this.loadPatientInteractionLocations();
  }

  onLocationKeyUp() {
    this.debounceLocationKeyUp();
  }

  onResetFiltersClick() {
    this.paging.currentPage = 1;
    this.filters = new PatientInteractionLocationFilters();
    this.loadPatientInteractionLocations();
  }

  private loadPatientInteractionLocations() {
    const restrictedFilters: PatientInteractionLocationFilters = { ...this.filters };

    if (restrictedFilters.eligibilityStatuses.length === 0) {
      restrictedFilters.eligibilityStatuses = this.allowedEligibilityStatusFilterValues;
    }

    this.patientInteractionLocationsService.getList(this.paging, restrictedFilters).subscribe(result => {
      this.patientInteractionLocations = result.patientInteractionLocations;
      this.paging = result.meta.paging;
      this.loading = false;
    });
  }

  private debounceLocationKeyUp = debounce(
    () => { this.onFilterChange(); },
    1000,
    { maxWait: 2000 }
  );
}
