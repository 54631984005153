<div class="card">
  <div class="card-body">
    <h5 class="mb-4">Inbound Calls</h5>
    <app-patient-inbound-calls [patient]="patient" />
  </div>
</div>

<div class="card mt-3">
  <div class="card-body">
    <h5 class="mb-4">Outbound Faxes</h5>
    <app-patient-outbound-faxes [patient]="patient" />
  </div>
</div>