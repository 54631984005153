import { Component, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ProviderService } from 'app/core/services/provider.service';
import { Provider } from 'app/core/models/provider.model';
import { keyedStatusOptions } from 'app/core/options/provider-status.opts';

export class NewProviderModalOptions {
  npi = '';
}

@Component({
  selector: 'app-new-provider-modal',
  templateUrl: './new-provider-modal.component.html',
  styleUrls: ['./new-provider-modal.component.scss'],
})
export class NewProviderModalComponent implements OnInit {
  formGroup: FormGroup;
  existingProvider: Provider;
  errorMessage: string;
  keyedStatusOptions = keyedStatusOptions;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private providerService: ProviderService,
    private router: Router,
    @Optional() private options: NewProviderModalOptions,
  ) {
    if (!this.options) {
      this.options = new NewProviderModalOptions();
    }
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      npi: [this.options.npi, Validators.required],
    });
  }

  onCloseClick() {
    this.activeModal.close();
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    const npi = this.formGroup.get('npi').value;

    this.existingProvider = null;
    this.errorMessage = null;

    this.providerService.getByNpi(npi, true).subscribe(
      (existingProvider: Provider) => {
        if (existingProvider) {
          this.existingProvider = existingProvider;
        } else {
          const url = `/capture-admin/providers/new?npi=${npi}`;

          this.router.navigateByUrl(url).then(() => {
            this.activeModal.close();
          });
        }
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = error.error.error;
      });
  }

  onExistingProviderClick($event: MouseEvent) {
    $event.preventDefault();

    const url = `/capture-admin/providers/${this.existingProvider.npi}`

    this.router.navigateByUrl(url).then(() => {
      this.activeModal.close();
    });
  }

}
