import { Component, Input, OnInit } from '@angular/core';
import { ViewAsClientService } from 'app/core/services/view-as-client.service';
import { Client } from '../../../core/models/client.model';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  @Input() clients: Client[];
  @Input() canManageClients: boolean;

  constructor(private viewAsClientService: ViewAsClientService) {}

  ngOnInit() {}

  clientActive(client): boolean {
    return client && client.status === 'active';
  }

  onViewPortalClick($event, client: Client) {
    this.viewAsClientService.viewAsClient($event, client);
  }

  public assignedUsers(client) {
    const assignedUsers = client.assignedUsers;
    if (assignedUsers && assignedUsers.length > 0) {
      return assignedUsers
        .map(u => u.username)
        .sort()
        .join(', ');
    } else {
      return 'no users assigned';
    }
  }
}
