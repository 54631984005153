import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { IccCaptureEligibilityFindingsImport } from 'app/core/models/icc/icc-capture-eligibility-findings-import.model';
import { IccImportFilters } from 'app/core/models/icc/icc-import-settings.model';

@Injectable()
export class IccCaptureEligibilityFindingsImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<IccCaptureEligibilityFindingsImport> {
    return this.http.get<IccCaptureEligibilityFindingsImport>(
      `${environment.captureApi.url}/icc/capture_eligibility_findings_imports/${id}`
    );
  }

  getList(
    filters: IccImportFilters = null
  ): Observable<{ captureEligibilityFindingsImports: IccCaptureEligibilityFindingsImport[] }> {
    const params = new URLSearchParams();

    return this.http.get<{ captureEligibilityFindingsImports: IccCaptureEligibilityFindingsImport[] }>(
      `${environment.captureApi.url}/icc/capture_eligibility_findings_imports?${params.toString()}`
    );
  }

  create(name: string, signedId: string): Observable<IccCaptureEligibilityFindingsImport> {
    return this.http.post<IccCaptureEligibilityFindingsImport>(
      `${environment.captureApi.url}/icc/capture_eligibility_findings_imports`,
      {
        captureEligibilityFindingsImport: { name, signedId },
      }
    );
  }

  update(id, name): Observable<IccCaptureEligibilityFindingsImport> {
    return this.http.put<IccCaptureEligibilityFindingsImport>(
      `${environment.captureApi.url}/icc/capture_eligibility_findings_imports/${id}`,
      {
        captureEligibilityFindingsImport: { name },
      }
    );
  }

  cancel(id): Observable<IccCaptureEligibilityFindingsImport> {
    return this.http.patch<IccCaptureEligibilityFindingsImport>(
      `${environment.captureApi.url}/icc/capture_eligibility_findings_imports/${id}/cancel`,
      {}
    );
  }
}
