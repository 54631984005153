import { fromEvent as observableFromEvent, merge, throwError, Observable, Subject, Subscription } from 'rxjs';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { SessionStorageService } from 'app/core/services/session-storage.service';
import { debounce } from 'lodash-es';
import { DateTime } from 'luxon';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SentryService } from './sentry.service';
import { NavigationService } from './navigation.service';
import { LogoutReason } from '../enums/logout-reason.enum';
import { DateUtils } from '../lib/date-utils';
import { AppTabIdSessionKey } from '../constants';
import { AppVersion } from '../lib/app-version';
import { AuthenticationService, UserLogoutEvent } from './authentication.service';
import { WebsocketService } from './websocket.service';
import { nanoid } from 'nanoid';

const legacyUserLeftSiteAtKey = 'userLeftSiteAt';
const legacyUserLastActiveAtKey = 'userLastActiveAt';
const mostRecentActivityKey = 'mostRecentActivity';
const idleTimeoutSeconds = environment.idleTimeoutSeconds;

class MostRecentActivity {
  constructor(public appTabId: string, public timestamp: Date) {}
}

@Injectable()
export class ApplicationService implements OnDestroy {
  private navigationService: NavigationService = null;

  private userLoginSubscription: Subscription = null;
  private userLogoutSubscription: Subscription = null;

  private debouncedHandleUserActivity = debounce(() => {
    this.updateUserSessionActivity();
    this.setInactivityTimeout();
  }, 250);

  private activityEvents: Observable<any> = null;
  private activityEventsSubscription: Subscription = null;

  private userInactive: Subject<any> = new Subject();
  private userInactiveSubscription: Subscription = null;
  private inactivityTimeout;

  constructor(
    private authService: AuthenticationService,
    private sentryService: SentryService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private websocketService: WebsocketService,
    private injector: Injector
  ) {}

  public initialize(): Observable<any> {
    this.sessionStorageService.set(AppTabIdSessionKey, nanoid());

    // no longer needed
    this.localStorageService.remove(legacyUserLeftSiteAtKey);
    this.localStorageService.remove(legacyUserLastActiveAtKey);

    const msg = !environment.production
      ? `Starting R1 340B Recovery, version: ${AppVersion.humanize()}, tabId: ${this.appTabId}`
      : 'Starting R1 340B Recovery';
    console.log(msg);

    this.initDebugUtils();
    this.initAuthEventListeners();

    // fix for circular deps between router and http, let's see if this can be improved
    // it's also likely that the login service can be decomposed a bit more into this service
    this.navigationService = this.injector.get(NavigationService);

    return this.authService.reloadSession().pipe(
      tap(() => {
        if (this.userSessionIsInactive) {
          this.logActivityMessage('User has been away too long');
          this.authService.logout(LogoutReason.awayTooLong);
        }

        this.updateUserSessionActivity();
        this.connectWebsocket();
      }),
      catchError(err => {
        this.authService.logout(LogoutReason.appInitError);
        return throwError(err);
      })
    );
  }

  ngOnDestroy() {
    if (this.userLoginSubscription) {
      this.userLoginSubscription.unsubscribe();
    }

    if (this.userLogoutSubscription) {
      this.userLogoutSubscription.unsubscribe();
    }

    if (this.activityEventsSubscription) {
      this.activityEventsSubscription.unsubscribe();
    }

    if (this.userInactiveSubscription) {
      this.userInactiveSubscription.unsubscribe();
    }

    this.clearInactivityTimeout();
  }

  public startWatchingUserIdle() {
    this.logActivityMessage('Starting to watch user idle');

    this.setInactivityTimeout();
    this.userInactiveSubscription = this.userInactive.subscribe(this.handleUserIdle.bind(this));

    this.activityEvents = merge(
      observableFromEvent(window, 'mousemove'),
      observableFromEvent(window, 'resize'),
      observableFromEvent(document, 'keydown')
    );

    this.activityEventsSubscription = this.activityEvents.subscribe(this.handleUserActivity.bind(this));
  }

  private initAuthEventListeners() {
    this.userLoginSubscription = this.authService.userLogin.subscribe(() => this.handleUserLogin());
    this.userLogoutSubscription = this.authService.userLogout.subscribe((logoutEvent: UserLogoutEvent) =>
      this.handleUserLogout(logoutEvent)
    );
  }

  private get appTabId(): string {
    return this.sessionStorageService.get(AppTabIdSessionKey);
  }

  private initDebugUtils() {
    if (!environment.production) {
      window['printTokenInfo'] = this.printTokenInfo.bind(this);
      window['printUserInfo'] = this.printUserInfo.bind(this);
    }
  }

  private updateUserSessionActivity() {
    if (this.authService.isLoggedIn) {
      const mostRecentActivity = new MostRecentActivity(this.appTabId, new Date());
      const ts = DateUtils.formatDateTime(mostRecentActivity.timestamp);
      this.logActivityMessage(`Updating the most recent activity of this user session to be ${ts}`);
      this.localStorageService.set<MostRecentActivity>(mostRecentActivityKey, mostRecentActivity);
    }
  }

  private setInactivityTimeout(timeoutSeconds = idleTimeoutSeconds) {
    this.clearInactivityTimeout();
    const nextTimeout = DateTime.now().plus({ seconds: timeoutSeconds });
    const formatted = DateUtils.formatDateTime(nextTimeout.toJSDate());

    const timeoutMilliseconds = timeoutSeconds * 1000;

    this.logActivityMessage(`Resetting idle timer. User will be considered idle on this tab at ${formatted}`);
    this.inactivityTimeout = setTimeout(() => this.userInactive.next(), timeoutMilliseconds);
  }

  private handleUserLogin() {
    this.updateUserSessionActivity();
    this.connectWebsocket();
    this.navigationService.redirectToReturnUrl();
  }

  private handleUserLogout({ logoutReason, appTabId }: UserLogoutEvent) {
    const includeReason = logoutReason === LogoutReason.inactivity || logoutReason === LogoutReason.awayTooLong;
    const skipReturnUrl = logoutReason === LogoutReason.userLoggedOut;
    const redirectToLogin = logoutReason !== LogoutReason.creatingNewPassword || appTabId !== this.appTabId;
    const forcePageReload =
      logoutReason === LogoutReason.inactivity ||
      logoutReason === LogoutReason.awayTooLong ||
      logoutReason === LogoutReason.userLoggedOut;

    this.disconnectWebsocket();

    if (redirectToLogin) {
      const queryParams = includeReason ? { logoutReason } : {};
      this.navigationService.redirectToLogin(queryParams, skipReturnUrl, forcePageReload);
    }
  }

  private handleUserActivity() {
    this.debouncedHandleUserActivity();
  }

  private handleUserIdle() {
    if (this.userSessionIsInactive) {
      const now = DateUtils.formatDateTime(new Date());
      this.logActivityMessage(`Logging out for inactivity at ${now}`);
      this.authService.logout(LogoutReason.inactivity);
    } else if (this.authService.isLoggedIn) {
      this.logActivityMessage('There is another active tab open. Ignoring user idle');
    }
  }

  private get userSessionIsInactive(): boolean {
    return this.authService.isLoggedIn && this.noRecentActivity;
  }

  private get noRecentActivity(): boolean {
    const activity = this.mostRecentActivity;

    if (activity && activity.timestamp) {
      const duration = DateTime.fromJSDate(activity.timestamp).diffNow('seconds');
      const durationSeconds = Math.ceil(Math.abs(duration.seconds));
      const ts = DateUtils.formatDateTime(activity.timestamp);
      const msg = `Most recent activity was at ${ts} (~ ${durationSeconds} seconds ago) on tab ${activity.appTabId}.`;
      this.logActivityMessage(msg);

      return durationSeconds >= idleTimeoutSeconds;
    } else {
      return true;
    }
  }

  private get mostRecentActivity(): MostRecentActivity {
    try {
      let activityObj = this.localStorageService.get(mostRecentActivityKey);

      if (activityObj) {
        activityObj = typeof activityObj === 'string' ? JSON.parse(activityObj) : activityObj;
        return new MostRecentActivity(activityObj.appTabId, new Date(activityObj.timestamp));
      } else {
        return null;
      }
    } catch (err) {
      this.sentryService.logException(err);
      return null;
    }
  }

  private clearInactivityTimeout() {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
    }
  }

  private printTokenInfo() {
    if (this.authService.isLoggedIn) {
      const idToken = this.authService.idToken;

      console.log(`id token authenticated at: ${DateUtils.formatDateTime(idToken.authenticatedAt)}`);
      console.log(`id token issued at: ${DateUtils.formatDateTime(idToken.issuedAt)}`);
      console.log(`id token expires at: ${DateUtils.formatDateTime(idToken.expiresAt)}`);
      console.log(`id token payload:`);
      console.log(JSON.stringify(this.authService.idToken.payload, null, 2));

      const accessToken = this.authService.accessToken;

      console.log(`access token issued at: ${DateUtils.formatDateTime(accessToken.issuedAt)}`);
      console.log(`access token expires at: ${DateUtils.formatDateTime(accessToken.expiresAt)}`);
      console.log(`access token payload:`);
      console.log(JSON.stringify(this.authService.accessToken.payload, null, 2));
    }
  }

  private printUserInfo = (authService: AuthenticationService) => () => {
      if (authService && authService.isLoggedIn) {
        console.log(JSON.stringify(authService.currentUser, null, 2));
      }
    };

  private logActivityMessage(msg) {
    if (environment.debugUserIdle) {
      console.log(`${msg} [${this.appTabId}] `);
    }
  }

  private connectWebsocket() {
    if (this.authService.isLoggedIn) {
      this.websocketService.connect(this.authService.jwtIdentity);
    }
  }

  private disconnectWebsocket() {
    this.websocketService.disconnect();
  }
}
