<table class="table table-striped mt-3">
  <tbody>
    <ng-container *ngIf="historyItems.length > 0; else: noHistoryItems">
      <tr *ngFor="let clientTaskHistoryItem of historyItems">
        <td class="highlighted">
          <div>{{ clientTaskHistoryItem.createdAt | date: 'MM/dd/yyyy' }}</div>
          <div>{{ clientTaskHistoryItem.createdAt | date: 'hh:mm a' }}</div>
        </td>
        <td>
          <div [ngSwitch]="clientTaskHistoryItem.action">

            <app-client-task-history-item-type-created
              *ngSwitchCase="clientTaskHistoryItemActions.created"
              [clientTaskHistoryItem]="clientTaskHistoryItem" />

            <app-client-task-history-item-type-transitioned
              *ngSwitchCase="clientTaskHistoryItemActions.transitioned"
              [clientTaskHistoryItem]="clientTaskHistoryItem" />

            <app-client-task-history-item-type-submitted
              *ngSwitchCase="clientTaskHistoryItemActions.submitted"
              [clientTaskHistoryItem]="clientTaskHistoryItem" />

            <app-client-task-history-item-type-base
              *ngSwitchDefault
              [clientTaskHistoryItem]="clientTaskHistoryItem" />
          </div>
        </td>
        <td class="highlighted">{{ clientTaskHistoryItem | historyItemUserName }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
<ng-template #noHistoryItems>
  <tr>
    <td>There are no history items.</td>
  </tr>
</ng-template>
