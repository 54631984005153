import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { trim } from 'lodash-es';

@Component({
  selector: 'app-outbound-fax-search-form',
  templateUrl: './outbound-fax-search-form.component.html',
  styleUrls: ['./outbound-fax-search-form.component.scss'],
})
export class OutboundFaxSearchFormComponent implements OnInit {
  canSearch = false;
  claimIdentifier;

  @Output() public search = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
    this.updateCanSearch();
  }

  onFormSubmit() {
    this.search.emit(this.claimIdentifier);
  }

  onClaimIdChange() {
    this.updateCanSearch();
  }

  private updateCanSearch() {
    this.canSearch = !!trim(this.claimIdentifier);
  }
}
