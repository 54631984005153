import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-ce-review-task-content',
  templateUrl: './ce-review-task-content.component.html',
})
export class CeReviewTaskContentComponent {
  @Input()
  task: {
    humanizedType: string;
    notes: string;
  };
}
