import { Component, Input, OnInit } from '@angular/core';
import { InboundCall } from 'app/core/models/inbound-call.model';
import { Patient } from 'app/core/models/patient.model';
import { InboundCallsService } from 'app/core/services/inbound-calls.service';
import { keyedReasonOptions } from 'app/core/options/inbound-call-reasons.opts';

@Component({
  selector: 'app-patient-inbound-calls',
  templateUrl: './patient-inbound-calls.component.html',
  styleUrls: ['./patient-inbound-calls.component.scss'],
})
export class PatientInboundCallsComponent implements OnInit {
  @Input() patient: Patient;

  loading = true;
  inboundCalls: InboundCall[] = [];

  keyedInboundCallReasonOptions = keyedReasonOptions;

  constructor(
    private inboundCallsService: InboundCallsService,
  ) {}

  ngOnInit() {
    this.inboundCallsService
      .getList(1, 10, [], null, null, this.patient.id)
      .subscribe(results => {
        this.loading = false;
        this.inboundCalls = results.inboundCalls;
    });
  }
}
