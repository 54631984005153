import { keyBy } from 'lodash-es';
import { Component, Input } from '@angular/core';
import { ClientReferralTask } from '../../../core/models/client-referral-task.model';
import { choiceOptions } from '../../../core/options/client-referral-task-choice-opts';
import { Capture } from '../../../core/models/capture.model';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';

const KeyedChoiceOptions = keyBy(choiceOptions, 'value');

@Component({
  selector: 'app-ce-review-client-referral-task',
  templateUrl: './ce-review-client-referral-task.component.html',
})
export class CeReviewClientReferralTaskComponent {
  keyedChoiceOptions = KeyedChoiceOptions;
  verificationReasons = ReferralVerificationReason;

  @Input() clientReferralTask: ClientReferralTask;
  @Input() capture: Capture;
}
