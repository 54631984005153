import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { ReportOption } from 'app/core/models/report-option.model';
import { CaptureService } from 'app/core/services/capture.service';
import { CaptureStatus } from 'app/core/enums/capture-status.enum';

export class ReportCaptureStatusOption implements ReportOption {
  constructor(value = ReportCaptureStatusOption.defaultValue) {
    this.value = value;
  }

  static readonly defaultValue = [
    CaptureStatus.ceReview,
    CaptureStatus.clientRejected,
    CaptureStatus.gatherEvidenceBlocked,
    CaptureStatus.internalReview,
    CaptureStatus.needsEvidence,
    CaptureStatus.pending,
    CaptureStatus.rejected,
    CaptureStatus.reversed,
    CaptureStatus.verified,
    CaptureStatus.verifiedClientPrescriberList,
    CaptureStatus.verifiedClientPrescription,
    CaptureStatus.verifiedClientHasConsultNote,
  ];
  value: CaptureStatus[] = [];

  toPostData(options = { paramName: 'captureStatuses' }) {
    return { [options.paramName]: this.value };
  }
}

@Component({
  selector: 'app-report-capture-status-option',
  templateUrl: './report-capture-status-option.component.html',
  styleUrls: ['./report-capture-status-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportCaptureStatusOptionComponent),
    },
  ],
})
export class ReportCaptureStatusOptionComponent extends ReportAbstractOptionComponent implements OnInit {
  @Input() label = 'Filter by Capture Statuses';
  @Input() multiple = true;

  selectedStatuses: CaptureStatus[];
  captureStatuses: { value: string; display: string }[] = [];

  constructor(private captureService: CaptureService) {
    super();
  }

  ngOnInit() {
    this.captureStatuses = this.captureService.statusesForSelect();
  }

  protected updateInternalState(model: ReportCaptureStatusOption) {
    if (model) {
      this.selectedStatuses = model.value;
    } else {
      this.selectedStatuses = [];
    }
  }

  protected determineModelStateOnChange(): ReportCaptureStatusOption {
    if (this.selectedStatuses.length > 0) {
      return new ReportCaptureStatusOption(this.selectedStatuses);
    } else {
      return null;
    }
  }
}
