import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ValidationError, ValueSuffix, ValueType } from '../../lib/client-configuration';

@Component({
  selector: 'app-client-configuration-basic-value',
  templateUrl: './client-configuration-basic-value.component.html',
  styleUrls: ['./client-configuration-basic-value.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ClientConfigurationBasicValueComponent implements OnInit {
  _errors: ValidationError[] = [];
  @Input() identifier: string;
  @Input() value: any;
  @Input() definition: { dataType: string; editable: boolean };

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  isFlag = true;
  valueSuffix = ValueSuffix;
  valueValidationError: ValidationError = null;

  flagOptions = [
    ['Select one', undefined],
    ['On', true],
    ['Off', false],
  ];

  constructor() {}

  get errors(): ValidationError[] {
    return this._errors;
  }

  @Input()
  set errors(errors: ValidationError[]) {
    this._errors = errors;
    this.refreshValidationError(errors);
  }

  ngOnInit() {
    this.isFlag = ValueType.bool === this.definition.dataType;
  }

  onValueChange(value) {
    this.valueChange.emit(value);
  }

  private refreshValidationError(errors: ValidationError[]) {
    this.valueValidationError = null;
    const controlIdentifier = `${this.identifier}${ValueSuffix}`;
    this.valueValidationError = errors.find(err => err.identifier === controlIdentifier);
  }
}
