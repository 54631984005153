import { debounce } from 'lodash-es';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SpecialistEncounterVerification } from '../../../core/models/specialist-encounter-verification.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { SpecialistEncounterVerificationService } from '../../../core/services/specialist-encounter-verification.service';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { isConsultNoteVerificationFrozen } from '../../../core/lib/verification-utils';
import { nanoid } from 'nanoid';
import { VerificationType } from '../../../core/enums/verification-type.enum';

@Component({
  selector: 'app-validate-specialist-encounter-verifications',
  templateUrl: './validate-specialist-encounter-verifications.component.html',
  styleUrls: ['./validate-specialist-encounter-verifications.component.scss'],
})
export class ValidateSpecialistEncounterVerificationsComponent {
  @Input() collapsed: boolean;

  @Output() verificationChange = new EventEmitter<{ verification: SpecialistEncounterVerification; tempId?: string }>();
  @Output() verificationRemove = new EventEmitter<SpecialistEncounterVerification>();

  _patientAttachment: PatientAttachment;
  addDisabled = false;
  specialistEncounterVerifications: SpecialistEncounterVerification[] = [];

  debouncedDestroyVerification = debounce(
    (sev: SpecialistEncounterVerification) => {
      this.destroyVerification(sev);
    },
    250,
    { maxWait: 1000 }
  );

  constructor(
    private authService: AuthenticationService,
    private specialistEncounterVerificationService: SpecialistEncounterVerificationService
  ) {}

  get patientAttachment(): PatientAttachment {
    return this._patientAttachment;
  }

  @Input()
  set patientAttachment(pa: PatientAttachment) {
    this._patientAttachment = pa;
    this.initialize();
  }

  onChange(verification: SpecialistEncounterVerification) {
    if (verification.encounterDate) {
      this.handleVerificationChange(verification);
    }
  }

  onRemove(verification: SpecialistEncounterVerification) {
    if (verification.createdAt) {
      this.debouncedDestroyVerification(verification);
    } else {
      this.verificationRemove.emit(verification);
    }
  }

  onAddClick($event) {
    $event.preventDefault();
    this.addNewVerification();
  }

  private initialize() {
    this.addDisabled = this.isAddDisabled();
    this.initSpecialistEncounterVerifications();
  }

  private handleVerificationChange(verification: SpecialistEncounterVerification) {
    if (verification.createdAt) {
      this.updateVerification(verification);
    } else {
      this.createVerification(verification);
    }
  }

  private createVerification(verification: SpecialistEncounterVerification) {
    this.specialistEncounterVerificationService
      .create(this.patientAttachment.id, null, verification)
      .subscribe(newVerification => {
        this.verificationChange.emit({ verification: newVerification, tempId: verification.id });
      });
  }

  private updateVerification(verification: SpecialistEncounterVerification) {
    this.specialistEncounterVerificationService
      .update(this.patientAttachment.id, null, verification)
      .subscribe(updatedVerification => {
        this.verificationChange.emit({ verification: updatedVerification });
      });
  }

  private destroyVerification(verification: SpecialistEncounterVerification) {
    this.specialistEncounterVerificationService.destroy(this.patientAttachment.id, verification).subscribe(removed => {
      this.verificationRemove.emit(removed);
    });
  }

  private initSpecialistEncounterVerifications() {
    this.specialistEncounterVerifications = this.patientAttachment.specialistEncounterVerifications || [];

    if (this.specialistEncounterVerifications.length === 0) {
      this.addNewVerification();
    }
  }

  private isAddDisabled(): boolean {
    return isConsultNoteVerificationFrozen(
      this.authService,
      this.patientAttachment,
      VerificationType.specialistEncounterDate
    );
  }

  private addNewVerification() {
    const sev = new SpecialistEncounterVerification();
    sev.id = nanoid();
    sev.encounterDate = null;
    this.verificationChange.emit({ verification: sev });
  }
}
