import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { every, some } from 'lodash-es';
import { Capture } from 'app/core/models/capture.model';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { SpecialistEncounterVerification } from 'app/core/models/specialist-encounter-verification.model';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { isSpecialistEncounterDateWithinRange } from 'app/core/lib/verification-utils';

@Component({
  selector: 'app-edit-encounter-dates-modal',
  templateUrl: './edit-encounter-dates-modal.component.html',
  styleUrls: ['./edit-encounter-dates-modal.component.scss']
})
export class EditEncounterDatesModalComponent implements OnInit {
  capture: Capture;
  patientAttachment: PatientAttachment;
  specialistEncounterVerifications: SpecialistEncounterVerification[] = [];
  loadingCaptures = true;
  verifyingCaptures: Capture[] = [];
  writtenDateIsValid: boolean[] = [];
  allWrittenDatesAreValid = false;

  constructor(
    private activeModal: NgbActiveModal,
    private patientAttachmentsService: PatientAttachmentsService,
  ) {}

  ngOnInit(): void {
    this.specialistEncounterVerifications = this.specialistEncounterVerifications.map((sev) => Object.assign({}, sev));
    this.patientAttachmentsService.getVerifyingCaptures(this.patientAttachment.id).subscribe((captures) => {
      this.verifyingCaptures = captures;
      this.loadingCaptures = false;
      this.validateWrittenDates();
    });
  }

  onChange(index: number, verification: SpecialistEncounterVerification): void {
    this.specialistEncounterVerifications[index].encounterDate = verification.encounterDate;
    this.validateWrittenDates();
  }

  onRemove(index: number): void {
    this.specialistEncounterVerifications.splice(index, 1);
    this.validateWrittenDates();
  }

  onAddClick(event: Event): void {
    event.preventDefault();
    const sev = new SpecialistEncounterVerification();
    sev.encounterDate = null;
    this.specialistEncounterVerifications.push(sev);
    this.allWrittenDatesAreValid = false;
  }

  onCancelClick(): void {
    this.activeModal.dismiss();
  }

  onSaveClick(): void {
    if (this.allWrittenDatesAreValid) {
      this.patientAttachmentsService.updateSpecialistEncounterVerifications(
        this.patientAttachment.id,
        this.specialistEncounterVerifications
      ).subscribe((patientAttachment: PatientAttachment) => {
        this.activeModal.close(patientAttachment);
      })
    }
  }

  private validateWrittenDates(): void {
    this.verifyingCaptures.forEach((capture, i: number) =>
      this.writtenDateIsValid[i] = some(this.specialistEncounterVerifications,
        (sev) => isSpecialistEncounterDateWithinRange(sev, capture))
    );
    this.allWrittenDatesAreValid = every(this.writtenDateIsValid, (value) => value) &&
      every(this.specialistEncounterVerifications, (sev) => sev.encounterDate !== null);
  }

}
