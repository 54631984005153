import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
})
export abstract class NavComponent {
  @Input() currentSection: string;
  @Output() sectionClick = new EventEmitter<string>();

  constructor() {}

  navClick($event, section) {
    $event.preventDefault();
    this.sectionClick.emit(section);
  }
}