import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-consult-note-queue-assign-user',
  templateUrl: './consult-note-queue-assign-user.component.html',
  styleUrls: ['./consult-note-queue-assign-user.component.scss'],
})
export class ConsultNoteQueueAssignUserComponent implements OnInit {
  @Input() enabled = false;
  @Input() selectedCount = 0;
  @Input() hideOnNoSelection = true;
  @Input() totalCount: number;

  @Output() assignUser = new EventEmitter<number>();
  @Output() clearSelection = new EventEmitter();

  originalSelectedUser: number;
  selectedUser: number;

  constructor(
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.originalSelectedUser = this.authService.currentUser.id;
  }

  public get canAssign() {
    return this.enabled && this.selectedUser;
  }

  public handleAssignClick($event) {
    $event.preventDefault();
    this.assignUser.emit(this.selectedUser);
  }

  public get canClearSelection() {
    return this.enabled;
  }

  public get selectedConsultNoteText() {
    if (this.selectedCount === 1) {
      return "1 Note selected";
    } else {
      return `${this.selectedCount} Notes selected`;
    }
  }

  public handleClearSelectionClick($event) {
    $event.preventDefault();
    this.clearSelection.emit();
  }

  public handleAssignedUserChanged(id) {
    this.selectedUser = id;
  }

  public get showAassignments(): boolean {
    if (this.selectedCount > 0) {
      return true;
    } else {
      return !this.hideOnNoSelection;
    }
  }

  public get displayTotalCount(): boolean {
    return this.totalCount && this.totalCount >= 0;
  }

  public get totalCountText(): string {
    if (this.totalCount === 1) {
      return "1 Consult note";
    } else {
      return `${this.totalCount} Consult notes`;
    }
  }
}
