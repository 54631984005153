import { Component } from '@angular/core';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';

@Component({
  selector: 'app-capture-history-item-type-referral-match-verification-submitted',
  templateUrl: './capture-history-item-type-referral-match-verification-submitted.component.html',
})
export class CaptureHistoryItemTypeReferralMatchVerificationSubmittedComponent
       extends CaptureHistoryItemTypeBaseComponent {
  get title() {
    return 'Referral Validation Submitted';
  }
}
