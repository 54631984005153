<h5>New Locations</h5>

<p class="fst-italic" *ngIf="!loading && patientInteractionLocations.length > 0">
  NOTE: Data from locations in this list will not be used to capture claims until a review is completed.
</p>

<ng-container *ngIf="!loading; else: spinner">
  <ng-container *ngIf="patientInteractionLocations.length > 0; else: none">
    <app-pagination-header
      [label]="'locations to review'"
      [paging]="paging"
      (pageSizeChange)="onPageSizeChange($event)" />

    <app-patient-interaction-location-list
      [includeClientColumn]="false"
      [patientInteractionLocations]="patientInteractionLocations" />

    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </ng-container>
</ng-container>

<ng-template #none>
  No new locations. All locations have been reviewed.
</ng-template>

<ng-template #spinner>
  <mat-spinner diameter="30" />
</ng-template>
