import { Component, Input, OnInit } from '@angular/core';
import { Prescription } from '../../../core/models/prescription.model';
import { LimitOffsetPaging, Sort } from 'app/core/models/paged-results/limit-offset-paging.model';
import { PrescriptionsService } from 'app/core/services/prescriptions.service';
import { PagedPrescriptions } from 'app/core/services/prescriptions.service';
import { PrescriptionQueueSettings } from 'app/core/models/user-settings/prescription-search-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { Provider } from 'app/core/models/provider.model';

@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.scss'],
})

export class PrescriptionsComponent implements OnInit {
  @Input() provider: Provider;

  prescriptions: Prescription[] = [];
  refreshing = false;
  paging: LimitOffsetPaging;
  eligible = true;
  currentSection: string;

  constructor(
    private prescriptionsService: PrescriptionsService,
    private userSettingsService: UserSettingsService,
  ) {}

  get viewing() {
    return Math.min(this.paging.pageSize, this.paging.count);
  }

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.saveQueueSettings();
    this.loadPrescriptions(this.paging.currentPage);
  }

  onPageChange(page) {
    this.saveQueueSettings();
    this.loadPrescriptions(page);
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.loadQueueSettings();
    this.paging.currentPage = 1;
    this.loadPrescriptions(this.paging.currentPage);
  }

  private loadQueueSettings() {
    const queueSettings: PrescriptionQueueSettings = this.userSettingsService.get<PrescriptionQueueSettings>(
      UserSettings.captureAdminPrescriptionQueueSettings
    );
    if (queueSettings) {
      this.paging = queueSettings.paging;
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new PrescriptionQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    this.userSettingsService.save<PrescriptionQueueSettings>(
      UserSettings.captureAdminPrescriptionQueueSettings, queueSettings
    );
  }

  private loadPrescriptions(page) {
    const paging = { page, pageSize: this.paging.pageSize};

    this.refreshing = true;

    const sort = new Sort();
    sort.col = 'written_date_then_value';
    sort.dir = 'desc';

    this.prescriptionsService.getList(
      {prescriberNpi: this.provider.npi}, sort, paging
    ).subscribe((result: PagedPrescriptions) => {
      this.prescriptions = result.prescriptions || [];
      this.paging = result.meta.paging;
      this.refreshing = false;
    });
  }

  onSectionClick(section: string) {
    this.currentSection = section;
  }

  navClick($event: Event, eligible: boolean) {
    $event.preventDefault();
    this.eligible = eligible;
    this.loadPrescriptions(1);
  }
}
