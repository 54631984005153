<ng-container *ngIf="suboptionChoices">
  <div class="suboption" *ngFor="let suboptionChoice of suboptionChoices; trackBy: trackBySuboptionChoice">
    <app-client-configuration-suboption
      [suboptionTypeChoices]="suboptionTypeChoices"
      [suboptionChoices]="suboptionChoices"
      [suboptionChoice]="suboptionChoice"
      [definition]="definition"
      (choiceChange)="onChoiceChange($event)"
      (choiceRemove)="onChoiceRemove($event)"
    />
  </div>
  <div *ngIf="canAdd" class="small">
    <a href="#" (click)="onAddClick($event)">
      <fa-icon [icon]="['fas', 'plus-circle']" size="1x" />
      Add {{ suboptionTypeDescriptions[definition.suboptionType] }}
    </a>
  </div>
</ng-container>
