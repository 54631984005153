import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { PartnerPortalComponent } from './partner-portal.component';
import { PartnerPortalSearchComponent } from './pages/partner-portal-search/partner-portal-search.component';
import { PartnerPortalMatchReportsComponent } from './pages/partner-portal-match-reports/partner-portal-match-reports.component';
import { CapturesModule } from '../captures/captures.module';

@NgModule({
  imports: [SharedModule, CapturesModule],
  declarations: [PartnerPortalComponent, PartnerPortalSearchComponent, PartnerPortalMatchReportsComponent],
  providers: [],
})
export class PartnerPortalModule {}
