import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { ReportOption } from 'app/core/models/report-option.model';
import { SourcesService } from 'app/core/services/sources.service';

export class ReportSourcesOption implements ReportOption {
  value: string[] | string = null;

  constructor(value) {
    this.value = value;
  }

  toPostData(options = { paramName: 'sources' }) {
    return { [options.paramName]: this.value };
  }
}

@Component({
  selector: 'app-report-sources-option',
  templateUrl: './report-sources-option.component.html',
  styleUrls: ['./report-sources-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportSourcesOptionComponent),
    },
  ],
})
export class ReportSourcesOptionComponent extends ReportAbstractOptionComponent implements OnInit {
  @Input() label = 'Filter by Sources';
  @Input() multiple = true;

  constructor(private sourcesService: SourcesService) {
    super();
  }

  selectedSources: string[] | string;
  sources: string[];

  ngOnInit() {
    this.sourcesService.getList().subscribe(sources => {
      this.sources = sources;
    });
  }

  protected updateInternalState(model: ReportSourcesOption) {
    if (model) {
      this.selectedSources = model.value;
    } else {
      this.selectedSources = [];
    }
  }

  protected determineModelStateOnChange(): ReportSourcesOption {
    if (this.selectedSources && (!Array.isArray(this.selectedSources) || this.selectedSources.length > 0)) {
      return new ReportSourcesOption(this.selectedSources);
    } else {
      return null;
    }
  }
}
