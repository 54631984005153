import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ClientConsultNoteTasksService } from '../../../core/services/client-consult-note-tasks.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';
import {
  ClientConsultNoteTaskChoiceOption,
  choiceOptions,
} from '../../../core/options/client-consult-note-task-choice-opts';
import { ClientConsultNoteTaskChoice } from '../../../core/enums/client-consult-note-task-choice.enum';
import { ClientTaskRejectionReason } from '../../../core/enums/client-task-rejection-reason.enum';
import { getSelectedValues, getKeyedSelectedValues } from '../../../core/lib/enum-utils';

const initSelectedReasons = (reasons, choice) => {
  if (choice === ClientConsultNoteTaskChoice.no) {
    return getKeyedSelectedValues(reasons, ClientTaskRejectionReason);
  } else {
    return {};
  }
};

@Component({
  selector: 'app-client-consult-note-task-options',
  templateUrl: './client-consult-note-task-options.component.html',
  styleUrls: ['./client-consult-note-task-options.component.scss'],
})
export class ClientConsultNoteTaskOptionsComponent implements OnInit, OnChanges {
  formModel = { choice: null, reasonSelections: {} };
  choiceOptions: ClientConsultNoteTaskChoiceOption[] = [];
  choices = ClientConsultNoteTaskChoice;
  disabled = false;
  updatingChoice = false;

  @Input() task: ClientConsultNoteTask;
  @Input() formDisabled: boolean;
  @Output() taskChange = new EventEmitter<ClientConsultNoteTask>();

  constructor(
    private clientConsultNoteTasksService: ClientConsultNoteTasksService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.init();
    this.initChoiceOptions();
  }

  ngOnChanges() {
    this.init();
  }

  onChoiceClick($event, choice: ClientConsultNoteTaskChoice) {
    if (!this.task.frozen && this.formModel.choice !== choice) {
      this.formModel.choice = choice;
      this.updateChoice(choice, this.filterSelectedReasons(choice));
    }
  }

  onRejectionReasonChange(
    { reason, checked }: { reason: ClientTaskRejectionReason; checked: boolean },
    choice: ClientConsultNoteTaskChoice
  ) {
    if (!this.task.frozen) {
      // only one reason at a time can be selected, to match
      // the radio button input UX
      this.formModel.reasonSelections = { [reason]: checked };
      const selectedReasons = this.filterSelectedReasons(choice);
      this.formModel.choice = selectedReasons.length > 0 ? choice : null;
      this.updateChoice(this.formModel.choice, selectedReasons);
    }
  }

  onRejectionReasonsClear() {
    this.formModel.reasonSelections = {};
    this.updateChoice(this.formModel.choice, null);
  }

  private updateChoice(choice: string, choiceReasons: string[]) {
    if (!this.updatingChoice) {
      this.updatingChoice = true;
      const { id, notes, rejectedPrescriptionIds } = this.task;
      this.clientConsultNoteTasksService.updateChoices(id, choice, choiceReasons).subscribe(task => {
        this.taskChange.emit(task);
        this.updatingChoice = false;
      });
    }
  }

  private init() {
    const { choice } = this.task;
    const reasonSelections = initSelectedReasons(this.task.choiceReasons || [], choice);

    this.formModel = { choice, reasonSelections };
    this.disabled = this.formDisabled || this.task.frozen;
  }

  private filterSelectedReasons(choice) {
    if (choice === ClientConsultNoteTaskChoice.no) {
      return getSelectedValues(this.formModel.reasonSelections, ClientTaskRejectionReason);
    } else {
      return [];
    }
  }

  private initChoiceOptions() {
    let filteredOptions = choiceOptions;

    if (this.task.disallowAssignToPar8o) {
      filteredOptions = filteredOptions.filter(opt => opt.value !== ClientConsultNoteTaskChoice.assignBackToPar8o);
    }

    if (!this.authService.isCaptureAdminUser && !this.authService.isInternalConsultantUser) {
      filteredOptions = filteredOptions.filter(opt => opt.value !== ClientConsultNoteTaskChoice.prescriberIsCeProvider);
    }

    this.choiceOptions = filteredOptions;
  }
}
