import { Component, Input, OnInit } from '@angular/core';
import { ChangePasswordStatus } from '../../../core/enums/change-password-status.enum';
import { NgForm } from '@angular/forms';
import { LogoutReason } from '../../../core/enums/logout-reason.enum';
import { AuthenticationService, ChangePasswordResult } from '../../../core/services/authentication.service';
import { AuthPaths } from '../../enums/auth-paths';
import { NavigationService } from '../../../core/services/navigation.service';
import { supportEmailAddress } from '../../../core/constants';

export enum CreatePasswordMode {
  newUser,
  passwordReset,
  forcePasswordChange,
}

@Component({
  selector: 'app-create-password-form',
  templateUrl: './create-password-form.component.html',
  styleUrls: ['./create-password-form.component.scss'],
})
export class CreatePasswordFormComponent implements OnInit {
  @Input() mode: CreatePasswordMode = null;

  createPasswordModes = CreatePasswordMode;
  supportEmailAddress = supportEmailAddress;

  formModel = { password: '', confirmPassword: '' };
  submitted = false;
  submitting = false;

  resetPasswordStatus: ChangePasswordStatus = null;
  resetPasswordStatuses = ChangePasswordStatus;

  firstName = '';
  username = '';
  code = '';

  constructor(private authService: AuthenticationService, private navigationService: NavigationService) {}

  ngOnInit() {
    this.authService.logout(LogoutReason.creatingNewPassword);
    const queryParams = this.navigationService.queryParams;

    this.firstName = queryParams['first_name'];
    this.username = queryParams['username'];
    this.code = queryParams['code'];

    if (!(this.firstName && this.username && this.code)) {
      this.navigationService.navigateHome();
    }
  }

  onSubmit(createPasswordForm: NgForm) {
    this.submitted = true;

    if (createPasswordForm.valid) {
      this.submitting = true;

      this.authService
        .changePassword(this.username, this.code, this.formModel.password, this.formModel.confirmPassword)
        .subscribe((result: ChangePasswordResult) => {
          this.resetPasswordStatus = result.status;
          this.submitting = false;

          if (result.status === ChangePasswordStatus.MFARequired) {
            // @ts-ignore
            const { username, session, destination } = result.data;
            const params = new URLSearchParams({
              username,
              session,
              destination,
            });
            this.navigationService.goToUrl(`/${AuthPaths.confirmMfa}?${params.toString()}`);
          } else if (result.status === ChangePasswordStatus.Success) {
            this.navigationService.navigateHome();
          }
        });
    }
  }

  invalidCss(controlName: string, createPasswordForm: NgForm) {
    if (this.submitted) {
      const control = createPasswordForm.controls[controlName];
      return {
        'is-invalid': this.submitted && control && control.hasError('required'),
      };
    } else {
      return {};
    }
  }
}
