import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Upload } from 'app/core/models/upload.model';
import { Client } from 'app/core/models/client.model';
import { ThirdPartyAdminister } from 'app/core/models/third-party-administer.model';
import { ClientsService } from 'app/core/services/clients.service';
import { ThirdPartyAdministersService } from 'app/core/services/third-party-administers.service';

const emptyForm = { clientId: '', thirdPartyAdministerId: '', name: '', file: null };

export class ImportUploadResult {
  clientId?: number;
  thirdPartyAdministerId?: number;
  name?: string;
  upload: Upload;
}

@Component({
  selector: 'app-icc-import-upload',
  templateUrl: './icc-import-upload.component.html',
})
export class IccImportUploadComponent implements OnInit {
  @Input() collectThirdPartyAdminister = false;
  @Input() collectClient = true;

  @Output() uploadComplete = new EventEmitter<ImportUploadResult>();
  @Output() uploadStart = new EventEmitter();

  uploading = false;
  formModel = { ...emptyForm };
  clients: Client[] = [];
  thirdPartyAdministers: ThirdPartyAdminister[] = [];

  constructor(
    private clientsService: ClientsService,
    private thirdPartyAdministersService: ThirdPartyAdministersService
  ) {}

  ngOnInit() {
    this.loadClients();

    if (this.collectThirdPartyAdminister) {
      this.loadThirdPartyAdministers();
    }
  }

  onUploadStart() {
    this.uploading = true;
    this.uploadStart.emit();
  }

  onUploadCancel() {
    this.uploading = false;
  }

  onUploadComplete(upload: Upload) {
    this.uploading = false;

    const result: ImportUploadResult = {
      upload,
      name: this.formModel.name,
    };

    if (this.collectClient) {
      result.clientId = parseInt(this.formModel.clientId, 10);
    }

    if (this.collectThirdPartyAdminister) {
      result.thirdPartyAdministerId = parseInt(this.formModel.thirdPartyAdministerId, 10);
    }

    this.uploadComplete.emit(result);
  }

  onUploadError() {
    this.uploading = false;
  }

  onFormEnter($event) {
    $event.preventDefault();
  }

  get uploadButtonDisabled(): boolean {
    return !(
      (!this.collectClient || this.formModel.clientId) &&
      (!this.collectThirdPartyAdminister || this.formModel.thirdPartyAdministerId) &&
      this.formModel.file
    );
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToIccClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  loadThirdPartyAdministers() {
    this.thirdPartyAdministersService.getList().subscribe(result => {
      this.thirdPartyAdministers = result.thirdPartyAdministers;
    });
  }

  onFileSelected(file) {
    this.formModel.file = file;
  }
}
