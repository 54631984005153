import { Component, Input } from '@angular/core';
import { Patient } from 'app/core/models/patient.model';
import { PatientService } from 'app/core/services/patient.service';

@Component({
  selector: 'app-patient-demographics',
  templateUrl: './patient-demographics.component.html',
  styleUrls: ['./patient-demographics.component.scss'],
})
export class PatientDemographicsComponent {
  @Input() patient: Patient;

  constructor(
    private patientService: PatientService,
  ) {}

  public onSsnAccessed(id: number): void {
    this.patientService.getSsn(id).subscribe(result => {
      if (this.patient.id === id) {
        this.patient.decryptedSsn = result.decryptedSsn;
      } else {
        const sub = this.patient.subPatients.find(subPatient => subPatient.id === id);
        if (sub) sub.decryptedSsn = result.decryptedSsn;
      }
    });
  }
}
