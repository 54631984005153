import { Component, Input } from '@angular/core';
import { ClientReferralTask } from 'app/core/models/client-referral-task.model';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-client-task-nav',
  templateUrl: './client-task-nav.component.html',
  styleUrls: ['./client-task-nav.component.scss']
})
export class ClientTaskNavComponent {
  @Input() baseUrl: string;
  @Input() task: ClientReferralTask;

  constructor(private authService: AuthenticationService) {}

  public get isCaptureAdmin() {
    return this.authService.isCaptureAdminUser;
  }

  public get detailsUrl() {
    return this.baseUrl;
  }

  public get historyItemsUrl() {
    return `${this.baseUrl}/history-items`;
  }
}
