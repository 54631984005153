import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PdfFileType } from '../enums/pdf-file-type.enum';
import { PdfFile } from '../interfaces/pdf-file';
import { InboundFaxesService } from './inbound-faxes.service';
import { PatientAttachmentsService } from './patient-attachments.service';

export class AnnotationsWillChangeEvent {
  constructor() {}
}

export class PdfFileChangedEvent {
  constructor(public pdfFile: PdfFile, public pdfFileType: PdfFileType) {}
}

@Injectable({
  providedIn: 'root'
})
export class PdfFileService {

  constructor(
    private patientAttachmentsService: PatientAttachmentsService,
    private inboundFaxesService: InboundFaxesService
  ) {}

  private annotationsWillChangeSource = new Subject<AnnotationsWillChangeEvent>();
  annotationsWillChange = this.annotationsWillChangeSource.asObservable();

  private pdfFileChangedSource = new Subject<PdfFileChangedEvent>();
  pdfFileChanged = this.pdfFileChangedSource.asObservable();

  get(pdfFile: PdfFile, pdfFileType: PdfFileType): Observable<PdfFile> {
    if (pdfFileType === PdfFileType.patientAttachment) {
      return this.patientAttachmentsService.get(pdfFile.id);
    } else if (pdfFileType === PdfFileType.inboundFax) {
      return this.inboundFaxesService.get(pdfFile.id);
    } else {
      throw new TypeError("Unknown pdfFileType");
    }
  }

  notifyAnnotationsWillChange() {
    this.annotationsWillChangeSource.next(new AnnotationsWillChangeEvent());
  }

  notifyPdfFileChanged(pdfFile: PdfFile, pdfFileType: PdfFileType) {
    this.pdfFileChangedSource.next(new PdfFileChangedEvent(pdfFile, pdfFileType));
  }

}
