import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ProviderMechanicalTurkReview } from "app/core/models/provider-mechanical-turk-review.model";
import { Provider } from "app/core/models/provider.model";
import { keyedStatusOptions, providerStatusOptions, filterProviderStatusOptions } from 'app/core/options/provider-status.opts';
import { ProviderStatus } from "app/core/enums/provider-status.enum";
import { ProviderService } from "app/core/services/provider.service";
import { mapObjectToFormGroup, applyServerErrorsToFormGroup } from 'app/core/lib/form-utils';
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-provider-status-edit',
  templateUrl: './provider-status-edit.component.html',
  styleUrls: ['./provider-status-edit.component.scss'],
})

export class ProviderStatusEditComponent implements OnInit {
  @Input() provider: Provider | ProviderMechanicalTurkReview;
  @Output() providerStatusChanged = new EventEmitter<Provider>();

  formGroup: FormGroup;
  editing = false;
  saving = false;
  keyedStatusOptions = keyedStatusOptions;
  providerStatusOptions;

  constructor(
    private formBuilder: FormBuilder,
    private providerService: ProviderService,
  ) {}

  ngOnInit() {
    this.initFormGroup();
  }

  onEdit($event: MouseEvent) {
    $event.preventDefault();
    this.editing = true;
  }

  onCancelEdit($event: MouseEvent) {
    $event.preventDefault();
    this.editing = false;
    // resets the drop down to actual value on
    // providerOffice
    this.initFormGroup();
  }

  onSubmit() {
    const attributes = { status: this.formGroup.value.status };

    if (this.didChange()) {
      this.saving = true;

      this.providerService.update(this.provider as Provider, attributes).subscribe(
        provider => this.handleSaveSuccess(provider),
        error => this.handleSaveFailure(error)
      )
    } else {
      this.editing = false;
      this.initFormGroup();
    }
  }

  handleSaveSuccess(provider: Provider) {
    this.provider = provider;
    this.editing = false;
    this.saving = false;
    this.providerStatusChanged.emit(provider);
  }

  handleSaveFailure(response: HttpErrorResponse) {
    this.saving = false;
    if (response.status === 400) {
      applyServerErrorsToFormGroup(response, this.formGroup);
    } else {
      console.error(response);
    }
  }

  initFormGroup() {
    this.formGroup = mapObjectToFormGroup(this.formBuilder, this.provider, [
      'status'
    ]);

    this.providerStatusOptions = filterProviderStatusOptions(this.provider.status);
  }

  didChange() {
    return this.formGroup.value.status !== ProviderStatus.mechanicalTurkReview;
  }
}