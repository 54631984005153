<div class="row">
  <div class="col-4">
    <strong>STATUS</strong>
    <div>{{ inboundFax.status | faxStatus }}</div>
  </div>
  <div class="col-4">
    <strong>RECEIVED AT</strong>
    <div>{{ inboundFax.receivedAt | date: 'M/d/yyyy h:mm a' }}</div>
  </div>
  <div class="col-4">
    <strong>PROCESSED AT</strong>
    <div>{{ (inboundFax.processedAt | date: 'MM/dd/yyyy  hh:mm a') | blankValueDash }}</div>
  </div>
</div>
