<div class="drug-details">
  <div class="row mb-2">
    <div class="col-lg"><strong>ALSO KNOWN AS:</strong></div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <form><textarea class="form-control ndc-akas" value="{{akas()}}" readonly> </textarea></form>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <div><strong>NDC</strong></div>
      <div>
        {{ capture.candidate.ndc }}
        <app-copy-to-clipboard [text]="capture.candidate.ndc" />
      </div>
    </div>
    <div class="col">
      <div><strong>RX-WRITTEN DATE</strong></div>
      <div>{{ capture.candidate.writtenDate | date: 'MM/dd/yyyy' }}</div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <div><strong>RX-DRUG</strong></div>
      <div>
        {{ capture.candidate.product }}
        <app-copy-to-clipboard [text]="capture.candidate.product" />
      </div>
    </div>
    <div class="col">
      <div><strong>RX-SERVICE-DATE</strong></div>
      <div>{{ capture.candidate.serviceDate | date: 'MM/dd/yyyy' }}</div>
    </div>
  </div>
  <div class="row mb-2" *ngIf="showEstimatedValue">
    <div class="col">
      <div><strong>VALUE</strong></div>
      <div>{{ capture.candidate.estimatedValueForDisplay }}</div>
    </div>
  </div>
</div>
