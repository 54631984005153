import { AuthenticationService } from '../../core/services/authentication.service';
import { UserRoleName } from '../../core/enums/user-role-name.enum';
import { NavigationService } from '../../core/services/navigation.service';

export class AccessBaseGuard  {
  constructor(
    protected roleName: UserRoleName,
    protected authService: AuthenticationService,
    protected navigationService: NavigationService
  ) {}

  canActivate(): boolean {
    if (!this.authService.isLoggedIn) {
      return false;
    }

    if (!this.authService.hasPermissionTo(this.roleName)) {
      this.navigationService.navigateHome();
      return false;
    } else {
      return true;
    }
  }
}
