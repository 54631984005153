import { Component, Input, OnInit } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { CaptureService } from 'app/core/services/capture.service';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-verified-invalid-reviewed',
  templateUrl: './verified-invalid-reviewed.component.html',
  styleUrls: ['./verified-invalid-reviewed.component.scss'],
})
export class VerifiedInvalidReviewedComponent implements OnInit {
  @Input() capture: Capture;

  enabled = false;
  canManageVerfieidInvalidReviewed = false;

  constructor(
    private captureService: CaptureService,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.init();
  }

  onClick() {
    this.enabled = true;
    this.captureService.toggleVerifiedInvalidReviewed(this.capture).subscribe(
      capture => this.enabled = capture.verifiedInvalidReviewed
    );
  }

  private init() {
    this.enabled = this.capture.verifiedInvalidReviewed;
    this.canManageVerfieidInvalidReviewed = this.authService.isCaptureAdminUser;
  }
}
