import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { IccImport } from '../../../core/models/icc/icc-import.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const emptyForm = { name: '' };

@Component({
  selector: 'app-icc-import-rename-modal',
  templateUrl: './icc-import-rename-modal.component.html',
})
export class IccImportRenameModalComponent implements OnInit {
  @Input() iccImport: IccImport;
  @Input() updateImport: (importId: number, name: string) => Observable<IccImport>;

  formModel = { ...emptyForm };

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.formModel.name = this.iccImport.name;
  }

  onSubmit($event, form: NgForm) {
    $event.preventDefault();

    const formGroup = form.form;

    this.updateImport(this.iccImport.id, this.formModel.name).subscribe(
      updatedIccImport => {
        this.iccImport.name = updatedIccImport.name;

        this.activeModal.close();
      },
      response => {
        if (response.status === 400) {
          const { error: data } = response;

          Object.keys(data.errors).forEach(fieldName => {
            formGroup.get(fieldName).setErrors({ server: data.errors[fieldName] });
          });
        } else {
          console.error(response);
        }
      }
    );
  }

  onCancelClick() {
    this.activeModal.close();
  }

  invalidCss(form: NgForm, controlName: string) {
    return {
      'is-invalid': this.isControlInvalid(form, controlName),
    };
  }

  private isControlInvalid(form: NgForm, controlName: string) {
    const control = form.form.get(controlName);

    if (control) {
      return control.invalid;
    }
  }
}
