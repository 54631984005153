<div #container class="container" [ngStyle]="containerStyles">
  <div #content>
    <ng-content />
  </div>
</div>

<div *ngIf="expandable">
  <span *ngIf="!expanded">
    (<a href="javascript:;" (click)="onExpandClick(true)">more</a>)
  </span>
  <span *ngIf="expanded">
    (<a href="javascript:;" (click)="onExpandClick(false)">collapse</a>)
  </span>
</div>
