import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PatientMatchVerification } from '../models/patient-match-verification.model';
import { ApiService } from './api.service';

const buildPayload = (verifyingCaptureId: number, { matches, notes }: PatientMatchVerification) => {
  let payload = {
    matches,
    notes,
  };

  if (verifyingCaptureId) {
    payload = Object.assign({}, payload, { verifyingCaptureId });
  }
  return payload;
};

@Injectable()
export class PatientMatchVerificationsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  create(
    patientAttachmentId: number,
    verifyingCaptureId: number,
    pmv: PatientMatchVerification
  ): Observable<PatientMatchVerification> {
    const payload = buildPayload(verifyingCaptureId, pmv);

    return this.http.post<PatientMatchVerification>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/patient_match_verifications`,
      {
        patientMatchVerification: { patientAttachmentId, ...payload },
      }
    );
  }

  update(
    patientAttachmentId: number,
    id: number,
    verifyingCaptureId: number,
    pmv: PatientMatchVerification
  ): Observable<PatientMatchVerification> {
    const payload = buildPayload(verifyingCaptureId, pmv);

    return this.http.patch<PatientMatchVerification>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/patient_match_verifications/${id}`,
      {
        patientMatchVerification: payload,
      }
    );
  }

  match(patientAttachmentId: number): Observable<PatientMatchVerification> {
    return this.http.get<PatientMatchVerification>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/patient_match_verifications/match`,
      {}
    );
  }
}
