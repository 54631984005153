import { Component, OnDestroy, OnInit } from '@angular/core';
import { Base64 } from 'js-base64';
import { switchMap, tap } from 'rxjs/operators';
import { forEach } from 'lodash-es';
import { CaptureQueueResult } from 'app/core/models/capture-lists/capture-queue-result.model';
import { InternalConsultantCapturesService, PagedInternalConsultantCaptureResults } from 'app/core/services/internal-consultant-captures.service';
import { NavigationService, PageChangeEvent } from 'app/core/services/navigation.service';
import { UserPresenceService } from 'app/core/services/user-presence.service';
import { UserTimingService } from 'app/core/services/user-timing.service';
import { Subject } from 'rxjs';
import { InternalConsultantCaptureCursorType } from 'app/core/enums/internal-consultant-capture-cursor-type.enum';
import { Cursor } from '../../../core/models/paged-results/cursor-based-paging.model';

@Component({
  selector: 'app-capture-admin-internal-consultant-captures',
  templateUrl: './capture-admin-internal-consultant-captures.component.html',
  styleUrls: ['./capture-admin-internal-consultant-captures.component.scss'],
})
export class CaptureAdminInternalConsultantCapturesComponent implements OnInit, OnDestroy {
  getResults$: Subject<void> = new Subject();
  pageLoaded = false;
  loadingResults = false;
  hasMoreResults = false;
  limit = 10;
  captureQueueResults: CaptureQueueResult[] = [];
  cursor: Cursor<InternalConsultantCaptureCursorType>;

  constructor(
    private internalConsultantCapturesService: InternalConsultantCapturesService,
    private navigationService: NavigationService,
    private userPresenceService: UserPresenceService,
    private userTimingService: UserTimingService,
  ) {
    this.navigationService.pageChanged.subscribe((pageChangeEvent: PageChangeEvent) => {
      if (pageChangeEvent.navigatedBack && this.pageLoaded) {
        this.initCursorFromParams();
        this.getResults$.next();
      }
    });
  }

  ngOnInit() {
    this.configureGetResults();
    this.initCursorFromParams();
    this.getResults$.next();
    this.startTimeTracking();
    this.pageLoaded = true;
  }

  onSettingsChange() {
    this.startTimeTracking();
    this.cursor = null;
    this.hasMoreResults = false;
    this.updateQueryParams();
    this.getResults$.next();
  }

  onNextClick($event) {
    $event.preventDefault();
    this.updateQueryParams();
    this.getResults$.next();
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.userTimingService.stop();
    this.userPresenceService.unsubscribeAll();
  }

  private updateQueryParams() {
    if (this.cursor) {
      const encodedCursor = encodeURIComponent(Base64.encode(JSON.stringify(this.cursor)));
      this.navigationService.updateQueryParams({ cursor: encodedCursor });
    } else {
      this.navigationService.updateQueryParams({}, true);
    }
  }

  private configureGetResults() {
    // https://www.credera.com/blog/technology-solutions/using-rxjs-switchmap-angular-7-reactive-forms-cancel-pending-requests/
    this.getResults$
      .pipe(
        tap(() => {
          this.loadingResults = true;
          this.captureQueueResults = [];
          this.userPresenceService.unsubscribeAll();
        }),
        switchMap(() => this.internalConsultantCapturesService.getResults(this.cursor))
      )
      .subscribe((results: PagedInternalConsultantCaptureResults) => {
        this.captureQueueResults = results.captureQueueResults;
        this.cursor = results.meta.cursor;
        this.loadingResults = false;
        this.hasMoreResults = !!results.meta.cursor;
        this.subscribeToUserPresence();
      });
  }

  private initCursorFromParams() {
    const queryParams = this.navigationService.queryParams;

    if ('cursor' in queryParams) {
      this.cursor = this.decodeCursor(queryParams);
    } else {
      this.cursor = null;
    }
  }

  private decodeCursor(queryParams) {
    try {
      const cursorParam = decodeURIComponent(queryParams['cursor']);
      return JSON.parse(Base64.decode(cursorParam));
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private subscribeToUserPresence() {
    forEach(this.captureQueueResults, (capture: CaptureQueueResult) => {
      this.userPresenceService.subscribe('capture', capture.id)
    })
  }

  private startTimeTracking() {
    const data: any = { page: 'internal_consultant_captures' };
    this.userTimingService.track(data);
  }
}