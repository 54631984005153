import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from 'app/auth/guards/auth.guard';
import { FeatureGuard } from './guards/feature.guard';
import { AccessManageUsersGuard } from './guards/access-manage-users.guard';
import { AccessManageClientsGuard } from './guards/access-manage-clients.guard';
import { AccessReferralClaimsGuard } from './guards/access-referral-claims.guard';
import { AccessIccClaimsGuard } from './guards/access-icc-claims.guard';
import { AccessManageClientChoresGuard } from './guards/access-manage-client-chores.guard';
import { ConfirmNewUserComponent } from './pages/confirm-new-user/confirm-new-user.component';
import { ConfirmPasswordResetComponent } from './pages/confirm-password-reset/confirm-password-reset.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { CreatePasswordFormComponent } from './components/create-password-form/create-password-form.component';
import { ConfirmForcePasswordChangeComponent } from './pages/confirm-force-password-change/confirm-force-password-change.component';
import { ConfirmMfaComponent } from './pages/confirm-mfa/confirm-mfa.component';
import { SamlComponent } from './pages/saml/saml.component';
import { DeactivatedClientCePortalTasksGuard } from './guards/deactivated-client-ce-portal-tasks.guard';

@NgModule({
  imports: [SharedModule, AuthRoutingModule],
  declarations: [
    LoginComponent,
    ConfirmNewUserComponent,
    ConfirmPasswordResetComponent,
    ForgotPasswordComponent,
    CreatePasswordFormComponent,
    ConfirmForcePasswordChangeComponent,
    ConfirmMfaComponent,
    SamlComponent,
  ],
  providers: [
    AuthGuard,
    FeatureGuard,
    AccessManageUsersGuard,
    AccessManageClientsGuard,
    AccessManageClientChoresGuard,
    AccessReferralClaimsGuard,
    AccessIccClaimsGuard,
    AccessManageClientChoresGuard,
    DeactivatedClientCePortalTasksGuard,
  ],
})
export class AuthModule {}
