import { Component, Input } from '@angular/core';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';

@Component({
  selector: 'app-client-consult-note-task-details',
  templateUrl: './client-consult-note-task-details.component.html',
  styleUrls: ['./client-consult-note-task-details.component.scss'],
})
export class ClientConsultNoteTaskDetailsComponent {
  @Input() task: ClientConsultNoteTask;
}
