import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Capture } from 'app/core/models/capture.model';
import { PrescriptionEhrMatchVerification } from 'app/core/models/prescription-ehr-match-verification.model'
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { PrescriptionEhrMatchVerificationsService } from 'app/core/services/prescription-ehr-match-verifications.service';
import { mapObjectToFormGroup, applyServerErrorsToFormGroup } from 'app/core/lib/form-utils';
import { CaptureService } from 'app/core/services/capture.service';
import { AppToastService } from 'app/core/services/app-toast.service';
import { CaptureStatus } from 'app/core/enums/capture-status.enum';
import { CaptureTransition } from 'app/core/enums/capture-transition.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { CaptureStatusReason } from 'app/core/enums/capture-status-reason.enum';
import { ServerError } from 'app/core/models/server-error.model';
import { showCaptureTransitionedToast } from 'app/core/lib/verification-utils';

@Component({
  selector: 'app-validate-prescription-written-at-ce',
  templateUrl: './validate-prescription-written-at-ce.component.html',
  styleUrls: ['./validate-prescription-written-at-ce.component.scss'],
})
export class ValidatePrescriptionWrittenAtCeComponent implements OnInit, OnDestroy {
  @Input() capture: Capture;

  prescriptionEhrMatchVerification: PrescriptionEhrMatchVerification;
  formGroup: FormGroup;
  frozen = false;
  processing = false;
  serverError = false;
  serverErrorResponse: ServerError;
  captureChangedSubscription = null;

  constructor(
    private formBuilder: FormBuilder,
    private prescriptionEhrMatchVerificationService: PrescriptionEhrMatchVerificationsService,
    private captureService: CaptureService,
    private toastService: AppToastService,
  ) { }

  ngOnInit() {
    this.captureChangedSubscription = this.captureService.captureChanged.subscribe(({ capture }) => {
      if (capture.id === this.capture.id) {
        this.capture = capture;
        this.setupForm();
      }
    });

    this.setupForm();
  }

  ngOnDestroy() {
    if (this.captureChangedSubscription) {
      this.captureChangedSubscription.unsubscribe();
    }
  }

  setupForm() {
    this.prescriptionEhrMatchVerification =
      this.capture.prescriptionEhrMatchVerification || new PrescriptionEhrMatchVerification();

    this.formGroup = mapObjectToFormGroup(this.formBuilder, this.prescriptionEhrMatchVerification, [
      'prescriptionWrittenAtCe',
      'notes'
    ]);

    this.frozen = this.prescriptionEhrMatchVerification.frozen

    if (this.frozen) {
      this.formGroup.disable();
    }

    this.formGroup.valueChanges.pipe(
      debounceTime(250)
    ).subscribe(values => {
      this.persistVerification(values);
    });
  }

  persistVerification(values: any) {
    this.prescriptionEhrMatchVerificationService.
      update(this.capture.prescription.id, values).
      subscribe(result => {
        this.prescriptionEhrMatchVerification = result;
      });
  }

  onVerifyClick() {
    const transition = CaptureTransition.verifyClientPrescriptionInternalConsultant;

    this.transitionCapture(transition);
  }

  onRejectClick() {
    const transition = CaptureTransition.reject;
    const statusReason = CaptureStatusReason.notAValidCePrescriptionInternalConsultant;

    this.transitionCapture(transition, statusReason);
  }

  transitionCapture(transition: string, statusReason = null) {
    const verificationOutcome = { statusReason };

    this.serverError = false;
    this.processing = true;
    this.captureService.transition(this.capture, transition, verificationOutcome).subscribe(
      capture => {
        this.processing = false;
        this.serverError = false;
        this.captureService.notifyCaptureChanged(capture);
        showCaptureTransitionedToast(this.toastService, capture.status);
      },
      (err: HttpErrorResponse) => {
        this.processing = false;
        this.serverError = true;
        this.serverErrorResponse = err.error;
        console.error(err);
      }
    );
  }
}
