import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../../../core/services/clients.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Client } from '../../../core/models/client.model';
import { UserRoleName } from '../../../core/enums/user-role-name.enum';
import { ActivatedRoute } from '@angular/router';
import { ClientSftpCredentialService } from '../../../core/services/client-sftp-credentials.service';

const ehrIntanceValueBlankPlaceholder = "(blank)";

@Component({
  selector: 'app-capture-admin-client',
  templateUrl: './capture-admin-client.component.html',
  styleUrls: ['./capture-admin-client.component.scss'],
})
export class CaptureAdminClientComponent implements OnInit {
  client: Client;
  canManageClients = false;
  effectiveAllowedClientFileEhrInstanceValues: string[];
  loading = false;
  clientSftpCredentials = [];

  constructor(
    private clientsService: ClientsService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private clientSftpCredentialService: ClientSftpCredentialService
  ) {}

  ngOnInit() {
    this.canManageClients = this.authService.hasPermissionTo(UserRoleName.manageClients);
    this.init();
  }

  private init() {
    const clientId = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    this.loading = true;
    this.clientsService.get(clientId).subscribe(client => {
      this.client = client;

      this.effectiveAllowedClientFileEhrInstanceValues =
        client.effectiveAllowedClientFileEhrInstanceValues.map(value => value || ehrIntanceValueBlankPlaceholder);

      this.loadClientSftpCredentials();

      this.loading = false;
    });
  }

  onClientChange(client: Client) {
    this.client = client;
  }

  onPasswordAccessed(id: string) {
    this.loadClientSftpCredentialPassword(id);
  }

  get canManageImplementation() {
    return this.authService.hasPermissionTo(UserRoleName.manageImplementation);
  }

  public assignedUsers() {
    const userNames = this.client.assignedUsers.map(u => u.email).sort();
    return userNames.join(', ');
  }

  public liveThirdPartyAdministers() {
    const names = this.client.liveThirdPartyAdministers.map(a => a.name).sort();
    return names.join(', ');
  }

  public entityTypes() {
    const types = this.client.entityTypes.sort();
    return types.join(', ');
  }

  public contractedThirdPartyAdministers() {
    const names = this.client.contractedThirdPartyAdministers.map(a => a.name).sort();
    return names.join(', ');
  }

  public applicableToClaimsTypes() {
    const types = [];

    if (this.client.applicableToReferralClaims) {
      types.push('Referral');
    }

    if (this.client.applicableToIccClaims) {
      types.push('Icc');
    }

    return types;
  }

  public get processedValidFile() : boolean {
    return !this.client.processingEspNdcs && this.client.espNdcsFileError === null;
  }

  public get hasInvalidEspNdcs() : boolean {
    return this.client.invalidEspNdcs && this.client.invalidEspNdcs.length > 0;
  }

  private loadClientSftpCredentials() {
    if (this.canManageImplementation) {
      this.clientSftpCredentialService.getCredentials(this.client.id).subscribe(result => {
        this.clientSftpCredentials = result;
      });
    }
  }

  private loadClientSftpCredentialPassword(id: string) {
    if (this.canManageImplementation) {
      const credential = this.clientSftpCredentials.find(cred => cred.id === id);

      this.clientSftpCredentialService.getPassword(id).subscribe(result => {
        if (credential) credential.decryptedPassword = result.decryptedPassword;
      });
    }
  }
}
