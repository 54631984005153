import { snakeCase } from 'lodash-es';

const defaultKeyFormatter = k => k;

export class ApiService {
  constructor() {}

  toParams(obj: object): object {
    return this.serialize(obj, snakeCase);
  }

  browserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  private serialize(obj, keyFormatter = defaultKeyFormatter) {
    return Object.keys(obj).reduce((o, k) => {
      if (obj[k]) {
        o[keyFormatter(k)] = obj[k];
      }

      return o;
    }, {});
  }
}
