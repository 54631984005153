import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-verification-heading',
  templateUrl: './verification-heading.component.html',
  styleUrls: ['./verification-heading.component.scss'],
})
export class VerificationHeadingComponent implements OnInit {
  @Input() title: string;
  @Input() patientAttachment: PatientAttachment;

  constructor() {}

  ngOnInit() {}
}
