import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { isEmpty } from 'lodash-es';
import { ProviderOfficeAssociationNotesModalComponent } from '../provider-office-association-notes-modal/provider-office-association-notes-modal.component';
import { ProviderOfficeService } from 'app/core/services/provider-office.service';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-provider-office-association-notes',
  templateUrl: './provider-office-association-notes.component.html',
  styleUrls: ['./provider-office-association-notes.component.scss'],
})
export class ProviderOfficeAssociationNotesComponent implements OnInit {
  // Currently, the calling code has access to a ListProviderOffice which
  // doesn't contain enough information to display the modal.
  @Input() providerOfficeId: number;
  @Input() enablePopover: boolean;

  providerOffice: ProviderOffice;
  hasNotes = false;
  loading = true;

  associationNotesModal: NgbModalRef = null;

  constructor(
    private providerOfficeService: ProviderOfficeService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.init();
  }

  onClick($event) {
    $event.preventDefault();

    // NOTE: the backdrop/keyboard settings above prevent the dismiss flow
    this.associationNotesModal = this.modalService.open(ProviderOfficeAssociationNotesModalComponent, {
      windowClass: 'wide',
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
    });

    const modalInstance = this.associationNotesModal.componentInstance;
    modalInstance.providerOffice = this.providerOffice;

    this.associationNotesModal.result.then(
      (changes: boolean) => this.handleModalClosed(changes)
    );
  }

  public get linkClass() {
    return this.hasNotes ? 'edit-note' : 'add-note';
  }

  public get linkLabel() {
    return this.hasNotes ? 'View Association Note' : 'Add Association Note';
  }

  public get linkLabelTitle() {
    return this.hasNotes ? 'Edit Association Note' : 'Add Association Note'
  }

  public get displayPopover() {
    return this.enablePopover && this.hasNotes;
  }

  public get canAddNotes() {
    return this.authService.isCaptureAdminUser;
  }

  private init() {
    this.loadProviderOffice();
  }

  private loadProviderOffice() {
    this.loading = true;

    this.providerOfficeService.get(this.providerOfficeId).subscribe(providerOffice => {
      this.providerOffice = providerOffice;
      this.hasNotes = !isEmpty(providerOffice.notes);
      this.loading = false;
    })
  }

  private handleModalClosed(changes: boolean) {
    if (changes) {
      this.loadProviderOffice();
    }
  }
}