import { Component, OnDestroy, OnInit } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { ActivatedRoute, Router } from '@angular/router';
import { InboundFaxesService } from '../../../core/services/inbound-faxes.service';
import { UserTimingService } from '../../../core/services/user-timing.service';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { UserPresenceService } from 'app/core/services/user-presence.service';

const shouldReloadFax =
  (inboundFax: InboundFax, inboundFaxId) => inboundFax && inboundFaxId !== inboundFax.id.toString();

@Component({
  templateUrl: './capture-admin-inbound-fax.component.html'
})
export class CaptureAdminInboundFaxComponent implements OnInit, OnDestroy {
  inboundFaxId: string;
  inboundFax: InboundFax;
  refreshing = true;

  constructor(
    private route: ActivatedRoute,
    private inboundFaxesService: InboundFaxesService,
    private router: Router,
    private titleService: Title,
    private userPresenceService: UserPresenceService,
    private userTimingService: UserTimingService
  ) {
    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  ngOnInit() {
    this.inboundFaxId = this.route.snapshot.paramMap.get('id');
    this.loadInboundFax(this.inboundFaxId);
  }

  ngOnDestroy(): void {
    this.userTimingService.stop();
    this.userPresenceService.leaveAndUnsubscribe("fax", this.inboundFaxId);
  }

  private handleRouteChange(paramsMap) {
    const params = paramsMap['params'];
    const inboundFaxId = params && params['id'];

    if (shouldReloadFax(this.inboundFax, inboundFaxId)) {
      this.userPresenceService.leaveAndUnsubscribe("fax", this.inboundFaxId);
      this.inboundFaxId = inboundFaxId;
      this.loadInboundFax(inboundFaxId);
    }
  }

  private loadInboundFax(inboundFaxId): void {
    this.refreshing = true;
    this.inboundFaxesService.get(inboundFaxId).subscribe(
      inboundFax => {
        this.inboundFax = inboundFax;
        this.refreshing = false;
        this.updateTitle();
        this.startTimeTracking();
        this.userPresenceService.subscribeAndJoin("fax", this.inboundFaxId);
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
    window.scrollTo(0, 0);
  }

  private updateTitle() {
    const titleDetail = this.inboundFax.patient ? this.inboundFax.patient.mrn : this.inboundFax.client.humanizedName;
    const title = `R1 340B Recovery - View Fax | ${titleDetail}`;
    this.titleService.setTitle(title);
  }

  private startTimeTracking() {
    this.userTimingService.track({
      page: 'inbound_fax_details',
      recordId: this.inboundFax.id,
      status: this.inboundFax.status,
    });
  }
}
