import { TitleCasePipe } from '@angular/common';

export const displayTaskStatus = (value: string): string => {
  if (value) {
    value = value.replace(new RegExp('_', 'g'), ' ');
    return new TitleCasePipe().transform(value);
  } else {
    return value;
  }
}
