import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';
import { ReportMonthRangeOption } from 'app/shared/components/report-month-range-option/report-month-range-option.component';

@Component({
  templateUrl: './capture-admin-capture-invoice-line-item-reports.component.html',
})
export class CaptureAdminCaptureInvoiceLineItemReportsComponent implements OnInit {
  reportsUrl = `${environment.captureApi.url}/capture_invoice_line_item_reports`;
  reportsCountUrl = `${environment.captureApi.url}/capture_invoice_line_item_reports/count`;

  filtersForm: UntypedFormGroup;

  effectiveDateFilterOptions: {
    minYear: number;
    minMonth: number;
    maxYear: number;
    maxMonth: number;
  };

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.setupFilters();
  }

  setupFilters() {
    const currentDate = new Date();

    const effectiveDateFilter = new ReportMonthRangeOption({
      fromYear: currentDate.getFullYear(),
      fromMonth: currentDate.getMonth(),
      toYear: currentDate.getFullYear(),
      toMonth: currentDate.getMonth() + 1,
    });

    this.filtersForm = this.formBuilder.group({
      effectiveDate: [effectiveDateFilter, Validators.required],
    });

    this.effectiveDateFilterOptions = {
      minYear: 2018,
      minMonth: 1,
      maxYear: currentDate.getFullYear(),
      maxMonth: currentDate.getMonth() + 1,
    };
  }
}
