import { Component, Input, OnInit } from '@angular/core';
import { Provider } from 'app/core/models/provider.model';
import { ProviderService } from 'app/core/services/provider.service';
import { ProviderOffice } from 'app/core/models/provider-office.model';

@Component({
  selector: 'app-provider-communications',
  templateUrl: './provider-communications.component.html',
  styleUrls: ['./provider-communications.component.scss'],
})
export class ProviderCommunicationsComponent implements OnInit {
  @Input() provider: Provider;
  providerOffices: ProviderOffice[];
  providerOfficesLoading = true;

  constructor(private providerService: ProviderService) { }

  ngOnInit() {
    this.loadProviderOffices();
  }

  private loadProviderOffices() {
    this.providerService.getProviderOffices(this.provider.id).subscribe((providerOffices: ProviderOffice[]) => {
      this.providerOffices = providerOffices;
      this.providerOfficesLoading = false;
    });
  }
}
