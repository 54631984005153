import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Prescription } from '../../../core/models/prescription.model';
import { ClientReferralTaskChoice } from '../../../core/enums/client-referral-task-choice.enum';
import { ClientConsultNoteTaskChoice } from '../../../core/enums/client-consult-note-task-choice.enum';
import { ClientReferralTaskStatus } from '../../../core/enums/client-referral-task-status.enum';
import { ClientConsultNoteTaskStatus } from '../../../core/enums/client-consult-note-task-status.enum';

const noChoices = [ClientReferralTaskChoice.no, ClientConsultNoteTaskChoice.no];
const closedStatuses = [ClientReferralTaskStatus.closed, ClientConsultNoteTaskStatus.closed];

export const initRejection = (
  task: { rejectedPrescriptionIds: number[]; choice; status },
  prescription: Prescription
): { checked: boolean; disabled: boolean } => {
  const checked = task.rejectedPrescriptionIds.some(pId => pId === prescription.id);
  const disabled = noChoices.some(v => v === task.choice) || closedStatuses.some(v => v === task.status);

  return { checked, disabled };
};

@Component({
  selector: 'app-client-task-reject-prescription',
  templateUrl: './client-task-reject-prescription.component.html',
  styleUrls: ['./client-task-reject-prescription.component.scss'],
})
export class ClientTaskRejectPrescriptionComponent implements OnInit, OnChanges {
  @Input() prescriptionId: number;
  @Input() rejection: { checked: boolean; disabled: boolean };

  @Output() rejectionChange = new EventEmitter<{ prescriptionId: number; checked: boolean }>();

  checked = false;
  disabled = false;

  constructor() {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  onRejectionChange() {
    this.rejectionChange.emit({ prescriptionId: this.prescriptionId, checked: this.checked });
  }

  init() {
    this.checked = this.rejection.checked;
    this.disabled = this.rejection.disabled;
  }
}
