<app-reports
  title="Captured Claims Reports"
  [filtersForm]="filtersForm"
  [reportsUrl]="reportsUrl">

  <ng-template appNewReportOptionsTemplate let-filtersForm="filtersForm">
    <ng-container [formGroup]="filtersForm">

      <app-report-date-range-option
        formControlName="verifiedDate"
        label="Verification Date" />

    </ng-container>
  </ng-template>

  <ng-template appReportDetailsTemplate let-report="report">

    <app-report-applied-date-range-option
      [options]="report.filters"
      name="verifiedDate"
      label="Verification Date" />

  </ng-template>

</app-reports>
