import { keyBy } from 'lodash-es';
import { ClaimSource } from '../enums/claim-source.enum';

// TODO: [#185472863] Consider using ThirdPartyAdminister model for this information
export const ClaimSourceOptions = [
  { value: ClaimSource.bioPlus, display: 'BioPlus' },
  { value: ClaimSource.wellPartner, display: 'CVS Wellpartner' },
  { value: ClaimSource.hudsonHeadwaters, display: 'Hudson Headwaters' },
  { value: ClaimSource.equiscripts, display: 'Equiscripts' },
  { value: ClaimSource.walgreens, display: 'Walgreens' },
  { value: ClaimSource.pdmi, display: 'PDMI' },
  { value: ClaimSource.captureRx, display: 'CaptureRx' },
  { value: ClaimSource.verity, display: 'Verity' },
  { value: ClaimSource.louvirVerity, display: 'Louvir-Verity' },
  { value: ClaimSource.louvir340Basics, display: 'Louvir-340Basics' },
  { value: ClaimSource.macrohelix, display: 'Macrohelix' },
];

export const keyedClaimSourceOptions = keyBy(ClaimSourceOptions, 'value');
