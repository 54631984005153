import { filter, catchError, switchMap, take, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AppVersion } from '../core/lib/app-version';
import { LogoutReason } from '../core/enums/logout-reason.enum';
import { AuthenticationService, RefreshTokenResult } from '../core/services/authentication.service';
import { PendingHttpRequestsService } from '../core/services/pending-http-requests.service';
import { HttpMethods } from '../core/enums/http-methods-enum';
import { nanoid } from 'nanoid';

const anonRequests = [
  { method: HttpMethods.POST, endpoint: '/sessions' },
  { method: HttpMethods.POST, endpoint: '/sessions/create_from_code' },
  { method: HttpMethods.PATCH, endpoint: '/sessions/refresh' },
  { method: HttpMethods.PATCH, endpoint: '/sessions/perform_mfa' },
  { method: HttpMethods.PATCH, endpoint: '/me/change_password' },
];

const isAnonymousApiRequest = ({ method, url }: HttpRequest<any>) =>
  anonRequests.some(anon => method === anon.method && url.endsWith(anon.endpoint));

const responseToError = (errorResponse: HttpErrorResponse) => {
  if (errorResponse.status === 0) {
    console.error(`HTTP Failure: ${errorResponse.message}`);
  } else if (errorResponse.error instanceof ErrorEvent) {
    console.error(`HTTP Error: ${errorResponse.error.message}`);
  } else {
    console.error(`HTTP Error: ${errorResponse.status} ${errorResponse.message}`);
  }
  return errorResponse;
};

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private waitForTokenRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthenticationService,
    private pendingHttpRequestsService: PendingHttpRequestsService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestId = nanoid();
    this.pendingHttpRequestsService.add(requestId, request);
    return this.processRequest(request, next).pipe(
      finalize(() => {
        this.pendingHttpRequestsService.remove(requestId);
      })
    );
  }

  private processRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!isAnonymousApiRequest(request)) {
      return next.handle(this.addHeaders(request)).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
          if (httpErrorResponse.status === 401) {
            return this.handle401(request, next, httpErrorResponse);
          } else {
            return throwError(responseToError(httpErrorResponse));
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }

  private addHeaders(request: HttpRequest<any>) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.jwtIdentity}`,
      'X-340B-APP-VERSION': AppVersion.humanize(true),
      'X-U-ID': this.authService.userSub,
      'X-340B-PAGE': window.location.pathname,
    };

    if (!!this.authService.viewingAsClient) {
      headers['X-340B-VIEW-AS-CLIENT'] = this.authService.viewingAsClient.id.toString();
    }

    return request.clone({ setHeaders: headers });
  }

  private handle401(
    request: HttpRequest<any>,
    next: HttpHandler,
    httpErrorResponse: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    if (this.refreshTokenInProgress) {
      return this.waitForTokenRefresh.pipe(filter(result => result === false)).pipe(
        take(1),
        switchMap(() => next.handle(this.addHeaders(request)))
      );
    } else {
      return this.refreshTokens(request, next, httpErrorResponse);
    }
  }

  private refreshTokens(
    request: HttpRequest<any>,
    next: HttpHandler,
    httpErrorResponse: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    this.refreshTokenInProgress = true;
    this.waitForTokenRefresh.next(true);

    return this.authService.refreshTokens().pipe(
      switchMap((result: RefreshTokenResult) => {
        this.refreshTokenInProgress = false;
        this.waitForTokenRefresh.next(false);

        if (result !== RefreshTokenResult.Succeeded) {
          this.authService.logout(LogoutReason.failedToRefreshTokens);
          return throwError(httpErrorResponse);
        } else {
          return next.handle(this.addHeaders(request));
        }
      }),
      catchError(err => {
        this.refreshTokenInProgress = false;
        this.waitForTokenRefresh.next(false);
        this.authService.logout(LogoutReason.failedToRefreshTokens);
        return throwError(err);
      })
    );
  }
}
