import { Component, OnInit } from '@angular/core';
import { Client } from '../../../core/models/client.model';
import { ClientFormMode } from '../../components/client-form/client-form.component';

@Component({
  selector: 'app-capture-admin-client-new',
  templateUrl: './capture-admin-client-new.component.html',
  styleUrls: ['./capture-admin-client-new.component.scss'],
})
export class CaptureAdminClientNewComponent implements OnInit {
  client: Client = new Client();
  uploading = false;
  formModes = ClientFormMode;

  constructor() {}

  ngOnInit() {
    this.client.fileProcessingEnabled = true;
  }
}
