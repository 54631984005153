import { Component, Input } from '@angular/core';
import { ClientPrescriberTask } from '../../../core/models/client-prescriber-task.model';

@Component({
  selector: 'app-client-prescriber-task-details',
  templateUrl: './client-prescriber-task-details.component.html',
  styleUrls: ['./client-prescriber-task-details.component.scss'],
})
export class ClientPrescriberTaskDetailsComponent {
  @Input() task: ClientPrescriberTask;
}
