import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-consult-note-validator',
  templateUrl: './consult-note-validator.component.html',
  styleUrls: ['./consult-note-validator.component.scss'],
})
export class ConsultNoteValidatorComponent implements OnInit {
  _patientAttachment: PatientAttachment;

  @Output() markedAsInvalidChanged = new EventEmitter<PatientAttachment>();

  markingAsInvalid = false;
  markAsInvalidDisabled = false;
  usePdfKitViewer: boolean;

  constructor(
    private patientAttachmentsService: PatientAttachmentsService,
    private authService: AuthenticationService) {}

  get patientAttachment(): PatientAttachment {
    return this._patientAttachment;
  }

  @Input()
  set patientAttachment(pa: PatientAttachment) {
    this._patientAttachment = pa;
    this.markAsInvalidDisabled = this.patientAttachment.hasVerifiedCapture;
  }

  onMarkAsInvalidClick($event) {
    $event.preventDefault();
    this.markingAsInvalid = true;
    const invalid = !this.patientAttachment.markedAsInvalid;

    this.patientAttachmentsService.markAsInvalid(this.patientAttachment.id, invalid, true).subscribe(
      pa => {
        this.markedAsInvalidChanged.emit(pa);
        this.markingAsInvalid = false;
      },
      () => {
        this.markingAsInvalid = false;
      }
    );
  }

  ngOnInit() {
    this.usePdfKitViewer = environment.featureData?.usePdfKitViewer && this.authService.isCaptureAdminUser;
  }
}
