import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MeService } from '../../../core/services/me.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { User } from '../../../core/models/user.model';

@Component({
  selector: 'app-accept-eula-modal',
  templateUrl: './accept-eula-modal.component.html',
  styleUrls: ['./accept-eula-modal.component.scss'],
})
export class AcceptEulaModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    public meService: MeService,
    public authService: AuthenticationService
  ) {}

  ngOnInit() {}

  onAcceptClick() {
    this.meService.acceptEula().subscribe((user: User) => {
      this.authService.updateCurrentUser(user);
      this.activeModal.close();
    });
  }
}
