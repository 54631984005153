<app-reports
  title="Capture Invoice Report"
  [filtersForm]="filtersForm"
  [reportsUrl]="reportsUrl"
  [reportsCountUrl]="reportsCountUrl">

  <ng-template appNewReportOptionsTemplate let-filtersForm="filtersForm">
    <ng-container [formGroup]="filtersForm">

      <app-report-month-range-option
        name="effectiveDate"
        label="Invoice Date"
        formControlName="effectiveDate"
        [minYear]="effectiveDateFilterOptions.minYear"
        [minMonth]="effectiveDateFilterOptions.minMonth"
        [maxYear]="effectiveDateFilterOptions.maxYear"
        [maxMonth]="effectiveDateFilterOptions.maxMonth" />

    </ng-container>
  </ng-template>

  <ng-template appReportDetailsTemplate let-report="report">

    <app-report-applied-month-range-option
      [options]="report.filters"
      name="effectiveDate"
      label="Invoice Date" />

  </ng-template>

</app-reports>
