import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListProviderOffice } from 'app/core/models/list-provider-office.model';
import { PrescriberMatchVerification } from '../../../core/models/prescriber-match-verification.model';

export class ValidatePrescriberMatchOption {
  constructor(
    public npi: string,
    public name: string,
    public specialty: string,
    public relevantProviderOffice: ListProviderOffice,
    public verification: PrescriberMatchVerification,
    public frozen: boolean,
    public selected: boolean
  ) {}
}

@Component({
  selector: 'app-validate-prescriber-match-verifications',
  templateUrl: './validate-prescriber-match-verifications.component.html',
  styleUrls: ['./validate-prescriber-match-verifications.component.scss'],
})
export class ValidatePrescriberMatchVerificationsComponent {
  @Input() prescriberMatchOptions: ValidatePrescriberMatchOption[];
  @Output() verificationChange = new EventEmitter<PrescriberMatchVerification>();

  constructor() {}

  onPrescriberVerificationChange(prescriberMatchVerification: PrescriberMatchVerification) {
    this.verificationChange.emit(prescriberMatchVerification);
  }
}
