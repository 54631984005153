<div *ngIf="outboundFax.inboundCalls.length > 0; else: noCalls">
  Yes -
  <br />
  <div *ngFor="let inboundCall of outboundFax.inboundCalls">
    {{ keyedInboundCallReasonOptions[inboundCall.reason].display }}:
    <a target="_blank" routerLink="/capture-admin/communications/inbound-calls/{{ inboundCall.id }}">
      {{ inboundCall.createdAt | date: 'M/d/yyyy' }}
    </a>
  </div>
</div>

<ng-template #noCalls>No</ng-template>
