import { CaptureQueueCursorType } from '../../enums/capture-queue-cursor-type.enum';
import { CaptureStatus } from '../../enums/capture-status.enum';
import { CaptureStatusReason } from '../../enums/capture-status-reason.enum';

export class ProviderCapturesSearchFilter {
  constructor(
    public statuses: CaptureStatus[] = [],
    public reasons: CaptureStatusReason[] = [],
    public highValue: boolean = false,
    public activeClientsOnly: boolean = true,
    public manualFaxSent: boolean | null = null,
  ) {}
}

export class ProviderCapturesSearchSettings {
  constructor(
    public filters: ProviderCapturesSearchFilter = new ProviderCapturesSearchFilter(),
    public cursorType: CaptureQueueCursorType = CaptureQueueCursorType.highestEstimatedValue
  ) {}
}