import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Prescriber } from '../../../core/models/prescriber.model';

@Component({
  selector: 'app-prescriber-list',
  templateUrl: './prescriber-list.component.html',
  styleUrls: ['./prescriber-list.component.scss'],
})
export class PrescriberListComponent implements OnInit {
  @Input() prescribers: Prescriber[];

  @Output() public prescriberSelect = new EventEmitter<Prescriber>();

  constructor() {}

  ngOnInit() {}

  onPrescriberClick(prescriber: Prescriber) {
    this.prescriberSelect.emit(prescriber);
  }
}
