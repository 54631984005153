import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { CaptureService } from '../../../core/services/capture.service';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationService } from '../../../core/services/navigation.service';
import { PatientOutboundFaxesComponent } from 'app/shared/components/patient-outbound-faxes/patient-outbound-faxes.component';
import { UserPresenceService } from 'app/core/services/user-presence.service';

@Component({
  selector: 'app-capture-fax',
  templateUrl: './capture-fax.component.html',
  styleUrls: ['./capture-fax.component.scss'],
})
export class CaptureFaxComponent implements OnInit, OnDestroy {
  private _patientOutboundFaxesComponent: PatientOutboundFaxesComponent;

  captureId: string;
  capture: Capture;
  loadingCapture = false;

  constructor(
    private captureService: CaptureService,
    private titleService: Title,
    private navigationService: NavigationService,
    private userPresenceService: UserPresenceService
  ) {}

  @ViewChild('patientOutboundFaxes')
  set patientOutboundFaxes(patientOutboundFaxes: PatientOutboundFaxesComponent) {
    if (patientOutboundFaxes) {
      this._patientOutboundFaxesComponent = patientOutboundFaxes;
    }
  }

  get patientOutboundFaxes() {
    return this._patientOutboundFaxesComponent;
  }

  ngOnInit() {
    this.captureId = this.navigationService.currentId;
    this.loadCapture(this.captureId);
    this.userPresenceService.subscribeAndJoin("capture", this.captureId)
  }

  ngOnDestroy() {
    this.userPresenceService.leaveAndUnsubscribe("capture", this.captureId)
  }

  onFaxSent() {
    if (this.patientOutboundFaxes) {
      this.patientOutboundFaxes.refreshPatientFaxes();
    }
  }

  private setTitle() {
    const title = `R1 340B Recovery - ${this.capture.candidate.claimIdentifier} | Fax`;
    this.titleService.setTitle(title);
  }

  private loadCapture(captureId): void {
    this.loadingCapture = true;
    this.captureService.getCapture(captureId).subscribe(
      capture => {
        this.capture = capture;
        this.loadingCapture = false;
        this.setTitle();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.navigationService.notFound();
        } else {
          console.error(err);
        }
      }
    );
  }
}
