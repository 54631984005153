import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { UserTodos } from '../models/user-todo.model';
import { UserFeatures } from '../models/user-features.model';

export class UserTodosChangedEvent {
  constructor(public todos: UserTodos) {}
}

@Injectable()
export class MeService extends ApiService {
  private userTodosChangedSource = new Subject<UserTodosChangedEvent>();
  userTodosChanged = this.userTodosChangedSource.asObservable();

  private clientChangedSource = new Subject<UserTodosChangedEvent>();
  selectedClientChanged = this.clientChangedSource.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  resetPassword(email: string): Observable<Record<string, unknown>> {
    return this.http.patch<Record<string, unknown>>(
      `${environment.captureApi.url}/me/reset_password`,
      {
        user: { email },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  put(user: { enableCeReviewRequestsEmail?: boolean; defaultAllowedClientId?: number }): Observable<User> {
    return this.http.put<User>(
      `${environment.captureApi.url}/me`,
      JSON.stringify({
        user,
      })
    );
  }

  updateSelectedClient(clientId: number): Observable<User> {
    const observable = this.put({ defaultAllowedClientId: clientId })

    observable.subscribe(() => {
      this.notifyUserTodosChanged();
      this.clientChangedSource.next();
    });

    return observable;
  }

  get(): Observable<User> {
    return this.http.get<User>(`${environment.captureApi.url}/me`);
  }

  getTodos(): Observable<UserTodos> {
    return this.http.get<UserTodos>(`${environment.captureApi.url}/me/todos`);
  }

  getFeatures(): Observable<UserFeatures> {
    return this.http.get<UserFeatures>(`${environment.captureApi.url}/me/features`);
  }

  acceptEula(): Observable<User> {
    return this.http.patch<User>(`${environment.captureApi.url}/me/accept_eula`, {});
  }

  confirmMfaPhoneNumber(phoneNumber: string): Observable<User> {
    return this.http.patch<User>(`${environment.captureApi.url}/me/confirm_mfa_phone`, {
      user: { phoneNumber },
    });
  }

  notifyUserTodosChanged() {
    this.getTodos().subscribe(todos => {
      this.userTodosChangedSource.next(new UserTodosChangedEvent(todos));
    });
  }
}
