import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { supportEmailAddress } from '../../../core/constants';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  supportEmailAddress = supportEmailAddress;
  constructor(
    private navigationService: NavigationService,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    if(!this.authService.isInMaintenance) {
      this.navigationService.navigateHome();
    }
  }
}
