import { Component, Input } from '@angular/core';
import { CaptureQueueResult } from '../../../core/models/capture-lists/capture-queue-result.model';

@Component({
  selector: 'app-capture-search-results',
  templateUrl: './capture-search-results.component.html',
  styleUrls: ['./capture-search-results.component.scss'],
})
export class CaptureSearchResultsComponent {
  @Input() captureQueueResults: CaptureQueueResult[] = [];

  constructor() {}
}
