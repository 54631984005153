import { Component, Input, OnInit } from '@angular/core';
import { EditorType, SuboptionTypeDescriptions } from '../../lib/client-configuration';
import { ClientConfigurationOptionDefinition } from '../../../core/models/client-configuration-option-definition.model';
import { ClientConfigurationOption } from '../../../core/models/client-configuration-option.model';

@Component({
  selector: 'app-client-configuration-title',
  templateUrl: './client-configuration-title.component.html',
  styleUrls: ['./client-configuration-title.component.scss'],
})
export class ClientConfigurationTitleComponent implements OnInit {
  @Input() editorType: EditorType;
  @Input() deactivated: boolean;
  @Input() definition: ClientConfigurationOptionDefinition;
  @Input() configOption: ClientConfigurationOption;

  editorTypes = EditorType;
  suboptionTypeDescriptions = SuboptionTypeDescriptions;

  constructor() {}

  ngOnInit(): void {}
}
