import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { VersionService } from './services/version.service';
import { AppVersion } from './lib/app-version';
import { environment } from '../../environments/environment';
import { isBlank } from './lib/js-utils';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  currentFrontendVersion = AppVersion.humanize(true);
  isThrottled = false;
  throttleDurationMs = 5000;

  constructor(private versionService: VersionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only do version checking with requests to our own backend
    const url = new URL(request.url).toString();
    if (!url.startsWith(environment.captureApi.url)) {
      return next.handle(request);
    } else {
      return next.handle(request).pipe(
        tap(event => this.checkVersion(event))
      );
    }
  }

  private checkVersion(event: HttpEvent<any>): void {
    if (event instanceof HttpResponse) {
      const backendVersion = event.headers.get('X-340B-API-VERSION');
      if (backendVersion && this.isFrontendBehind(backendVersion) && !this.isThrottled) {
        this.isThrottled = true;
        setTimeout(() => { this.isThrottled = false }, this.throttleDurationMs);
        this.isNewFrontendAvailable().subscribe(isAvailable => {
          if (isAvailable) {
            this.versionService.showVersionAlert();
          }
        })
      }
    }
  }

  private isFrontendBehind(version: string): boolean {
    if (version === this.currentFrontendVersion || isBlank(version)) {
      return false;
    } else {
      const versionTimestamp = this.getTimestamp(version);
      const currentFrontendTimestamp = this.getTimestamp(this.currentFrontendVersion);
      return versionTimestamp > currentFrontendTimestamp;
    }
  }

  private isNewFrontendAvailable(): Observable<boolean> {
    return this.versionService.getAvailableFrontendVersion().pipe(
      map(result => this.isFrontendBehind(result)),
      catchError((error) => {
        console.error('Error checking for new frontend version:', error);
        return of(false);
      })
    );
  }

  private getTimestamp(version: string) {
    const parts = version.split("-");
    return parseInt(parts[parts.length - 1], 10);
  }
}
