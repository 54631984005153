import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticationService, PerformMfaResult } from '../../../core/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-confirm-mfa',
  templateUrl: './confirm-mfa.component.html',
  styleUrls: ['./confirm-mfa.component.scss'],
})
export class ConfirmMfaComponent implements OnInit {
  securityCode: string = null;
  error = false;

  username = '';
  session = '';
  destination = '';

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;

    this.username = queryParams['username'];
    this.session = queryParams['session'];
    this.destination = queryParams['destination'];

    if (!(this.username && this.session && this.destination)) {
      this.router.navigate(['/login'], { replaceUrl: true });
    } else {
      document.getElementById('securityCode').focus();
    }
  }

  onSubmit(confirmMfaForm: NgForm) {
    if (confirmMfaForm.valid) {
      this.authenticationService.performMfa(this.username, this.session, this.securityCode).subscribe(
        (result: PerformMfaResult) => {
          this.error = result !== PerformMfaResult.Succeeded;
        },
        (_err: HttpErrorResponse) => {
          this.error = true;
        }
      );
    }
  }
}
