<div class="row">
  <div class="col-6">
    <h3 class="heading mb-0">{{ inboundFax.client.humanizedName }}</h3>
    <h5>
      <span class="fax-number">
        <fa-icon [icon]="['fas', 'fax']" size="sm" />
        <ng-container *ngIf="inboundFax.callerNumber; else unknownCaller">
          <app-phone-number [number]="inboundFax.callerNumber" />
          <app-copy-to-clipboard [text]="inboundFax.callerNumber | phoneNumber" />
        </ng-container>
        <ng-template #unknownCaller> UNKNOWN CALLER </ng-template>
      </span>
    </h5>
  </div>
</div>
