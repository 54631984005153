export enum UserType {
  unknown = 'unknown',
  captureAdmin = 'capture_admin',
  cePortal = 'ce_portal',
  cePortalConsultant = 'ce_portal_consultant',
  cePortalInternalConsultant = 'ce_portal_internal_consultant',
  partnerPortal = 'partner_portal',
  llmPlaygroundUser = 'llm_playground_user',
}

export const isCePortalUser = userType =>
  [UserType.cePortal, UserType.cePortalConsultant, UserType.cePortalInternalConsultant].some(
    ut => userType === ut
  );

export const isCePortalConsultantUser = userType =>
  [UserType.cePortalConsultant, UserType.cePortalInternalConsultant].some(
    ut => userType === ut
  );
