import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { FileRotationsService } from '../../../core/services/file-rotations.service';
import { FileRotation } from '../../../core/models/file-rotation.model';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { FileRotationStatus } from '../../../core/enums/file-rotation-status.enum';
import { PdfFile } from '../../../core/interfaces/pdf-file';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-pdf-file-viewer',
  templateUrl: './pdf-file-viewer.component.html',
  styleUrls: ['./pdf-file-viewer.component.scss'],
})
export class PdfFileViewerComponent implements OnChanges, OnDestroy {
  @Input() pdfFile: PdfFile;
  @Input() initialPage = null;
  @Input() pdfFileType: PdfFileType;
  @Input() height: string;
  @Input() hideRotationControls = false;
  @Output() pageChange = new EventEmitter<number>();

  nextCheckTimeout = null;

  fileRotation: FileRotation = null;
  error: any;
  pdf: PDFDocumentProxy;
  rotation = 0;
  zoom = 1.0;
  isLoaded = false;
  rotating = false;
  showAll = true;
  fitToPage = false;
  renderText = false;
  autoResize = true;
  originalSize = true;
  stickToPage = false;

  constructor(private authService: AuthenticationService, private fileRotationsService: FileRotationsService) {
    // See https://github.com/VadimDez/ng2-pdf-viewer#set-custom-path-to-the-worker
    if (!environment.localApiServer) {
      (window as any).pdfWorkerSrc = '/pdf.worker.min.js';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'pdfFile') {
        this.isLoaded = false;
        this.checkRotation();
      }
    });
  }

  ngOnDestroy() {
    clearTimeout(this.nextCheckTimeout);
    this.cleanupPdf();
  }

  get dirty(): boolean {
    return this.rotation !== 0;
  }

  onZoomClick(amount: number) {
    this.zoom += amount;
  }

  onSaveClick() {
    this.rotating = true;
    this.fileRotationsService.rotate(this.pdfFile, this.pdfFileType, this.rotation).subscribe(ft => {
      this.updateRotation(ft);
    });
  }

  onCancelClick() {
    if (this.rotating) {
      this.fileRotationsService.cancel(this.fileRotation).subscribe(() => {
        this.cancelRotation();
      });
    } else {
      this.cancelRotation();
    }
  }

  onRotateClick(angle: number) {
    if (Math.abs(this.rotation) === 270) {
      this.rotation = 0;
    } else {
      this.rotation += angle;
    }
  }

  onPdfLoadComplete(pdf: PDFDocumentProxy) {
    this.isLoaded = true;
    this.pdf = pdf;
  }

  onPdfPageChange($event) {
    if (this.isLoaded) {
      this.pageChange.emit($event);
    }
  }

  private cleanupPdf() {
    if (this.pdf) {
      this.pdf.destroy();
    }
  }

  private cancelRotation() {
    this.rotation = 0;
    this.rotating = false;
    this.fileRotation = null;
  }

  private checkRotation() {
    if (this.authService.isCaptureAdminUser) {
      this.fileRotationsService.getByTarget(this.pdfFile, this.pdfFileType).subscribe(ft => {
        this.updateRotation(ft);
      });
    }
  }

  private updateRotation(fileRotation: FileRotation) {
    if (fileRotation) {
      this.rotating =
        fileRotation.status === FileRotationStatus.initialized || fileRotation.status === FileRotationStatus.processing;

      if (!this.rotating) {
        this.isLoaded = false;
        this.pdfFile.url = fileRotation.targetUrl;
        this.fileRotation = null;
        this.rotation = 0;
      } else {
        this.fileRotation = fileRotation;
        clearTimeout(this.nextCheckTimeout);
        this.nextCheckTimeout = setTimeout(() => this.checkRotation(), 500);
      }
    }
  }
}
