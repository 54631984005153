import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { ClaimImport } from '../models/claim-import.model';
import { ClaimImportFilters } from '../models/user-settings/claim-import-settings.model';

@Injectable()
export class ClaimImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<ClaimImport> {
    return this.http.get<ClaimImport>(`${environment.captureApi.url}/claim_imports/${id}`);
  }

  getList(filters: ClaimImportFilters = null): Observable<{ claimImports: ClaimImport[] }> {
    const params = new URLSearchParams();

    if (filters && filters.selectedClientIds) {
      filters.selectedClientIds.forEach(clientId => params.append('client_ids[]', clientId));
    }

    return this.http.get<{ claimImports: ClaimImport[] }>(
      `${environment.captureApi.url}/claim_imports?${params.toString()}`
    );
  }

  create(clientId: number, thirdPartyAdministerName: string, signedId: string): Observable<ClaimImport> {
    return this.http.post<ClaimImport>(`${environment.captureApi.url}/claim_imports`, {
      claimImport: { clientId, thirdPartyAdministerName, name, signedId },
    });
  }

  cancel(id): Observable<ClaimImport> {
    return this.http.patch<ClaimImport>(`${environment.captureApi.url}/claim_imports/${id}/cancel`, {});
  }
}
