<app-capture-history-item-title>
  {{ displayStatus }}
</app-capture-history-item-title>

<app-capture-history-item-body>
  <p>
    {{ description }}
  </p>

  <p *ngIf="additionalDetails">
    Additional Details: {{ additionalDetails }}
  </p>

  <p *ngIf="verifiedDuringNoReferralPeriod">
    CE's policies and procedures supported a verification without a referral on file at the time of verification.
  </p>
</app-capture-history-item-body>
