import { Component, Input, OnInit } from '@angular/core';

class MinimalPatient {
  id: number;
  mrn: string;
  dob: Date;
  fullName: string;
  alternateNames: string[];
}

@Component({
  selector: 'app-patient-name',
  templateUrl: './patient-name.component.html',
  styleUrls: ['./patient-name.component.scss'],
})
export class PatientNameComponent implements OnInit {
  @Input() patient: MinimalPatient;
  @Input() sourcePatient: MinimalPatient;
  @Input() showAlternateNames = true;
  @Input() canViewPatient = false;

  visiblePatient: MinimalPatient;

  constructor() {}

  ngOnInit() {
    if (this.sourcePatient) {
      this.visiblePatient = this.sourcePatient;
    } else {
      this.visiblePatient = this.patient;
    }
  }
}
