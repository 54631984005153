import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InboundFax } from 'app/core/models/inbound-fax.model';

@Component({
  selector: 'app-inbound-fax-multiple-patients-warning',
  templateUrl: './inbound-fax-multiple-patients-warning.component.html',
  styleUrls: ['./inbound-fax-multiple-patients-warning.component.scss']
})
export class InboundFaxMultiplePatientsWarningComponent implements OnInit {

  @Input() inboundFax: InboundFax;
  @Output() multiplePatientsValidChange = new EventEmitter<boolean>();

  multiplePatientsValid: boolean | null  = null;

  constructor() { }

  ngOnInit(): void {
  }

  onMultiplePatientsValidChange() {
    this.multiplePatientsValidChange.emit(this.multiplePatientsValid);
  }

}
