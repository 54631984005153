<div class="row border py-3 rounded m-0">
  <div class="col-md-auto">
    <strong>MRN</strong>
    <div>{{ task.patient.mrn }}</div>
    <app-user-presence *ngIf="displayUserPresence" category="clientReferralTask" [targetId]="task.id" />
  </div>
  <div class="col-md-auto">
    <strong>Patient Name</strong>
    <div>{{ task.patient.fullName }}</div>
    <div *ngIf="task.patient.alternateNames.length > 0">
      <div class="alternate-names"><strong>ALTERNATE NAMES</strong></div>
      <div *ngFor='let alternateName of task.patient.alternateNames;'>
        {{ alternateName }}
      </div>
    </div>
  </div>
  <div class="col-md-auto">
    <strong>DOB</strong>
    <div>{{ task.patient.dob | date: 'MM/dd/yyyy' }}</div>
  </div>
  <div class="col-md-auto">
    <strong>Task Type</strong>
    <div>{{ task.humanizedType }}</div>
  </div>
  <div class="col-md-auto text-center">
    <strong>Prescriptions Associated</strong>
    <div>{{ task.prescriptionsCount | number }}</div>
  </div>
  <div class="col-md-auto">
    <strong>Value</strong>
    <div><app-task-estimated-value [rank]="task.estimatedValueRank" [value]="task.estimatedValue" /></div>
  </div>
  <div class="col-md-2">
    <app-client-referral-task-closed
      *ngIf="task.status === referralTaskStatuses.closed; else: taskStatus"
      [task]="task"
    />
  </div>
  <div class="col-md-auto">
    <div class="alert alert-danger my-3 p-1 text-center" *ngIf="task.flaggedForEsp">ESP</div>
  </div>
</div>
<ng-template #taskStatus>
  <strong>Status</strong>
  <div>{{ task.status | taskStatus }}</div>
</ng-template>
