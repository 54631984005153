import { Component, EventEmitter, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { ClientReferralMatchOption } from 'app/core/models/client-referral-match-option.model';
import { handleSimpleChanges } from 'app/core/lib/component-utils';
import { ReferralMatchVerificationsService } from 'app/core/services/referral-match-verifications.service';
import { ClientReferralVerificationsService } from 'app/core/services/client-referral-verifications.service';
import { PatientPrescriberService } from 'app/core/services/patient-prescriber.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { ReferralMatchVerificationsClientBaseComponent } from '../referral-match-verifications-client-base/referral-match-verifications-client-base.component';

@Component({
  selector: 'app-referral-match-verifications-client',
  templateUrl: './referral-match-verifications-client.component.html',
  styleUrls: ['./referral-match-verifications-client.component.scss'],
})
export class ReferralMatchVerificationsClientComponent
  extends ReferralMatchVerificationsClientBaseComponent
  implements OnInit, OnChanges {

  constructor(
    protected authService: AuthenticationService,
    protected clientReferralVerificationsService: ClientReferralVerificationsService,
    private referralMatchVerificationsService: ReferralMatchVerificationsService,
    private patientPrescriberService: PatientPrescriberService
  ) {
    super(authService, clientReferralVerificationsService);
  }

  ngOnInit() {
    this.fetchClientReferralVerifications();

    this.patientPrescriberChangedSubscription = this.patientPrescriberService.patientPrescriberChanged.subscribe(
      ({ patientPrescriber }) => {
        if (patientPrescriber.id === this.patientPrescriber.id) {
          this.patientPrescriber = patientPrescriber;

          this.fetchClientReferralVerifications();
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'capture' || inputName === 'verificationChangeInProgress') {
        this.refreshReferralMatchOptions();
      }
    });
  }

  onReferralVerificationChange(option: ClientReferralMatchOption) {
    const updatedVerification = { ...option.verification };
    const updatedOption = { ...option };

    if (option.selected) {
      updatedVerification.peerClientReferralVerification = true;
    }
    updatedOption.verification = updatedVerification;

    this.referralMatchVerificationsService.updateForOption(
      this.patientPrescriber.id,
      updatedOption,
      this.verificationChange
    ).subscribe();
  }
}
