import { Component, Input } from '@angular/core';
import { MatchingPrescriberDrug } from '../../../core/models/matching-prescriber-drug.model';

const initializeAkas = (mpd: MatchingPrescriberDrug) => {
  const drugGroupName = ((mpd.drugGroup && mpd.drugGroup.name) || '').toUpperCase();
  const drugGroupApproved = mpd.drugGroup && mpd.drugGroup.approved;

  const akas = [];

  if (drugGroupName) {
    akas.push(`${drugGroupName}${drugGroupApproved ? ' (Approved)' : ''}`);
  }

  if (mpd.relatedAkas) {
    mpd.relatedAkas.forEach(aka => {
      if (aka.toUpperCase() !== drugGroupName) {
        akas.push(aka);
      }
    });
  }

  return akas.sort();
};

@Component({
  selector: 'app-drug-akas',
  templateUrl: './drug-akas.component.html',
  styleUrls: ['./drug-akas.component.scss'],
})
export class DrugAkasComponent {
  _matchingPrescriberDrug: MatchingPrescriberDrug;
  akas = [];

  constructor() {}

  get matchingPrescriberDrug(): MatchingPrescriberDrug {
    return this._matchingPrescriberDrug;
  }

  @Input()
  set matchingPrescriberDrug(mpd: MatchingPrescriberDrug) {
    this._matchingPrescriberDrug = mpd;
    this.akas = initializeAkas(this.matchingPrescriberDrug);
  }
}
