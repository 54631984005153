import { Component } from '@angular/core';
import { SearchField } from '../../../core/models/search-field.model';
import { CaptureSearchResult } from '../../../core/models/capture-lists/capture-search-result.model';

@Component({
  selector: 'app-partner-portal-search',
  templateUrl: './partner-portal-search.component.html',
  styleUrls: ['./partner-portal-search.component.scss'],
})
export class PartnerPortalSearchComponent {
  captures: CaptureSearchResult[] = [];

  searchFields = [
    new SearchField('claimIdentifier', 'Claim ID'),
    new SearchField('prescriberNpi', 'Prescriber NPI'),
    new SearchField('rxReferenceNumber', 'Rx Reference Number'),
  ];

  onSearchComplete(captures: CaptureSearchResult[]) {
    this.captures = captures;
  }
}
