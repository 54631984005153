import { Pipe, PipeTransform } from '@angular/core';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';

@Pipe({
  name: 'patientAttachmentDisplayName',
})
export class PatientAttachmentDisplayNamePipe implements PipeTransform {
  transform(patientAttachment: PatientAttachment): string {
    if (patientAttachment.ehrConsultNoteReference) {
      return `${patientAttachment.ehrConsultNoteReference.name} (EHR Reference)`;
    } else if (patientAttachment.fileName) {
      return patientAttachment.fileName;
    } else {
      return '(NO NAME)';
    }
  }
}
