import { Component, Input, OnInit } from '@angular/core';
import { ClientReferralTask } from '../../../core/models/client-referral-task.model';
import { ClientReferralTaskStatus } from '../../../core/enums/client-referral-task-status.enum';

@Component({
  selector: 'app-client-referral-task-header',
  templateUrl: './client-referral-task-header.component.html',
  styleUrls: ['./client-referral-task-header.component.scss'],
})
export class ClientReferralTaskHeaderComponent implements OnInit {
  @Input() task: ClientReferralTask;
  @Input() displayUserPresence = true;

  referralTaskStatuses = ClientReferralTaskStatus;

  constructor() {}

  ngOnInit() {}
}
