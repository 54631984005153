<table class="lifecycle">
  <tr>
    <td>Started:</td>
    <td>
      {{ clientChore.startedAt | date: 'MM/dd/yyyy hh:mm a' }}
      <span>by {{ clientChore.submittedBy?.fullName }}</span>

      <div *ngIf="clientChore.initiatedVia === clientChoreInitiatedVia.clientConfigurationUpdate">
        <small>(via config update)</small>
      </div>
      <div *ngIf="clientChore.initiatedVia === clientChoreInitiatedVia.clientUpdate">
        <small>(via client update)</small>
      </div>
      <div *ngIf="clientChore.initiatedVia === clientChoreInitiatedVia.patientInteractionLocationUpdate">
        <small>(via client location update)</small>
      </div>
    </td>
  </tr>
  <tr *ngIf="clientChore.endedAt">
    <td>Ended:</td>
    <td>{{ clientChore.endedAt | date: 'MM/dd/yyyy hh:mm a' }}</td>
  </tr>
  <tr *ngIf="clientChore.reviewedAt">
    <td>Reviewed:</td>
    <td>
      {{ clientChore.reviewedAt | date: 'MM/dd/yyyy hh:mm a' }}
      <span>by {{ clientChore.reviewedBy?.fullName }}</span>
    </td>
  </tr>
  <tr *ngIf="clientChore.inactive" class="warning">
    <td></td>
    <td>Last update {{ clientChore.lastUpdatedInWords }}</td>
  </tr>

</table>
