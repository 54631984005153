import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ReferralMatchVerificationsStandardBaseComponent } from "../referral-match-verifications-standard-base/referral-match-verifications-standard-base.component";
import { AuthenticationService } from "app/core/services/authentication.service";
import { PatientService } from "app/core/services/patient.service";
import { ReferralMatchVerification } from "app/core/models/referral-match-verification.model";
import { ReferralMatchOption } from "app/core/models/referral-match-option.model";

@Component({
  selector: 'app-referral-match-verifications-standard-edit',
  templateUrl: './referral-match-verifications-standard-edit.component.html',
  styleUrls: ['./referral-match-verifications-standard-edit.component.scss'],
})
export class ReferralMatchVerificationsStandardEditComponent
  extends ReferralMatchVerificationsStandardBaseComponent
  implements OnInit {

  @Output() referralMatchOptionsChanged = new EventEmitter<ReferralMatchOption[]>();

  constructor(
    protected authService: AuthenticationService,
    protected patientService: PatientService,
  ) {
    super(authService, patientService);
  }

  onReferralMatchOptionChange(option: ReferralMatchOption) {
    this.updateReferralMatchSelection(option);
    this.referralMatchOptionsChanged.emit(this.referralMatchOptions);
  }

  protected isReferralMatchVerificationDisabledOrFormDisabled(allSelectedRmvs, resolvedVerification) {
    return false;
  }

  private updateReferralMatchSelection(option: ReferralMatchOption) {
    option.selected = option.verification.office || option.verification.specialty || option.verification.provider;
  }
}
