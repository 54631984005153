import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IccMedicaidCodeImportsService } from 'app/core/services/icc/icc-medicaid-code-imports.service';
import { ImportUploadResult } from '../../components/icc-import-upload/icc-import-upload.component';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-icc-import-medicaid-codes',
  templateUrl: './icc-import-medicaid-codes.component.html',
  styleUrls: ['./icc-import-medicaid-codes.component.scss'],
})
export class IccImportMedicaidCodesComponent {
  errors: string[] = [];

  constructor(private medicaidCodeImportsService: IccMedicaidCodeImportsService, private router: Router) {}

  onUploadComplete({ clientId, name, upload }: ImportUploadResult) {
    this.medicaidCodeImportsService.create(clientId, name, upload.signedId).subscribe(
      () => {
        this.router.navigate(['/capture-admin/icc-claims/imports/medicaid-codes/history']);
      },
      (error: HttpErrorResponse) => {
        this.errors = error.error.errors;
      }
    );
  }

  onUploadStart() {
    this.errors = [];
  }

  public get hasError() {
    return !isEmpty(this.errors);
  }
}
