import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PatientCapturesSearchFilter, PatientCapturesSearchSettings } from "../models/user-settings/patient-captures-search-settings.model";
import { CaptureQueueCursorType } from "../enums/capture-queue-cursor-type.enum";
import { Cursor, CursorBasedPagingMeta } from "../models/paged-results/cursor-based-paging.model";
import { PagedResults } from "../models/paged-results/paged-results.model";
import { CaptureQueueResult } from "../models/capture-lists/capture-queue-result.model";
import { PagedCaptureQueueResults } from "./capture-queue.service";
import { CaptureQueueFilterName } from "../enums/capture-queue-filter-name.enum";
import {
  appendFilterParam,
  appendFilterListParams,
  appendFilterFlagParam,
  buildCursorParams
} from "../lib/url-utils";

export class PagedCaptureResults
  implements PagedResults<CaptureQueueResult, CursorBasedPagingMeta<CaptureQueueCursorType>> {
  captureQueueResults: CaptureQueueResult[] = new Array<CaptureQueueResult>();
  meta: CursorBasedPagingMeta<CaptureQueueCursorType> = new CursorBasedPagingMeta<CaptureQueueCursorType>();

  get records() {
    return this.captureQueueResults;
  }
}

@Injectable()
export class PatientCapturesService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getCaptures(
    patientId: number,
    cursor: Cursor<CaptureQueueCursorType> = null,
    settings: PatientCapturesSearchSettings,
    limit: number = null
  ): Observable<PagedCaptureResults> {
    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);

    if (limit) {
      filterParams.append('limit', limit.toString());
    }

    let cursorParams: URLSearchParams = null;
    if (cursor) {
      cursorParams = buildCursorParams(cursor.name, cursor.id, cursor.value);
    } else {
      cursorParams = buildCursorParams(settings.cursorType);
    }

    return this.http.get<PagedCaptureQueueResults>(
      `${environment.captureApi.url}/patients/${patientId}/captures?${filterParams.toString()}&${cursorParams.toString()}`
    );
  }

  getCount(
    patientId: number,
    settings: PatientCapturesSearchSettings
  ): Observable<{ count: number }> {
    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);

    return this.http.get<{ count: number }>(
      `${environment.captureApi.url}/patients/${patientId}/captures/count?${filterParams.toString()}`
    );
  }

  private buildFilterParams(filters: PatientCapturesSearchFilter): URLSearchParams {
    const urlSearchParams = new URLSearchParams();

    appendFilterListParams(CaptureQueueFilterName.statuses, filters.statuses, urlSearchParams);
    appendFilterListParams(CaptureQueueFilterName.reasons, filters.reasons, urlSearchParams);
    appendFilterParam(CaptureQueueFilterName.prescriberNpi, filters.prescriberNpi, urlSearchParams);
    appendFilterParam(CaptureQueueFilterName.prescriberLastName, filters.prescriberLastName, urlSearchParams);
    appendFilterParam(CaptureQueueFilterName.prescriberFirstName, filters.prescriberFirstName, urlSearchParams);
    appendFilterFlagParam(CaptureQueueFilterName.highValue, filters.highValue, urlSearchParams);

    return urlSearchParams;
  }
}