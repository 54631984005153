import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { EhrReferralVerification } from 'app/core/models/ehr-referral-verification.model';
import { EhrReferralMatchOption } from 'app/core/models/ehr-referral-match-option.model';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { ReferralMatchVerificationsEhrBaseComponent } from '../referral-match-verifications-ehr-base/referral-match-verifications-ehr-base.component';
import { ReferralMatchVerificationEvent, ReferralMatchVerificationEventType } from 'app/core/models/referral-match-verification-event.model';

@Component({
  selector: 'app-referral-match-verifications-ehr',
  templateUrl: '../referral-match-verifications-ehr-base/referral-match-verifications-ehr-base.component.html',
  styleUrls: ['../referral-match-verifications-ehr-base/referral-match-verifications-ehr-base.component.scss'],
})
export class ReferralMatchVerificationsEhrComponent
  extends ReferralMatchVerificationsEhrBaseComponent
  implements OnInit {

  @Output() verificationChange = new EventEmitter<ReferralMatchVerificationEvent>();

  ngOnInit() {
    super.ngOnInit();
  }

  protected handleRmvSelectionChange(option: EhrReferralMatchOption) {
    this.updateReferralMatchVerificationForOption(option);
  }

  protected handleNewEhrReferralVerification(newVerification: EhrReferralVerification) {
    const newRmv = new ReferralMatchVerification();
    newRmv.ehrReferralVerification = newVerification;

    this.createOrUpdateReferralMatchVerification(newRmv);
  }

  private updateReferralMatchVerificationForOption(option: EhrReferralMatchOption) {
    const updatedVerification = { ...option.verification };
    const updatedOption = { ...option };

    if (option.selected) {
      updatedVerification.ehrReferralVerification = option.ehrReferralVerification;
    }
    updatedOption.verification = updatedVerification;

    this.referralMatchVerificationsService.updateForOption(
      this.patientPrescriber.id,
      updatedOption,
      this.verificationChange
    ).subscribe();
  }

  private createOrUpdateReferralMatchVerification(referralMatchVerification: ReferralMatchVerification) {
    const initEvent = new ReferralMatchVerificationEvent(ReferralMatchVerificationEventType.changeInitiated);
    this.verificationChange.emit(initEvent);

    this.referralMatchVerificationsService.
      createOrUpdate(this.patientPrescriber.id, referralMatchVerification).
      subscribe((rmv: ReferralMatchVerification) => {
        const changeEvent =
          new ReferralMatchVerificationEvent(ReferralMatchVerificationEventType.changed, rmv);

        this.verificationChange.emit(changeEvent);
      });
  }
}
