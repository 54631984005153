<div *ngIf="!refreshing; else: spinner" class="capture-detail">
  <ng-container [ngSwitch]="capture.status">

    <ng-container *ngSwitchCase="captureStatuses.verifiedClientPrescription">
      <div class="capture-fixed mb-1">
        <app-capture-header [capture]="capture" />
        <div class="mt-1 capture-nav">
          <app-capture-nav
            [capture]="capture"
            [currentSection]="currentSection"
            (sectionClick)="onSectionClick($event)"
          />
        </div>
      </div>
      <div
        id="captureScrollable"
        class="capture-scrollable"
        appScrollSpy
        [spiedTags]="['SECTION']"
        (sectionChange)="onSectionScroll($event)"
      >
        <section *ngIf="showValidateReferralSection" id="validateReferral">
          <app-validate-referral
            [capture]="capture"
            [patientPrescriber]="patientPrescriber" />
        </section>
        <section id="ceReviewDetails"><app-ce-review-details [capture]="capture" /></section>
        <section id="patientPrescriptions">
          <app-patient-prescriptions [capture]="capture" />
        </section>
        <section id="clientPrescriptionMatches"
                 *ngIf="showClientPrescriptionMatches">
          <app-client-prescription-matches [capture]="capture" />
        </section>
        <section id="validatePatientCareOwnership">
          <app-validate-patient-care-ownership [capture]="capture" />
        </section>
        <section id="captureVerification">
          <app-capture-verification
            [capture]="capture"
            [patientPrescriber]="patientPrescriber"
          />
        </section>
        <section id="captureUserAudit" *ngIf="capture.userAuditable || capture.userAuditOutcome">
          <app-capture-user-audit [capture]="capture" />
        </section>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="captureStatuses.needsPatientMatchApproval">
      <div class="mb-3"><app-capture-header [capture]="capture" /></div>
      <div class="ps-3">
        <section><app-needs-patient-match-approval [capture]="capture" /></section>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="captureStatuses.needsReferralMatchApproval">
      <div class="mb-3"><app-capture-header [capture]="capture" /></div>
      <div>
        <section>
          <app-needs-referral-match-approval
            [capture]="capture"
            [patientPrescriber]="patientPrescriber"
          />
        </section>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="captureStatuses.verifiedClientHasConsultNote">
      <div class="capture-fixed mb-1">
        <app-capture-header [capture]="capture" />
        <div class="mt-1 capture-nav">
          <app-capture-nav
            [capture]="capture"
            [currentSection]="currentSection"
            (sectionClick)="onSectionClick($event)"
          />
        </div>
      </div>
      <div
        id="captureScrollable"
        class="capture-scrollable"
        appScrollSpy
        [spiedTags]="['SECTION']"
        (sectionChange)="onSectionScroll($event)"
      >
        <section id="validateReferral">
          <app-validate-referral
            [capture]="capture"
            [patientPrescriber]="patientPrescriber" />
        </section>
        <section id="ceReviewDetails"><app-ce-review-details [capture]="capture" /></section>
        <section id="patientPrescriptions">
          <app-patient-prescriptions [capture]="capture" />
        </section>
        <section id="consultNoteVerification">
          <app-capture-consult-note-verification [capture]="capture" />
        </section>
        <section id="clientPrescriptionMatches"
                *ngIf="showClientPrescriptionMatches">
            <app-client-prescription-matches [capture]="capture" />
        </section>
        <section id="validatePatientCareOwnership">
          <app-validate-patient-care-ownership [capture]="capture" />
        </section>
        <section id="captureVerification">
          <app-capture-verification
            [capture]="capture"
            [patientPrescriber]="patientPrescriber"
          />
        </section>
          <section id="captureUserAudit" *ngIf="capture.userAuditable || capture.userAuditOutcome">
          <app-capture-user-audit [capture]="capture" />
        </section>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="capture-fixed mb-1">
        <app-capture-header [capture]="capture" />
        <div class="mt-1 capture-nav">
          <app-capture-nav
            [capture]="capture"
            [currentSection]="currentSection"
            (sectionClick)="onSectionClick($event)"
          />
        </div>
      </div>
      <div
        id="captureScrollable"
        class="capture-scrollable"
        appScrollSpy
        [spiedTags]="['SECTION']"
        (sectionChange)="onSectionScroll($event)"
      >
        <section *ngIf="showValidateReferralSection" id="validateReferral">
          <app-validate-referral
            [capture]="capture"
            [patientPrescriber]="patientPrescriber" />
        </section>
        <section id="ceReviewDetails"><app-ce-review-details [capture]="capture" /></section>
        <section id="patientPrescriptions">
          <app-patient-prescriptions [capture]="capture" />
        </section>
        <section *ngIf="showPatientDocumentsSection" id="patientDocuments">
          <app-patient-documents
            [capture]="capture"
            [patientPrescriber]="patientPrescriber"
            [selectedAttachment]="selectedAttachment"
            (attachmentSelectionChange)="onAttachmentSelectionChange($event)"
          />
        </section>
        <section id="clientPrescriptionMatches"
                 *ngIf="showClientPrescriptionMatches">
          <app-client-prescription-matches [capture]="capture" />
        </section>
        <section id="validatePatientCareOwnership">
          <app-validate-patient-care-ownership [capture]="capture" />
        </section>
        <section *ngIf="showValidatePrescriptionWrittenAtCe" id="validatePrescriptionWrittenAtCe">
          <app-validate-prescription-written-at-ce [capture]="capture" />
        </section>
        <section id="captureVerification">
          <app-capture-verification
            [capture]="capture"
            [patientPrescriber]="patientPrescriber"
            [selectedAttachment]="selectedAttachment"
          />
        </section>
        <section id="captureUserAudit" *ngIf="capture.userAuditable || capture.userAuditOutcome">
          <app-capture-user-audit [capture]="capture" />
        </section>
      </div>
    </ng-container>
  </ng-container>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
