import { UserQueueSettings } from '../../interfaces/user-queue-settings';
import { LimitOffsetPaging, Sort } from '../paged-results/limit-offset-paging.model';

class ProviderMechanicalTurkReviewFilters { }

export class ProviderMechanicalTurkReviewListSettings {
  filters: ProviderMechanicalTurkReviewFilters;
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  sort: Sort;
}

export class ProviderMechanicalTurkReviewQueueSettings
  extends ProviderMechanicalTurkReviewListSettings implements UserQueueSettings {

  position: number;
}
