import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Base64 } from 'js-base64';
import { forEach } from 'lodash-es';
import { ConsultNoteQueueCursorType } from 'app/core/enums/consult-note-queue-cursor-type.enum';
import { ConsultNoteQueueResult } from 'app/core/models/consult-note-lists/consult-note-queue-result.model';
import { Cursor } from 'app/core/models/paged-results/cursor-based-paging.model';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { ConsultNoteQueueService } from 'app/core/services/consult-note-queue.service';
import { NavigationService, PageChangeEvent } from 'app/core/services/navigation.service';
import { UserPresenceService } from 'app/core/services/user-presence.service';
import { UserTimingService } from 'app/core/services/user-timing.service';
import { Subject } from 'rxjs';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';

@Directive()
export abstract class BaseConsultNotesComponent implements OnInit, OnDestroy {
  getResults$: Subject<void> = new Subject();
  loadingResults = false;
  hasMoreResults = false;
  limit = 10;
  cursor: Cursor<ConsultNoteQueueCursorType>;
  consultNoteQueueResults: ConsultNoteQueueResult[] = [];
  selectedConsultNoteIds: number[] = [];

  constructor(
    protected consultNoteQueueService: ConsultNoteQueueService,
    protected navigationService: NavigationService,
    protected userPresenceService: UserPresenceService,
    protected userTimingService: UserTimingService,
    protected authenticationService: AuthenticationService,
    protected patientAttachmentsService: PatientAttachmentsService,
  ) {
    this.navigationService.pageChanged.subscribe((pageChangeEvent: PageChangeEvent) => {
      if (pageChangeEvent.navigatedBack) {
        this.initCursorFromParams();
        this.getResults$.next();
      }
    });
  }

  ngOnInit() {
    this.init();

    // this ensures we call ngOnDestroy when the page
    // is reloaded
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  onSettingsChange() {
    this.startTimeTracking();
    this.resetResults();
  }

  onNextClick($event) {
    $event.preventDefault();
    this.updateQueryParams();
    this.getResults$.next();
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.unselectAllSelectedConsultNotes();
    this.userTimingService.stop();
    this.userPresenceService.unsubscribeAll();
  }

  protected unselectAllSelectedConsultNotes() {
    this.selectedConsultNoteIds.forEach(id => this.consultNoteHasBeenUnselected(id));
  }

  public handleSelectionChange(consultNote: ConsultNoteQueueResult) {
    this.patientAttachmentsService.get(consultNote.id).subscribe(patientAttachment => {
      const consultNoteIds = this.consultNoteAndRelatedConsultNoteIds(patientAttachment);
      consultNoteIds.forEach(id => {
        this.selectOrUnselectConsultNote(id);
      });
    });
  }

  // sub-class can overwrite if they need to do something
  // after a consult note has been selected or unselected
  protected consultNoteHasBeenSelected(id) {}
  protected consultNoteHasBeenUnselected(id) {}


  private selectOrUnselectConsultNote(id) {
    const index = this.selectedConsultNoteIds.indexOf(id);
    if (index >= 0) {
      this.selectedConsultNoteIds.splice(index, 1);
      this.consultNoteHasBeenUnselected(id);
    } else {
      this.selectedConsultNoteIds.push(id);
      this.consultNoteHasBeenSelected(id);
    }
  }

  public get canAssignUser(): boolean {
    return this.authenticationService.hasPermissionTo(UserRoleName.consultNoteUser);
  }

  public handleAssignUser(userId: number) {
    this.consultNoteQueueService.assignUser(userId, this.selectedConsultNoteIds).subscribe((result) => {
      if (result.status === 'ok') {
        this.navigationService.reloadCurrentUrl();
      }
    })
  }

  public get hasConsultNoteSelection(): boolean {
    return this.selectedConsultNoteIds.length > 0;
  }

  public get consultNoteSelectionCount(): number {
    return this.selectedConsultNoteIds.length;
  }

  public handleClearSelection() {
    this.selectedConsultNoteIds = [];
  }

  protected init() {
    this.consultNoteQueueResults = [];

    this.configureGetResults();
    this.initCursorFromParams();
    this.getResults$.next();
    this.startTimeTracking();
  }

  protected updateQueryParams() {
    if (this.cursor) {
      const encodedCursor = encodeURIComponent(Base64.encode(JSON.stringify(this.cursor)));
      this.navigationService.updateQueryParams({ cursor: encodedCursor });
    } else {
      this.navigationService.updateQueryParams({}, true);
    }
  }

  protected initCursorFromParams() {
    const queryParams = this.navigationService.queryParams;

    if ('cursor' in queryParams) {
      this.cursor = this.decodeCursor(queryParams);
    } else {
      this.cursor = null;
    }
  }

  protected abstract configureGetResults();

  protected decodeCursor(queryParams) {
    try {
      const cursorParam = decodeURIComponent(queryParams['cursor']);
      return JSON.parse(Base64.decode(cursorParam));
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  protected startTimeTracking() {
    const data: any = { page: 'consult_note_queue' };
    data.statuses = this.consultNoteQueueService.getUserSettings().filters.statuses;

    this.userTimingService.track(data);
  }

  protected subscribeToUserPresence() {
    forEach(this.consultNoteQueueResults, (consultNote: ConsultNoteQueueResult) => {
      this.userPresenceService.subscribe("consultNote", consultNote.id)
    })
  }

  protected resetResults() {
    this.selectedConsultNoteIds = [];
    this.cursor = null;
    this.hasMoreResults = false;
    this.updateQueryParams();
    this.getResults$.next();
  }

  private consultNoteAndRelatedConsultNoteIds(patientAttachment): number[] {
    const ids = [patientAttachment.id];
    if (patientAttachment.relatedPatientAttachments) {
      patientAttachment.relatedPatientAttachments.forEach((related) => {
        ids.push(related.id);
      });
    }
    return ids;
  }
}
