import { Component, Input } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';

@Component({
  selector: 'app-inbound-fax-info',
  templateUrl: './inbound-fax-info.component.html'
})
export class InboundFaxInfoComponent {
  @Input() inboundFax: InboundFax;

  constructor() {}
}
