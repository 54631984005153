/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';

@Component({
  selector: '[app-consult-note-task-list-row]',
  templateUrl: './consult-note-task-list-row.component.html',
  styleUrls: ['./consult-note-task-list-row.component.scss'],
})
export class ConsultNoteTaskListRowComponent implements OnInit {
  @Input() task: ClientConsultNoteTask;
  @Input() position: number;

  @Output() viewTask = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  onViewClick() {
    this.viewTask.emit(this.position);
  }
}
