import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';
import { AppVersion } from '../lib/app-version';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { UserSessionKey } from '../constants';
import { UserSession } from '../models/user-session.model';

const onBeforeSend = (event: Sentry.ErrorEvent, hint?: any): Sentry.ErrorEvent => {
  if (event.message && event.message.startsWith('Non-Error exception captured') && hint.originalException.error) {
    Sentry.withScope(scope => {
      scope.setExtra('nonErrorException', true);
      Sentry.captureException(hint.originalException.error);
    });
    return null;
  }
  return event;
};

@Injectable()
export class SentryService {
  constructor(private localStorageService: LocalStorageService) {
    Sentry.init({
      dsn: environment.sentryDsn,
      release: AppVersion.humanize(),
      beforeSend: onBeforeSend,
    });
  }

  public logException(err: any) {
    console.error(err);
    {
      const scope = Sentry.getCurrentScope();
      scope.setUser({ username: this.currentUserName });
      Sentry.captureException(err);
    }
  }

  public logMessage(msg: string, sev: Sentry.SeverityLevel = "log") {
    console.log(msg);
    {
      const scope = Sentry.getCurrentScope();
      scope.setUser({ username: this.currentUserName });
      Sentry.captureMessage(msg, sev);
    }
  }

  private get currentUserName(): string {
    const userSession: UserSession = this.localStorageService.get(UserSessionKey);

    if (userSession) {
      return userSession.username;
    } else {
      return 'unknown-user';
    }
  }
}
