import { Component } from '@angular/core';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';


@Component({
  selector: 'app-capture-history-item-type-claim-status-updated',
  templateUrl: '../capture-history-item-type-base/capture-history-item-type-base.component.html',
})
export class CaptureHistoryItemTypeClaimStatusUpdatedComponent extends CaptureHistoryItemTypeBaseComponent {
  get title() {
    return "Claim Partner Update";
  }

  get description() {
    return this.captureHistoryItem.data?.claimPartnerStatus;
  }
}
