import { LimitOffsetPaging } from '../paged-results/limit-offset-paging.model';

export class ClientConsultNoteFilters {
  selectedClientIds: string[] = [];
  patientFirstName: string = null;
  patientLastName: string = null;
  patientDob: string = null;
}

export class ClientConsultNoteListSettings {
  filters: ClientConsultNoteFilters = new ClientConsultNoteFilters();
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
}