import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { EhrConsultNoteReference } from '../models/ehr-consult-note-reference.model';

export class EhrConsultNoteReferenceParams {
  public id?: number;
  public name: string;
  public notes: string;
}

@Injectable()
export class EhrConsultNoteReferencesService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  update(
    id: number,
    ehrConsultNoteReferenceParams: EhrConsultNoteReferenceParams
  ): Observable<EhrConsultNoteReference> {
    return this.http.patch<EhrConsultNoteReference>(
      `${environment.captureApi.url}/ehr_consult_note_references/${id}`,
      { ehrConsultNoteReference: ehrConsultNoteReferenceParams }
    );
  }
}
