import { Component, OnDestroy, OnInit } from '@angular/core';
import { Base64 } from 'js-base64';
import { forEach } from 'lodash-es';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CaptureQueueService, CaptureQueueType, PagedCaptureQueueResults } from '../../../core/services/capture-queue.service';
import { CaptureQueueCursorType } from '../../../core/enums/capture-queue-cursor-type.enum';
import { Cursor } from '../../../core/models/paged-results/cursor-based-paging.model';
import { CaptureQueueResult } from '../../../core/models/capture-lists/capture-queue-result.model';
import { WebsocketChannelHandle } from '../../../core/models/websocket-channel-handle.model';
import { NavigationService, PageChangeEvent } from '../../../core/services/navigation.service';
import { UserTimingService } from '../../../core/services/user-timing.service';
import { WebsocketService } from '../../../core/services/websocket.service';
import { UserPresenceService } from 'app/core/services/user-presence.service';

@Component({
  selector: 'app-capture-admin-capture-queue',
  templateUrl: './capture-admin-capture-queue.component.html',
  styleUrls: ['./capture-admin-capture-queue.component.scss'],
})
export class CaptureAdminCaptureQueueComponent implements OnInit, OnDestroy {
  getResults$: Subject<void> = new Subject();
  pageLoaded = false;
  loadingResults = false;
  hasMoreResults = false;
  limit = 10;
  advancementQueueCount: number = null;
  advancementQueueCountChannelHandle: WebsocketChannelHandle;
  captureQueueResults: CaptureQueueResult[] = [];
  cursor: Cursor<CaptureQueueCursorType>;

  constructor(
    private captureQueueService: CaptureQueueService,
    private navigationService: NavigationService,
    private userPresenceService: UserPresenceService,
    private userTimingService: UserTimingService,
    private websocketService: WebsocketService
  ) {
    this.navigationService.pageChanged.subscribe((pageChangeEvent: PageChangeEvent) => {
      if (pageChangeEvent.navigatedBack && this.pageLoaded) {
        this.initCursorFromParams();
        this.getResults$.next();
      }
    });
  }

  ngOnInit() {
    this.captureQueueService.queueType = CaptureQueueType.work;
    this.configureGetResults();
    this.initCursorFromParams();
    this.getResults$.next();
    this.startTimeTracking();
    this.subscribeToAdvancementQueueCount();
    this.pageLoaded = true;
  }

  onSettingsChange() {
    this.startTimeTracking();
    this.cursor = null;
    this.hasMoreResults = false;
    this.updateQueryParams();
    this.getResults$.next();
  }

  onNextClick($event) {
    $event.preventDefault();
    this.updateQueryParams();
    this.getResults$.next();
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.userTimingService.stop();
    this.advancementQueueCountChannelHandle.unsubscribe();
    this.userPresenceService.unsubscribeAll();
  }

  private updateQueryParams() {
    if (this.cursor) {
      const encodedCursor = encodeURIComponent(Base64.encode(JSON.stringify(this.cursor)));
      this.navigationService.updateQueryParams({ cursor: encodedCursor });
    } else {
      this.navigationService.updateQueryParams({}, true);
    }
  }

  private initCursorFromParams() {
    const queryParams = this.navigationService.queryParams;

    if ('cursor' in queryParams) {
      this.cursor = this.decodeCursor(queryParams);
    } else {
      this.cursor = null;
    }
  }

  private configureGetResults() {
    // https://www.credera.com/blog/technology-solutions/using-rxjs-switchmap-angular-7-reactive-forms-cancel-pending-requests/
    this.getResults$
      .pipe(
        tap(() => {
          this.loadingResults = true;
          this.captureQueueResults = [];
          this.userPresenceService.unsubscribeAll();
        }),
        switchMap(() => this.captureQueueService.getResults(this.cursor))
      )
      .subscribe((results: PagedCaptureQueueResults) => {
        this.captureQueueResults = results.captureQueueResults;
        this.cursor = results.meta.cursor;
        this.loadingResults = false;
        this.hasMoreResults = !!results.meta.cursor;
        this.subscribeToUserPresence();
      });
  }

  private decodeCursor(queryParams) {
    try {
      const cursorParam = decodeURIComponent(queryParams['cursor']);
      return JSON.parse(Base64.decode(cursorParam));
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private startTimeTracking() {
    const data: any = { page: 'capture_queue' };
    data.statuses = this.captureQueueService.getUserSettings().filters.statuses;

    this.userTimingService.track(data);
  }

  private subscribeToAdvancementQueueCount() {
    this.advancementQueueCountChannelHandle = this.websocketService.channelHandle(
      'CaptureAdvancementQueueCountChannel'
    );

    this.advancementQueueCountChannelHandle.messageReceived.subscribe(message => {
      this.advancementQueueCount = message.count;
    });
  }

  private subscribeToUserPresence() {
    forEach(this.captureQueueResults, (capture: CaptureQueueResult) => {
      this.userPresenceService.subscribe('capture', capture.id)
    })
  }
}
