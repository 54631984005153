<app-reports
  title="Patient Interaction Location Reports"
  [filtersForm]="filtersForm"
  [reportsUrl]="reportsUrl">

  <ng-template appNewReportOptionsTemplate let-filtersForm="filtersForm">
    <ng-container [formGroup]="filtersForm">
      <app-report-location-eligibility-status-option
        formControlName="eligibilityStatuses" />

      <app-report-client-option
        formControlName="clientIds"
        [clientFilters]="{ applicableToReferralClaims: true }" />

      <app-report-text-option
        formControlName="name"
        type="text"
        label="Location" />

      <app-report-client-entity-type-option
        formControlName="clientEntityTypes" />

      <app-report-client-lead-option
        formControlName="assignedClientLeadsIds" />
    </ng-container>
  </ng-template>

  <ng-template appReportDetailsTemplate let-report="report">

    <app-report-applied-location-eligibility-status-option
      [options]="report.filters" />

    <app-report-applied-client-option
      [options]="report.filters"
      [optionAssociations]="report.filterAssociations" />

    <app-report-applied-text-option
      [options]="report.filters"
      name="name"
      label="Location" />

    <app-report-applied-client-entity-type-option
      [options]="report.filters" />

    <app-report-applied-client-lead-option
      [options]="report.filters"
      [optionAssociations]="report.filterAssociations" />

  </ng-template>
</app-reports>