import { ConsultNoteQueueCursorType } from '../../enums/consult-note-queue-cursor-type.enum';
import { PatientAttachmentStatus } from '../../enums/patient-attachment-status.enum';

export class ConsultNoteQueueFilters {
  constructor(
    public statuses: PatientAttachmentStatus[] = [],
    public clientIds: number[] = [],
    public clientStates: string[] = [],
    public specialtyStore: boolean = false,
    public highValue: boolean = false,
    public expiringWithinDays: string | null = null,
    public outboundFaxNumber: string = '',
    public assignedClientLeadIds: number[] = [],
    public assignedToIds: number[] = [],
  ) {}
}

export class ConsultNoteQueueSettings {
  constructor(
    public filters: ConsultNoteQueueFilters = new ConsultNoteQueueFilters(),
    public cursorType: ConsultNoteQueueCursorType = ConsultNoteQueueCursorType.highestPriority
  ) {}
}
