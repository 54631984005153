import { Component, Input, OnInit } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-validate-drug-details',
  templateUrl: './validate-drug-details.component.html',
  styleUrls: ['./validate-drug-details.component.scss'],
})
export class ValidateDrugDetailsComponent implements OnInit {
  constructor() {}

  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;

  ngOnInit() {}
}
