import { Component } from '@angular/core';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';

@Component({
  selector: 'app-capture-history-item-type-consult-note-submitted',
  templateUrl: './capture-history-item-type-consult-note-submitted.component.html',
})
export class CaptureHistoryItemTypeConsultNoteSubmittedComponent extends CaptureHistoryItemTypeBaseComponent {
  get title() {
    return 'Consult Note Submitted';
  }

  get consultNoteId() {
    return this.captureHistoryItem.relatedRecord.id;
  }
}
