import { Component, OnDestroy, OnInit } from '@angular/core';
import { forEach } from 'lodash-es';
import { InboundFax } from 'app/core/models/inbound-fax.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { InboundFaxesService, PagedInboundFaxes } from 'app/core/services/inbound-faxes.service';
import { UserPresenceService } from 'app/core/services/user-presence.service';
import { DownloadService } from 'app/core/services/download.service';
import { FaxQueueSettings } from 'app/core/models/user-settings/fax-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { UserTimingService } from 'app/core/services/user-timing.service';

@Component({
  selector: 'app-capture-admin-my-inbound-faxes',
  templateUrl: './capture-admin-my-inbound-faxes.component.html',
  styleUrls: ['./capture-admin-my-inbound-faxes.component.scss']
})
export class CaptureAdminMyInboundFaxesComponent implements OnInit, OnDestroy {
  inboundFaxes: InboundFax[] = [];
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  loading = true;

  constructor(
    private downloadService: DownloadService,
    private inboundFaxesService: InboundFaxesService,
    private userPresenceService: UserPresenceService,
    private userSettingsService: UserSettingsService,
    private userTimingService: UserTimingService,
  ) { }

  ngOnInit(): void {
    this.startTimeTracking();
    this.getFaxes(this.paging.currentPage);
  }

  ngOnDestroy(): void {
    this.userTimingService.stop();
    this.userPresenceService.unsubscribeAll();
  }

  onPageChange(page: number): void {
    this.getFaxes(page);
    this.saveQueueSettings();
  }

  onPageSizeChange(size: number): void {
    this.paging.pageSize = size;
    this.getFaxes(this.paging.currentPage);
    this.saveQueueSettings();
  }

  download($event: Event, inboundFax: InboundFax): void {
    $event.preventDefault();
    this.downloadService.download(inboundFax.url);
  }

  onViewClick(position: number): void {
    this.saveQueueSettings(position);
  }

  private getFaxes(page: number): void {
    this.loading = true;
    this.userPresenceService.unsubscribeAll();
    this.inboundFaxesService
      .getMyQueue(page, this.paging.pageSize)
      .subscribe((result: PagedInboundFaxes) => {
        this.inboundFaxes = result.inboundFaxes;
        this.paging = result.meta.paging;
        this.loading = false;
        this.subscribeToUserPresence();
      });
  }

  private saveQueueSettings(position = null): void {
    const queueSettings = new FaxQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    this.userSettingsService.save<FaxQueueSettings>(UserSettings.captureAdminMyFaxQueueSettings, queueSettings);
  }

  private subscribeToUserPresence(): void {
    forEach(this.inboundFaxes, (inboundFax: InboundFax) => {
      this.userPresenceService.subscribe("fax", inboundFax.id)
    })
  }

  private startTimeTracking() {
    const data: any = { page: 'my_faxes' };
    this.userTimingService.track(data);
  }

}
