<span [ngClass]="{ 'hidden-value': !visible }">
  <span class="value">
    {{ value || valueLoading }}
  </span>
  <a href="javascript:;" (click)="toggleVisibility()">
    <mat-icon class="material-icons-round">visibility_off</mat-icon>
  </a>
</span>
<div [hidden]="visible">
  <span>
    {{ hiddenTextString }}
  </span>

  <a href="javascript:;" (click)="toggleVisibility()">
    <mat-icon class="material-icons-round">visibility</mat-icon>
  </a>
</div>
