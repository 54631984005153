import { Component, Input } from '@angular/core';
import { OutboundFax } from '../../../core/models/outbound-fax.model';
import { keyedReasonOptions } from '../../../core/options/inbound-call-reasons.opts';

@Component({
  selector: 'app-inbound-call-received',
  templateUrl: './inbound-call-received.component.html',
})
export class InboundCallReceivedComponent {
  @Input() outboundFax: OutboundFax;

  keyedInboundCallReasonOptions = keyedReasonOptions;

  constructor() {}
}
