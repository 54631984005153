import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CaptureTransitionReasonOption, keyedReasonOptions } from '../../../core/options/capture-status-reason.opts';
import { Capture } from '../../../core/models/capture.model';
import { VerificationOutcome } from '../../../core/models/verification-outcome.model';
import { PatientPrescriberNote } from 'app/core/models/patient-prescriber-note.model';

@Component({
  selector: 'app-verification-outcome-status',
  templateUrl: './verification-outcome-status.component.html',
  styleUrls: ['./verification-outcome-status.component.scss'],
})
export class VerificationOutcomeStatusComponent implements OnInit, OnChanges {
  @Input() capture: Capture;
  @Input() patientPrescriberNote: PatientPrescriberNote;

  verificationOutcome: VerificationOutcome;
  reason: CaptureTransitionReasonOption;

  constructor() {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  private init() {
    this.verificationOutcome = this.capture.verificationOutcome;
    this.reason = keyedReasonOptions[this.verificationOutcome.statusReason];
  }
}
