<div *ngIf="!loadingCapture; else: spinner" class="capture-detail">
  <div class="capture-fixed mb-1"><app-capture-header [capture]="capture" /></div>
  <div class="content">
    <div class="card mt-1">
      <div class="card-body">
        <h4>Send Fax</h4>
        <app-send-fax [capture]="capture" (faxSent)="onFaxSent()" />
      </div>
    </div>
    <div class="card mt-1">
      <div class="card-body">
        <h4>Outbound Faxes</h4>
        <app-patient-outbound-faxes #patientOutboundFaxes [patient]="capture.patient" />
      </div>
    </div>
    <div class="card mt-1">
      <div class="card-body">
        <h4>Outbound Call Log</h4>
        <app-npi-call-logs [npi]="capture.prescriber.npi" />
      </div>
    </div>
    <app-patient-prescriptions [capture]="capture" [fixedHeader]="false" />
  </div>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
