import { Component, Input, OnInit } from '@angular/core';
import { Office } from 'app/core/models/office.model';
import { OfficeService } from 'app/core/services/office.service';
import { ProviderOffice } from 'app/core/models/provider-office.model';

@Component({
  selector: 'app-office-detail',
  templateUrl: './office-detail.component.html',
  styleUrls: ['./office-detail.component.scss'],
})
export class OfficeDetailComponent implements OnInit {
  @Input() office: Office;

  showAddProviders = false;

  providerOffices: ProviderOffice[];
  providerOfficesLoading = true;

  constructor(
    private officeService: OfficeService,
  ) { }

  ngOnInit() {
    this.loadProviderOffices();
  }

  onAddNewProvidersClick() {
    this.showAddProviders = true;
  }

  onProviderFormChanged() {
    this.loadProviderOffices();
  }

  onProviderFormCompleted() {
    this.showAddProviders = false;
  }

  private loadProviderOffices() {
    this.officeService.getProviderOffices(this.office.id).subscribe((providerOffices: ProviderOffice[]) => {
      this.providerOffices = providerOffices;
      this.providerOfficesLoading = false;
    })
  }
}
