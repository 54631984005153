/* eslint-disable @angular-eslint/component-selector */

import { Component, Input, OnInit } from '@angular/core';
import { Audit } from 'app/core/models/audit.model';
import { uniq } from 'lodash-es';
import { camelCaseToFlat, camelToSnakeCase } from 'app/core/lib/string-utils';

@Component({
  selector: 'app-audit-office-action-meta',
  templateUrl: './audit-office-action-meta.component.html',
  styleUrls: ['./audit-office-action-meta.component.scss'],
})
// This component attempts to create InboundFax / InboundCall
// links from the reviewInboundCommunication and communicationMethodSource
// fields in the changes.
//
// It will only display unique links, so if the same Inbound Call showed
// up in reviewInboundCommunication and communicationMethodSource it
// would only be displayed one.
//
// The link is only displayed if the type and id are present, if a change
// happens and only id or type is change then a link will not be created.
export class AuditOfficeActionMetaComponent implements OnInit{
  @Input() audit: Audit;

  referenceableTypeIdKeys: string[] = [];

  ngOnInit() {
    this.buildReferenceableTypeIdKeys();
    this.referenceableTypeIdKeys = uniq(this.referenceableTypeIdKeys);

    if (this.audit.action === 'update') {
      console.log(this.referenceableTypeIdKeys);
    }
  }

  private buildReferenceableTypeIdKeys() {
    const {
      reviewInboundCommunicationId,
      reviewInboundCommunicationType,
      communicationMethodSourceId,
      communicationMethodSourceType
    } = this.audit.auditedChanges;

    this.buildReferenceableKeyFromIdAndType(reviewInboundCommunicationId, reviewInboundCommunicationType);
    this.buildReferenceableKeyFromIdAndType(communicationMethodSourceId, communicationMethodSourceType);
  }

  private buildReferenceableKeyFromIdAndType(id, type) {
    const ids = [id].flat();
    const types = [type].flat();

    ids.forEach( (i, index) => {
      const t = types[index];
      if (i && t) {
        this.referenceableTypeIdKeys.push(`${t}:${i}`);
      }
    })
  }

  public linkFromTypeIdKey(typeIdKey) {
    const [type, id] = typeIdKey.split(':');
    switch(type) {
      case 'InboundFax':
        return `/capture-admin/communications/inbound-faxes/${id}`;
      case 'InboundCall':
        return `/capture-admin/communications/inbound-calls/${id}`;
      default:
        return null;
    }
  }

  public linkLabelFromTypeIdKey(typeIdKey) {
    const [type, id] = typeIdKey.split(':');
    const humanizedType = camelCaseToFlat(type);
    return `View ${humanizedType} ${id}`;
  }

}