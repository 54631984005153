import { LimitOffsetPaging } from '../paged-results/limit-offset-paging.model';

export class PatientInteractionLocationFilters {
  clientIds = [];
  clientEntityTypes = [];
  assignedClientLeadUserIds = [];
  eligibilityStatuses = [];
  name = "";
}

export class PatientInteractionLocationUiOptions {
  explainerExpanded = true;
}

export class PatientInteractionLocationListSettings {
  uiOptions: PatientInteractionLocationUiOptions = new PatientInteractionLocationUiOptions();
  filters: PatientInteractionLocationFilters = new PatientInteractionLocationFilters();
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
}
