import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { range, min, max } from 'lodash-es';

// ng-bootstrap does not currently support selecting a year and month only.
// There has been an open issue since 2017.  If that's ever added, we should
// consider using that instead.
// https://github.com/ng-bootstrap/ng-bootstrap/issues/1811

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
})
export class MonthPickerComponent implements OnChanges {
  @Input() year;
  @Input() month;

  @Input() minYear;
  @Input() minMonth;
  @Input() maxYear;
  @Input() maxMonth;

  @Output() yearChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() monthChange: EventEmitter<number> = new EventEmitter<number>();

  availableYears: number[];
  availableMonths: number[];

  constructor() {}

  determineAvailableValues() {
    const currentYear = new Date().getFullYear();
    const minYear = this.minYear || currentYear - 10;
    const maxYear = this.maxYear || currentYear + 10;

    const minMonth = max([this.minYear && this.minMonth && this.year === this.minYear ? this.minMonth : 1, 1]);
    const maxMonth = min([this.maxYear && this.maxMonth && this.year === this.maxYear ? this.maxMonth : 12, 12]);

    this.availableYears = range(maxYear, minYear - 1);
    this.availableMonths = range(minMonth, maxMonth + 1);
  }

  changeMonth(month) {
    this.month = month;
    this.determineAvailableValues();

    Promise.resolve().then(() => {
      this.monthChange.emit(this.month);
    });
  }

  ngOnChanges() {
    this.determineAvailableValues();

    if (this.minYear && this.minMonth && this.year === this.minYear && this.month < this.minMonth) {
      this.changeMonth(this.minMonth);
    }

    if (this.maxYear && this.maxMonth && this.year === this.maxYear && this.month > this.maxMonth) {
      this.changeMonth(this.maxMonth);
    }
  }
}
