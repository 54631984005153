import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './capture-admin-patient-interaction-location-reports.component.html',
  styleUrls: ['./capture-admin-patient-interaction-location-reports.component.scss'],
})
export class CaptureAdminPatientInteractionLocationReportsComponent {
  reportsUrl = `${environment.captureApi.url}/patient_interaction_location_reports`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    clientIds: [null],
    assignedClientLeadsIds: [null],
    clientEntityTypes: [null],
    eligibilityStatuses: [null],
    name: [null],
  });
}