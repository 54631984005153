import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { IccOrphanExemptionDrugImport } from '../../models/icc/icc-orphan-exemption-drug-import.model';
import { IccImportFilters } from '../../models/icc/icc-import-settings.model';

@Injectable()
export class IccOrphanExemptionDrugImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<IccOrphanExemptionDrugImport> {
    return this.http.get<IccOrphanExemptionDrugImport>(
      `${environment.captureApi.url}/icc/orphan_exemption_drug_imports/${id}`
    );
  }

  getList(
    filters: IccImportFilters = null
  ): Observable<{ orphanExemptionDrugImports: IccOrphanExemptionDrugImport[] }> {
    const params = new URLSearchParams();

    if (filters && filters.selectedClientIds) {
      filters.selectedClientIds.forEach(clientId => params.append('client_ids[]', clientId));
    }

    return this.http.get<{ orphanExemptionDrugImports: IccOrphanExemptionDrugImport[] }>(
      `${environment.captureApi.url}/icc/orphan_exemption_drug_imports?${params.toString()}`
    );
  }

  create(clientId: number, name: string, signedId: string): Observable<IccOrphanExemptionDrugImport> {
    return this.http.post<IccOrphanExemptionDrugImport>(
      `${environment.captureApi.url}/icc/orphan_exemption_drug_imports`,
      {
        orphanExemptionDrugImport: { clientId, name, signedId },
      }
    );
  }

  update(id, name): Observable<IccOrphanExemptionDrugImport> {
    return this.http.put<IccOrphanExemptionDrugImport>(
      `${environment.captureApi.url}/icc/orphan_exemption_drug_imports/${id}`,
      {
        orphanExemptionDrugImport: { name },
      }
    );
  }

  cancel(id): Observable<IccOrphanExemptionDrugImport> {
    return this.http.patch<IccOrphanExemptionDrugImport>(
      `${environment.captureApi.url}/icc/orphan_exemption_drug_imports/${id}/cancel`,
      {}
    );
  }
}
