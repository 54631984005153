<app-consult-note-history-item-title>
  {{ title }}
</app-consult-note-history-item-title>

<app-consult-note-history-item-body>
  <ng-container *ngIf="rolledUpTransitionedCaptureStatusCounts.length > 0; else: noCapturesTransitioned">
    <p>Consult note submitted and captures were transitioned:</p>
    <ul>
      <li *ngFor="let entry of rolledUpTransitionedCaptureStatusCounts">
        {{ displayTransitionedCounts(entry) }}
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="rolledUpFailedTransitionedCaptureStatusCounts.length > 0">
    <p>Not transitioned:</p>
    <ul>
      <li *ngFor="let entry of rolledUpFailedTransitionedCaptureStatusCounts">
        {{ displayTransitionedCounts(entry) }}
      </li>
    </ul>
  </ng-container>
</app-consult-note-history-item-body>
<ng-template #noCapturesTransitioned>
  <p>Consult note submitted and no captures were transitioned.</p>
</ng-template>
