import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MeService } from '../core/services/me.service';
import { NavigationService } from '../core/services/navigation.service';
import { UserSettings } from '../core/enums/user-settings.enum';
import { UserSettingsService } from '../core/services/user-settings.service';
import { AuthenticationService } from '../core/services/authentication.service';
import { AcceptEulaModalComponent } from './components/accept-eula-modal/accept-eula-modal.component';
import { NavigationSettings } from '../core/models/user-settings/navigation-settings.model';
import { UserTodos } from 'app/core/models/user-todo.model';

@Component({
  selector: 'app-client-portal',
  templateUrl: './client-portal.component.html',
  styleUrls: ['./client-portal.component.scss'],
})
export class ClientPortalComponent implements OnInit, OnDestroy {
  constructor(
    private meService: MeService,
    private authService: AuthenticationService,
    private navigationService: NavigationService,
    private userSettingsService: UserSettingsService,
    private modalService: NgbModal
  ) {}

  selectedClientChangedSubscription = null;
  userTodosChangedSubscription = null;
  userLogoutSubscription = null;

  reviewableClientPrescriberTasks = 0;
  reviewableClientConsultNoteTasks = 0;
  reviewableClientReferralTasks = 0;
  ehrUploadablePatientAttachments = 0;
  newPatientInteractionLocations = 0;

  clientConsultNoteTasksEnabled = false;
  clientReferralTasksEnabled = false;
  directEnabled = false;
  patientInteractionLocationManagementEnabled = false;

  acceptEulaModal: NgbModalRef = null;

  ngOnInit() {
    this.updateSelectedClient();

    this.selectedClientChangedSubscription = this.meService.selectedClientChanged.subscribe(() => {
      this.updateSelectedClient();
    });

    this.userTodosChangedSubscription = this.meService.userTodosChanged.subscribe(({ todos }) => {
      this.updatesTodos(todos);
    });

    this.userLogoutSubscription = this.authService.userLogout.subscribe(() => {
      this.closeEulaModal();
    });

    if (this.authService.isEulaRequired) {
      this.openEulaModal();
    }
  }

  ngOnDestroy() {
    if (this.userTodosChangedSubscription) {
      this.userTodosChangedSubscription.unsubscribe();
    }

    if (this.userLogoutSubscription) {
      this.userLogoutSubscription.unsubscribe();
    }

    this.closeEulaModal();
  }

  get activeTasksLink(): string {
    const userNavigationSettings: NavigationSettings = this.userSettingsService.get<NavigationSettings>(
      UserSettings.userNavigationSettings
    );

    if (userNavigationSettings && userNavigationSettings.lastTasksSectionPage) {
      return userNavigationSettings.lastTasksSectionPage;
    }

    if (this.clientReferralTasksEnabled) {
      return 'tasks/client-referral-tasks';
    }

    if (this.clientConsultNoteTasksEnabled) {
      return 'tasks/client-consult-note-tasks';
    }

    return 'tasks/client-prescriber-tasks';
  }

  get isTasksLinkActive(): boolean {
    return this.navigationService.inSubSection('tasks');
  }

  get showReportsSubNav(): boolean {
    return this.navigationService.inSubSection('reports');
  }

  get showTasksSubNav(): boolean {
    return this.navigationService.inSubSection('tasks');
  }

  get showingSubNav(): boolean {
    return this.showReportsSubNav || this.showTasksSubNav || this.showConsultNotesSubNav;
  }

  get showConsultNotesSubNav(): boolean {
    return this.navigationService.inSubSection('consult-notes');
  }

  get reviewableClientTasks(): number {
    return (
      this.reviewableClientPrescriberTasks + this.reviewableClientConsultNoteTasks + this.reviewableClientReferralTasks
    );
  }

  private updatesTodos(todos: UserTodos) {
    this.reviewableClientPrescriberTasks = todos.reviewableClientPrescriberTasks;
    this.reviewableClientConsultNoteTasks = todos.reviewableClientConsultNoteTasks;
    this.reviewableClientReferralTasks = todos.reviewableClientReferralTasks;
    this.ehrUploadablePatientAttachments = todos.ehrUploadablePatientAttachments;
    this.newPatientInteractionLocations = todos.newPatientInteractionLocations;
  }

  private updateSelectedClient() {
    this.meService.getFeatures().subscribe(features => {
      this.directEnabled = features.directEnabled;
      this.clientConsultNoteTasksEnabled = features.clientConsultNoteTasksEnabled;
      this.clientReferralTasksEnabled = features.clientReferralTasksEnabled;
      this.patientInteractionLocationManagementEnabled = features.patientInteractionLocationManagementEnabled;

      this.meService.getTodos().subscribe(todos => {
        this.updatesTodos(todos);
      });
    });
  }

  private openEulaModal() {
    this.acceptEulaModal = this.modalService.open(AcceptEulaModalComponent, {
      windowClass: 'wide',
      beforeDismiss: () => false,
    });
  }

  private closeEulaModal() {
    if (this.acceptEulaModal) {
      this.acceptEulaModal.close();
      this.acceptEulaModal = null;
    }
  }
}
