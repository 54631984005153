import { Component, Input } from '@angular/core';
import { Report, AppliedReportOptions } from 'app/core/models/report.model';

@Component({
  selector: 'app-report-applied-month-range-option',
  templateUrl: './report-applied-month-range-option.component.html',
})
export class ReportAppliedMonthRangeOptionComponent {
  @Input() name;
  @Input() label;
  @Input() options: AppliedReportOptions;

  constructor() {}

  splitYearMonth(yearMonth) {
    if (yearMonth) {
      const [year, month] = yearMonth.split(/-/);

      return [parseInt(year, 10), parseInt(month, 10)];
    } else {
      return [];
    }
  }

  get monthRange() {
    const from = this.splitYearMonth(this.options[`${this.name}From`]);
    const to = this.splitYearMonth(this.options[`${this.name}To`]);

    if (from || to) {
      return {
        fromYear: from[0],
        fromMonth: from[1],
        toYear: to[0],
        toMonth: to[1],
      };
    }
  }
}
