import { DatePipe } from '@angular/common';
import { DateUtils } from '../lib/date-utils';
import { isNotBlank, obj2Html, isObjectEmpty } from '../lib/js-utils';
import { Audit, ParsedAudit } from '../models/audit.model';

const pretty = (val: any, notSetString) => {
  const dataType = typeof val;

  if (val) {
    switch (dataType) {
      case 'boolean':
        return val ? 'On' : 'Off';
      case 'object':
        return obj2Html(val);
      case 'string':
        if (!val.match(/^\w+/) && DateUtils.isValid(val)) {
          return new DatePipe('en-US').transform(val, 'M/d/yyyy h:mm a');
        } else {
          return val || notSetString;
        }
      default:
        return val || notSetString;
    }
  } else {
    return notSetString;
  }
}

const parsedAuditChanges = (changes, notSetString) => {
  const current = {};
  const previous = {};

  Object.entries(changes).forEach(([k, v]) => {
    if (Array.isArray(v) && v.length === 2) {
      current[k] = pretty(v[1], notSetString);
      previous[k] = pretty(v[0], notSetString);
    } else {
      if (isNotBlank(v)) {
        current[k] = pretty(v, notSetString);
      }
    }
  });

  return { current, previous };
}

export const createParsedAudit = (audit: Audit, changes: any, notSetString: string): ParsedAudit | null => {
    const { current, previous } = parsedAuditChanges(changes, notSetString);

    if (isObjectEmpty(current) && isObjectEmpty(previous)) {
      return null;
    }

    const { createdAt, action, user, auditableType } = audit;
    return {
      createdAt,
      action,
      user,
      auditableType,
      changes: [previous, current],
      changesHtml: [obj2Html(previous), obj2Html(current)],
    };
}
