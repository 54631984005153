import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DrugMatchVerification } from '../models/drug-match-verification.model';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { Candidate } from '../models/candidate.model';

@Injectable()
export class DrugMatchVerificationsService extends ApiService {
  constructor(authService: AuthenticationService, private http: HttpClient) {
    super();
  }

  create(
    patientAttachmentId: number,
    verifyingCaptureId: number,
    { drugGroupId, ndc, referenceNumber, matches, notes }: DrugMatchVerification
  ): Observable<DrugMatchVerification> {
    const drugContext = drugGroupId ? { drugGroupId, ndc: null } : { drugGroupId: null, ndc };

    return this.http.post<DrugMatchVerification>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/drug_match_verifications`,
      {
        drugMatchVerification: {
          ...drugContext,
          referenceNumber,
          patientAttachmentId,
          verifyingCaptureId,
          matches,
          notes
        },
      }
    );
  }

  update(
    patientAttachmentId: number,
    verifyingCaptureId: number,
    { id, matches, notes }: DrugMatchVerification
  ): Observable<DrugMatchVerification> {
    return this.http.patch<DrugMatchVerification>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/drug_match_verifications/${id}`,
      {
        drugMatchVerification: { verifyingCaptureId, matches, notes },
      }
    );
  }
}
