import { Component, OnInit } from '@angular/core';
import { MeService } from 'app/core/services/me.service';
import { User } from 'app/core/models/user.model';

@Component({
  selector: 'app-client-portal-preferences',
  templateUrl: './client-portal-preferences.component.html',
})
export class ClientPortalPreferencesComponent implements OnInit {
  refreshing = true;
  successfullyUpdated: boolean;
  errorOccurred: boolean;
  user: User;

  constructor(private meService: MeService) {}

  ngOnInit() {
    this.loadUserPreference();
  }

  onSubmit() {
    this.errorOccurred = false;
    this.meService.put(this.user).subscribe(
      userPreference => {
        this.successfullyUpdated = true;
        setTimeout(() => (this.successfullyUpdated = false), 3000);
        this.user = userPreference;
      },
      _error => {
        this.errorOccurred = true;
      }
    );
  }

  loadUserPreference() {
    this.errorOccurred = false;
    this.refreshing = true;
    this.meService.get().subscribe(
      user => {
        this.user = user;
        this.refreshing = false;
      },
      _error => {
        this.errorOccurred = true;
      }
    );
  }
}
