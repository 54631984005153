<div
  *ngFor="let prescriberMatchOption of prescriberMatchOptions"
  class="prescriber form-check mb-1"
  [ngClass]="{ disabled: prescriberMatchOption.frozen }"
>
  <input
    type="checkbox"
    [disabled]="prescriberMatchOption.frozen"
    (change)="onPrescriberVerificationChange(prescriberMatchOption.verification)"
    [id]="prescriberMatchOption.npi"
    [name]="prescriberMatchOption.npi"
    class="form-check-input"
    [(ngModel)]="prescriberMatchOption.verification.matches"
  />
  <app-provider [provider]="prescriberMatchOption" [showFaxStatus]="true" />
</div>
