import { Component, Input, OnInit } from '@angular/core';
import { Candidate } from '../../../core/models/candidate.model';

@Component({
  selector: 'app-high-value-indicator',
  templateUrl: './high-value-indicator.component.html',
  styleUrls: ['./high-value-indicator.component.scss'],
})
export class HighValueIndicatorComponent implements OnInit {
  @Input() capture: { candidate: { highValue: boolean } };

  constructor() {}

  ngOnInit() {}
}
