import { Component, Input } from '@angular/core';
import { Report, AppliedReportOptions } from 'app/core/models/report.model';

@Component({
  selector: 'app-report-applied-date-range-option',
  templateUrl: './report-applied-date-range-option.component.html',
})
export class ReportAppliedDateRangeOptionComponent {
  @Input() name;
  @Input() label;
  @Input() options: AppliedReportOptions;

  constructor() {}

  get dateRange() {
    const from = this.options[`${this.name}From`];
    const to = this.options[`${this.name}To`];

    if (from || to) {
      return {
        from,
        to,
      };
    }
  }
}
