import { Component, OnInit } from '@angular/core';
import { UserFormMode } from '../../components/user-form/user-form.component';

@Component({
  selector: 'app-capture-admin-user-new',
  templateUrl: './capture-admin-user-new.component.html',
  styleUrls: ['./capture-admin-user-new.component.scss'],
})
export class CaptureAdminUserNewComponent implements OnInit {
  userFormModes = UserFormMode;

  constructor() {}

  ngOnInit() {}
}
