import { Component, Input } from '@angular/core';
import { remove } from 'lodash-es';
import { Capture } from '../../../core/models/capture.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { CaptureValidationService, ValidationKeys } from '../../../core/services/capture-validation.service';
import { SpecialistEncounterVerification } from '../../../core/models/specialist-encounter-verification.model';
import { CaptureService } from '../../../core/services/capture.service';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';

@Component({
  selector: 'app-specialist-encounter-form',
  templateUrl: './specialist-encounter-form.component.html',
  styleUrls: ['./specialist-encounter-form.component.scss'],
})
export class SpecialistEncounterFormComponent {
  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;

  validationKey = ValidationKeys.specialistEncounterVerification;

  constructor(
    private authService: AuthenticationService,
    private captureService: CaptureService,
    private patientAttachmentService: PatientAttachmentsService,
    private captureValidationService: CaptureValidationService
  ) {}

  onVerificationChange(change: { verification: SpecialistEncounterVerification; tempId?: string }) {
    this.captureValidationService.clearError(this.validationKey);
    this.updateCaptureSpecialistEncounterVerification(change.verification, change.tempId);
  }

  onVerificationRemove(sev: SpecialistEncounterVerification) {
    this.captureValidationService.clearError(this.validationKey);
    this.removeCaptureSpecialistEncounterVerification(sev);
  }

  private updateCaptureSpecialistEncounterVerification(
    specialistEncounterVerification: SpecialistEncounterVerification,
    tempId?: string
  ) {
    const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);

    if (!modifiedPatientAttachment.specialistEncounterVerifications) {
      modifiedPatientAttachment.specialistEncounterVerifications = [];
    }

    const paIndex = modifiedPatientAttachment.specialistEncounterVerifications.findIndex(
      paSev => paSev.id === specialistEncounterVerification.id || paSev.id === tempId
    );

    if (paIndex > -1) {
      modifiedPatientAttachment.specialistEncounterVerifications[paIndex] = specialistEncounterVerification;
    } else {
      modifiedPatientAttachment.specialistEncounterVerifications.push(specialistEncounterVerification);
    }

    this.patientAttachmentService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
  }

  private removeCaptureSpecialistEncounterVerification(sev: SpecialistEncounterVerification) {
    const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);
    remove(modifiedPatientAttachment.specialistEncounterVerifications, captureSev => captureSev.id === sev.id);
    this.patientAttachmentService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
  }
}
