<div *ngIf="!refreshing; else: spinner">
  <div class="row g-0 align-items-end mb-1">
    <div class="col">
      <strong>{{ patientAttachment.fileName | blankValueDash }}</strong>
    </div>
    <div class="col text-end">
      <button class="btn btn-sm btn-secondary" *ngIf="patientAttachment.fileName" (click)="onDownloadClick($event)">
        <fa-icon [icon]="['fas', 'cloud-download-alt']" /> Download
      </button>

      <span *ngIf="patientAttachment.pdfGenerationPending">
        Preparing
      </span>
    </div>
  </div>
  <app-pdf-file-viewer
    [pdfFile]="patientAttachment"
    [pdfFileType]="pdfFileType"
    [initialPage]="1"
    [hideRotationControls]="true"
    height="80vh"
  />
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
