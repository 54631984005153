<section *ngIf="!loading; else: spinner">
  <app-client-task-nav [baseUrl]="navBaseUrl" />
  <div><app-client-referral-task-header [task]="task" [displayUserPresence]="displayUserPresence" /></div>
  <app-patient-prescriber-active-note [patientPrescriberId]="task.patientPrescriberId" />
  <div class="row m-0 p-0 mt-3 mb-5">
    <div class="col p-0">
      <app-client-referral-task-verification
        [task]="task"
        [formDisabled]="formDisabled"
        (taskChange)="onTaskChange($event)"
        (prescriptionDismissed)="onPrescriptionDismissed()"
      />
    </div>
    <div class="col">
      <app-client-referral-task-details [task]="task" />
    </div>
  </div>
</section>
<ng-template #spinner> <mat-spinner /> </ng-template>
