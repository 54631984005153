import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-validate-drug-match-heading',
  templateUrl: './validate-drug-match-heading.component.html',
  styleUrls: ['./validate-drug-match-heading.component.scss'],
})
export class ValidateDrugMatchHeadingComponent implements OnInit {
  @Input() key: string;
  @Input() name: string;
  @Input() position: number;
  @Input() showWarningIndicator: boolean;
  @Input() collapsed: boolean;

  @Output() collapseChange = new EventEmitter<{ key: string; collapsed: boolean }>();

  constructor() {}

  ngOnInit() {}

  onToggleCollapseClick($event) {
    $event.preventDefault();
    this.collapseChange.emit({ key: this.key, collapsed: !this.collapsed });
  }
}
