import { Component } from '@angular/core';
import { ConsultNoteHistoryItemTypeBaseComponent } from '../consult-note-history-item-type-base/consult-note-history-item-type-base.component';

@Component({
  selector: 'app-consult-note-history-item-type-created',
  templateUrl: './consult-note-history-item-type-created.component.html',
})
export class ConsultNoteHistoryItemTypeCreatedComponent extends ConsultNoteHistoryItemTypeBaseComponent {
  get title() {
    return 'Created';
  }

  get description() {
    return 'New consult note created';
  }
}
