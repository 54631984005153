import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { LimitOffsetPaging } from '../../../core/models/paged-results/limit-offset-paging.model';
import { NavigationService } from '../../../core/services/navigation.service';
import {
  NameMapping,
  NameMappingSummary,
  NameMappingUpdate,
  NameMappingFilters
} from '../../../core/models/name-mapping.model';
import {
  NameMappingsService,
  PagedNameMappings,
} from '../../../core/services/name-mappings.service';

const defaultPageSize = 10;
const defaultCursorType = 'levenshtein_ratio';

@Component({
  selector: 'app-capture-admin-name-mappings',
  templateUrl: './capture-admin-name-mappings.component.html',
  styleUrls: ['./capture-admin-name-mappings.component.scss'],
})

export class CaptureAdminNameMappingsComponent implements OnInit {
  MatchOptions = [
    { value: 'first', display: 'First' },
    { value: 'last', display: 'Last' },
  ];

  CursorOptions = [
    { value: 'created_at', display: 'Date', dir: 'desc' },
    { value: 'estimated_value', display: 'Estimated Value', dir: 'desc' },
    { value: 'name', display: 'CE Name', dir: 'asc' },
    { value: 'alternate', display: 'RX Name', dir: 'asc' },
    { value: 'levenshtein_ratio', display: 'Confidence Ratio', dir: 'desc' },
  ];

  filters: NameMappingFilters;
  nameMappings: NameMapping[] = [];
  nameMappingSummary: NameMappingSummary;
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  loading = true;
  baseCaptureUrl = null;
  Approvals!: FormGroup;
  submitData: NameMappingUpdate;
  cursorType = defaultCursorType

  constructor(
    private nameMappingsService: NameMappingsService,
    private navigationService: NavigationService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.paging = LimitOffsetPaging.fromQueryParams({}, { pageSize: defaultPageSize });
    this.baseCaptureUrl = this.navigationService.baseShowUrl('captures');
    this.resetSearchOptions();
    this.init();
  }

  private init() {
    this.getNameMappings();
  }

  private getNameMappings() {
    this.loading = true;
    this.nameMappingsService
      .getList(this.paging.currentPage, this.paging.pageSize, this.filters, this.cursorType, this.cursorDir())
      .subscribe((result: PagedNameMappings) => {
        this.loadNameMappingSummary();
        this.nameMappings = result.nameMappings;
        this.initializeForm();
        this.buildRows();
        this.paging = result.meta.paging;
        this.loading = false;
      });
  }

  private updateNameMappings() {
    this.loading = true;
    this.nameMappingsService
      .updateList(this.submitData)
      .subscribe((result: NameMappingSummary) => {
        this.nameMappingSummary = result;
        this.getNameMappings();
      }, error => {
        this.loading = false;
        console.log(error);
      } );
  }

  private initializeForm() {
    this.Approvals = this.formBuilder.group({
      Rows: this.formBuilder.array([])
    });
  }

  private buildRows() {
    this.nameMappings.forEach((row) => {
      this.formArr.push(this.formRow(row));
    });
  }

  private formRow(row: NameMapping) {
    return  this.formBuilder.group({
      id: row.id,
      status: row.status,
      name: row.name,
      alternate: row.alternate,
      nameType: row.nameType,
      highValue: row.highValue,
      estimatedValue: row.estimatedValue,
      createdAt: row.createdAt,
      updatedAt: row.updatedAt,
      patientId: row.patientId,
      captureId: row.captureId,
      userId: row.userId,
      needsPropagation: row.needsPropagation,
      statusUpdatedAt: row.statusUpdatedAt,
      statusUpdatedById: row.statusUpdatedById,
      levenshteinRatio: row.levenshteinRatio,
      approve: false
    });
  }

  private loadNameMappingSummary() {
    this.nameMappingsService.getSummary().subscribe((result: NameMappingSummary) => {
      this.nameMappingSummary = result;
    });
  }

  get formArr() {
    return this.Approvals.get('Rows') as FormArray;
  }

  private cursorDir() {
    return this.CursorOptions.find((option) => option.value === this.cursorType)?.dir || 'asc';
  }

  onSubmit() {
    this.submitData = { approve: [], reject: []};
    this.Approvals.value.Rows.forEach((row) => {
      if (row.approve === true) {
        this.submitData.approve.push(row.id);
      } else {
        this.submitData.reject.push(row.id);
      }
    });
    this.updateNameMappings();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.getNameMappings();
  }

  onPageChange(page) {
    this.getNameMappings();
  }

  onSearchClick() {
    this.getNameMappings();
  }

  private resetSearchOptions() {
    this.filters = new NameMappingFilters();
    this.filters.hasHighValue = 'true';
    this.filters.hasNpmaCaptures = 'true';

    this.paging.pageSize = defaultPageSize;

    this.cursorType = defaultCursorType;
  }

  onResetClick(event) {
    this.resetSearchOptions();
    this.getNameMappings();
  }
}
