import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { SpecialistEncounterVerification } from '../../../core/models/specialist-encounter-verification.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { SpecialistEncounterVerificationService } from '../../../core/services/specialist-encounter-verification.service';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { isPatientAttachmentVerificationFrozen } from '../../../core/lib/verification-utils';
import { nanoid } from 'nanoid';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EditEncounterDatesModalComponent } from '../edit-encounter-dates-modal/edit-encounter-dates-modal.component';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';

@Component({
  selector: 'app-specialist-encounter-verifications',
  templateUrl: './specialist-encounter-verifications.component.html',
  styleUrls: ['./specialist-encounter-verifications.component.scss'],
})
export class SpecialistEncounterVerificationsComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;

  @Output() verificationChange = new EventEmitter<{ verification: SpecialistEncounterVerification; tempId?: string }>();
  @Output() verificationRemove = new EventEmitter<SpecialistEncounterVerification>();

  addDisabled = false;
  canEditDates = false;
  specialistEncounterVerifications: SpecialistEncounterVerification[] = [];
  private editDatesModal: NgbModalRef;

  constructor(
    private authService: AuthenticationService,
    private patientAttachmentsService: PatientAttachmentsService,
    private specialistEncounterVerificationService: SpecialistEncounterVerificationService,
    private modalService: NgbModal
  ) {}

  ngOnChanges() {
    this.initialize();
  }

  onChange(verification: SpecialistEncounterVerification) {
    if (verification.encounterDate) {
      this.handleVerificationChange(verification);
    }
  }

  onRemove(verification: SpecialistEncounterVerification) {
    if (verification.createdAt) {
      this.destroyVerification(verification);
    } else {
      this.verificationRemove.emit(verification);
    }
  }

  onAddClick($event: Event) {
    $event.preventDefault();
    this.addNewVerification();
  }

  onEditClick($event: Event) {
    $event.preventDefault();
    this.openEditModal();
  }

  private initialize() {
    this.addDisabled = this.isAddDisabled();
    this.canEditDates = this.capture.verified && this.authService.hasPermissionTo(UserRoleName.editVerifiedCaptures);
    this.initSpecialistEncounterVerifications();
  }

  private handleVerificationChange(verification: SpecialistEncounterVerification) {
    if (verification.createdAt) {
      this.updateVerification(verification);
    } else {
      this.createVerification(verification);
    }
  }

  private createVerification(verification: SpecialistEncounterVerification) {
    this.specialistEncounterVerificationService
      .create(this.patientAttachment.id, this.capture.id, verification)
      .subscribe(newVerification => {
        this.verificationChange.emit({ verification: newVerification, tempId: verification.id });
      });
  }

  private updateVerification(verification: SpecialistEncounterVerification) {
    this.specialistEncounterVerificationService
      .update(this.patientAttachment.id, this.capture.id, verification)
      .subscribe(updatedVerification => {
        this.verificationChange.emit({ verification: updatedVerification });
      });
  }

  private destroyVerification(verification: SpecialistEncounterVerification) {
    this.specialistEncounterVerificationService.destroy(this.patientAttachment.id, verification).subscribe(removed => {
      this.verificationRemove.emit(removed);
    });
  }

  private initSpecialistEncounterVerifications() {
    this.specialistEncounterVerifications = this.patientAttachment.specialistEncounterVerifications || [];

    if (this.specialistEncounterVerifications.length === 0) {
      this.addNewVerification();
    }
  }

  private isAddDisabled(): boolean {
    return isPatientAttachmentVerificationFrozen(this.authService, this.capture, this.patientAttachment, null);
  }

  private addNewVerification() {
    const sev = new SpecialistEncounterVerification();
    sev.id = nanoid();
    sev.encounterDate = null;
    this.verificationChange.emit({ verification: sev });
  }

  private openEditModal() {
    this.editDatesModal = this.modalService.open(EditEncounterDatesModalComponent, {
      windowClass: 'edit', size: 'lg'
    });

    this.editDatesModal.componentInstance.capture = this.capture;
    this.editDatesModal.componentInstance.patientAttachment = this.patientAttachment;
    this.editDatesModal.componentInstance.specialistEncounterVerifications = this.specialistEncounterVerifications;

    this.editDatesModal.result.then(
      (patientAttachment: PatientAttachment) =>
        this.patientAttachmentsService.notifyPatientAttachmentChanged(patientAttachment),
      () => undefined
    );
  }
}
