import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { InboundCall } from '../models/inbound-call.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';
import { environment } from '../../../environments/environment';

export class PagedInboundCalls implements PagedResults {
  inboundCalls: InboundCall[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.inboundCalls;
  }
}

@Injectable()
export class InboundCallsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id: number): Observable<InboundCall> {
    return this.http.get<InboundCall>(`${environment.captureApi.url}/inbound_calls/${id}`);
  }

  getList(
    page: number,
    pageSize: number,
    clientIds: Array<string> = [],
    npi: string = null,
    outboundFaxNumber: string = null,
    patientId: number = null,
  ): Observable<PagedInboundCalls> {
    const searchParams = new URLSearchParams(`page=${page}&per_page=${pageSize}`);

    clientIds.forEach(clientId => searchParams.append('client_ids[]', clientId));

    if (npi) {
      searchParams.append('npi', npi);
    }
    if (outboundFaxNumber) {
      searchParams.append('outbound_fax_number', outboundFaxNumber);
    }
    if (patientId) {
      searchParams.append('patient_id', patientId.toString());
    }

    return this.http.get<PagedInboundCalls>(`${environment.captureApi.url}/inbound_calls?${searchParams.toString()}`);
  }

  create(inboundCall: { outboundFaxId: number; reason: string; notes: string }): Observable<InboundCall> {
    return this.http.post<InboundCall>(`${environment.captureApi.url}/inbound_calls`, {
      inboundCall,
    });
  }
}
