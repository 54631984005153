<ng-container *ngIf="!loading; else: spinner">
  <app-patient-header [patient]="patient" />
  <div class="mt-3">
    <app-patient-captures-search-settings [patient]="patient" (settingsChange)="onSettingsChange($event)" />

    <div class="mt-2" *ngIf="!loadingCaptures; else: spinner">
      <ng-container *ngIf="captureQueueResults.length > 0; else: noCapturesFound">
        <app-capture-queue-results
          [captureQueueResults]="captureQueueResults"
          [displayPatient]="false"
          [displayClient]="false"
          [openInNewTab]="true" />

        <div *ngIf="hasMoreCaptures" class="text-center mb-4">
          <a href="#next" (click)="onNextClick($event)" class="load-next-captures">
            View Next {{ limit }} Captures
          </a>
        </div>
      </ng-container>
    </div>
    <ng-template #spinner>
      <div class="mt-2"><mat-spinner /></div>
    </ng-template>
    <ng-template #noCapturesFound>
      <div class="p-2">No captures found. Try modifying the filters.</div>
    </ng-template>
  </div>
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>