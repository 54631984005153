import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Capture } from '../../../core/models/capture.model';
import { CaptureHistoryItem } from '../../../core/models/capture-history-item.model';
import { CaptureService } from '../../../core/services/capture.service';
import { CaptureHistoryItemActions } from '../../../core/enums/capture-history-item-actions.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { UserPresenceService } from 'app/core/services/user-presence.service';

@Component({
  selector: 'app-capture-history-new',
  templateUrl: './capture-history-new.component.html',
  styleUrls: ['./capture-history-new.component.scss'],
})
export class CaptureHistoryNewComponent implements OnInit, OnDestroy {
  captureId: string;
  capture: Capture;
  captureHistoryItems: CaptureHistoryItem[] = [];
  captureHistoryItemActions = CaptureHistoryItemActions;

  loadingCapture = false;
  loadingHistoryItems = false;

  captureChangedSubscription = null;

  constructor(
    private route: ActivatedRoute,
    private captureService: CaptureService,
    private router: Router,
    private titleService: Title,
    private userPresenceService: UserPresenceService
  ) {}

  ngOnInit() {
    this.captureId = this.route.snapshot.paramMap.get('id');

    this.loadCapture(this.captureId);
    this.loadHistoryItems(this.captureId);

    this.captureChangedSubscription = this.captureService.captureChanged.subscribe(({ capture }) => {
      // is it our capture?
      if (capture.id === this.capture.id) {
        this.capture = capture;
        this.loadHistoryItems(capture.id);
      }
    });

    this.userPresenceService.subscribeAndJoin("capture", this.captureId)
  }

  ngOnDestroy() {
    if (this.captureChangedSubscription) {
      this.captureChangedSubscription.unsubscribe();
    }

    this.userPresenceService.leaveAndUnsubscribe("capture", this.captureId);
  }

  private loadCapture(captureId): void {
    this.loadingCapture = true;
    this.captureService.getCapture(captureId).subscribe(
      capture => {
        this.capture = capture;
        this.loadingCapture = false;
        this.setTitle();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
  }

  private loadHistoryItems(captureId): void {
    this.loadingHistoryItems = true;
    this.captureService.getCaptureHistoryItems(captureId).subscribe(
      captureHistoryItems => {
        this.loadingHistoryItems = false;
        this.captureHistoryItems = captureHistoryItems.reverse();
      },
      err => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
  }

  private setTitle() {
    const title = `R1 340B Recovery - ${this.capture.candidate.claimIdentifier} | History`;
    this.titleService.setTitle(title);
  }
}
