export const providerCredentialOptions = [
  'ACNP',
  'AGACNP',
  'AGNP',
  'ANP',
  'APN',
  'APRN',
  'ARNP',
  'ATC',
  'Aud.D',
  'CNM',
  'CNS',
  'CPNP',
  'DC',
  'DDS',
  'DMD',
  'DO',
  'DPM',
  'DPT',
  'FNP',
  'LCPC',
  'LCSW',
  'LMFT',
  'LPC',
  'MD',
  'MSW',
  'ND',
  'NMD',
  'NNP',
  'NP',
  'OD',
  'OT',
  'OTR',
  'PA',
  'PA-C',
  'PMHNP',
  'PNP',
  'PT',
  'PharmD',
  'PsyD',
  'RD',
  'RDH',
  'RDN',
  'RPA',
  'RPh',
  'WHNP'
];
