import { Injectable } from '@angular/core';
import { supportedBrowserVersions } from '../constants';
import Bowser from 'bowser';

@Injectable()
export class BrowserService {
  determineSupport() {
    const browser = Bowser.getParser(window.navigator.userAgent);

    return !!browser.satisfies(supportedBrowserVersions);
  }
}
