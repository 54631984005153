import { Component, Input, OnInit } from '@angular/core';
import { Office } from 'app/core/models/office.model';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-office-fax',
  templateUrl: './office-fax.component.html',
})
export class OfficeFaxComponent implements OnInit {
  @Input() office: Office;
  @Input() allowLinkToOffice = true;

  canViewOffice = false;

  constructor(
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.canViewOffice = this.authService.isCaptureAdminUser;
  }

  get linkTo(): string | null {
    return this.allowLinkToOffice && this.canViewOffice ? `/capture-admin/offices/${this.office.id}` : null;
  }
}
