import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './capture-admin-partner-portal-claim-report.component.html',
})
export class CaptureAdminPartnerPortalClaimReportComponent {
  reportsUrl = `${environment.captureApi.url}/partner_capture_reports`;
  reportsCountUrl = `${environment.captureApi.url}/partner_capture_reports/count`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    sources: [null],
    verifiedDate: [null],
  });
}
