import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { DateUtils } from '../../../core/lib/date-utils';
import { SpecialistEncounterVerification } from '../../../core/models/specialist-encounter-verification.model';
import { isConsultNoteVerificationFrozen } from '../../../core/lib/verification-utils';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { VerificationType } from '../../../core/enums/verification-type.enum';

@Component({
  selector: 'app-validate-specialist-encounter-verification',
  templateUrl: './validate-specialist-encounter-verification.component.html',
  styleUrls: ['./validate-specialist-encounter-verification.component.scss'],
})
export class ValidateSpecialistEncounterVerificationComponent implements OnChanges {
  @Input() patientAttachment: PatientAttachment;
  @Input() position = -1;
  @Input() verification: SpecialistEncounterVerification;

  @Output() changeVerification = new EventEmitter<SpecialistEncounterVerification>();
  @Output() removeVerification = new EventEmitter<SpecialistEncounterVerification>();

  encounterDate = null;
  disabled = false;
  canRemove = false;

  constructor(private authService: AuthenticationService) {}

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (['patientAttachment', 'position', 'verification'].some(input => input === inputName)) {
        this.initSpecialistEncounterVerification();
      }
    });
  }

  onEncounterDateBlur() {
    if (!this.isValidEncounterDate()) {
      this.encounterDate = null;
    }

    const updateSev: SpecialistEncounterVerification = Object.assign({}, this.verification);
    updateSev.encounterDate = this.encounterDate;

    this.changeVerification.emit(updateSev);
  }

  onRemoveClick($event) {
    $event.preventDefault();
    this.removeVerification.emit(this.verification);
  }

  private initSpecialistEncounterVerification() {
    this.disabled = this.isDisabled();
    this.canRemove = !this.disabled && this.position !== 0;
    this.encounterDate = this.verification && this.verification.encounterDate;
  }

  private isDisabled(): boolean {
    return isConsultNoteVerificationFrozen(
      this.authService,
      this.patientAttachment,
      VerificationType.specialistEncounterDate,
      this.verification
    );
  }

  private isValidEncounterDate() {
    return DateUtils.isValid(this.encounterDate);
  }
}
