<div *ngIf="!loadingCapture; else: spinner" class="capture-detail">
  <div class="capture-fixed mb-1"><app-capture-header [capture]="capture" /></div>
  <div *ngIf="!loadingHistoryItems; else: spinner" class="capture-history">
    <table class="table table-striped mt-3">
      <tbody>
        <tr *ngFor="let captureHistoryItem of captureHistoryItems">
          <td class="datetime">
            <div>{{ captureHistoryItem.createdAt | date: 'MM/dd/yyyy' }}</div>
            <div>{{ captureHistoryItem.createdAt | date: 'hh:mm a' }}</div>
          </td>
          <td>
            <div [ngSwitch]="captureHistoryItem.action">
              <app-capture-history-item-type-created
                *ngSwitchCase="captureHistoryItemActions.created"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-updated
                *ngSwitchCase="captureHistoryItemActions.updated"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-transitioned
                *ngSwitchCase="captureHistoryItemActions.transitioned"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-unreversed
                *ngSwitchCase="captureHistoryItemActions.unreversed"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-snapshot-created
                *ngSwitchCase="captureHistoryItemActions.snapshotCreated"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-verifying-patient-attachment-associated
                *ngSwitchCase="captureHistoryItemActions.verifyingPatientAttachmentAssociated"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-consult-note-submitted
                *ngSwitchCase="captureHistoryItemActions.consultNoteSubmitted"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-referral-match-verification-submitted
                *ngSwitchCase="captureHistoryItemActions.referralMatchVerificationSubmitted"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-base
                *ngSwitchDefault
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-location-added
                *ngSwitchCase="captureHistoryItemActions.locationAdded"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-developer-chore-updated
                *ngSwitchCase="captureHistoryItemActions.developerChoreUpdated"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-client-chore-started
                *ngSwitchCase="captureHistoryItemActions.clientChoreStarted"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-client-chore-updated
                *ngSwitchCase="captureHistoryItemActions.clientChoreUpdated"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-claim-status-updated
                *ngSwitchCase="captureHistoryItemActions.claimStatusUpdated"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-client-note-added
                *ngSwitchCase="captureHistoryItemActions.clientNoteAdded"
                [captureHistoryItem]="captureHistoryItem" />

              <app-capture-history-item-type-client-note-removed
                *ngSwitchCase="captureHistoryItemActions.clientNoteRemoved"
                [captureHistoryItem]="captureHistoryItem" />
            </div>
          </td>
          <td class="user-details">
            <div class="user">
              {{ captureHistoryItem.user.fullName }}
            </div>
            <div *ngIf="captureHistoryItem.initiatingUser" class="small">
              Initiated by {{ captureHistoryItem.initiatingUser.fullName }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
