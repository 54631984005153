import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { PatientPrescriber } from '../models/patient-prescriber.model';
import { PatientPrescriberNote } from '../models/patient-prescriber-note.model';

export class PatientPrescriberChangedEvent {
  constructor(public patientPrescriber: PatientPrescriber) { }
}

export class PatientPrescriberCapturesTransitionedEvent {
  patientPrescriber: PatientPrescriber;
  transitionedCaptureIds: number[];
}

@Injectable()
export class PatientPrescriberService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  private patientPrescriberChangedSource = new Subject<PatientPrescriberChangedEvent>();
  patientPrescriberChanged = this.patientPrescriberChangedSource.asObservable();

  get(ppId: number): Observable<PatientPrescriber> {
    const url = `${environment.captureApi.url}/patient_prescribers/${ppId}`;
    return this.http.get<PatientPrescriber>(url);
  }

  update(ppId: number, referralMatchVerificationNotes: string): Observable<PatientPrescriber> {
    const url = `${environment.captureApi.url}/patient_prescribers/${ppId}`;
    return this.http.patch<PatientPrescriber>(url, {
      patientPrescriber: { referralMatchVerificationNotes },
    });
  }

  advanceCapturesRequiringReferral(ppId: number):
    Observable<PatientPrescriberCapturesTransitionedEvent> {

    const url = `${environment.captureApi.url}/patient_prescribers/${ppId}/advance_captures_requiring_referral`;
    return this.http.patch<PatientPrescriberCapturesTransitionedEvent>(url, {});
  }

  notifyPatientPrescriberChanged(patientPrescriber: PatientPrescriber) {
    this.patientPrescriberChangedSource.next(new PatientPrescriberChangedEvent(patientPrescriber));
  }

  note(ppId: number): Observable<PatientPrescriberNote> {
    const url = `${environment.captureApi.url}/patient_prescribers/${ppId}/note`;
    return this.http.get<PatientPrescriberNote>(url);
  }
}
