import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FileRotation } from '../models/file-rotation.model';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { PdfFile } from '../interfaces/pdf-file';
import { PdfFileType } from '../enums/pdf-file-type.enum';

@Injectable()
export class FileRotationsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getByTarget(pdfFile: PdfFile, pdfFileType: PdfFileType): Observable<FileRotation> {
    return this.http.get<FileRotation>(
      `${environment.captureApi.url}/file_rotations/by_target/${pdfFileType}/${pdfFile.id}`
    );
  }

  cancel(fileRotation: FileRotation) {
    return this.http.patch(`${environment.captureApi.url}/file_rotations/${fileRotation.id}/cancel`, {});
  }

  rotate(pdfFile: PdfFile, pdfFileType: PdfFileType, degrees: number): Observable<FileRotation> {
    return this.http.post<FileRotation>(`${environment.captureApi.url}/file_rotations`, {
      rotation: {
        targetId: pdfFile.id,
        targetType: pdfFileType,
        degrees,
      },
    });
  }
}
