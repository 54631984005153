import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from 'app/core/models/client.model';
import { PatientFilters } from 'app/core/models/user-settings/patient-search-settings.model';
import { ClientsService } from 'app/core/services/clients.service';

@Component({
  selector: 'app-patient-filters',
  templateUrl: './patient-filters.component.html',
  styleUrls: ['./patient-filters.component.scss']
})
export class PatientFiltersComponent implements OnInit {
  @Input() filters: PatientFilters;
  @Output() public filtersChange = new EventEmitter<PatientFilters>();

  clients: Client[] = [];

  constructor(private clientsService: ClientsService) {}

  ngOnInit() {
    this.init();
  }

  emitFilterChange() {
    this.filtersChange.emit(this.filters);
  }

  onFormEnter($event) {
    $event.preventDefault();
    this.emitFilterChange();
  }

  onClearAllClick($event) {
    $event.preventDefault();
    this.filters = new PatientFilters();
    this.emitFilterChange();
  }

  onSubmit() {
    this.emitFilterChange();
  }

  private init() {
    this.loadClients();
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }
}
