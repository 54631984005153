import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { ValidationKeys } from '../../../core/services/capture-validation.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { DateUtils } from '../../../core/lib/date-utils';
import { SpecialistEncounterVerification } from '../../../core/models/specialist-encounter-verification.model';
import {
  isSpecialistEncounterDateWithinRange,
  isPatientAttachmentVerificationFrozen,
} from '../../../core/lib/verification-utils';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-specialist-encounter-verification',
  templateUrl: './specialist-encounter-verification.component.html',
  styleUrls: ['./specialist-encounter-verification.component.scss'],
})
export class SpecialistEncounterVerificationComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;
  @Input() position = -1;
  @Input() verification: SpecialistEncounterVerification;
  @Input() unrestrictedMode = false;

  @Output() changeVerification = new EventEmitter<SpecialistEncounterVerification>();
  @Output() removeVerification = new EventEmitter<SpecialistEncounterVerification>();

  validationKey = ValidationKeys.specialistEncounterVerification;
  encounterDate = null;
  showEncounterDateCheck = false;
  showEncounterDateWarning = false;
  disabled = false;
  canRemove = false;

  constructor(private authService: AuthenticationService) {}

  ngOnChanges() {
    this.initialize();
  }

  onEncounterDateBlur() {
    if (!this.isValidEncounterDate()) {
      this.encounterDate = null;
    }

    const updateSev: SpecialistEncounterVerification = Object.assign({}, this.verification);
    updateSev.encounterDate = this.encounterDate;

    this.changeVerification.emit(updateSev);
    this.updateEncounterDateIndicators(updateSev);
  }

  onRemoveClick($event) {
    $event.preventDefault();
    this.removeVerification.emit(this.verification);
  }

  private initialize() {
    this.disabled = !this.unrestrictedMode && this.isDisabled();
    this.canRemove = this.unrestrictedMode || (!this.disabled && this.position !== 0);
    this.encounterDate = this.verification.encounterDate;
    this.updateEncounterDateIndicators(this.verification);
  }

  private isDisabled(): boolean {
    return isPatientAttachmentVerificationFrozen(
      this.authService,
      this.capture,
      this.patientAttachment,
      this.verification
    );
  }

  private isValidEncounterDate() {
    return DateUtils.isValid(this.encounterDate);
  }

  private updateEncounterDateIndicators(updateSev: SpecialistEncounterVerification) {
    if (!this.unrestrictedMode) {
      const validDate = this.isValidEncounterDate();
      const validTimeFrame = isSpecialistEncounterDateWithinRange(updateSev, this.capture);

      this.showEncounterDateWarning = validDate && !validTimeFrame && !this.disabled;
      this.showEncounterDateCheck = validDate && validTimeFrame && !this.disabled;
    }
  }
}
