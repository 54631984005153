<ul class="list-group">
  <li
    *ngFor="let patient of patients"
    (click)="onPatientClick(patient)"
    class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
  >
    <span>
      <strong>{{ patient.fullName }}</strong> <small>{{ patient.dob | date: 'M/dd/yyyy' }}</small>
    </span>
    <span> {{ patient.gender | gender }} </span>
  </li>
</ul>
