import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Office } from 'app/core/models/office.model';
import { keyedStatusOptions } from 'app/core/options/office-status.opts';

@Component({
  selector: 'app-office-header',
  templateUrl: './office-header.component.html',
  styleUrls: ['./office-header.component.scss'],
})
export class OfficeHeaderComponent {
  @Input() office: Office;
  @Output() editDetails = new EventEmitter();

  keyedStatusOptions = keyedStatusOptions;

  onEdit($event) {
    $event.preventDefault();
    this.editDetails.emit();
  }
}
