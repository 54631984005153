import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseCaptureQueueSettingsComponent } from '../capture-queue/base-capture-queue-settings';

@Component({
  selector: 'app-capture-search-settings',
  templateUrl: './capture-search-settings.component.html',
  styleUrls: ['./capture-search-settings.component.scss'],
})
export class CaptureSearchSettingsComponent extends BaseCaptureQueueSettingsComponent {
  @Input() disableSearch = false;
  @Output() performSearch = new EventEmitter();
  @Output() performReset = new EventEmitter();

  onSearchClick($event) {
    $event.preventDefault();
    this.performSearch.emit();
  }

  protected handleResetClick($event) {
    super.handleResetClick($event);
    this.performReset.emit();
  }
}
