import { Component, Input, OnInit } from '@angular/core';
import { Provider } from 'app/core/models/provider.model';
import { ProviderInformationCollectionDetails } from 'app/core/models/provider-information-collection-details.model';
import { MechanicalTurkTask } from 'app/core/models/mechanical-turk-task.model';
import { MechanicalTurkTaskAnswer } from 'app/core/models/mechanical-turk-task-answer.model';
import { sortBy } from 'lodash-es';
import { keyedResultOptions } from 'app/core/options/provider-information-collection-result-issue.opts';

const urlRegex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);

@Component({
  selector: 'app-provider-information-collection-details',
  templateUrl: './provider-information-collection-details.component.html',
  styleUrls: ['./provider-information-collection-details.component.scss'],
})

export class ProviderInformationCollectionDetailsComponent implements OnInit {
  @Input() provider: Provider;

  informationCollectionDetails: ProviderInformationCollectionDetails;
  mostRecentTask: MechanicalTurkTask;
  mostRecentAnswer: MechanicalTurkTaskAnswer;
  taskResultIssues: string;
  taskResultSourceWebsites: any[];

  ngOnInit() {
    this.informationCollectionDetails = this.provider.informationCollectionDetails;

    if (this.informationCollectionDetails) {
      this.mostRecentTask = this.mostRecentByCreatedAt(this.informationCollectionDetails.mechanicalTurkTasks);
    }

    if (this.mostRecentTask) {
      this.mostRecentAnswer =
        this.mostRecentByCreatedAt(this.mostRecentTask.answers)
    }

    this.taskResultIssues = this.buildTaskResults();
    this.taskResultSourceWebsites = this.buildTaskResultSourceWebsites();
  }

  private mostRecentByCreatedAt(objects: any[]) {
    return sortBy(objects, (object: any) => object.createdAt).reverse()[0];
  }

  private buildTaskResults(): string {
    const issues = this.informationCollectionDetails?.resultIssues;

    if (issues && issues.length > 0) {
      return issues.map(result => keyedResultOptions[result]?.display).join(', ');
    } else if (this.mostRecentAnswer) {
      return 'HIT Complete - No Issues';
    }
  }

  private buildTaskResultSourceWebsites(): any[] {
    if (this.informationCollectionDetails) {
      return (this.informationCollectionDetails.resultSourceWebsites || []).
        map(website => ({ url: website, shouldLink: this.shouldLinkWebsite(website) }));
    }

    return [];
  }

  private shouldLinkWebsite(website: string): boolean {
    return !!website.match(urlRegex);
  }

  confirmExternalLink(websiteUrl: string): boolean {
    return confirm([
      "WARNING: You are attempting to follow a link provided by an outside party. ",
      "Please review the link before continuing:\n\n",
      websiteUrl,
      "\n\nContinue?"
    ].join(''));
  }
}
