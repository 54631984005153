import { Component } from '@angular/core';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';

@Component({
  selector: 'app-capture-history-item-type-client-note-added',
  templateUrl: '../capture-history-item-type-base/capture-history-item-type-base.component.html',
})
export class CaptureHistoryItemTypeClientNoteAddedComponent extends CaptureHistoryItemTypeBaseComponent {
  constructor() {
    super();
  }

  get title() {
    return "Client Note Added";
  }

  get description() {
    return this.captureHistoryItem.data?.content;
  }
}
