import { Component, OnInit, OnDestroy } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { NavigationService } from '../../../core/services/navigation.service';
import { UserTimingService } from '../../../core/services/user-timing.service';
import {
  ConsultNoteQueueService,
  PagedConsultNoteQueueResults,
} from '../../../core/services/consult-note-queue.service';
import {UserPresenceService} from 'app/core/services/user-presence.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { BaseConsultNotesComponent } from '../base-consult-notes/base-consult-notes.component';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';

@Component({
  selector: 'app-consult-notes',
  templateUrl: './consult-notes.component.html',
  styleUrls: ['./consult-notes.component.scss'],
})
export class ConsultNotesComponent extends BaseConsultNotesComponent implements OnInit, OnDestroy {

  constructor(
    protected consultNoteQueueService: ConsultNoteQueueService,
    protected navigationService: NavigationService,
    protected userPresenceService: UserPresenceService,
    protected userTimingService: UserTimingService,
    protected authenticationService: AuthenticationService,
    protected patientAttachmentsService: PatientAttachmentsService,
  ) {
    super(
      consultNoteQueueService,
      navigationService,
      userPresenceService,
      userTimingService,
      authenticationService,
      patientAttachmentsService
    );
  }

  protected consultNoteHasBeenSelected(id) {
    this.userPresenceService.subscribeAndJoin('consultNote', id);
  }

  protected consultNoteHasBeenUnselected(id) {
    this.userPresenceService.leaveAndUnsubscribe('consultNote', id);
  }

  protected configureGetResults() {
    this.getResults$
      .pipe(
        tap(() => {
          this.loadingResults = true;
          this.consultNoteQueueResults = [];
          this.userPresenceService.unsubscribeAll();
        }),
        switchMap(() => this.consultNoteQueueService.getResults(this.cursor))
      )
      .subscribe((results: PagedConsultNoteQueueResults) => {
        this.consultNoteQueueResults = results.consultNoteQueueResults;
        this.cursor = results.meta.cursor;
        this.loadingResults = false;
        this.hasMoreResults = !!results.meta.cursor;
        this.subscribeToUserPresence();
      });
  }
}
