import { Component, Input } from '@angular/core';
import { CaptureValidationService } from '../../../core/services/capture-validation.service';

@Component({
  selector: 'app-verification-validation',
  templateUrl: './verification-validation.component.html',
  styleUrls: ['./verification-validation.component.scss'],
})
export class VerificationValidationComponent {
  @Input() key: string;

  constructor(private captureValidationService: CaptureValidationService) {}

  get hasError(): boolean {
    return !!this.captureValidationService.getError(this.key);
  }

  get errorMessage(): string {
    return this.captureValidationService.getError(this.key);
  }
}
