import { Component, Input, OnInit } from '@angular/core';
import { ClientPrescriberTask } from '../../../core/models/client-prescriber-task.model';
import { ClientPrescriberTaskStatus } from '../../../core/enums/client-prescriber-task-status.enum';

@Component({
  selector: 'app-client-prescriber-task-status',
  templateUrl: './client-prescriber-task-status.component.html',
  styleUrls: ['./client-prescriber-task-status.component.scss'],
})
export class ClientPrescriberTaskStatusComponent implements OnInit {
  @Input() task: ClientPrescriberTask;

  taskStatusDisplay: { textColor: string; iconName: string } = { textColor: '', iconName: '' };

  constructor() {}

  ngOnInit() {
    switch (this.task.status) {
      case ClientPrescriberTaskStatus.open:
        this.taskStatusDisplay = { textColor: 'text-info', iconName: 'pageviews' };
        break;
      case ClientPrescriberTaskStatus.accepted:
        this.taskStatusDisplay = { textColor: 'text-success', iconName: 'check_circle' };
        break;
      case ClientPrescriberTaskStatus.rejected:
        this.taskStatusDisplay = { textColor: 'text-danger', iconName: 'thumb_down' };
        break;
    }
  }
}
