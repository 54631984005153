import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Capture } from 'app/core/models/capture.model';
import { CaptureStatus } from 'app/core/enums/capture-status.enum';
import {
  showValidateReferral,
  showCeReviewDetails,
  showPatientDocuments,
  showClientPrescriptionMatches,
  showConsultNoteVerification,
  showValidatePrescriptionWrittenAtCe
} from 'app/core/lib/capture-nav-util';

@Component({
  selector: 'app-capture-nav',
  templateUrl: './capture-nav.component.html',
  styleUrls: ['./capture-nav.component.scss'],
})
export class CaptureNavComponent implements OnInit {
  @Input() currentSection: string;
  @Input() capture: Capture;
  @Output() sectionClick = new EventEmitter<string>();

  captureStatuses = CaptureStatus;

  constructor() {}

  ngOnInit() {}

  navClick($event, sectionId) {
    $event.preventDefault();
    this.sectionClick.emit(sectionId);
  }

  get showValidateReferral(): boolean {
    return showValidateReferral(this.capture);
  }

  get showCeReviewDetails(): boolean {
    return showCeReviewDetails(this.capture);
  }

  get showPatientDocuments(): boolean {
    return showPatientDocuments(this.capture);
  }

  get showClientPrescriptionMatches(): boolean {
    return showClientPrescriptionMatches(this.capture);
  }

  get showConsultNoteVerification(): boolean {
    return showConsultNoteVerification(this.capture);
  }

  get showValidatePrescriptionWrittenAtCe(): boolean {
    return showValidatePrescriptionWrittenAtCe(this.capture);
  }
}
