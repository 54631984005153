import { Component, OnInit, Injector } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MeService } from 'app/core/services/me.service';
import { ClientsService } from 'app/core/services/clients.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { ClientConfigurationOptionsService } from 'app/core/services/client-configuration-options.service';
import { Client } from 'app/core/models/client.model';
import { MinimalClient } from 'app/core/models/minimal-client.model';
import { ClientConfigurationOption } from 'app/core/models/client-configuration-option.model';
import { ClientConfigurationOptionKey } from 'app/core/enums/client-configuration-option-key.enum';
import { Observable, of, iif } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { obj2Html, isObject, isObjectEmpty, isNotBlank } from 'app/core/lib/js-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageViewModalComponent, ImageViewModalOptions } from 'app/shared/components/image-view-modal/image-view-modal.component';

type TableKey = ClientConfigurationOptionKey | string

@Component({
  selector: 'app-client-portal-program-overview',
  templateUrl: './client-portal-program-overview.component.html',
  styleUrls: ['./client-portal-program-overview.component.scss'],
  providers: [DatePipe]
})
export class ClientPortalProgramOverviewComponent implements OnInit {
  loading = true;
  errorMessage = '';
  client: Client;
  clientConfigurationOptions: ClientConfigurationOption[];
  tableDisplayData = { primary: {}, other: {} };

  readonly tableTitles = {
    primary: 'Primary Program Configurations',
    other: 'Other Program Configurations'
  }

  readonly primaryTableStructure: { [key: string]: TableKey[] } = {
    "Program Parameters": [
      ClientConfigurationOptionKey.serviceDateRangeInMonths,
      ClientConfigurationOptionKey.specialistEncounterTimeframeInMonths,
    ]
  };

  readonly otherTableStructure: { [key: string]: TableKey[] } = {
    "340B ESP": [
      ClientConfigurationOptionKey.espExpirationOverrideTimePeriodInDays,
      'clients.submittingToEsp',
    ],
    "Client EHR Feed File Monitoring": [
      ClientConfigurationOptionKey.patientInteractionLocationsIneligibleByDefault,
      ClientConfigurationOptionKey.clientFileOverduePrescriptionsCheckIntervalInDays,
      ClientConfigurationOptionKey.clientFileOverdueEncountersCheckIntervalInDays,
      ClientConfigurationOptionKey.clientFileOverduePrescribersCheckIntervalInDays,
      ClientConfigurationOptionKey.clientFileOverdueReferralsCheckIntervalInDays,
      'clients.clientFileMonitoringEmailRecipients',
    ],
    "Client Tasks": [
      ClientConfigurationOptionKey.clientConsultNoteTaskMinimumEstimatedValue,
      ClientConfigurationOptionKey.clientReferralTaskMinimumEstimatedValue,
      ClientConfigurationOptionKey.employedWrittenDateTimeframeInMonths,
      ClientConfigurationOptionKey.ehrAccess,
    ],
    "Direct Messaging": [
      'clients.directEnabled',
      'clients.directReceivingAddress',
      'clients.directSendingAddress',
    ],
    "Consult Note Requirements": [
      ClientConfigurationOptionKey.moveNeedsEvidenceCapturesToCeReviewEmrConsultNoteReviewRequired,
      ClientConfigurationOptionKey.disableOutboundFaxes,
      ClientConfigurationOptionKey.patientAttachmentNotRequiredToVerify,
      ClientConfigurationOptionKey.drugNotRequiredOnConsultNote,
      ClientConfigurationOptionKey.enableOutreachAttemptEvidence,
    ],
    "Referral Requirements": [
      ClientConfigurationOptionKey.bypassPrimaryCareRejectionTransitioners,
      ClientConfigurationOptionKey.referralNotRequiredToVerify,
      ClientConfigurationOptionKey.referralDateMustBeBeforeRxWrittenDate,
    ],
  };

  // only show section if the value is truthy
  readonly sectionDependencies = { "Direct Messaging": 'clients.directEnabled' }

  // These are not client config options but rather attributes right on the
  // client record that are part of the structures above.
  readonly clientAttributeStrings = {
    submittingToEsp: {
      portalName: 'Submitting to ESP',
      portalDescription: 'The client is submitting data to ESP'
    },
    clientFileMonitoringEmailRecipients: {
      portalName: 'File monitoring emails',
      portalDescription: `When a client EHR feed file has any issues or is not
        received in the expected timeframe, an alert will be sent to the listed
        email addresses`
    },
    directReceivingAddress: {
      portalName: 'Direct Secure Messaging Receiving Address',
      portalDescription: `The client provided Direct Secure Messaging address consult
        notes are sent to out of the R1 platform`
    },
    directSendingAddress: {
      portalName: 'Direct Secure Messaging Sending Address',
      portalDescription: `The Direct Secure Messaging address consult notes are sent
        from out of the R1 platform`
    },
    directEnabled: {
      portalName: 'Recieve consult notes via Direct Secure Messaging',
      portalDescription: `Consult notes collected by R1 will be delivered via
        Direct Secure Messaging instead of downloaded from the client portal`
    },
  }

  constructor(
    private injector: Injector,
    private clientsService: ClientsService,
    private meService: MeService,
    private clientConfigurationOptionsService: ClientConfigurationOptionsService,
    private authService: AuthenticationService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.loadEverything()
  }

  get liveTpas(): string[] {
    return this.client.liveThirdPartyAdministers.map((tpa) => tpa.name)
  }

  get programTypes(): string {
    const types = [];
    if (this.client.applicableToReferralClaims) { types.push("Referral Capture") }
    if (this.client.applicableToIccClaims) { types.push("Ineligible Claims Capture") }
    if (types.length === 0) { types.push("-") }
    return types.join(", ")
  }

  showTable(tableType: string) {
    return !isObjectEmpty(this.tableDisplayData[tableType])
  }

  tableSections(tableType: string) {
    return Object.keys(this.tableDisplayData[tableType])
  }

  onLogoClick(_event: MouseEvent) {
    const modalOptions = new ImageViewModalOptions();
    modalOptions.imageUrl = this.client.logoUrl;
    modalOptions.altText = 'client logo';

    const openOptions: any = {
      windowClass: 'image-view-modal',
    };

    openOptions.injector = Injector.create({
      providers: [{ provide: ImageViewModalOptions, useValue: modalOptions }],
      parent: this.injector
    })

    this.modalService.open(ImageViewModalComponent, openOptions);
  }

  private loadEverything() {
    iif(
      this.isViewingAsClient(this.authService.viewingAsClient),
      this.loadClientAndOptions(this.authService.viewingAsClient),
      this.reloadUserAndOptions()
    ).pipe(
      catchError(error => {
        this.errorMessage = error;
        console.error('Error:', error);
        return of(null);
      }),
      finalize(() => this.loading = false)
    ).subscribe(clientConfigurationOptionData => {
      this.clientConfigurationOptions = clientConfigurationOptionData.clientConfigurationOptions;
      this.buildTables();
    })
  }

  private isViewingAsClient(client: Client) {
    return () => client !== null;
  }

  private loadClientAndOptions(minimalClient: MinimalClient): Observable<any> {
    return of(minimalClient).pipe(
      switchMap(client => this.loadClient(client.id)),
      switchMap(client => this.loadConfigurationOptions(client))
    );
  }

  private reloadUserAndOptions(): Observable<any> {
    return this.loadUser().pipe(
      switchMap(user => {
        const clientId = user.defaultAllowedClientId || user.availableClients[0].id;
        return this.loadClient(clientId);
      }),
      switchMap(client => this.loadConfigurationOptions(client))
    );
  }

  private buildTables() {
    this.tableDisplayData.primary = this.tableValues(this.primaryTableStructure)
    this.tableDisplayData.other = this.tableValues(this.otherTableStructure)
  }

  private tableValues(structure: { [key: string]: string[] }) {
    const result = {}
    Object.entries(structure).forEach(([section, keys]) => {
      const sectionDependency = this.sectionDependencies[section];
      if (sectionDependency && !this.configData(sectionDependency)) { return }
      for (const key of keys) {
        const config = this.configData(key)
        if (config) {
          result[section] = result[section] || []
          config.value = this.ensureDisplayable(config.value)
          result[section].push(config)
        }
      }
    })
    return result;
  }

  private ensureDisplayable(value: any) {
    const type = typeof value
    const displables = ['string', 'number', 'boolean', null, undefined]
    if (displables.includes(type)) {
      return value
    } else if (Array.isArray(value)) {
      return value.join(", ")
    } else {
      return obj2Html(value)
    }
  }

  private configData(key: string) {
    if (key.startsWith("clients.")) {
      return this.clientAttribute(key.split(".").pop())
    } else {
      return this.clientOption(key)
    }
  }

  private clientAttribute(key: string) {
    const data = this.clientAttributeStrings[key]
    data.value = this.client[key]
    if (this.isValidValue(data.value)) {
      data.value = this.ensureDisplayable(data.value)
      return data
    } else {
      return null
    }
  }

  private clientOption(key: string) {
    const option = this.clientConfigurationOptions.find((config => config.option === key))
    if (option && this.isValidValue(option.value)) {
      return option.dated ? this.valueWithStartAndEnd(option) : option
    } else {
      return null
    }
  }

  private valueWithStartAndEnd(option: ClientConfigurationOption): ClientConfigurationOption {
    const { startAt, endAt, value } = option;

    let startAtStr = startAt ? this.datePipe.transform(startAt, 'yyyy-MM-dd') : undefined;
    if (startAtStr === "1999-12-31") { startAtStr = undefined }

    const endAtStr = endAt ? this.datePipe.transform(endAt, 'yyyy-MM-dd') : undefined;

    if (!startAtStr && !endAtStr) {
      return option;
    }

    if (isObject(value)) {
      const newValue = { ...value }

      if (startAtStr) {
        newValue["start at"] = startAtStr;
      }
      if (endAtStr) {
        newValue["end at"] = endAtStr;
      }
      option.value = newValue;
    } else {
      const newValue = { value: value };

      if (startAtStr) {
        newValue["start at"] = startAtStr;
      }
      if (endAtStr) {
        newValue["end at"] = endAtStr;
      }
      option.value = newValue;
    }

    return option;
  }

  private isValidValue(value: any) {
    return isNotBlank(value) &&
      value !== false &&
      value !== "false" &&
      (isObject(value) ? !isObjectEmpty(value) : true)
  }

  private loadUser() {
    return this.meService.get()
  }

  private loadClient(clientId: number) {
    return this.clientsService.get(clientId)
  }

  private loadConfigurationOptions(client: Client) {
    this.client = client;
    return this.clientConfigurationOptionsService.getList(client.id)
  }
}
