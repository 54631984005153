import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Office } from 'app/core/models/office.model';
import { OfficeService } from 'app/core/services/office.service';
import { Client } from 'app/core/models/client.model';
import { NavigationService } from 'app/core/services/navigation.service';
import { StateOptions } from 'app/core/options/states.opts';
import { InboundFaxCommunicationMethod } from 'app/core/enums/inbound-fax-communication-method.enum';
import { OfficeStatus } from 'app/core/enums/office-status.enum';
import { officeStatusOptions } from 'app/core/options/office-status.opts';
import { ClientsService } from 'app/core/services/clients.service';
import { mapObjectToFormGroup, applyServerErrorsToFormGroup } from 'app/core/lib/form-utils';

@Component({
  selector: 'app-capture-admin-office-new',
  styleUrls: ['./capture-admin-office-new.component.scss'],
  templateUrl: './capture-admin-office-new.component.html',
})
export class CaptureAdminOfficeNewComponent implements OnInit {
  formGroup: FormGroup;
  saving = false;
  stateOptions = StateOptions;
  communicationMethods = Object.values(InboundFaxCommunicationMethod);
  officeStatusOptions = officeStatusOptions;
  clients: Client[];

  @ViewChild('form') public form: FormGroupDirective;

  constructor(
    private formBuilder: FormBuilder,
    private officeService: OfficeService,
    private navigationService: NavigationService,
    private clientsService: ClientsService,
  ) { }

  ngOnInit() {
    const queryParams = this.navigationService.queryParams;

    const office = new Office;
    office.status = OfficeStatus.testing;
    office.fax = office.fax || queryParams.fax;

    this.formGroup = mapObjectToFormGroup(this.formBuilder, office, [
      'name',
      'aka',
      'address1',
      'address2',
      'city',
      'state',
      'zip',
      'phone',
      'phoneExtension',
      'fax',
      'website',
      'communicationMethod',
      'status',
      'employedByClientIds',
      'note',
    ]);

    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  onSubmit() {
    this.saving = true;

    this.officeService.create(this.formGroup.value).subscribe(
      office => this.handleSaveSuccess(office),
      error => this.handleSaveFailure(error)
    );
  }

  onCancelClick() {
    this.navigationService.navigateTo(`/capture-admin/offices/all`);
  }

  private handleSaveSuccess(office: Office) {
    this.navigationService.navigateTo(`/capture-admin/offices/${office.id}`);
  }

  private handleSaveFailure(response: HttpErrorResponse) {
    this.saving = false;

    if (response.status === 400) {
      applyServerErrorsToFormGroup(response, this.formGroup);
    } else {
      console.error(response);
    }
  }
}
