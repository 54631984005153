import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ClientConsultNoteTasksService } from 'app/core/services/client-consult-note-tasks.service';
import { ClientConsultNoteTask } from 'app/core/models/client-consult-note-task.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { UserPresenceService } from 'app/core/services/user-presence.service';

const shouldReloadTask = (task: ClientConsultNoteTask, taskId) => task && taskId !== task.id.toString();
const userPresenceKey = 'clientConsultNoteTask';

@Component({
  templateUrl: './client-consult-note-task.component.html',
})
export class ClientConsultNoteTaskComponent implements OnInit, OnDestroy {
  task: ClientConsultNoteTask;
  loading = true;
  formDisabled = true;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    private titleService: Title,
    private clientConsultNoteTasksService: ClientConsultNoteTasksService,
    private userPresenceService: UserPresenceService
  ) {
    this.formDisabled = this.authService.isCaptureAdminUser;

    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(): void {
    this.leaveAndUnsubscribe();
  }

  ngOnInit() {
    this.loadTask(this.taskId);
  }

  ngOnDestroy(): void {
    this.leaveAndUnsubscribe();
  }

  onTaskChange(task: ClientConsultNoteTask) {
    this.task = task;
  }

  private loadTask(id) {
    this.loading = true;

    this.clientConsultNoteTasksService.get(id).subscribe(
      task => {
        this.task = task;
        this.loading = false;
        this.updateTitle();
        this.subscribeAndJoin(this.task.id);
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
  }

  private handleRouteChange(paramsMap) {
    const params = paramsMap['params'];
    const taskId = params && params['id'];

    if (shouldReloadTask(this.task, taskId)) {
      this.leaveAndUnsubscribe();
      this.loadTask(taskId);
    }
  }

  private updateTitle() {
    const title = `R1 340B Recovery - View Task | ${this.task.patient.mrn}`;
    this.titleService.setTitle(title);
  }

  private get taskId(): number {
    return Number(this.route.snapshot.paramMap.get('id'));
  }

  public get navBaseUrl(): string {
    return `/capture-admin/referral-claims/clients/client-consult-note-tasks/${this.taskId}`;
  }

  public get displayUserPresence(): boolean {
    return !this.authService.isCaptureAdminUser;
  }

  private subscribeAndJoin(id) {
    if (!this.displayUserPresence) return;
    this.userPresenceService.subscribeAndJoin(userPresenceKey, id);
  }

  private leaveAndUnsubscribe() {
    if (!this.displayUserPresence) return;
    this.userPresenceService.leaveAndUnsubscribe(userPresenceKey, this.task.id);
  }
}
