import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Patient } from '../../../core/models/patient.model';
import { PatientSearchQuery } from '../../../core/models/patient-search-query.model';
import { PatientService, PagedPatients } from '../../../core/services/patient.service';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { InboundFaxStatus } from '../../../core/enums/inbound-fax-status.enum';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { DateUtils } from 'app/core/lib/date-utils';

@Component({
  selector: 'app-inbound-fax-select-patient',
  templateUrl: './inbound-fax-select-patient.component.html',
  styleUrls: ['./inbound-fax-select-patient.component.scss'],
})
export class InboundFaxSelectPatientComponent implements OnInit {
  patients: Patient[] = new Array<Patient>();
  hasSearched = false;
  refreshing = false;
  suggestedSearch: PatientSearchQuery;
  currentSearch: PatientSearchQuery;
  paging: LimitOffsetPaging;
  loadingMoreResults = false;
  hasMoreResults = false;

  @Input() inboundFax: InboundFax;

  @Output() public selectionChange = new EventEmitter<Patient>();

  @ViewChild('patientSearchForm', { static: true })
  patientSearchForm;

  constructor(private patientService: PatientService) {}

  get selectedPatient(): Patient {
    return this.inboundFax.patient;
  }

  get searchFormDisabled(): boolean {
    return !!this.selectedPatient || this.inboundFax.status === InboundFaxStatus.needsManualIntervention;
  }

  ngOnInit() {
    this.setSuggestedSearch();
  }

  canSuggestSearch(): boolean {
    if (!this.searchFormDisabled &&
        this.inboundFax.possiblePatientInfo.length === 1 &&
        this.inboundFax.possiblePatientInfo[0]['dob'] &&
        !isNaN(new Date(this.inboundFax.possiblePatientInfo[0]['dob']) as any)) {
      return true;
    } else {
      return false;
    }
  }

  setSuggestedSearch(): void {
    if (this.canSuggestSearch()) {
      this.suggestedSearch = new PatientSearchQuery();
      const dobString = this.inboundFax.possiblePatientInfo[0]['dob'];
      this.suggestedSearch.dob = DateUtils.toServerFormat(new Date(dobString));
    }
  }

  resetSearchForm() {
    this.patientSearchForm.resetSearchForm();
  }

  onPatientSearch(patientSearchQuery: PatientSearchQuery) {
    this.hasSearched = true;
    this.refreshing = true;
    this.patients = new Array<Patient>();
    this.paging = LimitOffsetPaging.empty;
    this.currentSearch = patientSearchQuery;
    this.currentSearch.withOutboundFax = true;
    this.loadSearchResults();
  }

  loadSearchResults() {
    this.currentSearch.clientId = this.inboundFax.client.id;
    const paging = { page: this.paging.currentPage, pageSize: this.paging.pageSize };

    this.patientService.search(this.currentSearch, paging).subscribe((data: PagedPatients) => {
      this.patients.push(...(data.patients || []));
      this.paging = data.meta?.paging || LimitOffsetPaging.empty;
      this.hasMoreResults = this.paging.currentPage < this.paging.totalPages;
      this.refreshing = false;
      this.loadingMoreResults = false;
    });
  }

  onPatientScrolled() {
    if (this.hasMoreResults) {
      this.loadingMoreResults = true;
      this.paging.currentPage++;
      this.loadSearchResults();
    }
  }

  onPatientSelect(patient: Patient) {
    this.resetSearchForm();
    this.hasSearched = false;
    this.patients = new Array<Patient>();
    this.selectionChange.emit(patient);
  }

  onPatientClear() {
    this.hasSearched = false;
    this.patients = new Array<Patient>();
    this.selectionChange.emit(null);
  }

  onReset() {
    this.hasSearched = false;
    this.patients = new Array<Patient>();
  }
}
