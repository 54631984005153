import { Component, OnInit } from '@angular/core';
import { Client } from '../../../core/models/client.model';
import { ClientsService } from '../../../core/services/clients.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientFormMode } from '../../components/client-form/client-form.component';

@Component({
  selector: 'app-capture-admin-client-edit',
  templateUrl: './capture-admin-client-edit.component.html',
  styleUrls: ['./capture-admin-client-edit.component.scss'],
})
export class CaptureAdminClientEditComponent implements OnInit {
  client: Client;
  loading = false;
  formModes = ClientFormMode;
  uploading = false;

  constructor(private clientsService: ClientsService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.init();
  }

  private init() {
    const clientId = this.route.snapshot.paramMap.get('id');
    this.loadClient(clientId);
  }

  private loadClient(clientId): void {
    this.loading = true;
    this.clientsService.get(clientId).subscribe(
      client => {
        this.client = client;
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404'], { replaceUrl: true });
        } else {
          console.error(err);
        }
      }
    );
  }
}
