import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Office } from 'app/core/models/office.model';
import { keyedStatusOptions, officeStatusOptions } from 'app/core/options/office-status.opts';
import { mapObjectToFormGroup, applyServerErrorsToFormGroup } from 'app/core/lib/form-utils';
import { OfficeService } from 'app/core/services/office.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-office-fax-status',
  templateUrl: './office-fax-status.component.html',
  styleUrls: ['./office-fax-status.component.scss'],
})
export class OfficeFaxStatusComponent implements OnInit {
  @Input() office: Office;
  @Input() clearReviewStatus = false;

  @Output() officeChanged = new EventEmitter<Office>();

  formGroup: FormGroup;
  editing = false;

  keyedStatusOptions = keyedStatusOptions;
  officeStatusOptions = officeStatusOptions;

  constructor(
    private formBuilder: FormBuilder,
    private officeService: OfficeService,
  ) { }

  ngOnInit() {
    this.initFormGroup();
  }

  onEdit($event: MouseEvent) {
    $event.preventDefault();
    this.editing = true;
  }

  onCancelEdit($event: MouseEvent) {
    $event.preventDefault();
    this.editing = false;
    this.initFormGroup();
  }

  onSubmit() {
    const attributes: any = { status: this.formGroup.value.status };

    if (this.clearReviewStatus) {
      attributes.reviewStatus = null;
    }

    this.officeService.update(this.office, attributes).subscribe(
      (office: Office) => this.handleSaveSuccess(office),
      (error: HttpErrorResponse) => this.handleSaveFailure(error)
    )
  }

  private handleSaveSuccess(office: Office) {
    this.office = office;
    this.editing = false;

    this.officeChanged.emit(office);
  }

  private handleSaveFailure(response: HttpErrorResponse) {
    if (response.status === 400) {
      applyServerErrorsToFormGroup(response, this.formGroup);
    } else {
      console.error(response);
    }
  }

  initFormGroup() {
    this.formGroup = mapObjectToFormGroup(this.formBuilder, this.office, [
      'status'
    ]);
  }
}
