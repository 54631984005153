import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './capture-admin-missing-ndc-reports.component.html',
})
export class CaptureAdminMissingNdcReportsComponent {
  reportsUrl = `${environment.captureApi.url}/missing_ndc_reports`;
  reportsCountUrl = `${environment.captureApi.url}/missing_ndc_reports/count`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    processedDate: [null],
  });
}
