import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable()
export class GenericCountService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getCount(countUrl) {
    return this.http.get<{ count: number }>(countUrl);
  }
}
