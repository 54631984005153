import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ClientConfigurationOption } from '../models/client-configuration-option.model';
import { ApiService } from './api.service';
import { ClientConfigurationOptionDefinition } from '../models/client-configuration-option-definition.model';

export class PersistableClientConfigurationOption extends ClientConfigurationOption {
  deleted: boolean;
}

@Injectable()
export class ClientConfigurationOptionsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(id: number): Observable<{ clientConfigurationOptions: ClientConfigurationOption[] }> {
    return this.http.get<{ clientConfigurationOptions: PersistableClientConfigurationOption[] }>(
      `${environment.captureApi.url}/clients/${id}/configuration_options`
    );
  }

  updateAll(
    id,
    options: PersistableClientConfigurationOption[]
  ): Observable<{ clientConfigurationOptions: ClientConfigurationOption[] }> {
    const formattedOptions: any[] = options.map(option => {
      const formattedOption: any = Object.assign({}, option);

      if (formattedOption.deleted) {
        formattedOption._destroy = true;
      }

      delete formattedOption.deleted;
      return formattedOption;
    });

    return this.http.patch<{ clientConfigurationOptions: ClientConfigurationOption[] }>(
      `${environment.captureApi.url}/clients/${id}/configuration_options`,
      {
        configurationOptionsAttributes: formattedOptions,
      }
    );
  }

  getAllDefinitions(): Observable<{ availableClientConfigurationOptions: ClientConfigurationOptionDefinition[] }> {
    return this.http.get<{ availableClientConfigurationOptions: ClientConfigurationOptionDefinition[] }>(
      `${environment.captureApi.url}/available_client_configuration_options`
    );
  }
}
