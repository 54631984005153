import { Component, OnInit } from '@angular/core';
import { UserStat } from 'app/core/models/user-stat.model';
import { MyStatsService } from 'app/core/services/my-stats.service';

@Component({
  selector: 'app-user-stats',
  templateUrl: './user-stats.component.html',
  styleUrls: ['./user-stats.component.scss'],
})
export class UserStatsComponent implements OnInit {
  faxStat: UserStat;
  gatherEvidenceBlockedStat: UserStat;
  consultNoteStat: UserStat;
  verifiedCaptureStat: UserStat;

  constructor(
    private myStatsService: MyStatsService,
  ) { }

  ngOnInit() {
    this.init();
  }

  private init() {
    this.myStatsService.getFaxes().subscribe(result => {
      this.faxStat = result;
    });

    this.myStatsService.getGatherEvidenceBlocked().subscribe(result => {
      this.gatherEvidenceBlockedStat = result;
    });

    this.myStatsService.getConsultNote().subscribe(result => {
      this.consultNoteStat = result;
    });

    this.myStatsService.getVerifiedCapture().subscribe(result => {
      this.verifiedCaptureStat = result;
    });
  }

}
