<ng-container [ngSwitch]="reason">
  <span *ngSwitchCase="verificationReasons.prescriberReferral">
    Reference to the prescriber
  </span>
  <span *ngSwitchCase="verificationReasons.prescriberOfficeReferral">
    Reference to the prescriber’s office
  </span>
  <span *ngSwitchCase="verificationReasons.specialityReferral">
    <span>
      Reference to the prescriber's specialty<span *ngIf="specialityReferralSpeciality">:</span>
      <div *ngIf="specialityReferralSpeciality">{{ specialityReferralSpeciality }}</div>
    </span>
  </span>
  <span *ngSwitchCase="verificationReasons.other">
    Other sufficient supporting documentation is present<span *ngIf="otherNote">:</span>
    <div *ngIf="otherNote">{{ otherNote }}</div>
  </span>
  <span *ngSwitchCase="rejectionReasons.ignorePatient">
    Do not show me any further captures for patient
    <strong>{{ patient.fullName }} DOB {{ patient.dob | date: 'MM/dd/yyyy' }}</strong>.
  </span>
  <span *ngSwitchCase="rejectionReasons.ignorePrescriber">
    Do not show me any further captures from prescriber <strong>{{ prescriber.fullName }}</strong>.
  </span>
  <span *ngSwitchCase="rejectionReasons.ignorePatientPrescriber">
    Do not show me any further captures from prescriber <strong>{{ prescriber.fullName }}</strong> for patient
    <strong>{{ patient.fullName }} DOB {{ patient.dob | date: 'MM/dd/yyyy' }}</strong>.
  </span>
</ng-container>
