import { Pipe, PipeTransform } from '@angular/core';
import { keyedClaimSourceOptions } from '../../core/options/claim-source.opts';

@Pipe({
  name: 'claimSource',
})
export class ClaimSourcePipe implements PipeTransform {
  transform(source: string): string {
    if (source) {
      const option: { display: string } = keyedClaimSourceOptions[source];
      return option ? `${option.display}` : source.toUpperCase();
    }
  }
}
