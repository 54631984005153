import { Directive, OnInit } from '@angular/core';
import { ClientReferralTasksService } from 'app/core/services/client-referral-tasks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ClientTaskHistoryItem } from 'app/core/models/client-task-history-item.model';
import { ClientConsultNoteTasksService } from 'app/core/services/client-consult-note-tasks.service';
import { ClientPrescriberTasksService } from 'app/core/services/client-prescriber-tasks.service';

@Directive()
export abstract class BaseClientTaskHistoryComponent implements OnInit {
  historyItems: ClientTaskHistoryItem[] = [];
  loading = true;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected titleService: Title,
    protected clientTasksService:
      ClientReferralTasksService | ClientConsultNoteTasksService | ClientPrescriberTasksService
  ) {
    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  ngOnInit() {
    this.loadHistoryItems(this.taskId);
  }

  private loadHistoryItems(id) {
    this.loading = true;

    this.clientTasksService.getClientTaskHistoryItems(id).subscribe(
      results => {
        this.historyItems = results.reverse();
        this.loading = false;
        this.updateTitle();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
  }

  protected handleRouteChange(paramsMap) {
    const params = paramsMap['params'];
    const taskId = params && params['id'];

    this.loadHistoryItems(taskId);
  }

  private updateTitle() {
    const title = `R1 340B Recovery - Referral Task ${this.taskId} History`;
    this.titleService.setTitle(title);
  }

  public get taskId(): number {
    return Number(this.route.snapshot.paramMap.get('id'));
  }

  public get navBaseUrl(): string {
    return this.router.url.replace('/history-items', '');
  }
}
