import { Component, Input, OnDestroy } from '@angular/core';
import { DateTime } from 'luxon';
import { Patient } from '../../../core/models/patient.model';
import { OutboundFaxesResult, PatientService } from '../../../core/services/patient.service';
import { OutboundFax } from '../../../core/models/outbound-fax.model';
import { OutboundFaxStatus } from '../../../core/enums/outbound-fax-status.enum';
import { OutboundFaxesService } from 'app/core/services/outbound-faxes.service';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';

const OutboundFaxLimit = 10;
const CheckInterval = 3000;
const ScheduledSoonMinutes = 15;

const isActive = (outboundFax: OutboundFax) => (
  outboundFax.status === OutboundFaxStatus.pendingScheduling ||
  outboundFax.status === OutboundFaxStatus.queued ||
  (outboundFax.status === OutboundFaxStatus.scheduled &&
    DateTime.fromJSDate(new Date(outboundFax.scheduledTime)) < DateTime.now().plus({ minutes: ScheduledSoonMinutes }))
);

const trackFaxItemsBy = (_index: number, item: OutboundFax) => `${item.id}-${item.status}`;

const visibleStatuses = [
  OutboundFaxStatus.pendingScheduling,
  OutboundFaxStatus.scheduled,
  OutboundFaxStatus.queued,
  OutboundFaxStatus.skipped,
  OutboundFaxStatus.sent,
  OutboundFaxStatus.failed,
  OutboundFaxStatus.canceled,
];

@Component({
  selector: 'app-patient-outbound-faxes',
  templateUrl: './patient-outbound-faxes.component.html',
  styleUrls: ['./patient-outbound-faxes.component.scss'],
})
export class PatientOutboundFaxesComponent implements OnDestroy {
  private _patient: Patient;

  loadingFaxes = false;
  outboundFaxes: OutboundFax[] = [];
  outboundFaxStatuses = OutboundFaxStatus;
  trackItemsBy = trackFaxItemsBy;
  nextCheckTimeout = null;
  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;

  constructor(private patientService: PatientService, private outboundFaxesService: OutboundFaxesService) { }

  ngOnDestroy() {
    this.clearNextCheckTimeout();
  }

  @Input()
  set patient(patient: Patient) {
    this._patient = patient;
    this.loadPatientFaxes();
  }

  get patient(): Patient {
    return this._patient;
  }

  refreshPatientFaxes() {
    this.clearNextCheckTimeout();
    this.loadPatientFaxes();
  }

  cancel($event, outboundFax) {
    $event.preventDefault();

    this.outboundFaxesService.cancel(outboundFax.id).subscribe((result: OutboundFax) => {
      this.loadPatientFaxes(false);
    });
  }

  private clearNextCheckTimeout() {
    if (this.nextCheckTimeout) {
      clearTimeout(this.nextCheckTimeout);
    }
  }

  private loadPatientFaxes(showSpinner = true): void {
    this.loadingFaxes = showSpinner;
    this.patientService
      .getOutboundFaxes(this.patient.id, visibleStatuses, OutboundFaxLimit)
      .subscribe((results: OutboundFaxesResult) => {
        this.loadingFaxes = false;
        this.outboundFaxes = results.outboundFaxes;

        const anyActive: boolean = this.outboundFaxes.some(isActive);

        if (anyActive) {
          this.nextCheckTimeout = setTimeout(() => this.loadPatientFaxes(false), CheckInterval);
        }
      });
  }
}
