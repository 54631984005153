export const getSelectedValues = (selections: Record<string, unknown>, enumType): string[] => {
  const enumValues = Object.values(enumType);
  const selected = Object.entries(selections)
    .filter(o => {
      const [k, v] = o;
      return v && enumValues.some(rv => rv === k);
    })
    .map(o => o[0].toString());

  return selected || [];
};

export const getKeyedSelectedValues = (selections: [], enumType) => Object.values(enumType).reduce((o, k: string) => {
    o[k] = !!selections.find(r => r === k);
    return o;
  }, {});

export const anyValueSelected = (selections: Record<string, unknown>, enumType) =>
  getSelectedValues(selections, enumType).length > 0;
