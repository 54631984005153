<div class="p-2">
  <ng-container *ngIf="!refreshing; else: spinner" class="faxes-detail">
    <app-inbound-fax-header [inboundFax]="inboundFax" />
    <div class="mt-2">
      <app-inbound-fax-content [inboundFax]="inboundFax">
        <app-inbound-fax-process-form [inboundFax]="inboundFax" *ngIf="!inboundFax.processed" />

        <app-inbound-fax-view-processed [inboundFax]="inboundFax" *ngIf="inboundFax.processed" />
      </app-inbound-fax-content>
    </div>
  </ng-container>
  <ng-template #spinner> <mat-spinner /> </ng-template>
</div>
