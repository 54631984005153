import { UserQueueSettings } from '../../interfaces/user-queue-settings';
import { LimitOffsetPaging } from '../paged-results/limit-offset-paging.model';

export class OfficeOutboundFaxListSettings {
  filters: null;
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
}

export class OfficeOutboundFaxQueueSettings extends OfficeOutboundFaxListSettings implements UserQueueSettings {
  position: number;
}
