import { Component } from '@angular/core';
import { ClientReferralTaskChoice } from 'app/core/enums/client-referral-task-choice.enum';
import { ClientTaskHistoryItemTypeBaseComponent } from '../client-task-history-item-type-base/client-task-history-item-type-base.component';
import { isEmpty } from 'lodash-es'
import { ClientConsultNoteTaskChoice } from 'app/core/enums/client-consult-note-task-choice.enum';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';

@Component({
  selector: 'app-client-task-history-item-type-submitted',
  templateUrl: './client-task-history-item-type-submitted.component.html',
})
export class ClientTaskHistoryItemTypeSubmittedComponent extends ClientTaskHistoryItemTypeBaseComponent {
  verificationReasons = ReferralVerificationReason;

  get title() {
    return 'Submitted';
  }

  get isReferralTask() {
    return this.clientTaskHistoryItem.clientTaskType === 'ClientReferralTask';
  }

  get isConsultNoteTask() {
    return this.clientTaskHistoryItem.clientTaskType === 'ClientConsultNoteTask';
  }

  get isPrescriberTask() {
    return this.clientTaskHistoryItem.clientTaskType === 'ClientPrescriberTask';
  }

  get clientReferralTaskChoices() {
    return ClientReferralTaskChoice;
  }

  get clientConsultNoteTaskChoices() {
    return ClientConsultNoteTaskChoice;
  }

  get clientPrescriberTaskChoices() {
    return {
      yes: 'yes',
      no: 'no'
    };
  }

  get submissionTitle() {
    return `${this.taskName} task submitted by ${this.username} with response ${this.humanizeChoice.toUpperCase()}`
  }

  get humanizeChoice() {
    if (this.choice === 'assign_back_to_par8o') {
      return 'R1 Outreach';
    } else if (this.choice === 'prescriber_is_ce_provider') {
      return 'Prescriber is a client provider';
    } else {
      return this.choice;
    }
  }

  get hasAdditionDetails() {
    return !isEmpty(this.additionalDetails);
  }

  get additionalDetails() {
    return this.clientTaskHistoryItem.data.notes;
  }

  get referralDates() {
    return this.clientTaskHistoryItem.data.referralDates;
  }

  get referralRejectedReason() {
    if (this.clientTaskHistoryItem.data.choiceReasons && this.clientTaskHistoryItem.data.choiceReasons[0]) {
      return this.clientTaskHistoryItem.data.choiceReasons[0];
    } else {
      return 'prescription';
    }
  }

  get consultNoteDates() {
    return this.clientTaskHistoryItem.data.consultNoteDates;
  }

  get verifiedPrescriptionIds() {
    return this.clientTaskHistoryItem.data.verifiedPrescriptionIds;
  }

  get consultNoteRejectedReason() {
    if (this.clientTaskHistoryItem.data.choiceReasons && this.clientTaskHistoryItem.data.choiceReasons[0]) {
      return this.clientTaskHistoryItem.data.choiceReasons[0];
    } else {
      return 'prescription';
    }
  }
}
