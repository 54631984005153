import { Component } from '@angular/core';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';
import { keyedReasonOptions } from '../../../core/options/capture-status-reason.opts';
import { displayCaptureStatus } from 'app/core/options/capture-status.opts';

@Component({
  selector: 'app-capture-history-item-type-updated',
  templateUrl: './capture-history-item-type-updated.component.html',
})
export class CaptureHistoryItemTypeUpdatedComponent extends CaptureHistoryItemTypeBaseComponent {
  constructor() {
    super();
  }

  get displayStatus() {
    return displayCaptureStatus(this.captureHistoryItem.data.status);
  }

  get statusReasons() {
    return this.mapStatusReasons().join(', ');
  }

  get additionalDetails() {
    return this.captureHistoryItem.data.additionalDetails;
  }

  get verifiedAtChanges() {
    return this.captureHistoryItem.data.verifiedAt;
  }

  get verifiedInvalidReviewedChanges() {
    return this.captureHistoryItem.data.hasOwnProperty('verifiedInvalidReviewed');
  }

  get verifiedInvalidReviewedEnabled() {
    return this.captureHistoryItem.data.verifiedInvalidReviewed;
  }

  protected mapStatusReasons() {
    const statusReasons = this.captureHistoryItem.data.statusReasons || [];

    const statusReasonsForDisplay = statusReasons.map(reason => {
      if (keyedReasonOptions[reason]) {
        return keyedReasonOptions[reason].display;
      } else {
        return reason;
      }
    });

    return statusReasonsForDisplay;
  }
}
