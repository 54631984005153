import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportOption } from 'app/core/models/report-option.model';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { Client } from 'app/core/models/client.model';
import { ClientsService, ClientListFilters } from 'app/core/services/clients.service';

export class ReportClientOption implements ReportOption {
  value: Client[] | Client = null;

  constructor(value) {
    this.value = value;
  }

  toPostData(options = { paramName: 'clientIds' }) {
    const value = Array.isArray(this.value) ? this.value.map(client => client.id) : this.value.id;

    return { [options.paramName]: value };
  }
}

@Component({
  selector: 'app-report-client-option',
  templateUrl: './report-client-option.component.html',
  styleUrls: ['./report-client-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportClientOptionComponent),
    },
  ],
})
export class ReportClientOptionComponent extends ReportAbstractOptionComponent implements OnInit {
  @Input() label = 'Filter by Client';
  @Input() multiple = true;

  @Input() clientFilters: ClientListFilters;

  selectedClients: Client[] | Client;
  clients: Client[];

  constructor(private clientsService: ClientsService) {
    super();
  }

  ngOnInit() {
    this.loadClients();
  }

  private loadClients() {
    this.clientsService.getList(null, this.clientFilters).subscribe(result => {
      this.clients = result.clients;
    });
  }

  protected updateInternalState(model: ReportClientOption) {
    if (model) {
      this.selectedClients = model.value;
    }
  }

  protected determineModelStateOnChange(): ReportClientOption {
    if (this.selectedClients && (!Array.isArray(this.selectedClients) || this.selectedClients.length > 0)) {
      return new ReportClientOption(this.selectedClients);
    } else {
      return null;
    }
  }
}
