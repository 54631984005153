import { Component, Input } from '@angular/core';
import { Report, AppliedReportOptions } from 'app/core/models/report.model';

@Component({
  selector: 'app-report-applied-text-option',
  templateUrl: './report-applied-text-option.component.html',
})
export class ReportAppliedTextOptionComponent {
  @Input() name;
  @Input() label;
  @Input() options: AppliedReportOptions;

  constructor() {}

  get value() {
    return this.options && this.options[this.name];
  }
}
