/* eslint-disable @angular-eslint/component-selector, @angular-eslint/no-input-rename */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort } from '../../../core/models/paged-results/limit-offset-paging.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: '[app-sortable-column]',
  templateUrl: './sortable-column.component.html',
  styleUrls: ['./sortable-column.component.scss'],
})
export class SortableColumnComponent implements OnInit {
  @Input('app-sortable-column') name: string;
  @Input() sort: Sort;

  @Output() public sortChange = new EventEmitter<Sort>();

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {}

  get sortingAsc() {
    return this.sort.col === this.name && this.sort.dir === 'asc';
  }

  get sortingDesc(): boolean {
    return this.sort.col === this.name && this.sort.dir === 'desc';
  }

  onSortClick($event) {
    $event.preventDefault();
    const sort = new Sort();
    sort.col = this.name;
    sort.dir = this.sort.dir === 'asc' ? 'desc' : 'asc';

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sortCol: sort.col, sortDir: sort.dir },
      queryParamsHandling: 'merge',
    });

    this.sortChange.emit(sort);
  }
}
