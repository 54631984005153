import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpRequest } from '@angular/common/http';

class PendingHttpRequest {
  constructor(public id: string, public method: string, public url: string) {}

  public matches(method: string, pattern: RegExp) {
    if (method.trim().toUpperCase() === this.method.trim().toUpperCase()) {
      return pattern.test(this.url);
    }

    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PendingHttpRequestsService {
  private requestsChangedSource = new Subject();
  private pendingHttpRequests: Record<string, PendingHttpRequest>;

  public requestsChanged = this.requestsChangedSource.asObservable();

  public add(id: string, request: HttpRequest<any>) {
    const pendingHttpRequests = { ...this.pendingHttpRequests };
    pendingHttpRequests[id] = new PendingHttpRequest(id, request.method, request.urlWithParams);

    this.pendingHttpRequests = pendingHttpRequests;
    this.requestsChangedSource.next();
  }

  public remove(id: string) {
    const pendingHttpRequests = { ...this.pendingHttpRequests };
    delete pendingHttpRequests[id];

    this.pendingHttpRequests = pendingHttpRequests;
    this.requestsChangedSource.next();
  }

  public isPending(method: string, pattern: RegExp) {
    return Object.values(
      this.pendingHttpRequests).some((pendingHttpRequest: PendingHttpRequest) =>
        pendingHttpRequest.matches(method, pattern)
    );
  }
}
