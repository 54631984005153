<div class="patient-details">
  <div class="row mb-2">
    <div class="col">
      <div><strong>ENC-MRN</strong></div>
      <div>{{ capture.patient.mrn }}</div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <div><strong>CE-PATIENT</strong></div>
      <div>{{ capture.patient.fullName }}</div>
    </div>
    <div class="col">
      <div><strong>RX-PATIENT</strong></div>
      <div>{{ capture.candidate.patientFullName }}</div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <div><strong>CE-DOB</strong></div>
      <div>{{ capture.patient.dob | date: 'MM/dd/yyyy' }}</div>
    </div>
    <div class="col">
      <div><strong>RX-DOB</strong></div>
      <div>{{ capture.candidate.patientDob | date: 'MM/dd/yyyy' }}</div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col">
    </div>
  </div>
</div>
