import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { OutreachAttemptEvidence } from 'app/core/models/outreach-attempt-evidence.model';
import { canModifyPatientAttachment } from 'app/core/lib/verification-utils';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-outreach-attempt-evidence-section',
  templateUrl: './outreach-attempt-evidence-section.component.html',
  styleUrls: ['./outreach-attempt-evidence-section.component.scss'],
})
export class OutreachAttemptEvidenceSectionComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() patientAttachments: PatientAttachment[];

  @Output() newPatientAttachment = new EventEmitter<PatientAttachment>();

  outreachAttemptEvidence: OutreachAttemptEvidence;

  showSection = false;
  showAddOutreachAttemptEvidenceForm = false;

  constructor(private authService: AuthenticationService) {}

  ngOnChanges() {
    if (this.patientAttachments) {
      const bestOutreachAttemptEvidenceAttachment =
        this.determineBestOutreachAttemptEvidenceAttachment(this.patientAttachments);

      this.outreachAttemptEvidence = bestOutreachAttemptEvidenceAttachment?.outreachAttemptEvidence;
    }

    this.showSection =
      this.capture.client.outreachAttemptEvidenceEnabled &&
      canModifyPatientAttachment(this.authService, this.capture);
  }

  onAddOutReachAttemptEvidenceClick() {
    this.showAddOutreachAttemptEvidenceForm = true;
  }

  onAddOutReachAttemptEvidenceCanceled() {
    this.showAddOutreachAttemptEvidenceForm = false;
  }

  onNewPatientAttachment(newPatientAttachment: PatientAttachment) {
    this.showAddOutreachAttemptEvidenceForm = false;

    this.newPatientAttachment.emit(newPatientAttachment);
  }

  private determineBestOutreachAttemptEvidenceAttachment(patientAttachments: PatientAttachment[]) {
    if (this.capture.verifyingPatientAttachmentId) {
      return patientAttachments.find(attachment => attachment.id === this.capture.verifyingPatientAttachmentId);
    }

    return patientAttachments.find(attachment =>
      attachment.id === this.capture.bestOutreachAttemptEvidencePatientAttachmentId
    );
  }
}
