import { Pipe, PipeTransform } from '@angular/core';
import { CaptureUserAuditOutcomeStatus } from '../../core/enums/capture-user-audit-outcome-status.enum';
import {
  CaptureUserAuditOutcomeStatusOption,
  keyedOutcomeStatusOptions,
} from '../../core/options/capture-user-audit-outcome-status.opts';

@Pipe({
  name: 'captureUserAuditOutcomeStatus',
})
export class CaptureUserAuditOutcomeStatusPipe implements PipeTransform {
  transform(value: CaptureUserAuditOutcomeStatus): string {
    const option = keyedOutcomeStatusOptions[value];

    if (option) {
      return option.display;
    } else {
      return value;
    }
  }
}
