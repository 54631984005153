<div *ngIf="!refreshing; else: spinner">
  <h3>Inbound Call Log</h3>
  <div class="row mb-3">
    <div class="col">
      <div><strong>CLIENT</strong></div>
      <div>{{ inboundCall.client.humanizedName }}</div>
    </div>
    <div class="col">
      <div><strong>OUTBOUND FAX</strong></div>
      <div>{{ inboundCall.outboundFaxDate | date: 'MM/dd/yyyy' }}</div>
      <div>
        <small><strong><app-phone-number [number]="inboundCall.outboundFaxNumber" /></strong></small>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <div><strong>PATIENT</strong></div>
      <div>
        <app-patient-name [patient]="inboundCall.patient" />
      </div>
    </div>
    <div class="col">
      <div><strong>PRESCRIBER</strong></div>
      <div><app-prescriber-name [prescriber]="inboundCall.prescriber" /></div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <div><strong>OUTCOME</strong></div>
      <div>{{ keyedReasonOptions[inboundCall.reason].display }}</div>
    </div>
    <div class="col">
      <div><strong>COMMUNICATION METHOD</strong></div>
      <div>{{ inboundCall.communicationMethod | blankValueDash }}</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <div><strong>ADDITIONAL DETAILS</strong></div>
      <div>{{ inboundCall.notes | blankValueDash }}</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <div><strong>CREATED AT</strong></div>
      <div>{{ inboundCall.createdAt | date: 'M/d/yyyy h:mm a' }}</div>
    </div>
    <div class="col">
      <div><strong>CREATED BY</strong></div>
      <div>{{ inboundCall.submittedBy.fullName }}</div>
    </div>
  </div>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
