import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../core/models/user.model';
import { isCePortalUser, UserType } from '../../../core/enums/user-type.enum';

@Component({
  selector: 'app-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.scss'],
})
export class UserTypeComponent implements OnInit {
  @Input() user: User;

  accessList = '';

  constructor() {}

  ngOnInit() {
    this.init();
  }

  private init() {
    if (isCePortalUser(this.user.userType)) {
      const availableClients = this.user.availableClients || [];
      this.accessList = availableClients
        .map(c => c.humanizedName || c.name)
        .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
        .join(', ');
    } else if (this.user.userType === UserType.partnerPortal) {
      const allowedSources = this.user.allowedSources || [];
      this.accessList = allowedSources.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' })).join(', ');
    }
  }
}
