<div class="card mt-1">
  <div class="card-body">
    <app-verification-heading
      [patientAttachment]="patientAttachment"
      title="Validate Specialist Encounters"
    />
    <app-specialist-encounter-form
      [capture]="capture"
      [patientAttachment]="patientAttachment"
    />
  </div>
</div>
