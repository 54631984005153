import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { filter, slice } from 'lodash-es';
import { User } from 'app/core/models/user.model';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { UserPresenceService } from 'app/core/services/user-presence.service';

@Component({
  selector: 'app-user-presence',
  templateUrl: './user-presence.component.html',
  styleUrls: ['./user-presence.component.scss']
})
export class UserPresenceComponent implements OnInit, OnChanges {
  @Input() category: string;
  @Input() targetId: number | string;

  private _users = [];
  private ownUser: User;

  constructor(
    private authService: AuthenticationService,
    private userPresence: UserPresenceService
  ) {}

  ngOnInit(): void {
    this.ownUser = this.authService.currentUser;
    this.userPresence.getMessages(this.category, this.targetId).subscribe(
      (message) => this.readUsers(message)
    )
  }

  ngOnChanges(): void {
    this.userPresence.getMessages(this.category, this.targetId).subscribe(
      (message) => this.readUsers(message)
    )
  }

  get users(): Record<string, any>[] {
    return slice(this._users, 0, 5);
  }

  get overflow(): number {
    if (this._users.length > 5) {
      return this._users.length - 5;
    } else {
      return 0;
    }
  }

  private readUsers(message): void {
    if (message.users) {
      this._users = filter(message.users, (u) => u.id !== this.ownUser?.id);
    }
  }

}
