import { Component, Input } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import {
  showCeReviewDetails,
  showClientConsultNoteTask,
  showClientPrescriberTask,
  showClientReferralTask,
} from '../../../core/lib/capture-nav-util';

@Component({
  selector: 'app-ce-review-details',
  templateUrl: './ce-review-details.component.html',
})
export class CeReviewDetailsComponent {
  @Input() capture: Capture;

  constructor() {}

  get showCeReviewDetails(): boolean {
    return showCeReviewDetails(this.capture);
  }

  get showClientPrescriberTask(): boolean {
    return showClientPrescriberTask(this.capture);
  }

  get showClientConsultNoteTask(): boolean {
    return showClientConsultNoteTask(this.capture);
  }

  get showClientReferralTask(): boolean {
    return showClientReferralTask(this.capture);
  }
}
