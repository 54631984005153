import { Component, Input, OnInit } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-inbound-fax-content',
  templateUrl: './inbound-fax-content.component.html',
})
export class InboundFaxContentComponent implements OnInit {
  @Input() inboundFax: InboundFax;

  inboundFaxForInfo: InboundFax;
  pdfFileType: PdfFileType = PdfFileType.inboundFax;

  constructor(private authService: AuthenticationService) {}

  usePdfKitViewer: boolean;

  ngOnInit() {
    this.inboundFaxForInfo = { ...this.inboundFax };
    this.usePdfKitViewer = environment.featureData?.usePdfKitViewer && this.authService.isCaptureAdminUser;
  }
}
