import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { isNull } from 'lodash-es';
import { ApiService } from './api.service';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { Cursor, CursorBasedPagingMeta, NextResult } from '../models/paged-results/cursor-based-paging.model';
import { CaptureQueueResult } from '../models/capture-lists/capture-queue-result.model';
import { InternalConsultantCaptureCursorType } from '../enums/internal-consultant-capture-cursor-type.enum';
import { environment } from '../../../environments/environment';
import { InternalConsultantCaptureFilterName } from '../enums/internal-consultant-capture-filter-name.enum';
import { CursorParams } from '../enums/cursor-params.enum';
import { Capture } from '../models/capture.model';
import { UserSettingsService } from './user-settings.service';
import { UserSettings } from '../enums/user-settings.enum';
import {
  InternalConsultantCaptureFilters,
  InternalConsultantCaptureSettings,
} from '../models/user-settings/internal-consultant-capture-settings.model';
import {
  appendFilterParam,
  appendFilterListParams,
  appendFilterFlagParam,
} from "../lib/url-utils";


export class PagedInternalConsultantCaptureResults
  implements PagedResults<CaptureQueueResult, CursorBasedPagingMeta<InternalConsultantCaptureCursorType>> {
  captureQueueResults: CaptureQueueResult[] = new Array<CaptureQueueResult>();
  meta: CursorBasedPagingMeta<InternalConsultantCaptureCursorType> =
    new CursorBasedPagingMeta<InternalConsultantCaptureCursorType>();

  get records() {
    return this.captureQueueResults;
  }
}

@Injectable()
export class InternalConsultantCapturesService extends ApiService {

  constructor(private http: HttpClient, private userSettingsService: UserSettingsService) {
    super();
  }

  getUserSettings(): InternalConsultantCaptureSettings {
    const queueSettings = this.userSettingsService.get<InternalConsultantCaptureSettings>
      (UserSettings.captureAdminInternalConsultantCaptureSettings);

    if (!queueSettings) {
      return new InternalConsultantCaptureSettings();
    } else {
      return queueSettings;
    }
  }

  saveUserSettings(settings: InternalConsultantCaptureSettings) {
    this.userSettingsService.save<InternalConsultantCaptureSettings>
      (UserSettings.captureAdminInternalConsultantCaptureSettings, settings);
  }

  getCount(): Observable<{ count: number }> {
    const settings: InternalConsultantCaptureSettings = this.getUserSettings();
    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);

    return this.http.get<{ count: number }>(
      `${environment.captureApi.url}/internal_consultant_captures/count?${filterParams.toString()}`
    );
  }

  getResults(cursor: Cursor<InternalConsultantCaptureCursorType> = null, limit = null):
    Observable<PagedInternalConsultantCaptureResults> {

    const settings: InternalConsultantCaptureSettings = this.getUserSettings();
    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);

    if (limit) {
      filterParams.append('limit', limit);
    }

    let cursorParams: URLSearchParams = null;
    if (cursor) {
      cursorParams = this.buildCursorParams(cursor.name, cursor.id, cursor.value);
    } else {
      cursorParams = this.buildCursorParams(settings.cursorType);
    }

    return this.http.get<PagedInternalConsultantCaptureResults>(
      `${environment.captureApi.url}/internal_consultant_captures?${filterParams.toString()}&${cursorParams.toString()}`
    );
  }

  getNextResult(capture: Capture, additionalFilters: InternalConsultantCaptureFilters = null): Observable<NextResult> {
    const settings: InternalConsultantCaptureSettings = this.getUserSettings();
    const cursor = this.cursorFromCapture(settings.cursorType, capture);

    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);
    const cursorParams: URLSearchParams = this.buildCursorParams(cursor.name, cursor.id, cursor.value);

    return this.http
      .get<{ nextResult: NextResult }>(
        `${
          environment.captureApi.url
        }/internal_consultant_captures/next?${filterParams.toString()}&${cursorParams.toString()}`
      )
      .pipe(
        mergeMap((resp: { nextResult: NextResult }) => {
          if (resp.nextResult) {
            return of(resp.nextResult);
          } else {
            return this.handleNoResult(filterParams, cursorParams);
          }
        })
      );
  }

  private handleNoResult(filterParams: URLSearchParams, cursorParams: URLSearchParams): Observable<NextResult> {
    cursorParams.delete(CursorParams.id);
    cursorParams.delete(CursorParams.value);

    return this.http
      .get<{ nextResult: NextResult }>(
        `${environment.captureApi.url}/internal_consultant_captures/next?${filterParams.toString()}&${cursorParams.toString()}`
      )
      .pipe(
        mergeMap((resp: { nextResult: NextResult }) => {
          if (resp) {
            return of(resp.nextResult);
          } else {
            return of(null);
          }
        })
      );
  }

  private buildFilterParams(filters: InternalConsultantCaptureFilters): URLSearchParams {
    const urlSearchParams = new URLSearchParams();

    appendFilterListParams(InternalConsultantCaptureFilterName.clientIds, filters.clientIds, urlSearchParams);
    appendFilterListParams(InternalConsultantCaptureFilterName.reasons, filters.reasons, urlSearchParams);
    appendFilterListParams(InternalConsultantCaptureFilterName.sources, filters.sources, urlSearchParams);
    appendFilterFlagParam(InternalConsultantCaptureFilterName.specialtyStore, filters.specialtyStore, urlSearchParams);
    appendFilterFlagParam(InternalConsultantCaptureFilterName.highValue, filters.highValue, urlSearchParams);
    appendFilterParam(
      InternalConsultantCaptureFilterName.expiringWithinDays,
      filters.expiringWithinDays, urlSearchParams
    );
    appendFilterListParams(
      InternalConsultantCaptureFilterName.assignedUserIds,
      filters.assignedUserIds,
      urlSearchParams
    );
    appendFilterFlagParam(
      InternalConsultantCaptureFilterName.activeClientsOnly,
      filters.activeClientsOnly,
      urlSearchParams
    );

    return urlSearchParams;
  }

  private buildCursorParams(cursorType: InternalConsultantCaptureCursorType, id: number = null, value: string = null) {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append(CursorParams.name, cursorType);

    if (!isNull(id)) {
      urlSearchParams.append(CursorParams.id, id.toString());
    }

    if (!isNull(value)) {
      urlSearchParams.append(CursorParams.value, value);
    }

    return urlSearchParams;
  }

  private cursorFromCapture(cursorType: InternalConsultantCaptureCursorType, capture: Capture):
    Cursor<InternalConsultantCaptureCursorType> {

    const cursor = new Cursor<InternalConsultantCaptureCursorType>();
    cursor.name = cursorType;
    cursor.id = capture.id;
    cursor.value = this.determineCursorValue(cursor.name, capture);
    return cursor;
  }

  private determineCursorValue(cursorType: InternalConsultantCaptureCursorType, capture: Capture): string {
    switch (cursorType) {
      case InternalConsultantCaptureCursorType.mostRecentlyCreated:
        return capture.createdAt.toString();
      case InternalConsultantCaptureCursorType.highestEstimatedValue:
        return capture.candidate.estimatedValue.toString();
      default:
        return null;
    }
  }
}
