<form novalidate (keydown.enter)="onFormEnter($event)">
  <div class="dates px-3 py-1 border bg-white rounded-0">
    <ng-container *ngFor="let verification of verifications; let i = index">
      <app-client-referral-task-date
        [verification]="verification"
        [position]="i"
        [formDisabled]="formDisabled"
        (updateVerification)="onUpdate($event)"
        (removeVerification)="onRemove($event)"
      />
    </ng-container>
  </div>
  <div class="mt-2">
    <button [disabled]="!canAddVerifications || formDisabled"
            class="btn btn-sm btn-primary"
            (click)="onAddClick($event)">
      <fa-icon class="text-white" [icon]="['fas', 'plus-circle']" />
      Add Referral
    </button>
  </div>
</form>
