import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientConsultNoteVerification } from '../../../core/models/client-consult-note-verification.model';

@Component({
  selector: 'app-client-consult-note-task-dates',
  templateUrl: './client-consult-note-task-dates.component.html',
  styleUrls: ['./client-consult-note-task-dates.component.scss'],
})
export class ClientConsultNoteTaskDatesComponent {
  @Input() verifications: ClientConsultNoteVerification[];
  @Input() formDisabled : boolean;

  @Output() updateVerification = new EventEmitter<{ id: number; encounterDate: string }>();
  @Output() removeVerification = new EventEmitter<number>();
  @Output() addVerification = new EventEmitter();

  onUpdate(verification: { id: number; encounterDate: string }) {
    this.updateVerification.emit(verification);
  }

  onRemove(id: number) {
    this.removeVerification.emit(id);
  }

  onFormEnter($event) {
    $event.preventDefault();
  }

  onAddClick($event) {
    $event.preventDefault();
    this.addVerification.emit();
  }
}
