import { Component, Input } from '@angular/core';
import { ClientTaskHistoryItemActions } from 'app/core/enums/client-task-history-item-actions.enum';
import { ClientTaskHistoryItem } from 'app/core/models/client-task-history-item.model';

@Component({
  selector: 'app-client-task-history-item-list',
  templateUrl: './client-task-history-item-list.component.html'
})
export class ClientTaskHistoryItemListComponent  {
  @Input() historyItems: ClientTaskHistoryItem[];

  clientTaskHistoryItemActions = ClientTaskHistoryItemActions;
}
