import { Component, Input, OnInit } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { join, filter } from 'lodash-es';

@Component({
  selector: 'app-drug-details',
  templateUrl: './drug-details.component.html',
  styleUrls: ['./drug-details.component.scss'],
})
export class DrugDetailsComponent implements OnInit {
  constructor(private authService: AuthenticationService) {}

  @Input() capture: Capture;

  drugGroupName = null;
  drugGroupApproved = null;

  ngOnInit() {
    if (this.capture.candidate.drugGroup) {
      this.drugGroupName = (this.capture.candidate.drugGroup.name || '').toUpperCase();
      this.drugGroupApproved = this.capture.candidate.drugGroup.approved;
    }
  }

  akas() {
    const akas = [this.drugGroupNameWithApproval()].concat(this.filteredAndSortCandidateNDCAkas());
    return join(akas, ', ');
  }

  filteredAndSortCandidateNDCAkas() {
    const drugGroupName = this.drugGroupName;

    return filter(this.capture.candidate.ndcAkas.sort(), (el) => el.toUpperCase() !== drugGroupName);
  }

  drugGroupNameWithApproval() {
    if (this.drugGroupName) {
      const approved = this.drugGroupApproved ? ' (Approved)' : '';
      return `${this.drugGroupName}${approved}`;
    }
  }

  get showEstimatedValue(): boolean {
    return this.authService.isCaptureAdminUser;
  }
}
