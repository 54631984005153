import { mergeMap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PartnerFilesService, PagedPartnerFiles } from 'app/core/services/partner-files.service';
import { SqsQueueSummaryService } from 'app/core/services/sqs-queue-summary.service';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { PartnerFile } from 'app/core/models/partner-file.model';
import { SqsQueueSummary } from 'app/core/models/sqs-queue-summary.model';
import { interval } from 'rxjs';
import { ClaimSourceOptions } from 'app/core/options/claim-source.opts';
import { DownloadService } from 'app/core/services/download.service';

const sqsQueueSummaryPollingInterval = 10000;

@Component({
  selector: 'app-capture-admin-partner-files',
  templateUrl: './capture-admin-partner-files.component.html',
  styleUrls: ['./capture-admin-partner-files.component.scss'],
})
export class CaptureAdminPartnerFilesComponent implements OnInit, OnDestroy {
  partnerFiles: PartnerFile[] = [];
  sqsQueueSummary: SqsQueueSummary;
  sqsQueueSummaryPolling;
  paging: LimitOffsetPaging;
  loading = true;
  formModel: any = {};
  claimSourceOptions = ClaimSourceOptions;

  constructor(
    private partnerFilesService: PartnerFilesService,
    private downloadService: DownloadService,
    private sqsQueueInfosService: SqsQueueSummaryService
  ) {}

  ngOnInit() {
    this.init();
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.loadSqsQueueSummary();
    this.loadPartnerFiles(1);
    this.resetFormModel();
    this.sqsQueueSummaryPollingInit();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadPartnerFiles(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadPartnerFiles(page);
  }

  private loadPartnerFiles(page: number) {
    this.loading = true;
    this.partnerFilesService
      .getList(page, this.paging.pageSize, this.formModel.selectedPartnerNames)
      .subscribe((result: PagedPartnerFiles) => {
        this.partnerFiles = result.partnerFiles;
        this.paging = result.meta.paging;
        this.loading = false;
      });
  }

  private loadSqsQueueSummary() {
    this.sqsQueueInfosService.getSummary().subscribe((result: SqsQueueSummary) => {
      console.log(this.sqsQueueSummary);
      this.sqsQueueSummary = result;
    });
  }

  private resetFormModel() {
    this.formModel = {
      partnerNames: this.formModel.selectedPartnerNames ? [...this.formModel.selectedPartnerNames] : [],
    };
  }

  onClearAllClick($event: any) {
    this.formModel.selectedPartnerNames = new Array<string>();
    this.resetFormModel();
    this.loadPartnerFiles(1);
    $event.preventDefault();
  }

  onPartnerNamesChange() {
    this.loadPartnerFiles(1);
  }

  onFormEnter($event: any) {
    $event.preventDefault();
    this.loadPartnerFiles(1);
  }

  onDownloadErrorsClick($event: any, clientFile: PartnerFile) {
    $event.preventDefault();
    this.partnerFilesService.generateErrorFileAndGetDownloadUrl(clientFile.id).subscribe(url => {
      this.downloadService.download(url.download_url);
    });
  }

  ngOnDestroy() {
    if (this.sqsQueueSummaryPolling) {
      this.sqsQueueSummaryPolling.unsubscribe();
      this.sqsQueueSummaryPolling = null;
    }
  }

  private sqsQueueSummaryPollingInit() {
    this.sqsQueueSummaryPolling = interval(sqsQueueSummaryPollingInterval)
      .pipe(mergeMap(() => this.sqsQueueInfosService.getSummary()))
      .subscribe(result => {
        console.log(this.sqsQueueSummary);
        this.sqsQueueSummary = result;
      });
  }
}
