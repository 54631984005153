export const isObjectEmpty = (obj: object) => Object.keys(obj).length === 0;

export const isNotBlank = (value: any) => value !== '' && value !== null && value !== undefined;

export const isBlank = (value: any) => !isNotBlank(value);

export const obj2Html = (obj: object, indent = 0) => {
  let html = '';

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const keyHtml = typeof obj[key] === 'object' ? obj2Html(obj[key], indent + 1) : obj[key];
      html += '<div class="ml-' + indent + '"><span class="text-muted">' + key + ':</span> ' + keyHtml + '</div>';
    }
  }

  return html;
};

export const isObject = (value: any): value is object => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}
