import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-simple-expandable-area',
  templateUrl: './simple-expandable-area.component.html',
  styleUrls: ['./simple-expandable-area.component.scss'],
})
export class SimpleExpandableAreaComponent {
  @ViewChild('container', { static: true })
  container;

  @ViewChild('content', { static: true })
  content;

  @Input()
  maxHeight = '5em';

  _expandable: boolean;
  expanded: boolean;

  get expandable(): boolean {
    if (this._expandable === undefined) {
      const containerHeight = this.container.nativeElement.clientHeight;
      const contentHeight = this.content.nativeElement.clientHeight;

      this._expandable = contentHeight > containerHeight;
    }

    return this._expandable;
  }

  get containerStyles() {
    if (!this.expanded) {
      return {
        'max-height': this.maxHeight
      }
    }
  }

  onExpandClick(expanded: boolean) {
    this.expanded = expanded;
  }
}
