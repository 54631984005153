import { ClientConsultNoteTaskChoice } from '../enums/client-consult-note-task-choice.enum';
import { ClientTaskRejectionReason } from '../enums/client-task-rejection-reason.enum';

export class ClientConsultNoteTaskChoiceOption {
  constructor(
    public value: ClientConsultNoteTaskChoice,
    public label: string,
    public helpText: string,
    public reasons: Array<ClientTaskRejectionReason> = []
  ) {}
}

const choiceOptions = [
  new ClientConsultNoteTaskChoiceOption(
    ClientConsultNoteTaskChoice.yes,
    'Yes',
    `By selecting "Yes" I confirm that (1) I have a relevant consult note on file for this patient and
     prescriber OR other supporting documentation is present, and (2) that all future prescriptions
     written by this prescriber for this patient will be verified as eligible for 340B pricing.`
  ),
  new ClientConsultNoteTaskChoiceOption(
    ClientConsultNoteTaskChoice.notYet,
    'Not yet, but I plan to collect one.',
    `This task will remain in 'NEEDS CONSULT NOTE'.`
  ),
  new ClientConsultNoteTaskChoiceOption(
    ClientConsultNoteTaskChoice.assignBackToPar8o,
    'No, but I would like R1 to attempt to retrieve one.',
    'This option will close the this task and ask the R1 team to collect a consult note.'
  ),
  new ClientConsultNoteTaskChoiceOption(
    ClientConsultNoteTaskChoice.no,
    'No, and I would like to reject this task',
    'All prescriptions currently listed on this task will be rejected and this task will be closed. If future prescriptions come in for this patient and prescriber, this task will reopen.',
    [
      ClientTaskRejectionReason.ignorePatient,
      ClientTaskRejectionReason.ignorePrescriber,
      ClientTaskRejectionReason.ignorePatientPrescriber,
    ]
  ),
  new ClientConsultNoteTaskChoiceOption(
    ClientConsultNoteTaskChoice.prescriberIsCeProvider,
    'Prescriber is a client provider',
    'This prescriber has encounters in the client EHR and the med is listed on the patient\'s med list. Selecting this option will close this task and create a Client Prescriber Check task'
  ),
];

export { choiceOptions };
