import { Component, OnInit } from '@angular/core';
import { Client } from '../../../core/models/client.model';
import { Audit, ClientOptionsAudit } from '../../../core/models/audit.model';
import { ClientConfigurationOption } from '../../../core/models/client-configuration-option.model';
import { NavigationService } from '../../../core/services/navigation.service';
import { ClientConfigurationOptionsService } from '../../../core/services/client-configuration-options.service';
import { AuditsService } from '../../../core/services/audits.service';
import { ClientsService } from '../../../core/services/clients.service';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';

@Component({
  selector: 'app-capture-admin-client-options-audit',
  templateUrl: './capture-admin-client-options-audit.component.html',
  styleUrls: ['./capture-admin-client-options-audit.component.scss'],
})
export class CaptureAdminClientOptionsAuditComponent implements OnInit {
  clientId = parseInt(this.navigationService.currentId, 10);
  client: Client;
  optionNames: { [key: string]: string };
  optionIds: number[];
  parsedAudits: ClientOptionsAudit[];
  loading = true;
  paging: LimitOffsetPaging;

  constructor(
    private navigationService: NavigationService,
    private clientConfigurationOptionsService: ClientConfigurationOptionsService,
    private auditsService: AuditsService,
    private clientService: ClientsService
  ) {}

  ngOnInit(): void {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.loadClient();
    this.loadClientOptions();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadAudits(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadAudits(page);
  }

  private parseValue(valueChange: any): [any, any] {
    let oldValue: any = false;
    let newValue: any;

    if (Array.isArray(valueChange)) {
      oldValue = this.pretty(valueChange[0]);
      newValue = this.pretty(valueChange[1]);
    } else {
      newValue = this.pretty(valueChange);
    }

    return [oldValue, newValue];
  }

  private parseDate(valueChange: any): [string, string] {
    let oldValue: '';
    let newValue: string;

    if (Array.isArray(valueChange)) {
      oldValue = this.isDefaultDate(valueChange[0]) ? '' : valueChange[0];
      newValue = this.isDefaultDate(valueChange[1]) ? '' : valueChange[1];
    } else {
      newValue = this.isDefaultDate(valueChange) ? '' : valueChange;
    }

    return [oldValue, newValue];
  }

  private isDefaultDate(str: string) {
    // ignore dates before 2001
    const date = new Date(str);
    return date.toString() !== 'Invalid Date' && !isNaN(date.getTime()) && date.getFullYear() <= 2000;
  }

  private pretty(val: any) {
    const dataType = typeof val;

    switch (dataType) {
      case 'boolean':
        return val ? 'On' : 'Off';
      case 'object':
        return this.obj2Html(val);
      default:
        return val || '';
    }
  }

  private obj2Html(obj: object, indent=0) {
    let html = '';

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const keyHtml = (typeof(obj[key]) === "object") ? this.obj2Html(obj[key], indent+1) : obj[key];
        html += '<div class="ml-' + indent + '"><em>' + key + ':</em> ' + keyHtml + '</div>';
      }
    }

    return html;
  }

  private loadClient(): void {
    this.clientService.get(this.clientId).subscribe(client => {
      this.client = client;
    });
  }

  private loadClientOptions(): void {
    this.clientConfigurationOptionsService.getList(this.clientId).subscribe(({ clientConfigurationOptions }) => {
      this.optionNames = clientConfigurationOptions.reduce(
        (memo: { [key: string]: string }, c: ClientConfigurationOption) => {
          memo[c.id] = c.title;
          return memo;
        },
        {}
      );
      this.optionIds = clientConfigurationOptions.map((c: ClientConfigurationOption) => c.id);
      this.loadAudits(this.paging.currentPage);
    });
  }

  private parseAudit(audit: Audit): ClientOptionsAudit {
    return {
      createdAt: audit.createdAt,
      action: audit.action,
      optionName: this.optionNames[audit.auditableId],
      userEmail: audit.user?.email,
      changes: {
        value: this.parseValue(audit.auditedChanges.value),
        startAt: this.parseDate(audit.auditedChanges.startAt),
        endAt: this.parseDate(audit.auditedChanges.endAt),
      },
    };
  }

  private loadAudits(page: number): void {
    this.loading = true;
    const paging = { page, pageSize: this.paging.pageSize };

    if (this.optionIds.length > 0) {
      this.auditsService.getList(paging, 'ClientConfigurationOption', this.optionIds).subscribe(result => {
        this.paging = result.meta.paging;
        this.parsedAudits = result.audits.map((audit: Audit) => this.parseAudit(audit));
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }
}
