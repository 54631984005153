import { OfficeStatus } from '../enums/office-status.enum';
import { keyBy } from 'lodash-es';

export class OfficeStatusOption {
  constructor(public value: string, public display: string) { }
}

const officeStatusOptions = [
  new OfficeStatusOption(OfficeStatus.validFax, "Valid Fax"),
  new OfficeStatusOption(OfficeStatus.optedOut, "Opted Out"),
  new OfficeStatusOption(OfficeStatus.notAMedicalOffice, "Not a Medical Office"),
  new OfficeStatusOption(OfficeStatus.faxFailed, "Fax Failed - Nonfunctional"),
  new OfficeStatusOption(OfficeStatus.unresponsive, "Unresponsive Office"),
  new OfficeStatusOption(OfficeStatus.testing, "Testing"),
];

const keyedStatusOptions = keyBy(officeStatusOptions, 'value');

export { keyedStatusOptions, officeStatusOptions };
