<div *ngIf="!editing">
  <strong>ASSIGNED TO <span *ngIf="canEdit"><a href="#" (click)="handleEdit($event)"><fa-icon [icon]="['fas', 'file-signature']" size="sm" /></a></span></strong>
  <div>{{ assignedToName | blankValueDash }}</div>
</div>
<div *ngIf="editing">
  <strong>ASSIGNED TO</strong>
  <form novalidate class="d-flex flex-row flex-wrap align-items-center g-0">

    <div class="w-50">
      <app-consult-note-assign-user-select
        [originalSelectedUser]="originalSelectedUser"
        (selectionChanged)="handleAssignedUserChanged($event)"
      />
    </div>

    <button type="button" class="btn btn-primary form-input m-1 ms-2" (click)="handleSave($event)">Save</button>
    <button type="button" class="btn btn-secondary form-input m-1" (click)="handleCancel($event)">Cancel</button>
  </form>
  <div class="small"><i>* All consult note(s) for the patient at this office will be assigned.</i></div>
</div>
