import { CaptureUserAuditOutcomeStatus } from '../enums/capture-user-audit-outcome-status.enum';
import { keyBy } from 'lodash-es';

export class CaptureUserAuditOutcomeStatusOption {
  constructor(public value: string, public display: string) {}
}

export const outcomeStatusOptions = [
  new CaptureUserAuditOutcomeStatusOption(CaptureUserAuditOutcomeStatus.compliant, 'Compliant'),
  new CaptureUserAuditOutcomeStatusOption(
    CaptureUserAuditOutcomeStatus.compliantWithMinorFindings,
    'Compliant with minor findings'
  ),
  new CaptureUserAuditOutcomeStatusOption(CaptureUserAuditOutcomeStatus.noncompliant, 'Non-Compliant'),
];

export const keyedOutcomeStatusOptions = keyBy(outcomeStatusOptions, 'value');
