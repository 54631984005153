import { ProviderOffice } from './provider-office.model';

export class Office {
  id: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  fax: string;
  officeNames: string[];
  name: string;
  aka: string;
  status: string;
  associatedProviderOffice: ProviderOffice;
  lastUpdatedAt: Date;
  website: string;
  employedBy: string[];
  note: string;
  providerCount: number;
  communicationMethod: string;
  employedByClientIds: number[];
}
