import { Component, Input, OnInit } from '@angular/core';
import { ClientPrescriberTaskStatus } from 'app/core/enums/client-prescriber-task-status.enum';
import { ClientPrescriberTask } from 'app/core/models/client-prescriber-task.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { TaskFilters } from 'app/core/models/user-settings/task-settings.model';
import { ClientPrescriberTasksService, PagedClientPrescriberTasks } from 'app/core/services/client-prescriber-tasks.service';

const defaultRelatedTaskSize = 5;

@Component({
  selector: 'app-client-prescriber-task-related-tasks',
  templateUrl: './client-prescriber-task-related-tasks.component.html',
  styleUrls: ['./client-prescriber-task-related-tasks.component.scss'],
})
export class ClientPrescriberTaskRelatedTasksComponent implements OnInit {
  @Input() task: ClientPrescriberTask;

  loadingRelatedTasks = true;
  showingAll = false;

  filters: TaskFilters = new TaskFilters();
  relatedTasks: ClientPrescriberTask[] = [];
  totalRelatedTasks: number;

  constructor(
    private clientPrescriberTasksService: ClientPrescriberTasksService
  ) {}

  ngOnInit() {
    this.init();
  }

  public get showSeeAll() {
    if (this.showingAll) {
      return false;
    } else {
      return this.totalRelatedTasks && this.totalRelatedTasks > (defaultRelatedTaskSize + 1);
    }
  }

  public clickSeeAll($event) {
    $event.preventDefault();
    this.showingAll = true;
    this.loadPatientTasks(this.totalRelatedTasks);
  }

  private init() {
    this.filters.patientId = this.task.patient.id;
    this.loadPatientTasks(defaultRelatedTaskSize + 1);
  }

  private loadPatientTasks(count) {
    const paging = { page: 1, pageSize: count };
    this.clientPrescriberTasksService.getList(paging, this.filters).subscribe(
      (result: PagedClientPrescriberTasks) => {
        this.relatedTasks = this.buildRelatedTasks(result, count);
        this.totalRelatedTasks = result.meta.paging.count;
        this.loadingRelatedTasks = false;
      }
    );
  }

  private buildRelatedTasks(result: PagedClientPrescriberTasks, count: number) {
    const filteredTasks = result.clientPrescriberTasks.filter( (t) => t.id !== this.task.id );
    return filteredTasks.slice(0, count - 1);
  }
}
