<div *ngIf="active">
  <div>
    Active
    <app-patient-deactivate-button [patient]="patient" (patientDeactivated)="onPatientDeactivated($event)" />
  </div>
</div>

<div *ngIf="!active">
  <strong class="text-danger">Inactive ({{ patient.inactiveDate | date: 'MM/dd/yyyy' }})</strong>
</div>
