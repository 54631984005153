<footer class="page-footer py-3">
  <div class="text-center text-md-left container">
    <div class="row">
      <div class="col-auto">
        <a href="https://www.r1rcm.com/what-we-do/regulatory-navigation/340b-recovery/" target="_blank">
          <img src="assets/images/r1-logo-blue.png" class="logo" alt="R1" />
        </a>
      </div>
      <div class="col-auto">
        <ul>
          <li>©{{ today | date: 'yyyy' }} R1</li>
          <li><a href="https://www.r1rcm.com/terms-of-use/" target="_blank">Terms of Use</a></li>
          <li><a href="https://www.r1rcm.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
          <li *ngIf="!isProduction">Version: {{ version }}</li>
        </ul>
      </div>
    </div>
  </div>
</footer>
