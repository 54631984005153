import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { UserRoleName } from '../../core/enums/user-role-name.enum';
import { NavigationService } from '../../core/services/navigation.service';
import { AccessBaseGuard } from './access-base-guard';

@Injectable()
export class AccessIccClaimsGuard extends AccessBaseGuard {
  constructor(protected authService: AuthenticationService, protected navigationService: NavigationService) {
    super(UserRoleName.accessIccClaims, authService, navigationService);
  }
}
