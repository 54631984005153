import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { MatchingPatientPrescriber } from '../../../core/models/matching-patient-prescriber.model';

@Component({
  selector: 'app-validate-referral-match-verifications',
  templateUrl: './validate-referral-match-verifications.component.html',
  styleUrls: ['./validate-referral-match-verifications.component.scss'],
})
export class ValidateReferralMatchVerificationsComponent implements OnInit {
  _patientAttachment: PatientAttachment;
  matchingPatientPrescribers: MatchingPatientPrescriber[] = [];

  constructor(private patientAttachmentsService: PatientAttachmentsService) {}

  ngOnInit() {}

  get patientAttachment(): PatientAttachment {
    return this._patientAttachment;
  }

  @Input()
  set patientAttachment(pa: PatientAttachment) {
    this._patientAttachment = pa;
    this.initializeMatchingPatientPrescribers();
  }

  private initializeMatchingPatientPrescribers() {
    this.patientAttachmentsService
      .getMatchingPatientPrescribers(this.patientAttachment.id)
      .subscribe((mpps: MatchingPatientPrescriber[]) => {
        this.matchingPatientPrescribers = mpps;
      });
  }
}
