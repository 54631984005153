import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-consult-note-title',
  templateUrl: './consult-note-title.component.html',
  styleUrls: ['./consult-note-title.component.scss'],
})
export class ConsultNoteTitleComponent implements OnInit {
  @Input() patientAttachment: PatientAttachment;

  constructor() {}

  ngOnInit() {}
}
