import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { ClientSftpCredential } from '../models/client-sftp-credential.model'

@Injectable()
export class ClientSftpCredentialService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getCredentials(clientId: number): Observable<ClientSftpCredential[]> {
    return this.http.get<ClientSftpCredential[]>(`${environment.captureApi.url}/client_sftp_credentials/${clientId}`);
  }

  getPassword(id: string): Observable<ClientSftpCredential> {
    return this.http.get<ClientSftpCredential>(`${environment.captureApi.url}/client_sftp_credentials/${id}/password`);
  }
}
