import { Component, Input, OnInit } from '@angular/core';
import { isNull, cloneDeep } from 'lodash-es';
import { Capture } from '../../../core/models/capture.model';
import { PatientPrescriber } from '../../../core/models/patient-prescriber.model';
import { Prescription } from '../../../core/models/prescription.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EditReferralMatchVerificationsModalComponent } from '../edit-referral-match-verifications-modal/edit-referral-match-verifications-modal.component';
import { ClientConfigurationOptionsService } from 'app/core/services/client-configuration-options.service';
import { ClientConfigurationOption } from 'app/core/models/client-configuration-option.model';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';

@Component({
  selector: 'app-validate-referral',
  templateUrl: './validate-referral.component.html',
  styleUrls: ['./validate-referral.component.scss'],
})
export class ValidateReferralComponent implements OnInit {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;
  @Input() patientPrescriptions: Prescription[];

  displayLegacyInfo = false;
  clientConfigurationOptions: ClientConfigurationOption[];

  editRmvsModal: NgbModalRef = null;

  constructor(
    private modalService: NgbModal,
    private clientConfigurationOptionsService: ClientConfigurationOptionsService,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.displayLegacyInfo = !isNull(this.patientPrescriber.legacyReferralMatchVerification);

    this.clientConfigurationOptionsService.getList(this.capture.client.id).subscribe(results => {
      this.clientConfigurationOptions = results.clientConfigurationOptions;
    })
  }

  onEditRmvsClick($event) {
    if (!this.canEditRmvs) return;

    $event.preventDefault();
    this.openEditRmvsModal();
  }

  private openEditRmvsModal() {
    this.editRmvsModal = this.modalService.open(EditReferralMatchVerificationsModalComponent, {
      windowClass: 'edit-rmv-modal',
    });

    // NOTE: We pass in a deep clone of the capture and patient prescriber
    // to avoid any changes in the modal from being reflected in the main
    // page.
    const modalInstance = this.editRmvsModal.componentInstance;
    modalInstance.capture = cloneDeep(this.capture);
    modalInstance.patientPrescriber = cloneDeep(this.patientPrescriber);
    modalInstance.clientConfigurationOptions = this.clientConfigurationOptions;
  }

  get canEditRmvs(): boolean {
    return this.capture.verified && this.authService.hasPermissionTo(UserRoleName.editVerifiedCaptures);
  }
}
