import { Component, OnInit } from '@angular/core';
import { ClientChore } from 'app/core/models/client-chore.model';
import { ClientChoreFilters } from 'app/core/models/user-settings/client-chore-settings.model';
import { ClientChoresService, PagedClientChores } from 'app/core/services/client-chores.service';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ClientChoreStatus } from 'app/core/enums/client-chore-status.enum';

@Component({
  templateUrl: './capture-admin-client-chores.component.html',
  styleUrls: ['./capture-admin-client-chores.component.scss'],
})
export class CaptureAdminClientChoresComponent implements OnInit {
  clientChores: ClientChore[] = [];
  filterableStatuses = Object.values(ClientChoreStatus);
  filters: ClientChoreFilters = new ClientChoreFilters();
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  loading = true;

  constructor(
    private clientChoresService: ClientChoresService,
  ) { }

  ngOnInit() {
    this.getClientChores();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.getClientChores();
  }

  onPageChange(_page: number) {
    this.getClientChores();
  }

  onFilterChange() {
    this.getClientChores();
  }

  onClearClick() {
    this.filters = new ClientChoreFilters();
    this.paging.currentPage = 1;
    this.getClientChores();
  }

  getClientChores() {
    this.loading = true;
    this.clientChoresService
      .getList(
        this.paging.currentPage,
        this.paging.pageSize,
        this.filters.selectedStatuses,
        this.filters.selectedClientIds,
        this.filters.selectedUserIds,
        this.filters.selectedNames,
        this.filters.inactive,
        this.filters.requiresReview,
      )
      .subscribe((result: PagedClientChores) => {
        this.clientChores = result.clientChores;
        this.paging = result.meta.paging;
        this.loading = false;
      });
  }
}
