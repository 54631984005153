import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ReferralPrescriberSpecialtyMappingImport } from '../models/referral-prescriber-specialty-mapping-import.model';

@Injectable()
export class ReferralPrescriberSpecialtyMappingService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  create(signedId): Observable<ReferralPrescriberSpecialtyMappingImport> {
    return this.http.post<ReferralPrescriberSpecialtyMappingImport>(
      `${environment.captureApi.url}/referral_prescriber_specialty_mapping_imports`,
      {
        referralPrescriberSpecialtyMappingImport: { signedId },
      }
    );
  }

  getList(): Observable<{ referralPrescriberSpecialtyMappingImports: ReferralPrescriberSpecialtyMappingImport[] }> {
    return this.http.get<{ referralPrescriberSpecialtyMappingImports: ReferralPrescriberSpecialtyMappingImport[] }>(
      `${environment.captureApi.url}/referral_prescriber_specialty_mapping_imports`
    );
  }
}
