import { Component, OnInit } from '@angular/core';
import { Upload } from '../../../core/models/upload.model';
import { CuratedSpecialtiesService } from '../../../core/services/curated-specialties.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty } from 'lodash-es';

const emptyForm = { file: null };

@Component({
  selector: 'app-capture-admin-import-curated-specialties',
  templateUrl: './capture-admin-import-curated-specialties.component.html',
  styleUrls: ['./capture-admin-import-curated-specialties.component.scss'],
})
export class CaptureAdminImportCuratedSpecialtiesComponent implements OnInit {
  uploading = false;
  formModel = { ...emptyForm };
  errors: string[] = [];

  constructor(
    private curatedSpecialtiesService: CuratedSpecialtiesService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {}

  onUploadStart() {
    this.uploading = true;
    this.errors = [];
  }

  onUploadComplete(upload: Upload) {
    this.curatedSpecialtiesService.create(upload.signedId).subscribe(
      () => {
        this.navigationService.navigateTo('/capture-admin/tools/curated-specialties/history');
        this.uploading = false;
        this.reset();
      },
      (error: HttpErrorResponse) => {
        this.uploading = false;
        this.errors = error.error.errors;
      }
    );
  }

  onUploadError($event) {
    this.reset();
  }

  onFormEnter($event) {
    $event.preventDefault();
  }

  private reset() {
    this.uploading = false;
    this.formModel = { ...emptyForm };
  }

  public get hasError() {
    return !isEmpty(this.errors);
  }
}
