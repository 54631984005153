import { CaptureQueueCursorType } from '../../enums/capture-queue-cursor-type.enum';
import { CaptureStatus } from '../../enums/capture-status.enum';
import { CaptureStatusReason } from '../../enums/capture-status-reason.enum';

export class PatientCapturesSearchFilter {
  constructor(
    public statuses: CaptureStatus[] = [],
    public reasons: CaptureStatusReason[] = [],
    public prescriberFirstName: string = '',
    public prescriberLastName: string = '',
    public prescriberNpi: string = '',
    public highValue: boolean = false
  ) {}
}

export class PatientCapturesSearchSettings {
  constructor(
    public filters: PatientCapturesSearchFilter = new PatientCapturesSearchFilter(),
    public cursorType: CaptureQueueCursorType = CaptureQueueCursorType.highestEstimatedValue
  ) {}
}