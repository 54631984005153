import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProviderCallLogsService } from 'app/core/services/provider-call-logs.service';

@Component({
  selector: 'app-npi-call-logs',
  templateUrl: './npi-call-logs.component.html',
  styleUrls: ['./npi-call-logs.component.scss'],
})
export class NpiCallLogsComponent implements OnInit {
  @Input() npi: string;

  providerCallLogs = [];
  loadingProviderCallLogs = true;

  constructor(
    private providerCallLogService: ProviderCallLogsService,
  ) {}

  ngOnInit() {
    this.loadProviderCallLogs();
  }

  highlightPhone(enteredPhone, officePhone) {
    if (enteredPhone && (enteredPhone !== officePhone)) {
      return "red-cell";
    }
    return "";
  }

  private loadProviderCallLogs() {
    this.providerCallLogService.getByNpi(this.npi).subscribe(results => {
      this.providerCallLogs = results;
      this.loadingProviderCallLogs = false;
    });
  }
}
