import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { IccCaptureQueueCursorType } from 'app/core/enums/icc-capture-queue-cursor-type.enum';
import { IccCapture } from 'app/core/models/icc/icc-capture.model';
import { Cursor } from 'app/core/models/paged-results/cursor-based-paging.model';
import { IccCaptureService, PagedIccCaptureQueueResults } from 'app/core/services/icc/icc-capture.service';

@Component({
  selector: 'app-icc-captures',
  templateUrl: './icc-captures.component.html',
  styleUrls: ['./icc-captures.component.scss']
})
export class IccCapturesComponent implements OnInit {
  private getResults$: Subject<void> = new Subject();
  public captures: IccCapture[];
  public cursor: Cursor<IccCaptureQueueCursorType> = null;
  public hasMoreResults = false;
  public loadingResults = true;
  public limit = 10;

  constructor(
    private iccCaptureService: IccCaptureService
  ) {}

  ngOnInit(): void {
    this.configureGetResults();
    this.getResults$.next();
  }

  private configureGetResults() {
    this.getResults$
      .pipe(
        tap(() => {
          this.loadingResults = true;
          this.captures = [];
        }),
        switchMap(() => this.iccCaptureService.getQueue(this.cursor))
      )
      .subscribe((results: PagedIccCaptureQueueResults) => {
        this.captures = results.iccCaptures;
        this.cursor = results.meta.cursor;
        this.hasMoreResults = !!results.meta.cursor;
        this.loadingResults = false;
      });
  }

  onNextClick($event: Event) {
    $event.preventDefault();
    this.getResults$.next();
    window.scrollTo(0, 0);
  }

}
