import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { CaptureInvoiceLineItemImport } from '../models/capture-invoice-line-item-import.model';

@Injectable()
export class CaptureInvoiceLineItemImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<CaptureInvoiceLineItemImport> {
    return this.http.get<CaptureInvoiceLineItemImport>(
      `${environment.captureApi.url}/capture_invoice_line_item_imports/${id}`
    );
  }

  getList(): Observable<{ captureInvoiceLineItemImports: CaptureInvoiceLineItemImport[] }> {
    return this.http.get<{ captureInvoiceLineItemImports: CaptureInvoiceLineItemImport[] }>(
      `${environment.captureApi.url}/capture_invoice_line_item_imports`
    );
  }

  create(signedId: string): Observable<CaptureInvoiceLineItemImport> {
    return this.http.post<CaptureInvoiceLineItemImport>(
      `${environment.captureApi.url}/capture_invoice_line_item_imports`,
      {
        captureInvoiceLineItemImport: { signedId },
      }
    );
  }

  cancel(id): Observable<CaptureInvoiceLineItemImport> {
    return this.http.patch<CaptureInvoiceLineItemImport>(
      `${environment.captureApi.url}/capture_invoice_line_item_imports/${id}/cancel`,
      {}
    );
  }
}
