<div>
  <section class="shadow-sm bg-light p-3 mb-2 border rounded">
    <app-client-referral-task-options
      [task]="task"
      [formDisabled]="formDisabled"
      (taskChange)="onTaskChange($event)"
    />
  </section>
  <section *ngIf="isYesSelected" class="shadow-sm bg-light p-3 mb-2 border rounded">
    <h5 class="question mb-2">What is the referral or supporting documentation date?</h5>
    <app-client-referral-task-dates
      [verifications]="verifications"
      [canAddVerifications]="canAddVerifications"
      [formDisabled]="formDisabled"
      (addVerification)="onAddVerification()"
      (updateVerification)="onUpdateVerification($event)"
      (removeVerification)="onRemoveVerification($event)"
    />
  </section>
  <section class="mt-3 mb-2">
    <h5 class="prescriptions-header">
      Prescriptions for <strong>{{ task.patient.fullName }}</strong> from
      <strong>{{ task.prescriber.fullName }}</strong>
    </h5>
    <app-client-referral-task-prescriptions
      [task]="task"
      [prescriptions]="prescriptions"
      [formDisabled]="formDisabled"
      [canAddVerifications]="canAddVerifications"
      (addVerification)="onAddVerification()"
      (taskChange)="onTaskChange($event)"
    />
    <div class="alert alert-warning mt-1" *ngIf="showPrescriptionsWarning">
      One or more of the prescriptions listed has a written date that is after the provided documentation date(s). Add
      another referral to qualify all prescriptions.
    </div>
  </section>
  <app-client-referral-task-actions
    [task]="task"
    [prescriptions]="prescriptions"
    [formDisabled]="formDisabled"
    (taskChange)="onTaskChange($event)"
  />
</div>
