import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { MeService } from '../../../core/services/me.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../core/models/user.model';

@Component({
  selector: 'app-confirm-mfa-phone-modal',
  templateUrl: './confirm-mfa-phone-modal.component.html',
  styleUrls: ['./confirm-mfa-phone-modal.component.scss'],
})
export class ConfirmMfaPhoneModalComponent implements OnInit {
  serverError = false;
  formModel: { phoneNumber: string } = { phoneNumber: null };

  constructor(
    public activeModal: NgbActiveModal,
    public meService: MeService,
    public authService: AuthenticationService
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.serverError = false;

    this.meService.confirmMfaPhoneNumber(this.formModel.phoneNumber).subscribe(
      (user: User) => {
        this.authService.updateCurrentUser(user);
        this.activeModal.close();
      },
      err => {
        console.log(err);
        this.serverError = true;
      }
    );
  }
}
