import { Pipe, PipeTransform } from '@angular/core';
import { CaptureHistoryItem } from 'app/core/models/capture-history-item.model';
import { PatientAttachmentHistoryItem } from 'app/core/models/patient-attachment-history-item.model';
import { ClientTaskHistoryItem } from 'app/core/models/client-task-history-item.model';

@Pipe({
  name: 'historyItemUserName',
})
export class HistoryItemUserNamePipe implements PipeTransform {
  transform(historyItem: CaptureHistoryItem | PatientAttachmentHistoryItem | ClientTaskHistoryItem, args?: any): any {
    if (historyItem.user && historyItem.user.username) {
      return historyItem.user.username;
    } else {
      return 'System';
    }
  }
}
