import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseConsultNotesComponent } from 'app/consult-notes/pages/base-consult-notes/base-consult-notes.component';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { ConsultNoteQueueService, PagedConsultNoteQueueResults } from 'app/core/services/consult-note-queue.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { UserPresenceService } from 'app/core/services/user-presence.service';
import { UserTimingService } from 'app/core/services/user-timing.service';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-capture-admin-my-consult-notes',
  templateUrl: './capture-admin-my-consult-notes.component.html',
  styleUrls: ['./capture-admin-my-consult-notes.component.scss']
})
export class CaptureAdminMyConsultNotesComponent extends BaseConsultNotesComponent implements OnInit, OnDestroy {
  resultCount = 0;

  constructor(
    protected consultNoteQueueService: ConsultNoteQueueService,
    protected navigationService: NavigationService,
    protected userPresenceService: UserPresenceService,
    protected userTimingService: UserTimingService,
    protected authenticationService: AuthenticationService,
    protected patientAttachmentsService: PatientAttachmentsService,
  ) {
    super(
      consultNoteQueueService,
      navigationService,
      userPresenceService,
      userTimingService,
      authenticationService,
      patientAttachmentsService
    );
  }

  ngOnInit() {
    this.init();
    this.loadResultCount();
  }

  protected configureGetResults() {
    this.getResults$
      .pipe(
        tap(() => {
          this.loadingResults = true;
          this.consultNoteQueueResults = [];
          this.userPresenceService.unsubscribeAll();
        }),
        switchMap(
          () => this.consultNoteQueueService.getMyResults(this.authenticationService.currentUser.id, this.cursor)
        )
      )
      .subscribe((results: PagedConsultNoteQueueResults) => {
        this.consultNoteQueueResults = results.consultNoteQueueResults;
        this.cursor = results.meta.cursor;
        this.loadingResults = false;
        this.hasMoreResults = !!results.meta.cursor;
        this.subscribeToUserPresence();
      });
  }

  protected startTimeTracking() {
    const data: any = { page: 'my_consult_note_queue' };
    this.userTimingService.track(data);
  }

  private loadResultCount() {
    this.consultNoteQueueService.getMyCount(this.authenticationService.currentUser?.id).subscribe((result) => {
      this.resultCount = result.count;
    });
  }
}
