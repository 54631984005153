import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { SqsQueueSummary } from '../models/sqs-queue-summary.model';

@Injectable()
export class SqsQueueSummaryService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getSummary(): Observable<SqsQueueSummary> {
    return this.http.get<SqsQueueSummary>(`${environment.captureApi.url}/sqs_queue_summary/summary`);
  }
}
