/* eslint-disable @angular-eslint/component-selector */

import { Component, Input, OnInit } from '@angular/core';
import { Audit } from 'app/core/models/audit.model';
import { obj2Html } from '../../../core/lib/js-utils';
import { camelCaseToFlat } from 'app/core/lib/string-utils';

@Component({
  selector: '[app-audit-note-create-row]',
  templateUrl: './audit-note-create-row.component.html',
  styleUrls: ['./audit-note-create-row.component.scss'],
})
export class AuditNoteCreateRowComponent implements OnInit {
  @Input() audit: Audit;
  newValueHtml;
  modelType;
  modelId;
  modelHumanizedName;

  constructor() {}

  ngOnInit() {
    const {
      userId,
      data,
      modelType,
      modelId,
      ...changes
    } = this.audit.auditedChanges;

    this.modelType = modelType;
    this.modelId = modelId;
    this.modelHumanizedName = camelCaseToFlat(modelType);

    this.newValueHtml = obj2Html(changes);
  }
}
