import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CaptureSearchParamSet } from '../../../core/models/capture-search-param-set.model';
import { SearchField } from '../../../core/models/search-field.model';
import { CaptureSearchService, PagedCaptureSearchResults } from '../../../core/services/capture-search.service';
import { CaptureSearchResult } from '../../../core/models/capture-lists/capture-search-result.model';
import { LimitOffsetPaging } from '../../../core/models/paged-results/limit-offset-paging.model';
import { supportEmailAddress } from '../../../core/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { flatten } from 'lodash-es';

@Component({
  selector: 'app-capture-search',
  templateUrl: './capture-search.component.html',
  styleUrls: ['./capture-search.component.scss'],
})
export class CaptureSearchComponent implements OnInit {
  supportEmailAddress = supportEmailAddress;
  captureSearchParams = new CaptureSearchParamSet();

  canSearch = false;
  refreshing = false;
  hasQueried = false;
  hasResults = false;
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;

  @Input() searchFields: Array<SearchField>;

  @Output() searchComplete = new EventEmitter<CaptureSearchResult[]>();

  constructor(private captureSearchService: CaptureSearchService) {}

  ngOnInit() {
    this.loadSavedParams();
  }

  performSearch() {
    this.getCaptures(1);
  }

  onSubmit() {
    this.performSearch();
  }

  onReset() {
    this.hasQueried = false;
    this.captureSearchParams = new CaptureSearchParamSet();
    this.paging = LimitOffsetPaging.empty;
    this.hasResults = false;
    this.searchComplete.emit([]);
    this.searchFieldsUpdated();
    this.saveParams();
  }

  searchFieldsUpdated() {
    this.updateCanSearch();
  }

  onSearchFieldChanged() {
    this.searchFieldsUpdated();
  }

  onPageChange(page) {
    this.getCaptures(page);
  }

  private getCaptures(page) {
    this.refreshing = true;
    this.hasQueried = false;

    const { pageSize } = this.paging;

    this.captureSearchService
      .search(this.captureSearchParams, page, pageSize)
      .subscribe((results: PagedCaptureSearchResults) => {
        this.paging = results.meta.paging;
        this.refreshing = false;
        this.hasQueried = true;
        this.hasResults = results.captures && results.captures.length > 0;
        this.searchComplete.emit(results.captures || []);
        this.saveParams();
      });
  }

  private canPerformSearch() {
    const fields = flatten(this.searchFields).map(sf => sf.name);
    return fields.some(k => this.captureSearchParams[k]);
  }

  private updateCanSearch() {
    this.canSearch = this.canPerformSearch();
  }

  private saveParams() {
    this.captureSearchService.saveSearchParams(this.captureSearchParams).subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        console.error(err);
      }
    );
  }

  private loadSavedParams() {
    this.captureSearchService.getSavedSearchParams().subscribe((captureSearchParams: CaptureSearchParamSet) => {
      if (captureSearchParams) {
        this.captureSearchParams = captureSearchParams;

        this.updateCanSearch();

        if (this.canSearch) {
          this.performSearch();
        }
      }
    });
  }
}
