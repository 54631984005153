<app-capture-history-item-title>
  {{ title }}
</app-capture-history-item-title>

<app-capture-history-item-body>
  <p>
    A
    <a routerLink="/capture-admin/consult-notes/{{consultNoteId}}">
      related consult note
    </a>
    was submitted
  </p>
</app-capture-history-item-body>
