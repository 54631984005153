import { InboundCallReason } from '../enums/inbound-call-reason.enum';
import { keyBy } from 'lodash-es';
import { FaxStatusReasonOption } from './inbound-fax-status-reasons.opts';

export class InboundCallReasonOption {
  constructor(public value: string, public display: string) {}
}

const reasonOptions = [
  new InboundCallReasonOption(InboundCallReason.patientNotAtPractice, 'Patient not at this practice'),
  new InboundCallReasonOption(InboundCallReason.prescriberNotAtPractice, 'Provider not at this practice'),
  new InboundCallReasonOption(InboundCallReason.patientReleaseRequired, 'Practice requires patient release'),
  new InboundCallReasonOption(
    InboundCallReason.prescriberSpecialtyDoesNotReceiveReferrals,
    'Prescriber specialty does not receive referrals.'
  ),
  new FaxStatusReasonOption(InboundCallReason.faxReceivedHasAnIssue, 'Fax received has an issue'),
  new InboundCallReasonOption(InboundCallReason.officeOptedOut, 'Office opted out'),
  new InboundCallReasonOption(InboundCallReason.needsPayment, 'Needs payment'),
  new InboundCallReasonOption(InboundCallReason.needsMailingAddress, 'Needs mailing address'),
  new InboundCallReasonOption(InboundCallReason.ceHasConsultNote, 'CE has consult note'),
  new InboundCallReasonOption(InboundCallReason.practiceWorkingOnRequest, 'Practice is working on request'),
  new InboundCallReasonOption(InboundCallReason.noRecordForDateOfService, 'No record for date of service'),
  new InboundCallReasonOption(InboundCallReason.additionalInformationRequested, 'Additional information requested'),
  new InboundCallReasonOption(InboundCallReason.other, 'Other'),
];

const keyedReasonOptions = keyBy(reasonOptions, 'value');

export { keyedReasonOptions, reasonOptions };
