import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-validate-patient-match-details',
  templateUrl: './validate-patient-match-details.component.html',
  styleUrls: ['./validate-patient-match-details.component.scss'],
})
export class ValidatePatientMatchDetailsComponent implements OnInit {
  @Input() patientAttachment: PatientAttachment;

  constructor() {}

  ngOnInit() {}
}
