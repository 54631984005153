import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { ApiService } from './api.service';
import { NameMapping, NameMappingSummary, NameMappingUpdate, NameMappingFilters } from '../models/name-mapping.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';
import { environment } from '../../../environments/environment';

export class PagedNameMappings implements PagedResults {
  nameMappings: NameMapping[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.nameMappings;
  }
}

@Injectable()
export class NameMappingsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    page: number,
    pageSize: number,
    filters: NameMappingFilters,
    sort_col: string,
    sort_dir: string,
  ): Observable<PagedNameMappings> {
    const filterParams: URLSearchParams = this.buildFilterParams(filters);
    const searchParams = new URLSearchParams(`page=${page}&per_page=${pageSize}&sort_col=${sort_col}&sort_dir=${sort_dir}`);

    return this.http.get<PagedNameMappings>(
      `${environment.captureApi.url}/name_mappings?${filterParams.toString()}&${searchParams.toString()}`
    );
  }

  updateList(updates: NameMappingUpdate): Observable<NameMappingSummary> {
    return this.http.patch<NameMappingSummary>(
      `${environment.captureApi.url}/name_mappings/bulk_update`,
      {
        updates,
      }
    );
  }

  getSummary(): Observable<NameMappingSummary> {
    return this.http.get<NameMappingSummary>(
      `${environment.captureApi.url}/name_mappings/summary`
    );
  }

  private buildFilterParams(filters: NameMappingFilters): URLSearchParams {
    const urlSearchParams = new URLSearchParams();
    this.appendFilterParam('match_type', filters.matchType, urlSearchParams);
    this.appendFilterParam('has_high_value', filters.hasHighValue, urlSearchParams);
    this.appendFilterParam('created_date_from', filters.createdDateFrom, urlSearchParams);
    this.appendFilterParam('created_date_to', filters.createdDateTo, urlSearchParams);
    this.appendFilterParam('has_npma_captures', filters.hasNpmaCaptures, urlSearchParams);
    return urlSearchParams;
  }

  private appendFilterParam(paramName: string, value: string, urlSearchParams: URLSearchParams) {
    if (value) {
      urlSearchParams.append(paramName, value);
    }
  }
}
