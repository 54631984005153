<div class="patient-details">
  <div class="row">
    <div class="col">
      <div><strong>ENC-MRN</strong></div>
      <div>{{ patientAttachment.patient.mrn }}</div>
    </div>
    <div class="col">
      <div><strong>CE-PATIENT</strong></div>
      <div>{{ patientAttachment.patient.fullName }}</div>
    </div>
    <div class="col">
      <div><strong>CE-DOB</strong></div>
      <div>{{ patientAttachment.patient.dob | date: 'MM/dd/yyyy' }}</div>
    </div>
  </div>
</div>
