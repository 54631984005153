import { Directive, ElementRef, HostListener, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appExcludeInputPattern]',
})
export class ExcludeInputPatternDirective implements AfterViewInit {
  @Input() public excludedPattern;

  compiledExcludedPattern: RegExp;

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    this.compiledExcludedPattern = new RegExp(this.excludedPattern, 'g');
  }

  @HostListener('keypress', ['$event'])
  onInputChange(event) {
    if (event.key.match(this.compiledExcludedPattern)) {
      event.preventDefault();
    }
  }
}
