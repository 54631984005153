import { Component, Input } from '@angular/core';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { keyedStatusOptions } from 'app/core/options/provider-office-status.opts';

@Component({
  selector: 'app-office-provider-list',
  templateUrl: './office-provider-list.component.html',
  styleUrls: ['./office-provider-list.component.scss'],
})
export class OfficeProviderListComponent {
  @Input() providerOffices: ProviderOffice[];
  @Input() loading: boolean;

  keyedStatusOptions = keyedStatusOptions;
}
