import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BaseCaptureQueueSettingsComponent } from '../capture-queue/base-capture-queue-settings';

@Component({
  selector: 'app-capture-queue-settings',
  templateUrl: './capture-queue-settings.component.html',
  styleUrls: ['./capture-queue-settings.component.scss'],
})
export class CaptureQueueSettingsComponent extends BaseCaptureQueueSettingsComponent {
  refreshCaptureCount$: Subject<void> = new Subject();
  updatingCaptureCount = false;
  captureCount = 0;

  protected initialize() {
    super.initialize();
    this.configureRefreshCaptureCount();
    this.refreshCaptureCount();
  }

  protected handleSettingsChange() {
    super.handleSettingsChange();
    this.refreshCaptureCount();
  }

  private refreshCaptureCount() {
    this.refreshCaptureCount$.next();
  }

  private configureRefreshCaptureCount() {
    // https://www.credera.com/blog/technology-solutions/using-rxjs-switchmap-angular-7-reactive-forms-cancel-pending-requests/

    this.refreshCaptureCount$
      .pipe(
        tap(() => {
          this.updatingCaptureCount = true;
        }),
        switchMap(() => this.captureQueueService.getCount())
      )
      .subscribe(({ count }) => {
        this.updatingCaptureCount = false;
        this.captureCount = count;
      });
  }
}
