<div>
  <a
    *ngIf="canViewClient"
    target="_blank"
    routerLink="/capture-admin/client-management/clients/{{ client.id }}"
  >
    {{ client.name }}
  </a>
  <span *ngIf="!canViewClient">{{ client.name }}</span>
  <app-copy-to-clipboard [text]="client.name" />
  ({{ client.state }})
</div>