import { Component, Input } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { NavigationService } from '../../../core/services/navigation.service';
import { CaptureQueueService } from '../../../core/services/capture-queue.service';
import { NextResult } from '../../../core/models/paged-results/cursor-based-paging.model';
import { CaptureValidationService } from '../../../core/services/capture-validation.service';

@Component({
  selector: 'app-capture-next',
  templateUrl: './capture-next.component.html',
})
export class CaptureNextComponent {
  isDisabled = false;

  @Input() capture: Capture;

  constructor(
    private captureQueueService: CaptureQueueService,
    private navigationService: NavigationService,
    private captureValidationService: CaptureValidationService
  ) {}

  nextCaptureClick() {
    this.isDisabled = true;
    this.captureValidationService.clearErrors();
    this.captureQueueService.getNextResult(this.capture).subscribe((nextResult: NextResult) => {
      this.isDisabled = false;
      if (nextResult) {
        this.navigationService.navigateTo(`/capture-admin/captures/${nextResult.id}`);
      } else {
        this.navigationService.navigateTo(`/capture-admin/captures`);
      }
    });
  }
}
