import { Component, Input, Output, EventEmitter } from '@angular/core';
import { debounce, isNull } from 'lodash-es';
import { TaskFilters } from 'app/core/models/user-settings/task-settings.model';
import { estimatedValueRank } from 'app/core/options/estimated-value-rank.opts';

const MAX_EST_VALUE_LENGTH = 15;

@Component({
  selector: 'app-client-tasks-filter',
  templateUrl: './client-tasks-filter.component.html',
  styleUrls: ['./client-tasks-filter.component.scss'],
})
export class ClientTasksFilterComponent {
  @Input() filters: TaskFilters;
  @Output() filtersChange = new EventEmitter<TaskFilters>();

  @Input() loading: boolean;

  @Input() exportUrl: string;
  @Input() enableExport: boolean;

  @Input() taskStatuses = [];
  @Input() specialties = [];
  @Input() manufacturers = [];
  @Input() sources = [];
  @Input() encounterPatientInteractionLocations = null;
  @Input() clients = null;

  @Input() allowTaskIdSearch: boolean;

  @Input() useEstimatedValueRanks: boolean;
  estimatedValueRank = estimatedValueRank;
  previousEstimatedValue;

  debounceChange = debounce(
    () => {
      this.onFilterChange();
    },
    1000,
    { maxWait: 2000 }
  );

  debounceEstimatedValueKeyUp = debounce(
    () => {
      if (this.previousEstimatedValue !== this.filters.estimatedValue) {
        this.onFilterChange();
      }
    },
    1000,
    { maxWait: 2000 }
  );

  constructor() {}

  onFilterChange() {
    this.filtersChange.emit(this.filters);
  }

  onClearFiltersClick() {
    this.filters = new TaskFilters();
    this.onFilterChange();
  }

  onTextFieldChange() {
    this.debounceChange();
  }

  get hasEstimatedValue(): boolean {
    const value = this.filters.estimatedValue;
    return !(isNull(value) || value === '');
  }

  onEstimatedValueKeyDown($event) {
    this.previousEstimatedValue = this.filters.estimatedValue;

    if (this.shouldIgnore($event)) {
      return;
    }

    if (this.shouldPreventDefault($event) || this.maxEstimatedValueLength()) {
      $event.preventDefault();
    }
  }

  onEstimatedValueKeyUp() {
    this.debounceEstimatedValueKeyUp();
  }

  onClearEstimatedValueClick() {
    this.filters.estimatedValue = null;
    this.onFilterChange();
  }

  // https://codeburst.io/digit-only-directive-in-angular-3db8a94d80c3
  private shouldIgnore($event) {
    return (
      [46, 8, 9, 27, 13].indexOf($event.keyCode) !== -1 ||
      ($event.keyCode === 65 && $event.ctrlKey === true) || // Allow: Ctrl+A
      ($event.keyCode === 67 && $event.ctrlKey === true) || // Allow: Ctrl+C
      ($event.keyCode === 86 && $event.ctrlKey === true) || // Allow: Ctrl+V
      ($event.keyCode === 88 && $event.ctrlKey === true) || // Allow: Ctrl+X
      ($event.keyCode === 65 && $event.metaKey === true) || // Cmd+A (Mac)
      ($event.keyCode === 67 && $event.metaKey === true) || // Cmd+C (Mac)
      ($event.keyCode === 86 && $event.metaKey === true) || // Cmd+V (Mac)
      ($event.keyCode === 88 && $event.metaKey === true) || // Cmd+X (Mac)
      ($event.keyCode >= 35 && $event.keyCode <= 39)
    );
  }

  private shouldPreventDefault($event) {
    const value = this.filters.estimatedValue;
    if ((($event.keyCode === 109 || $event.keyCode === 189) && isNull(value)) || value === '') {
      return false;
    }

    if ($event.keyCode === 48 && value === '-') {
      return true;
    }

    if ($event.keyCode === 48 && value === '0') {
      return true;
    }

    return (
      ($event.shiftKey || $event.keyCode < 48 || $event.keyCode > 57) && ($event.keyCode < 96 || $event.keyCode > 105)
    );
  }

  private maxEstimatedValueLength() {
    const value = this.filters.estimatedValue;
    return !isNull(value) && value.length >= MAX_EST_VALUE_LENGTH;
  }
}
