import { Component, OnInit } from '@angular/core';
import { CreatePasswordMode } from '../../components/create-password-form/create-password-form.component';

@Component({
  selector: 'app-confirm-password-reset',
  templateUrl: './confirm-password-reset.component.html',
  styleUrls: ['./confirm-password-reset.component.scss'],
})
export class ConfirmPasswordResetComponent implements OnInit {
  createPasswordModes = CreatePasswordMode;

  constructor() {}

  ngOnInit() {}
}
