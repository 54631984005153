<h4>340B Locations</h4>

<app-patient-interaction-locations-explainer
  [expanded]="true"
  [forClient]="true" />

<div class="card">
  <div class="card-body">

    <app-patient-interaction-locations-new-locations-section />

  </div>
</div>

<div class="card mt-2">
  <div class="card-body">

    <app-patient-interaction-locations-current-locations-section />

  </div>
</div>
