import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { CaptureSnapshot } from '../models/capture-snapshot.model';

@Injectable()
export class CaptureSnapshotsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id: number): Observable<CaptureSnapshot> {
    return this.http.get<CaptureSnapshot>(`${environment.captureApi.url}/capture_snapshots/${id}`);
  }

  create(captureId: number): Observable<CaptureSnapshot> {
    return this.http.post<CaptureSnapshot>(`${environment.captureApi.url}/capture_snapshots`, {
      captureSnapshot: { captureId },
    });
  }
}
