import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CaptureStatus } from 'app/core/enums/capture-status.enum';
import { CaptureStatusReason } from 'app/core/enums/capture-status-reason.enum';
import { sortBy } from 'lodash-es';
import { displayCaptureStatus } from 'app/core/options/capture-status.opts';
import { displayCaptureStatusReason } from 'app/core/options/capture-status-reason.opts';

class SelectOption {
  status: CaptureStatus;
  displayStatus: string;
  statusHasReasons: boolean;
  disabled: boolean;
}

class CaptureStatusAndReason {
  status: CaptureStatus;
  reason: CaptureStatusReason;
}

class CaptureStatusGroupOption extends SelectOption {
}

class CaptureStatusAndReasonOption extends SelectOption {
  group: CaptureStatusGroupOption;
  reason: CaptureStatusReason;
  displayReason: string;
}

@Component({
  selector: 'app-capture-status-and-reason-select',
  templateUrl: './capture-status-and-reason-select.component.html',
})
export class CaptureStatusAndReasonSelectComponent {
  @Input() name: string;
  @Input() placeholder: string;
  @Input() multiple: boolean;

  @Output() modelChange: EventEmitter<CaptureStatusAndReason[]> =
    new EventEmitter<CaptureStatusAndReason[]>();

  internalModel: CaptureStatusAndReasonOption[];
  externalModel: CaptureStatusAndReason[];

  options: CaptureStatusAndReasonOption[];

  @Input()
  set items(items: CaptureStatusAndReason[]) {
    this.options = this.buildOptions(items);
  }

  @Input()
  set model(items: CaptureStatusAndReason[]) {
    if (items && items !== this.externalModel) {
      this.internalModel = this.buildOptions(items);
      delete this.externalModel;
    }
  }

  onModelChange(value: CaptureStatusAndReasonOption[]) {
    this.internalModel = value;

    this.externalModel = this.internalModel.map(option => ({
      status: option.status,
      reason: option.reason
    }));

    this.modelChange.emit(this.externalModel);
  }

  filterSelectItem(term: string, item: CaptureStatusAndReasonOption) {
    return [
      item.displayStatus.toLowerCase(),
      item.displayReason?.toLowerCase()
    ].filter(str => !!str).join(" - ").indexOf(term.toLowerCase()) >= 0;
  }

  private newGroupFromItem(status: CaptureStatus, displayStatus: string, statusHasReasons: boolean) {
    const group = new CaptureStatusGroupOption();
    group.status = status;
    group.displayStatus = displayStatus;
    group.statusHasReasons = statusHasReasons;
    group.disabled = true;

    return group;
  }

  groupValueFn(_groupName: string, children: CaptureStatusAndReasonOption[]): CaptureStatusGroupOption {
    return children[0].group;
  }

  private buildOptions(items: CaptureStatusAndReason[]): CaptureStatusAndReasonOption[] {
    const groups = {};

    let options = items.map(item => {
      const displayStatus = displayCaptureStatus(item.status);
      const displayReason = displayCaptureStatusReason(item.reason);
      const statusHasReasons = !!item.reason;
      const group = groups[item.status] || this.newGroupFromItem(item.status, displayStatus, statusHasReasons);

      groups[item.status] = group;

      return Object.assign({
        group,
        displayStatus,
        displayReason,
        statusHasReasons,
        disabled: false
      }, item);
    });

    options = sortBy(options, ["displayStatus", "displayReason"]);

    return options;
  }

}
