import { Component, OnInit, Input } from '@angular/core';
import { ClientStats } from 'app/core/models/client-stats.model';
import { Client } from 'app/core/models/client.model';
import { ClientsService } from 'app/core/services/clients.service';

@Component({
  selector: 'app-client-stats',
  templateUrl: './client-stats.component.html',
  styleUrls: ['./client-stats.component.scss'],
})
export class ClientStatsComponent implements OnInit {
  @Input() client: Client;
  clientStats: ClientStats;
  loading: boolean;

  constructor(
    private clientsService: ClientsService,
  ) {}

  ngOnInit() {
    this.init();
  }

  private init() {
    this.loading = true;
    this.clientsService.getStats(this.client.id).subscribe(stats => {
      this.clientStats = stats;
      this.loading = false;
    });
  }
}
