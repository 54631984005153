import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatchingPrescriberDrug } from 'app/core/models/matching-prescriber-drug.model';
import { DrugMatchVerification } from 'app/core/models/drug-match-verification.model';
import { WrittenDateWithInRangeStatus } from 'app/core/lib/verification-utils';

export class ValidateDrugMatchOption {
  constructor(
    public key: string,
    public matchingPrescriberDrug: MatchingPrescriberDrug,
    public verification: DrugMatchVerification,
    public frozen: boolean,
    public collapsed: boolean,
    public checkedWrittenDates: WrittenDateWithInRangeStatus[],
    public checkedWrittenDatesHaveWarnings: boolean
  ) {}

  get hideAdditionalDetails() {
    return this.collapsed && !(this.verification && this.verification.notes);
  }
}

@Component({
  selector: 'app-validate-drug-match-verification',
  templateUrl: './validate-drug-match-verification.component.html',
  styleUrls: ['./validate-drug-match-verification.component.scss'],
})
export class ValidateDrugMatchVerificationComponent implements OnInit {
  @Input() drugMatchOption: ValidateDrugMatchOption;
  @Input() position: number;
  @Output() verificationChange = new EventEmitter<ValidateDrugMatchOption>();

  constructor() {}

  ngOnInit() {}

  onDrugMatchesChange(dmo: ValidateDrugMatchOption) {
    this.verificationChange.emit(dmo);
  }
}
