import { Component, Input } from '@angular/core';
import { AppliedReportOptions } from 'app/core/models/report.model';
import { keyedPatientInteractionLocationEligibilityStatusOptions } from 'app/core/options/patient-interaction-location-eligibility-status.opts';

@Component({
  selector: 'app-report-applied-location-eligibility-status-option',
  templateUrl: './report-applied-location-eligibility-status-option.component.html',
})
export class ReportAppliedLocationEligibilityStatusOptionComponent {
  @Input() name = 'eligibilityStatuses';
  @Input() label = 'Eligibility Status';
  @Input() options: AppliedReportOptions;

  keyedPatientInteractionLocationEligibilityStatusOptions = keyedPatientInteractionLocationEligibilityStatusOptions;

  get values() {
    const eligibilityStatusOptions = this.options[this.name];
    if (eligibilityStatusOptions) {
      const values = eligibilityStatusOptions.map(
        v => keyedPatientInteractionLocationEligibilityStatusOptions[v].display
      );
      return values.join(', ');
    } else {
      return null;
    }
  }
}