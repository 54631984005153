import { User } from './user.model';
import { ThirdPartyAdminister } from './third-party-administer.model';

export class Client {
  id: number;
  name: string;
  fullName: string;
  humanizedName: string;
  claimIdentifierPrefix: string;
  hrsaIdStrings: string[];
  allowedClientFileEhrInstanceValues: string[] = [];
  effectiveHrsaIdStrings: string[] = [];
  effectiveClaimIdentifierPrefixes: string[] = [];
  effectiveAllowedClientFileEhrInstanceValues: string[] = [];
  faxLocal: string;
  faxTollfree: string;
  serviceDateRangeInMonths: number;
  specialistEncounterTimeframeInMonths: number;
  outreachAttemptEvidenceTimeframeInMonths: number;
  status: string;
  invoicingTerms: string;
  invoicingTermsRate: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  logoUrl: string;
  directEnabled: boolean;
  directSendingAddress: string;
  directReceivingAddress: string;
  allowResearchDataSharing: boolean;
  goLiveDate: string;
  clientFileMonitoringEmailRecipients: string[];
  assignedUsers: User[] = [];
  liveThirdPartyAdministers: ThirdPartyAdminister[] = [];
  contractedThirdPartyAdministers: ThirdPartyAdminister[] = [];
  applicableToReferralClaims: boolean;
  applicableToIccClaims: boolean;
  superClientId: number;
  superClient: { id: number; humanizedName: string };
  entityTypes: string[] = [];
  inheritsIdentifiersFromSubClients: boolean;
  firstYearEstimatedValue: number;
  annualContractValue: number;
  adjustment: number;
  submittingToEsp: boolean;
  espSubmitStartDate: string;
  espNdcs: string[];
  invalidEspNdcs: string[];
  espNdcsFilename: string;
  processingEspNdcs: boolean;
  espNdcsFileError: string;
  dateDealClosed: string;
  active: boolean;
  fileProcessingEnabled: boolean;
  outreachAttemptEvidenceEnabled: boolean;
}
