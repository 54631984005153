import { Component, OnInit } from '@angular/core';
import { SentryService } from '../../../core/services/sentry.service';
import { NavigationService } from '../../../core/services/navigation.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private sentryService: SentryService, private navigationService: NavigationService) {}

  ngOnInit() {
    this.sentryService.logMessage(`User encountered a 404. Redirected from ${this.navigationService.currentUrl}`);
  }
}
