import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { User } from '../models/user.model';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class UserAssignedClientsService extends ApiService {
  constructor(authService: AuthenticationService, private http: HttpClient) {
    super();
  }

  getList(): Observable<{ users: User[] }> {
    return this.http.get<{ users: User[] }>(`${environment.captureApi.url}/user_assigned_clients/users`);
  }
}
