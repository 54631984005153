import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { FeaturesService } from '../../core/services/features.service';

@Injectable()
export class FeatureGuard  {
  constructor(private featuresService: FeaturesService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const feature = route.data['feature'];
    return feature && this.featuresService.get()[feature] === true;
  }
}
