import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { ClientReferralVerification } from '../models/client-referral-verification.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';

export class PagedClientReferralVerifications implements PagedResults {
  clientReferralVerifications: ClientReferralVerification[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.clientReferralVerifications;
  }
}

@Injectable()
export class ClientReferralVerificationsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    filters: {
      patientPrescriberId?: number,
      patientId?: number,
      hasClientReferralTaskSubmission?: boolean,
      includeClientReferralTaskSubmission?: boolean,
      includePrescriber?: boolean
    },
    paging: { page: number; pageSize: number } = { page: 1, pageSize: 1000 }
  ): Observable<PagedClientReferralVerifications> {
    let url = `${environment.captureApi.url}/client_referral_verifications?page=${paging.page}&per_page=${
      paging.pageSize
    }`;

    if (filters.patientPrescriberId) {
      url = `${url}&patient_prescriber_id=${filters.patientPrescriberId}`;
    }

    if (filters.patientId) {
      url = `${url}&patient_id=${filters.patientId}`;
    }

    if (filters.hasClientReferralTaskSubmission) {
      url = `${url}&has_client_referral_task_submission=true`;
    }

    if (filters.includeClientReferralTaskSubmission) {
      url = `${url}&include_client_referral_task_submission=true`;
    }

    if (filters.includePrescriber) {
      url = `${url}&include_prescriber=true`;
    }

    return this.http.get<PagedClientReferralVerifications>(url);
  }

  create(patientPrescriberId: number): Observable<ClientReferralVerification> {
    return this.http.post<ClientReferralVerification>(`${environment.captureApi.url}/client_referral_verifications`, {
      clientReferralVerification: { patientPrescriberId },
    });
  }

  update(id: number, referralDate: string): Observable<ClientReferralVerification> {
    return this.http.patch<ClientReferralVerification>(
      `${environment.captureApi.url}/client_referral_verifications/${id}`,
      {
        clientReferralVerification: { referralDate },
      }
    );
  }

  destroy(id: number): Observable<ClientReferralVerification> {
    return this.http.delete<ClientReferralVerification>(
      `${environment.captureApi.url}/client_referral_verifications/${id}`,
      {}
    );
  }
}
