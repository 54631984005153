import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-task-estimated-value',
  templateUrl: './task-estimated-value.component.html',
  styleUrls: ['./task-estimated-value.component.scss'],
})
export class TaskEstimatedValueComponent implements OnInit {
  @Input() value: number;
  @Input() rank: number;

  constructor(private authService: AuthenticationService) {}

  ngOnInit() {}

  public get displayRank() {
    return !(this.authService.isInternalConsultantUser || this.authService.isCaptureAdminUser);
  }
}
