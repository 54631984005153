import { Component, Input } from '@angular/core';
import { Provider } from 'app/core/models/provider.model';
import { keyedStatusOptions } from 'app/core/options/provider-status.opts';

@Component({
  selector: 'app-provider-header',
  templateUrl: './provider-header.component.html',
  styleUrls: ['./provider-header.component.scss'],
})

export class ProviderHeaderComponent {
  @Input() provider: Provider;

  editing = false;
  keyedStatusOptions = keyedStatusOptions;

  onEdit($event) {
    $event.preventDefault();
    this.editing = true;
  }

  onEditDetailsCancel() {
    this.editing = false;
  }

  onUpdatedDetails(provider: Provider) {
    this.editing = false;
    this.provider = provider;
  }
}
