import { Component } from '@angular/core';
import { ClientTaskHistoryItemTypeBaseComponent } from '../client-task-history-item-type-base/client-task-history-item-type-base.component';

@Component({
  selector: 'app-client-task-history-item-type-transitioned',
  templateUrl: './client-task-history-item-type-transitioned.component.html',
})
export class ClientTaskHistoryItemTypeTransitionedComponent extends ClientTaskHistoryItemTypeBaseComponent {
  get status() {
    return this.clientTaskHistoryItem.data.status;
  }
}
