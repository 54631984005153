import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { ReportOption } from 'app/core/models/report-option.model';

export class ReportBooleanOption implements ReportOption {
  value: boolean;

  constructor(value) {
    this.value = value;
  }

  toPostData(options = { paramName: 'enabled' }) {
    return { [options.paramName]: this.value };
  }
}

@Component({
  selector: 'app-report-boolean-option',
  templateUrl: './report-boolean-option.component.html',
  styleUrls: ['./report-boolean-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportBooleanOptionComponent),
    },
  ],
})
export class ReportBooleanOptionComponent extends ReportAbstractOptionComponent {
  @Input() label;

  value: boolean = null;

  protected updateInternalState(model: ReportBooleanOption) {
    if (model !== null && model !== undefined) {
      this.value = model.value;
    } else {
      this.value = null;
    }
  }

  protected determineModelStateOnChange(): ReportBooleanOption {
    if (this.value !== null) {
      return new ReportBooleanOption(this.value);
    } else {
      return null;
    }
  }
}
