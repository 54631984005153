<div class="card mt-2">
  <div class="card-body">
    <h4 class="mb-2">Update Communication Method</h4>
    <div>If applicable, indicate how/where we sourced these records</div>
    <form novalidate>
      <div class="form-check" *ngFor="let communicationMethod of communicationMethods">
        <fieldset>
          <input
            (change)="onCommunicationMethodChange(communicationMethod.value)"
            type="radio"
            [id]="communicationMethod.value"
            name="communicationMethod"
            class="form-check-input"
            [disabled]="disabled"
            [(ngModel)]="selectedCommunicationMethod"
            [value]="communicationMethod.value"
          />
          <label [for]="communicationMethod.value" class="form-check-label"> {{ communicationMethod.display }} </label>
        </fieldset>
      </div>
    </form>
  </div>
</div>
