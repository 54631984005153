import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

// Originally the reviewed slider was just a mat-slide-toggle with an ngModel but for
// whatever reason every time the page would load the slider would visibly toggle from
// off to on for previously reviewed chores.  Switching to using `checked` and `change`
// on mat-slide-toggle fixed the issue.
@Component({
  selector: 'app-client-chore-review',
  templateUrl: './client-chore-review.component.html',
  styleUrls: ['./client-chore-review.component.scss'],
})
export class ClientChoreReviewComponent {
  @Output() reviewedChanged = new EventEmitter<boolean>();

  @Input() reviewed: boolean;

  onReviewChange($event: MatSlideToggleChange) {
    this.reviewed = $event.checked;
    this.reviewedChanged.emit(this.reviewed);
  }
}
