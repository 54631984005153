import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { ApiService } from './api.service';
import { ProviderCallLog } from '../models/provider-call-log.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';

export class PagedProviderCallLogs implements PagedResults {
  providerCallLogs: ProviderCallLog[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.providerCallLogs;
  }
}

@Injectable()
export class ProviderCallLogsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    filters: {
      npi: string,
      fax: string
      },
      page: number,
      pageSize: number
  ): Observable<PagedProviderCallLogs> {
    const { npi, fax } = filters;
    const searchParams = new URLSearchParams(`page=${page}&per_page=${pageSize}`);

    if (npi) {
      searchParams.append('npi', npi);
    }
    if (fax) {
      searchParams.append('fax', fax);
    }

    const url = `${environment.captureApi.url}/provider_call_logs/?${searchParams.toString()}`

    return this.http.get<PagedProviderCallLogs>(url);
  }

  getByNpi(npi: string): Observable<ProviderCallLog[]> {
    return this.http.get<ProviderCallLog[]>(`${environment.captureApi.url}/provider_call_logs/by_npi/${npi}`);
  }

  create(npi, callOutcome, fax, enteredPhone, notes): Observable<ProviderCallLog> {
    return this.http.post<ProviderCallLog>(`${environment.captureApi.url}/provider_call_logs`, {
      npi, callOutcome, fax, enteredPhone, notes
    });
  }
}
