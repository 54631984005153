import { Component, Input, OnInit } from '@angular/core';
import { Capture } from 'app/core/models/capture.model';
import { ClientPrescriptionMatch } from 'app/core/models/client-prescription-match.model';
import { PrescriptionsService } from 'app/core/services/prescriptions.service';
import { ClientPrescriptionMatchKind } from 'app/core/enums/client-prescription-match-kind.enum';
import { HttpErrorResponse } from '@angular/common/http';

class ClientPrescriptionMatchOption {
  match: ClientPrescriptionMatch;
  bestMatch: boolean;
}

@Component({
  selector: 'app-client-prescription-matches',
  templateUrl: './client-prescription-matches.component.html',
  styleUrls: ['./client-prescription-matches.component.scss'],
})
export class ClientPrescriptionMatchesComponent implements OnInit {
  @Input() capture: Capture;

  static readonly clientPrescriptionMatchKindRank = {
    [ClientPrescriptionMatchKind.cePrescriber]: 1,
    [ClientPrescriptionMatchKind.internalReferral]: 2
  };

  claimPrescriptionDrugDescriptions: string;
  claimPrescriptionDrugNdcs: string;
  clientPrescriptionMatchOptions: ClientPrescriptionMatchOption[] = [];

  constructor(private prescriptionsService: PrescriptionsService) {}

  ngOnInit() {
    const prescription = this.capture.prescription;

    this.claimPrescriptionDrugDescriptions = prescription.drugs.map(drug => drug.name).join(', ');
    this.claimPrescriptionDrugNdcs = prescription.drugs.map(drug => drug.ndc).join(', ');

    this.getClientPrescriptionMatches();
  }

  private getClientPrescriptionMatches() {
    this.prescriptionsService.getClientPrescriptionMatches(this.capture.prescription).subscribe(
      result => {
        this.clientPrescriptionMatchOptions =
          this.prepareClientPrescriptionMatchOptions(result.clientPrescriptionMatches);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  private prepareClientPrescriptionMatchOptions(clientPrescriptionMatches: ClientPrescriptionMatch[]) {
    const options =
      clientPrescriptionMatches.
      sort(this.clientPrescriptionMatchSort).
      map(this.newClientPrescriptionMatchOption);

    if (options[0]) {
      options[0].bestMatch = true
    }

    return options;
  }

  private newClientPrescriptionMatchOption(match: ClientPrescriptionMatch) {
    const option = new ClientPrescriptionMatchOption;
    option.match = match;
    return option;
  }

  private clientPrescriptionMatchSort(clientPrescriptionMatch1, clientPrescriptionMatch2) {
    return ClientPrescriptionMatchesComponent.clientPrescriptionMatchKindRank[clientPrescriptionMatch1.kind] -
           ClientPrescriptionMatchesComponent.clientPrescriptionMatchKindRank[clientPrescriptionMatch2.kind];
  }
}
