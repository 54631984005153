import { Component, Input } from '@angular/core';
import { Report, AppliedReportOptions } from 'app/core/models/report.model';
import { displayCaptureStatus } from 'app/core/options/capture-status.opts';

@Component({
  selector: 'app-report-applied-capture-status-option',
  templateUrl: './report-applied-capture-status-option.component.html',
})
export class ReportAppliedCaptureStatusOptionComponent {
  @Input() name = 'captureStatuses';
  @Input() label = 'Statuses';
  @Input() options: AppliedReportOptions;

  constructor() {}

  get values() {
    if (this.options && this.options[this.name]) {
      return this.options[this.name].map(value => displayCaptureStatus(value)).join(', ');
    }
  }
}
