export enum OutboundFaxTemplate {
  firstAttempt = 'first_attempt',
  secondAttempt = 'second_attempt',
  thirdAttempt = 'third_attempt',
  customNote = 'custom_note',
  moreRecentConsultNote = 'more_recent_consult_note',
  olderConsultNote = 'older_consult_note',
  olderDrugNotReferenced = 'older_drug_not_referenced',
  moreRecentDrugNotReferenced = 'more_recent_drug_not_referenced',
  drugNotReferenced = 'drug_not_referenced',
  thirdPartyFirstAttempt = 'third_party_first_attempt',
  thirdPartyCustomNote = 'third_party_custom_note',
}
