import { isEmpty } from 'lodash-es';
import { Component, Input, OnInit } from '@angular/core';
import { matchOptions } from './options';
import { LegacyReferralMatchVerification } from '../../../core/models/legacy-referral-match-verification.model';

@Component({
  selector: 'app-legacy-referral-match-form',
  templateUrl: './legacy-referral-match-form.component.html',
  styleUrls: ['./legacy-referral-match-form.component.scss'],
})
export class LegacyReferralMatchFormComponent implements OnInit {
  @Input() legacyReferralMatchVerification: LegacyReferralMatchVerification;
  matchOptions = matchOptions;
  showAdditionalDetails = false;

  constructor() {}

  ngOnInit() {
    this.showAdditionalDetails = !isEmpty(this.legacyReferralMatchVerification.notes);
  }

  onPlusClick() {
    this.showAdditionalDetails = true;
  }

  onMinusClick() {
    this.showAdditionalDetails = false;
  }
}
