<td>{{ audit.createdAt | date: 'M/d/yyyy h:mm a' }}</td>
<td>
  <div>Created Office</div>
  <app-audit-office-action-meta [audit]="audit" />
</td>
<td>
  <div class="overflow-auto" style="max-height: 600px;" [innerHTML]="newValueHtml"></div>
</td>
<td>--</td>
<td>{{ audit.user?.email | blankValueDash }}</td>
