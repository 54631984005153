import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-patient-name',
  templateUrl: './candidate-patient-name.component.html',
  styleUrls: ['./candidate-patient-name.component.scss'],
})
export class CandidatePatientNameComponent implements OnInit {
  @Input() candidate: { patientFullName: string; patientDob: Date };

  constructor() {}

  ngOnInit() {}
}
