<ng-select
  class="w-100"
  [name]="name"
  [placeholder]="placeholder"
  [multiple]="multiple"
  [items]="options"
  groupBy="group"
  [groupValue]="groupValueFn"
  [selectableGroup]="true"
  [hideSelected]="true"
  [searchFn]="filterSelectItem"
  [ngModel]="model"
  (ngModelChange)="onModelChange($event)">

  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-icon left" (click)="clear(item);" aria-hidden="true">×</span>
    <span class="ng-value-label">
      {{ item.displayStatus }}
      <span *ngIf="item.reason">
        - {{ item.displayReason }}
      </span>
      <span *ngIf="item.statusHasReasons && !item.reason">
        (All Reasons)
      </span>
    </span>
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    {{ item.displayStatus }}
    <span *ngIf="item.reason">
      - {{ item.displayReason }}
    </span>
  </ng-template>

  <ng-template ng-optgroup-tmp let-item="item">
    {{ item.displayStatus }}

    <span *ngIf="item.statusHasReasons">
      (All Reasons)
    </span>
  </ng-template>
</ng-select>
