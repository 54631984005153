import { Component, Input, OnInit } from '@angular/core';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { ConsultNoteQueueService } from 'app/core/services/consult-note-queue.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-consult-note-assigned-to',
  templateUrl: './consult-note-assigned-to.component.html',
  styleUrls: ['./consult-note-assigned-to.component.scss'],
})
export class ConsultNoteAssignedToComponent implements OnInit {
  @Input() patientAttachment: PatientAttachment;

  selectedUser: number;
  originalSelectedUser: number;
  canEdit = false;
  editing = false;

  constructor(
    private authService: AuthenticationService,
    private consultNoteQueueService: ConsultNoteQueueService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit() {
    this.canEdit = this.authService.hasPermissionTo(UserRoleName.consultNoteUser);
    this.originalSelectedUser = this.patientAttachment.assignedTo ?
                                  this.patientAttachment.assignedTo.id :
                                  this.authService.currentUser.id;
  }

  public get assignedToName(): string {
    if (this.patientAttachment.assignedTo) {
      return this.patientAttachment.assignedTo.fullName;
    }
  }

  public handleEdit($event) {
    $event.preventDefault();
    this.editing = true;
  }

  public handleSave($event) {
    $event.preventDefault();

    if (!this.patientAttachment.assignedTo || this.selectedUser !== this.originalSelectedUser) {
      const consultNoteIds = this.consultNoteAndRelatedConsultNoteIds();
      this.consultNoteQueueService.assignUser(this.selectedUser, consultNoteIds).subscribe((result) => {
        if (result.status === 'ok') {
          this.navigationService.reloadCurrentUrl();
        }
      });
    } else {
      this.editing = false;
    }
  }

  public handleCancel($event) {
    $event.preventDefault();
    this.editing = false;
    this.selectedUser = this.originalSelectedUser;
  }

  public handleAssignedUserChanged(id) {
    this.selectedUser = id;
  }

  private consultNoteAndRelatedConsultNoteIds(): number[] {
    const ids = [this.patientAttachment.id];
    if (this.patientAttachment.relatedPatientAttachments) {
      this.patientAttachment.relatedPatientAttachments.forEach((related) => {
        ids.push(related.id);
      });
    }
    return ids;
  }
}
