import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Note } from 'app/core/models/note.model';
import { NotesService } from 'app/core/services/notes.service';

@Component({
  selector: 'app-notes-for-model',
  templateUrl: './notes-for-model.component.html',
  styleUrls: ['./notes-for-model.component.scss'],
})
export class NotesForModelComponent implements OnInit {
  @Input() model: any;
  @Input() modelType: string;
  @Input() minimizeControls = false;
  @Input() allowAdd = true;
  @Input() noNotesMessage = "There are currently no notes.";

  @Output() noteAdded = new EventEmitter<Note>();

  notes: Note[] = [];
  loading = true;

  constructor(
    private notesService: NotesService,
  ) { }

  ngOnInit() {
    this.init();
  }

  onNoteAdded(note: Note) {
    this.notes.unshift(note);
    this.noteAdded.emit(note);
  }

  private init() {
    if (this.model.notes !== undefined) {
      this.notes = this.model.notes;
      this.loading = false;
    } else {
      this.loadNotes();
    }
  }

  private loadNotes() {
    this.loading = true;

    this.notesService.getForModel(this.model.id, this.modelType).subscribe((notes: Note[]) => {
      this.notes = notes;
      this.loading = false;
    });
  }
}