import { Injectable } from '@angular/core';
import { DeactivatedClientBaseGuard } from './deactivated-client-base-guard';
import { Router } from '@angular/router';
import { ClientsService } from 'app/core/services/clients.service';

@Injectable()
export class DeactivatedClientCePortalTasksGuard extends DeactivatedClientBaseGuard {
  constructor(protected clientService: ClientsService, protected router: Router) {
    super('/ce-portal/tasks/deactivated', clientService, router);
  }
}
