import { isNull, orderBy, last } from 'lodash-es';
import { NgForm } from '@angular/forms';
import { ClientConfigurationOptionDefinition } from '../../core/models/client-configuration-option-definition.model';
import { ClientConfigurationOption } from '../../core/models/client-configuration-option.model';
import { ClaimSourceOptions } from '../../core/options/claim-source.opts';

export const DraftId = '$draft$';
export const BaseSuffix = '-base';
export const ValueSuffix = '-value';
export const StartAtSuffix = '-start-at';
export const EndAtSuffix = '-end-at';

export const AttributeToControlSuffix = {
  value: ValueSuffix,
  startAt: StartAtSuffix,
  endAt: EndAtSuffix,
  base: BaseSuffix,
};

export enum EditorType {
  basic,
  dated,
  suboptions,
}

export enum ValueType {
  bool = 'boolean',
  integer = 'integer',
}

export enum SuboptionType {
  claimSource = 'claim_source',
}

export interface DefinitionsMap {
  [optionName: string]: ClientConfigurationOptionDefinition;
}

export interface ConfigsMap {
  [identifier: string]: EditableConfiguration;
}

export class ValidationError {
  constructor(public identifier: string, public message: string) {}
}

export class SuboptionChoice {
  constructor(
    public identifier: string,
    public selection: string | null = null,
    public value: string | boolean | null = null,
    public errors: ValidationError[] = [],
    public deleted: boolean = false
  ) {}
}

export class EditableConfiguration {
  constructor(
    public identifier: string,
    public configOption: ClientConfigurationOption,
    public definition: ClientConfigurationOptionDefinition,
    public editorType: EditorType,
    public deactivated: boolean = false,
    public suboptionChoices: SuboptionChoice[] = [],
    public errors: ValidationError[] = [],
    public deleted: boolean = false
  ) {}
}

export const SuboptionTypeDescriptions = {
  [SuboptionType.claimSource]: 'Claim Source',
};

export const SuboptionTypeChoices = {
  [SuboptionType.claimSource]: ClaimSourceOptions,
};

export const determineEditorType = (definition: ClientConfigurationOptionDefinition) => {
  if (definition.dated) {
    return EditorType.dated;
  }

  if (definition.suboptionType) {
    return EditorType.suboptions;
  }

  return EditorType.basic;
};

export const determineDeactivated = (
  definition: ClientConfigurationOptionDefinition,
  config: ClientConfigurationOption
) => {
  if (definition.dated) {
    return !isNull(config.endAt) && !config.value;
  }

  return false;
};

export const sortConfigs = (configs: EditableConfiguration[]) =>
  orderBy(configs, [c => c.definition.title, c => c.configOption.startAt], ['asc', 'asc']);

const findConfigByName = (name: string, actualConfigsByIdentifier: ConfigsMap) =>
  Object.values(actualConfigsByIdentifier).find(eco => eco.definition.option === name);

const isDatedDefinitionSelectable = (name: string, actualConfigsByIdentifier: ConfigsMap) => {
  const matches = Object.values(actualConfigsByIdentifier).filter(
    config => config.definition.option === name && !config.deactivated
  );

  return matches.length === 0;
};

export const isDefinitionSelectable = (
  definition: ClientConfigurationOptionDefinition,
  actualConfigsByIdentifier: ConfigsMap
) => {
  if (!definition.editable) {
    return false;
  }

  if (!definition.dated) {
    const matchingConfig = findConfigByName(definition.option, actualConfigsByIdentifier);
    return !(matchingConfig && !matchingConfig.deleted);
  } else {
    return isDatedDefinitionSelectable(definition.option, actualConfigsByIdentifier);
  }
};

export const generateConfigIdentifier = (optionName: string, id: string | null = null,
                                         suboptionIndex: number | null = null) => {
  let identifier: string;

  if (!isNull(id)) {
    identifier = `${optionName}-${id}`;
  } else {
    identifier = `${optionName}-${DraftId}`;
  }

  if (!isNull(suboptionIndex)) {
    identifier = `${identifier}-${suboptionIndex}`;
  }

  return identifier;
};

const valueToSuboptions = (name, id, valueObj): SuboptionChoice[] => {
  if (valueObj && Object.keys(valueObj).length > 0) {
    return Object.keys(valueObj).map((key, idx) => {
      const identifier = generateConfigIdentifier(name, id, idx);
      return new SuboptionChoice(identifier, key, valueObj[key]);
    });
  } else {
    const identifier = generateConfigIdentifier(name, id, 0);
    return [new SuboptionChoice(identifier)];
  }
};

export const initializeSuboptionChoices = (od: ClientConfigurationOptionDefinition, eco: EditableConfiguration) => {
  if (eco.editorType === EditorType.suboptions) {
    eco.suboptionChoices = valueToSuboptions(od.option, eco.configOption.id, eco.configOption.value);
  }
};

const isControlInvalid = (form: NgForm, controlName: string) => {
  const control = form.controls[controlName];

  if (control) {
    return form.submitted && !control.disabled && !control.valid;
  } else {
    return false;
  }
};

// the attribute key on the 'error' from rails is expected to be either an array or an array of arrays for suboptions
const resolveSuboptionServerErrorMessages = errorAttrItems => {
  const errorMessagesBySelection = {};

  if (errorAttrItems && errorAttrItems.length > 0) {
    errorAttrItems.forEach(evi => {
      if (evi.length === 2) {
        errorMessagesBySelection[evi[0]] = evi[1];
      }
    });
  }

  return errorMessagesBySelection;
};

const addError = (form: NgForm, errors: ValidationError[], controlName: string, errorMessage: string) => {
  const modifiedErrors = [...errors];

  if (isControlInvalid(form, controlName) || errorMessage) {
    const error = new ValidationError(controlName, errorMessage);
    const index = modifiedErrors.findIndex(err => err.identifier === controlName);

    if (index > -1) {
      modifiedErrors[index] = error;
    } else {
      modifiedErrors.push(error);
    }
  }

  return modifiedErrors;
};

const resolveServerErrorMessage = (attributeErrorItems: string[]) =>
  (attributeErrorItems && attributeErrorItems.length > 0 && last(attributeErrorItems)) || null;

export const refreshConfigValidity = (form: NgForm, config: EditableConfiguration, serverError = null) => {
  config.errors = [];

  Object.keys(AttributeToControlSuffix).forEach(attr => {
    const serverErrorItems = (serverError && serverError[attr]) || [];
    const controlSuffix = AttributeToControlSuffix[attr];

    if (config.editorType === EditorType.suboptions) {
      const errorMessagesBySelection = resolveSuboptionServerErrorMessages(serverErrorItems);
      config.suboptionChoices.forEach((sc, index) => {
        const errorMessage = errorMessagesBySelection[sc.selection];
        const controlName = `${config.identifier}-${index}${controlSuffix}`;
        sc.errors = addError(form, sc.errors, controlName, errorMessage);
      });
    } else {
      const errorMessage = resolveServerErrorMessage(serverErrorItems);
      const controlName = `${config.identifier}${controlSuffix}`;
      config.errors = addError(form, config.errors, controlName, errorMessage);
    }
  });
};

export const normalizeConfigValueForUpdate = (eco: EditableConfiguration) => {
  if (eco.editorType === EditorType.suboptions) {
    return eco.suboptionChoices.reduce((value, suboption) => {
      if (suboption.selection) {
        value[suboption.selection] = suboption.value;
      }
      return value;
    }, {}) as { key: string; value: string | boolean };
  }
  return eco.configOption.value;
};

export const isSuboptionTypeChoiceAvailable = (
  typeChoice: { value: string },
  choice: SuboptionChoice,
  otherChoices: SuboptionChoice[]
) => {
  if (typeChoice.value === choice.selection) {
    return true;
  }

  return !otherChoices.filter(oc => oc.identifier !== choice.identifier).some(oc => oc.selection === typeChoice.value);
};

export const isPersisted = (configOption: ClientConfigurationOption) => !!configOption.id;
