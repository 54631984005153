import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { debounce } from 'lodash-es';
import { ClientsService } from '../../../core/services/clients.service';
import { ConsultNoteQueueService } from '../../../core/services/consult-note-queue.service';
import { PatientAttachmentStatus } from '../../../core/enums/patient-attachment-status.enum';
import { UserAssignedClientsService } from 'app/core/services/user-assigned-clients.service';
import {
  ConsultNoteQueueFilters,
  ConsultNoteQueueSettings,
} from '../../../core/models/user-settings/consult-note-queue-settings.model';
import { ConsultNoteQueueCursorType } from '../../../core/enums/consult-note-queue-cursor-type.enum';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { StateOptions } from '../../../core/options/states.opts';
import { UsersService } from 'app/core/services/users.service';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { User } from 'app/core/models/user.model';
import { displayPatientAttachmentStatus } from 'app/core/options/patient-attachment-status.opts';

const CursorTypeOptions = [
  { value: ConsultNoteQueueCursorType.mostRecentlyCreated, display: 'Date Received: Newest' },
  { value: ConsultNoteQueueCursorType.leastRecentlyCreated, display: 'Date Received: Oldest' },
  { value: ConsultNoteQueueCursorType.highestPendingEstimatedValue, display: 'Pending Office Estimated Value' },
  { value: ConsultNoteQueueCursorType.highestWipEstimatedValue, display: 'WIP Office Estimated Value' },
  { value: ConsultNoteQueueCursorType.highestPendingHighValueCount, display: 'Count High Value Pending at Office' },
  { value: ConsultNoteQueueCursorType.highestPriority, display: 'Highest Priority' },
];

@Component({
  selector: 'app-consult-note-queue-settings',
  templateUrl: './consult-note-queue-settings.component.html',
  styleUrls: ['./consult-note-queue-settings.component.scss'],
})
export class ConsultNoteQueueSettingsComponent implements OnInit {
  refreshResultCount$: Subject<void> = new Subject();
  filters: ConsultNoteQueueFilters = new ConsultNoteQueueFilters();
  cursorType: ConsultNoteQueueCursorType = ConsultNoteQueueCursorType.highestPriority;
  updatingResultCount = false;
  resultCount = 0;
  ignoreStatusReasonChange = false;

  statusFilterOptions = [];
  clientFilterOptions = [];
  assignedClientLeadsFilterOptions = [];
  assignedToFilterOptions = [];
  cursorTypeOptions = CursorTypeOptions;
  stateOptions = StateOptions;

  debouncedHandleSettingsChange = debounce(
    () => {
      this.saveUserSettings();
      this.refreshResultCount$.next();
      this.settingsChange.emit();
    },
    250,
    { maxWait: 1000 }
  );

  @Output() public settingsChange = new EventEmitter();

  constructor(
    private clientsService: ClientsService,
    private consultNoteQueueService: ConsultNoteQueueService,
    private userAssignedClientsService: UserAssignedClientsService,
    private usersService: UsersService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.initStatusFilterOptions();
    this.initClientFilterOptions();
    this.initAssignedClientLeadsFilterOptions();
    this.initAssignedToFilterOptions();
    this.loadUserSettings();
    this.saveUserSettings();
    this.configureRefreshResultCount();
    this.refreshResultCount$.next();
  }

  onStatusFilterChange() {
    this.handleSettingsChange();
  }

  onFilterChange() {
    this.handleSettingsChange();
  }

  onCursorTypeChange() {
    this.handleSettingsChange();
  }

  onResetClick($event) {
    $event.preventDefault();
    this.filters = new ConsultNoteQueueFilters();
    this.handleSettingsChange();
  }

  private initStatusFilterOptions() {
    this.statusFilterOptions = Object.values(PatientAttachmentStatus).map(v => ({
      value: v,
      display: displayPatientAttachmentStatus(v),
    }));
  }

  private initClientFilterOptions() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clientFilterOptions = result.clients;
    });
  }

  private initAssignedClientLeadsFilterOptions() {
    this.userAssignedClientsService.getList().subscribe(result => {
      this.assignedClientLeadsFilterOptions = result.users;
    });
  }

  private initAssignedToFilterOptions() {
    this.usersService.getByUserRole([UserRoleName.consultNoteUser]).subscribe((result) => {
      const users = this.orderUsersByCurrentUserFirst(result.users);
      this.assignedToFilterOptions = [{ id: -1, fullName: 'UNASSIGNED' }].concat(users);
    });
  }

  private handleSettingsChange() {
    this.debouncedHandleSettingsChange();
  }

  private loadUserSettings() {
    const queueSettings = this.consultNoteQueueService.getUserSettings();
    this.filters = queueSettings.filters;
    this.cursorType = queueSettings.cursorType;
  }

  private saveUserSettings() {
    this.consultNoteQueueService.saveUserSettings(this.currentUserSettings);
  }

  private get currentUserSettings() {
    const {
      statuses,
      clientIds,
      clientStates,
      specialtyStore,
      highValue,
      expiringWithinDays,
      outboundFaxNumber,
      assignedClientLeadIds,
      assignedToIds,
    } = this.filters;
    const filters = new ConsultNoteQueueFilters(
      statuses,
      clientIds,
      clientStates,
      specialtyStore,
      highValue,
      expiringWithinDays,
      outboundFaxNumber,
      assignedClientLeadIds,
      assignedToIds
    );
    return new ConsultNoteQueueSettings(filters, this.cursorType);
  }

  private configureRefreshResultCount() {
    // https://www.credera.com/blog/technology-solutions/using-rxjs-switchmap-angular-7-reactive-forms-cancel-pending-requests/

    this.refreshResultCount$
      .pipe(
        tap(() => {
          this.updatingResultCount = true;
        }),
        switchMap(() => this.consultNoteQueueService.getCount())
      )
      .subscribe(({ count }) => {
        this.updatingResultCount = false;
        this.resultCount = count;
      });
  }

  private orderUsersByCurrentUserFirst(users: User[]): User[] {
    const orderedUsers = [];
    const currentUser = this.authService.currentUser;
    users.forEach((user) => {
      if (user.id === currentUser.id) {
        orderedUsers.unshift(user);
      } else {
        orderedUsers.push(user);
      }
    });
    return orderedUsers;
  }
}
