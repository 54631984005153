import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ConsultNoteQueueResult } from '../../../core/models/consult-note-lists/consult-note-queue-result.model';

@Component({
  selector: 'app-consult-note-queue-results',
  templateUrl: './consult-note-queue-results.component.html',
  styleUrls: ['./consult-note-queue-results.component.scss'],
})
export class ConsultNoteQueueResultsComponent {
  @Input() consultNoteQueueResults: ConsultNoteQueueResult[] = [];
  @Input() selectedConsultNoteIds: number[] = [];
  @Input() canAssignUser = false;
  @Input() myWorkQueue = false;

  @Output() selectionChanged = new EventEmitter<ConsultNoteQueueResult>();

  constructor() {}

  public isSelected(consultNote): boolean {
    return this.selectedConsultNoteIds.includes(consultNote.id);
  }

  public handleConsultNoteSelection(consultNote: ConsultNoteQueueResult) {
    this.selectionChanged.emit(consultNote);
  }
}
