import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LlmPlaygroundBatchesService } from '../../../core/services/llm-playground-batches.service';
import { DownloadService } from '../../../core/services/download.service';
import { BatchDetail } from '../../../core/models/llm-playground.model';

@Component({
  selector: 'app-llm-playground-batch',
  templateUrl: './llm-playground-batch.component.html',
  styleUrls: ['./llm-playground-batch.component.scss'],
})
export class LlmPlaygroundBatchComponent implements OnInit {
  batch: BatchDetail;
  isLoading = true;
  loadingError = '';

  constructor(
    private route: ActivatedRoute,
    private downloadService: DownloadService,
    private llmPlaygroundBatchesService: LlmPlaygroundBatchesService,
    private titleService: Title,
    private router: Router
  ) { }

  ngOnInit(): void {
    const batchId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.loadBatch(batchId);
  }

  onDownloadBatchResults(event: Event, id: number): void {
    event.preventDefault();
    this.llmPlaygroundBatchesService.csv(id).subscribe(
      data => {
        const blob = new Blob([data.blob], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        this.downloadService.download(url, data.filename);
      },
      error => {
        console.log(error);
        this.loadingError = 'error fetching batch CSV. ' + error.message;
      }
    );
  }

  private loadBatch(batchId: number) {
    this.llmPlaygroundBatchesService.get(batchId, true).pipe(
      catchError(error => throwError(error))
    ).subscribe({
      next: (batch) => {
        this.batch = batch;
        this.isLoading = false;
        this.titleService.setTitle(this.batch.name);
      },
      error: (error) => {
        if (error.status === 404) {
          this.router.navigate(['/404'])
        } else {
          console.log('Error fetching batch: ', error.status)
        }
      }
    });
  }
}
