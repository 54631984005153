<div *ngIf="!loading; else: spinner">
  <ul *ngIf="notes.length > 0; else: noNotes" class="list-unstyled">
    <li *ngFor="let note of notes">
      <app-note [note]="note" />
    </li>
  </ul>
  <app-notes-for-model-add
    *ngIf="allowAdd"
    [model]="model"
    [modelType]="modelType"
    [minimizeControls] = "minimizeControls"
    (noteAdded)="onNoteAdded($event)"
  />
</div>
<ng-template #noNotes>
  <p *ngIf="noNotesMessage">{{ noNotesMessage }}</p>
  <app-blank-value-dash *ngIf="!noNotesMessage" />
</ng-template>
<ng-template #spinner> <mat-spinner /> </ng-template>
