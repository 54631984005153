import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isSuboptionTypeChoiceAvailable, SuboptionChoice } from '../../lib/client-configuration';

@Component({
  selector: ' app-client-configuration-suboption',
  templateUrl: './client-configuration-suboption.component.html',
  styleUrls: ['./client-configuration-suboption.component.scss'],
})
export class ClientConfigurationSuboptionComponent {
  @Input() suboptionTypeChoices;
  @Input() definition: { dataType: string; editable: boolean };
  _suboptionChoices: SuboptionChoice[];
  _suboptionChoice: SuboptionChoice;

  @Output() choiceChange: EventEmitter<SuboptionChoice> = new EventEmitter<SuboptionChoice>();
  @Output() choiceRemove: EventEmitter<SuboptionChoice> = new EventEmitter<SuboptionChoice>();

  availableSuboptionTypeChoices = [];

  constructor() {}

  get suboptionChoices(): SuboptionChoice[] {
    return this._suboptionChoices;
  }

  @Input()
  set suboptionChoices(choices: SuboptionChoice[]) {
    this._suboptionChoices = choices;
    this.initializeAvailableChoices();
  }

  get suboptionChoice(): SuboptionChoice {
    return this._suboptionChoice;
  }

  @Input()
  set suboptionChoice(choice: SuboptionChoice) {
    this._suboptionChoice = choice;
    this.initializeAvailableChoices();
  }

  onSelectionChange(selection: string) {
    const modifiedChoice: SuboptionChoice = Object.assign({}, this.suboptionChoice);
    modifiedChoice.selection = selection;
    this.choiceChange.emit(modifiedChoice);
  }

  onValueChange(value: any) {
    const modifiedChoice: SuboptionChoice = Object.assign({}, this.suboptionChoice);
    modifiedChoice.value = value;
    this.choiceChange.emit(modifiedChoice);
  }

  onDeleteClick($event) {
    $event.preventDefault();
    this.choiceRemove.emit(this.suboptionChoice);
  }

  private initializeAvailableChoices() {
    if (this.suboptionChoice && this.suboptionChoices) {
      this.availableSuboptionTypeChoices = this.suboptionTypeChoices.filter(typeChoice =>
        isSuboptionTypeChoiceAvailable(typeChoice, this.suboptionChoice, this.suboptionChoices)
      );
    }
  }
}
