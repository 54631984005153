import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { SamlProvider } from 'app/core/models/saml-provider.model';
import { ApiService } from './api.service';

@Injectable()
export class SamlProvidersService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(): Observable<{ samlProviders: SamlProvider[] }> {
    return this.http.get<{ samlProviders: SamlProvider[] }>(`${environment.captureApi.url}/saml_providers`);
  }
}
