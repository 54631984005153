import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { PatientInteractionLocation } from 'app/core/models/patient-interaction-location.model';
import { ApiService } from './api.service';
import { PagedResults } from 'app/core/models/paged-results/paged-results.model';
import { LimitOffsetPaging, LimitOffsetPagingMeta } from 'app/core/models/paged-results/limit-offset-paging.model';
import { PatientInteractionLocationFilters } from 'app/core/models/user-settings/patient-interaction-location-settings.model';

export class PagedPatientInteractionLocations implements PagedResults {
  patientInteractionLocations: PatientInteractionLocation[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.patientInteractionLocations;
  }
}

@Injectable()
export class PatientInteractionLocationsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    paging: LimitOffsetPaging = null,
    filters: PatientInteractionLocationFilters = null
  ): Observable<PagedPatientInteractionLocations> {
    const params = new URLSearchParams();

    if (paging) {
      params.append('page', paging.currentPage.toString());
      params.append('per_page', paging.pageSize.toString());
    }

    if (filters) {
      if (filters.clientIds) {
        filters.clientIds.forEach(clientId => params.append('client_ids[]', clientId));
      }

      if (filters.assignedClientLeadUserIds) {
        filters.assignedClientLeadUserIds.forEach(userId=> params.append('assigned_client_leads_ids[]', userId));
      }

      if (filters.clientEntityTypes) {
        filters.clientEntityTypes.forEach(entityType => params.append('client_entity_types[]', entityType));
      }

      if (filters.eligibilityStatuses) {
        filters.
          eligibilityStatuses.
          forEach(eligibilityStatus => params.append('eligibility_statuses[]', eligibilityStatus));
      }

      if (filters.name) {
        params.append('name', filters.name);
      }
    }

    return this.http.get<PagedPatientInteractionLocations>(`${environment.captureApi.url}/patient_interaction_locations?${params.toString()}`);
  }

  update(id, attributes: any): Observable<PatientInteractionLocation> {
    return this.http.patch<PatientInteractionLocation>(`${environment.captureApi.url}/patient_interaction_locations/${id}`, attributes);
  }
}
