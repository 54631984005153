import { Component, Input } from '@angular/core';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { Provider } from 'app/core/models/provider.model';
import { keyedStatusOptions as keyedProviderStatusOptions } from 'app/core/options/provider-status.opts';
import { keyedStatusOptions as keyedOfficeStatusOptions } from 'app/core/options/office-status.opts';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';
import { Office } from 'app/core/models/office.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Note } from 'app/core/models/note.model';

@Component({
  selector: 'app-provider-office-association-notes-modal',
  templateUrl: './provider-office-association-notes-modal.component.html',
  styleUrls: ['./provider-office-association-notes-modal.component.scss'],
})
export class ProviderOfficeAssociationNotesModalComponent {
  @Input() providerOffice: ProviderOffice;

  changes = false;

  keyedProviderStatusOptions = keyedProviderStatusOptions;
  keyedOfficeStatusOptions = keyedOfficeStatusOptions;
  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;

  constructor(private activeModal: NgbActiveModal) {}

  public get provider(): Provider {
    return this.providerOffice.provider;
  }

  public get office(): Office {
    return this.providerOffice.office;
  }

  onNoteAdded(_note: Note) {
    this.changes = true;
  }

  onCloseClick() {
    this.activeModal.close(this.changes);
  }
}