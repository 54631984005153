export class Cursor<T> {
  name: T;
  value: string;
  id: number;
}

export class CursorBasedPagingMeta<C> {
  cursor: Cursor<C> = new Cursor<C>();
}

export class NextResult {
  public id: number;
}
