import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prescriberNameWithNpi',
})
export class PrescriberNameWithNpiPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const parts = [];
      parts.push(value.fullName);

      if (value.npi) {
        parts.push(`(NPI ${value.npi})`);
      }

      return parts.join(' ');
    } else {
      return value;
    }
  }
}
