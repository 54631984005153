import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-patient-interaction-locations-explainer',
  templateUrl: './patient-interaction-locations-explainer.component.html',
  styleUrls: ['./patient-interaction-locations-explainer.component.scss'],
})
export class PatientInteractionLocationsExplainerComponent {
  @Input() expanded = true;
  @Input() forClient = false;

  @Output() expandOrCollapse = new EventEmitter<boolean>();

  onExpandOrCollapseClick(expanded: boolean) {
    this.expanded = expanded;
    this.expandOrCollapse.emit(expanded);
  }
}
