export enum LoginAttemptStatus {
  Authenticated,
  InvalidCredentials,
  AccountLocked,
  AccountDisabled,
  ForceResetPassword,
  MFARequired,
  DeviceNotAuthorized,
  UnexpectedFailure,
}
