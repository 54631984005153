import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './client-portal-client-referral-task-reports.component.html',
})
export class ClientPortalClientReferralTaskReportsComponent {
  reportsUrl = `${environment.captureApi.url}/client_referral_task_reports`;

  constructor() {}
}
