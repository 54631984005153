import { Component, OnInit } from '@angular/core';
import {
  ClientConsultNoteTasksService,
  PagedClientConsultNoteTasks,
} from '../../../core/services/client-consult-note-tasks.service';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';
import { LimitOffsetPaging, Sort } from '../../../core/models/paged-results/limit-offset-paging.model';
import { UserSettings } from '../../../core/enums/user-settings.enum';
import { TaskFilters, TaskQueueSettings } from '../../../core/models/user-settings/task-settings.model';
import { UserSettingsService } from '../../../core/services/user-settings.service';
import { ClientConsultNoteTaskStatus } from '../../../core/enums/client-consult-note-task-status.enum';
import { ClientsService } from 'app/core/services/clients.service';
import { displayTaskStatus } from 'app/core/options/task-status.opts';

@Component({
  selector: 'app-client-portal-client-consult-note-tasks',
  templateUrl: './client-portal-client-consult-note-tasks.component.html',
  styleUrls: ['./client-portal-client-consult-note-tasks.component.scss'],
})
export class ClientPortalClientConsultNoteTasksComponent implements OnInit {
  tasks: ClientConsultNoteTask[] = [];
  filters: TaskFilters = new TaskFilters();
  taskStatuses = [];
  specialties = [];
  manufacturers = [];
  sources = [];

  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  sort: Sort = new Sort();

  loading = true;

  exportUrl = "/ce-portal/reports/client-consult-note-task-reports";

  constructor(
    private clientConsultNoteTasksService: ClientConsultNoteTasksService,
    private userSettingsService: UserSettingsService,
    private clientsService: ClientsService
  ) {}

  ngOnInit() {
    this.init();
  }

  filtersChange(filters) {
    this.filters = filters;
    this.loadTasks();
    this.saveQueueSettings();
  }

  onPageChange(page) {
    this.loadTasks(page);
    this.saveQueueSettings();
  }

  onPageSizeChange(pageSize) {
    this.paging.pageSize = pageSize;
    this.loadTasks(this.paging.currentPage);
    this.saveQueueSettings();
  }

  onSortChange(sort: Sort) {
    this.sort = sort;
    this.saveQueueSettings();
    this.loadTasks();
  }

  onViewTask(position: number) {
    this.saveQueueSettings(position);
  }

  private init() {
    this.loadStatuses();
    this.loadQueueSettings();
    this.loadSpecialties();
    this.loadManufacturers();
    this.loadSources();
    this.loadTasks();
  }

  private loadTasks(page = this.paging.currentPage) {
    this.loading = true;

    const paging = { page, pageSize: this.paging.pageSize };

    this.clientConsultNoteTasksService
      .getList(paging, this.filters, this.sort)
      .subscribe((result: PagedClientConsultNoteTasks) => {
        this.tasks = result.clientConsultNoteTasks;
        this.paging = result.meta.paging;
        this.sort = result.meta.sort || new Sort();
        this.loading = false;
      });
  }

  private loadSpecialties() {
    this.clientsService.getSpecialties().subscribe(result => {
      this.specialties = result;
    });
  }

  private loadManufacturers() {
    this.clientConsultNoteTasksService.getManufacturers().subscribe(result => {
      this.manufacturers = result;
    });
  }

  private loadSources() {
    this.clientConsultNoteTasksService.getSources().subscribe(result => {
      this.sources = result;
    });
  }

  private loadStatuses() {
    this.taskStatuses = Object.values(ClientConsultNoteTaskStatus).map(v => ({
      value: v,
      display: displayTaskStatus(v),
    }));
  }

  private loadQueueSettings() {
    const queueSettings: TaskQueueSettings = this.userSettingsService.get<TaskQueueSettings>(
      UserSettings.clientPortalConsultNoteTaskQueueSettings
    );

    if (queueSettings) {
      this.paging = queueSettings.paging;
      this.filters = queueSettings.filters;
      this.sort = queueSettings.sort;
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new TaskQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    queueSettings.filters = this.filters;
    queueSettings.sort = this.sort;
    this.userSettingsService.save<TaskQueueSettings>(
      UserSettings.clientPortalConsultNoteTaskQueueSettings,
      queueSettings
    );
  }
}
