import { Component, Input, EventEmitter, OnChanges, OnInit, Output, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Capture } from '../../../core/models/capture.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PagedPatientAttachments, PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { CaptureService } from '../../../core/services/capture.service';
import { CaptureValidationService, ValidationKeys } from '../../../core/services/capture-validation.service';
import { DownloadService } from '../../../core/services/download.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { AppToastService } from 'app/core/services/app-toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerError } from '../../../core/models/server-error.model';
import { showCaptureTransitionedToast, canModifyPatientAttachment } from 'app/core/lib/verification-utils';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';

@Component({
  selector: 'app-patient-documents',
  templateUrl: './patient-documents.component.html',
  styleUrls: ['./patient-documents.component.scss'],
  providers: [DatePipe],
})
export class PatientDocumentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;
  @Input() selectedAttachment: PatientAttachment;

  @Output() attachmentSelectionChange = new EventEmitter<PatientAttachment>();

  loading = true;
  canModifySelectedAttachment = true;

  allPatientAttachments: PatientAttachment[] = [];
  selectablePatientAttachments: PatientAttachment[] = [];

  serverFailure: { error: ServerError } = null;
  patientAttachmentValidationKey = ValidationKeys.verifyingPatientAttachment;

  showAddEhrConsultNoteReferenceForm = false;

  patientAttachmentChangedSubscription = null;

  constructor(
    private captureValidationService: CaptureValidationService,
    private patientAttachmentsService: PatientAttachmentsService,
    private authService: AuthenticationService,
    private downloadService: DownloadService,
    private captureService: CaptureService,
    private toastService: AppToastService
  ) { }

  ngOnInit() {
    this.patientAttachmentsService
      .getList(this.capture.patient.id)
      .subscribe((pagedPatientAttachments: PagedPatientAttachments) => {
        this.allPatientAttachments = pagedPatientAttachments.patientAttachments
        this.selectablePatientAttachments = this.allPatientAttachments.filter(this.isSelectableAttachment);

        this.loading = false;
      });

    this.patientAttachmentChangedSubscription = this.patientAttachmentsService.patientAttachmentChanged.subscribe(
      ({ patientAttachment }) => {
        this.replacePatientAttachment(patientAttachment);
      }
    );
  }

  ngOnChanges() {
    this.canModifySelectedAttachment = canModifyPatientAttachment(this.authService, this.capture);
  }

  ngOnDestroy() {
    if (this.patientAttachmentChangedSubscription) {
      this.patientAttachmentChangedSubscription.unsubscribe();
    }
  }

  onSelectedAttachmentChange(patientAttachmentId: number): void {
    this.captureValidationService.clearVerifyingAttachmentErrors();
    const index = this.selectablePatientAttachments.findIndex(pa => pa.id === patientAttachmentId);

    if (index > -1) {
      this.attachmentSelectionChange.emit(this.selectablePatientAttachments[index]);
    }
  }

  onAssociatedAttachmentChange({
    isAssociated,
    patientAttachment,
  }: {
    isAssociated: boolean;
    patientAttachment: PatientAttachment;
  }) {
    this.setCaptureVerifyingPatientAttachment(isAssociated ? patientAttachment : null);
  }

  onMarkedAttachmentAsInvalidChange(patientAttachment: PatientAttachment) {
    this.patientAttachmentsService
      .markAsInvalid(patientAttachment.id, patientAttachment.markedAsInvalid)
      .subscribe((updatedPatientAttachment: PatientAttachment) => {
        this.patientAttachmentsService.notifyPatientAttachmentChanged(updatedPatientAttachment);
      });

    if (patientAttachment.markedAsInvalid && this.capture.verifyingPatientAttachmentId === patientAttachment.id) {
      this.setCaptureVerifyingPatientAttachment(null);
    }
  }

  onDownloadAttachmentClick($event: MouseEvent, attachment: PatientAttachment): void {
    $event.preventDefault();

    this.patientAttachmentsService.get(attachment.id).subscribe(patientAttachment => {
      this.downloadService.download(patientAttachment.url);
    });
  }

  onAddEhrConsultNoteReferenceClick() {
    this.showAddEhrConsultNoteReferenceForm = true;
  }

  onNewPatientAttachment(newPatientAttachment: PatientAttachment) {
    // Show attachment as selected immediately while persisting
    this.capture.verifyingPatientAttachmentId = newPatientAttachment.id;

    // Use concat instead of push to force update of child components using this array
    this.allPatientAttachments = this.allPatientAttachments.concat(newPatientAttachment);

    this.showAddEhrConsultNoteReferenceForm = false;
    this.selectedAttachment = newPatientAttachment;

    if (this.isSelectableAttachment(newPatientAttachment)) {
      this.selectablePatientAttachments.push(newPatientAttachment);
      this.onSelectedAttachmentChange(newPatientAttachment.id);
    }

    this.setCaptureVerifyingPatientAttachment(newPatientAttachment);
  }

  onAddEhrConsultNoteReferenceCanceled() {
    this.showAddEhrConsultNoteReferenceForm = false;
  }

  get attachmentSelectedAndVisible() {
    return this.selectedAttachment &&
      this.selectedAttachment.ehrSupported &&
      !this.showAddEhrConsultNoteReferenceForm;
  }

  private setCaptureVerifyingPatientAttachment(attachment: PatientAttachment) {
    this.serverFailure = null;

    this.captureService.setVerifyingPatientAttachment(this.capture, attachment).subscribe(
      capture => {
        this.captureValidationService.clearVerifyingAttachmentErrors();
        this.showCaptureTransitionedToast(this.capture.status, capture.status);
        this.captureService.notifyCaptureChanged(capture);
      },
      (err: HttpErrorResponse) => {
        this.serverFailure = { error: null };
        if (err && err.error && err.error.summary && err.error.details) {
          this.serverFailure.error = err.error;
        }
      }
    );
  }

  private showCaptureTransitionedToast(initialStatus: string, newStatus: string) {
    if (initialStatus !== newStatus) {
      showCaptureTransitionedToast(this.toastService, newStatus);
    }
  }

  private replacePatientAttachment(patientAttachment: PatientAttachment) {
    const index = this.allPatientAttachments.findIndex(pa => pa.id === patientAttachment.id);

    if (index > -1) {
      this.allPatientAttachments[index] = patientAttachment;
    }

    this.allPatientAttachments = [...this.allPatientAttachments];
    this.selectablePatientAttachments = this.allPatientAttachments.filter(this.isSelectableAttachment);
  }

  private isSelectableAttachment(patientAttachment: PatientAttachment) {
    return patientAttachment.ehrSupported;
  }
}
