import { LimitOffsetPaging } from '../paged-results/limit-offset-paging.model';

export class ClientChoreFilters {
  selectedClientIds = [];
  selectedUserIds = [];
  selectedStatuses = [];
  selectedNames = [];
  inactive = false;
  requiresReview = false;
}

export class ClientChoreListSettings {
  filters: ClientChoreFilters = new ClientChoreFilters();
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
}
