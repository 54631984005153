import { Injectable } from '@angular/core';
import { ParseResult, parse } from 'papaparse';
import { Observable } from 'rxjs';
import { FilePreview } from '../models/file-preview.model';

@Injectable()
export class FilePreviewService {
  constructor() {}

  preview(file: File): Observable<FilePreview> {
    return new Observable(observer => {
      parse(file, {
        complete: (result: ParseResult<Record<string, any>>) => {
          observer.next(new FilePreview(result.meta.fields, result.data));
          observer.complete();
        },
        preview: 10,
        header: true,
      });
    });
  }
}
