import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn, UntypedFormControl } from '@angular/forms';

export const PhoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;

const validatePhoneNumberFactory = (): ValidatorFn => (c: AbstractControl) => {
    if (PhoneRegExp.test(c.value)) {
      return null;
    } else {
      return {
        phoneNumberFormat: {
          valid: false,
        },
      };
    }
  };

@Directive({
  selector: '[appPhoneNumber][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneNumberValidatorDirective, multi: true }],
})
export class PhoneNumberValidatorDirective implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = validatePhoneNumberFactory();
  }

  validate(c: UntypedFormControl) {
    return this.validator(c);
  }
}
