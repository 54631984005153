import { Component, Input } from '@angular/core';
import { debounce, isEqual, keyBy, uniq } from 'lodash-es';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { isConsultNoteVerificationFrozen } from '../../../core/lib/verification-utils';
import { MatchingPrescriberDrug } from '../../../core/models/matching-prescriber-drug.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { DrugMatchVerification } from '../../../core/models/drug-match-verification.model';
import { DrugMatchVerificationsService } from '../../../core/services/drug-match-verifications.service';
import { ValidateDrugMatchOption } from '../validate-drug-match-verification/validate-drug-match-verification.component';
import { VerificationType } from '../../../core/enums/verification-type.enum';
import { DateUtils } from 'app/core/lib/date-utils';
import { checkWrittenDatesAgainstEncounterDates } from 'app/core/lib/verification-utils';
import { PdfFileService } from 'app/core/services/pdf-file.service';
const trackDrugMatchItemsBy = (index: number, item: ValidateDrugMatchOption) => item.key;

@Component({
  selector: 'app-validate-drug-match-form',
  templateUrl: './validate-drug-match-form.component.html',
  styleUrls: ['./validate-drug-match-form.component.scss'],
})
export class ValidateDrugMatchFormComponent {
  _collapsed: boolean;
  _patientAttachment: PatientAttachment;

  drugMatchOptions: ValidateDrugMatchOption[] = [];
  specialistEncounterDates: string[] = [];
  trackItemsBy = trackDrugMatchItemsBy;
  initialLoad = true;
  drugOcrButtonClass = "btn-secondary";
  lastMpdKeys: string[] = [];

  debouncedSaveVerification = debounce(
    (dmo: ValidateDrugMatchOption) => {
      this.saveDrugMatchVerification(dmo);
    },
    250,
    { maxWait: 1000 }
  );

  constructor(
    private patientAttachmentService: PatientAttachmentsService,
    private drugMatchVerificationService: DrugMatchVerificationsService,
    private authService: AuthenticationService,
    private pdfFileService: PdfFileService,
  ) {}

  get patientAttachment(): PatientAttachment {
    return this._patientAttachment;
  }

  @Input()
  set patientAttachment(pa: PatientAttachment) {
    this._patientAttachment = pa;
    this.initializeSpecialistEncounterDates();
    this.initializeDrugMatchOptions();
  }

  get collapsed(): boolean {
    return this._collapsed;
  }

  @Input()
  set collapsed(val: boolean) {
    this._collapsed = val;
    this.drugMatchOptions.forEach(dmo => {
      dmo.collapsed = val;
    });
  }

  onNextClick($event, index, count) {
    $event.preventDefault();
    let next = index + 1;

    if (next === count) {
      next = 0;
    }

    const questionsWrapperElement = document.getElementById('questionsWrapper');
    const questionsNavbar = document.getElementById('questionsNavbar');
    const verificationElement = document.getElementById('validateDrugMatch');
    const elementId = `drugMatch${next}`;
    const drugMatchElement = document.getElementById(elementId);

    const offset = questionsNavbar.getBoundingClientRect().height;
    const parentPosition = verificationElement.offsetTop - offset;
    const elementPosition = drugMatchElement.offsetTop;
    const position = parentPosition + elementPosition;

    questionsWrapperElement.scrollTo({ top: position, behavior: 'smooth' });
    drugMatchElement.focus();
  }

  onDrugMatchesChange(dmo: ValidateDrugMatchOption) {
    this.debouncedSaveVerification(dmo);
  }

  onCollapseChange(val: { key: string; collapsed: boolean }) {
    const dmoIndex = this.drugMatchOptions.findIndex(dmo => dmo.key === val.key);

    if (dmoIndex > -1) {
      this.drugMatchOptions[dmoIndex].collapsed = val.collapsed;
    }
  }

  onAdditionalTextChange(dmo: ValidateDrugMatchOption, value) {
    dmo.verification.notes = value;
    this.debouncedSaveVerification(dmo);
  }

  onFindDrugsClick() {
    this.pdfFileService.notifyAnnotationsWillChange();
    this.drugOcrButtonClass = "btn-secondary";
  }

  private initializeSpecialistEncounterDates() {
    this.specialistEncounterDates =
      uniq(this.patientAttachment.specialistEncounterVerifications.
        map(verification => DateUtils.toServerFormat(verification.encounterDate)));
  }

  private initializeDrugMatchOptions() {
    const keyedDrugMatchOptions = keyBy(this.drugMatchOptions, 'key');

    this.patientAttachmentService.getMatchingPrescriberDrugs(this.patientAttachment.id).subscribe(mpds => {
      this.patientAttachmentService.notifyMatchingPrescriberDrugsChanged(mpds);
      this.compareMatchingPrescriberDrugs(mpds);

      this.drugMatchOptions = mpds.map((mpd: MatchingPrescriberDrug) => {
        const optionKey = mpd.key;
        const option: ValidateDrugMatchOption = keyedDrugMatchOptions[optionKey];

        if (option) {
          const verification = this.resolveVerification(mpd);
          option.matchingPrescriberDrug = mpd;
          option.verification = verification;
          option.frozen = isConsultNoteVerificationFrozen(
            this.authService,
            this.patientAttachment,
            VerificationType.drugMatch,
            verification
          );
          option.checkedWrittenDates = this.checkDrugMatchWrittenDatesAgainstEncounterDates(mpd);
          option.checkedWrittenDatesHaveWarnings = this.writtenDatesHaveWarnings(option.checkedWrittenDates);
          return option;
        } else {
          return this.buildDrugMatchOption(optionKey, mpd);
        }
      });
    });
  }

  private buildDrugMatchOption(optionKey: string, mpd: MatchingPrescriberDrug) {
    const verification = this.resolveVerification(mpd);

    const checkedWrittenDates = this.checkDrugMatchWrittenDatesAgainstEncounterDates(mpd);

    return new ValidateDrugMatchOption(
      optionKey,
      mpd,
      verification,
      isConsultNoteVerificationFrozen(
        this.authService,
        this.patientAttachment,
        VerificationType.drugMatch,
        verification
      ),
      this.collapsed,
      checkedWrittenDates,
      this.writtenDatesHaveWarnings(checkedWrittenDates)
    );
  }

  private checkDrugMatchWrittenDatesAgainstEncounterDates(matchingPrescriberDrug: MatchingPrescriberDrug) {
    return checkWrittenDatesAgainstEncounterDates(
      matchingPrescriberDrug.relatedWrittenDates,
      this.specialistEncounterDates,
      this.patientAttachment.client.specialistEncounterTimeframeInMonths
    );
  }

  public writtenDatesHaveWarnings(checkedWrittenDates) {
    return checkedWrittenDates.some(checkedWrittenDate => checkedWrittenDate.dateWithinRange === false);
  }

  private resolveVerification(mpd: MatchingPrescriberDrug) {
    const drugGroup = mpd.drugGroup;
    const ndc = !drugGroup && mpd.ndc;

    const resolvedVerification =
      mpd.matchingVerificationId &&
      this.patientAttachment.drugMatchVerifications.find(dmv => dmv.id === mpd.matchingVerificationId);

    if (!resolvedVerification) {
      const verification = new DrugMatchVerification();
      verification.drugGroupId = drugGroup && drugGroup.id;
      verification.ndc = ndc;
      verification.referenceNumber = mpd.ndcReferenceNumber;
      return verification;
    } else {
      return Object.assign({}, resolvedVerification);
    }
  }

  private saveDrugMatchVerification(dmo: ValidateDrugMatchOption) {
    if (dmo.verification.id) {
      this.updateDrugMatchVerification(dmo);
    } else {
      this.createDrugMatchVerification(dmo);
    }
  }

  private createDrugMatchVerification(dmo: ValidateDrugMatchOption) {
    this.drugMatchVerificationService
      .create(this.patientAttachment.id, null, dmo.verification)
      .subscribe((newDmv: DrugMatchVerification) => {
        dmo.verification = newDmv;
        this.updatePatientAttachmentDrugMatchVerification(newDmv);
      });
  }

  private updateDrugMatchVerification(dmo: ValidateDrugMatchOption) {
    this.drugMatchVerificationService
      .update(this.patientAttachment.id, null, dmo.verification)
      .subscribe((updatedDmv: DrugMatchVerification) => {
        dmo.verification = updatedDmv;
        this.updatePatientAttachmentDrugMatchVerification(updatedDmv);
      });
  }

  private updatePatientAttachmentDrugMatchVerification(drugMatchVerification: DrugMatchVerification) {
    const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);

    if (!modifiedPatientAttachment.drugMatchVerifications) {
      modifiedPatientAttachment.drugMatchVerifications = [];
    }

    const paIndex = modifiedPatientAttachment.drugMatchVerifications.findIndex(
      paDmv => paDmv.id === drugMatchVerification.id
    );

    if (paIndex > -1) {
      modifiedPatientAttachment.drugMatchVerifications[paIndex] = drugMatchVerification;
    } else {
      modifiedPatientAttachment.drugMatchVerifications.push(drugMatchVerification);
    }

    this.patientAttachmentService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
  }

  private compareMatchingPrescriberDrugs(newMpds: MatchingPrescriberDrug[]) {
    const newMpdKeys = newMpds.map((mpd) => mpd.key);

    if (this.initialLoad) {
      this.initialLoad = false;
    } else {
      if (!isEqual(this.lastMpdKeys, newMpdKeys)) {
        this.drugOcrButtonClass = "btn-primary";
      }
    }

    this.lastMpdKeys = newMpdKeys;
  }
}
