<div class="content">
  <div class="row mx-0">
    <div class="col-4 px-0 text-start">
      <h3>Internal Consultant Queue</h3>
    </div>
  </div>

  <app-internal-consultant-capture-settings (settingsChange)="onSettingsChange()" />
  <div class="mt-2" *ngIf="!loadingResults; else: spinner">
    <ng-container *ngIf="captureQueueResults.length > 0; else: noCaptures">
      <div>
        <app-capture-queue-results [captureQueueResults]="captureQueueResults" [internalConsultantView]="true" />
        <div *ngIf="hasMoreResults" class="text-center mb-4">
          <a href="#next" (click)="onNextClick($event)" class="load-next-captures">
            View Next {{ limit }} Captures
          </a>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #spinner>
    <div class="mt-2 ms-1"><mat-spinner diameter="30" /></div>
  </ng-template>
  <ng-template #noCaptures> <p class="mt-2 ms-1">No captures found. Try modifying the filters.</p></ng-template>
</div>