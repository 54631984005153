import { Component, Input } from '@angular/core';
import { Office } from 'app/core/models/office.model';

@Component({
  selector: 'app-office-communications',
  templateUrl: './office-communications.component.html',
  styleUrls: ['./office-communications.component.scss'],
})
export class OfficeCommunicationsComponent {
  @Input() office: Office;

  constructor(
  ) {}
}
