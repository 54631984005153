import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { communicationMethods } from '../../../core/options/inbound-communication-communication-methods.opts';

@Component({
  selector: 'app-inbound-fax-communication-method',
  templateUrl: './inbound-fax-communication-method.component.html',
  styleUrls: ['./inbound-fax-communication-method.component.scss'],
})
export class InboundFaxCommunicationMethodComponent implements OnInit {
  @Input() selectedCommunicationMethod: string;
  communicationMethods = communicationMethods;

  @Input() disabled = false;

  @Output() public communicationMethodChange = new EventEmitter<string>();

  ngOnInit() {}

  onCommunicationMethodChange(communicationMethod) {
    this.communicationMethodChange.emit(communicationMethod);
  }
}
