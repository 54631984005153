import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './icc-export-claims.component.html',
})
export class IccExportClaimsComponent {
  reportsUrl = `${environment.captureApi.url}/icc/capture_reports`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    clientId: [null, Validators.required],
    iccClaimImportId: [null, Validators.required],
  });

  outputOptionsForm = this.formBuilder.group({
    includeSourceColumns: [null],
  });
}
