export const matchOptions = [
  {
    key: 'specialtyMatches',
    display: 'Specialty',
  },
  {
    key: 'officeMatches',
    display: 'Office',
  },
  {
    key: 'prescribingProviderMatches',
    display: 'Prescribing Provider (Name/NPI)',
  },
  {
    key: 'nothingMatches',
    display: 'None - No referral order details match',
  },
  {
    key: 'notApplicableToReferralOrderPresent',
    display: 'NA - No referral order present',
  },
];
