import { UserQueueSettings } from '../../interfaces/user-queue-settings';
import { LimitOffsetPaging, Sort } from '../paged-results/limit-offset-paging.model';
import { ProviderOfficeStatus } from 'app/core/enums/provider-office-status.enum';

export class ProviderOfficeStatusReviewFilters {
  reviewStatus: ProviderOfficeStatus[] = []
}

export class ProviderOfficeStatusReviewReviewListSettings {
  filters: ProviderOfficeStatusReviewFilters;
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  sort: Sort;
}

export class ProviderOfficeStatusReviewQueueSettings
  extends ProviderOfficeStatusReviewReviewListSettings implements UserQueueSettings {

  position: number;
}
