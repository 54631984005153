import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';

@Component({
  selector: 'app-ocr-result-text',
  templateUrl: './ocr-result-text.component.html',
  styleUrls: ['./ocr-result-text.component.scss']
})
export class OcrResultTextComponent implements OnInit {
  @Input() patientAttachment: PatientAttachment;

  hintText = (window.navigator && window.navigator.platform.indexOf('Mac') >= 0) ?
    '(Click text and type cmd+f to search)' :
    '(Click text and type ctrl+f to search)';

  constructor() { }

  ngOnInit(): void {}
}
