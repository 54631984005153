import { Component, Input } from '@angular/core';
import { PatientAttachmentHistoryItem } from 'app/core/models/patient-attachment-history-item.model';

@Component({
  selector: 'app-consult-note-history-item-type-base',
  templateUrl: './consult-note-history-item-type-base.component.html',
})
export class ConsultNoteHistoryItemTypeBaseComponent {
  @Input() patientAttachmentHistoryItem: PatientAttachmentHistoryItem;

  constructor() {}

  // This is meant as fallback text and shouldn't be shown in practice
  get title() {
    return `Event: ${this.patientAttachmentHistoryItem.action}`;
  }

  get description() {
    return '';
  }
}
