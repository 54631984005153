import { Component, Input } from '@angular/core';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';

@Component({
  selector: 'app-ce-review-client-consult-note-task',
  templateUrl: './ce-review-client-consult-note-task.component.html',
})
export class CeReviewClientConsultNoteTaskComponent {
  @Input() clientConsultNoteTask: ClientConsultNoteTask;
}
