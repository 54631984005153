import { ProviderInformationCollectionDetailsResultIssue } from '../enums/provider-information-collection-details-result-issue.enum';
import { keyBy } from 'lodash-es';
import { GenericOption } from './generic-option.opts';

const providerInformationCollectionDetailsResultIssueOptions = [
  new GenericOption(ProviderInformationCollectionDetailsResultIssue.specialty1Blank, "No Specialty Found"),
  new GenericOption(ProviderInformationCollectionDetailsResultIssue.faxNumbersBlank, "No Fax Found"),
  new GenericOption(ProviderInformationCollectionDetailsResultIssue.faxNumberInvalid, "Invalid Fax"),
  new GenericOption(ProviderInformationCollectionDetailsResultIssue.taskExpired, "HIT Expired"),
];

const keyedResultOptions = keyBy(providerInformationCollectionDetailsResultIssueOptions, 'value');

export { keyedResultOptions, providerInformationCollectionDetailsResultIssueOptions };
