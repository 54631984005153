export enum CaptureHistoryItemActions {
  created = 'created',
  updated = 'updated',
  transitioned = 'transitioned',
  snapshotCreated = 'snapshot_created',
  verifyingPatientAttachmentAssociated = 'verifying_patient_attachment_associated',
  consultNoteSubmitted = 'consult_note_submitted',
  referralMatchVerificationSubmitted = 'referral_match_verification_submitted',
  unreversed = 'unreversed',
  claimStatusUpdated = 'claim_status_updated',
  developerChoreUpdated = 'developer_chore_updated',
  clientChoreStarted = 'client_chore_started',
  clientChoreUpdated = 'client_chore_updated',
  clientNoteAdded = 'client_note_added',
  clientNoteRemoved = 'client_note_removed',
  locationAdded = 'location_added',
}
