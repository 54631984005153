export class ClientConfigurationOption {
  id?: any;
  title?: string;
  option: string;
  dated: boolean;
  portalName?: string;
  portalDescription?: string;
  value?: string | boolean | { [key: string]: string | boolean; }
  startAt?: Date;
  endAt?: Date;
}
