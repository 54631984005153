<h5 class="my-3">Stats</h5>

<ng-container *ngIf="!loading; else: spinner">
  <div class="row my-2">
    <div class="col-8">
      <label>CLAIMS</label>
    </div>
    <div class="col-4">
      {{ clientStats.claimCount | number }}
    </div>
  </div>
  <div class="row my-2">
    <div class="col-8">
      <label>REFERRALS</label>
    </div>
    <div class="col-4">
      {{ clientStats.referralCount | number }}
    </div>
  </div>
  <div class="row my-2">
    <div class="col-8">
      <label>ENCOUNTERS</label>
    </div>
    <div class="col-4">
      {{ clientStats.encounterCount | number }}
    </div>
  </div>
  <div class="row my-2">
    <div class="col-8">
      <label>PATIENTS</label>
    </div>
    <div class="col-4">
      {{ clientStats.patientCount | number }}
    </div>
  </div>
  <div class="row my-2">
    <div class="col-8">
      <label>PATIENT MATCHES</label>
    </div>
    <div class="col-4">
      {{ clientStats.patientMatchesCount | number }}
    </div>
  </div>
</ng-container>
<ng-template #spinner><div class="mb-3"><mat-spinner [diameter]="30" /></div> </ng-template>


