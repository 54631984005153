import { Component, OnDestroy, OnInit } from '@angular/core';
import { DownloadService } from '../../../core/services/download.service';
import { FileCheck } from '../../../core/models/file-check.model';
import { FileChecksService } from '../../../core/services/file-checks.service';
import { ClientFileKind } from '../../../core/enums/client-file-kind.enum';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ClientsService } from '../../../core/services/clients.service';
import { FileCheckFilters, FileCheckResultsListSettings } from 'app/core/models/user-settings/file-check-settings.model';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-capture-admin-file-check-results',
  templateUrl: './capture-admin-file-check-results.component.html',
  styleUrls: ['./capture-admin-file-check-results.component.scss'],
})
export class CaptureAdminFileCheckResultsComponent implements OnInit, OnDestroy {
  loading = false;
  filters: FileCheckFilters;
  fileChecks: FileCheck[] = [];
  nextPollTimeout = null;
  clients;

  fileTypeToDisplayName = {
    [ClientFileKind.referral]: 'Referral',
    [ClientFileKind.encounter]: 'Encounter',
    [ClientFileKind.clientPrescription]: 'Client Prescription',
  };

  paging: LimitOffsetPaging = LimitOffsetPaging.empty;

  formGroup: FormGroup;

  constructor(
    private fileChecksService: FileChecksService,
    private downloadService: DownloadService,
    private clientsService: ClientsService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.initFormGroup();
    this.loadClients();
    this.loadFileChecks(true);
  }

  ngOnDestroy() {
    clearTimeout(this.nextPollTimeout);
  }

  onCancelClick($event, fileCheck: FileCheck) {
    $event.preventDefault();
    this.fileChecksService.cancel(fileCheck.id).subscribe(canceledFileCheck => {
      const index = this.fileChecks.findIndex(fc => fc.id === canceledFileCheck.id);

      if (index > -1) {
        this.fileChecks[index] = canceledFileCheck;
        this.fileChecks = [...this.fileChecks];
      }
    });
  }

  onPageSizeChange(pageSize) {
    this.paging.pageSize = pageSize;
    this.loadFileChecks();
  }

  onPageChange() {
    this.loadFileChecks();
  }

  onDownloadOriginalFileClick($event, fileCheck: FileCheck) {
    $event.preventDefault();
    this.fileChecksService.get(fileCheck.id).subscribe(fc => {
      this.downloadService.download(fc.fileUrl);
    });
  }

  onDownloadErrorFileClick($event, fileCheck: FileCheck) {
    $event.preventDefault();
    this.fileChecksService.get(fileCheck.id).subscribe(fc => {
      this.downloadService.download(fc.errorFileUrl);
    });
  }

  onResetFiltersClick() {
    this.paging.currentPage = 1;

    this.filters = new FileCheckFilters();

    this.loadFileChecks();
    this.initFormGroup();
  }

  private loadFileChecks(loading = true) {
    const paging = { page: this.paging.currentPage, pageSize: this.paging.pageSize };

    this.loading = loading;

    this.fileChecksService.getList(paging, this.filters).subscribe(data => {
      this.fileChecks = data.records;
      this.paging = data.meta.paging;
      this.loading = false;

      const anyActive: boolean = this.fileChecks.some(fc => fc.active);

      if (anyActive) {
        this.nextPollTimeout = setTimeout(() => this.loadFileChecks(false), 500);
      }
    });
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      filters: this.formBuilder.group({
        clientIds: []
      })
    });

    this.formGroup.valueChanges.subscribe(_value => {
      this.paging.currentPage = 1;

      const formValue = this.formGroup.value;

      this.filters = new FileCheckFilters();
      this.filters.clientIds = formValue.filters.clientIds;

      this.loadFileChecks();
    })
  }
}
