import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class SourcesService extends ApiService {
  constructor(authService: AuthenticationService, private http: HttpClient) {
    super();
  }

  getList(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.captureApi.url}/sources`);
  }
}
