import { Component, Input, OnInit } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
})
export class PatientDetailsComponent implements OnInit {
  constructor() {}

  @Input() capture: Capture;

  ngOnInit() {}
}
