export enum ConsultNoteQueueFilterName {
  clientIds = 'client_ids[]',
  clientStates = 'client_states[]',
  statuses = 'statuses[]',
  specialtyStore = 'specialty_store',
  expiringWithinDays = 'expiring_within_days',
  outboundFaxNumber = 'outbound_fax_number',
  highValue = 'high_value',
  assignedClientLeadsIds = 'assigned_client_leads_ids[]',
  assignedToIds = 'assigned_to_ids[]',
}
