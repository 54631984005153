import { Component, TemplateRef } from '@angular/core';
import { AppToastService } from 'app/core/services/app-toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  constructor(public toastService: AppToastService) {}

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  hide(toast: any) {
    this.toastService.remove(toast);
  }
}
