import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';
import { User } from 'app/core/models/user.model';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { UsersService } from 'app/core/services/users.service';

@Component({
  selector: 'app-consult-note-assign-user-select',
  templateUrl: './consult-note-assign-user-select.component.html',
  styleUrls: ['./consult-note-assign-user-select.component.scss'],
})
export class ConsultNoteAssignUserSelectComponent implements OnInit {
  @Input() originalSelectedUser: number;
  @Output() selectionChanged = new EventEmitter<number>();

  consultNoteUsers: User[] = [];
  selectedUser: number;

  constructor(
    private usersService: UsersService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.selectedUser = this.originalSelectedUser || this.authService.currentUser.id;
    this.usersService.getByUserRole([UserRoleName.consultNoteUser]).subscribe((result) => {
      this.consultNoteUsers = [this.unassignedOption].concat(this.orderUsersByCurrentUserFirst(result.users));
    });
    this.selectionChanged.emit(this.selectedUser);
  }

  public handleChange() {
    this.selectionChanged.emit(this.selectedUser);
  }

  private orderUsersByCurrentUserFirst(users: User[]): User[] {
    const orderedUsers = [];
    const currentUser = this.authService.currentUser;
    users.forEach((user) => {
      if (user.id === currentUser.id) {
        orderedUsers.unshift(user);
      } else {
        orderedUsers.push(user);
      }
    });
    return orderedUsers;
  }

  private get unassignedOption(): User {
    const user = { id: -1, fullName: 'UNASSIGNED' } as User;
    return user;
  }
}
