<div id="validatePatientMatch" class="card">
  <app-validation-header
    [icon]="'perm_identity'"
    [title]="'Validate Patient Match'"
    [collapsed]="collapsed"
    (collapseChange)="onCollapseChange($event)"
  />
  <div class="card-body p-1">
    <div *ngIf="!collapsed">
      <app-validate-patient-match-details [patientAttachment]="patientAttachment" />
    </div>
    <div>
      <app-validate-patient-match-form
        [patientAttachment]="patientAttachment"
        [collapsed]="collapsed"
      />
    </div>
  </div>
</div>
