import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-consult-note-estimated-values',
  templateUrl: './consult-note-estimated-values.component.html',
  styleUrls: ['./consult-note-estimated-values.component.scss'],
})
export class ConsultNoteEstimatedValuesComponent implements OnInit {
  @Input() patientAttachment: { wipEstimatedValue: number; pendingEstimatedValue: number };

  constructor() {}

  ngOnInit() {}
}
