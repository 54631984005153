import { UserQueueSettings } from '../../interfaces/user-queue-settings';
import { LimitOffsetPaging } from '../paged-results/limit-offset-paging.model';

export class ProviderFilters {
  clientId: number;
  firstName: string = null;
  lastName: string = null;
  npi: string = null;
  city: string = null;
  state: string = null;
  status: string[] = [];
}

export class ProviderListSettings {
  filters: ProviderFilters = new ProviderFilters();
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
}

export class ProviderQueueSettings extends ProviderListSettings implements UserQueueSettings {
  position: number;
}
