<ng-container [ngSwitch]="loginStatus">
  <ng-container *ngSwitchCase="null">
    <div class="spinner">
      <div class="spinner-wrapper">
        <div class="rotator">
          <div class="inner-spin"></div>
          <div class="inner-spin"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="loginStatuses.AccountDisabled">
    <h3 class="my-4 heading text-center">Account Disabled</h3>
    <div class="row">
      <div class="col-lg-4 offset-lg-4">
        <p>This account is disabled. Please call our support team: <b>1-781-214-3424</b>.</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <h3 class="my-4 heading text-center">SAML Authentication Issue</h3>
    <div class="row">
      <div class="col-lg-4 offset-lg-4">
        <p>{{ loginMessage }}</p>
      </div>
    </div>
  </ng-container>
</ng-container>
