import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';
import { ReportCaptureStatusOption } from 'app/shared/components/report-capture-status-option/report-capture-status-option.component';

@Component({
  templateUrl: './capture-admin-capture-reports.component.html',
})
export class CaptureAdminCaptureReportsComponent {
  reportsUrl = `${environment.captureApi.url}/capture_reports`;
  reportsCountUrl = `${environment.captureApi.url}/capture_reports/count`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    captureStatuses: [new ReportCaptureStatusOption()],
    clientIds: [null],
    highValueCandidatesOnly: [null],
    expiringWithinDays: [null],
  });
}
