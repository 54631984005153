import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, NgModule, APP_INITIALIZER } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppComponent } from './app.component';
import { AppRoutingModule } from 'app/app-routing.module';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { CaptureAdminModule } from './capture-admin/capture-admin.module';
import { LlmPlaygroundModule } from './llm-playground/llm-playground.module';
import { ClientPortalModule } from './client-portal/client-portal.module';
import { PartnerPortalModule } from './partner-portal/partner-portal.module';
import { SentryService } from './core/services/sentry.service';
import { ApplicationService } from './core/services/application.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { VersionInterceptor } from './core/version.interceptor';
import { CapturesModule } from './captures/captures.module';
import { HotkeyModule } from 'angular2-hotkeys';

export const initApp = (appService: ApplicationService) => () =>
    appService
      .initialize()
      .toPromise()
      .catch(() => {
        console.error('Application failed to initialize');
      });

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private sentryService: SentryService) {}
  handleError(err) {
    const error = err.originalError || err;
    this.sentryService.logException(error);
  }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        InfiniteScrollModule,
        CoreModule,
        SharedModule,
        AuthModule,
        AppRoutingModule,
        CapturesModule,
        CaptureAdminModule,
        LlmPlaygroundModule,
        ClientPortalModule,
        PartnerPortalModule,
        HotkeyModule.forRoot()
    ],
    providers: [
        { provide: ErrorHandler, useClass: SentryErrorHandler, deps: [SentryService] },
        { provide: APP_INITIALIZER, useFactory: initApp, deps: [ApplicationService], multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: VersionInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
