import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { Batch } from '../../../core/models/llm-playground.model';
import { LlmPlaygroundBatchesService } from '../../../core/services/llm-playground-batches.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { DownloadService } from '../../../core/services/download.service';
import { interval, Subject, throwError } from 'rxjs';
import { finalize, takeUntil, takeWhile, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-llm-playground-batches-table',
  templateUrl: './llm-playground-batches-table.component.html',
  styleUrls: ['./llm-playground-batches-table.component.scss'],
})
export class LlmPlaygroundBatchesTableComponent implements OnChanges {
  @Input() batches: Batch[];
  @Output() view: EventEmitter<number> = new EventEmitter<number>();
  @Output() delete: EventEmitter<number> = new EventEmitter<number>();
  pollingSubjects: { [key: number]: Subject<void> } = {};
  loadingBatches: { [key: number]: boolean } = {};
  loadingError: { [key: number]: string } = {};

  constructor(
    private downloadService: DownloadService,
    private llmPlaygroundBatchesService: LlmPlaygroundBatchesService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.batches) {
      this.batches.forEach(batch => {
        if (['initialized', 'processing'].some(status => batch.status === status) && !this.loadingBatches[batch.id]) {
          this.startPolling(batch.id);
        }
      });
    }
  }

  onViewBatchPrompt(event: Event, id: number): void {
    event.preventDefault();
    this.view.emit(id);
  }

  onDeleteBatchRun(event: Event, id: number): void {
    event.preventDefault();
    const confirmed = window.confirm('Are you sure you want to delete this batch run?');
    if (confirmed) {
      this.delete.emit(id);
    }
  }

  onDownloadBatchResults(event: Event, id: number): void {
    event.preventDefault();
    this.llmPlaygroundBatchesService.csv(id).subscribe(
      data => {
        const blob = new Blob([data.blob], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        this.downloadService.download(url, data.filename);
      },
      error => {
        console.log(error);
        this.loadingError[id] = 'error fetching batch CSV. ' + error.message;
      }
    );
  }

  isOwner(ownerEmail: string) {
    return this.authenticationService.currentUser.email === ownerEmail
  }

  resultDisplay(id) {
    if (this.loadingBatches[id]) {
      return "loading"
    } else if (this.loadingError[id]) {
      return "error"
    }
    return "complete"
  }

  startPolling(id: number): void {
    const pollingInterval = 3 * 1000; // 3 seconds
    const timeoutDuration = 5 * 60 * 1000; // 5 minutes
    const maxPolls = timeoutDuration / pollingInterval;
    let pollCount = 0;

    this.pollingSubjects[id] = new Subject();
    this.loadingBatches[id] = true;

    interval(pollingInterval)
      .pipe(
        takeWhile(() => pollCount++ < maxPolls),
        takeUntil(this.pollingSubjects[id]),
        catchError(error => {
          console.error('an error occurred:', error);
          this.loadingError[id] = 'Error occurred during polling. ' + error;
          this.pollingSubjects[id].next();
          return throwError(error);
        }),
        finalize(() => {
          if (pollCount > maxPolls) {
            this.loadingError[id] = "Polling timed out. Reloading the page may show updated results."
          }
          this.stopPolling(id);
        })
      )
      .subscribe(() => {
        this.llmPlaygroundBatchesService.get(id, false).subscribe(
          updatedBatch => {
            this.batches = this.batches.map(batch => (batch.id === updatedBatch.id ? updatedBatch : batch));
            if (!['initialized', 'processing'].some(status => updatedBatch.status === status)) {
              this.pollingSubjects[id].next();
            }
          },
          error => {
            console.log(error);
            this.loadingError[id] = 'Error fetching batch. ' + error.message;
            this.pollingSubjects[id].next();
          }
        );
      });
  }

  private stopPolling(id: number): void {
    this.loadingBatches[id] = false;
    this.pollingSubjects[id].complete();
  }
}
