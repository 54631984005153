import { Component, OnInit } from '@angular/core';
import { debounce } from 'lodash-es';
import { PatientInteractionLocationsService } from 'app/core/services/patient-interaction-locations.service';
import { PatientInteractionLocation } from 'app/core/models/patient-interaction-location.model';
import {
  PatientInteractionLocationFilters,
  PatientInteractionLocationListSettings,
  PatientInteractionLocationUiOptions
} from 'app/core/models/user-settings/patient-interaction-location-settings.model';
import { PatientInteractionLocationEligibilityStatusOptions } from 'app/core/options/patient-interaction-location-eligibility-status.opts';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ClientsService } from 'app/core/services/clients.service';
import { UserAssignedClientsService } from 'app/core/services/user-assigned-clients.service';

@Component({
  selector: 'app-capture-admin-patient-interaction-locations',
  templateUrl: './capture-admin-patient-interaction-locations.component.html',
  styleUrls: ['./capture-admin-patient-interaction-locations.component.scss'],
})
export class CaptureAdminPatientInteractionLocationsComponent implements OnInit {
  patientInteractionLocations: PatientInteractionLocation[] = [];
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  filters: PatientInteractionLocationFilters = new PatientInteractionLocationFilters();
  uiOptions: PatientInteractionLocationUiOptions = new PatientInteractionLocationUiOptions();
  loading = true;

  exportUrl = '/capture-admin/reports/patient-interaction-location-reports';

  clientsFilterOptions = [];
  clientEntityTypeFilterOptions = [];
  assignedClientLeadUsersFilterOptions = [];
  eligibilityStatusFilterOptions = PatientInteractionLocationEligibilityStatusOptions;

  constructor(
    private patientInteractionLocationsService: PatientInteractionLocationsService,
    private clientsService: ClientsService,
    private userSettingsService: UserSettingsService,
    private userAssignedClientsService: UserAssignedClientsService,
  ) {}

  ngOnInit() {
    this.loadClients();
    this.loadClientEntityTypes();
    this.loadAssignedUsers();
    this.loadSettings();
    this.loadPatientInteractionLocations();
  }

  onFilterChange() {
    this.paging.currentPage = 1;
    this.saveSettings();
    this.loadPatientInteractionLocations();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadPatientInteractionLocations();
    this.saveSettings();
  }

  onPageChange(_page: number) {
    this.loadPatientInteractionLocations();
    this.saveSettings();
  }

  onLocationKeyUp() {
    this.debounceLocationKeyUp();
  }

  onResetFiltersClick() {
    this.paging.currentPage = 1;
    this.filters = new PatientInteractionLocationFilters();
    this.loadPatientInteractionLocations();
    this.saveSettings();
  }

  onExplainerExpandOrCollapse(expanded: boolean) {
    this.uiOptions.explainerExpanded = expanded;
    this.saveSettings();
  }

  private loadPatientInteractionLocations() {
    this.loading = true;

    this.patientInteractionLocationsService.getList(this.paging, this.filters).subscribe(result => {
      this.patientInteractionLocations = result.patientInteractionLocations;
      this.paging = result.meta.paging;
      this.loading = false;
    });
  }

  private loadSettings() {
    const listSettings: PatientInteractionLocationListSettings =
      this.userSettingsService.get<PatientInteractionLocationListSettings>(
        UserSettings.captureAdminPatientInteractionLocationListSettings
      );

    if (listSettings) {
      this.filters = listSettings.filters;
      this.paging = listSettings.paging;

      if (listSettings.uiOptions) {
        this.uiOptions = listSettings.uiOptions;
      }
    }
  }

  private saveSettings() {
    const listSettings = new PatientInteractionLocationListSettings();
    listSettings.filters = this.filters;
    listSettings.paging = this.paging;
    listSettings.uiOptions = this.uiOptions;
    this.userSettingsService.save<PatientInteractionLocationListSettings>(
      UserSettings.captureAdminPatientInteractionLocationListSettings,
      listSettings
    );
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clientsFilterOptions = result.clients;
    });
  }

  private loadAssignedUsers() {
    this.userAssignedClientsService.getList().subscribe(result => {
      this.assignedClientLeadUsersFilterOptions = result.users;
    });
  }

  private loadClientEntityTypes() {
    this.clientsService.getEntityTypes().subscribe(result => {
      this.clientEntityTypeFilterOptions = result;
    });
  }

  private debounceLocationKeyUp = debounce(
    () => { this.onFilterChange(); },
    1000,
    { maxWait: 2000 }
  );
}
