import { Component, Input, OnInit } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { ClientConsultNoteVerificationsService } from '../../../core/services/client-consult-note-verifications.service';

@Component({
  selector: 'app-capture-consult-note-verification',
  templateUrl: './capture-consult-note-verification.component.html',
})
export class CaptureConsultNoteVerificationComponent implements OnInit {
  @Input() capture: Capture;

  verification = null;
  loading = false;

  constructor(private clientConsultNoteVerificationsService: ClientConsultNoteVerificationsService) {}

  ngOnInit() {
    this.loading = true;
    this.clientConsultNoteVerificationsService.getByCapture(this.capture.id).subscribe(verification => {
      this.loading = false;
      this.verification = verification;
    });
  }
}
