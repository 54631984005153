import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EhrConsultNoteReference } from 'app/core/models/ehr-consult-note-reference.model';
import { EhrConsultNoteReferencesService } from 'app/core/services/ehr-consult-note-references.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-ehr-consult-note-reference',
  templateUrl: './ehr-consult-note-reference.component.html',
  styleUrls: ['./ehr-consult-note-reference.component.scss'],
})
export class EhrConsultNoteReferenceComponent {
  @Input() ehrConsultNoteReference: EhrConsultNoteReference;
  @Output() referenceUpdated = new EventEmitter<EhrConsultNoteReference>();

  editing = false;
  ehrConsultNoteReferenceErrors: any;

  constructor(private ehrConsultNoteReferencesService: EhrConsultNoteReferencesService) { }

  onEditClick() {
    this.editing = true;
  }

  onEditCanceled() {
    this.editing = false;
  }

  onEditSaved(formData: any) {
    this.ehrConsultNoteReferencesService.
      update(this.ehrConsultNoteReference.id, formData).
      subscribe(
        (updatedEhrConsultNoteReference: EhrConsultNoteReference) => {
          this.referenceUpdated.emit(updatedEhrConsultNoteReference);
          this.editing = false;
        },
        (response: HttpErrorResponse) => {
          if (response.status === 400) {
            this.ehrConsultNoteReferenceErrors = response.error.errors;
          } else {
            console.error(response);
          }
        }
      );
  }
}
