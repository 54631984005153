<ng-container *ngIf="!loading; else: spinner">
  <div *ngIf="displayPopover">
    <a
      href="javascript:;"
      class="small text-nowrap {{ linkClass }}"
      [ngbPopover]="popoverContent"
      placement="bottom"
      container="body"
    >
      {{ linkLabel }}
    </a>
    <a
      *ngIf="canAddNotes"
      href="javascript:;"
      class="small text-nowrap {{ linkClass }}"
      (click)="onClick($event)"
    >
      <fa-icon [icon]="['fas', 'file-signature']" size="sm" title="{{ linkLabelTitle }}" />
    </a>
    <ng-template #popoverContent>
      <ng-container *ngFor="let note of providerOffice.notes">
        <app-note [note]="note" />
      </ng-container>
    </ng-template>
  </div>

  <div *ngIf="!displayPopover">
    <a
      *ngIf="canAddNotes"
      href="javascript:;"
      class="small text-nowrap {{ linkClass }}"
      (click)="onClick($event)"
    >
      {{ linkLabel }}

      <fa-icon [icon]="['fas', 'file-signature']" size="sm" title="{{ linkLabelTitle }}" />
    </a>
  </div>
</ng-container>

<ng-template #spinner> <mat-spinner [diameter]="20" /> </ng-template>
