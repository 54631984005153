<ul class="list-group">
  <li
    *ngFor="let prescriber of prescribers"
    (click)="onPrescriberClick(prescriber)"
    class="list-group-item list-group-item-action justify-content-between align-items-center"
  >
    <div>
      <strong>{{ prescriber | prescriberNameWithNpi }} </strong>
    </div>
    <div>
      {{ prescriber.displaySpecialty }}
    </div>
  </li>
</ul>
