import { Component, Input } from '@angular/core';
import { ClientTaskHistoryItem } from 'app/core/models/client-task-history-item.model';

@Component({
  selector: 'app-client-task-history-item-type-base',
  templateUrl: './client-task-history-item-type-base.component.html',
})
export class ClientTaskHistoryItemTypeBaseComponent {
  @Input() clientTaskHistoryItem: ClientTaskHistoryItem;

  constructor() {}

  // This is meant as fallback text and shouldn't be shown in practice
  get title() {
    return `Event: ${this.clientTaskHistoryItem.action}`;
  }

  get description() {
    return '';
  }

  get username() {
    return this.clientTaskHistoryItem.user.username;
  }

  get taskName() {
    switch(this.clientTaskHistoryItem.clientTaskType) {
      case 'ClientReferralTask': {
        return 'Referral';
      }
      case 'ClientConsultNoteTask': {
        return 'Consult Note';
      }
      case 'ClientPrescriberTask': {
        return 'Prescriber';
      }
      default: {
        return 'Unkown'
      }
    }
  }

  get choice() {
    return this.clientTaskHistoryItem.data.choice;
  }
}
