import { Component, OnInit } from '@angular/core';
import { ClientReferralTask } from '../../../core/models/client-referral-task.model';
import { TaskFilters, TaskQueueSettings } from '../../../core/models/user-settings/task-settings.model';
import { LimitOffsetPaging, Sort } from '../../../core/models/paged-results/limit-offset-paging.model';
import {
  ClientReferralTasksService,
  PagedClientReferralTasks,
} from '../../../core/services/client-referral-tasks.service';
import { UserSettingsService } from '../../../core/services/user-settings.service';
import { UserSettings } from '../../../core/enums/user-settings.enum';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { ClientReferralTaskStatus } from '../../../core/enums/client-referral-task-status.enum';
import { ClientsService } from 'app/core/services/clients.service';
import { displayTaskStatus } from 'app/core/options/task-status.opts';

@Component({
  selector: 'app-client-portal-client-referral-tasks',
  templateUrl: './client-portal-client-referral-tasks.component.html',
  styleUrls: ['./client-portal-client-referral-tasks.component.scss'],
})
export class ClientPortalClientReferralTasksComponent implements OnInit {
  tasks: ClientReferralTask[] = [];
  filters: TaskFilters = new TaskFilters();
  taskStatuses = [];
  specialties = [];
  manufacturers = [];
  sources = [];
  encounterPatientInteractionLocations = null;
  showEncounterLocationFilter = false;

  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  sort: Sort = new Sort();
  loading = true;

  exportUrl = "/ce-portal/reports/client-referral-task-reports";

  static readonly clientEntityTypesSupportingLocationFilter = [
    'DSH',
    'CAH',
    'SCH',
    'CAN',
    'PED'
  ];

  constructor(
    private clientReferralTasksService: ClientReferralTasksService,
    private userSettingsService: UserSettingsService,
    private clientsService: ClientsService,
    private authService: AuthenticationService
  ) {
    this.showEncounterLocationFilter = this.shouldShowLocationFilter();

    if (this.showEncounterLocationFilter) {
      this.encounterPatientInteractionLocations = [];
    }
  }

  ngOnInit() {
    this.init();
  }

  filtersChange(filters) {
    this.filters = filters;
    this.loadTasks();
    this.saveQueueSettings();
  }

  onSortChange(sort: Sort) {
    this.sort = sort;
    this.saveQueueSettings();
    this.loadTasks();
  }

  onPageChange(page) {
    this.loadTasks(page);
    this.saveQueueSettings();
  }

  onPageSizeChange(pageSize) {
    this.paging.pageSize = pageSize;
    this.loadTasks(this.paging.currentPage);
    this.saveQueueSettings();
  }

  onViewTask(position: number) {
    this.saveQueueSettings(position);
  }

  private init() {
    this.loadStatuses();
    this.loadQueueSettings();
    this.loadSpecialties();
    this.loadManufacturers();
    this.loadSources();
    this.loadTasks();
    this.loadLocations();
  }

  private loadTasks(page = this.paging.currentPage) {
    this.loading = true;

    const paging = { page, pageSize: this.paging.pageSize };

    this.clientReferralTasksService
      .getList(paging, this.filters, this.sort)
      .subscribe((result: PagedClientReferralTasks) => {
        this.tasks = result.clientReferralTasks;
        this.paging = result.meta.paging;
        this.sort = result.meta.sort || new Sort();
        this.loading = false;
        // we want to make sure we pickup any
        // new locations.
        this.loadLocations();
      });
  }

  private loadSpecialties() {
    this.clientsService.getSpecialties().subscribe(result => {
      this.specialties = result;
    });
  }

  private loadManufacturers() {
    this.clientReferralTasksService.getManufacturers().subscribe(result => {
      this.manufacturers = result;
    });
  }

  private loadSources() {
    this.clientReferralTasksService.getSources().subscribe(result => {
      this.sources = result;
    });
  }

  private loadStatuses() {
    this.taskStatuses = Object.values(ClientReferralTaskStatus).map(v => ({
      value: v,
      display: displayTaskStatus(v),
    }));
  }

  private loadLocations() {
    if (this.showEncounterLocationFilter) {
      this.clientsService.getEncounterLocations().subscribe(result => {
        this.encounterPatientInteractionLocations = result;
      });
    }
  }

  private shouldShowLocationFilter() {
    return this.authService.allClientEntityTypes.
      some(entityType => ClientPortalClientReferralTasksComponent.
        clientEntityTypesSupportingLocationFilter.includes(entityType));
  }

  private loadQueueSettings() {
    const queueSettings: TaskQueueSettings = this.userSettingsService.get<TaskQueueSettings>(
      UserSettings.clientPortalReferralTaskQueueSettings
    );

    if (queueSettings) {
      this.paging = queueSettings.paging;
      this.filters = queueSettings.filters;
      this.sort = queueSettings.sort;
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new TaskQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    queueSettings.filters = this.filters;
    queueSettings.sort = this.sort;
    this.userSettingsService.save<TaskQueueSettings>(UserSettings.clientPortalReferralTaskQueueSettings, queueSettings);
  }
}
