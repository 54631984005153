<app-reports
  title="Capture Reports"
  [filtersForm]="filtersForm"
  [forceReportDetailsDisplay]="true"
  [reportsUrl]="reportsUrl"
  [reportsCountUrl]="reportsCountUrl">

  <ng-template appNewReportOptionsTemplate let-filtersForm="filtersForm">
    <ng-container [formGroup]="filtersForm">

      <app-report-capture-status-option formControlName="captureStatuses" />

      <app-report-client-option
        formControlName="clientIds"
        [clientFilters]="{ applicableToReferralClaims: true }" />

      <app-report-boolean-option
        label="High Value"
        formControlName="highValueCandidatesOnly" />

      <app-report-text-option
        label="Days to Expiration"
        type="integer"
        formControlName="expiringWithinDays" />

    </ng-container>
  </ng-template>

  <ng-template appReportDetailsTemplate let-report="report">

    <app-report-applied-client-option
      [options]="report.filters"
      [optionAssociations]="report.filterAssociations" />

    <app-report-applied-capture-status-option
      [options]="report.filters" />

    <app-report-applied-boolean-option
      [options]="report.filters"
      name="highValueCandidatesOnly"
      label="High Value" />

    <app-report-applied-text-option
      [options]="report.filters"
      name="expiringWithinDays"
      label="Days to Expiration" />

  </ng-template>

</app-reports>
