import { Component, Input } from '@angular/core';
import { Patient } from 'app/core/models/patient.model';

@Component({
  selector: 'app-patient-status-and-deactivate-button',
  templateUrl: './patient-status-and-deactivate-button.component.html',
  styleUrls: ['./patient-status-and-deactivate-button.component.scss'],
})
export class PatientStatusAndDeactivateButtonComponent {
  @Input() patient: Patient;

  constructor() {}

  public get active(): boolean {
    return this.patient && this.patient.inactiveDate === null;
  }

  public onPatientDeactivated(patient: Patient) {
    this.patient = patient;
  }
}
