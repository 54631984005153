<section *ngIf="!refreshing; else: spinner">
  <app-client-task-nav [baseUrl]="navBaseUrl" />
  <div><app-client-prescriber-task-header [task]="task" [displayUserPresence]="displayUserPresence" /></div>
  <div class="my-3"><app-client-prescriber-task-instructions [task]="task" /></div>
  <div class="row m-0 p-0 mb-5">
    <div class="col p-0">
      <app-client-prescriber-task-verification
        [task]="task"
        [formDisabled]="formDisabled"
        (taskTransition)="onTaskTransition($event)" />
    </div>
    <div class="col">
      <app-client-prescriber-task-details [task]="task" />
    </div>
  </div>
</section>
<ng-template #spinner> <mat-spinner /> </ng-template>
