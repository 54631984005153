/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

export class ResizedEvent {
  public newRect: DOMRectReadOnly;
  public oldRect?: DOMRectReadOnly;
  public isFirst: boolean;

  public constructor(newRect: DOMRectReadOnly, oldRect: DOMRectReadOnly | undefined) {
    this.newRect = newRect;
    this.oldRect = oldRect;
    this.isFirst = oldRect == null;
  }
}

@Directive({
  selector: '[resized]',
})
export class ResizedDirective implements OnInit, OnDestroy {
  private observer: ResizeObserver;
  private oldRect?: DOMRectReadOnly;

  @Output() public readonly resized;

  public constructor(private readonly element: ElementRef, private readonly zone: NgZone) {
    this.resized = new EventEmitter<ResizedEvent>();

    this.observer = new ResizeObserver(entries => {
      // prevent: https://sentry.io/organizations/par8o/issues/2792317759
      // source: https://stackoverflow.com/a/58701523
      const directive = this;

      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        directive.zone.run(() => directive.observe(entries));
      });
    });
  }

  public ngOnInit(): void {
    this.observer.observe(this.element.nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private observe(entries): void {
    const domSize = entries[0];
    const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);
    this.oldRect = domSize.contentRect;
    this.resized.emit(resizedEvent);
  }
}
/* eslint-enable @angular-eslint/directive-selector */
