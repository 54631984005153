import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientPrescriberTask } from '../../../core/models/client-prescriber-task.model';
import { LimitOffsetPaging } from '../../../core/models/paged-results/limit-offset-paging.model';

@Component({
  selector: 'app-client-prescriber-task-list',
  templateUrl: './client-prescriber-task-list.component.html',
  styleUrls: ['./client-prescriber-task-list.component.scss'],
})
export class ClientPrescriberTaskListComponent {
  @Input() tasks: ClientPrescriberTask[];
  @Input() paging: LimitOffsetPaging;

  @Output() public pageChange = new EventEmitter<number>();
  @Output() public pageSizeChange = new EventEmitter<number>();

  constructor() {}

  onPageChange(page) {
    this.pageChange.emit(page);
  }

  onPageSizeChange(size) {
    this.pageSizeChange.emit(size);
  }
}
