import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportOption } from 'app/core/models/report-option.model';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { ClientsService, } from 'app/core/services/clients.service';
import { User } from 'app/core/models/user.model';
import { UserAssignedClientsService } from 'app/core/services/user-assigned-clients.service';

export class ReportClientLeadOption implements ReportOption {
  value: User[] | User;

  constructor(value) {
    this.value = value;
  }

  toPostData(options = { paramName: 'assignedClientLeadsIds' }) {
    return { [options.paramName]: this.value };
  }
}

@Component({
  selector: 'app-report-client-lead-option',
  templateUrl: './report-client-lead-option.component.html',
  styleUrls: ['./report-client-lead-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportClientLeadOptionComponent),
    },
  ],
})
export class ReportClientLeadOptionComponent extends ReportAbstractOptionComponent implements OnInit {
  @Input() label = 'Filter by Assigned User';
  @Input() multiple = true;

  selectedUsers: User[] | User;
  users: User[];

  constructor(private userAssignedClientsService: UserAssignedClientsService) {
    super();
  }

  ngOnInit() {
    this.loadUsers();
  }

  private loadUsers() {
    this.userAssignedClientsService.getList().subscribe(result => {
      this.users = result.users;
    });
  }

  protected updateInternalState(model: ReportClientLeadOption) {
    if (model) {
      this.selectedUsers = model.value;
    }
  }

  protected determineModelStateOnChange(): ReportClientLeadOption {
    if (this.selectedUsers && (!Array.isArray(this.selectedUsers) || this.selectedUsers.length > 0)) {
      return new ReportClientLeadOption(this.selectedUsers);
    } else {
      return null;
    }
  }
}
