import { Component, Input, OnInit } from '@angular/core';
import { Encounter } from '../../../core/models/encounter.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ProviderService } from 'app/core/services/provider.service';
import { PagedEncounters } from 'app/core/services/provider.service';
import { EncounterQueueSettings } from 'app/core/models/user-settings/encounter-search-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { Provider } from 'app/core/models/provider.model';

@Component({
  selector: 'app-encounter-list',
  templateUrl: './encounter-list.component.html',
  styleUrls: ['./encounter-list.component.scss'],
})

export class EncounterListComponent implements OnInit {
  @Input() provider: Provider;

  encounters: Encounter[] = [];
  refreshing = false;
  paging: LimitOffsetPaging;
  eligible = true;
  currentSection: string;

  constructor(
    private providerService: ProviderService,
    private userSettingsService: UserSettingsService,
  ) {}

  get viewing() {
    return Math.min(this.paging.pageSize, this.paging.count);
  }

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.saveQueueSettings();
    this.loadEncounters(this.paging.currentPage);
  }

  onPageChange(page) {
    this.saveQueueSettings();
    this.loadEncounters(page);
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.loadQueueSettings();
    this.paging.currentPage = 1;
    this.loadEncounters(this.paging.currentPage);
  }

  private loadQueueSettings() {
    const queueSettings: EncounterQueueSettings = this.userSettingsService.get<EncounterQueueSettings>(
      UserSettings.captureAdminEncounterQueueSettings
    );
    if (queueSettings) {
      this.paging = queueSettings.paging;
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new EncounterQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    this.userSettingsService.save<EncounterQueueSettings>(
      UserSettings.captureAdminEncounterQueueSettings, queueSettings
    );
  }

  private loadEncounters(page) {
    const paging = { page, pageSize: this.paging.pageSize };

    this.refreshing = true;

    this.providerService.encounters(this.provider.id, this.eligible, paging).subscribe((result: PagedEncounters) => {
      this.encounters = result.records || [];
      this.paging = result.meta.paging;
      this.refreshing = false;
    });
  }

  onSectionClick(section: string) {
    this.currentSection = section;
  }

  navClick($event: Event, eligible: boolean) {
    $event.preventDefault();
    this.eligible = eligible;
    this.loadEncounters(1);
  }
}
