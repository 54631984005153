import { Component, Input, OnInit } from '@angular/core';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ProviderCallLogQueueSettings } from 'app/core/models/user-settings/provider-call-log-search-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { Provider } from 'app/core/models/provider.model';
import { ProviderCallLog } from 'app/core/models/provider-call-log.model';
import { ProviderCallLogsService, PagedProviderCallLogs } from 'app/core/services/provider-call-logs.service';
import { Office } from 'app/core/models/office.model';

@Component({
  selector: 'app-provider-call-logs',
  templateUrl: './provider-call-logs.component.html',
  styleUrls: ['./provider-call-logs.component.scss'],
})

export class ProviderCallLogsComponent implements OnInit {
  // this component handles provider call logs based on the
  // input (provider or office).
  @Input() provider: Provider;
  @Input() office: Office;

  providerCallLogs: ProviderCallLog[] = [];
  refreshing = false;
  paging: LimitOffsetPaging;
  eligible = true;
  currentSection: string;

  constructor(
    private providerCallLogsService: ProviderCallLogsService,
    private userSettingsService: UserSettingsService,
  ) {}

  get viewing() {
    return Math.min(this.paging.pageSize, this.paging.count);
  }

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.saveQueueSettings();
    this.loadCallLogs(this.paging.currentPage);
  }

  onPageChange(page) {
    this.saveQueueSettings();
    this.loadCallLogs(page);
  }

  onNewCallLog() {
    this.loadCallLogs(1)
  }

  highlightPhone(enteredPhone, officePhone) {
    if (enteredPhone && (enteredPhone !== officePhone)) {
      return "red-cell";
    }
    return "";
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.loadQueueSettings();
    this.paging.currentPage = 1;
    this.loadCallLogs(this.paging.currentPage);
  }

  private loadQueueSettings() {
    const queueSettings: ProviderCallLogQueueSettings =
      this.userSettingsService.get<ProviderCallLogQueueSettings>(
        UserSettings.captureAdminProviderCallLogQueueSettings
      );

    if (queueSettings) {
      this.paging = queueSettings.paging;
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new ProviderCallLogQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    this.userSettingsService.save<ProviderCallLogQueueSettings>(
      UserSettings.captureAdminProviderCallLogQueueSettings, queueSettings
    );
  }

  private loadCallLogs(page, refreshing = true) {
    const paging = { page, pageSize: this.paging.pageSize };
    const filters = { npi: this.provider?.npi, fax: this.office?.fax };

    this.refreshing = refreshing;

    this.providerCallLogsService.getList(
      filters,
      paging.page,
      paging.pageSize
    ).subscribe((result: PagedProviderCallLogs) => {
      this.providerCallLogs = result.providerCallLogs || [];
      this.paging = result.meta.paging;
      this.refreshing = false;
    });
  }
}
