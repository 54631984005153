import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Audit } from 'app/core/models/audit.model';
import { Office } from 'app/core/models/office.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { OfficeService, PagedOfficeAudits } from 'app/core/services/office.service';

@Component({
  selector: 'app-capture-admin-office-audit',
  templateUrl: './capture-admin-office-audit.component.html',
  styleUrls: ['./capture-admin-office-audit.component.scss'],
})
export class CaptureAdminOfficeAuditComponent implements OnInit {
  office: Office;
  audits: Audit[];

  loadingOffice: boolean;
  loadingAudits: boolean;

  paging: LimitOffsetPaging;

  constructor(
    private officeService: OfficeService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.init();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadAudits(this.paging.currentPage);
  }

  onPageChange(page: number) {
    this.loadAudits(page);
  }

  private init() {
    const id = this.route.snapshot.paramMap.get('id');
    this.loadingOffice = true;
    this.officeService.get(id).subscribe(office => {
      this.office = office;
      this.loadingOffice = false;
      this.loadAudits(this.paging.currentPage);
    });
  }

  private loadAudits(page: number) {
    const paging = { page, pageSize: this.paging.pageSize };

    this.loadingAudits = true;

    this.officeService.getAudits(this.office, paging).subscribe((result: PagedOfficeAudits) => {
      this.audits = result.records;
      this.paging = result.meta.paging;
      this.loadingAudits = false
    })
  }
}