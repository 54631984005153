import { Component, OnInit } from '@angular/core';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { Patient } from 'app/core/models/patient.model';
import { PatientFilters } from 'app/core/models/user-settings/patient-search-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { PagedPatients, PatientService } from 'app/core/services/patient.service';

@Component({
  selector: 'app-capture-admin-patients',
  templateUrl: './capture-admin-patients.component.html',
  styleUrls: ['./capture-admin-patients.component.scss'],
})
export class CaptureAdminPatientsComponent implements OnInit {
  filters: PatientFilters = new PatientFilters();
  patients: Patient[] = [];
  refreshing = false;
  validSearch = false;
  paging: LimitOffsetPaging;

  constructor(
    private userSettingsService: UserSettingsService,
    private patientService: PatientService,
  ) {}

  get viewing() {
    return Math.min(this.paging.pageSize, this.paging.count);
  }

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.loadPatients(this.paging.currentPage);
  }

  onPageChange(page) {
    this.loadPatients(page);
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.loadFilters();
    this.loadPatients(1);
  }

  onFiltersChange(filters: PatientFilters) {
    this.filters = filters;
    this.validSearch = this.calcValidSearch;

    this.saveFilters();
    this.loadPatients(this.paging.currentPage);
  }

  patientStatus(patient) {
    if (patient.inactiveDate) {
      return 'Inactive';
    } else {
      return 'Active';
    }
  }

  patientMainPatientId(patient) {
    if (patient.mainPatientId) {
      return patient.mainPatientId;
    } else {
      return patient.id;
    }
  }

  private get calcValidSearch() {
    return [
      this.filters.clientId,
      this.filters.patientId,
      this.filters.patientFirstName,
      this.filters.patientLastName,
      this.filters.patientDob,
      this.filters.patientMrn
    ].some( str => str && str.toString().length > 0 )
  }

  private loadFilters() {
    const filters = this.userSettingsService.get<PatientFilters>(UserSettings.captureAdminPatientFilters);

    if (filters) {
      this.filters = { ...filters };
    }
  }

  private saveFilters() {
    const newFilters = { ...this.filters };
    this.userSettingsService.save<PatientFilters>(UserSettings.captureAdminPatientFilters, newFilters);
  }

  private loadPatients(page) {
    const paging = { page, pageSize: this.paging.pageSize };

    if (this.validSearch) {
      this.refreshing = true;

      this.patientService.getList(paging, this.filters, true).subscribe((result: PagedPatients) => {
        this.patients = result.patients || [];
        this.paging = result.meta.paging;
        this.refreshing = false;
      });
    } else {
      this.patients = [];
      this.paging = LimitOffsetPaging.empty;
    }
  }
}
