import { Component } from '@angular/core';
import { BaseClientTaskHistoryComponent } from '../base-client-task-history/base-client-task-history.component';
import { ClientPrescriberTasksService } from 'app/core/services/client-prescriber-tasks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-client-prescriber-task-history',
  templateUrl: './client-prescriber-task-history.component.html',
  styleUrls: ['./client-prescriber-task-history.component.scss']
})
export class ClientPrescriberTaskHistoryComponent extends BaseClientTaskHistoryComponent {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected titleService: Title,
    protected clientTasksService: ClientPrescriberTasksService
  ) {
    super(route, router, titleService, clientTasksService);
  }
}
