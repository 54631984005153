import { DateTime } from 'luxon';
import parseUrl from 'parse-url';
import { YesNo } from '../enums/yes-no.enum';
import { CaptureQueueCursorType } from '../enums/capture-queue-cursor-type.enum';
import { CursorParams } from '../enums/cursor-params.enum';
import { isNull } from 'lodash-es';

export const isUrlExpired = (url: string) => {
  try {
    const parsedUrl = parseUrl(url);

    if (parsedUrl.query) {
      const issueDateParam = parsedUrl.query['X-Amz-Date'];
      const expiresParam = parsedUrl.query['X-Amz-Expires'];

      if (issueDateParam && expiresParam) {
        const issueDate = DateTime.fromISO(issueDateParam);
        const expirationDate = issueDate.plus({ seconds: Number(expiresParam) });
        return DateTime.now() > expirationDate;
      }
    }
  } catch (err) {
    console.error(err);
  }

  return false;
};

export const appendFilterListParams = <T>(paramName: string, values: T[], urlSearchParams: URLSearchParams) => {
  if (values && values.length > 0) {
    values.forEach(v => urlSearchParams.append(paramName, v.toString()));
  }
};

export const appendFilterFlagParam = (paramName: string, value: boolean, urlSearchParams: URLSearchParams) => {
  if (value) {
    urlSearchParams.append(paramName, YesNo.yes.toString());
  }
};

export const appendFilterParam = (paramName: string, value: string, urlSearchParams: URLSearchParams) => {
  if (value) {
    urlSearchParams.append(paramName, value);
  }
};

export const appendNullableFilterFlagParam = (paramName: string, value: boolean, urlSearchParams: URLSearchParams) => {
  if (value === true) {
    urlSearchParams.append(paramName, YesNo.yes.toString());
  } else if (value === false) {
    urlSearchParams.append(paramName, YesNo.no.toString());
  }
}

export const buildCursorParams = (cursorType: CaptureQueueCursorType, id: number = null, value: string = null) => {
  const urlSearchParams = new URLSearchParams();

  urlSearchParams.append(CursorParams.name, cursorType);

  if (!isNull(id)) {
    urlSearchParams.append(CursorParams.id, id.toString());
  }

  if (!isNull(value)) {
    urlSearchParams.append(CursorParams.value, value);
  }

  return urlSearchParams;
}
