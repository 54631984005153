import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CuratedSpecialtiesImport } from '../models/curated-specialties-import.model';

@Injectable()
export class CuratedSpecialtiesService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  create(signedId): Observable<CuratedSpecialtiesImport> {
    return this.http.post<CuratedSpecialtiesImport>(`${environment.captureApi.url}/curated_specialties_imports`, {
      curatedSpecialtiesImport: { signedId },
    });
  }

  getList(): Observable<{ curatedSpecialtiesImports: CuratedSpecialtiesImport[] }> {
    return this.http.get<{ curatedSpecialtiesImports: CuratedSpecialtiesImport[] }>(
      `${environment.captureApi.url}/curated_specialties_imports`
    );
  }
}
