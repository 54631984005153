<app-reports
  title="Icc Claims Export"
  [filtersForm]="filtersForm"
  [outputOptionsForm]="outputOptionsForm"
  [forceReportDetailsDisplay]="true"
  totalColumnHeader="Rows"
  [displayProcessedColumn]="false"
  [reportsUrl]="reportsUrl">

  <ng-template appNewReportOptionsTemplate let-filtersForm="filtersForm">
    <ng-container [formGroup]="filtersForm">

      <app-report-client-option
        label="Select Client (required)"
        [multiple]="false"
        formControlName="clientId"
        [clientFilters]="{ applicableToIccClaims: true }" />

      <app-icc-report-claim-import-option
        label="Select Claims File (required)"
        formControlName="iccClaimImportId"
        [clientId]="filtersForm.value.clientId && filtersForm.value.clientId.value.id" />

    </ng-container>

    <ng-container [formGroup]="outputOptionsForm">

      <app-report-boolean-option
        label="Include Original Source Data in Export"
        formControlName="includeSourceColumns" />

    </ng-container>
  </ng-template>

  <ng-template appReportDetailsTemplate let-report="report">

    <app-report-applied-client-option
      [options]="report.filters"
      [optionAssociations]="report.filterAssociations"
      name="clientId"
      label="Client" />

    <app-icc-report-applied-claim-import-option
      [options]="report.filters"
      [optionAssociations]="report.filterAssociations" />

    <app-report-applied-boolean-option
      [options]="report.outputOptions"
      name="includeSourceColumns"
      label="Include Original Source Data in Export" />

  </ng-template>

</app-reports>
