import { Component, Input } from '@angular/core';
import { InboundFaxStatus } from 'app/core/enums/inbound-fax-status.enum';
import { OutboundFax } from '../../../core/models/outbound-fax.model';

@Component({
  selector: 'app-consult-note-received',
  templateUrl: './consult-note-received.component.html',
  styleUrls: ['./consult-note-received.component.scss'],
})
export class ConsultNoteReceivedComponent {
  @Input() outboundFax: OutboundFax;
  inboundFaxStatuses = InboundFaxStatus;

  constructor() {}
}
