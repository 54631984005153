import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-llm-playground',
  templateUrl: './llm-playground.component.html',
  styleUrls: ['./llm-playground.component.scss'],
})
export class LlmPlaygroundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
