import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { ReportOption } from 'app/core/models/report-option.model';

export enum TextOptionType {
  text = 'text',
  integer = 'integer',
}

export class ReportTextOption implements ReportOption {
  value: string;

  constructor(value) {
    this.value = value;
  }

  toPostData(options = { paramName: 'input' }) {
    return { [options.paramName]: this.value };
  }
}

@Component({
  selector: 'app-report-text-option',
  templateUrl: './report-text-option.component.html',
  styleUrls: ['./report-text-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportTextOptionComponent),
    },
  ],
})
export class ReportTextOptionComponent extends ReportAbstractOptionComponent {
  @Input() label: string;
  @Input() type: string;

  value: string;
  textOptionType = TextOptionType;

  protected updateInternalState(model: ReportTextOption) {
    if (model !== null && model !== undefined) {
      this.value = model.value;
    } else {
      this.value = null;
    }
  }

  protected determineModelStateOnChange(): ReportTextOption {
    if (this.value !== null) {
      return new ReportTextOption(this.value);
    } else {
      return null;
    }
  }
}
