import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { IccEligibleProviderImport } from '../../models/icc/icc-eligible-provider-import.model';
import { IccImportFilters } from '../../models/icc/icc-import-settings.model';

@Injectable()
export class IccEligibleProviderImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<IccEligibleProviderImport> {
    return this.http.get<IccEligibleProviderImport>(
      `${environment.captureApi.url}/icc/eligible_provider_imports/${id}`
    );
  }

  getList(filters: IccImportFilters = null): Observable<{ eligibleProviderImports: IccEligibleProviderImport[] }> {
    const params = new URLSearchParams();

    if (filters && filters.selectedClientIds) {
      filters.selectedClientIds.forEach(clientId => params.append('client_ids[]', clientId));
    }

    return this.http.get<{ eligibleProviderImports: IccEligibleProviderImport[] }>(
      `${environment.captureApi.url}/icc/eligible_provider_imports?${params.toString()}`
    );
  }

  create(clientId: number, name: string, signedId: string): Observable<IccEligibleProviderImport> {
    return this.http.post<IccEligibleProviderImport>(`${environment.captureApi.url}/icc/eligible_provider_imports`, {
      eligibleProviderImport: { clientId, name, signedId },
    });
  }

  update(id, name): Observable<IccEligibleProviderImport> {
    return this.http.put<IccEligibleProviderImport>(
      `${environment.captureApi.url}/icc/eligible_provider_imports/${id}`,
      {
        eligibleProviderImport: { name },
      }
    );
  }

  cancel(id): Observable<IccEligibleProviderImport> {
    return this.http.patch<IccEligibleProviderImport>(
      `${environment.captureApi.url}/icc/eligible_provider_imports/${id}/cancel`,
      {}
    );
  }
}
