import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PrescriptionEhrMatchVerification } from '../models/prescription-ehr-match-verification.model';
import { ApiService } from './api.service';

@Injectable()
export class PrescriptionEhrMatchVerificationsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  update(
    prescriptionId: number,
    { prescriptionWrittenAtCe, notes }: PrescriptionEhrMatchVerification
  ): Observable<PrescriptionEhrMatchVerification> {
    return this.http.patch<PrescriptionEhrMatchVerification>(
      `${environment.captureApi.url}/prescriptions/${prescriptionId}/prescription_ehr_match_verification`,
      {
        prescriptionEhrMatchVerification: {
          prescriptionWrittenAtCe,
          notes
        },
      }
    );
  }
}
