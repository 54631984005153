import { Component } from '@angular/core';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';


@Component({
  selector: 'app-capture-history-item-type-developer-chore-updated',
  templateUrl: './capture-history-item-type-developer-chore-updated.component.html',
})
export class CaptureHistoryItemTypeDeveloperChoreUpdatedComponent extends CaptureHistoryItemTypeBaseComponent {
  get references() {
    return this.captureHistoryItem.data?.references;
  }

  get note() {
    return this.captureHistoryItem.data?.note;
  }
}
