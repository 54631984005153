import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { ReferralMatchVerificationsEhrBaseComponent } from '../referral-match-verifications-ehr-base/referral-match-verifications-ehr-base.component';
import { EhrReferralVerification } from 'app/core/models/ehr-referral-verification.model';
import { EhrReferralMatchOption } from 'app/core/models/ehr-referral-match-option.model';

@Component({
  selector: 'app-referral-match-verifications-ehr-edit',
  templateUrl: '../referral-match-verifications-ehr-base/referral-match-verifications-ehr-base.component.html',
  styleUrls: ['../referral-match-verifications-ehr-base/referral-match-verifications-ehr-base.component.scss'],
})
export class ReferralMatchVerificationsEhrEditComponent
  extends ReferralMatchVerificationsEhrBaseComponent
  implements OnInit {

  @Output() ehrReferralMatchOptionsChanged = new EventEmitter<EhrReferralMatchOption[]>();

  ngOnInit() {
    this.editable = true;

    super.ngOnInit();
  }

  protected referralMatchVerificationDisabled() {
    return false;
  }

  protected handleRmvSelectionChange(option: EhrReferralMatchOption) {
    if (option.selected) {
      this.pendingRmvs.add(option.verification);
    } else {
      this.pendingRmvs.delete(option.verification);
    }

    this.emitOptionChange();
  }

  protected handleNewEhrReferralVerification(newVerification: EhrReferralVerification) {
    const newRmv = new ReferralMatchVerification();
    newRmv.ehrReferralVerificationId = newVerification.id;

    this.pendingRmvs.add(newRmv);
    this.referralMatchVerifications.push(newRmv);

    this.emitOptionChange();
  }

  protected emitOptionChange() {
    this.ehrReferralMatchOptionsChanged.emit(this.referralMatchOptions);
  }
}
