import { Component, OnInit } from '@angular/core';
import { Upload } from '../../../core/models/upload.model';
import { PatientInteractionLocationImportsService } from '../../../core/services/patient-interaction-location-imports.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-capture-admin-import-patient-interaction-locations',
  templateUrl: './capture-admin-import-patient-interaction-locations.component.html',
  styleUrls: ['./capture-admin-import-patient-interaction-locations.component.scss'],
})
export class CaptureAdminImportPatientInteractionLocationsComponent implements OnInit {
  uploading = false;
  formModel = {};
  errors: string[] = [];

  constructor(
    private patientInteractionLocationImportsService: PatientInteractionLocationImportsService,
    private router: Router
  ) {}

  ngOnInit() {}

  onUploadStart() {
    this.uploading = true;
    this.errors = [];
  }

  onUploadCancel() {
    this.uploading = false;
  }

  onUploadComplete(upload: Upload) {
    this.patientInteractionLocationImportsService.create(upload.signedId).subscribe(
      () => {
        this.router.navigate(['/capture-admin/tools/import-patient-interaction-locations/history']);
        this.uploading = false;
      },
      (error: HttpErrorResponse) => {
        this.uploading = false;
        this.errors = error.error.errors;
      }
    );
  }

  onUploadError() {
    this.uploading = false;
  }

  onFormEnter($event) {
    $event.preventDefault();
  }

  public get hasError() {
    return !isEmpty(this.errors);
  }
}
