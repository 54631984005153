export enum JwtTokenType {
  Id,
  Access,
}

export class JwtToken {
  constructor(private decodedPayload) {}

  get authenticatedAt(): Date {
    return this.getTokenTimestampAsDate('auth_time');
  }

  get issuedAt(): Date {
    return this.getTokenTimestampAsDate('iat');
  }

  get expiresAt(): Date {
    return this.getTokenTimestampAsDate('exp');
  }

  get issuedAtTimestamp(): number {
    return this.getTokenTimestamp('iat');
  }

  get expiresAtTimestamp(): number {
    return this.getTokenTimestamp('exp');
  }

  get payload(): object {
    return this.decodedPayload;
  }

  private getTokenTimestamp(name): number {
    if (this.decodedPayload && this.decodedPayload[name]) {
      return Number(this.decodedPayload[name]);
    } else {
      return null;
    }
  }

  private getTokenTimestampAsDate(name): Date {
    if (this.decodedPayload && this.decodedPayload[name]) {
      const loggedInAt = new Date(0);
      loggedInAt.setUTCSeconds(this.decodedPayload[name]);
      return loggedInAt;
    } else {
      return null;
    }
  }
}
