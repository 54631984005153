import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { ClientConsultNoteVerification } from '../models/client-consult-note-verification.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';

export class PagedClientConsultNoteVerifications implements PagedResults {
  clientConsultNoteVerifications: ClientConsultNoteVerification[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.clientConsultNoteVerifications;
  }
}

@Injectable()
export class ClientConsultNoteVerificationsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getByCapture(id: number): Observable<ClientConsultNoteVerification> {
    return this.http.get<ClientConsultNoteVerification>(
      `${environment.captureApi.url}/captures/${id}/client_consult_note_verification`
    );
  }

  getList(
    filters: { patientPrescriberId?: number },
    paging: { page: number; pageSize: number } = { page: 1, pageSize: 1000 }
  ): Observable<PagedClientConsultNoteVerifications> {
    let url = `${environment.captureApi.url}/client_consult_note_verifications?page=${paging.page}&per_page=${
      paging.pageSize
    }`;

    if (filters.patientPrescriberId) {
      url = `${url}&patient_prescriber_id=${filters.patientPrescriberId}`;
    }

    return this.http.get<PagedClientConsultNoteVerifications>(url);
  }

  create(patientPrescriberId: number): Observable<ClientConsultNoteVerification> {
    return this.http.post<ClientConsultNoteVerification>(
      `${environment.captureApi.url}/client_consult_note_verifications`,
      {
        clientConsultNoteVerification: { patientPrescriberId },
      }
    );
  }

  update(id: number, encounterDate: string): Observable<ClientConsultNoteVerification> {
    return this.http.patch<ClientConsultNoteVerification>(
      `${environment.captureApi.url}/client_consult_note_verifications/${id}`,
      {
        clientConsultNoteVerification: { encounterDate },
      }
    );
  }

  destroy(id: number): Observable<ClientConsultNoteVerification> {
    return this.http.delete<ClientConsultNoteVerification>(
      `${environment.captureApi.url}/client_consult_note_verifications/${id}`,
      {}
    );
  }
}
