
<div *ngIf="!clientChore.errorText">
  {{ clientChore.status | titlecase }}
</div>
<div *ngIf="clientChore.errorText">
  <a
    *ngIf="clientChore.errorText" [href]="'#error'"
    class="failed"
    placement="bottom"
    container="body"
    (click)="onNoteClick($event)"
    [ngbPopover]="clientChore.errorText">
    {{ clientChore.status | titlecase }}
  </a>
</div>

<div *ngIf="clientChore.currentStep && !clientChore.ended" class="step-progress">
  <div *ngIf="clientChore.currentStepNumber">
    Step {{ clientChore.currentStepNumber}} of {{ clientChore.totalSteps }}:
  </div>
  <div *ngIf="!clientChore.currentStepNumber">
    Step:
  </div>
  {{ clientChore.currentStepDisplayName }}
  <span *ngIf="clientChore.currentStepPercentage > 0">
    ({{ clientChore.currentStepPercentage }}%)
  </span>
</div>
