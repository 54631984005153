export const AppTabIdSessionKey = 'appTabId';
export const UserSessionKey = 'userSession';

// NOTE: do not include spaces in version specifications
export const supportedBrowserVersions = {
  chrome: '>=72',
  firefox: '>=68',
  edge: '>=80',
};

export const supportEmailAddress = '340BSupport@cloudmed.com';
