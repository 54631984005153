import { Component, EventEmitter, Output, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { ReferralMatchVerificationsService } from '../../../core/services/referral-match-verifications.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PatientService } from '../../../core/services/patient.service';
import { ReferralMatchVerificationsStandardBaseComponent } from '../referral-match-verifications-standard-base/referral-match-verifications-standard-base.component';
import {
  ReferralMatchVerificationEvent,
  ReferralMatchVerificationEventType
} from 'app/core/models/referral-match-verification-event.model';

@Component({
  selector: 'app-referral-match-verifications-standard',
  templateUrl: './referral-match-verifications-standard.component.html',
  styleUrls: ['./referral-match-verifications-standard.component.scss'],
})
export class ReferralMatchVerificationsStandardComponent
  extends ReferralMatchVerificationsStandardBaseComponent
  implements OnInit, OnChanges {

  @Output() verificationChangeInitiated = new EventEmitter<void>();

  constructor(
    protected authService: AuthenticationService,
    protected patientService: PatientService,
    private referralMatchVerificationsService: ReferralMatchVerificationsService
  ) {
    super(authService, patientService);
  }

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'capture' || inputName === 'verificationChangeInProgress') {
        this.refreshReferralMatchOptions();
      }

      if (inputName === 'patientPrescriber') {
        this.setupReferralMatchOptions();
      }
    });
  }

  onReferralVerificationChange(referralMatchVerification: ReferralMatchVerification) {
    this.createOrUpdateReferralMatchVerification(referralMatchVerification);
  }

  private createOrUpdateReferralMatchVerification(referralMatchVerification: ReferralMatchVerification) {
    this.verificationChangeInitiated.emit();

    this.referralMatchVerificationsService.
      createOrUpdate(this.patientPrescriber.id, referralMatchVerification).
      subscribe((rmv: ReferralMatchVerification) => {
        const changeEvent =
          new ReferralMatchVerificationEvent(ReferralMatchVerificationEventType.changed, rmv);

        this.verificationChange.emit(changeEvent);
      });
  }
}
