/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientReferralTask } from '../../../core/models/client-referral-task.model';

@Component({
  selector: '[app-client-referral-task-list-row]',
  templateUrl: './client-referral-task-list-row.component.html',
  styleUrls: ['./client-referral-task-list-row.component.scss'],
})
export class ClientReferralTaskListRowComponent implements OnInit {
  @Input() task: ClientReferralTask;
  @Input() position: number;

  @Output() viewTask = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  onViewClick() {
    this.viewTask.emit(this.position);
  }
}
