import { Component } from '@angular/core';
import { BaseClientTaskHistoryComponent } from '../base-client-task-history/base-client-task-history.component';
import { ClientConsultNoteTasksService } from 'app/core/services/client-consult-note-tasks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-client-consult-note-task-history',
  templateUrl: './client-consult-note-task-history.component.html',
  styleUrls: ['./client-consult-note-task-history.component.scss']
})
export class ClientConsultNoteTaskHistoryComponent extends BaseClientTaskHistoryComponent {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected titleService: Title,
    protected clientTasksService: ClientConsultNoteTasksService
  ) {
    super(route, router, titleService, clientTasksService);
  }
}
