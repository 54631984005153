<div>
  <span *ngIf="count; else: loading">
    {{ count }} Capture{{ count > 1 ? 's' : '' }}
  </span>
  <span> | </span>
  <a class="text-danger reset-filters"
     href="javascript:;"
     (click)="onResetFiltersClick()">Reset Filters</a>
</div>
<ng-template #loading><span>Loading...</span></ng-template>
