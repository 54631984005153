<form
  [formGroup]="formGroup"
  (submit)="onSubmit()">

  <div>
    <strong>Consult Note Name</strong>
  </div>

  <input
    type="text"
    class="form-control"
    appFormControlValidityClass
    formControlName="name" />

  <app-form-control-server-errors controlName="name" />

  <div class="mt-2">
    <strong>Client Note</strong>
  </div>

  <textarea
    class="form-control"
    appFormControlValidityClass
    formControlName="notes"></textarea>

  <app-form-control-server-errors controlName="notes" />

  <div class="actions mt-2">
    <button type="submit" class="btn btn-primary">
      Save
    </button>

    <button type="button" class="btn btn-secondary" (click)="onCancelClick()">
      Cancel
    </button>
  </div>
</form>
