<div class="reject-prescription rounded py-2 px-4" [ngClass]="{ checked: checked, disabled: disabled }">
  <div class="form-check" [ngClass]="{ disabled: disabled }">
    <input
      [id]="prescriptionId"
      [name]="'rejectPrescription' + prescriptionId"
      class="form-check-input"
      type="checkbox"
      [disabled]="disabled"
      [(ngModel)]="checked"
      (change)="onRejectionChange()"
    />
    <label [for]="prescriptionId" class="form-check-label"> REJECT </label>
  </div>
</div>
