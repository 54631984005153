export class PatientSearchFilters {
  clientId: number;
  patientFirstName: string = null;
  patientLastName: string = null;
  patientDob: string = null;
}

export class PatientFilters {
  clientId: number;
  patientId: string = null;
  patientFirstName: string = null;
  patientLastName: string = null;
  patientDob: string = null;
  patientMrn: string = null;
}
