import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../core/services/navigation.service';
import { AuthenticationService } from '../core/services/authentication.service';
import { FeaturesService } from '../core/services/features.service';
import { UserRoleName } from '../core/enums/user-role-name.enum';
import { InboundFaxesService } from 'app/core/services/inbound-faxes.service';
import { ConsultNoteQueueService } from 'app/core/services/consult-note-queue.service';
import { ClientChoresService } from 'app/core/services/client-chores.service';

const sections = {
  captures: false,
  reports: false,
  communications: false,
  tools: false,
  providers: false,
  offices: false,
  'referral-claims/clients': false,
  'icc-claims/imports': false,
  'icc-claims/exports': false,
  'my-work': false,
  'client-management': false,
};

@Component({
  selector: 'app-capture-admin',
  templateUrl: './capture-admin.component.html',
  styleUrls: ['./capture-admin.component.scss'],
})
export class CaptureAdminComponent implements OnInit, OnDestroy, AfterContentInit {
  userLoginSubscription = null;
  userLogoutSubscription = null;
  pageChangedSubscription = null;

  userName = '';
  sidebarCollapsed = false;
  activeSections = { ...sections };
  canManageUsers = false;
  canManageClients = false;
  canAccessReferralClaims = false;
  canAccessIccClaims = false;
  canManageClientChores = false;
  consultNotePagesEnabled = false;
  myWorkFaxesEnabled = false;
  myWorkConsultNotesEnabled = false;
  myWorkClientChoresEnabled = false;
  myWorkPagesEnabled = false;

  myWorkCounts = {
    faxes: null,
    consultNotes: null,
    clientChores: null
  };

  constructor(
    private navigationService: NavigationService,
    private authService: AuthenticationService,
    private featuresService: FeaturesService,
    private route: Router,
    private inboundFaxesService: InboundFaxesService,
    private consultNoteQueueService: ConsultNoteQueueService,
    private clientChoresService: ClientChoresService,
  ) { }

  ngOnInit() {
    if (this.route.url === '/capture-admin') {
      this.redirectToFirstAccessibleSection();
      return;
    }

    this.refresh();

    this.userLoginSubscription = this.authService.userLogin.subscribe(() => {
      this.refresh();
    });

    this.userLogoutSubscription = this.authService.userLogout.subscribe(() => {
      this.refresh();
    });
  }

  ngAfterContentInit() {
    this.pageChangedSubscription = this.navigationService.pageChanged.subscribe(() => {
      this.refresh();
    });
  }

  ngOnDestroy() {
    if (this.userLoginSubscription) {
      this.userLoginSubscription.unsubscribe();
    }

    if (this.userLogoutSubscription) {
      this.userLogoutSubscription.unsubscribe();
    }

    if (this.pageChangedSubscription) {
      this.pageChangedSubscription.unsubscribe();
    }
  }

  onToggleCollapseClick($event: Event) {
    $event.preventDefault();
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  private refresh() {
    this.consultNotePagesEnabled = this.featuresService.get()['consultNotePages'];

    this.userName = this.authService.currentUser?.fullName || '';

    this.determineRolePermissions();

    Object.keys(this.activeSections).forEach(k => {
      this.activeSections[k] = this.showingSection(k) || this.showingCapturesSection(k);
    });

    if (this.myWorkPagesEnabled) {
      this.refreshMyWorkCounts();
    }
  }

  private determineRolePermissions() {
    this.canManageUsers = this.authService.hasPermissionTo(UserRoleName.manageUsers);
    this.canManageClients = this.authService.hasPermissionTo(UserRoleName.manageClients);
    this.canAccessReferralClaims = this.authService.hasPermissionTo(UserRoleName.accessReferralClaims);
    this.canAccessIccClaims = this.authService.hasPermissionTo(UserRoleName.accessIccClaims);
    this.canManageClientChores = this.authService.hasPermissionTo(UserRoleName.manageClientChores);
    this.myWorkFaxesEnabled = this.authService.hasPermissionTo(UserRoleName.faxQueue);
    this.myWorkConsultNotesEnabled = this.authService.hasPermissionTo(UserRoleName.consultNoteUser);
    this.myWorkClientChoresEnabled =
      this.canManageClientChores || this.authService.currentUser?.assignedClients?.length > 0;
    this.myWorkPagesEnabled =
      this.myWorkFaxesEnabled || this.myWorkConsultNotesEnabled || this.myWorkClientChoresEnabled;
  }

  private showingSection(section: string) {
    return this.navigationService.inSubSection(section);
  }

  private showingCapturesSection(section: string) {
    if (section !== 'captures') return;

    const currentUrl = this.navigationService.currentUrl.split('?')[0];
    return currentUrl.endsWith('captures') || currentUrl.endsWith('internal-consultant-queue');
  }

  private redirectToFirstAccessibleSection() {
    if (this.authService.hasPermissionTo(UserRoleName.accessReferralClaims)) {
      this.navigationService.navigateTo(`/capture-admin/captures`);
    } else if (this.authService.hasPermissionTo(UserRoleName.accessIccClaims)) {
      this.navigationService.navigateTo(`/capture-admin/icc-claims/imports/claims`);
    } else if (this.authService.hasPermissionTo(UserRoleName.manageClients)) {
      this.navigationService.navigateTo(`/capture-admin/client-management/clients`);
    } else if (this.authService.hasPermissionTo(UserRoleName.manageUsers)) {
      this.navigationService.navigateTo(`/capture-admin/users`);
    }
  }

  private refreshMyWorkCounts() {
    if (this.myWorkFaxesEnabled) {
      this.inboundFaxesService.getMyQueueCount().subscribe((count) => {
        this.myWorkCounts.faxes = count;
      });
    }

    if (this.myWorkConsultNotesEnabled) {
      this.consultNoteQueueService.getMyCount(this.authService.currentUser?.id).subscribe((result) => {
        this.myWorkCounts.consultNotes = result.count;
      });
    }

    if (this.myWorkClientChoresEnabled) {
      this.clientChoresService.getMyQueueCount().subscribe((count) => {
        this.myWorkCounts.clientChores = count;
      });
    }
  }
}
