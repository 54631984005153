import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { environment } from '../../../environments/environment';

import { ClientFile } from '../models/client-file.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';

export class PagedClientFiles implements PagedResults {
  clientFiles: ClientFile[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.clientFiles;
  }
}

@Injectable()
export class ClientFilesService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(
    page: number,
    pageSize: number,
    clientIds: Array<number> = [],
    filename: string = ''
  ): Observable<PagedClientFiles> {
    const searchParams = new URLSearchParams(`page=${page}&per_page=${pageSize}`);
    clientIds.forEach(clientId => searchParams.append('client_ids[]', clientId.toString()));
    if (filename) {
      searchParams.append('filename', filename);
    }
    return this.http.get<PagedClientFiles>(`${environment.captureApi.url}/client_files?${searchParams.toString()}`);
  }
  generateErrorFileAndGetDownloadUrl(id: number): Observable<any> {
    return this.http.get<any>(
      `${environment.captureApi.url}/client_files/${id}/generate_error_file_and_get_download_url`
    );
  }
}
