import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { CaptureAdminCaptureInvoiceLineItemReportsComponent } from '../../../capture-admin/pages/capture-admin-capture-invoice-line-item-reports/capture-admin-capture-invoice-line-item-reports.component';

@Component({
  templateUrl:
    '../../../capture-admin/pages/capture-admin-capture-invoice-line-item-reports/capture-admin-capture-invoice-line-item-reports.component.html',
})
export class ClientPortalCaptureInvoiceLineItemReportComponent extends
    CaptureAdminCaptureInvoiceLineItemReportsComponent {
  reportsUrl = `${environment.captureApi.url}/client_capture_invoice_line_item_reports`;
  reportsCountUrl = `${environment.captureApi.url}/client_capture_invoice_line_item_reports/count`;
}
