import { Pipe, PipeTransform } from '@angular/core';
import { ReferralVerificationReasonShortNames } from 'app/core/options/referral-verification-reasons.opts';

@Pipe({
  name: 'referralVerificationReasonShortNames',
})
export class ReferralVerificationReasonShortNamesPipe implements PipeTransform {
  transform(input: any[]): Array<string> {
    return input.map(value => ReferralVerificationReasonShortNames[value]);
  }
}
