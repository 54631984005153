import { Component, OnInit } from '@angular/core';
import { LimitOffsetPaging, Sort } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { ProviderOfficeStatusReview } from 'app/core/models/provider-office-status-review.model';
import { ProviderOfficeStatusReviewFilters, ProviderOfficeStatusReviewQueueSettings } from 'app/core/models/user-settings/provider-office-status-review-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { ProviderOfficeStatus } from 'app/core/enums/provider-office-status.enum';
import { PagedProviderOfficeStatusReviews, ProviderOfficeService } from 'app/core/services/provider-office.service';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';
import { keyedStatusOptions as keyedProviderStatusOptions } from 'app/core/options/provider-status.opts';
import { keyedStatusOptions as keyedOfficeStatusOptions } from 'app/core/options/office-status.opts';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

enum ProviderOfficeStatusReviewsSortBy {
  statusReviewAddedAtDesc = 'review_status_added_at_desc',
  statusReviewAddedAtAsc = 'review_status_added_at_asc',
}

@Component({
  selector: 'app-capture-admin-provider-office-status-review-queue',
  templateUrl: './capture-admin-provider-office-status-review-queue.component.html',
  styleUrls: ['./capture-admin-provider-office-status-review-queue.component.scss'],
})
export class CaptureAdminProviderOfficeStatusReviewQueueComponent implements OnInit {
  filters: ProviderOfficeStatusReviewFilters;
  sort: Sort;
  statusReviews: ProviderOfficeStatusReview[] = [];
  loading = false;
  paging = LimitOffsetPaging.empty;
  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;
  keyedProviderStatusOptions = keyedProviderStatusOptions;
  keyedOfficeStatusOptions = keyedOfficeStatusOptions;

  formGroup: FormGroup;

  sortByOptions = [
    { value: ProviderOfficeStatusReviewsSortBy.statusReviewAddedAtDesc, display: 'Review Created On - Newest First' },
    { value: ProviderOfficeStatusReviewsSortBy.statusReviewAddedAtAsc, display: 'Review Created On - Oldest First' },
  ];

  static sortByValueToSort = {
    [ProviderOfficeStatusReviewsSortBy.statusReviewAddedAtDesc]: new Sort('review_status_added_at', 'desc'),
    [ProviderOfficeStatusReviewsSortBy.statusReviewAddedAtAsc]: new Sort('review_status_added_at', 'asc'),
  };

  filterOptions = [
    keyedProviderOfficeStatusOptions[ProviderOfficeStatus.validFax],
    keyedProviderOfficeStatusOptions[ProviderOfficeStatus.unresponsiveProvider],
    keyedProviderOfficeStatusOptions[ProviderOfficeStatus.unresponsiveOffice],
    keyedProviderOfficeStatusOptions[ProviderOfficeStatus.testing],
    keyedProviderOfficeStatusOptions[ProviderOfficeStatus.providerNotAtOffice],
    keyedProviderOfficeStatusOptions[ProviderOfficeStatus.providerNoLongerAtOffice],
    keyedProviderOfficeStatusOptions[ProviderOfficeStatus.officeOptedOut],
    keyedProviderOfficeStatusOptions[ProviderOfficeStatus.notAMedicalOffice],
    keyedProviderOfficeStatusOptions[ProviderOfficeStatus.faxFailed],
  ];

  constructor(
    private providerOfficeService: ProviderOfficeService,
    private userSettingsService: UserSettingsService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.loadQueueSettings();
    this.loadReviews();
    this.initFormGroup();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadReviews();
    this.saveQueueSettings();
  }

  onPageChange() {
    this.loadReviews();
    this.saveQueueSettings();
  }

  onClearReviewClick(statusReview: ProviderOfficeStatusReview) {
    const attributes = { reviewStatus: null }

    this.providerOfficeService.update(statusReview, attributes).subscribe(
      providerOffice => this.handleSaveSuccess(providerOffice),
      error => this.handleSaveFailure(error)
    )
  }

  onProviderOfficeChanged(providerOffice: ProviderOffice) {
    this.removeReviewForProviderOffice(providerOffice.id);
  }

  onResetFiltersClick() {
    this.paging = LimitOffsetPaging.empty;

    this.sort =
      CaptureAdminProviderOfficeStatusReviewQueueComponent.sortByValueToSort[this.sortByOptions[0].value];

    this.filters = new ProviderOfficeStatusReviewFilters();

    this.loadReviews();
    this.saveQueueSettings();
    this.initFormGroup();
  }

  private handleSaveSuccess(providerOffice: ProviderOffice) {
    this.removeReviewForProviderOffice(providerOffice.id);
  }

  private handleSaveFailure(response: HttpErrorResponse) {
    console.error(response);
  }

  private loadQueueSettings() {
    const queueSettings: ProviderOfficeStatusReviewQueueSettings =
      this.userSettingsService.get<ProviderOfficeStatusReviewQueueSettings>(
        UserSettings.captureAdminProviderOfficeStatusReviewQueueSettings
      );

    if (queueSettings) {
      this.filters = queueSettings.filters;
      this.paging = queueSettings.paging;
      this.sort = queueSettings.sort;
    } else {
      this.filters = new ProviderOfficeStatusReviewFilters();
      this.sort =
        CaptureAdminProviderOfficeStatusReviewQueueComponent.
          sortByValueToSort[ProviderOfficeStatusReviewsSortBy.statusReviewAddedAtDesc];
    }
  }

  private saveQueueSettings() {
    const queueSettings = new ProviderOfficeStatusReviewQueueSettings();
    queueSettings.filters = this.filters;
    queueSettings.paging = this.paging;
    queueSettings.sort =
      CaptureAdminProviderOfficeStatusReviewQueueComponent.sortByValueToSort[this.formGroup.value.sort];

    this.userSettingsService.
      save<ProviderOfficeStatusReviewQueueSettings>(
        UserSettings.captureAdminProviderOfficeStatusReviewQueueSettings,
        queueSettings
      );
  }

  private loadReviews(showLoading = true) {
    const paging = {
      page: this.paging.currentPage,
      pageSize: this.paging.pageSize
    };

    if (showLoading) {
      this.loading = true;
    }

    this.providerOfficeService.getStatusReviewList(paging, this.filters, this.sort).
      subscribe((result: PagedProviderOfficeStatusReviews) => {
        this.statusReviews = result.records || [];
        this.paging = result.meta.paging;
        this.loading = false;
      });
  }

  private removeReviewForProviderOffice(providerOfficeId: number) {
    const reviewIndex = this.statusReviews.findIndex(statusReview => statusReview.id === providerOfficeId);

    if (reviewIndex >= 0) {
      this.statusReviews.splice(reviewIndex, 1);
      this.paging.count -= 1;
      this.loadReviews(false);
    }
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      sort: `${this.sort.col}_${this.sort.dir}`,
      filters: this.formBuilder.group({
        reviewStatus: [this.filters.reviewStatus]
      })
    });

    this.formGroup.valueChanges.subscribe(_value => {
      this.paging.currentPage = 1;

      const formValue = this.formGroup.value;

      this.sort =
        CaptureAdminProviderOfficeStatusReviewQueueComponent.sortByValueToSort[formValue.sort];

      this.filters = new ProviderOfficeStatusReviewFilters();
      this.filters.reviewStatus = formValue.filters.reviewStatus;

      this.saveQueueSettings();

      this.loadReviews();
    })
  }
}
