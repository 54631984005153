import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../core/services/navigation.service';
import { LoginAttemptStatus } from '../../../core/enums/login-attempt-status.enum';
import { AuthenticationService, LoginAttemptResult } from '../../../core/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-saml',
  templateUrl: './saml.component.html',
  styleUrls: ['./saml.component.scss'],
})
export class SamlComponent implements OnInit {
  loginMessage = null;
  loginStatus = null;
  loginStatuses = LoginAttemptStatus;

  constructor(private authService: AuthenticationService,
              private navigationService: NavigationService,
              private route: ActivatedRoute,
              private router: Router) {}

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    const code = queryParams['code'];

    if (!code) {
      this.router.navigate(['/login'], { replaceUrl: true });
    }

    this.authService.loginFromCode(code).subscribe((loginAttemptResult: LoginAttemptResult) => {
      this.loginMessage = loginAttemptResult.data;
      this.loginStatus = loginAttemptResult.status;
    });
  }

}
