<div *ngIf="!processing; else: spinner" ngbDropdown class="d-inline-block text-nowrap">
  <button id="actionDropdown" class="btn btn-secondary" ngbDropdownToggle>Select Action</button>
  <div ngbDropdownMenu aria-labelledby="actionDropdown">
    <a class="dropdown-item" routerLink="/capture-admin/client-management/clients/{{client.id}}/edit">
      Edit Client
    </a>
    <a class="dropdown-item" routerLink="/capture-admin/client-management/clients/{{client.id}}/edit-configuration-options">
      Edit Config
    </a>
    <a class="dropdown-item"
       href="#viewPortal"
       (click)="onViewPortalClick($event)">
      View Portal
    </a>
    <a class="dropdown-item" routerLink="/capture-admin/client-management/clients/{{client.id}}/change-log">
      View Change Log
    </a>
    <a *ngIf="client.status === clientStatuses.active"
       class="dropdown-item"
       href="#deactivate"
       (click)="onDeactivateClick($event)">
      Deactivate
    </a>
    <a *ngIf="client.status === clientStatuses.deactivated"
       class="dropdown-item"
       href="#reactivate"
       (click)="onReactivateClick($event)">
      Reactivate
    </a>
  </div>
</div>
<ng-template #spinner>
  <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" /> Processing...
</ng-template>
