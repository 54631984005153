<nav class="nav nav-pills">
  <a
    *ngIf="showValidateReferral"
    class="nav-item nav-link"
    href="#"
    (click)="navClick($event, 'validateReferral')"
    [class.active]="currentSection === 'validateReferral'">
    Validate Referral
  </a>
  <a
    *ngIf="showCeReviewDetails"
    class="nav-item nav-link"
    href="#"
    (click)="navClick($event, 'ceReviewDetails')"
    [class.active]="currentSection === 'ceReviewDetails'">
    CE Review Details
  </a>
  <a
    class="nav-item nav-link"
    href="#"
    (click)="navClick($event, 'patientPrescriptions')"
    [class.active]="currentSection === 'patientPrescriptions'">
    Patient Prescriptions
  </a>
  <a
    *ngIf="showPatientDocuments"
    class="nav-item nav-link"
    href="#"
    (click)="navClick($event, 'patientDocuments')"
    [class.active]="currentSection === 'patientDocuments'">
    Patient Documents
  </a>
  <a
    *ngIf="showConsultNoteVerification"
    class="nav-item nav-link"
    href="#"
    (click)="navClick($event, 'consultNoteVerification')"
    [class.active]="currentSection === 'consultNoteVerification'">
    Validate Specialist Encounter Timeframe
  </a>
  <a
    class="nav-item nav-link"
    href="#"
    *ngIf="showClientPrescriptionMatches"
    (click)="navClick($event, 'clientPrescriptionMatches')"
    [class.active]="currentSection === 'clientPrescriptionMatches'">
    E-Prescribe Matches
  </a>
  <a
    class="nav-item nav-link"
    href="#"
    (click)="navClick($event, 'validatePatientCareOwnership')"
    [class.active]="currentSection === 'validatePatientCareOwnership'">
    Validate Patient Care Ownership
  </a>
  <a
    *ngIf="showValidatePrescriptionWrittenAtCe"
    class="nav-item nav-link"
    href="#"
    (click)="navClick($event, 'validatePrescriptionWrittenAtCe')"
    [class.active]="currentSection === 'validatePrescriptionWrittenAtCe'">
    Validate Prescription
  </a>
  <a
    class="nav-item nav-link"
    href="#"
    (click)="navClick($event, 'captureVerification')"
    [class.active]="currentSection === 'captureVerification'">
    Verification
  </a>
  <a
    class="nav-item nav-link"
    *ngIf="capture.userAuditable || capture.userAuditOutcome"
    href="#"
    (click)="navClick($event, 'captureUserAudit')"
    [class.active]="currentSection === 'captureUserAudit'">
    Audit
  </a>
</nav>
