import { Component, Input } from '@angular/core';
import { ClientReferralTask } from '../../../core/models/client-referral-task.model';

@Component({
  selector: 'app-client-referral-task-details',
  templateUrl: './client-referral-task-details.component.html',
  styleUrls: ['./client-referral-task-details.component.scss'],
})
export class ClientReferralTaskDetailsComponent {
  @Input() task: ClientReferralTask;
}
