import { Component } from '@angular/core';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';

@Component({
  selector: 'app-capture-history-item-type-created',
  templateUrl: './capture-history-item-type-created.component.html',
})
export class CaptureHistoryItemTypeCreatedComponent extends CaptureHistoryItemTypeBaseComponent {
  get title() {
    return 'Capture Created';
  }

  get description() {
    return 'New capture created';
  }

  get matchConfidence() {
    return this.captureHistoryItem.data && this.captureHistoryItem.data.matchConfidence;
  }

  get claimType() {
    return this.captureHistoryItem.data && this.captureHistoryItem.data.claimType;
  }
}
