import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { ReferralMatchVerification } from '../models/referral-match-verification.model';
import { PatientPrescriber } from '../models/patient-prescriber.model';
import { Capture } from '../models/capture.model';
import { AbstractReferralMatchOption } from '../models/abstract-referral-match-option.model';
import { ReferralMatchVerificationEvent, ReferralMatchVerificationEventType } from '../models/referral-match-verification-event.model';
import { EMPTY } from 'rxjs'

const buildUrl = (ppId: number) => `${environment.captureApi.url}/patient_prescribers/${ppId}/referral_match_verifications`;

@Injectable()
export class ReferralMatchVerificationsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(ppId: number): Observable<ReferralMatchVerification[]> {
    const url = buildUrl(ppId);
    return this.http.get<ReferralMatchVerification[]>(url);
  }

  create(ppId: number, referralMatchVerification: ReferralMatchVerification): Observable<ReferralMatchVerification> {
    const url = buildUrl(ppId);

    const { referral } = referralMatchVerification;
    const updateAttributes = this.buildUpdateAttributes(referralMatchVerification);

    return this.http.post<ReferralMatchVerification>(url, {
      referralMatchVerification: Object.assign(
        { referralId: referral && referral.id },
        updateAttributes
      )
    });
  }

  update(ppId: number, referralMatchVerification: ReferralMatchVerification): Observable<ReferralMatchVerification> {
    const url = `${buildUrl(ppId)}/${referralMatchVerification.id}`;

    const updateAttributes = this.buildUpdateAttributes(referralMatchVerification);

    return this.http.patch<ReferralMatchVerification>(url, { referralMatchVerification: updateAttributes });
  }

  createOrUpdate(
    ppId: number,
    referralMatchVerification: ReferralMatchVerification
  ): Observable<ReferralMatchVerification> {
    if (referralMatchVerification.id) {
      return this.update(ppId, referralMatchVerification);
    } else {
      return this.create(ppId, referralMatchVerification);
    }
  }

  bulkUpdate(
    ppId: number,
    applicableToVerifiedCapture: Capture,
    referralMatchVerifications: ReferralMatchVerification[],
    clientReferralMatchVerifications: ReferralMatchVerification[],
    ehrReferralMatchVerifications: ReferralMatchVerification[],
    clientDateReferralMatchVerifications: ReferralMatchVerification[],
    notes: string
  ): Observable<PatientPrescriber> {
    const url = `${buildUrl(ppId)}/bulk_update`;
    return this.http.patch<PatientPrescriber>(url, {
      applicableToVerifiedCaptureId: applicableToVerifiedCapture.id,
      changes: {
        referrals: referralMatchVerifications,
        clients: clientReferralMatchVerifications,
        ehr: ehrReferralMatchVerifications,
        clientDates: clientDateReferralMatchVerifications,
        notes
      }
    });
  }

  destroy(ppId: number, referralMatchVerification: ReferralMatchVerification): Observable<ReferralMatchVerification> {
    const url = `${buildUrl(ppId)}/${referralMatchVerification.id}`;
    return this.http.delete<ReferralMatchVerification>(url, {});
  }

  buildUpdateAttributes(referralMatchVerification: ReferralMatchVerification): any {
    const {
      client,
      provider,
      office,
      specialty,
      clientReferralDate,
      peerClientReferralVerification,
      ehrReferralVerification,
      clientReferralVerification
    } = referralMatchVerification;

    return {
      client,
      provider,
      office,
      specialty,
      clientReferralDate,
      peerClientReferralVerification,
      ehrReferralVerificationId: ehrReferralVerification?.id,
      clientReferralVerificationId: clientReferralVerification?.id
    };
  }

  updateForOption(
    ppId: number,
    option: AbstractReferralMatchOption,
    verificationChange: EventEmitter<ReferralMatchVerificationEvent>
  ) {
    const initEvent = new ReferralMatchVerificationEvent(ReferralMatchVerificationEventType.changeInitiated);
    verificationChange.emit(initEvent);

    if (option.selected) {
      return this.createOrUpdate(ppId, option.verification).pipe(
        tap((rmv: ReferralMatchVerification) => {
          const changeEvent =
            new ReferralMatchVerificationEvent(ReferralMatchVerificationEventType.changed, rmv);

          verificationChange.emit(changeEvent);
        })
      );
    } else if (option.verification.id) {
      return this.destroy(ppId, option.verification).pipe(
        tap((rmv: ReferralMatchVerification) => {
          const removeEvent =
            new ReferralMatchVerificationEvent(ReferralMatchVerificationEventType.removed, rmv);

          verificationChange.emit(removeEvent);
        })
      );
    } else {
      return EMPTY;
    }
  }
}
