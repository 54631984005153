import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js/min';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(phoneValue: number | string): any {
    try {
      const phoneNumber = parsePhoneNumber(phoneValue + '');
      return phoneNumber.formatNational();
    } catch (error) {
      return phoneValue;
    }
  }
}
