export enum UserRoleName {
  manageCaptureAdmin = 'manage_capture_admin',
  manageUsers = 'manage_users',
  manageClients = 'manage_clients',
  accessReferralClaims = 'access_referral_claims',
  accessIccClaims = 'access_icc_claims',
  editVerifiedCaptures = 'edit_verified_captures',
  faxQueue = 'fax_queue',
  viewOcr = 'view_ocr',
  consultNoteUser = 'consult_note_user',
  manageClientChores = 'manage_client_chores',
  manageImplementation = 'manage_implementation',
}

export const availableRoles = [
  // NOTE: manageCaptureAdmin is not an assignable role for the front end.
  {
    name: UserRoleName.manageUsers,
    humanizedName: 'Manage Client Users',
  },
  {
    name: UserRoleName.manageClients,
    humanizedName: 'Manage Clients',
  },
  {
    name: UserRoleName.accessReferralClaims,
    humanizedName: 'Referral Claims',
  },
  {
    name: UserRoleName.accessIccClaims,
    humanizedName: 'Ineligible Claims Capture',
  },
  {
    name: UserRoleName.editVerifiedCaptures,
    humanizedName: 'Edit Verified Captures',
  },
  {
    name: UserRoleName.faxQueue,
    humanizedName: 'Fax Queue',
  },
  {
    name: UserRoleName.viewOcr,
    humanizedName: 'View OCR',
  },
  {
    name: UserRoleName.consultNoteUser,
    humanizedName: 'Consult Note User',
  },
  {
    name: UserRoleName.manageClientChores,
    humanizedName: 'Manage Client Chores',
  },
  {
    name: UserRoleName.manageImplementation,
    humanizedName: 'Manage Implementation',
  },
];
