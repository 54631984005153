import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-provider-captures-search-summary',
  templateUrl: './provider-captures-search-summary.component.html',
  styleUrls: ['./provider-captures-search-summary.component.scss'],
})
export class ProviderCapturesSearchSummaryComponent {
  @Input() count: number;
  @Output() resetFiltersClick = new EventEmitter<void>();

  onResetFiltersClick() {
    this.resetFiltersClick.emit();
  }
}
