import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { Capture } from '../../../core/models/capture.model';
import { CaptureValidationService, ValidationKeys } from '../../../core/services/capture-validation.service';

@Component({
  selector: 'app-associate-patient-document',
  templateUrl: './associate-patient-document.component.html',
  styleUrls: ['./associate-patient-document.component.scss'],
})
export class AssociatePatientDocumentComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() selectedAttachment: PatientAttachment;

  @Output()
  associatedAttachmentChange = new EventEmitter<{ isAssociated: boolean; patientAttachment: PatientAttachment }>();

  isAssociated = false;
  disabled = false;

  constructor(private captureValidationService: CaptureValidationService) {}

  ngOnChanges() {
    this.isAssociated = this.capture.verifyingPatientAttachmentId === this.selectedAttachment.id;
    this.disabled = this.capture.frozen || this.selectedAttachment.markedAsInvalid;
  }

  onPatientAttachmentAssociatedChanged($event) {
    $event.preventDefault();
    this.associatedAttachmentChange.emit({
      isAssociated: this.isAssociated,
      patientAttachment: this.selectedAttachment,
    });
  }

  get errorMessage() {
    return this.captureValidationService.getError(ValidationKeys.selectedPatientAttachment);
  }
}
