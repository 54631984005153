import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { isEmpty } from 'lodash-es';
import { PatientPrescriber } from "app/core/models/patient-prescriber.model";

@Component({
  selector: 'app-referral-match-verifications-notes',
  templateUrl: './referral-match-verifications-notes.component.html',
  styleUrls: ['./referral-match-verifications-notes.component.scss'],
})
export class ReferralMatchVerificationsNotesComponent implements OnInit {
  @Input() patientPrescriber: PatientPrescriber;
  @Input() notesFrozen = false;

  @Output() notesChanged = new EventEmitter<string>();

  notes = null;
  showNotes = false;

  ngOnInit() {
    this.notes = this.patientPrescriber.referralMatchVerificationNotes;
    this.showNotes = this.shouldShowNotes();
  }

  onPlusClick() {
    this.showNotes = true;
  }

  onMinusClick() {
    this.showNotes = false;
  }

  onNotesChange() {
    this.notesChanged.emit(this.notes);
  }

  private shouldShowNotes() {
    return !isEmpty(this.notes) || this.patientPrescriber.referralMatchVerifications.some(rmv => rmv.client);
  }
}
