<div [ngSwitch]="editorType">
  <ng-container *ngSwitchCase="editorTypes.dated">
    <span [ngClass]="{ deactivated: deactivated }">{{ definition.title }}</span>
    <div *ngIf="!deactivated">
      <span *ngIf="!configOption.id" class="small alert alert-warning p-1 m-0">
        This configuration will become active upon 'Save'
      </span>
      <span *ngIf="configOption.id" class="small alert alert-success p-1 m-0">
        Active since {{ configOption.startAt | date: 'MM/dd/yyyy' }}
      </span>
    </div>
    <div *ngIf="deactivated">
      <span  class="small alert alert-secondary p-1 m-0">
        Active from {{ configOption.startAt | date: 'MM/dd/yyyy'}} to {{ configOption.endAt | date: 'MM/dd/yyyy'}}
      </span>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="editorTypes.suboptions">
    {{ definition.title }}
    <div *ngIf="suboptionTypeDescriptions[definition.suboptionType]" class="small text-muted">
      by {{ suboptionTypeDescriptions[definition.suboptionType] }}
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ definition.title }}
  </ng-container>
</div>
