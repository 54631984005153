import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Upload } from 'app/core/models/upload.model';
import { Client } from 'app/core/models/client.model';
import { ClientsService } from 'app/core/services/clients.service';
import { ClaimImportsService } from 'app/core/services/claim-imports.service';
import { ImportField, TPAImportFields, TPAs } from '../../../core/options/claim-import-tpa-fields.opts';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-capture-admin-import-claims',
  templateUrl: './capture-admin-import-claims.component.html',
  styleUrls: ['./capture-admin-import-claims.component.scss'],
})
export class CaptureAdminImportClaimsComponent implements OnInit {
  uploading = false;
  formModel = { clientId: '', thirdPartyAdministerName: '', file: null };
  clients: Client[] = [];
  thirdPartyAdministerNames: string[] = [];
  importFields: ImportField[] = undefined;
  errors: string[] = [];

  constructor(
    private clientsService: ClientsService,
    private router: Router,
    private claimImportsService: ClaimImportsService
  ) {}

  ngOnInit() {
    this.loadClients();
    this.loadThirdPartyAdministers();
  }

  onUploadStart() {
    this.uploading = true;
    this.errors = [];
  }

  onUploadCancel() {
    this.uploading = false;
  }

  onUploadComplete(upload: Upload) {
    this.uploading = false;

    const clientId = parseInt(this.formModel.clientId, 10);

    this.claimImportsService.create(clientId,
                                    this.formModel.thirdPartyAdministerName,
                                    upload.signedId).subscribe(
      () => {
        this.router.navigate(['/capture-admin/tools/import-claims/history']);
      },
      (error: HttpErrorResponse) => {
        this.uploading = false;
        this.errors = error.error.errors;
      }
    );
  }

  onUploadError() {
    this.uploading = false;
  }

  onFormEnter($event) {
    $event.preventDefault();
  }

  get uploadButtonDisabled(): boolean {
    return !(
      this.formModel.clientId &&
      this.formModel.thirdPartyAdministerName &&
      this.formModel.file
    );
  }

  onFileSelected(file) {
    this.formModel.file = file;
  }

  onTpaSelected() {
    this.importFields = TPAImportFields[this.formModel.thirdPartyAdministerName]
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  private loadThirdPartyAdministers() {
    this.thirdPartyAdministerNames = TPAs;
  }

  public get hasError() {
    return !isEmpty(this.errors);
  }
}
