import { keyBy, orderBy } from 'lodash-es';
import { Component, Input } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PrescriberMatchVerificationsService } from '../../../core/services/prescriber-match-verifications.service';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PrescriberMatchVerification } from '../../../core/models/prescriber-match-verification.model';
import { isConsultNoteVerificationFrozen } from '../../../core/lib/verification-utils';
import { ListProvider } from '../../../core/models/list-provider.model';
import { ValidatePrescriberMatchOption } from '../validate-prescriber-match-verifications/validate-prescriber-match-verifications.component';
import { VerificationType } from '../../../core/enums/verification-type.enum';

const resolveVerification = (npi: string, verificationsByNpi: object) => {
  if (!(npi in verificationsByNpi)) {
    const verification = new PrescriberMatchVerification();
    verification.npi = npi;
    return verification;
  } else {
    return verificationsByNpi[npi];
  }
};

const sortProviders = (providers: ValidatePrescriberMatchOption[]) =>
  orderBy(providers, ['selected', p => p.name && p.name.toLowerCase()], ['desc', 'asc']);

@Component({
  selector: 'app-validate-prescriber-match-form',
  templateUrl: './validate-prescriber-match-form.component.html',
  styleUrls: ['./validate-prescriber-match-form.component.scss'],
})
export class ValidatePrescriberMatchFormComponent {
  _patientAttachment: PatientAttachment;
  _collapsed: boolean;

  providersWithPrescriptions: ValidatePrescriberMatchOption[] = [];
  providersWithoutPrescriptions: ValidatePrescriberMatchOption[] = [];
  allSelectedProviderOptions: ValidatePrescriberMatchOption[] = [];
  providersAtOtherOfficesWithPrescriptions = [];
  refreshing = false;
  hideNotes = false;
  notesFrozen = false;
  officeNames = [];

  constructor(
    private prescriberMatchVerificationService: PrescriberMatchVerificationsService,
    private patientAttachmentsService: PatientAttachmentsService,
    private authService: AuthenticationService
  ) {}

  get patientAttachment(): PatientAttachment {
    return this._patientAttachment;
  }

  @Input()
  set patientAttachment(pa: PatientAttachment) {
    this._patientAttachment = pa;
    this.initOfficeDetails();
    this.initPrescriberMatchVerifications();
  }

  get collapsed(): boolean {
    return this._collapsed;
  }

  @Input()
  set collapsed(val: boolean) {
    this._collapsed = val;
    this.hideNotes = this.collapsed && !this.patientAttachment.prescriberMatchVerificationNotes;
  }

  onRefreshClick() {
    this.refreshing = true;

    this.patientAttachmentsService.getRelevantProviders(this.patientAttachment.id).subscribe(result => {
      const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment, result);
      this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
      this.refreshing = false;
    });
  }

  onAdditionalTextChange(notes) {
    this.updatePrescriberMatchNotes(notes);
  }

  onPrescriberVerificationChange(prescriberMatchVerification: PrescriberMatchVerification) {
    this.savePrescriberMatchVerification(prescriberMatchVerification);
  }

  private savePrescriberMatchVerification(prescriberMatchVerification: PrescriberMatchVerification) {
    if (prescriberMatchVerification.id) {
      this.updatePrescriberMatchVerification(prescriberMatchVerification);
    } else {
      this.createPrescriberMatchVerification(prescriberMatchVerification);
    }
  }

  private createPrescriberMatchVerification(prescriberMatchVerification: PrescriberMatchVerification) {
    this.prescriberMatchVerificationService
      .create(this.patientAttachment.id, null, prescriberMatchVerification)
      .subscribe((pmv: PrescriberMatchVerification) => {
        this.handleSave(pmv);
      });
  }

  private updatePrescriberMatchVerification(prescriberMatchVerification: PrescriberMatchVerification) {
    this.prescriberMatchVerificationService
      .update(this.patientAttachment.id, prescriberMatchVerification.id, null, prescriberMatchVerification)
      .subscribe((pmv: PrescriberMatchVerification) => {
        this.handleSave(pmv);
      });
  }

  private updatePrescriberMatchNotes(notes) {
    const updateData: PatientAttachment = Object.assign({}, this.patientAttachment);
    updateData.prescriberMatchVerificationNotes = notes;

    this.patientAttachmentsService.update(updateData).subscribe((pa: PatientAttachment) => {
      const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);
      modifiedPatientAttachment.prescriberMatchVerificationNotes = pa.prescriberMatchVerificationNotes;
      this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
    });
  }

  private handleSave(prescriberMatchVerification: PrescriberMatchVerification) {
    const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);

    if (!modifiedPatientAttachment.prescriberMatchVerifications) {
      modifiedPatientAttachment.prescriberMatchVerifications = [];
    }

    const paIndex = modifiedPatientAttachment.prescriberMatchVerifications.findIndex(
      pmv => pmv.npi === prescriberMatchVerification.npi
    );

    // creates and updates
    if (paIndex > -1) {
      modifiedPatientAttachment.prescriberMatchVerifications[paIndex] = prescriberMatchVerification;
    } else {
      modifiedPatientAttachment.prescriberMatchVerifications.push(prescriberMatchVerification);
    }

    this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);

    this.updatePrescriberMatchOptions(prescriberMatchVerification, this.providersWithPrescriptions);
    this.updatePrescriberMatchOptions(prescriberMatchVerification, this.providersWithoutPrescriptions);
  }

  private initOfficeDetails() {
    const fpo = this.patientAttachment.faxedProviderOffice;

    if (fpo && fpo.office) {
      this.officeNames = fpo.office.officeNames;
    }
  }

  private initPrescriberMatchVerifications() {
    const prescriberMatchVerifications = this.patientAttachment.prescriberMatchVerifications || [];
    const keyedPrescriberVerifications = keyBy(prescriberMatchVerifications, 'npi');

    this.providersWithPrescriptions = sortProviders(
      this.initPrescriberMatchOptions(
        keyedPrescriberVerifications,
        this.patientAttachment.providersAtOfficeWithPrescriptions
      )
    );

    this.providersWithoutPrescriptions = sortProviders(
      this.initPrescriberMatchOptions(
        keyedPrescriberVerifications,
        this.patientAttachment.providersAtOfficeWithoutPrescriptions
      )
    );

    this.providersAtOtherOfficesWithPrescriptions = sortProviders(
      this.patientAttachment.providersAtOtherOfficesWithPrescriptions.map(p => ({
        npi: p.npi,
        name: p.displayName,
        specialty: p.displaySpeciality,
        selected: false,
      } as ValidatePrescriberMatchOption))
    );

    this.allSelectedProviderOptions = this.providersWithPrescriptions
      .concat(this.providersWithoutPrescriptions)
      .filter(opt => opt.selected);
  }

  private initPrescriberMatchOptions(keyedPrescriberVerifications, providers: ListProvider[]) {
    this.notesFrozen = isConsultNoteVerificationFrozen(
      this.authService,
      this.patientAttachment,
      VerificationType.prescriberMatch
    );

    return providers.map(p => {
      const resolvedVerification = resolveVerification(p.npi, keyedPrescriberVerifications);

      return new ValidatePrescriberMatchOption(
        p.npi,
        p.displayName,
        p.displaySpeciality,
        p.relevantProviderOffice,
        resolvedVerification,
        isConsultNoteVerificationFrozen(
          this.authService,
          this.patientAttachment,
          VerificationType.prescriberMatch,
          resolvedVerification
        ),
        !!resolvedVerification.matches
      );
    });
  }

  private updatePrescriberMatchOptions(
    prescriberMatchVerification: PrescriberMatchVerification,
    prescriberMatchOptions: ValidatePrescriberMatchOption[]
  ) {
    const optionIndex = prescriberMatchOptions.findIndex(pmo => pmo.npi === prescriberMatchVerification.npi);

    if (optionIndex > -1) {
      prescriberMatchOptions[optionIndex].verification = prescriberMatchVerification;
    }
  }
}
