import { Component, OnDestroy, OnInit } from '@angular/core';
import { forEach } from 'lodash-es';
import {
  CaptureQueueResultsContext,
  CaptureQueueService,
  CaptureQueueType,
  PagedCaptureQueueResults,
} from '../../../core/services/capture-queue.service';
import { CaptureQueueResult } from '../../../core/models/capture-lists/capture-queue-result.model';
import { Cursor } from '../../../core/models/paged-results/cursor-based-paging.model';
import { CaptureQueueCursorType } from '../../../core/enums/capture-queue-cursor-type.enum';
import { UserPresenceService } from 'app/core/services/user-presence.service';

// TODO: remove this when we feel confident about the infinite scrolling + dupes
const printDuplicates = (results: CaptureQueueResult[]) => {
  window.setTimeout(() => {
    const lookup = results.reduce((captureIds, record) => {
      captureIds[record.id] = ++captureIds[record.id] || 0;
      return captureIds;
    }, {});

    const dupes = results.filter(e => lookup[e.id] > 0);

    if (dupes.length > 0) {
      console.log('duplicates: \n', dupes);
    }
  });
};

@Component({
  selector: 'app-capture-admin-capture-search',
  templateUrl: './capture-admin-capture-search.component.html',
  styleUrls: ['./capture-admin-capture-search.component.scss'],
})
export class CaptureAdminCaptureSearchComponent implements OnInit, OnDestroy {
  loadingInitialResults = false;
  loadingMoreResults = false;
  hasAnyResults = false;
  hasMoreResults = true;
  hasPerformedSearch = false;
  matchingCapturesCount = null;
  limit = 50;

  captureQueueResults: CaptureQueueResult[] = [];
  cursor: Cursor<CaptureQueueCursorType>;

  constructor(
    private captureQueueService: CaptureQueueService,
    private userPresenceService: UserPresenceService
  ) {}

  ngOnInit() {
    this.captureQueueService.queueType = CaptureQueueType.search;
    const context: CaptureQueueResultsContext = this.captureQueueService.restoreResultsContext(CaptureQueueType.search);
    if (context) {
      this.cursor = context.cursor;
      this.hasMoreResults = !!this.cursor;
      this.captureQueueResults = context.captureQueueResults;
      this.hasAnyResults = this.captureQueueResults && this.captureQueueResults.length > 0;
      this.hasPerformedSearch = true;
      this.matchingCapturesCount = context.count;
      this.subscribeToUserPresence(this.captureQueueResults);
      window.setTimeout(() => {
        window.scrollTo(0, context.scrollPosition);
      });
    }
  }

  ngOnDestroy() {
    this.captureQueueService.cacheResultsContext(
      CaptureQueueType.search,
      new CaptureQueueResultsContext(this.captureQueueResults, this.cursor, this.matchingCapturesCount, window.scrollY)
    );
    this.userPresenceService.unsubscribeAll();
  }

  onPerformSearch() {
    this.hasPerformedSearch = true;
    this.captureQueueResults = [];
    this.cursor = null;
    this.matchingCapturesCount = null;
    this.loadingInitialResults = true;
    this.hasAnyResults = false;
    this.loadSearchResults(true);
    this.updateCount();
  }

  onPerformReset() {
    this.hasPerformedSearch = false;
    this.captureQueueResults = [];
    this.cursor = null;
  }

  onScrolled() {
    if (this.hasMoreResults) {
      this.loadingMoreResults = true;
      this.loadSearchResults(false);
      printDuplicates(this.captureQueueResults);
    }
  }

  private loadSearchResults(isInitialQuery) {
    this.captureQueueService.getResults(this.cursor, this.limit).subscribe((results: PagedCaptureQueueResults) => {
      const hasResults = results.captureQueueResults && results.captureQueueResults.length > 0;

      if (isInitialQuery && hasResults) {
        this.hasAnyResults = true;
      }

      if (hasResults) {
        this.captureQueueResults = this.captureQueueResults.concat(results.captureQueueResults);
      }

      this.cursor = results.meta.cursor;
      this.hasMoreResults = !!results.meta.cursor;
      this.subscribeToUserPresence(results.captureQueueResults);
      this.loadingInitialResults = false;
      this.loadingMoreResults = false;
    });
  }

  private updateCount() {
    this.captureQueueService.getCount().subscribe(({ count }) => {
      this.matchingCapturesCount = count;
    });
  }

  private subscribeToUserPresence(captures: CaptureQueueResult[]) {
    forEach(captures, (capture: CaptureQueueResult) => {
      this.userPresenceService.subscribe('capture', capture.id)
    });
  }

}
