import { keyBy } from 'lodash-es';
import { PatientInteractionLocationEligibilityStatus } from '../enums/patient-interaction-location-eligibility-status.enum';

export const PatientInteractionLocationEligibilityStatusOptions = [
  { value: PatientInteractionLocationEligibilityStatus.new, display: 'New' },
  { value: PatientInteractionLocationEligibilityStatus.eligible, display: 'Eligible' },
  { value: PatientInteractionLocationEligibilityStatus.ineligible, display: 'Ineligible' },
];

export const keyedPatientInteractionLocationEligibilityStatusOptions =
  keyBy(PatientInteractionLocationEligibilityStatusOptions, 'value');
