import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-patient-captures-search-summary',
  templateUrl: './patient-captures-search-summary.component.html',
  styleUrls: ['./patient-captures-search-summary.component.scss'],
})
export class PatientCapturesSearchSummaryComponent {
  @Input() count: number;
  @Output() resetFiltersClick = new EventEmitter<void>();

  onResetFiltersClick() {
    this.resetFiltersClick.emit();
  }
}
