<div class="user-stats border rounded bg-light">
  <div class="container">
    <div class="d-flex flex-row">
      <div class="flex-fill title h6 align-middle">
        My Daily Stats
      </div>

      <div class="flex-fill stat faxes border rounded">
        Faxes
        <div *ngIf="faxStat; else: spinner" class="count h3">
          {{ faxStat.count }}
        </div>
      </div>

      <div class="flex-fill stat geb border rounded">
        GEB
        <div *ngIf="gatherEvidenceBlockedStat; else: spinner" class="count h3">
          {{ gatherEvidenceBlockedStat.count }}
        </div>
      </div>

      <div class="flex-fill stat consult-notes border rounded">
        Consult Notes
        <div *ngIf="consultNoteStat; else: spinner" class="count h3">
          {{ consultNoteStat.count }}
        </div>
      </div>

      <div class="flex-fill stat verified  border rounded">
        Verified
        <div *ngIf="verifiedCaptureStat; else: spinner" class="count h3">
          {{ verifiedCaptureStat.count }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="loading">
    <fa-icon [icon]="['fas', 'circle-notch']" size="lg" [spin]="true" />
  </div>
</ng-template>
