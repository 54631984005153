import { ReferralMatchVerification } from './referral-match-verification.model';
import { EhrReferralVerification } from './ehr-referral-verification.model';
import { AbstractReferralMatchOption } from './abstract-referral-match-option.model';

export class EhrReferralMatchOption extends AbstractReferralMatchOption {
  constructor(
    public verification: ReferralMatchVerification,
    public ehrReferralVerification: EhrReferralVerification,
    public selected: boolean,
    public disabled: boolean
  ) {
    super(verification, selected, disabled);
  }
}

