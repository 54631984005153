import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ClientReferralVerification } from '../../../core/models/client-referral-verification.model';
import { DateUtils } from 'app/core/lib/date-utils';

@Component({
  selector: 'app-client-referral-task-date',
  templateUrl: './client-referral-task-date.component.html',
  styleUrls: ['./client-referral-task-date.component.scss'],
})
export class ClientReferralTaskDateComponent implements OnInit, OnChanges {
  referralDate = null;
  disabled = false;

  @Input() position = -1;
  @Input() verification: ClientReferralVerification;
  @Input() formDisabled: boolean;
  @Output() removeVerification = new EventEmitter<number>();
  @Output() updateVerification = new EventEmitter<{ id: number; referralDate: string }>();

  constructor() {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  onDateClearClick() {
    this.referralDate = null;
    const obj = { id: this.verification.id, referralDate: null };
    this.updateVerification.emit(obj);
  }

  onReferralDateBlur() {
    if (!this.isValidEncounterDate()) {
      this.referralDate = null;
    }

    this.updateVerification.emit({ id: this.verification.id, referralDate: this.referralDate });
  }

  private isValidEncounterDate() {
    return DateUtils.isValid(this.referralDate);
  }

  onRemoveClick($event, verification: ClientReferralVerification) {
    $event.preventDefault();
    this.removeVerification.emit(verification.id);
  }

  private init() {
    this.referralDate = this.verification.referralDate;
    this.disabled = this.formDisabled || this.verification.frozen;
  }
}
