import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LimitOffsetPaging } from '../../../core/models/paged-results/limit-offset-paging.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() paging: LimitOffsetPaging;
  @Input() updateUrl = false;

  @Output() public pageChange = new EventEmitter<number>();

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {}

  onPageChange(page) {
    this.updatePageParam(page);
    this.pageChange.emit(page);
  }

  private updatePageParam(page) {
    if (this.updateUrl) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { page },
        queryParamsHandling: 'merge',
      });
    }
  }
}
