import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { ClientPortalComponent } from './client-portal.component';
import { ClientPortalSearchComponent } from './pages/client-portal-search/client-portal-search.component';
import { ClientPortalMatchReportsComponent } from './pages/client-portal-match-reports/client-portal-match-reports.component';
import { ClientPortalConsultNotesComponent } from './pages/client-portal-consult-notes/client-portal-consult-notes.component';
import { ClientPortalPreferencesComponent } from './pages/client-portal-preferences/client-portal-preferences.component';
import { ClientPortalClientPrescriberTasksComponent } from './pages/client-portal-client-prescriber-tasks/client-portal-client-prescriber-tasks.component';
import { ClientPortalPrescriptionReportComponent } from './pages/client-portal-prescription-report/client-portal-prescription-report.component';
import { ClientPortalCaptureInvoiceLineItemReportComponent } from './pages/client-portal-capture-invoice-line-item-report/client-portal-capture-invoice-line-item-report.component';
import { ClientPortalClientConsultNoteTasksComponent } from './pages/client-portal-client-consult-note-tasks/client-portal-client-consult-note-tasks.component';
import { ConsultNoteTaskListRowComponent } from './components/consult-note-task-list-row/consult-note-task-list-row.component';
import { ClientPrescriberTaskListRowComponent } from './components/client-prescriber-task-list-row/client-prescriber-task-list-row.component';
import { ClientPortalDirectConsultNotesComponent } from './pages/client-portal-direct-consult-notes/client-portal-direct-consult-notes.component';
import { ClientPortalClientReferralTasksComponent } from './pages/client-portal-client-referral-tasks/client-portal-client-referral-tasks.component';
import { ClientReferralTaskListRowComponent } from './components/client-referral-task-list-row/client-referral-task-list-row.component';
import { AcceptEulaModalComponent } from './components/accept-eula-modal/accept-eula-modal.component';
import { CapturesModule } from '../captures/captures.module';
import { ClientTasksModule } from '../client-tasks/client-tasks.module';
import { ClientPortalConsultNoteViewerComponent } from './pages/client-portal-consult-note-viewer/client-portal-consult-note-viewer.component';
import { ClientPortalClientPrescriberTaskReportsComponent } from './pages/client-portal-client-prescriber-task-reports/client-portal-client-prescriber-task-reports.component';
import { ClientPortalClientReferralTaskReportsComponent } from './pages/client-portal-client-referral-task-reports/client-portal-client-referral-task-reports.component';
import { ClientPortalClientConsultNoteTaskReportsComponent } from './pages/client-portal-client-consult-note-task-reports/client-portal-client-consult-note-task-reports.component';
import { ClientPortalClientDeactivatedTaskComponent } from './pages/client-portal-client-deactivated-task/client-portal-client-deactivated-task.component';
import { ClientPortalPatientInteractionLocationsComponent} from './pages/client-portal-patient-interaction-locations/client-portal-patient-interaction-locations.component';
import { PatientInteractionLocationsNewLocationsSectionComponent } from './components/patient-interaction-locations-new-locations-section/patient-interaction-locations-new-locations-section.component';
import { PatientInteractionLocationsCurrentLocationsSectionComponent } from './components/patient-interaction-locations-current-locations-section/patient-interaction-locations-current-locations-section.component';
import { ClientPortalProgramOverviewComponent } from './pages/client-portal-program-overview/client-portal-program-overview.component';

@NgModule({
  imports: [SharedModule, CapturesModule, ClientTasksModule],
  declarations: [
    ClientPortalComponent,
    ClientPortalSearchComponent,
    ClientPortalMatchReportsComponent,
    ClientPortalConsultNotesComponent,
    ClientPortalPreferencesComponent,
    ClientPortalClientPrescriberTasksComponent,
    ClientPortalPrescriptionReportComponent,
    ClientPortalCaptureInvoiceLineItemReportComponent,
    ClientPortalClientConsultNoteTasksComponent,
    ConsultNoteTaskListRowComponent,
    ClientPrescriberTaskListRowComponent,
    ClientPortalDirectConsultNotesComponent,
    ClientPortalClientReferralTasksComponent,
    ClientReferralTaskListRowComponent,
    AcceptEulaModalComponent,
    ClientPortalConsultNoteViewerComponent,
    ClientPortalClientPrescriberTaskReportsComponent,
    ClientPortalClientReferralTaskReportsComponent,
    ClientPortalClientConsultNoteTaskReportsComponent,
    ClientPortalClientDeactivatedTaskComponent,
    ClientPortalPatientInteractionLocationsComponent,
    PatientInteractionLocationsNewLocationsSectionComponent,
    PatientInteractionLocationsCurrentLocationsSectionComponent,
    ClientPortalProgramOverviewComponent,
  ],
  providers: [],
})
export class ClientPortalModule {}
