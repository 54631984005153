import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-reference-number',
  templateUrl: './candidate-reference-number.component.html',
  styleUrls: ['./candidate-reference-number.component.scss'],
})
export class CandidateReferenceNumberComponent implements OnInit {
  @Input() candidate: { referenceNumber: string; fillNumber: string };

  constructor() {}

  ngOnInit() {}
}
