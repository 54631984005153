import { of as observableOf, Observable, Observer } from 'rxjs';

import { Injectable } from '@angular/core';
import { isNull } from 'lodash-es';
import { NextQueueItem } from '../models/next-queue-item.model';
import { UserQueueSettings } from '../interfaces/user-queue-settings';
import { UserSettingsService } from './user-settings.service';
import { UserSettings } from '../enums/user-settings.enum';
import { NextQueueItemProvider } from '../interfaces/next-queue-item-provider';

class QueuePosition {
  constructor(public position: number, public currentPage: number, public pageSize: number, public filters: object) {}
}

@Injectable()
export class QueueProcessingService {
  constructor(private userSettingsService: UserSettingsService) {}

  skipToNextItem<T>(
    userSettingsKey: UserSettings,
    nextQueueItemProvider: NextQueueItemProvider,
    currentTask: any = null
  ) {
    const queueSettings: UserQueueSettings = this.userSettingsService.get(userSettingsKey);

    if (queueSettings) {
      const paging = queueSettings.paging;
      let realPageSize = 0;

      if (paging.currentPage === paging.totalPages) {
        if (paging.totalPages === 1) {
          realPageSize = paging.count;
        } else {
          realPageSize = paging.count - (paging.totalPages - 1) * paging.pageSize;
        }
      } else {
        realPageSize = paging.pageSize;
      }

      const maxPosition = realPageSize - 1;
      let nextPosition = queueSettings.position + 1;

      if (nextPosition > maxPosition) {
        nextPosition = 0;
        const nextPage = paging.currentPage + 1;
        paging.currentPage = nextPage > paging.totalPages ? 1 : nextPage;
      }

      queueSettings.paging = paging;
      queueSettings.position = nextPosition;

      this.userSettingsService.save(userSettingsKey, queueSettings);
    }

    return this.getNextItem<T>(userSettingsKey, nextQueueItemProvider, currentTask);
  }

  getNextItem<T>(
    userSettingsKey: UserSettings,
    nextQueueItemProvider: NextQueueItemProvider,
    currentTask: any = null
  ): Observable<NextQueueItem<T>> {
    const queuePosition = this.getQueuePosition(userSettingsKey);

    if (queuePosition) {
      return this.getNextInQueue<T>(nextQueueItemProvider, queuePosition, currentTask);
    } else {
      return observableOf(new NextQueueItem<T>(null, false));
    }
  }

  private getQueuePosition(settingsKey: UserSettings): QueuePosition {
    const queueSettings: UserQueueSettings = this.userSettingsService.get(settingsKey);

    if (queueSettings && !isNull(queueSettings.position)) {
      const {
        position,
        paging: { currentPage, pageSize },
        filters,
      } = queueSettings;
      return new QueuePosition(position, currentPage, pageSize, filters);
    } else {
      return null;
    }
  }

  private getNextInQueue<T>(
    nextQueueItemProvider: NextQueueItemProvider,
    queuePosition: QueuePosition,
    currentTask: any = null
  ): Observable<NextQueueItem<T>> {
    return new Observable(nextItemObserver => {
      const { position, currentPage, pageSize, filters } = queuePosition;

      nextQueueItemProvider.nextItemInQueueRequest(currentPage, pageSize, filters).subscribe(({ records }) => {
        const previousPosition = position - 1;
        let truePosition = position;

        // if we have a currentTask, see if it's still in the
        // list of records at the previous position.  If it's
        // not then it's likely it was removed from the filter
        // so make the true position be the previous position.
        //
        // this prevents tasks from being skipped if the previous
        // task was updated that removed it from the current filter query.
        //
        // this fixes: https://www.pivotaltracker.com/story/show/181671479/comments/230815147
        if (previousPosition >= 0) {
          if (currentTask && records[previousPosition]) {
            if (currentTask.id !== records[previousPosition].id) {
              truePosition = previousPosition;
            }
          }
        }

        if (records.length > truePosition) {
          nextItemObserver.next(new NextQueueItem<T>(records[truePosition]));
          nextItemObserver.complete();
        } else {
          this.getNextFromStart<T>(nextQueueItemProvider, nextItemObserver, filters);
        }
      });
    });
  }

  private getNextFromStart<T>(
    nextQueueItemProvider: NextQueueItemProvider,
    nextItemObserver: Observer<NextQueueItem<T>>,
    filters: object
  ) {
    nextQueueItemProvider.nextItemFromStartRequest(nextItemObserver).subscribe(({ records }) => {
      if (records.length > 0) {
        nextItemObserver.next(new NextQueueItem<T>(records[0]));
        nextItemObserver.complete();
      } else {
        nextItemObserver.next(new NextQueueItem<T>(null));
        nextItemObserver.complete();
      }
    });
  }
}
