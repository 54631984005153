import { Component, OnInit } from '@angular/core';
import { User } from '../../../core/models/user.model';
import { UsersService } from '../../../core/services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserFormMode } from '../../components/user-form/user-form.component';
import { HttpErrorResponse } from '@angular/common/http';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';
import { UserType } from 'app/core/enums/user-type.enum';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-capture-admin-user-edit',
  templateUrl: './capture-admin-user-edit.component.html',
  styleUrls: ['./capture-admin-user-edit.component.scss'],
})
export class CaptureAdminUserEditComponent implements OnInit {
  user: User;
  loading = false;
  formModes = UserFormMode;

  constructor(
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.init();
  }

  private init() {
    const userId = this.route.snapshot.paramMap.get('id');
    this.loadUser(userId);
  }

  private loadUser(userId): void {
    this.loading = true;
    this.usersService.get(userId).subscribe(
      user => {
        this.user = user;
        if (this.canManage) {
          this.loading = false;
        } else {
          this.router.navigate(['/404'], { replaceUrl: true });
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404'], { replaceUrl: true });
        } else {
          console.error(err);
        }
      }
    );
  }

  private get isAdminUser() {
    return this.user.userType === UserType.captureAdmin || this.user.userType === UserType.llmPlaygroundUser;
  }

  private get canManage() {
    return (this.isAdminUser && this.authService.hasPermissionTo(UserRoleName.manageCaptureAdmin)) ||
      !this.isAdminUser && this.authService.hasPermissionTo(UserRoleName.manageUsers);
  }
}
