import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { Capture } from '../../../core/models/capture.model';
import { CaptureValidationService, ValidationKeys } from '../../../core/services/capture-validation.service';

@Component({
  selector: 'app-mark-patient-document-as-invalid',
  templateUrl: './mark-patient-document-as-invalid.component.html',
  styleUrls: ['./mark-patient-document-as-invalid.component.scss'],
})
export class MarkPatientDocumentAsInvalidComponent implements OnInit, OnChanges {
  @Input() selectedAttachment: PatientAttachment;
  @Input() capture: Capture;
  @Output() markedAsInvalidChanged = new EventEmitter<PatientAttachment>();

  formModel = null;

  constructor(private captureValidationService: CaptureValidationService) {}

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges() {
    this.initialize();
  }

  private initialize() {
    this.formModel = {
      patientAttachment: this.selectedAttachment,
    };
  }

  public get errorMessage() {
    return this.captureValidationService.getError(ValidationKeys.selectedPatientAttachment);
  }

  public onMarkedAsInvalidChanged($event) {
    $event.preventDefault();
    this.markedAsInvalidChanged.emit(this.selectedAttachment);
  }

  public get captureFrozen(): boolean {
    return this.capture.frozen;
  }

  public get canMarkAsInvalid(): boolean {
    return !this.captureFrozen && !this.selectedAttachment.hasVerifiedCapture;
  }
}
