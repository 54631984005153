import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LimitOffsetPaging } from '../../../core/models/paged-results/limit-offset-paging.model';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';

@Component({
  selector: 'app-client-consult-note-task-list',
  templateUrl: './client-consult-note-task-list.component.html',
  styleUrls: ['./client-consult-note-task-list.component.scss'],
})
export class ClientConsultNoteTaskListComponent {
  @Input() tasks: ClientConsultNoteTask[];
  @Input() paging: LimitOffsetPaging;

  @Output() public pageChange = new EventEmitter<number>();
  @Output() public pageSizeChange = new EventEmitter<number>();

  constructor() {}

  onPageChange(page) {
    this.pageChange.emit(page);
  }

  onPageSizeChange(size) {
    this.pageSizeChange.emit(size);
  }
}
