import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultNoteComponent } from './pages/consult-note/consult-note.component';
import { ConsultNotesComponent } from './pages/consult-notes/consult-notes.component';
import { ConsultNoteHeaderComponent } from './components/consult-note-header/consult-note-header.component';
import { SharedModule } from '../shared/shared.module';
import { ConsultNoteTitleComponent } from './components/consult-note-title/consult-note-title.component';
import { ConsultNoteDocumentComponent } from './components/consult-note-document/consult-note-document.component';
import { ValidatePatientMatchComponent } from './components/validate-patient-match/validate-patient-match.component';
import { ValidatePatientMatchFormComponent } from './components/validate-patient-match-form/validate-patient-match-form.component';
import { ValidatePatientMatchDetailsComponent } from './components/validate-patient-match-details/validate-patient-match-details.component';
import { AdditionalDetailsInputComponent } from './components/additional-details-input/additional-details-input.component';
import { ValidateSpecialistEncounterComponent } from './components/validate-specialist-encounter/validate-specialist-encounter.component';
import { ValidateSpecialistEncounterFormComponent } from './components/validate-specialist-encounter-form/validate-specialist-encounter-form.component';
import { ValidateSpecialistEncounterVerificationComponent } from './components/validate-specialist-encounter-verification/validate-specialist-encounter-verification.component';
import { ValidateSpecialistEncounterVerificationsComponent } from './components/validate-specialist-encounter-verifications/validate-specialist-encounter-verifications.component';
import { ValidateSpecialistEncounterRelevantWrittenDatesComponent } from './components/validate-specialist-encounter-relevant-written-dates/validate-specialist-encounter-relevant-written-dates.component';
import { ValidatePrescriberMatchComponent } from './components/validate-prescriber-match/validate-prescriber-match.component';
import { ValidatePrescriberMatchFormComponent } from './components/validate-prescriber-match-form/validate-prescriber-match-form.component';
import { ProviderComponent } from './components/provider/provider.component';
import { ValidatePrescriberMatchVerificationsComponent } from './components/validate-prescriber-match-verifications/validate-prescriber-match-verifications.component';
import { ValidateDrugMatchComponent } from './components/validate-drug-match/validate-drug-match.component';
import { ValidateDrugMatchDetailsComponent } from './components/validate-drug-match-details/validate-drug-match-details.component';
import { ValidateDrugMatchFormComponent } from './components/validate-drug-match-form/validate-drug-match-form.component';
import { ValidationHeaderComponent } from './components/validation-header/validation-header.component';
import { ValidateDrugMatchVerificationComponent } from './components/validate-drug-match-verification/validate-drug-match-verification.component';
import { ValidateDrugMatchHeadingComponent } from './components/validate-drug-match-heading/validate-drug-match-heading.component';
import { OcrResultTextComponent } from './components/ocr-result-text/ocr-result-text.component';
import { DrugAkasComponent } from './components/drug-akas/drug-akas.component';
import { ValidateReferralMatchVerificationsComponent } from './components/validate-referral-match-verifications/validate-referral-match-verifications.component';
import { EditReferralMatchVerificationsModalComponent } from './components/edit-referral-match-verifications-modal/edit-referral-match-verifications-modal.component';
import { EditReferralMatchVerificationsStandardComponent } from './components/edit-referral-match-verifications-standard/edit-referral-match-verifications-standard.component';
import { EditReferralMatchVerificationsClientComponent } from './components/edit-referral-match-verifications-client/edit-referral-match-verifications-client.component';
import { EditReferralMatchVerificationsEhrComponent } from './components/edit-referral-match-verifications-ehr/edit-referral-match-verifications-ehr.component';
import { EditReferralMatchVerificationsClientDatesComponent } from './components/edit-referral-match-verifications-client-dates/edit-referral-match-verifications-client-dates.component';
import { ValidateReferralMatchVerificationsPrescriberComponent } from './components/validate-referral-match-verifications-prescriber/validate-referral-match-verifications-prescriber.component';
import { ValidateReferralMatchVerificationsPrescriberListComponent } from './components/validate-referral-match-verifications-prescriber-list/validate-referral-match-verifications-prescriber-list.component';
import { ValidateReferralMatchVerificationsClientListComponent } from './components/validate-referral-match-verifications-client-list/validate-referral-match-verifications-client-list.component';
import { ValidateReferralMatchVerificationsEhrListComponent } from './components/validate-referral-match-verifications-ehr-list/validate-referral-match-verifications-ehr-list.component';
import { ConsultNoteQueueSettingsComponent } from './components/consult-note-queue-settings/consult-note-queue-settings.component';
import { ClientReferralTaskResponseComponent } from './components/client-referral-task-response/client-referral-task-response.component';
import { ConsultNoteNavComponent } from './components/consult-note-nav/consult-note-nav.component';
import { ConsultNoteValidatorComponent } from './components/consult-note-validator/consult-note-validator.component';
import { ValidatePatientPrescriptionsComponent } from './components/validate-patient-prescriptions/validate-patient-prescriptions.component';
import { ConsultNotePrescriptionsComponent } from './pages/consult-note-prescriptions/consult-note-prescriptions.component';
import { ConsultNoteHistoryComponent } from './pages/consult-note-history/consult-note-history.component';
import { ValidatePatientEncountersComponent } from './components/validate-patient-encounters/validate-patient-encounters.component';
import { ConsultNoteOthersComponent } from './pages/consult-note-others/consult-note-others.component';
import { OtherConsultNotesSelectorComponent } from './components/other-consult-notes-selector/other-consult-notes-selector.component';
import { ConsultNoteHistoryItemTitleComponent } from './components/consult-note-history-item-title/consult-note-history-item-title.component';
import { ConsultNoteHistoryItemBodyComponent } from './components/consult-note-history-item-body/consult-note-history-item-body.component';
import { ConsultNoteHistoryItemTypeBaseComponent } from './components/consult-note-history-item-type-base/consult-note-history-item-type-base.component';
import { ConsultNoteHistoryItemTypeCreatedComponent } from './components/consult-note-history-item-type-created/consult-note-history-item-type-created.component';
import { ConsultNoteHistoryItemTypeSubmittedComponent } from './components/consult-note-history-item-type-submitted/consult-note-history-item-type-submitted.component';
import { ConsultNoteHistoryItemTypeTransitionedComponent } from './components/consult-note-history-item-type-transitioned/consult-note-history-item-type-transitioned.component';
import { ConsultNoteAssignedToComponent } from './components/consult-note-assigned-to/consult-note-assigned-to.component';
import { ConsultNoteOcrBackfillComponent } from './components/consult-note-ocr-backfill/consult-note-ocr-backfill.component';
import { ConsultNoteHistoryItemTypeAssignmentNewComponent } from './components/consult-note-history-item-type-assignment-new/consult-note-history-item-type-assignment-new.component';
import { ConsultNoteHistoryItemTypeAssignmentUpdatedComponent } from './components/consult-note-history-item-type-assignment-updated/consult-note-history-item-type-assignment-updated.component';
import { ConsultNoteHistoryItemTypeAssignmentRemovedComponent } from './components/consult-note-history-item-type-assignment-removed/consult-note-history-item-type-assignment-removed.component';
import { ConsultNoteSummaryComponent } from './components/consult-note-summary/consult-note-summary.component';

@NgModule({
  declarations: [
    ConsultNoteComponent,
    ConsultNotePrescriptionsComponent,
    ConsultNoteHistoryComponent,
    ConsultNoteOthersComponent,
    ConsultNotesComponent,
    ConsultNoteHeaderComponent,
    ConsultNoteTitleComponent,
    ConsultNoteDocumentComponent,
    ValidatePatientMatchComponent,
    ValidatePatientMatchFormComponent,
    ValidatePatientMatchDetailsComponent,
    AdditionalDetailsInputComponent,
    ValidateSpecialistEncounterComponent,
    ValidateSpecialistEncounterFormComponent,
    ValidateSpecialistEncounterVerificationComponent,
    ValidateSpecialistEncounterVerificationsComponent,
    ValidateSpecialistEncounterRelevantWrittenDatesComponent,
    ValidatePrescriberMatchComponent,
    ValidatePrescriberMatchFormComponent,
    ProviderComponent,
    ValidatePrescriberMatchVerificationsComponent,
    ValidateDrugMatchComponent,
    ValidateDrugMatchDetailsComponent,
    ValidateDrugMatchFormComponent,
    ValidationHeaderComponent,
    ValidateDrugMatchVerificationComponent,
    ValidateDrugMatchHeadingComponent,
    OcrResultTextComponent,
    DrugAkasComponent,
    ValidateReferralMatchVerificationsComponent,
    EditReferralMatchVerificationsModalComponent,
    EditReferralMatchVerificationsStandardComponent,
    EditReferralMatchVerificationsClientComponent,
    EditReferralMatchVerificationsEhrComponent,
    EditReferralMatchVerificationsClientDatesComponent,
    ValidateReferralMatchVerificationsPrescriberComponent,
    ValidateReferralMatchVerificationsPrescriberListComponent,
    ValidateReferralMatchVerificationsClientListComponent,
    ValidateReferralMatchVerificationsEhrListComponent,
    ConsultNoteQueueSettingsComponent,
    ClientReferralTaskResponseComponent,
    ConsultNoteNavComponent,
    ConsultNoteValidatorComponent,
    ValidatePatientPrescriptionsComponent,
    ValidatePatientEncountersComponent,
    OtherConsultNotesSelectorComponent,
    ConsultNoteHistoryItemTitleComponent,
    ConsultNoteHistoryItemBodyComponent,
    ConsultNoteHistoryItemTypeBaseComponent,
    ConsultNoteHistoryItemTypeCreatedComponent,
    ConsultNoteHistoryItemTypeSubmittedComponent,
    ConsultNoteHistoryItemTypeTransitionedComponent,
    ConsultNoteAssignedToComponent,
    ConsultNoteOcrBackfillComponent,
    ConsultNoteHistoryItemTypeAssignmentNewComponent,
    ConsultNoteHistoryItemTypeAssignmentUpdatedComponent,
    ConsultNoteHistoryItemTypeAssignmentRemovedComponent,
    ConsultNoteSummaryComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class ConsultNotesModule { }
