import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportAbstractOptionComponent } from 'app/shared/components/report-abstract-option/report-abstract-option.component';
import { ReportOption } from 'app/core/models/report-option.model';

export class ReportMonthRangeOption implements ReportOption {
  fromYear: number;
  fromMonth: number;
  toYear: number;
  toMonth: number;

  constructor(values) {
    if (values) {
      this.fromYear = values.fromYear;
      this.fromMonth = values.fromMonth;
      this.toYear = values.toYear;
      this.toMonth = values.toMonth;
    }
  }

  get values() {
    return {
      fromYear: this.fromYear,
      fromMonth: this.fromMonth,
      toYear: this.toYear,
      toMonth: this.toMonth,
    };
  }

  format(year, month) {
    if (year && month) {
      const paddedMonth = `0${month}`.slice(-2);
      return `${year}-${paddedMonth}`;
    }
  }

  toPostData(options = { paramName: 'createdAt' }) {
    const postData = {};

    if (this.fromYear && this.fromMonth) {
      postData[`${options.paramName}From`] = this.format(this.fromYear, this.fromMonth);
    }

    if (this.toYear && this.toMonth) {
      postData[`${options.paramName}To`] = this.format(this.toYear, this.toMonth);
    }

    return postData;
  }
}

@Component({
  selector: 'app-report-month-range-option',
  templateUrl: './report-month-range-option.component.html',
  styleUrls: ['./report-month-range-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReportMonthRangeOptionComponent),
    },
  ],
})
export class ReportMonthRangeOptionComponent extends ReportAbstractOptionComponent {
  static readonly blankValues = {
    fromYear: null,
    fromMonth: null,
    toYear: null,
    toMonth: null,
  };

  @Input() label = 'Months';

  @Input() minYear: number;
  @Input() minMonth: number;
  @Input() maxYear: number;
  @Input() maxMonth: number;

  values = { ...ReportMonthRangeOptionComponent.blankValues };

  onValueChange(property, value) {
    this.values[property] = value;

    this.onChange();
  }

  protected updateInternalState(model: ReportMonthRangeOption) {
    if (model) {
      this.values = model.values;
    } else {
      this.values = { ...ReportMonthRangeOptionComponent.blankValues };
    }
  }

  protected determineModelStateOnChange(): ReportMonthRangeOption {
    if ((this.values.fromYear && this.values.fromMonth) || (this.values.toYear && this.values.toMonth)) {
      return new ReportMonthRangeOption(this.values);
    } else {
      return null;
    }
  }
}
