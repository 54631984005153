import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiService } from './api.service';
import { OutboundFaxImport } from 'app/core/models/outbound-fax-import.model';

export class AttachedFile {
  url: string;
}

@Injectable()
export class OutboundFaxImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id: number): Observable<OutboundFaxImport> {
    return this.http.get<OutboundFaxImport>(`${environment.captureApi.url}/outbound_fax_imports/${id}`);
  }

  getList(): Observable<{ outboundFaxImports: OutboundFaxImport[] }> {
    return this.http.get<{ outboundFaxImports: OutboundFaxImport[] }>(
      `${environment.captureApi.url}/outbound_fax_imports`
    );
  }

  create(signedId: string): Observable<OutboundFaxImport> {
    return this.http.post<OutboundFaxImport>(`${environment.captureApi.url}/outbound_fax_imports`, {
      outboundFaxImport: { signedId },
    });
  }

  cancel(id: number): Observable<OutboundFaxImport> {
    return this.http.patch<OutboundFaxImport>(`${environment.captureApi.url}/outbound_fax_imports/${id}/cancel`, {});
  }

  generateSkippedFile(id: number): Observable<{ skippedFile: AttachedFile }> {
    return this.http.put<{ skippedFile: AttachedFile }>(`${environment.captureApi.url}/outbound_fax_imports/${id}/generate_skipped_file`, {});
  }

  generateOutcomeFile(id: number): Observable<{ outcomeFile: AttachedFile }> {
    return this.http.put<{ outcomeFile: AttachedFile }>(`${environment.captureApi.url}/outbound_fax_imports/${id}/generate_outcome_file`, {});
  }
}
