import { mergeMap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, TemplateRef, ContentChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportsService } from 'app/core/services/reports.service';
import { GenericCountService } from 'app/core/services/generic-count.service';
import { DownloadService } from 'app/core/services/download.service';
import { Report } from 'app/core/models/report.model';
import { NewReportOptionsTemplateDirective } from 'app/shared/directives/new-report-options-template.directive';
import { ReportDetailsTemplateDirective } from 'app/shared/directives/report-details-template.directive';
import { interval } from 'rxjs';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  static readonly listPollingInterval = 10000;
  static readonly activePollingInterval = 1000;

  availableRecordCount: number = null;
  loading = true;
  listPolling;
  activePolling = new Map<number, object>();

  reports: Report[];
  reportIndex = new Map<number, number>();

  creationError;

  @Input() filtersForm = this.formBuilder.group({});
  @Input() outputOptionsForm = this.formBuilder.group({});
  @Input() optionSerializationParams = { filters: {}, outputOptions: {} };

  @Input() reportsUrl;
  @Input() reportsCountUrl;
  @Input() title;
  @Input() displayProcessedColumn = true;
  @Input() totalColumnHeader = 'Total';
  @Input() forceReportDetailsDisplay = false;

  @ContentChild(NewReportOptionsTemplateDirective, { read: TemplateRef })
  newReportOptionsTemplate: TemplateRef<any>;
  @ContentChild(ReportDetailsTemplateDirective, { read: TemplateRef })
  reportDetailsTemplate: TemplateRef<any>;

  constructor(
    private reportsService: ReportsService,
    private downloadService: DownloadService,
    private genericCountService: GenericCountService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.reportsService.getList(this.reportsUrl).subscribe(reports => {
      this.setReports(reports);
      this.loading = false;

      this.listPollingInit();
    });
  }

  ngOnDestroy() {
    this.stopAllPolling();
  }

  get newReportOptionsValid(): boolean {
    return this.filtersForm.valid && this.outputOptionsForm.valid;
  }

  get generating(): boolean {
    return this.reports.some(report => this.isProcessing(report));
  }

  generate() {
    delete this.creationError;

    this.reportsService.create(this.reportsUrl, this.filterParams(), this.outputOptionParams()).subscribe(
      report => {
        this.setReports([report, ...this.reports]);
        this.startActivePolling(report.id);
      },
      (response: HttpErrorResponse) => {
        if (response.error && response.error.error) {
          this.creationError = response.error.error;
        }
      }
    );
  }

  download(report) {
    this.downloadService.getDownloadUrl(report.reportFileExpirableDownloadPath).subscribe(expirableDownload => {
      this.downloadService.download(expirableDownload.url);
    });
  }

  cancel(report) {
    this.reportsService.cancel(this.reportsUrl, report.id).subscribe(foundReport => {
      this.replaceReport(foundReport);
    });
  }

  isProcessing(report) {
    return report.status === 'processing' || report.status === 'initialized';
  }

  private optionsSetAndNotEmpty(options) {
    return (
      options &&
      Object.values(options).some(value => value && (!Array.isArray(value) || value.length > 0))
    );
  }

  hasAppliedOptions(report) {
    return this.optionsSetAndNotEmpty(report.filters) || this.optionsSetAndNotEmpty(report.outputOptions);
  }

  private listPollingInit() {
    this.listPolling = interval(ReportsComponent.listPollingInterval)
      .pipe(mergeMap(() => this.reportsService.getList(this.reportsUrl)))
      .subscribe(reports => {
        this.setReports(reports);
        this.resetActivePolling();
      });
  }

  private initiateActivePolling() {
    const inProcess = this.reports.filter(report => report.status === 'initialized' || report.status === 'processing');
    inProcess.forEach(report => {
      this.startActivePolling(report.id);
    });
  }

  private startActivePolling(reportId) {
    if (!this.activePolling[reportId]) {
      this.activePolling[reportId] = interval(ReportsComponent.activePollingInterval)
        .pipe(mergeMap(() => this.reportsService.get(this.reportsUrl, reportId)))
        .subscribe(report => {
          this.replaceReport(report);
        });
    }
  }

  private resetActivePolling() {
    this.stopAllActivePolling();
    this.initiateActivePolling();
  }

  private stopAllPolling() {
    this.stopListPolling();
    this.stopAllActivePolling();
  }

  private stopListPolling() {
    if (this.listPolling) {
      this.listPolling.unsubscribe();
      this.listPolling = null;
    }
  }

  private stopAllActivePolling() {
    Object.keys(this.activePolling).forEach(reportId => {
      this.stopActivePolling(reportId);
    });
    this.activePolling = new Map<number, object>();
  }

  private stopActivePolling(reportId) {
    if (this.activePolling[reportId]) {
      this.activePolling[reportId].unsubscribe();
      this.activePolling[reportId] = null;
      this.activePolling.delete(reportId);
    }
  }

  private setReports(reports) {
    this.reports = reports;
    this.resetReportIndex();

    if (this.reports.length === 0 && this.reportsCountUrl && this.availableRecordCount === null) {
      this.genericCountService.getCount(this.reportsCountUrl).subscribe(data => {
        this.availableRecordCount = data.count;
      });
    }
  }

  private resetReportIndex() {
    this.reportIndex = new Map<number, number>();
    let index = 0;
    this.reports.forEach(report => {
      this.reportIndex[report.id] = index;
      index += 1;
    });
  }

  private replaceReport(report) {
    const index = this.reportIndex[report.id];
    this.reports[index] = report;
  }

  private optionParams(options, optionType) {
    return Object.keys(options).reduce((result, optionName) => {
      const filter = options[optionName];

      if (filter) {
        const serializationParams = this.optionSerializationParams[optionType][optionName] || {};

        if (!serializationParams.paramName) {
          serializationParams.paramName = optionName;
        }

        const filterParams = filter.toPostData(serializationParams);
        return { ...result, ...filterParams };
      } else {
        return result;
      }
    }, {});
  }

  private filterParams() {
    const filters = this.filtersForm.value;

    return this.optionParams(filters, 'filters');
  }

  private outputOptionParams() {
    const outputOptions = this.outputOptionsForm.value;

    return this.optionParams(outputOptions, 'outputOptions');
  }
}
