import { Component, OnDestroy, OnInit } from '@angular/core';
import { LimitOffsetPaging } from '../../../core/models/paged-results/limit-offset-paging.model';
import { InboundCall } from '../../../core/models/inbound-call.model';
import { ClientsService } from '../../../core/services/clients.service';
import { UserSettingsService } from '../../../core/services/user-settings.service';
import { UserTimingService } from '../../../core/services/user-timing.service';
import { UserSettings } from '../../../core/enums/user-settings.enum';
import {
  InboundCallFilters,
  InboundCallListSettings,
} from '../../../core/models/user-settings/inbound-call-settings.model';
import { InboundCallsService, PagedInboundCalls } from '../../../core/services/inbound-calls.service';
import { keyedReasonOptions } from '../../../core/options/inbound-call-reasons.opts';

@Component({
  selector: 'app-capture-admin-inbound-calls',
  templateUrl: './capture-admin-inbound-calls.component.html',
  styleUrls: ['./capture-admin-inbound-calls.component.scss'],
})
export class CaptureAdminInboundCallsComponent implements OnInit, OnDestroy {
  inboundCalls: InboundCall[] = [];
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  filters: InboundCallFilters = new InboundCallFilters();
  keyedReasonOptions = keyedReasonOptions;
  loading = true;
  clients;
  selectedClientIds = [];

  constructor(
    private inboundCallsService: InboundCallsService,
    private clientsService: ClientsService,
    private userSettingsService: UserSettingsService,
    private userTimingService: UserTimingService
  ) {}

  ngOnInit() {
    this.init();
    this.startTimeTracking();
  }

  ngOnDestroy(): void {
    this.userTimingService.stop();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.getInboundCalls(this.paging.currentPage);
    this.saveListSettings();
  }

  onPageChange(page) {
    this.getInboundCalls(page);
    this.saveListSettings();
  }

  onClearClick($event) {
    $event.preventDefault();
    this.filters = new InboundCallFilters();
    this.saveListSettings();
    this.getInboundCalls(this.paging.currentPage);
  }

  onFilterChange() {
    this.saveListSettings();
    this.getInboundCalls(this.paging.currentPage);
    this.startTimeTracking();
  }

  private init() {
    this.loadListSettings();
    this.loadClients();
    this.getInboundCalls(this.paging.currentPage);
  }

  getInboundCalls(page) {
    this.loading = true;
    this.inboundCallsService
      .getList(page, this.paging.pageSize, this.filters.selectedClientIds)
      .subscribe((result: PagedInboundCalls) => {
        this.inboundCalls = result.inboundCalls;
        this.paging = result.meta.paging;
        this.loading = false;
      });
  }

  onViewClick() {
    this.saveListSettings();
  }

  private loadListSettings() {
    const listSettings: InboundCallListSettings = this.userSettingsService.get<InboundCallListSettings>(
      UserSettings.captureAdminInboundCallSettings
    );

    if (listSettings) {
      this.filters = listSettings.filters;
      this.paging = listSettings.paging;
    }
  }

  private saveListSettings() {
    const listSettings = new InboundCallListSettings();
    listSettings.filters = this.filters;
    listSettings.paging = this.paging;
    this.userSettingsService.save<InboundCallListSettings>(UserSettings.captureAdminInboundCallSettings, listSettings);
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  private startTimeTracking() {
    const data: any = { page: 'inbound_call_log' };
    this.userTimingService.track(data);
  }
}
