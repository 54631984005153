import { Pipe, PipeTransform } from '@angular/core';
import { CaptureStatus } from 'app/core/enums/capture-status.enum';
import { CaptureStatusReason } from 'app/core/enums/capture-status-reason.enum';
import { displayCaptureStatus } from 'app/core/options/capture-status.opts';
import { displayCaptureStatusReason } from 'app/core/options/capture-status-reason.opts';

// Example input:
//
// [
//   ["client_rejected", []],
//   ["rejected", ["expired", "service_date_before_first_interaction"]],
// ]

@Pipe({
  name: 'captureStatusesAndReasons',
})
export class CaptureStatusesAndReasonsPipe implements PipeTransform {
  transform(statusesAndReasons: any): string {
    return statusesAndReasons.map(([status, statusReasons]: [CaptureStatus, CaptureStatusReason[]]) => {
      let allReasons: string;

      if (statusReasons.length === 0) {
        allReasons = "All Reasons";
      } else {
        allReasons = statusReasons.map(reason => displayCaptureStatusReason(reason)).join(", ");
      }

      return `${displayCaptureStatus(status)} (${allReasons})`
    });
  }
}
