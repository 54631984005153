import { Component, Input, EventEmitter, Output, OnChanges, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';

const defaultTitle = 'Copy';
const copiedText = 'Copied to clipboard';
const loadingText = 'Loading...';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent implements OnChanges {
  @Output() copyClicked: EventEmitter<string> = new EventEmitter<string>();
  @Input() text: string;
  @Input() title = defaultTitle;
  @ViewChild('tooltip') tooltip: MatTooltip;

  tooltipText: string;
  errorMessage: string;
  isLoading = false;

  constructor(private clipboard: Clipboard) {
    this.tooltipText = this.title;
  }

  ngOnChanges() {
    if (this.isLoading) {
      this.copyToClipboard();
      this.isLoading = false;
    }
  }

  startCopy($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.copyClicked.observers.length === 0) {
      this.copyToClipboard();
    } else {
      this.requestTextAndCopy();
    }
  }

  resetTooltip(): void {
    setTimeout(() => {
      this.tooltipText = this.title;
    }, 50); // tooltip hangs around a bit after mouseLeave
  }

  private requestTextAndCopy(): void {
    this.copyClicked.emit();

    if (this.text) {
      this.copyToClipboard();
    } else {
      this.isLoading = true;
      this.showToolTip(loadingText);
    }
  }

  private copyToClipboard(): void {
    if (this.clipboard.copy(this.text)) {
      this.showToolTip(copiedText);
    }
  }

  private showToolTip(tip: string): void {
    this.tooltipText = tip;
    this.tooltip?.show();
  }
}
