import { Component, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';
import { CaptureSnapshotsService } from '../../../core/services/capture-snapshots.service';
import { CaptureSnapshot } from '../../../core/models/capture-snapshot.model';

const snapshotPollingInterval = 3000;

@Component({
  selector: 'app-capture-history-item-type-snapshot-created',
  templateUrl: './capture-history-item-type-snapshot-created.component.html',
})
export class CaptureHistoryItemTypeSnapshotCreatedComponent extends CaptureHistoryItemTypeBaseComponent
  implements OnInit, OnChanges, OnDestroy {
  captureSnapshot: CaptureSnapshot;

  snapshotPollingSubscription = null;

  constructor(private captureSnapshotsService: CaptureSnapshotsService) {
    super();
  }

  ngOnInit() {
    this.captureSnapshot = this.captureHistoryItem.relatedRecord;
    this.init();
  }

  ngOnChanges() {
    if (this.captureSnapshot) {
      this.init();
    }
  }

  ngOnDestroy() {
    this.stopSnapshotPolling();
  }

  private init() {
    this.stopSnapshotPolling();

    if (this.captureSnapshot.processing) {
      this.startSnapshotPolling(this.captureSnapshot.id);
    }
  }

  private startSnapshotPolling(captureSnapshotId: number) {
    this.snapshotPollingSubscription = interval(snapshotPollingInterval)
      .pipe(mergeMap(() => this.captureSnapshotsService.get(captureSnapshotId)))
      .subscribe(captureSnapshot => {
        this.captureSnapshot = captureSnapshot;

        if (!captureSnapshot.processing) {
          this.stopSnapshotPolling();
        }
      });
  }

  private stopSnapshotPolling() {
    if (this.snapshotPollingSubscription) {
      this.snapshotPollingSubscription.unsubscribe();
    }
  }
}
