<ng-container *ngIf="patient; else: noPatient">
  <div>
    <a
      *ngIf="canViewPatient; else: patientName"
      target="_blank"
      routerLink="/capture-admin/patients/{{ patient.id }}"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >{{ visiblePatient.fullName }}</a>
  </div>
  <div *ngIf="showAlternateNames">
    <div *ngIf="visiblePatient.alternateNames.length > 0">
      <div class="alternate-names"><strong>ALTERNATE NAMES</strong></div>
      <div *ngFor='let alternateName of visiblePatient.alternateNames'>
        {{ alternateName }}
      </div>
    </div>
  </div>
  <div class="small text-nowrap"><strong>MRN:</strong> {{ visiblePatient.mrn }}</div>
  <div class="small text-nowrap"><strong>DOB:</strong> {{ visiblePatient.dob | date: 'MM/dd/yyyy' }}</div>
</ng-container>
<ng-template #noPatient> <div><app-blank-value-dash /></div> </ng-template>
<ng-template #patientName>{{ visiblePatient.fullName }}</ng-template>

