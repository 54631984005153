<ul class="nav nav-tabs">
  <li class="nav-item">
    <a
      class="nav-link"
      routerLinkActive="active"
      routerLink="/capture-admin/tools/file-check"
      [routerLinkActiveOptions]="{ exact: true }"
      >Upload File</a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      routerLinkActive="active"
      routerLink="/capture-admin/tools/file-check/results"
      [routerLinkActiveOptions]="{ exact: true }"
      >Results</a
    >
  </li>
</ul>
