import { Component, Input, OnInit } from '@angular/core';
import { Office } from 'app/core/models/office.model';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss'],
})
export class OfficeComponent implements OnInit {
  @Input() office: Office;
  @Input() providerOffice: ProviderOffice;
  @Input() displayPhoneAndFax = true;
  @Input() allowCopyOfficeName = false;

  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;
  canViewOffice = false;

  constructor(
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.canViewOffice = this.authService.isCaptureAdminUser;
  }

}
