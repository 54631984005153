import { Component, OnInit } from '@angular/core';
import { CreatePasswordMode } from '../../components/create-password-form/create-password-form.component';

@Component({
  selector: 'app-confirm-new-user',
  templateUrl: './confirm-new-user.component.html',
  styleUrls: ['./confirm-new-user.component.scss'],
})
export class ConfirmNewUserComponent implements OnInit {
  createPasswordModes = CreatePasswordMode;

  constructor() {}

  ngOnInit() {}
}
