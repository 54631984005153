import { UserRoleName } from '../enums/user-role-name.enum';
import { keyBy } from 'lodash-es';

export class UserRoleOption {
  constructor(
    public value: string,
    public display: string,
  ) { }
}

const userRoleOptions = [
  new UserRoleOption(UserRoleName.manageCaptureAdmin, "Manage Capture Admin"),
  new UserRoleOption(UserRoleName.manageUsers, "Manage Client Users"),
  new UserRoleOption(UserRoleName.manageClients, "Manage Clients"),
  new UserRoleOption(UserRoleName.accessReferralClaims, "Referral Claims"),
  new UserRoleOption(UserRoleName.accessIccClaims, "Ineligible Claims Capture"),
  new UserRoleOption(UserRoleName.editVerifiedCaptures, "Edit Verified Captures"),
  new UserRoleOption(UserRoleName.faxQueue, "Fax Queue"),
  new UserRoleOption(UserRoleName.viewOcr, "View OCR"),
  new UserRoleOption(UserRoleName.consultNoteUser, "Consult Note User"),
  new UserRoleOption(UserRoleName.manageClientChores, "Manage Client Chores"),
  new UserRoleOption(UserRoleName.manageImplementation, "Manage Implementation"),
];

export const keyedUserRoleOptions = keyBy(userRoleOptions, 'value');