import { Component, Input, OnInit } from '@angular/core';
import { Capture } from 'app/core/models/capture.model';
import { CaptureService } from 'app/core/services/capture.service';
import { CaptureUserAuditOutcomeStatus } from 'app/core/enums/capture-user-audit-outcome-status.enum';
import {
  outcomeStatusOptions,
  CaptureUserAuditOutcomeStatusOption,
} from 'app/core/options/capture-user-audit-outcome-status.opts';
import {
  outcomeStatusReasonOptions,
  CaptureUserAuditOutcomeStatusReasonOption,
} from 'app/core/options/capture-user-audit-outcome-status-reason.opts';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-capture-user-audit',
  templateUrl: './capture-user-audit.component.html',
  styleUrls: ['./capture-user-audit.component.scss'],
  providers: [],
})
export class CaptureUserAuditComponent implements OnInit {
  @Input() capture: Capture;

  canAudit = false;

  processing = false;
  serverErrorMessage: string;

  captureUserAuditOutcomeStatus = CaptureUserAuditOutcomeStatus;
  availableOutcomeStatusOptions: CaptureUserAuditOutcomeStatusOption[] = outcomeStatusOptions;
  availableOutcomeStatusReasonOptions: CaptureUserAuditOutcomeStatusReasonOption[] = outcomeStatusReasonOptions;

  allowSubmit = false;

  formModel: {
    status: string;
    statusReasons: { [key: string]: boolean };
    note: string;
  } = {
    status: null,
    statusReasons: {},
    note: '',
  };

  constructor(private captureService: CaptureService,
              private authService: AuthenticationService) {}

  ngOnInit() {
    this.canAudit = this.authService.isCaptureAdminUser;
  }

  onFormControlChange() {
    this.allowSubmit = this.determineAllowSubmit();
  }

  determineAllowSubmit() {
    return (
      this.formModel.status &&
      (this.formModel.status === CaptureUserAuditOutcomeStatus.compliant ||
        (Object.values(this.formModel.statusReasons).some(reason => reason) && this.formModel.note.length > 0))
    );
  }

  onSubmit() {
    if (this.allowSubmit) {
      this.processing = true;
      this.serverErrorMessage = null;

      this.captureService.createUserAuditOutcome(this.capture, this.formParams()).subscribe(
        (capture: Capture) => {
          this.processing = false;
          this.captureService.notifyCaptureChanged(capture);
        },
        response => {
          this.processing = false;
          this.serverErrorMessage = response.error.error;
        }
      );
    }
  }

  formParams() {
    const statusReasons = Object.entries(this.formModel.statusReasons)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => key);

    return {
      status: this.formModel.status,
      statusReasons,
      note: this.formModel.note,
    };
  }
}
