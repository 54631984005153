<label [for]="provider.npi" class="form-check-label">
  <div class="small name">
    <strong>
      {{ provider.name | blankValueDash }}
      <span *ngIf="showFaxStatus">
        ({{ provider.relevantProviderOffice?.status | providerOfficeFaxStatus }})
      </span>
    </strong>
    <a target="_blank" href="/capture-admin/providers/{{ provider.npi }}" *ngIf="provider.npi">
      <fa-icon [icon]="['fas', 'external-link-alt']" size="sm" />
    </a>
    <app-copy-to-clipboard [text]="provider.name" />
  </div>
  <div class="small">{{ provider.specialty | blankValueDash }}</div>
  <div class="small">
    <strong>NPI:</strong>
    <a target="_blank" href="/capture-admin/providers/{{provider.npi}}">
      {{ provider.npi }}
    </a>
    <app-copy-to-clipboard [text]="provider.npi" />
  </div>

  <app-provider-office-association-notes
    *ngIf="provider.relevantProviderOffice"
    [providerOfficeId]="provider.relevantProviderOffice?.id"
    [enablePopover]="true"
  />
</label>
