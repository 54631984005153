<div class="d-flex small text-center justify-content-around align-items-center">
  <div class="mt-1 px-1 border bg-light box">
    <strong>PENDING</strong>
    <div>{{ patientAttachment.pendingEstimatedValue | currency: 'USD':'symbol':'1.0-0' }}</div>
  </div>
  <div class="mt-1 px-1 border bg-light box">
    <strong>WIP</strong>
    <div>{{ patientAttachment.wipEstimatedValue | currency: 'USD':'symbol':'1.0-0' }}</div>
  </div>
</div>
