import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, catchError, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { User } from '../../../core/models/user.model';
import { UserType } from '../../../core/enums/user-type.enum';
import { LlmPlaygroundCollaboratorPermissionsService } from '../../../core/services/llm-playground-collaborator-permissions.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { UsersService } from '../../../core/services/users.service';

@Component({
  selector: 'app-llm-playground-share-modal',
  templateUrl: './llm-playground-share-modal.component.html',
})
export class LlmPlaygroundShareModalComponent implements OnInit {
  @Input() projectId: number;
  @Input() createdByEmail: string;
  loading = true;
  errorMessage = '';
  eligibleUsers = [];
  selectableUsers = [];
  selectedUsers = [];
  currentPermissions = [];

  constructor(
    public activeModal: NgbActiveModal,
    private llmPlaygroundCollaboratorPermissionsService: LlmPlaygroundCollaboratorPermissionsService,
    private authenticationService: AuthenticationService,
    private usersService: UsersService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  isNotSelf(email: string) {
    return this.authenticationService.currentUser.email !== email
  }

  onCollaboratorChange(currentSelection: User[]) {
    this.selectedUsers = currentSelection;
  }

  onShare() {
    if (this.selectedUsers.length > 0) {
      this.loading = true;
      const permissionObservables = this.selectedUsers.map(user => this.createPermission(user.id));

      forkJoin(permissionObservables).pipe(
        switchMap(() => this.loadCollaboratorPermissions()),
        finalize(() => {
          this.loading = false;
        })
      ).subscribe(
        collaboratorPermissions => {
          this.currentPermissions = collaboratorPermissions;
          this.updateSelectableUsers();
          this.selectedUsers = this.selectedUsers.filter(user => (
            !collaboratorPermissions.map(permission => permission.userId).includes(user.id)
          ));
        },
        error => {
          console.error('Error creating or loading permissions:', error);
          this.errorMessage = `Error creating or loading permissions: ${error.error?.join(", ")}`;
        }
      );
    }
  }

  onCancel() {
    this.activeModal.close();
  }

  onRemove($event: Event, permissionId: number) {
    $event.preventDefault();
    return this.llmPlaygroundCollaboratorPermissionsService.destroy(permissionId).subscribe(() => {
      this.currentPermissions = this.currentPermissions.filter(p => p.id !== permissionId)
      this.updateSelectableUsers();
    })
  }

  private loadCollaboratorPermissions() {
    return this.llmPlaygroundCollaboratorPermissionsService.index(this.projectId)
  }

  private loadEligibleUsers() {
    return this.usersService.getByUserType([UserType.captureAdmin, UserType.llmPlaygroundUser])
  }

  private createPermission(userId: number) {
    return this.llmPlaygroundCollaboratorPermissionsService.create(this.projectId, userId)
  }

  private loadData() {
    forkJoin([
      this.loadCollaboratorPermissions(),
      this.loadEligibleUsers()
    ]).subscribe(
      ([collaboratorPermissions, eligibleUsers]) => {
        this.currentPermissions = collaboratorPermissions;
        this.eligibleUsers = eligibleUsers.users;
        this.updateSelectableUsers()
        this.loading = false;
      },
      error => {
        console.error('Error loading sharing permissions', error);
        this.errorMessage = `Error loading sharing permissions: ${error.error?.join(", ")}`;
        this.loading = false;
      }
    );
  }

  private updateSelectableUsers() {
    const collaboratorUserIds = this.currentPermissions.map((permission) => permission.userId);
    this.selectableUsers = this.eligibleUsers.filter(user => (
      !collaboratorUserIds.includes(user.id) && user.email !== this.createdByEmail)
    )
  }
}
