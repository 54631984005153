import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UserTimingService } from '../../../core/services/user-timing.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InboundCall } from '../../../core/models/inbound-call.model';
import { InboundCallsService } from '../../../core/services/inbound-calls.service';
import { keyedReasonOptions } from '../../../core/options/inbound-call-reasons.opts';

const shouldReloadInboundCall = (inboundCall: InboundCall, inboundCallId) =>
  inboundCall && inboundCallId !== inboundCall.id.toString();

@Component({
  selector: 'app-capture-admin-inbound-call',
  templateUrl: './capture-admin-inbound-call.component.html',
  styleUrls: ['./capture-admin-inbound-call.component.scss'],
})
export class CaptureAdminInboundCallComponent implements OnInit, OnDestroy {
  keyedReasonOptions = keyedReasonOptions;
  inboundCall: InboundCall;
  refreshing = true;

  constructor(
    private route: ActivatedRoute,
    private inboundCallsService: InboundCallsService,
    private router: Router,
    private titleService: Title,
    private userTimingService: UserTimingService
  ) {
    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  ngOnInit() {
    const inboundCallId = this.route.snapshot.paramMap.get('id');
    this.loadCall(inboundCallId);
  }

  ngOnDestroy(): void {
    this.userTimingService.stop();
  }

  private handleRouteChange(paramsMap) {
    const params = paramsMap['params'];
    const inboundCallId = params && params['id'];

    if (shouldReloadInboundCall(this.inboundCall, inboundCallId)) {
      this.loadCall(inboundCallId);
    }
  }

  private loadCall(inboundCallId): void {
    this.refreshing = true;
    this.inboundCallsService.get(inboundCallId).subscribe(
      inboundCall => {
        this.inboundCall = inboundCall;
        this.refreshing = false;
        this.updateTitle();
        this.startTimeTracking();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
    window.scrollTo(0, 0);
  }

  private updateTitle() {
    const titleDetail = this.inboundCall.patient ? this.inboundCall.patient.mrn : this.inboundCall.client.humanizedName;
    const title = `R1 340B Recovery - View Inbound Call | ${titleDetail}`;
    this.titleService.setTitle(title);
  }

  private startTimeTracking() {
    this.userTimingService.track({
      page: 'inbound_call_details',
      recordId: this.inboundCall.id,
      reason: this.inboundCall.reason,
    });
  }
}
