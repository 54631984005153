import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FileCheck } from '../../../core/models/file-check.model';

@Component({
  selector: 'app-file-check-status',
  templateUrl: './file-check-status.component.html',
  styleUrls: ['./file-check-status.component.scss'],
})
export class FileCheckStatusComponent implements OnInit, OnChanges {
  @Input() fileCheck: FileCheck;

  displayStatus: string;
  errorMessage: string;

  constructor() {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  private init() {
    if (this.checkFailed && this.fileCheck.failureMessage) {
      this.errorMessage = this.fileCheck.failureMessage;
    }

    if (this.fileInvalid && this.fileCheck.invalidMessage) {
      this.errorMessage = this.fileCheck.invalidMessage;
    }

    if (this.fileCheck.status === 'completed') {
      this.displayStatus = this.fileInvalid ? 'invalid' : 'valid';
    } else {
      this.displayStatus = this.fileCheck.status;
    }
  }

  private get checkFailed() {
    return this.fileCheck.status === 'failed';
  }

  private get fileInvalid() {
    return this.fileCheck.invalidRows > 0;
  }
}
