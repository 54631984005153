import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EhrConsultNoteReference } from 'app/core/models/ehr-consult-note-reference.model';
import { mapObjectToFormGroup, applyServerErrorsToFormGroup } from 'app/core/lib/form-utils';

@Component({
  selector: 'app-ehr-consult-note-reference-form',
  templateUrl: './ehr-consult-note-reference-form.component.html',
  styleUrls: ['./ehr-consult-note-reference-form.component.scss'],
})
export class EhrConsultNoteReferenceFormComponent implements OnInit, OnChanges {

  @Input() ehrConsultNoteReference: EhrConsultNoteReference;
  @Input() errors: any;

  @Output() saved = new EventEmitter<any>();
  @Output() canceled = new EventEmitter<void>();

  formGroup: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    if (!this.ehrConsultNoteReference) {
      this.ehrConsultNoteReference = new EhrConsultNoteReference();
    }

    this.formGroup = mapObjectToFormGroup(this.formBuilder, this.ehrConsultNoteReference, [
      'name',
      'notes'
    ]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.errors?.currentValue) {
      applyServerErrorsToFormGroup(changes.errors.currentValue, this.formGroup);
    }
  }

  onSubmit() {
    this.saved.emit(this.formGroup.value);
  }

  onCancelClick() {
    this.canceled.emit();
  }
}
