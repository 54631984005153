import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { OutboundFax } from '../models/outbound-fax.model';
import { environment } from '../../../environments/environment';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';
import { PagedResults } from '../models/paged-results/paged-results.model';

export class OutboundFaxesResult {
  outboundFaxes: OutboundFax[];
}

export class PagedOutboundFaxes implements PagedResults {
  outboundFaxes: OutboundFax[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.outboundFaxes;
  }
}

@Injectable()
export class OutboundFaxesService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(claimIdentifier: string = null, limit = 10): Observable<OutboundFaxesResult> {
    const searchParams = new URLSearchParams();

    searchParams.append('page', '1');
    searchParams.append('per_page', limit.toString());

    if (claimIdentifier) {
      searchParams.append('claim_identifier', claimIdentifier);
    }

    return this.http.get<OutboundFaxesResult>(
      `${environment.captureApi.url}/outbound_faxes?&${searchParams.toString()}`
    );
  }

  providerOutboundFaxes(npi: string,
    paging: { page: number; pageSize: number },
  ): Observable<PagedOutboundFaxes> {

    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);
    searchParams.append('prescriber_npi', npi);

    return this.http.get<PagedOutboundFaxes>(
      `${environment.captureApi.url}/outbound_faxes?${searchParams.toString()}`
    );
  }

  outboundFaxesByFax(fax: string,
    paging: { page: number; pageSize: number },
  ): Observable<PagedOutboundFaxes> {
    const searchParams = new URLSearchParams(`page=${paging.page}&per_page=${paging.pageSize}`);
    searchParams.append('fax', fax);

    return this.http.get<PagedOutboundFaxes>(
      `${environment.captureApi.url}/outbound_faxes?${searchParams.toString()}`
    );
  }

  cancel(id): Observable<OutboundFax> {
    return this.http.patch<OutboundFax>(`${environment.captureApi.url}/outbound_faxes/${id}/cancel`, {});
  }
}
