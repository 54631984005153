import { CaptureUserAuditOutcomeStatusReason } from '../enums/capture-user-audit-outcome-status-reason.enum';
import { keyBy } from 'lodash-es';

export class CaptureUserAuditOutcomeStatusReasonOption {
  constructor(public value: string, public display: string) {}
}

export const outcomeStatusReasonOptions = [
  new CaptureUserAuditOutcomeStatusReasonOption(
    CaptureUserAuditOutcomeStatusReason.missingReferralValidation,
    'Missing Referral Validation'
  ),
  new CaptureUserAuditOutcomeStatusReasonOption(
    CaptureUserAuditOutcomeStatusReason.missingPatientValidation,
    'Missing Patient Validation'
  ),
  new CaptureUserAuditOutcomeStatusReasonOption(
    CaptureUserAuditOutcomeStatusReason.missingPrescriberValidation,
    'Missing Prescriber Validation'
  ),
  new CaptureUserAuditOutcomeStatusReasonOption(
    CaptureUserAuditOutcomeStatusReason.missingSpecialtyValidation,
    'Missing Specialty Validation'
  ),
  new CaptureUserAuditOutcomeStatusReasonOption(
    CaptureUserAuditOutcomeStatusReason.missingDrugValidation,
    'Missing Drug Validation'
  ),
  new CaptureUserAuditOutcomeStatusReasonOption(
    CaptureUserAuditOutcomeStatusReason.missingEncounterValidation,
    'Missing Encounter Validation'
  ),
  new CaptureUserAuditOutcomeStatusReasonOption(CaptureUserAuditOutcomeStatusReason.other, 'Other'),
];

export const keyedOutcomeStatusReasonOptions = keyBy(outcomeStatusReasonOptions, 'value');
