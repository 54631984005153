<div id="capture_detail_header" [ngClass]="{ collapse: isHidden }">
  <div class="row mx-0">
    <div class="col-6 px-0 text-start">
      <nav class="nav nav-pills">
        <a
          class="nav-item nav-link"
          routerLink="/capture-admin/captures/{{capture.id}}"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Capture Details
        </a>
        <a
          *ngIf="canViewHistory"
          class="nav-item nav-link"
          routerLink="/capture-admin/captures/{{capture.id}}/history-new"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Capture History (New)
        </a>
        <a
          *ngIf="canViewHistory"
          class="nav-item nav-link"
          routerLink="/capture-admin/captures/{{capture.id}}/history"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Capture History (Historical)
        </a>
        <a
          *ngIf="canViewFax"
          class="nav-item nav-link"
          routerLink="/capture-admin/captures/{{capture.id}}/fax"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Fax
        </a>
      </nav>
    </div>

    <div class="col-6 px-0 text-end">
      <div class="my-1 d-inline-block">
        <app-user-presence category="capture" [targetId]="capture.id" />
      </div>
      <div class="invoice-credit">
        <span>
          <strong>Audited:</strong>
          <span *ngIf="capture.userAuditOutcome">Yes</span>
          <span *ngIf="!capture.userAuditOutcome">No</span>
        </span>
        <span>
          <strong>Invoiced:</strong>
          <span *ngIf="!capture.invoicingDuplicate && capture.invoiceDate"> Yes, {{ capture.invoiceDate | date: 'MM/yyyy' }} </span>
          <span *ngIf="!capture.invoicingDuplicate && !capture.invoiceDate"> No </span>
          <span *ngIf="capture.invoicingDuplicate"> Duplicate </span>
        </span>
        <span>
          <strong>Credited:</strong>
          <span *ngIf="!capture.invoicingDuplicate && capture.creditDate"> Yes, {{ capture.creditDate | date: 'MM/yyyy' }} </span>
          <span *ngIf="!capture.invoicingDuplicate && !capture.creditDate"> No </span>
          <span *ngIf="capture.invoicingDuplicate"> N/A </span>
        </span>
      </div>
      <div class="my-1 d-inline-block" *ngIf="canSnapshot">
        <app-capture-snapshot [capture]="capture" />
      </div>
      <div class="my-1 d-inline-block" *ngIf="canReprocess">
        <app-capture-reprocess [capture]="capture" />
      </div>
      <div class="my-1 d-inline-block" *ngIf="canUnverify">
        <app-capture-unverify [capture]="capture" />
      </div>
      <div class="my-1 d-inline-block" *ngIf="canGoToNext">
        <app-capture-next [capture]="capture" />
      </div>
    </div>
  </div>
  <div class="border py-1 rounded" [ngClass]="{ 'inactive': !capture.client.active }">
    <div class="inactive-label" [ngClass]="{ 'd-none': capture.client.active }">INACTIVE</div>
    <div class="row mx-1">
      <div class="col-auto">
        <strong>CLAIM ID</strong>
        <div>{{ capture.candidate.claimIdentifier }}</div>
      </div>
      <div class="col-auto text-center">
        <strong>CAPTURE ID</strong>
        <div>{{ capture.id }}</div>
      </div>
      <div class="col-auto capture-status">
        <strong>STATUS</strong>
        <div>
          {{ capture.status | captureStatus }}
          <span *ngIf="capture.statusReason">
            -
          </span>
        </div>
        <div *ngIf="capture.statusReason">
          <small>
            {{ capture.statusReason | captureStatusReason }}
          </small>
        </div>
      </div>
      <div class="col-auto">
        <div>
          <strong>CLIENT</strong>
          <span *ngIf="!capture.client.active" class="inactive-client">INACTIVE</span>
        </div>
        <app-client-name [client]="capture.client" />
        <div *ngIf="capture.client.entityTypes">
          Type: {{ capture.client.entityTypes | joinList }}
        </div>
        <div *ngIf="capture.ehrAccess" class="ehr-access">
          EHR Access
        </div>
        <div *ngIf="capture.client.outreachAttemptEvidenceEnabled" class="outreach-attempt-evidence-enabled">
          Outreach Attempts
        </div>
      </div>
      <div class="col-auto">
        <strong>CE-PATIENT</strong>
        <app-patient-name
          [patient]="capture.patient"
          [sourcePatient]="capture.sourcePatient"
          [canViewPatient]="canViewPatient" />
      </div>
      <div class="col-auto">
        <strong>RX-PATIENT</strong>
        <app-candidate-patient-name [candidate]="capture.candidate" />
      </div>
      <div class="col-auto text-end">
        <strong>EXPIRES ON</strong>
        <div>
          <app-expiration-date [expirationDate]="capture.expirationDate" />
        </div>
      </div>
      <div class="col-auto text-end">
        <strong>EST. VALUE</strong>
        <div>{{ capture.candidate.estimatedValueForDisplay }}</div>
        <app-high-value-indicator [capture]="capture" />
      </div>
      <div class="col-auto">
        <strong>DRUG</strong>
        <app-candidate-drug
          [candidate]="capture.candidate"
          [wrapProductName]="true"
          [showCopy]="true"
          [displayFlaggedForEsp]="true"
        />
      </div>
      <div *ngIf="showClientReviewTask" class="col-auto">
        <strong>CE TASKS</strong>

        <div>
          <strong>PRESCRIBER: </strong>
          <span *ngIf="capture.clientPrescriberTask; else: noClientPrescriberTask">
            <a routerLink="/capture-admin/referral-claims/clients/client-prescriber-tasks/{{ capture.clientPrescriberTask.id }}"
               target="_blank">
              {{ capture.clientPrescriberTask.id }}
            </a>
            ({{ capture.clientPrescriberTask.status | capitalize }})
          </span>
          <ng-template #noClientPrescriberTask>No</ng-template>
        </div>

        <div>
          <strong>REFERRAL: </strong>
          <span *ngIf="capture.clientReferralTask; else: noClientReferralTask">
            <a routerLink="/capture-admin/referral-claims/clients/client-referral-tasks/{{ capture.clientReferralTask.id }}"
               target="_blank">
              {{ capture.clientReferralTask.id }}
            </a>
            ({{ capture.clientReferralTask.status | capitalize }})
          </span>
          <ng-template #noClientReferralTask>No</ng-template>
        </div>

        <div>
          <strong>CONSULT NOTE: </strong>
          <span *ngIf="capture.clientConsultNoteTask; else: noClientConsultNoteTask">
            <a routerLink="/capture-admin/referral-claims/clients/client-consult-note-tasks/{{ capture.clientConsultNoteTask.id }}"
               target="_blank">
              {{ capture.clientConsultNoteTask.id }}
            </a>
            ({{ capture.clientConsultNoteTask.status | capitalize }})
          </span>
          <ng-template #noClientConsultNoteTask>No</ng-template>
        </div>
      </div>
      <div *ngIf="capture.autoRefillSource" class="col-auto">
        <strong>AUTO VERIFIED FROM CLAIM</strong>
        <div>
          <a routerLink="/capture-admin/captures/{{capture.autoRefillSource.id}}" target="_blank">{{
            capture.autoRefillSource.candidate.claimIdentifier
          }}</a>
        </div>
      </div>
    </div>
    <div class="row mx-1">
      <div class="col-auto">
        <strong>RX-PRESCRIBER</strong>
        <app-prescriber-name [prescriber]="capture.prescriber" [linkToProvider]="true" />
        <span *ngIf="capture.clientAffiliatedPrescriber" class="client-affiliated-prescriber">
          Client Affiliated Prescriber
        </span>
      </div>
      <div class="col-auto provider-specialties">
        <strong>SPECIALTIES</strong>
        <div>{{ capture.prescriber.displaySpecialty }}</div>
      </div>
      <div class="col-auto">
        <app-prescriber-offices [prescriber]="capture.prescriber" [acceptableFaxStatusOnly]="true" />
      </div>
    </div>
  </div>
</div>
<div id="capture_detail_header_hidden"
  [ngClass]="{ collapse: !isHidden }"
  (click)="onShowClick()">
  <div class="border py-1 rounded">
    <div class="row mx-1">
      <div class="col-auto">
        Capture Details are hidden. Needs Patient Match Approval should focus on the possibility of the names matching and not information within the capture.
        <br>
        Click to show capture details
      </div>
    </div>
  </div>
</div>
