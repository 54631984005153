import { Component, Input } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';

@Component({
  selector: 'app-validate-drug-match',
  templateUrl: './validate-drug-match.component.html',
  styleUrls: ['./validate-drug-match.component.scss'],
})
export class ValidateDrugMatchComponent {
  collapsed = false;

  @Input() patientAttachment: PatientAttachment;

  constructor() {}

  onCollapseChange(collapsed) {
    this.collapsed = collapsed;
  }

  collapse() {
    this.collapsed = true;
  }

  expand() {
    this.collapsed = false;
  }
}
