import { Component, OnInit, ViewChild } from '@angular/core';
import { keyBy } from 'lodash-es';
import { HttpErrorResponse } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { Client } from '../../../core/models/client.model';
import { ClientConfigurationOptionDefinition } from '../../../core/models/client-configuration-option-definition.model';
import { ClientsService } from '../../../core/services/clients.service';
import {
  ClientConfigurationOptionsService,
  PersistableClientConfigurationOption,
} from '../../../core/services/client-configuration-options.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { ClientConfigurationOption } from '../../../core/models/client-configuration-option.model';
import {
  ConfigsMap,
  DefinitionsMap,
  determineDeactivated,
  determineEditorType,
  EditableConfiguration,
  EditorType,
  generateConfigIdentifier,
  initializeSuboptionChoices,
  isDefinitionSelectable,
  isPersisted,
  normalizeConfigValueForUpdate,
  refreshConfigValidity,
  sortConfigs,
  SuboptionChoice,
} from '../../lib/client-configuration';

@Component({
  selector: 'app-capture-admin-client-edit-configuration',
  templateUrl: './capture-admin-client-edit-configuration.component.html',
  styleUrls: ['./capture-admin-client-edit-configuration.component.scss'],
})
export class CaptureAdminClientEditConfigurationComponent implements OnInit {
  @ViewChild('configForm', { static: false }) form: any;

  client: Client;
  loading = false;
  saving = false;
  editorTypes = EditorType;

  trackByConfig = (index: number, config: EditableConfiguration) => config.identifier;

  allDefinitionsByName: DefinitionsMap = {};
  selectableDefinitions: ClientConfigurationOptionDefinition[] = null;
  selectedDefinition: ClientConfigurationOptionDefinition = null;

  actualConfigsByIdentifier: ConfigsMap = {};
  displayedConfigs: EditableConfiguration[] = [];

  constructor(
    private clientsService: ClientsService,
    private clientConfigurationOptionsService: ClientConfigurationOptionsService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    const clientId = this.navigationService.currentId;
    this.loadConfigs(clientId);
  }

  onAddNewConfig(ngSelectControl) {
    this.addNewConfig(ngSelectControl);
  }

  onBasicChange(config: EditableConfiguration, val: any) {
    config.configOption.value = val;
    refreshConfigValidity(this.form, config);
  }

  onBasicDeleteClick(config: EditableConfiguration, $event) {
    $event.preventDefault();
    this.deleteConfig(config);
  }

  onSuboptionsChange(config: EditableConfiguration, suboptionChoices: SuboptionChoice[]) {
    this.updateSuboptions(config, suboptionChoices);
  }

  onDatedChange(config: EditableConfiguration, configOption: ClientConfigurationOption) {
    config.configOption = configOption;
    refreshConfigValidity(this.form, config);
  }

  onDatedDelete(config: EditableConfiguration) {
    this.deleteConfig(config);
  }

  onFormSubmit(form) {
    const theForm = form.form;
    this.saveConfigs(theForm);
  }

  onCancelClick() {
    this.navigateToClient();
  }

  private loadConfigs(clientId): void {
    this.loading = true;

    this.clientsService
      .get(clientId)
      .pipe(
        mergeMap((client: Client) => {
          this.client = client;
          return this.clientConfigurationOptionsService.getAllDefinitions();
        }),
        mergeMap(({ availableClientConfigurationOptions }) => {
          this.allDefinitionsByName = keyBy(availableClientConfigurationOptions, 'option');
          return this.clientConfigurationOptionsService.getList(this.client.id);
        })
      )
      .subscribe(({ clientConfigurationOptions }) => {
        this.initializeConfigs(clientConfigurationOptions);
        this.loading = false;
      });
  }

  private initializeConfigs(clientConfigs: ClientConfigurationOption[]) {
    const initialConfigs = clientConfigs.map(config => {
      const definition = this.allDefinitionsByName[config.option];

      const eco = new EditableConfiguration(
        generateConfigIdentifier(definition.option, config.id),
        config,
        definition,
        determineEditorType(definition),
        determineDeactivated(definition, config)
      );

      initializeSuboptionChoices(definition, eco);

      return eco;
    });

    this.actualConfigsByIdentifier = keyBy(initialConfigs, 'identifier');
    this.refereshLists();
  }

  private refereshLists() {
    this.refreshSelectableDefinitions();
    this.refreshDisplayedConfigs();
  }

  private refreshSelectableDefinitions() {
    this.selectableDefinitions = Object.values(this.allDefinitionsByName)
      .filter(kod => isDefinitionSelectable(kod, this.actualConfigsByIdentifier))
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  private refreshDisplayedConfigs() {
    this.displayedConfigs = sortConfigs(Object.values(this.actualConfigsByIdentifier).filter(opt => !opt.deleted));
  }

  private updateSuboptions(config: EditableConfiguration, suboptionChoices: SuboptionChoice[]) {
    config.suboptionChoices = suboptionChoices;
    refreshConfigValidity(this.form, config);
  }

  private addNewConfig(ngSelectControl) {
    if (this.selectedDefinition) {
      const identifier = generateConfigIdentifier(this.selectedDefinition.option, null);
      const matchingConfig = this.actualConfigsByIdentifier[identifier];

      if (!matchingConfig) {
        const newConfigOption = new ClientConfigurationOption();
        newConfigOption.option = this.selectedDefinition.option;
        newConfigOption.value = this.selectedDefinition.defaultValue;

        if (this.selectedDefinition.dated) {
          newConfigOption.value = true;
        }

        const newEditableOption = new EditableConfiguration(
          identifier,
          newConfigOption,
          this.selectedDefinition,
          determineEditorType(this.selectedDefinition)
        );

        initializeSuboptionChoices(this.selectedDefinition, newEditableOption);

        this.actualConfigsByIdentifier[newEditableOption.identifier] = newEditableOption;
      } else {
        matchingConfig.deleted = false;
        initializeSuboptionChoices(this.selectedDefinition, matchingConfig);
      }
    }

    ngSelectControl.handleClearClick();
    this.refereshLists();
  }

  private deleteConfig(config: EditableConfiguration) {
    if (!isPersisted(config.configOption)) {
      delete this.actualConfigsByIdentifier[config.identifier];
    } else {
      config.deleted = true;
    }

    this.refereshLists();
  }

  private saveConfigs(theForm) {
    if (theForm.valid) {
      this.saving = true;
      const payload = this.normalizeConfigsForSave();
      this.clientConfigurationOptionsService.updateAll(this.client.id, payload).subscribe(
        ({ clientConfigurationOptions }) => this.handleSaveSuccess(clientConfigurationOptions),
        response => this.handleSaveFailure(response)
      );
    }
  }

  private normalizeConfigsForSave() {
    return Object.values(this.actualConfigsByIdentifier)
      .filter(eco => !eco.deactivated && eco.definition.editable)
      .map(eco => {
        const normalizedOption = new PersistableClientConfigurationOption();

        if (isPersisted(eco.configOption)) {
          normalizedOption.id = eco.configOption.id;
        }

        if (eco.editorType === EditorType.dated) {
          normalizedOption.startAt = eco.configOption.startAt;
          normalizedOption.endAt = eco.configOption.endAt;
        }

        normalizedOption.option = eco.definition.option;
        normalizedOption.deleted = eco.deleted;
        normalizedOption.value = normalizeConfigValueForUpdate(eco);

        return normalizedOption;
      });
  }

  private handleSaveSuccess(clientConfigs: ClientConfigurationOption[]) {
    this.initializeConfigs(clientConfigs);
    this.navigateToClient();
  }

  private handleSaveFailure(response: HttpErrorResponse) {
    this.saving = false;

    if (response.status === 400) {
      const { error: data } = response;

      data.errors.forEach(error => {
        const identifier = generateConfigIdentifier(error.option, error.id);
        const config = this.actualConfigsByIdentifier[identifier];
        if (config) {
          refreshConfigValidity(this.form, config, error);
        }
      });
    } else {
      console.error(response);
    }
  }

  private navigateToClient() {
    this.navigationService.navigateTo(`/capture-admin/client-management/clients/${this.client.id}`);
  }
}
