import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiService } from 'app/core/services/api.service';
import { UserStat } from 'app/core/models/user-stat.model';

@Injectable()
export class MyStatsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getFaxes(): Observable<UserStat> {
    return this.http.get<UserStat>(`${environment.captureApi.url}/me/stats/inbound_fax?${this.timezoneParam()}`);
  }

  getGatherEvidenceBlocked(): Observable<UserStat> {
    return this.http.get<UserStat>(`${environment.captureApi.url}/me/stats/gather_evidence_blocked?${this.timezoneParam()}`);
  }

  getConsultNote(): Observable<UserStat> {
    return this.http.get<UserStat>(`${environment.captureApi.url}/me/stats/consult_note?${this.timezoneParam()}`);
  }

  getVerifiedCapture(): Observable<UserStat> {
    return this.http.get<UserStat>(`${environment.captureApi.url}/me/stats/verified_capture?${this.timezoneParam()}`);
  }

  private timezoneParam() {
    return new URLSearchParams(`browser_timezone=${this.browserTimezone()}`).toString();
  }
}
