import { Component, Input } from '@angular/core';
import { ClientChore } from 'app/core/models/client-chore.model';

@Component({
  selector: 'app-client-chore-status',
  templateUrl: './client-chore-status.component.html',
  styleUrls: ['./client-chore-status.component.scss'],
})
export class ClientChoreStatusComponent {
  @Input() clientChore: ClientChore;

  onNoteClick($event: MouseEvent) {
    $event.preventDefault();
  }
}
