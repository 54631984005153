import { Component } from '@angular/core';
import { compact } from 'lodash-es';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';

@Component({
  selector: 'app-capture-history-item-type-verifying-patient-attachment-associated',
  templateUrl: '../capture-history-item-type-base/capture-history-item-type-base.component.html',
})
export class CaptureHistoryItemTypeVerifyingPatientAttachmentAssociatedComponent extends
    CaptureHistoryItemTypeBaseComponent {
  get title() {
    return 'Patient Attachment Associated with Capture';
  }

  get description() {
    return compact(['Document', this.filename, 'associated with this capture']).join(' ');
  }

  get filename() {
    if (this.captureHistoryItem.relatedRecord) {
      return this.captureHistoryItem.relatedRecord.fileName;
    }
  }
}
