/* eslint-disable @angular-eslint/component-selector */

import { Component, Input, OnInit } from '@angular/core';
import { Audit } from 'app/core/models/audit.model';
import { obj2Html } from '../../../core/lib/js-utils';

@Component({
  selector: '[app-audit-provider-create-row]',
  templateUrl: './audit-provider-create-row.component.html',
  styleUrls: ['./audit-provider-create-row.component.scss'],
})
export class AuditProviderCreateRowComponent implements OnInit {
  @Input() audit: Audit;
  newValueHtml;

  constructor() {}

  ngOnInit() {
    const {
      internalNotes,
      ...changes
    } = this.audit.auditedChanges;

    this.newValueHtml = obj2Html(changes);
  }
}