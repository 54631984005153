<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link"
       routerLinkActive="active"
       routerLink="{{ uploadPath }}"
       [routerLinkActiveOptions]="{ exact: true }">
      Upload File
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       routerLinkActive="active"
       routerLink="{{ historyPath }}"
       [routerLinkActiveOptions]="{ exact: true }">
      History
    </a>
  </li>
</ul>
