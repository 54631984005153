<div *ngIf="!loading; else: spinner">
  <div class="row">
    <div class="col-4">
      <h2>Referral Task {{ taskId }}</h2>
    </div>
    <div class="col-8">
      <app-client-task-nav [baseUrl]="navBaseUrl" />
    </div>
  </div>
  <div>
    <app-client-task-history-item-list [historyItems]="historyItems" />
  </div>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
