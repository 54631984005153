<div *ngIf="showCeReviewDetails" class="card mt-1">
  <div class="card-body">
    <h4>CE Review Details</h4>
    <app-ce-review-client-prescriber-task
      *ngIf="showClientPrescriberTask"
      [clientPrescriberTask]="capture.clientPrescriberTask"
    />
    <app-ce-review-client-referral-task
      *ngIf="showClientReferralTask"
      [clientReferralTask]="capture.clientReferralTask"
      [capture]="capture"
    />
    <app-ce-review-client-consult-note-task
      *ngIf="showClientConsultNoteTask"
      [clientConsultNoteTask]="capture.clientConsultNoteTask"
    />
  </div>
</div>
