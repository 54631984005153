<div *ngIf="!refreshing; else: spinner" id="consultNoteDetailPage">
  <app-consult-note-title [patientAttachment]="patientAttachment" />
  <app-consult-note-header [patientAttachment]="patientAttachment" />
  <div *ngIf="!loadingHistoryItems; else: spinner" class="patient-attachment-history">
    <table class="table table-striped mt-3">
      <tbody>
        <tr *ngFor="let patientAttachmentHistoryItem of patientAttachmentHistoryItems">
          <td class="highlighted">
            <div>{{ patientAttachmentHistoryItem.createdAt | date: 'MM/dd/yyyy' }}</div>
            <div>{{ patientAttachmentHistoryItem.createdAt | date: 'hh:mm a' }}</div>
          </td>
          <td>
            <div [ngSwitch]="patientAttachmentHistoryItem.action">
              <app-consult-note-history-item-type-created
                *ngSwitchCase="patientAttachmentHistoryItemActions.created"
                [patientAttachmentHistoryItem]="patientAttachmentHistoryItem" />

              <app-consult-note-history-item-type-transitioned
                *ngSwitchCase="patientAttachmentHistoryItemActions.transitioned"
                [patientAttachmentHistoryItem]="patientAttachmentHistoryItem" />

              <app-consult-note-history-item-type-submitted
                *ngSwitchCase="patientAttachmentHistoryItemActions.submitted"
                [patientAttachmentHistoryItem]="patientAttachmentHistoryItem" />

              <app-consult-note-history-item-type-assignment-new
                *ngSwitchCase="patientAttachmentHistoryItemActions.assignmentNew"
                [patientAttachmentHistoryItem]="patientAttachmentHistoryItem" />

              <app-consult-note-history-item-type-assignment-updated
                *ngSwitchCase="patientAttachmentHistoryItemActions.assignmentUpdated"
                [patientAttachmentHistoryItem]="patientAttachmentHistoryItem" />

              <app-consult-note-history-item-type-assignment-removed
                *ngSwitchCase="patientAttachmentHistoryItemActions.assignmentRemoved"
                [patientAttachmentHistoryItem]="patientAttachmentHistoryItem" />

              <app-consult-note-history-item-type-base
                *ngSwitchDefault
                [patientAttachmentHistoryItem]="patientAttachmentHistoryItem" />
            </div>
          </td>
          <td class="highlighted">{{ patientAttachmentHistoryItem | historyItemUserName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
