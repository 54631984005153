import { LimitOffsetPaging } from '../paged-results/limit-offset-paging.model';

export class FileCheckFilters {
  clientIds: number[] = []
}

export class FileCheckResultsListSettings {
  filters: FileCheckFilters;
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
}
