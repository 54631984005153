import { Component } from '@angular/core';
import { ClientTaskHistoryItemTypeBaseComponent } from '../client-task-history-item-type-base/client-task-history-item-type-base.component';

@Component({
  selector: 'app-client-task-history-item-type-created',
  templateUrl: './client-task-history-item-type-created.component.html',
})
export class ClientTaskHistoryItemTypeCreatedComponent extends ClientTaskHistoryItemTypeBaseComponent {
  get title() {
    return 'Created';
  }

  get description() {
    return `New ${this.taskName.toLowerCase()} task Created`;
  }
}
