<form novalidate>
  <div class="row flex-row align-items-end">
    <div class="col" *ngIf="canFilterByClients">
      <div class="my-0 py-0">
        <label for="clientIds"><strong>Clients</strong></label>
        <ng-select
          class="w-100"
          bindValue="id"
          bindLabel="humanizedName"
          name="clientIds"
          placeholder="Filter by Client"
          [multiple]="true"
          [items]="clients"
          (remove)="onFilterChange()"
          (change)="onFilterChange()"
          [(ngModel)]="filters.selectedClientIds"
        />
      </div>
    </div>
    <div class="col">
      <div class="my-0 py-0">
        <label for="patientFirstName"><strong>Patient First Name</strong></label>
        <input
          id="patientFirstName"
          class="form-control"
          name="patientFirstName"
          placeholder="Filter By First Name"
          [(ngModel)]="filters.patientFirstName"
          (input)="onTextFilterChange()">
      </div>
    </div>
    <div class="col">
      <div class="my-0 py-0">
        <label for="patientLastName"><strong>Patient Last Name</strong></label>
        <input
          id="patientLastName"
          class="form-control"
          name="patientLastName"
          placeholder="Filter By Last Name"
          [(ngModel)]="filters.patientLastName"
          (input)="onTextFilterChange()">
      </div>
    </div>
    <div class="col">
      <div class="my-0 py-0">
        <label for="patientDob"><strong>Patient DOB</strong></label>
        <input
          id="patientDob"
          class="form-control"
          type="date"
          name="patientDob"
          [(ngModel)]="filters.patientDob"
          (change)="onFilterChange()"
        >
      </div>
    </div>
    <div class="col">
      <button class="btn btn-info" (click)="onClearClick()">Clear All</button>
    </div>
  </div>
</form>
<div class="mt-2" *ngIf="showTable">
  <table class="table table-bordered table-hover">
    <thead class="table-dark">
      <tr>
        <th>PATIENT</th>
        <th *ngIf="canFilterByClients">CLIENT</th>
        <th>DOB</th>
        <th>MRN</th>
        <th>DOCUMENT</th>
        <th></th>
        <th>SENT AT</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let patientAttachment of patientAttachments">
        <td>{{ patientAttachment.patient.fullName }}</td>
        <td *ngIf="canFilterByClients">{{ patientAttachment.client.name }}</td>
        <td>{{ patientAttachment.patient.dob | date: 'MM/dd/yyyy' }}</td>
        <td>{{ patientAttachment.patient.mrn }}</td>
        <td>{{ patientAttachment.fileName | blankValueDash }}</td>
        <td>
          <a href="/download" *ngIf="patientAttachment.fileName" (click)="download($event, patientAttachment)">
            Download
          </a>
          <span *ngIf="patientAttachment.pdfGenerationPending">
            Preparing
          </span>
        </td>
        <td>
          <div>
            {{ patientAttachment.directMessageSentAt | date: 'MM/dd/yyyy' }} to
            <div>{{ patientAttachment.directMessageSentTo }}</div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
</div>

<div class="mt-2 ms-1" *ngIf="noResults">There are no consult notes.</div>

<div *ngIf="loading" class="mt-2 ms-1"><mat-spinner diameter="30" /></div>
