import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../core/models/user.model';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent implements OnInit {
  @Input() user: User;

  constructor() {}

  ngOnInit() {}
}
