import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { debounce } from 'lodash-es';
import { InternalConsultantCaptureCursorType } from 'app/core/enums/internal-consultant-capture-cursor-type.enum';
import { InternalConsultantCaptureFilters, InternalConsultantCaptureSettings } from 'app/core/models/user-settings/internal-consultant-capture-settings.model';
import { ClaimSourceOptions } from 'app/core/options/claim-source.opts';
import { ClientsService } from 'app/core/services/clients.service';
import { InternalConsultantCapturesService } from 'app/core/services/internal-consultant-captures.service';
import { UserAssignedClientsService } from 'app/core/services/user-assigned-clients.service';
import { CaptureStatusReason } from 'app/core/enums/capture-status-reason.enum';

const CursorTypeOptions = [
  { value: InternalConsultantCaptureCursorType.highestEstimatedValue, display: 'Estimated Value' },
  { value: InternalConsultantCaptureCursorType.highestWipEstimatedValue, display: 'WIP Estimated Value' },
  { value: InternalConsultantCaptureCursorType.mostRecentlyCreated, display: 'Created On' },
  { value: InternalConsultantCaptureCursorType.highestPatientEstimatedValue, display: 'Patient Estimated Value' },
];

const StatusReasonOptions = [
  { value: CaptureStatusReason.additionalReferralDocumentationRequired, display: 'Additional Referral Documentation Required' },
  { value: CaptureStatusReason.emrConsultNoteReviewRequired, display: 'EMR Consult Note Review Required' },
  { value: CaptureStatusReason.prescriberIsCeProvider, display: 'Prescriber is CE Provider' },
];

@Component({
  selector: 'app-internal-consultant-capture-settings',
  templateUrl: './internal-consultant-capture-settings.component.html',
  styleUrls: ['./internal-consultant-capture-settings.component.scss'],
})
export class InternalConsultantCaptureSettingsComponent implements OnInit {
  filters: InternalConsultantCaptureFilters = new InternalConsultantCaptureFilters();
  cursorType: InternalConsultantCaptureCursorType = InternalConsultantCaptureCursorType.highestPatientEstimatedValue;
  statusReasonFilterOptions = StatusReasonOptions;
  clientFilterOptions = [];
  cursorTypeOptions = CursorTypeOptions;
  claimSourceOptions = ClaimSourceOptions;
  assignedUsersFilterOptions = [];

  refreshCaptureCount$: Subject<void> = new Subject();
  updatingCaptureCount = false;
  captureCount = 0;

  debouncedHandleSettingsChange = debounce(
    () => {
      this.handleSettingsChange();
    },
    250,
    { maxWait: 1000 }
  );

  ignoreStatusReasonChange = false;

  @Output() public settingsChange = new EventEmitter();

  constructor(
    private clientsService: ClientsService,
    private internalConsultantCapturesService: InternalConsultantCapturesService,
    private userAssignedClientsService: UserAssignedClientsService
  ) {}

  ngOnInit() {
    this.initialize();
  }

  onFilterChange() {
    this.fireSettingsChange();
  }

  onCursorTypeChange() {
    this.fireSettingsChange();
  }

  onResetClick($event) {
    this.handleResetClick($event);
  }

  private initialize() {
    this.initClientFilterOptions();
    this.initAssignedUsersFilterOptions();
    this.loadUserSettings();
    this.saveUserSettings();
    this.configureRefreshCaptureCount();
    this.refreshCaptureCount();
  }

  private fireSettingsChange() {
    this.debouncedHandleSettingsChange();
  }

  protected handleResetClick($event) {
    $event.preventDefault();
    this.filters = new InternalConsultantCaptureFilters();
    this.cursorType = InternalConsultantCaptureCursorType.highestPatientEstimatedValue;
    this.fireSettingsChange();
  }

  private handleSettingsChange() {
    this.saveUserSettings();
    this.settingsChange.emit();
    this.refreshCaptureCount();
  }

  private initClientFilterOptions() {
    this.clientsService.getList(null, { applicableToReferralClaims: true, ehrAccessOnly: true }).subscribe(result => {
      this.clientFilterOptions = result.clients;
    });
  }

  private initAssignedUsersFilterOptions() {
    this.userAssignedClientsService.getList().subscribe(result => {
      this.assignedUsersFilterOptions = result.users;
    });
  }

  private loadUserSettings() {
    const queueSettings = this.internalConsultantCapturesService.getUserSettings();
    this.filters = queueSettings.filters;
    this.cursorType = queueSettings.cursorType;
  }

  private get currentUserSettings(): InternalConsultantCaptureSettings {
    const filters = Object.assign({}, this.filters);
    return new InternalConsultantCaptureSettings(filters, this.cursorType);
  }

  private saveUserSettings() {
    this.internalConsultantCapturesService.saveUserSettings(this.currentUserSettings);
  }

  private refreshCaptureCount() {
    this.refreshCaptureCount$.next();
  }

  private configureRefreshCaptureCount() {
    // https://www.credera.com/blog/technology-solutions/using-rxjs-switchmap-angular-7-reactive-forms-cancel-pending-requests/

    this.refreshCaptureCount$
      .pipe(
        tap(() => {
          this.updatingCaptureCount = true;
        }),
        switchMap(() => this.internalConsultantCapturesService.getCount())
      )
      .subscribe(({ count }) => {
        this.updatingCaptureCount = false;
        this.captureCount = count;
      });
  }
}