import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { ThirdPartyAdminister } from '../models/third-party-administer.model';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ThirdPartyAdministersService extends ApiService {
  constructor(authService: AuthenticationService, private http: HttpClient) {
    super();
  }

  getList(): Observable<{ thirdPartyAdministers: ThirdPartyAdminister[] }> {
    return this.http.get<{ thirdPartyAdministers: ThirdPartyAdminister[] }>(
      `${environment.captureApi.url}/third_party_administers`
    );
  }
}
