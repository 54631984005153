import { Component, Input } from '@angular/core';
import { ClientChore } from 'app/core/models/client-chore.model';
import { DownloadService } from 'app/core/services/download.service';
import { ClientChoresService } from 'app/core/services/client-chores.service';

@Component({
  selector: 'app-client-chore-list',
  templateUrl: './client-chore-list.component.html',
  styleUrls: ['./client-chore-list.component.scss']
})
export class ClientChoreListComponent {
  @Input() clientChores: ClientChore[] = [];
  @Input() canManageChores = false;

  constructor(
    private clientChoresService: ClientChoresService,
    private downloadService: DownloadService,
  ) { }

  onDownloadClick($event: MouseEvent, fileUrl: string) {
    $event.preventDefault();
    this.downloadService.download(fileUrl);
  }

  onFailClick($event: MouseEvent, clientChoreId: number) {
    $event.preventDefault();

    if (confirm("Are you sure you want to fail this chore?  Records may be left in an invalid state.")) {
      this.clientChoresService
        .fail(clientChoreId)
        .subscribe((updatedClientChore) => {
          this.replaceUpdatedClientChore(updatedClientChore);
        });
    }
  }

  onRestartClick($event: MouseEvent, clientChoreId: number) {
    $event.preventDefault();

    this.clientChoresService
      .restart(clientChoreId)
      .subscribe((updatedClientChore) => {
        this.replaceUpdatedClientChore(updatedClientChore);
      });
  }

  onReviewChange($event: boolean, clientChore: ClientChore) {
    clientChore.reviewed = $event;

    this.clientChoresService
      .review(clientChore.id, clientChore.reviewed)
      .subscribe((updatedClientChore) => {
        this.replaceUpdatedClientChore(updatedClientChore);
      });
  }

  private replaceUpdatedClientChore(updatedClientChore: ClientChore) {
    const index = this.clientChores.findIndex(chore => chore.id === updatedClientChore.id);

    if (index > -1) {
      this.clientChores[index] = updatedClientChore;
    }
  }
}
