import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PrescriberMatchVerification } from '../models/prescriber-match-verification.model';
import { ApiService } from './api.service';

@Injectable()
export class PrescriberMatchVerificationsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(patientAttachmentId: number): Observable<PrescriberMatchVerification[]> {
    return this.http.get<PrescriberMatchVerification[]>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/prescriber_match_verifications`,
      {}
    );
  }

  create(
    patientAttachmentId: number,
    verifyingCaptureId: number,
    { matches, npi }: PrescriberMatchVerification
  ): Observable<PrescriberMatchVerification> {
    return this.http.post<PrescriberMatchVerification>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/prescriber_match_verifications`,
      {
        prescriberMatchVerification: { patientAttachmentId, verifyingCaptureId, matches, npi },
      }
    );
  }

  update(
    patientAttachmentId: number,
    id: number,
    verifyingCaptureId: number,
    { matches, npi }: PrescriberMatchVerification
  ): Observable<PrescriberMatchVerification> {
    return this.http.patch<PrescriberMatchVerification>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/prescriber_match_verifications/${id}`,
      {
        prescriberMatchVerification: {
          verifyingCaptureId,
          matches,
          npi,
        },
      }
    );
  }
}
