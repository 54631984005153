import { Component, Input, OnInit } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { keyedReasonOptions } from '../../../core/options/inbound-fax-status-reasons.opts';
import { OutboundFaxStatus } from '../../../core/enums/outbound-fax-status.enum';

@Component({
  selector: 'app-inbound-fax-view-processed',
  templateUrl: './inbound-fax-view-processed.component.html'
})
export class InboundFaxViewProcssedComponent implements OnInit {
  keyedReasonOptions = keyedReasonOptions;
  outboundFaxStatuses = OutboundFaxStatus;

  @Input() inboundFax: InboundFax;

  constructor() {}

  ngOnInit() {}
}
