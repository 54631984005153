import { Component, Input, OnInit } from '@angular/core';
import { ClientPrescriberTask } from '../../../core/models/client-prescriber-task.model';
import { ClientPrescriberTaskStatus } from '../../../core/enums/client-prescriber-task-status.enum';

@Component({
  selector: 'app-client-prescriber-task-instructions',
  templateUrl: './client-prescriber-task-instructions.component.html',
  styleUrls: ['./client-prescriber-task-instructions.component.scss'],
})
export class ClientPrescriberTaskInstructionsComponent implements OnInit {
  @Input() task: ClientPrescriberTask;

  constructor() {}

  ngOnInit() {}

  get hideInstructions(): boolean {
    return this.task.status !== ClientPrescriberTaskStatus.open;
  }
}
