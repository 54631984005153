import { Component } from '@angular/core';
import { CaptureHistoryItemTypeBaseComponent } from '../capture-history-item-type-base/capture-history-item-type-base.component';


@Component({
  selector: 'app-capture-history-item-type-client-chore-updated',
  templateUrl: './capture-history-item-type-client-chore-updated.component.html',
})
export class CaptureHistoryItemTypeClientChoreUpdatedComponent extends CaptureHistoryItemTypeBaseComponent {
  get title() {
    return "Updated By Chore";
  }

  get choreId() {
    return this.captureHistoryItem.data?.choreId || 'N/A';
  }

  get choreDisplayName() {
    return this.captureHistoryItem.data?.choreDisplayName;
  }

  get note() {
    return this.captureHistoryItem.data?.note;
  }

  get error() {
    return this.captureHistoryItem.data?.error;
  }
}
