import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'app/core/models/client.model';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-client-name',
  templateUrl: './client-name.component.html',
  styleUrls: ['./client-name.component.scss'],
})
export class ClientNameComponent implements OnInit {
  @Input() client: Client;

  canViewClient = false;

  constructor(
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.canViewClient = this.authService.isCaptureAdminUser;
  }
}
