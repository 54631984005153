export enum InternalConsultantCaptureFilterName {
  clientIds = 'client_ids[]',
  reasons = 'reasons[]',
  sources = 'sources[]',
  specialtyStore = 'specialty_store',
  expiringWithinDays = 'expiring_within_days',
  highValue = 'high_value',
  assignedUserIds = 'assigned_user_ids[]',
  activeClientsOnly = 'active_clients_only',
}
