<table class="table table-bordered table-hover">
  <thead>
    <tr>
      <th>Full Name</th>
      <th>Active?</th>
      <th>Go Live Date</th>
      <th>Name</th>
      <th>City, State</th>
      <th>Claim Identifier Prefixes</th>
      <th>HRSA IDs</th>
      <th>Fax Local</th>
      <th>Fax Tollfree</th>
      <th>Assigned Users</th>
      <th>Entity Types</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let client of clients">
      <td>
        <a routerLink="/capture-admin/client-management/clients/{{client.id}}">
          {{ client.humanizedName }}
        </a>
        <div *ngIf="canManageClients">
          <a href="#viewAsClient" (click)="onViewPortalClick($event, client)">View Portal</a>
          |
          <a href="#" routerLink="/capture-admin/client-management/clients/{{ client.id }}/edit" routerLinkActive="active">
            Edit Details
          </a>
          |
          <a href="#" routerLink="/capture-admin/client-management/clients/{{ client.id }}/edit-configuration-options" routerLinkActive="active">
            Edit Config
          </a>
        </div>
      </td>
      <td>{{ clientActive(client) ? 'YES' : 'NO' }}</td>
      <td>{{ client.goLiveDate | date: 'MM/dd/yyyy'}}</td>
      <td>{{ client.name }}</td>
      <td>
        {{ client.city }}<span *ngIf="client.city && client.state">, </span>
        {{ client.state }}
        <span *ngIf="!client.city && !client.state"><app-blank-value-dash /></span>
      </td>
      <td>{{ client.effectiveClaimIdentifierPrefixes.join(', ') | blankValueDash }}</td>
      <td>{{ client.effectiveHrsaIdStrings.join(', ') | blankValueDash }}</td>
      <td><app-phone-number [number]="client.faxLocal" /></td>
      <td><app-phone-number [number]="client.faxTollfree" /></td>
      <td>{{ assignedUsers(client) }}</td>
      <td>{{ client.entityTypes | joinList }}</td>
    </tr>
  </tbody>
</table>
