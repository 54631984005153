export enum ProviderOfficeStatus {
  validFax = 'valid_fax',
  verified = 'verified',
  unresponsiveOffice = 'unresponsive_office',
  unresponsiveProvider = 'unresponsive_provider',
  testing = 'testing',
  faxFailed = 'fax_failed',
  providerNoLongerAtOffice = 'provider_no_longer_at_office',
  officeOptedOut = 'office_opted_out',
  notAMedicalOffice = 'not_a_medical_office',
  providerNotAtOffice = 'provider_not_at_office'
}
