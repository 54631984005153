import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ClientPrescriberTaskDetailsComponent } from './components/client-prescriber-task-details/client-prescriber-task-details.component';
import { ClientPrescriberTaskHeaderComponent } from './components/client-prescriber-task-header/client-prescriber-task-header.component';
import { ClientPrescriberTaskInstructionsComponent } from './components/client-prescriber-task-instructions/client-prescriber-task-instructions.component';
import { ClientPrescriberTaskVerificationComponent } from './components/client-prescriber-task-verification/client-prescriber-task-verification.component';
import { ClientConsultNoteTaskHeaderComponent } from './components/client-consult-note-task-header/client-consult-note-task-header.component';
import { ClientConsultNoteTaskClosedComponent } from './components/client-consult-note-task-closed/client-consult-note-task-closed.component';
import { ClientConsultNoteTaskVerificationComponent } from './components/client-consult-note-task-verification/client-consult-note-task-verification.component';
import { ClientConsultNoteTaskOptionsComponent } from './components/client-consult-note-task-options/client-consult-note-task-options.component';
import { ClientConsultNoteTaskDatesComponent } from './components/client-consult-note-task-dates/client-consult-note-task-dates.component';
import { ClientConsultNoteTaskDateComponent } from './components/client-consult-note-task-date/client-consult-note-task-date.component';
import { ClientConsultNoteTaskPrescriptionsComponent } from './components/client-consult-note-task-prescriptions/client-consult-note-task-prescriptions.component';
import { ClientConsultNoteTaskActionsComponent } from './components/client-consult-note-task-actions/client-consult-note-task-actions.component';
import { ClientConsultNoteTaskDetailsComponent } from './components/client-consult-note-task-details/client-consult-note-task-details.component';
import { ClientConsultNoteTaskReferralsComponent } from './components/client-consult-note-task-referrals/client-consult-note-task-referrals.component';
import { ClientTaskRejectionReasonsComponent } from './components/client-task-rejection-reasons/client-task-rejection-reasons.component';
import { ClientReferralTaskOptionsComponent } from './components/client-referral-task-options/client-referral-task-options.component';
import { ClientReferralTaskOptionReasonAdditionalFieldsComponent } from './components/client-referral-task-option-reason-additional-fields/client-referral-task-option-reason-additional-fields.component';
import { ClientReferralTaskVerificationComponent } from './components/client-referral-task-verification/client-referral-task-verification.component';
import { ClientReferralTaskDatesComponent } from './components/client-referral-task-dates/client-referral-task-dates.component';
import { ClientReferralTaskDateComponent } from './components/client-referral-task-date/client-referral-task-date.component';
import { ClientReferralTaskPrescriptionsComponent } from './components/client-referral-task-prescriptions/client-referral-task-prescriptions.component';
import { ClientReferralTaskActionsComponent } from './components/client-referral-task-actions/client-referral-task-actions.component';
import { ClientReferralTaskDetailsComponent } from './components/client-referral-task-details/client-referral-task-details.component';
import { ClientReferralTaskHeaderComponent } from './components/client-referral-task-header/client-referral-task-header.component';
import { ClientReferralTaskClosedComponent } from './components/client-referral-task-closed/client-referral-task-closed.component';
import { ClientTaskRejectPrescriptionComponent } from './components/client-task-reject-prescription/client-task-reject-prescription.component';
import { ClientTaskSubmittedPrescriptionComponent } from './components/client-task-submitted-prescription/client-task-submitted-prescription.component';
import { ClientConsultNoteTaskComponent } from './pages/client-consult-note-task/client-consult-note-task.component';
import { ClientReferralTaskComponent } from './pages/client-referral-task/client-referral-task.component';
import { ClientPrescriberTaskComponent } from './pages/client-prescriber-task/client-prescriber-task.component';
import { PatientPrescriberConsultNotesComponent } from './components/patient-prescriber-consult-notes/patient-prescriber-consult-notes.component';
import { ClientReferralTaskHistoryComponent } from './pages/client-referral-task-history/client-referral-task-history.component';
import { ClientConsultNoteTaskHistoryComponent } from './pages/client-consult-note-task-history/client-consult-note-task-history.component';
import { ClientPrescriberTaskHistoryComponent } from './pages/client-prescriber-task-history/client-prescriber-task-history.component';
import { ClientTaskHistoryItemTitleComponent } from './components/client-task-history-item-title/client-task-history-item-title.component';
import { ClientTaskHistoryItemBodyComponent } from './components/client-task-history-item-body/client-task-history-item-body.component';
import { ClientTaskHistoryItemTypeBaseComponent } from './components/client-task-history-item-type-base/client-task-history-item-type-base.component';
import { ClientTaskHistoryItemTypeCreatedComponent } from './components/client-task-history-item-type-created/client-task-history-item-type-created.component';
import { ClientTaskHistoryItemTypeTransitionedComponent } from './components/client-task-history-item-type-transitioned/client-task-history-item-type-transitioned.component';
import { ClientTaskHistoryItemListComponent } from './components/client-task-history-item-list/client-task-history-item-list.component';
import { ClientTaskNavComponent } from './components/client-task-nav/client-task-nav.component';
import { ClientTaskHistoryItemTypeSubmittedComponent } from './components/client-task-history-item-type-submitted/client-task-history-item-type-submitted.component';
import { ClientTaskHistoryItemRejectedPrescriptionsComponent } from './components/client-task-history-item-rejected-prescriptions/client-task-history-item-rejected-prescriptions.component';
import { ClientConsultNoteTaskRelatedTasksComponent } from './components/client-consult-note-task-related-tasks/client-consult-note-task-related-tasks.component';
import { ClientReferralTaskRelatedTasksComponent } from './components/client-referral-task-related-tasks/client-referral-task-related-tasks.component';
import { ClientPrescriberTaskRelatedTasksComponent } from './components/client-prescriber-task-related-tasks/client-prescriber-task-related-tasks.component';
import { ClientTaskEncountersComponent } from './components/client-task-encounters/client-task-encounters.component';
import { PatientPrescriberActiveNoteComponent } from './components/patient-prescriber-active-note/patient-prescriber-active-note.component';

@NgModule({
  declarations: [
    ClientPrescriberTaskDetailsComponent,
    ClientPrescriberTaskHeaderComponent,
    ClientPrescriberTaskInstructionsComponent,
    ClientPrescriberTaskVerificationComponent,
    ClientConsultNoteTaskHeaderComponent,
    ClientConsultNoteTaskClosedComponent,
    ClientConsultNoteTaskVerificationComponent,
    ClientConsultNoteTaskOptionsComponent,
    ClientConsultNoteTaskDatesComponent,
    ClientConsultNoteTaskDateComponent,
    ClientConsultNoteTaskPrescriptionsComponent,
    ClientConsultNoteTaskActionsComponent,
    ClientConsultNoteTaskDetailsComponent,
    ClientConsultNoteTaskReferralsComponent,
    ClientReferralTaskOptionsComponent,
    ClientReferralTaskOptionReasonAdditionalFieldsComponent,
    ClientReferralTaskVerificationComponent,
    ClientReferralTaskDatesComponent,
    ClientReferralTaskDateComponent,
    ClientReferralTaskPrescriptionsComponent,
    ClientReferralTaskActionsComponent,
    ClientReferralTaskDetailsComponent,
    ClientReferralTaskHeaderComponent,
    ClientReferralTaskClosedComponent,
    ClientTaskRejectionReasonsComponent,
    ClientTaskRejectPrescriptionComponent,
    ClientTaskSubmittedPrescriptionComponent,
    PatientPrescriberConsultNotesComponent,
    ClientConsultNoteTaskComponent,
    ClientReferralTaskComponent,
    ClientPrescriberTaskComponent,
    ClientReferralTaskHistoryComponent,
    ClientConsultNoteTaskHistoryComponent,
    ClientPrescriberTaskHistoryComponent,
    ClientTaskHistoryItemListComponent,
    ClientTaskHistoryItemTitleComponent,
    ClientTaskHistoryItemBodyComponent,
    ClientTaskHistoryItemTypeBaseComponent,
    ClientTaskHistoryItemTypeCreatedComponent,
    ClientTaskHistoryItemTypeTransitionedComponent,
    ClientTaskHistoryItemTypeSubmittedComponent,
    ClientTaskNavComponent,
    ClientTaskHistoryItemRejectedPrescriptionsComponent,
    ClientConsultNoteTaskRelatedTasksComponent,
    ClientReferralTaskRelatedTasksComponent,
    ClientPrescriberTaskRelatedTasksComponent,
    ClientTaskEncountersComponent,
    PatientPrescriberActiveNoteComponent
  ],
  imports: [CommonModule, SharedModule],
})
export class ClientTasksModule {}
