import { OnInit, OnDestroy, Directive } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { IccImport } from '../../../core/models/icc/icc-import.model';
import { DownloadService } from '../../../core/services/download.service';
import { ClientsService } from '../../../core/services/clients.service';
import { IccImportRenameModalComponent } from '../../components/icc-import-rename-modal/icc-import-rename-modal.component';
import { IccImportFilters } from '../../../core/models/icc/icc-import-settings.model';

@Directive()
export abstract class IccImportHistoryComponent implements OnInit, OnDestroy {
  importsAssociatedToClients = true;

  loading = false;
  imports: IccImport[] = [];
  nextCheckTimeout = null;
  clientFilterOptions = [];
  filters: IccImportFilters = new IccImportFilters();

  importRenameModal: NgbModalRef = null;

  pageTitle = 'Imports';

  showThirdPartyAdminister = false;

  abstract getImports(filters: IccImportFilters): Observable<any>;
  abstract getImport(importId: number): Observable<IccImport>;
  abstract cancelImport(importId: number): Observable<IccImport>;
  abstract updateImport(importId: number, name: string): Observable<IccImport>;
  abstract prepareImportResults(data: any): IccImport[];

  constructor(
    protected downloadService: DownloadService,
    protected modalService: NgbModal,
    protected clientsService: ClientsService
  ) {}

  ngOnInit() {
    this.loadImports(true);
    this.initClientFilterOptions();
  }

  ngOnDestroy() {
    clearTimeout(this.nextCheckTimeout);
  }

  onCancelClick($event, iccImport: IccImport) {
    $event.preventDefault();
    this.cancelImport(iccImport.id).subscribe(invoiceImport => {
      const index = this.imports.findIndex(imp => imp.id === invoiceImport.id);

      if (index > -1) {
        this.imports[index] = invoiceImport;
        this.imports = [...this.imports];
      }
    });
  }

  onDownloadOriginalClick($event, iccImport: IccImport) {
    $event.preventDefault();
    this.getImport(iccImport.id).subscribe(invoiceimport => {
      this.downloadService.download(invoiceimport.fileUrl);
    });
  }

  onDownloadSkippedClick($event, iccImport: IccImport) {
    $event.preventDefault();
    this.getImport(iccImport.id).subscribe(invoiceimport => {
      this.downloadService.download(invoiceimport.skippedFileUrl);
    });
  }

  onDownloadFailedClick($event, iccImport: IccImport) {
    $event.preventDefault();
    this.getImport(iccImport.id).subscribe(invoiceimport => {
      this.downloadService.download(invoiceimport.failedFileUrl);
    });
  }

  onImportNameEditClick($event, iccImport: IccImport) {
    $event.preventDefault();

    this.importRenameModal = this.modalService.open(IccImportRenameModalComponent, {
      windowClass: 'import-rename-modal',
    });

    this.importRenameModal.componentInstance.iccImport = iccImport;
    this.importRenameModal.componentInstance.updateImport = this.updateImport.bind(this);
  }

  private loadImports(loading) {
    this.loading = loading;
    this.getImports(this.filters).subscribe(data => {
      this.imports = this.prepareImportResults(data);
      this.loading = false;

      const anyActive: boolean = this.imports.some(invoiceImport => invoiceImport.active);

      if (anyActive) {
        this.nextCheckTimeout = setTimeout(() => this.loadImports(false), 500);
      }
    });
  }

  private initClientFilterOptions() {
    this.clientsService.getList(null, { applicableToIccClaims: true }).subscribe(result => {
      this.clientFilterOptions = result.clients;
    });
  }

  onFilterChange() {
    this.loadImports(true);
  }
}
