import { Component, Input, OnInit } from '@angular/core';
import { Prescription } from '../../../core/models/prescription.model';

@Component({
  selector: 'app-prescription-name',
  templateUrl: './prescription-name.component.html',
})
export class PrescriptionNameComponent implements OnInit {
  @Input() prescription: Prescription;

  constructor() {}

  ngOnInit() {}

  get name() {
    const prescriptionFamily = this.prescription.prescriptionFamily;
    let names = [prescriptionFamily.humanizedName];
    names = names.concat(this.prescription.prescriptionFamily.akas);
    return names.slice(0, 5).join(', ');
  }

  get products() {
    return this.prescription.drugs.map(d => d.name).join(', ');
  }
}
