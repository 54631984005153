<ng-container>
  <div class="row">
    <div class="col-12">
      <h3>
        Add New Client
      </h3>
    </div>
  </div>

  <app-client-form [client]="client" [mode]="formModes.create" />
</ng-container>
