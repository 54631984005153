<div id="validateDrugMatch" class="card mt-1">
  <app-validation-header
    [icon]="'local_pharmacy'"
    [title]="'Validate Drug Details'"
    [collapsed]="collapsed"
    (collapseChange)="onCollapseChange($event)"
  />
  <div class="card-body p-1">
    <div class="mt-1">
      <app-validate-drug-match-form
        [patientAttachment]="patientAttachment"
        [collapsed]="collapsed"
      />
    </div>
  </div>
</div>
