import { UserQueueSettings } from '../../interfaces/user-queue-settings';
import { LimitOffsetPaging } from '../paged-results/limit-offset-paging.model';

export class FaxFilters {
  selectedClientIds = [];
  selectedStatuses = [];
  selectedAssignedToIds = [];
  includeArchived = false;
}

export class FaxListSettings {
  filters: FaxFilters = new FaxFilters();
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
}

export class FaxQueueSettings extends FaxListSettings implements UserQueueSettings {
  position: number;
}
