import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  StartAtSuffix,
  EndAtSuffix,
  ValidationError,
  ValueType,
  isPersisted,
  AttributeToControlSuffix,
} from '../../lib/client-configuration';
import { DateUtils } from '../../../core/lib/date-utils';
import { ClientConfigurationOption } from '../../../core/models/client-configuration-option.model';

const StartAtValidatedAttributes = ['startAt', 'value', 'base'];

@Component({
  selector: 'app-client-configuration-dated-value',
  templateUrl: './client-configuration-dated-value.component.html',
  styleUrls: ['./client-configuration-dated-value.component.scss'],
})
export class ClientConfigurationDatedValueComponent implements OnInit {
  _configOption: ClientConfigurationOption;
  _errors: ValidationError[] = [];
  @Input() deactivated: boolean;
  @Input() identifier: string;
  @Input() definition: { dataType: string; editable: boolean; suboptionType: string };
  @Output() optionChange: EventEmitter<ClientConfigurationOption> = new EventEmitter<ClientConfigurationOption>();

  validDefinition = true;
  deactivating = false;

  canDelete = false;
  canDeactivate = false;
  canCancelDeactivate = false;

  startAtDisabled = false;
  endAtDisabled = true;

  startAtSuffix = StartAtSuffix;
  endAtSuffix = EndAtSuffix;

  startAtValidationError: ValidationError = null;
  endAtValidationError: ValidationError = null;

  constructor() {}

  get errors(): ValidationError[] {
    return this._errors;
  }

  @Input()
  set errors(errors: ValidationError[]) {
    this._errors = errors;
    this.refreshValidationErrors(this.errors);
  }

  get configOption(): ClientConfigurationOption {
    return this._configOption;
  }

  @Input()
  set configOption(configOption: ClientConfigurationOption) {
    this._configOption = configOption;
    this.refreshFlags();
  }

  ngOnInit(): void {
    if (this.definition.dataType !== ValueType.bool) {
      this.validDefinition = false;
    }
  }

  onStartAtChange(value: string) {
    this.handleDateChange('startAt', value);
  }

  onEndAtChange(value: string) {
    this.handleDateChange('endAt', value);
  }

  onDeactivateClick($event) {
    $event.preventDefault();
    this.deactivating = true;
    this.configOption.value = false;
    this.configOption.endAt = null;
    this.updateConfigOption();
  }

  onCancellDeactivateClick($event) {
    $event.preventDefault();
    this.deactivating = false;
    this.configOption.value = true;
    this.configOption.endAt = null;
    this.updateConfigOption();
  }

  private handleDateChange(attr: string, value: string) {
    if (DateUtils.isValid(value)) {
      this.configOption[attr] = new Date(value);
    } else {
      this.configOption[attr] = null;
    }
    this.updateConfigOption();
  }

  private updateConfigOption() {
    const modifiedConfigOption: ClientConfigurationOption = Object.assign({}, this.configOption);
    this.optionChange.emit(modifiedConfigOption);
  }

  private refreshFlags() {
    const persisted = isPersisted(this.configOption);

    this.canDelete = !persisted;
    this.canDeactivate = persisted && !this.deactivated && !this.deactivating;
    this.canCancelDeactivate = !this.deactivated && this.deactivating;

    this.startAtDisabled = this.deactivated || this.deactivating;
    this.endAtDisabled = this.deactivated || !this.deactivating;
  }

  private refreshValidationErrors(errors: ValidationError[]) {
    this.startAtValidationError = null;
    this.endAtValidationError = null;

    StartAtValidatedAttributes.every(attr => {
      const identifier = `${this.identifier}${AttributeToControlSuffix[attr]}`;
      this.startAtValidationError = errors.find(err => err.identifier === identifier);
      if (this.startAtValidationError) {
        return false;
      } else {
        return true;
      }
    });

    const endAtIdentifier = `${this.identifier}${AttributeToControlSuffix['endAt']}`;
    this.endAtValidationError = errors.find(err => err.identifier === endAtIdentifier);
  }
}
