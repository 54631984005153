import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ClientsService } from 'app/core/services/clients.service';
import { map } from 'rxjs/operators';

export class DeactivatedClientBaseGuard  {

  constructor(
    protected redirectTo: string,
    protected clientService: ClientsService,
    protected router: Router,
  ) {}

  public canActivate() {
    return this.clientService.viewing().pipe(
      map(client => {
        if (client && !client.active) {
          this.router.navigateByUrl(this.redirectTo);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}

