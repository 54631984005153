import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Capture } from '../../../core/models/capture.model';
import { CaptureActivity } from '../../../core/models/capture-activity.model';
import { CaptureService } from '../../../core/services/capture.service';
import { CaptureActivityActions } from '../../../core/enums/capture-activity-actions.enum';
import { CaptureSnapshot } from '../../../core/models/capture-snapshot.model';
import { HttpErrorResponse } from '@angular/common/http';
import { UserPresenceService } from 'app/core/services/user-presence.service';

@Component({
  selector: 'app-capture-history',
  templateUrl: './capture-history.component.html',
  styleUrls: ['./capture-history.component.scss'],
})
export class CaptureHistoryComponent implements OnInit, OnDestroy {
  captureId: string;
  capture: Capture;
  captureActivities: CaptureActivity[] = [];
  captureActivityActions = CaptureActivityActions;

  loadingCapture = false;
  loadingActivities = false;

  captureChangedSubscription = null;

  constructor(
    private route: ActivatedRoute,
    private captureService: CaptureService,
    private router: Router,
    private titleService: Title,
    private userPresenceService: UserPresenceService
  ) {}

  ngOnInit() {
    this.captureId = this.route.snapshot.paramMap.get('id');

    this.loadCapture(this.captureId);
    this.loadCaptureActivities(this.captureId);

    this.captureChangedSubscription = this.captureService.captureChanged.subscribe(({ capture }) => {
      // is it our capture?
      if (capture.id === this.capture.id) {
        this.capture = capture;
        this.loadCaptureActivities(capture.id);
      }
    });

    this.userPresenceService.subscribeAndJoin("capture", this.captureId);
  }

  ngOnDestroy() {
    if (this.captureChangedSubscription) {
      this.captureChangedSubscription.unsubscribe();
    }

    this.userPresenceService.leaveAndUnsubscribe("capture", this.captureId);
  }

  onSnapshotActivityComplete(captureSnapshot: CaptureSnapshot) {
    const index = this.captureActivities.findIndex(ca => ca.activity.id === captureSnapshot.id);

    if (index > -1) {
      this.captureActivities[index].activity = captureSnapshot;
    }
  }

  getUserNameFromCaptureActivity(captureActivity) {
    if (captureActivity.user && captureActivity.user.username) {
      return captureActivity.user.username;
    } else {
      return 'System';
    }
  }

  private loadCapture(captureId): void {
    this.loadingCapture = true;
    this.captureService.getCapture(captureId).subscribe(
      capture => {
        this.capture = capture;
        this.loadingCapture = false;
        this.setTitle();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
  }

  private loadCaptureActivities(captureId): void {
    this.loadingActivities = true;
    this.captureService.getCaptureActivities(captureId).subscribe(
      captureActivities => {
        this.loadingActivities = false;
        this.captureActivities = captureActivities;
      },
      err => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else {
          console.error(err);
        }
      }
    );
  }

  private setTitle() {
    const title = `R1 340B Recovery - ${this.capture.candidate.claimIdentifier} | History`;
    this.titleService.setTitle(title);
  }
}
