<div class="card mt-1">
  <div class="card-body">

    <div class="row">
      <div class="col-8">
        <h5>Consult Note Reference</h5>
      </div>

      <div class="col-4 ehr-consult-note-reference-actions">
        <a href="javascript:;" *ngIf="!ehrConsultNoteReference.frozen" (click)="onEditClick()">
          Edit Consult Note Reference
        </a>
      </div>
    </div>

    <ng-container *ngIf="!editing">
      <div>
        <strong>Consult Note Name</strong>
      </div>

      <div>
        {{ ehrConsultNoteReference.name }}
      </div>

      <div class="mt-2">
        <strong>Client Note</strong>
      </div>

      <div>
        {{ ehrConsultNoteReference.notes | blankValueDash }}
      </div>

      <div class="mt-2">
        <strong>Created</strong>
      </div>

      <div>
        {{ ehrConsultNoteReference.createdAt | date: 'M/d/yyyy h:mm a' }} -
        {{ ehrConsultNoteReference.user.email }}
      </div>
    </ng-container>

    <app-ehr-consult-note-reference-form
      *ngIf="editing"
      [ehrConsultNoteReference]="ehrConsultNoteReference"
      [errors]="ehrConsultNoteReferenceErrors"
      (saved)="onEditSaved($event)"
      (canceled)="onEditCanceled()" />
  </div>
</div>
