import { Component, OnInit } from '@angular/core';
import { PatientInteractionLocationsService } from 'app/core/services/patient-interaction-locations.service';
import { PatientInteractionLocation } from 'app/core/models/patient-interaction-location.model';
import { PatientInteractionLocationEligibilityStatus } from 'app/core/enums/patient-interaction-location-eligibility-status.enum';
import {
  PatientInteractionLocationFilters,
} from 'app/core/models/user-settings/patient-interaction-location-settings.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';

@Component({
  selector: 'app-patient-interaction-locations-new-locations-section',
  templateUrl: './patient-interaction-locations-new-locations-section.component.html',
})
export class PatientInteractionLocationsNewLocationsSectionComponent implements OnInit {
  patientInteractionLocations: PatientInteractionLocation[] = [];
  loading = true;
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;

  constructor(
    private patientInteractionLocationsService: PatientInteractionLocationsService,
  ) {
    this.paging.pageSize = 20;
  }

  ngOnInit() {
    this.loadPatientInteractionLocations();
  }

  onPageChange(_page: number) {
    this.loadPatientInteractionLocations();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadPatientInteractionLocations();
  }

  private loadPatientInteractionLocations() {
    const filters = new PatientInteractionLocationFilters();
    filters.eligibilityStatuses = [PatientInteractionLocationEligibilityStatus.new];

    this.patientInteractionLocationsService.getList(this.paging, filters).subscribe(result => {
      this.patientInteractionLocations = result.patientInteractionLocations;
      this.paging = result.meta.paging;
      this.loading = false;
    });
  }
}
