<div class="text-nowrap" [ngClass]="{'text-danger': !!errorMessage}">
  {{ displayStatus | titlecase }}
  <fa-icon
    class="icon"
    *ngIf="!!errorMessage"
    [icon]="['fas', 'info-circle']"
    placement="bottom"
    [ngbTooltip]="errorMessage"
  />
</div>
