/* eslint-disable @angular-eslint/component-selector */

import { Component, Input, OnInit } from '@angular/core';
import { Audit } from 'app/core/models/audit.model';
import { obj2Html } from '../../../core/lib/js-utils';

@Component({
  selector: '[app-audit-office-update-row]',
  templateUrl: './audit-office-update-row.component.html',
  styleUrls: ['./audit-office-update-row.component.scss'],
})
export class AuditOfficeUpdateRowComponent implements OnInit {
  @Input() audit: Audit;
  newValueHtml;
  previousValueHtml;

  constructor() {}

  ngOnInit() {
    const newValues = {};
    const previousValues = {};

    for(const [key, value] of Object.entries(this.audit.auditedChanges)) {
      newValues[key] = value[1] || '--';
      previousValues[key] = value[0] || '--';
    }

    this.newValueHtml = obj2Html(newValues);
    this.previousValueHtml = obj2Html(previousValues);
  }
}
