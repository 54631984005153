import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare let Beacon: any;

@Injectable()
export class HelpScoutService {
  initialized = false;

  initialize() {
    if (this.configured && !this.initialized) {
      Beacon('init', environment.helpscoutApi.token);
      this.initialized = true;
    }
  }

  destroy() {
    if (this.configured && this.initialized) {
      this.initialized = false;
      Beacon('destroy');
    }
  }

  get configured() {
    return environment.helpscoutApi && environment.helpscoutApi.token;
  }
}
