import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { ClientsService } from 'app/core/services/clients.service';
import { Client } from 'app/core/models/client.model';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';

@Component({
  selector: 'app-capture-admin-clients',
  templateUrl: './capture-admin-clients.component.html',
})
export class CaptureAdminClientsComponent implements OnInit, AfterViewInit {
  clients: Client[] = [];
  loading = true;
  canManageClients = false;

  constructor(
    private clientsService: ClientsService,
    private authService: AuthenticationService,
    private element: ElementRef
  ) {}

  ngOnInit() {
    this.canManageClients = this.authService.hasPermissionTo(UserRoleName.manageClients);
    this.loadClients();
  }

  // Since we sprinkle a bit of bootstrap and non-bootstrap css,
  // the width of the row that display header and add new client
  // get expanded to where the button no longer appears on the
  // screen. Resetting the row width to the boundling client width
  // was the easiest fix.
  ngAfterViewInit() {
    const row = this.element.nativeElement.querySelector('.row');
    row.style.width = row.getBoundingClientRect().width + 'px';
  }

  private loadClients() {
    this.loading = true;

    this.clientsService.getList().subscribe(result => {
      this.clients = result.clients;
      this.loading = false;
    });
  }
}
