import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { isNull } from 'lodash-es';
import { ApiService } from './api.service';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { Cursor, CursorBasedPagingMeta, NextResult } from '../models/paged-results/cursor-based-paging.model';
import { ConsultNoteQueueResult } from '../models/consult-note-lists/consult-note-queue-result.model';
import { ConsultNoteQueueCursorType } from '../enums/consult-note-queue-cursor-type.enum';
import { environment } from '../../../environments/environment';
import {
  ConsultNoteQueueFilters,
  ConsultNoteQueueSettings,
} from '../models/user-settings/consult-note-queue-settings.model';
import { ConsultNoteQueueFilterName } from '../enums/consult-note-queue-filter-name.enum';
import { YesNo } from '../enums/yes-no.enum';
import { CursorParams } from '../enums/cursor-params.enum';
import { UserSettingsService } from './user-settings.service';
import { UserSettings } from '../enums/user-settings.enum';
import { PatientAttachment } from '../models/patient-attachment.model';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { PatientAttachmentStatus } from '../enums/patient-attachment-status.enum';

export class PagedConsultNoteQueueResults
  implements PagedResults<ConsultNoteQueueResult, CursorBasedPagingMeta<ConsultNoteQueueCursorType>> {
  consultNoteQueueResults: ConsultNoteQueueResult[] = new Array<ConsultNoteQueueResult>();
  meta: CursorBasedPagingMeta<ConsultNoteQueueCursorType> = new CursorBasedPagingMeta<ConsultNoteQueueCursorType>();

  get records() {
    return this.consultNoteQueueResults;
  }
}

@Injectable()
export class ConsultNoteQueueService extends ApiService {
  constructor(private http: HttpClient, private userSettingsService: UserSettingsService) {
    super();
  }

  getUserSettings(): ConsultNoteQueueSettings {
    const queueSettings = this.userSettingsService.get<ConsultNoteQueueSettings>(
      UserSettings.consultNotesConsultNoteQueueSettings
    );

    if (!queueSettings) {
      return new ConsultNoteQueueSettings(new ConsultNoteQueueFilters());
    } else {
      return queueSettings;
    }
  }

  saveUserSettings(consultNoteQueueSettings: ConsultNoteQueueSettings) {
    this.userSettingsService.save<ConsultNoteQueueSettings>(
      UserSettings.consultNotesConsultNoteQueueSettings,
      consultNoteQueueSettings
    );
  }

  getCount(): Observable<{ count: number }> {
    const settings: ConsultNoteQueueSettings = this.getUserSettings();
    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);

    return this.fetchCount(filterParams);
  }

  getMyCount(userId: number): Observable<{ count: number }> {
    const filterParams = new URLSearchParams();
    this.appendFilterListParams(ConsultNoteQueueFilterName.statuses, [PatientAttachmentStatus.open], filterParams);
    this.appendFilterListParams(ConsultNoteQueueFilterName.assignedToIds, [userId], filterParams);

    return this.fetchCount(filterParams);
  }

  getResults(cursor: Cursor<ConsultNoteQueueCursorType> = null): Observable<PagedConsultNoteQueueResults> {
    const settings: ConsultNoteQueueSettings = this.getUserSettings();

    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);

    let cursorParams: URLSearchParams = null;
    if (cursor) {
      cursorParams = this.buildCursorParams(cursor.name, cursor.id, cursor.value);
    } else {
      cursorParams = this.buildCursorParams(settings.cursorType);
    }

    return this.fetchResults(filterParams, cursorParams);
  }

  getMyResults(
    userId: number,
    cursor: Cursor<ConsultNoteQueueCursorType> = null
  ): Observable<PagedConsultNoteQueueResults> {

    const filterParams = new URLSearchParams();
    this.appendFilterListParams(ConsultNoteQueueFilterName.statuses, [PatientAttachmentStatus.open], filterParams);
    this.appendFilterListParams(ConsultNoteQueueFilterName.assignedToIds, [userId], filterParams);

    let cursorParams: URLSearchParams = null;
    if (cursor) {
      cursorParams = this.buildCursorParams(cursor.name, cursor.id, cursor.value);
    } else {
      cursorParams = this.buildCursorParams(ConsultNoteQueueCursorType.mostRecentlyCreated);
    }

    return this.fetchResults(filterParams, cursorParams);
  }

  getNextResult(patientAttachment: PatientAttachment): Observable<NextResult> {
    const settings: ConsultNoteQueueSettings = this.getUserSettings();
    const cursor = this.cursorFromPatientAttachment(settings.cursorType, patientAttachment);

    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);
    const cursorParams: URLSearchParams = this.buildCursorParams(cursor.name, cursor.id, cursor.value);

    return this.fetchNextResult(filterParams, cursorParams);
  }

  assignUser(user_id: number, ids: number[]): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(
      `${environment.captureApi.url}/consult_note_queue/assign_to_user`,
      {
        user_id,
        ids
      }
    );
  }

  private fetchCount(filterParams: URLSearchParams): Observable<{ count: number }> {
    return this.http.get<{ count: number }>(
      `${environment.captureApi.url}/consult_note_queue/count?${filterParams.toString()}`
    );
  }

  private fetchResults(
    filterParams: URLSearchParams,
    cursorParams: URLSearchParams): Observable<PagedConsultNoteQueueResults> {

    return this.http.get<PagedConsultNoteQueueResults>(
      `${environment.captureApi.url}/consult_note_queue?${filterParams.toString()}&${cursorParams.toString()}`
    );
  }

  private fetchNextResult(
    filterParams: URLSearchParams,
    cursorParams: URLSearchParams): Observable<NextResult> {

    return this.http
      .get<{ nextResult: NextResult }>(
        `${environment.captureApi.url}/consult_note_queue/next?${filterParams.toString()}&${cursorParams.toString()}`
      )
      .pipe(
        mergeMap((resp: { nextResult: NextResult }) => {
          if (resp.nextResult) {
            return of(resp.nextResult);
          } else {
            return this.handleNoResult(filterParams, cursorParams);
          }
        })
      );
  }

  private handleNoResult(filterParams: URLSearchParams, cursorParams: URLSearchParams): Observable<NextResult> {
    cursorParams.delete(CursorParams.id);
    cursorParams.delete(CursorParams.value);

    return this.http
      .get<{ nextResult: NextResult }>(
        `${environment.captureApi.url}/consult_note_queue/next?${filterParams.toString()}&${cursorParams.toString()}`
      )
      .pipe(
        mergeMap((resp: { nextResult: NextResult }) => {
          if (resp) {
            return of(resp.nextResult);
          } else {
            return of(null);
          }
        })
      );
  }

  private cursorFromPatientAttachment(
    cursorType: ConsultNoteQueueCursorType,
    patientAttachment: PatientAttachment
  ): Cursor<ConsultNoteQueueCursorType> {
    const cursor = new Cursor<ConsultNoteQueueCursorType>();
    cursor.name = cursorType;
    cursor.id = patientAttachment.id;
    cursor.value = this.determineCursorValue(cursor.name, patientAttachment);
    return cursor;
  }

  private determineCursorValue(cursorType: ConsultNoteQueueCursorType, pa: PatientAttachment): string {
    switch (cursorType) {
      case ConsultNoteQueueCursorType.mostRecentlyCreated:
        return pa.createdAt.toString();
      case ConsultNoteQueueCursorType.leastRecentlyCreated:
        return pa.createdAt && pa.createdAt.toString();
      case ConsultNoteQueueCursorType.highestPendingEstimatedValue:
        return pa.pendingEstimatedValue.toString();
      case ConsultNoteQueueCursorType.highestWipEstimatedValue:
        return pa.wipEstimatedValue.toString();
      case ConsultNoteQueueCursorType.highestPendingHighValueCount:
        return pa.pendingHighValueCount.toString();
      default:
        return null;
    }
  }

  private buildFilterParams(filters: ConsultNoteQueueFilters): URLSearchParams {
    const urlSearchParams = new URLSearchParams();

    this.appendFilterListParams(ConsultNoteQueueFilterName.clientIds, filters.clientIds, urlSearchParams);
    this.appendFilterListParams(ConsultNoteQueueFilterName.clientStates, filters.clientStates, urlSearchParams);
    this.appendFilterListParams(ConsultNoteQueueFilterName.statuses, filters.statuses, urlSearchParams);
    this.appendFilterListParams(
      ConsultNoteQueueFilterName.assignedClientLeadsIds,
      filters.assignedClientLeadIds,
      urlSearchParams
    );
    this.appendFilterListParams(ConsultNoteQueueFilterName.assignedToIds, filters.assignedToIds, urlSearchParams);
    this.appendFilterFlagParam(ConsultNoteQueueFilterName.specialtyStore, filters.specialtyStore, urlSearchParams);
    this.appendFilterFlagParam(ConsultNoteQueueFilterName.highValue, filters.highValue, urlSearchParams);
    this.appendFilterParam(ConsultNoteQueueFilterName.expiringWithinDays, filters.expiringWithinDays, urlSearchParams);
    this.appendFilterParam(
      ConsultNoteQueueFilterName.outboundFaxNumber,
      filters.outboundFaxNumber,
      urlSearchParams
    );

    return urlSearchParams;
  }

  private buildCursorParams(cursorType: ConsultNoteQueueCursorType, id: number = null, value: string = null) {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append(CursorParams.name, cursorType);

    if (!isNull(id)) {
      urlSearchParams.append(CursorParams.id, id.toString());
    }

    if (!isNull(value)) {
      urlSearchParams.append(CursorParams.value, value);
    }

    return urlSearchParams;
  }

  private appendFilterListParams<T>(paramName: string, values: T[], urlSearchParams: URLSearchParams) {
    if (values && values.length > 0) {
      values.forEach(v => urlSearchParams.append(paramName, v.toString()));
    }
  }

  private appendFilterFlagParam(paramName: string, value: boolean, urlSearchParams: URLSearchParams) {
    if (value) {
      urlSearchParams.append(paramName, YesNo.yes.toString());
    }
  }

  private appendFilterParam(paramName: string, value: string, urlSearchParams: URLSearchParams) {
    if (value) {
      urlSearchParams.append(paramName, value);
    }
  }
}
