import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { PatientInteractionLocationImport } from '../models/patient-interaction-location-import.model';

@Injectable()
export class PatientInteractionLocationImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<PatientInteractionLocationImport> {
    return this.http.get<PatientInteractionLocationImport>(
      `${environment.captureApi.url}/patient_interaction_location_imports/${id}`
    );
  }

  getList(): Observable<{ patientInteractionLocationImports: PatientInteractionLocationImport[] }> {
    return this.http.get<{ patientInteractionLocationImports: PatientInteractionLocationImport[] }>(
      `${environment.captureApi.url}/patient_interaction_location_imports`
    );
  }

  create(signedId: string): Observable<PatientInteractionLocationImport> {
    return this.http.post<PatientInteractionLocationImport>(
      `${environment.captureApi.url}/patient_interaction_location_imports`,
      {
        patientInteractionLocationImport: { signedId },
      }
    );
  }

  cancel(id): Observable<PatientInteractionLocationImport> {
    return this.http.patch<PatientInteractionLocationImport>(
      `${environment.captureApi.url}/patient_interaction_location_imports/${id}/cancel`,
      {}
    );
  }
}
