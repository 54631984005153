import { Component, Input } from '@angular/core';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';

@Component({
  selector: 'app-validate-referral-match-verifications-prescriber-list',
  templateUrl: './validate-referral-match-verifications-prescriber-list.component.html',
  styleUrls: ['./validate-referral-match-verifications-prescriber-list.component.scss'],
})
export class ValidateReferralMatchVerificationsPrescriberListComponent {
  @Input() patientReferralMatchVerifications: ReferralMatchVerification[];
}
