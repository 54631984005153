import { Component, Input } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { CaptureSnapshotsService } from '../../../core/services/capture-snapshots.service';
import { CaptureService } from '../../../core/services/capture.service';
import { NavigationService } from '../../../core/services/navigation.service';

@Component({
  selector: 'app-capture-snapshot',
  templateUrl: './capture-snapshot.component.html',
  styleUrls: ['./capture-snapshot.component.scss'],
})
export class CaptureSnapshotComponent {
  @Input() capture: Capture;

  constructor(
    private captureSnapshotsService: CaptureSnapshotsService,
    private captureService: CaptureService,
    private navigationService: NavigationService
  ) {}

  createSnapshotClick() {
    this.captureSnapshotsService.create(this.capture.id).subscribe(() => {
      const modifiedCapture: Capture = Object.assign({}, this.capture);
      this.captureService.notifyCaptureChanged(modifiedCapture);

      const historyUrl = this.navigationService.createShowUrl('captures', this.capture.id) + '/history-new';

      if (this.navigationService.currentUrl !== historyUrl) {
        this.navigationService.goToUrl(historyUrl);
      }
    });
  }
}
