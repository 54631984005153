import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Capture } from '../models/capture.model';
import { VerificationOutcome } from '../models/verification-outcome.model';
import { PatientAttachment } from '../models/patient-attachment.model';
import { CaptureActivity } from '../models/capture-activity.model';
import { CaptureHistoryItem } from '../models/capture-history-item.model';
import { PatientMatchStatus } from '../enums/patient-match-status.enum';
import { CaptureStatus } from '../enums/capture-status.enum';
import { Office } from '../models/office.model';
import { displayCaptureStatus } from '../options/capture-status.opts';
import { OutreachAttemptEvidence } from '../models/outreach-attempt-evidence.model';

export class CaptureChangedEvent {
  constructor(public capture: Capture) {}
}

@Injectable()
export class CaptureService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  private captureChangedSource = new Subject<CaptureChangedEvent>();
  captureChanged = this.captureChangedSource.asObservable();

  getCapture(id: number): Observable<Capture> {
    return this.http.get<Capture>(`${environment.captureApi.url}/captures/${id}`);
  }

  transition(capture: Capture, eventName: string, outcome: VerificationOutcome = null): Observable<Capture> {
    return this.http.patch<Capture>(`${environment.captureApi.url}/captures/${capture.id}/transition/${eventName}`, {
      outcome: outcome || {},
    });
  }

  setVerifyingPatientAttachment(capture: Capture, attachment: PatientAttachment): Observable<Capture> {
    return this.http.patch<Capture>(
      `${environment.captureApi.url}/captures/${capture.id}/set_verifying_patient_attachment`,
      { patientAttachmentId: attachment ? attachment.id : null }
    );
  }

  setVerifyingOutreachAttemptEvidence(
    capture: Capture,
    outreachAttemptEvidence: OutreachAttemptEvidence
  ): Observable<Capture> {
    return this.http.patch<Capture>(
      `${environment.captureApi.url}/captures/${capture.id}/set_verifying_outreach_attempt_evidence`,
      { outreachAttemptEvidenceId: outreachAttemptEvidence?.id }
    );
  }

  notifyCaptureChanged(capture: Capture) {
    this.captureChangedSource.next(new CaptureChangedEvent(capture));
  }

  getCaptureActivities(id: number): Observable<CaptureActivity[]> {
    return this.http.get<CaptureActivity[]>(`${environment.captureApi.url}/captures/${id}/activities`);
  }

  getCaptureHistoryItems(id: number): Observable<CaptureHistoryItem[]> {
    return this.http.get<CaptureHistoryItem[]>(`${environment.captureApi.url}/captures/${id}/history_items`);
  }

  reprocess(capture: Capture): Observable<Capture> {
    return this.http.patch<Capture>(`${environment.captureApi.url}/captures/${capture.id}/reprocess`, {});
  }

  unverify(capture: Capture): Observable<Capture> {
    return this.http.patch<Capture>(`${environment.captureApi.url}/captures/${capture.id}/unverify`, {});
  }

  processPatientMatch(
    capture: Capture,
    status: PatientMatchStatus,
    nameType: string
  ): Observable<{ lastError: object }> {
    let action = null;

    switch (status) {
      case PatientMatchStatus.approved:
        action = 'approve_patient_match';
        break;
      case PatientMatchStatus.rejected:
        action = 'reject_patient_match';
        break;
      default:
        throw new Error(`Cannot determine action for status: ${status}`);
    }
    return this.http.patch<{ lastError: object }>(`${environment.captureApi.url}/captures/${capture.id}/${action}`, {
      nameType,
    });
  }

  sendFaxes(
    capture: Capture,
    template: string,
    providerOfficeIds: number[],
    customNote: string = null
  ): Observable<void> {
    return this.http.patch<void>(`${environment.captureApi.url}/captures/${capture.id}/send_faxes`, {
      sendFaxes: { template, providerOfficeIds, customNote },
    });
  }

  statusesForSelect() {
    return Object.values(CaptureStatus)
      .filter(v => v !== CaptureStatus.discarded)
      .map(v => ({
        value: v,
        display: displayCaptureStatus(v),
      }));
  }

  createUserAuditOutcome(
    capture: Capture,
    outcome: { status: string; statusReasons: string[]; note: string }
  ): Observable<Capture> {
    const { status, statusReasons, note } = outcome;

    return this.http.post<Capture>(`${environment.captureApi.url}/captures/${capture.id}/user_audit_outcomes`, {
      captureUserAuditOutcome: { status, statusReasons, note },
    });
  }

  saveAdditionalDetails(
    capture: Capture,
    additionalDetails: string
  ): Observable<Capture> {

    return this.http.patch<Capture>(`${environment.captureApi.url}/captures/${capture.id}/additional_details`, {
      capture: { additionalDetails },
    });
  }

  officeFaxSuggestion(
    capture: Capture,
    office: Office
  ): Observable<{ template: string }> {

    return this.http.get<{ template: string }>(`${environment.captureApi.url}/captures/${capture.id}/office_fax_suggestion/${office.id}`);
  }

  toggleVerifiedInvalidReviewed(
    capture: Capture,
  ): Observable<Capture> {
    return this.http.patch<Capture>(`${environment.captureApi.url}/captures/${capture.id}/toggle_verified_invalid_reviewed`, {});
  }
}
