import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { environment } from '../../../environments/environment';

import { PartnerFile } from '../models/partner-file.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';

export class PagedPartnerFiles implements PagedResults {
  partnerFiles: PartnerFile[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.partnerFiles;
  }
}

@Injectable()
export class PartnerFilesService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(page: number, pageSize: number, partnerNames: Array<string> = []): Observable<PagedPartnerFiles> {
    const searchParams = new URLSearchParams(`page=${page}&per_page=${pageSize}`);
    partnerNames.forEach(partnerName => searchParams.append('partner_names[]', partnerName));
    return this.http.get<PagedPartnerFiles>(`${environment.captureApi.url}/partner_files?${searchParams.toString()}`);
  }

  generateErrorFileAndGetDownloadUrl(id: number): Observable<any> {
    return this.http.get<any>(
      `${environment.captureApi.url}/partner_files/${id}/generate_error_file_and_get_download_url`
    );
  }
}
