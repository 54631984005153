import { ReferralMatchVerification } from './referral-match-verification.model';

export enum ReferralMatchVerificationEventType {
  changeInitiated = "changeInitiated",
  changed = "changed",
  removed = "removed",
}

export class ReferralMatchVerificationEvent {
  constructor(
    public type: ReferralMatchVerificationEventType,
    public verification?: ReferralMatchVerification,
    public tempId?: string
  ) { }
}
