import { Component, OnInit } from '@angular/core';
import { LimitOffsetPaging, Sort } from 'app/core/models/paged-results/limit-offset-paging.model';
import { Office } from 'app/core/models/office.model';
import { OfficeStatusReview } from 'app/core/models/office-status-review.model';
import { OfficeStatusReviewFilters, OfficeStatusReviewQueueSettings } from 'app/core/models/user-settings/office-status-review-settings.model';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { OfficeStatus } from 'app/core/enums/office-status.enum';
import { PagedOfficeStatusReviews, OfficeService } from 'app/core/services/office.service';
import { keyedStatusOptions as keyedOfficeStatusOptions } from 'app/core/options/office-status.opts';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

enum OfficeStatusReviewsSortBy {
  statusReviewAddedAtDesc = 'review_status_added_at_desc',
  statusReviewAddedAtAsc = 'review_status_added_at_asc',
}

@Component({
  selector: 'app-capture-admin-office-status-review-queue',
  templateUrl: './capture-admin-office-status-review-queue.component.html',
  styleUrls: ['./capture-admin-office-status-review-queue.component.scss'],
})
export class CaptureAdminOfficeStatusReviewQueueComponent implements OnInit {
  filters: OfficeStatusReviewFilters;
  sort: Sort;
  statusReviews: OfficeStatusReview[] = [];
  loading = false;
  paging = LimitOffsetPaging.empty;
  keyedOfficeStatusOptions = keyedOfficeStatusOptions;

  formGroup: FormGroup;

  sortByOptions = [
    { value: OfficeStatusReviewsSortBy.statusReviewAddedAtDesc, display: 'Review Created On - Newest First' },
    { value: OfficeStatusReviewsSortBy.statusReviewAddedAtAsc, display: 'Review Created On - Oldest First' },
  ];

  static sortByValueToSort = {
    [OfficeStatusReviewsSortBy.statusReviewAddedAtDesc]: new Sort('review_status_added_at', 'desc'),
    [OfficeStatusReviewsSortBy.statusReviewAddedAtAsc]: new Sort('review_status_added_at', 'asc'),
  };

  filterOptions = [
    keyedOfficeStatusOptions[OfficeStatus.unresponsive],
    keyedOfficeStatusOptions[OfficeStatus.testing],
    keyedOfficeStatusOptions[OfficeStatus.optedOut],
    keyedOfficeStatusOptions[OfficeStatus.notAMedicalOffice],
    keyedOfficeStatusOptions[OfficeStatus.faxFailed],
  ];

  constructor(
    private officeService: OfficeService,
    private userSettingsService: UserSettingsService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.loadQueueSettings();
    this.loadReviews();
    this.initFormGroup();
  }

  onPageSizeChange(size: number) {
    this.paging.pageSize = size;
    this.loadReviews();
    this.saveQueueSettings();
  }


  onPageChange() {
    this.loadReviews();
    this.saveQueueSettings();
  }

  onClearReviewClick(statusReview: OfficeStatusReview) {
    const attributes = { reviewStatus: null }

    this.officeService.update(statusReview, attributes).subscribe(
      office => this.handleSaveSuccess(office),
      error => this.handleSaveFailure(error)
    )
  }

  onOfficeChanged(office: Office) {
    this.removeReviewForOffice(office.id);
  }

  onResetFiltersClick() {
    this.paging.currentPage = 1;

    this.sort =
      CaptureAdminOfficeStatusReviewQueueComponent.sortByValueToSort[this.sortByOptions[0].value];

    this.filters = new OfficeStatusReviewFilters();

    this.loadReviews();
    this.saveQueueSettings();
    this.initFormGroup();
  }

  private handleSaveSuccess(office: Office) {
    this.removeReviewForOffice(office.id);
  }

  private handleSaveFailure(response: HttpErrorResponse) {
    console.error(response);
  }

  private loadQueueSettings() {
    const queueSettings: OfficeStatusReviewQueueSettings =
      this.userSettingsService.get<OfficeStatusReviewQueueSettings>(
        UserSettings.captureAdminOfficeStatusReviewQueueSettings
      );

    if (queueSettings) {
      this.filters = queueSettings.filters;
      this.paging = queueSettings.paging;
      this.sort = queueSettings.sort;
    } else {
      this.filters = new OfficeStatusReviewFilters();
      this.sort =
        CaptureAdminOfficeStatusReviewQueueComponent.
          sortByValueToSort[OfficeStatusReviewsSortBy.statusReviewAddedAtDesc];
    }
  }

  private saveQueueSettings() {
    const queueSettings = new OfficeStatusReviewQueueSettings();
    queueSettings.filters = this.filters;
    queueSettings.paging = this.paging;
    queueSettings.sort =
      CaptureAdminOfficeStatusReviewQueueComponent.sortByValueToSort[this.formGroup.value.sort];

    this.userSettingsService.
      save<OfficeStatusReviewQueueSettings>(
        UserSettings.captureAdminOfficeStatusReviewQueueSettings,
        queueSettings
      );
  }

  private loadReviews(showLoading = true) {
    const paging = {
      page: this.paging.currentPage,
      pageSize: this.paging.pageSize
    };

    if (showLoading) {
      this.loading = true;
    }

    this.officeService.getStatusReviewList(paging, this.filters, this.sort).
      subscribe((result: PagedOfficeStatusReviews) => {
        this.statusReviews = result.records || [];
        this.paging = result.meta.paging;
        this.loading = false;
      });
  }

  private removeReviewForOffice(officeId: number) {
    const reviewIndex = this.statusReviews.findIndex(statusReview => statusReview.id === officeId);

    if (reviewIndex >= 0) {
      this.statusReviews.splice(reviewIndex, 1);
      this.paging.count -= 1;
      this.loadReviews(false);
    }
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      sort: `${this.sort.col}_${this.sort.dir}`,
      filters: this.formBuilder.group({
        reviewStatus: [this.filters.reviewStatus]
      })
    });

    this.formGroup.valueChanges.subscribe(_value => {
      this.paging.currentPage = 1;

      const formValue = this.formGroup.value;

      this.sort =
        CaptureAdminOfficeStatusReviewQueueComponent.sortByValueToSort[formValue.sort];

      this.filters = new OfficeStatusReviewFilters();
      this.filters.reviewStatus = formValue.filters.reviewStatus;

      this.saveQueueSettings();

      this.loadReviews();
    })
  }
}
