import { Component, Input, OnInit } from '@angular/core';
import { keyedStatusOptions } from 'app/core/options/provider-status.opts';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'app-prescriber-name',
  templateUrl: './prescriber-name.component.html',
  styleUrls: ['./prescriber-name.component.scss'],
})
export class PrescriberNameComponent implements OnInit {
  @Input() prescriber: {
    npi: string;
    fullName: string;
    providerId: number;
    providerStatus: string;
    displaySpecialty: string;
  };
  @Input() linkToProvider = false;
  @Input() showSpecialtiesDetails = false;

  keyedStatusOptions = keyedStatusOptions;
  canViewProvider = false;

  constructor(
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.canViewProvider = this.authService.isCaptureAdminUser;
  }

  get hasName() {
    return this.prescriber.fullName.trim().length > 0;
  }

  get hasSpecialtiesInfo() {
    return this.showSpecialtiesDetails && this.prescriber.displaySpecialty.trim().length > 0;
  }
}
