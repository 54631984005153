import { Pipe, PipeTransform } from '@angular/core';
import { ClientPrescriptionMatchKind } from 'app/core/enums/client-prescription-match-kind.enum';


@Pipe({
  name: 'clientPrescriptionMatchKindName',
})
export class ClientPrescriptionMatchKindNamePipe implements PipeTransform {
  static readonly kindDisplayNames = {
    [ClientPrescriptionMatchKind.cePrescriber]: "CE Prescriber Match",
    [ClientPrescriptionMatchKind.internalReferral]: "Internal Referral Match"
  };

  transform(value: string): string {
    return ClientPrescriptionMatchKindNamePipe.kindDisplayNames[value];
  }
}
