import { Component, Input } from '@angular/core';
import { ClientChore } from 'app/core/models/client-chore.model';
import { ClientChoreInitiatedVia } from 'app/core/enums/client-chore-initiated-via.enum';

@Component({
  selector: 'app-client-chore-lifecycle',
  templateUrl: './client-chore-lifecycle.component.html',
  styleUrls: ['./client-chore-lifecycle.component.scss'],
})
export class ClientChoreLifecycleComponent {
  @Input() clientChore: ClientChore;

  clientChoreInitiatedVia = ClientChoreInitiatedVia;
}
