import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { IccMedicaidCodeImport } from '../../models/icc/icc-medicaid-code-import.model';
import { IccImportFilters } from '../../models/icc/icc-import-settings.model';

@Injectable()
export class IccMedicaidCodeImportsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id): Observable<IccMedicaidCodeImport> {
    return this.http.get<IccMedicaidCodeImport>(`${environment.captureApi.url}/icc/medicaid_code_imports/${id}`);
  }

  getList(filters: IccImportFilters = null): Observable<{ medicaidCodeImports: IccMedicaidCodeImport[] }> {
    const params = new URLSearchParams();

    if (filters && filters.selectedClientIds) {
      filters.selectedClientIds.forEach(clientId => params.append('client_ids[]', clientId));
    }

    return this.http.get<{ medicaidCodeImports: IccMedicaidCodeImport[] }>(
      `${environment.captureApi.url}/icc/medicaid_code_imports?${params.toString()}`
    );
  }

  create(clientId: number, name: string, signedId: string): Observable<IccMedicaidCodeImport> {
    return this.http.post<IccMedicaidCodeImport>(`${environment.captureApi.url}/icc/medicaid_code_imports`, {
      medicaidCodeImport: { clientId, name, signedId },
    });
  }

  update(id, name): Observable<IccMedicaidCodeImport> {
    return this.http.put<IccMedicaidCodeImport>(`${environment.captureApi.url}/icc/medicaid_code_imports/${id}`, {
      medicaidCodeImport: { name },
    });
  }

  cancel(id): Observable<IccMedicaidCodeImport> {
    return this.http.patch<IccMedicaidCodeImport>(
      `${environment.captureApi.url}/icc/medicaid_code_imports/${id}/cancel`,
      {}
    );
  }
}
