<div class="suboption">
  <div>
    <select
      id="{{ suboptionChoice.identifier }}-selection"
      name="{{ suboptionChoice.identifier }}-selection"
      class="form-select"
      required
      [ngModel]="suboptionChoice.selection"
      (ngModelChange)="onSelectionChange($event)"
    >
      <option
        *ngFor="let availableSuboptionTypeChoice of availableSuboptionTypeChoices"
        [ngValue]="availableSuboptionTypeChoice.value"
        [selected]="suboptionChoice.selection === availableSuboptionTypeChoice.value"
      >
        {{ availableSuboptionTypeChoice.display }}
      </option>
    </select>
  </div>
  <div class="ms-1">
    <app-client-configuration-basic-value
      [identifier]="suboptionChoice.identifier"
      [errors]="suboptionChoice.errors"
      [value]="suboptionChoice.value"
      [definition]="definition"
      (valueChange)="onValueChange($event)"
    />
  </div>
  <div class="ms-2">
    <a *ngIf="definition.editable" href="#" class="text-danger" (click)="onDeleteClick($event)">
      <fa-icon [icon]="['fas', 'trash']" size="1x" />
      Delete
    </a>
  </div>
</div>
