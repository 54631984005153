import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { filter, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  static readonly prefix = "t40b_";

  protected storage = localStorage;
  protected changes: Observable<StorageEvent>;

  constructor() {
    this.changes =
      fromEvent<StorageEvent>(window, 'storage')
        .pipe(filter((event: StorageEvent) => event.storageArea === this.storage));
  }

  public get keys(): Array<string> {
    const result = [];
    for (let i = 0, len = this.storage.length; i < len; ++i) {
      const key = this.storage.key(i)

      if (key.startsWith(LocalStorageService.prefix)) {
        result.push(key);
      }
    }
    return result;
  }

  public get(key: string): any {
    return JSON.parse(this.storage.getItem(this.prefixKey(key)))
  }

  public set<T>(key: string, value: T): T {
    const serializedValue = JSON.stringify(value);
    this.storage.setItem(this.prefixKey(key), serializedValue);
    return value;
  }

  public remove(key: string): void {
    this.storage.removeItem(this.prefixKey(key));
  }

  public observe(key: string) {
    const prefixedKey = this.prefixKey(key);

    return this.changes.pipe(
      filter((event: StorageEvent) => event.key === prefixedKey),
      delay(30)
    );
  }

  private prefixKey(key: string): string {
    return `${LocalStorageService.prefix}${key}`;
  }

}
