import { keyBy } from 'lodash-es';
import { Component, Input, OnInit } from '@angular/core';
import { TaskSubmission } from '../../../core/models/task-submission.model';
import { ListProvider } from '../../../core/models/list-provider.model';
import { Patient } from '../../../core/models/patient.model';
import { choiceOptions } from '../../../core/options/client-referral-task-choice-opts';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';
import { ClientTaskRejectionReason } from '../../../core/enums/client-task-rejection-reason.enum';

const KeyedChoiceOptions = keyBy(choiceOptions, 'value');

@Component({
  selector: 'app-client-referral-task-response',
  templateUrl: './client-referral-task-response.component.html',
  styleUrls: ['./client-referral-task-response.component.scss'],
})
export class ClientReferralTaskResponseComponent implements OnInit {
  @Input() taskSubmission: TaskSubmission;
  @Input() provider: ListProvider;
  @Input() patient: Patient;

  keyedChoiceOptions = KeyedChoiceOptions;
  verificationReasons = ReferralVerificationReason;
  rejectionReasons = ClientTaskRejectionReason;

  constructor() {}

  ngOnInit() {}
}
