<div id="validateSpecialistEncounterMatch" class="card mt-1">
  <app-validation-header
    [icon]="'event'"
    [title]="'Validate Specialist Encounter'"
    [collapsed]="collapsed"
    (collapseChange)="onCollapseChange($event)"
  />
  <div class="card-body p-1">
    <div>
      <app-validate-specialist-encounter-form
        [patientAttachment]="patientAttachment"
        [collapsed]="collapsed"
      />
    </div>
  </div>
</div>
