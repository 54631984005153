import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';
import { ClientTaskRejectionReason } from 'app/core/enums/client-task-rejection-reason.enum';
import { referralSpecialties } from 'app/core/options/referral-specialties.opts';

@Component({
  selector: 'app-client-referral-task-option-reason-additional-fields',
  templateUrl: './client-referral-task-option-reason-additional-fields.component.html'
})
export class ClientReferralTaskOptionReasonAdditionalFieldsComponent {
  @Input() reason: ReferralVerificationReason | ClientTaskRejectionReason;
  @Input() disabled: boolean;
  @Input() model: { [property: string]: string };

  @Output() modelChange = new EventEmitter<{ [property: string]: string }>();

  referralSpecialties = referralSpecialties;

  verificationReasons = ReferralVerificationReason;

  onValueChange() {
    this.modelChange.emit(this.model);
  }
}
