import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-import-nav',
  templateUrl: './import-nav.component.html',
})
export class ImportNavComponent implements OnInit {
  @Input() uploadPath: string;
  @Input() historyPath: string;

  constructor(private route: Router) {}

  ngOnInit() {
    if (!this.uploadPath && !this.historyPath) {
      const currentPath = this.route.url;
      const pathMatch = currentPath.match(/(.+)(\/history)$/);

      if (pathMatch) {
        this.uploadPath = pathMatch[1];
        this.historyPath = currentPath;
      } else {
        this.uploadPath = currentPath;
        this.historyPath = `${currentPath}/history`;
      }
    }
  }
}
