export class EstimatedValueRankOption {
  constructor(public value: number, public display: string) {}
}

export const estimatedValueRank = [
  new EstimatedValueRankOption(1, '$'),
  new EstimatedValueRankOption(2, '$$'),
  new EstimatedValueRankOption(3, '$$$'),
  new EstimatedValueRankOption(4, '$$$$'),
];
