import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-client-task-history-item-rejected-prescriptions',
  templateUrl: './client-task-history-item-rejected-prescriptions.component.html',
})
export class ClientTaskHistoryItemRejectedPrescriptionsComponent {
  @Input() clientTaskHistoryItem;

  get rejectedPrescriptionIds() {
    const rejectedPrescriptionIds = this.clientTaskHistoryItem.data.rejectedPrescriptionIds;
    if (rejectedPrescriptionIds && rejectedPrescriptionIds.length > 0) {
      return rejectedPrescriptionIds;
    } else {
      return null;
    }
  }
}
