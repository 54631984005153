import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService } from 'app/core/services/clients.service';
import { Client } from 'app/core/models/client.model';
import { ClientChore } from 'app/core/models/client-chore.model';
import { Upload } from '../../../core/models/upload.model';
import { ClientChoresService } from 'app/core/services/client-chores.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AvailableClientChore } from 'app/core/models/available-client-chore.model';
import { AvailableClientChoreColumn } from 'app/core/models/available-client-chore-column.model';
import { ClientChoreClientOption } from 'app/core/enums/client-chore-client-option.enum';
import { isEmpty, sortBy } from 'lodash-es';

@Component({
  templateUrl: './capture-admin-client-chore-create.component.html',
})
export class CaptureAdminClientChoreCreateComponent implements OnInit {
  directUploadPath = ClientChoresService.directUploadPath;
  selectedClientId: number = null;
  selectedChore: AvailableClientChore = null;
  availableChores: AvailableClientChore[] = [];
  clients: Client[] = [];
  errors: string[] = [];
  pages = 1;
  uploadingFile = false;
  params = {};

  constructor(
    private clientsService: ClientsService,
    private authService: AuthenticationService,
    private clientChoresService: ClientChoresService,
    private router: Router
  ) {}

  ngOnInit() {
    this.clientChoresService.getAvailableChores().subscribe(result => {
      const creatableChores = result.availableClientChores.filter(availableChore => availableChore.userCreatable);

      this.availableChores =
        sortBy(creatableChores, (availableChore: AvailableClientChore) => availableChore.displayName);
    });

    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  hideClient(): boolean {
    return this.authService.isCaptureAdminUser &&
      (!this.hasChosenChore() || this.chosenChoreForAnyClient());
  }

  showFileUpload(): boolean {
    return this.authService.isCaptureAdminUser &&
      this.hasChosenChore() &&
      (this.chosenChoreForAnyClient() || this.hasClient()) &&
      this.chosenChoreTakesFileInput();
  }

  showFormFields(): boolean {
    return this.authService.isCaptureAdminUser &&
      this.hasChosenChore() &&
      (this.chosenChoreForAnyClient() || this.hasClient()) &&
      this.chosenChoreTakesFormInput();
  }

  formattedDescription(column: AvailableClientChoreColumn): string {
    let string = column.description + ', type: ' + column.type;
    if (column.format)
      string = string + ', format: ' + column.format;
    if (column.required)
      string = string + ' REQUIRED';
    if (string)
      string = '(' + string + ')';
    return string;
  }

  showCreateButton(): boolean {
    return this.hasChosenChore() && !this.chosenChoreTakesFileInput();
  }

  hasClient(): boolean {
    return this.selectedClientId != null;
  }

  hasChosenChore(): boolean {
    return this.selectedChore != null;
  }

  chosenChoreTakesFileInput(): boolean {
    return this.selectedChore && this.selectedChore.inputs?.file?.type === 'csv';
  }

  chosenChoreTakesFormInput(): boolean {
    return this.selectedChore && this.selectedChore.inputs?.form?.fields?.length > 0;
  }

  chosenChoreForAnyClient(): boolean {
    return this.selectedChore && this.selectedChore.clientOption === ClientChoreClientOption.notAllowed;
  }

  onUploadStart(): void {
    this.uploadingFile = true;
  }

  onUploadComplete(upload: Upload): void {
    this.clientChoresService
      .create(this.selectedClientId, this.selectedChore.name, upload.signedId, this.params)
      .subscribe(
        (clientChore: ClientChore) => {
          this.handleChoreCreation(clientChore);
        },
        (error: HttpErrorResponse) => {
          this.handleErrorResponse(error);
        }
      );
  }

  onUploadError(error: Error): void {
    this.uploadingFile = false;
    console.error(error);
  }

  onSubmit() {
    this.clientChoresService
      .create(this.selectedClientId, this.selectedChore.name, null, this.params)
      .subscribe(
        (clientChore: ClientChore) => {
          this.handleChoreCreation(clientChore);
        },
        (error: HttpErrorResponse) => {
          this.handleErrorResponse(error);
        }
      );
  }

  onCancelClick() {
    this.router.navigate(['/capture-admin/tools/client-chores']);
  }

  requiredFieldMissing() {
    if (this.chosenChoreTakesFormInput()) {
      return !(this.selectedChore.inputs.form.fields.length === Object.keys(this.params).length);
    }
    // this allows a client chore with no inputs but
    // the client.
    if (this.selectedChore.inputs === null) {
      return !this.selectedClientId;
    }
    return true;
  }

  private handleChoreCreation(_clientChore: ClientChore) {
    this.router.navigate(['/capture-admin/tools/client-chores']);
  }

  private handleErrorResponse(error: HttpErrorResponse) {
    this.errors = error.error.errors;
    this.uploadingFile = false;
  }

  public get hasError() {
    return !isEmpty(this.errors);
  }
}
