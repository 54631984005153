/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientPrescriberTask } from '../../../core/models/client-prescriber-task.model';

@Component({
  selector: '[app-client-prescriber-task-list-row]',
  templateUrl: './client-prescriber-task-list-row.component.html',
  styleUrls: ['./client-prescriber-task-list-row.component.scss'],
})
export class ClientPrescriberTaskListRowComponent implements OnInit {
  @Input() task: ClientPrescriberTask;
  @Input() position: number;

  @Output() viewTask = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  onViewClick() {
    this.viewTask.emit(this.position);
  }
}
