import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './client-portal-match-reports.component.html',
})
export class ClientPortalMatchReportsComponent {
  reportsUrl = `${environment.captureApi.url}/client_capture_reports`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    verifiedDate: [null],
  });
}
