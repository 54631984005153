import { Component, Input } from '@angular/core';
import { CaptureHistoryItem } from '../../../core/models/capture-history-item.model';

@Component({
  selector: 'app-capture-history-item-type-base',
  templateUrl: './capture-history-item-type-base.component.html',
})
export class CaptureHistoryItemTypeBaseComponent {
  @Input() captureHistoryItem: CaptureHistoryItem;

  constructor() {}

  // This is meant as fallback text and shouldn't be shown in practice
  get title() {
    return `Event: ${this.captureHistoryItem.action}`;
  }

  get description() {
    return '';
  }
}
