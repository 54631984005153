import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { keyedPatientAttachmentStatusOptions } from '../../../core/options/patient-attachment-status.opts';

@Component({
  selector: 'app-other-consult-notes-selector',
  templateUrl: './other-consult-notes-selector.component.html',
  styleUrls: ['./other-consult-notes-selector.component.scss'],
})
export class OtherConsultNotesSelectorComponent {
  @Input() otherPatientAttachments: PatientAttachment[];
  @Input() selectedPatientAttachment: PatientAttachment;

  @Output() selectionChange = new EventEmitter<PatientAttachment>();

  onPatientAttachmentClick(otherPatientAttachment: PatientAttachment) {
    this.selectionChange.emit(otherPatientAttachment);
  }
}
