import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './client-portal-prescription-report.component.html',
})
export class ClientPortalPrescriptionReportComponent {
  reportsUrl = `${environment.captureApi.url}/client_prescription_reports`;
  reportsCountUrl = `${environment.captureApi.url}/client_prescription_reports/count`;
}
