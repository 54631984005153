import { ReferralMatchVerification } from './referral-match-verification.model';
import { Referral } from './referral.model';
import { AbstractReferralMatchOption } from './abstract-referral-match-option.model';

export class ReferralMatchOption extends AbstractReferralMatchOption {
  constructor(
    public verification: ReferralMatchVerification,
    public referral: Referral,
    public selected: boolean,
    public disabled: boolean
  ) {
    super(verification, selected, disabled);
  }
}
