import { Pipe, PipeTransform } from '@angular/core';
import { CaptureUserAuditOutcomeStatusReason } from '../../core/enums/capture-user-audit-outcome-status-reason.enum';
import {
  CaptureUserAuditOutcomeStatusReasonOption,
  keyedOutcomeStatusReasonOptions,
} from '../../core/options/capture-user-audit-outcome-status-reason.opts';

@Pipe({
  name: 'captureUserAuditOutcomeStatusReasons',
})
export class CaptureUserAuditOutcomeStatusReasonsPipe implements PipeTransform {
  transform(reasons: CaptureUserAuditOutcomeStatusReason[]): string {
    const opts = reasons
      .filter((csr: CaptureUserAuditOutcomeStatusReason) => csr in keyedOutcomeStatusReasonOptions)
      .map(csr => keyedOutcomeStatusReasonOptions[csr]);
    return opts.map((opt: CaptureUserAuditOutcomeStatusReasonOption) => opt.display).join(', ');
  }
}
