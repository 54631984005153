import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './client-portal-client-consult-note-task-reports.component.html',
})
export class ClientPortalClientConsultNoteTaskReportsComponent {
  reportsUrl = `${environment.captureApi.url}/client_consult_note_task_reports`;

  constructor() {}
}
