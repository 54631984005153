import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { AuthenticationService } from './authentication.service';
import { UserTiming } from '../models/user-timing.model';
import { WindowActivityService } from './window-activity.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserTimingService {
  static readonly tickTimeoutMilliseconds = 2 * 1000;
  static readonly idleTimeoutMilliseconds = 10 * 1000;
  static readonly focusLostTimeoutMilliseconds = 5 * 1000;

  private windowActivityService;

  private tickTimeout;
  private idleTimeout;
  private focusLostTimeout;

  private currentTiming;
  private previousTiming;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private authService: AuthenticationService
  ) {}

  track(data) {
    if (this.authService.isCaptureAdminUser) {
      const dataHash = UserTiming.dataHash(data);

      if (!this.currentTiming || this.currentTiming.hash !== dataHash) {
        this.debugLog('SUBJECT', JSON.stringify(data));
        this.startTracking();

        this.stopTimer('replaced');
        this.startTimer(dataHash, data);
      }

      this.recordOrphanedTimings();
    }
  }

  stop() {
    if (this.authService.isCaptureAdminUser) {
      this.stopTracking();
      this.stopTimer('stopped');
    }
  }

  private startTimer(dataHash, data) {
    const now = new Date();

    this.currentTiming = new UserTiming(dataHash, data, now, now);
    this.persistTimingLocally();

    this.debugTimingLog('START', this.currentTiming);

    this.setIdleTimeout();
    this.setTickTimeout();
  }

  private restartTimer() {
    if (this.previousTiming) {
      this.startTimer(this.previousTiming.hash, this.previousTiming.data);
    }
  }

  private updateCurrentTiming() {
    this.currentTiming.update();
    this.persistTimingLocally();
  }

  private stopTimer(stopCode) {
    if (this.currentTiming) {
      this.clearTickTimeout();

      this.currentTiming.stopCode = stopCode;
      this.updateCurrentTiming();

      this.debugTimingLog('END', this.currentTiming, `reason: ${stopCode}, millis: ${this.currentTiming.timeElapsed}`);
      this.recordTiming(this.currentTiming, stopCode);

      this.previousTiming = this.currentTiming;
      delete this.currentTiming;
    }
  }

  private timingLocalStorageKey(timing) {
    return `${this.authService.currentUsername}_timing_${timing.id}`;
  }

  private getLocallyPersistedTimings() {
    const timingPattern =
      new RegExp(`^${LocalStorageService.prefix}(${this.authService.currentUsername}_timing_.*)$`);

    const timingObjects = this.localStorageService.keys.reduce((result, key) => {
      const match = timingPattern.exec(key);

      if (match !== null) {
        return result.concat([this.localStorageService.get(match[1])]);
      } else {
        return result;
      }
    }, []);

    return timingObjects.map(obj => UserTiming.fromObject(obj));
  }

  private persistTimingLocally() {
    return this.localStorageService.set(this.timingLocalStorageKey(this.currentTiming), this.currentTiming);
  }

  private removeLocallyPersistedTiming(timing) {
    return this.localStorageService.remove(this.timingLocalStorageKey(timing));
  }

  private recordTimingRequest(timing) {
    return this.http.post<null>(`${environment.captureApi.url}/user_timings`, timing.postData);
  }

  private recordTiming(timing, stopCode) {
    this.recordTimingRequest(timing).subscribe(() => {
      this.removeLocallyPersistedTiming(timing);
    });
  }

  private recordOrphanedTimings() {
    const timings = this.getLocallyPersistedTimings();

    timings.forEach(timing => {
      if (timing.isOrphan) {
        this.debugTimingLog('ORPHAN', timing);
        this.recordTiming(timing, 'orphaned');
      }
    });
  }

  private subscribeToWindowActivity() {
    this.windowActivityService.activity.subscribe(activityEvent => {
      this.clearIdleTimeout();
      this.clearFocusLostTimeout();

      if (activityEvent.active) {
        if (!this.currentTiming) {
          this.restartTimer();
        }

        this.setIdleTimeout();
      } else {
        this.setFocusLostTimeout();
      }
    });
  }

  private startTracking() {
    if (this.windowActivityService) {
      return;
    }

    this.windowActivityService = new WindowActivityService();
    this.subscribeToWindowActivity();
  }

  private setIdleTimeout() {
    this.clearIdleTimeout();
    this.idleTimeout = setTimeout(() => {
      this.stopTimer('idled');
    }, UserTimingService.idleTimeoutMilliseconds);
  }

  private clearIdleTimeout() {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
    }
  }

  private setFocusLostTimeout() {
    this.clearFocusLostTimeout();
    this.focusLostTimeout = setTimeout(() => {
      this.stopTimer('focusLost');
    }, UserTimingService.focusLostTimeoutMilliseconds);
  }

  private clearFocusLostTimeout() {
    if (this.focusLostTimeout) {
      clearTimeout(this.focusLostTimeout);
    }
  }

  private setTickTimeout() {
    this.clearTickTimeout();
    this.tickTimeout = setTimeout(this.updateCurrentTiming.bind(this), UserTimingService.tickTimeoutMilliseconds);
  }

  private clearTickTimeout() {
    if (this.tickTimeout) {
      clearTimeout(this.tickTimeout);
    }
  }

  private stopTracking() {
    if (this.windowActivityService) {
      this.windowActivityService.destroy();
      delete this.windowActivityService;
    }

    this.clearIdleTimeout();
    this.clearFocusLostTimeout();
    this.clearTickTimeout();
  }

  private debugLog(...msg) {
    if (environment.debugUserTiming) {
      console.log('TIMING', ...msg);
    }
  }

  private debugTimingLog(title, timing, ...msg) {
    if (environment.debugUserTiming) {
      console.groupCollapsed('TIMING', title, `[id: ${timing.id}]`, ...msg);
      console.log('startedAt:', timing.startedAt);
      console.log('endedAt:', timing.endedAt);
      console.log('data:', JSON.stringify(timing.data));
      console.log('hash:', timing.hash);
      console.groupEnd();
    }
  }
}
