import { Injectable } from '@angular/core';
import { DirectUpload } from 'activestorage';
import { Observable } from 'rxjs';
import { Upload } from '../models/upload.model';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';

const defaultDirectUploadPath = 'rails/active_storage/direct_uploads';

@Injectable()
export class UploadService {
  constructor(private authService: AuthenticationService) {}

  upload(file: File, directUploadPath = null): Observable<Upload> {
    return new Observable(observer => {
      directUploadPath = directUploadPath || defaultDirectUploadPath;
      const origin = new URL(environment.captureApi.url).origin;
      const directUploadUrl = `${origin}/${directUploadPath}`;

      const directUpload = new DirectUpload(file, directUploadUrl, {
        directUploadWillCreateBlobWithXHR: xhr => {
          xhr.setRequestHeader('Authorization', `Bearer ${this.authService.jwtIdentity}`);
        },
      });

      directUpload.create((error, blob) => {
        if (error) {
          observer.error(error);
        } else {
          observer.next(new Upload(file, blob.signed_id));
        }
        observer.complete();
      });
    });
  }
}
