import { Component, Input } from '@angular/core';
import { Provider } from 'app/core/models/provider.model';
import { NavComponent } from 'app/shared/components/nav/nav.component';

@Component({
  selector: 'app-provider-nav',
  templateUrl: './provider-nav.component.html',
  styleUrls: ['./provider-nav.component.scss'],
})
export class ProviderNavComponent extends NavComponent {
  @Input() provider: Provider;
}
