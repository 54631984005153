import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { ApiService } from './api.service';
import { User } from '../models/user.model';
import { UserListFilters } from '../models/user-settings/user-management-settings.model';
import { AuthenticationService } from '../services/authentication.service';
import { LimitOffsetPagingMeta, Sort } from '../models/paged-results/limit-offset-paging.model';
import { UserType } from '../enums/user-type.enum';
import { UserRoleName } from '../enums/user-role-name.enum';

export class PagedUsers implements PagedResults {
  users: User[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.users;
  }
}

@Injectable()
export class UsersService extends ApiService {
  constructor(authService: AuthenticationService, private http: HttpClient) {
    super();
  }

  get(id: number): Observable<User> {
    return this.http.get<User>(`${environment.captureApi.url}/users/${id}`);
  }

  getList(
    page: number,
    pageSize: number,
    sort: Sort,
    { userFirstName, userLastName, userEmail, userClients, userTypes, userStatuses }: UserListFilters
  ): Observable<PagedUsers> {
    let url = `${environment.captureApi.url}/users?page=${page}&per_page=${pageSize}`;

    if (sort && sort.col && sort.dir) {
      url = `${url}&sort_col=${sort.col}&sort_dir=${sort.dir}`;
    }

    if (userFirstName) {
      url = `${url}&first_name=${userFirstName}`;
    }

    if (userLastName) {
      url = `${url}&last_name=${userLastName}`;
    }

    if (userEmail) {
      url = `${url}&email=${userEmail}`;
    }

    if (userClients && userClients.length > 0) {
      url = `${url}&${userClients.map(s => `client_ids[]=${s}`).join('&')}`;
    }

    if (userTypes && userTypes.length > 0) {
      url = `${url}&${userTypes.map(s => `user_types[]=${s}`).join('&')}`;
    }

    if (userStatuses && userStatuses.length > 0) {
      url = `${url}&${userStatuses.map(s => `statuses[]=${s}`).join('&')}`;
    }

    return this.http.get<PagedUsers>(url);
  }

  getByUserType(userTypes: UserType[]): Observable<{ users: User[] }> {
    let url = `${environment.captureApi.url}/users/by_user_type?`;
    if (userTypes && userTypes.length > 0) {
      url = `${url}&${userTypes.map(s => `user_type[]=${s}`).join('&')}`;
    }
    return this.http.get<{ users: User[] }>(url);
  }

  getByUserRole(userRoles: UserRoleName[]): Observable<{ users: User[] }> {
    let url = `${environment.captureApi.url}/users/by_user_role?`;
    if (userRoles && userRoles.length > 0) {
      url = `${url}&${userRoles.map(s => `user_roles[]=${s}`).join('&')}`;
    }
    return this.http.get<{ users: User[] }>(url);
  }

  create(
    email,
    userType,
    firstName,
    lastName,
    phone = null,
    clients = [],
    source = null,
    roles = [],
    assignedClients = [],
    samlProvider
  ): Observable<User> {
    return this.http.post<User>(`${environment.captureApi.url}/users`, {
      user: { email, userType, firstName, lastName, phone, clients, source, roles, assignedClients, samlProvider },
    });
  }

  update(
    id,
    userType,
    firstName,
    lastName,
    phone = null,
    clients = [],
    source = null,
    roles = [],
    assignedClients = [],
    samlProvider
  ): Observable<User> {
    return this.http.patch<User>(`${environment.captureApi.url}/users/${id}`, {
      user: { userType, firstName, lastName, phone, clients, source, roles, assignedClients, samlProvider },
    });
  }

  resendInvite(user: User): Observable<User> {
    return this.http.patch<User>(`${environment.captureApi.url}/users/${user.id}/resend_invite`, {});
  }

  resetPassword(user: User): Observable<User> {
    return this.http.patch<User>(`${environment.captureApi.url}/users/${user.id}/reset_password`, {});
  }

  deactivate(user: User): Observable<User> {
    return this.http.patch<User>(`${environment.captureApi.url}/users/${user.id}/deactivate`, {});
  }

  reactivate(user: User): Observable<User> {
    return this.http.patch<User>(`${environment.captureApi.url}/users/${user.id}/reactivate`, {});
  }

  unlock(user: User): Observable<User> {
    return this.http.patch<User>(`${environment.captureApi.url}/users/${user.id}/unlock`, {});
  }
}
