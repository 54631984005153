import { InboundFaxCommunicationMethod } from '../enums/inbound-fax-communication-method.enum';
import { keyBy } from 'lodash-es';

export class FaxCommunicationMethodOption {
  constructor(public value: string, public display: string, public selectable: boolean = true) {}
}

const communicationMethods = [
  new FaxCommunicationMethodOption(InboundFaxCommunicationMethod.standardFax, 'Standard Fax'),
  new FaxCommunicationMethodOption(InboundFaxCommunicationMethod.ciox, 'CIOX'),
  new FaxCommunicationMethodOption(InboundFaxCommunicationMethod.mro, 'MRO'),
  new FaxCommunicationMethodOption(InboundFaxCommunicationMethod.ironMountain, 'Iron Mountain'),
  new FaxCommunicationMethodOption(InboundFaxCommunicationMethod.shareCare, 'ShareCare'),
  new FaxCommunicationMethodOption(InboundFaxCommunicationMethod.otherThirdParty, 'Other 3rd Party'),
  new FaxCommunicationMethodOption(InboundFaxCommunicationMethod.email, 'Email'),
];

const keyedCommunicationMethods = keyBy(communicationMethods, 'value');

export { keyedCommunicationMethods, communicationMethods };
