<div class="dates" *ngIf="clientReferralMatchVerifications.length > 0; else: noClientReferralDates">
  <ng-container *ngFor="let verification of clientReferralMatchVerifications; let i = index">
    <div class="my-2">
      <span>
        <strong>Client Referral Date {{ i + 1 }}</strong>
      </span>
      <div class="form-inline client-referral-match-verification">
        <div class="input-group client-referral-date">
          <input
            name="clientReferralDate"
            class="form-control client-referral-date-input"
            type="date"
            autocomplete="off"
            tabindex="-1"
            [(ngModel)]="verification.clientReferralDate"
            [disabled]="true"
            placeholder="MM/DD/YYYY"
          />
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #noClientReferralDates>
  <div class="my-2">No client referral dates found.</div>
</ng-template>
