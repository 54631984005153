import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Note } from "../models/note.model";
import { environment } from '../../../environments/environment';

@Injectable()
export class NotesService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getForModel(modelId: number, modelType: string): Observable<Note[]> {
    const searchParams = new URLSearchParams(`model_id=${modelId}&model_type=${modelType}`);
    return this.http.get<Note[]>(
      `${environment.captureApi.url}/notes/for_model?${searchParams.toString()}`
    );
  }

  create(modelId: number, modelType: string, content: string): Observable<Note> {
    return this.http.post<Note>(
      `${environment.captureApi.url}/notes`,
      {
        note: {
          modelId,
          modelType,
          content,
        }
      }
    );
  }
}