<h5>Consult Note Tasks</h5>
<ng-container *ngIf="!loadingRelatedTasks; else: spinner">
  <div *ngIf="relatedTasks.length > 0; else: noRelatedTasks">
    <p>All consult note tasks for patient, {{ task.patient.fullName }} DOB {{ task.patient.dob | date: 'MM/dd/yyyy' }}.
    </p>
    <table class="table table-sm">
      <thead>
        <tr>
          <th class="text-center">TASK ID</th>
          <th>STATUS</th>
          <th>PRESCRIBER</th>
          <th class="text-center">ESTIMATED VALUE</th>
          <th class="text-center">EXPIRES ON</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let relatedTask of relatedTasks">
          <td class="text-center">
            <a target="_blank" routerLinkActive="active" routerLink="/ce-portal/tasks/client-consult-note-tasks/{{ relatedTask.id }}">{{ relatedTask.id }}</a>
          </td>
          <td>{{ relatedTask.status | taskStatus }}</td>
          <td><app-prescriber-name [prescriber]="relatedTask.prescriber" [showSpecialtiesDetails]="true" /></td>
          <td class="text-center"><app-task-estimated-value [rank]="relatedTask.estimatedValueRank" [value]="relatedTask.estimatedValue" /></td>
          <td class="text-center"><app-expiration-date [expirationDate]="relatedTask.nextClaimExpirationDate" /></td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="showSeeAll" class="text-center">
      <a href="#" (click)="clickSeeAll($event)">All Other</a>
    </div>
  </div>
</ng-container>
<ng-template #noRelatedTasks> <p>There are no related tasks.</p> </ng-template>
<ng-template #spinner> <mat-spinner [diameter]="30" /> </ng-template>
