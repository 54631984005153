import { keyBy } from 'lodash-es';
import { OutboundFaxTemplate } from "../enums/outbound-fax-template.enum";

export const SelectableOutboundFaxTemplateOptions = [
  { value: OutboundFaxTemplate.firstAttempt, display: 'First Attempt' },
  { value: OutboundFaxTemplate.moreRecentConsultNote, display: 'More Recent Consult Note' },
  { value: OutboundFaxTemplate.olderConsultNote, display: 'Older Consult Note' },
  { value: OutboundFaxTemplate.olderDrugNotReferenced, display: 'Older Drug Not Referenced' },
  { value: OutboundFaxTemplate.moreRecentDrugNotReferenced, display: 'More Recent Drug Not Referenced' },
  { value: OutboundFaxTemplate.drugNotReferenced, display: 'Drug Not Referenced' },
  { value: OutboundFaxTemplate.customNote, display: 'Custom Note' },
  { value: OutboundFaxTemplate.thirdPartyFirstAttempt, display: 'First Attempt - Third Party' },
  { value: OutboundFaxTemplate.thirdPartyCustomNote, display: 'Custom Note - Third Party' },
];

export const OutboundFaxTemplateOptions = SelectableOutboundFaxTemplateOptions.concat([
  { value: OutboundFaxTemplate.secondAttempt, display: 'Second Attempt' },
  { value: OutboundFaxTemplate.thirdAttempt, display: 'Third Attempt' },
]);

export const keyedOutboundFaxTemplateOptions = keyBy(OutboundFaxTemplateOptions, 'value');

export const AlertableTemplates = [
  OutboundFaxTemplate.customNote,
  OutboundFaxTemplate.moreRecentConsultNote,
  OutboundFaxTemplate.olderConsultNote,
  OutboundFaxTemplate.olderDrugNotReferenced,
  OutboundFaxTemplate.moreRecentDrugNotReferenced,
  OutboundFaxTemplate.drugNotReferenced,
  OutboundFaxTemplate.thirdPartyCustomNote,
];

export const isAlertableTemplate = (template) => AlertableTemplates.includes(template);
