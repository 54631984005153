import { Component, OnInit } from '@angular/core';
import { ConsultNoteHistoryItemTypeBaseComponent } from '../consult-note-history-item-type-base/consult-note-history-item-type-base.component';
import { keyedReasonOptions } from 'app/core/options/capture-status-reason.opts';
import { displayCaptureStatus } from 'app/core/options/capture-status.opts';

@Component({
  selector: 'app-consult-note-history-item-type-submitted',
  templateUrl: './consult-note-history-item-type-submitted.component.html',
})
export class ConsultNoteHistoryItemTypeSubmittedComponent
       extends ConsultNoteHistoryItemTypeBaseComponent
       implements OnInit {

  rolledUpTransitionedCaptureStatusCounts = [];
  rolledUpFailedTransitionedCaptureStatusCounts = [];

  constructor() {
    super();
  }

  ngOnInit() {
    this.buildRolledUpTransitionedCaptureStatusCounts();
    this.buildRolledUpFailedTransitionedCaptureStatusCounts();
  }

  get title() {
    return 'Submitted';
  }

  public displayReasonOrError(reasonOrError) {
    const reason = this.displayReason(reasonOrError);
    return reasonOrError !== '_NO_REASON_' ? reason || reasonOrError : null;
  }

  public displayTransitionedCounts(entry) {
    const values = [
      displayCaptureStatus(entry[0]),
      this.displayReasonOrError(entry[1]),
      entry[2]
    ];

    return values.filter(value => value !== null).join(' - ');
  }

  private displayReason(reason) {
    if (keyedReasonOptions[reason]) {
      return keyedReasonOptions[reason].display;
    }
  }

  private buildRolledUpTransitionedCaptureStatusCounts() {
    const data = this.patientAttachmentHistoryItem.data;
    if (data && data.transitionedCaptureStatusCounts) {
      this.rolledUpTransitionedCaptureStatusCounts =
        this.rollupCaptureStatusCounts(data.transitionedCaptureStatusCounts);
    }
  }

  private buildRolledUpFailedTransitionedCaptureStatusCounts() {
    const data = this.patientAttachmentHistoryItem.data;
    if (data && data.failedTransitionedCaptureStatusCounts) {
      this.rolledUpFailedTransitionedCaptureStatusCounts =
        this.rollupCaptureStatusCounts(data.failedTransitionedCaptureStatusCounts);
    }
  }

  private rollupCaptureStatusCounts(data) {
    const rollup = [];

    data.forEach(([status, reasonWithCounts]) => {
      Object.entries(reasonWithCounts).forEach(([reason, count]) => {
        rollup.push([status, reason, count]);
      });
    });

    return rollup;
  }
}
