import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capture-admin-client-direct-consult-notes',
  templateUrl: './capture-admin-client-direct-consult-notes.component.html',
  styleUrls: ['./capture-admin-client-direct-consult-notes.component.scss'],
})
export class CaptureAdminClientDirectConsultNotesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
