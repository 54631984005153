import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Patient } from '../../../core/models/patient.model';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent implements OnInit {
  @Input() patients: Patient[];

  @Output() public patientSelect = new EventEmitter<Patient>();

  constructor() {}

  ngOnInit() {}

  onPatientClick(patient: Patient) {
    this.patientSelect.emit(patient);
  }
}
