import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-validation-header',
  templateUrl: './validation-header.component.html',
  styleUrls: ['./validation-header.component.scss'],
})
export class ValidationHeaderComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() collapsed: boolean;
  @Input() hintText: string;

  @Output() collapseChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  onToggleCollapseClick($event) {
    $event.preventDefault();
    this.collapseChange.emit(!this.collapsed);
  }
}
