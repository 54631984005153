import { Component, Input, OnInit } from '@angular/core';
import { Office } from '../../../core/models/office.model';
import { ProviderOffice } from '../../../core/models/provider-office.model';

class OfficeDetail {
  constructor(public office: Office, public officeStatus: string, public officeNames: string[]) {}
}

@Component({
  selector: 'app-consult-note-office',
  templateUrl: './consult-note-office.component.html',
  styleUrls: ['./consult-note-office.component.scss'],
})
export class ConsultNoteOfficeComponent implements OnInit {
  @Input() faxedProviderOffice: ProviderOffice;

  officeDetail: OfficeDetail = null;

  constructor() {}

  ngOnInit() {
    if (this.faxedProviderOffice) {
      const officeNames =
        this.faxedProviderOffice.office &&
        this.faxedProviderOffice.office.officeNames;

      this.officeDetail = new OfficeDetail(
        this.faxedProviderOffice.office,
        this.faxedProviderOffice.status,
        officeNames || []
      );
    }
  }
}
