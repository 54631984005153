import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { SpecialistEncounterVerification } from '../models/specialist-encounter-verification.model';
import { AuthenticationService } from './authentication.service';

const buildUrl = (patientAttachmentId: number): string => `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/specialist_encounter_verifications`;

@Injectable()
export class SpecialistEncounterVerificationService extends ApiService {
  constructor(authService: AuthenticationService, private http: HttpClient) {
    super();
  }

  getList(patientAttachmentId: number): Observable<SpecialistEncounterVerification[]> {
    return this.http.get<SpecialistEncounterVerification[]>(buildUrl(patientAttachmentId), {});
  }

  create(
    patientAttachmentId: number,
    verifyingCaptureId: number,
    specialistEncounterVerification: SpecialistEncounterVerification
  ): Observable<SpecialistEncounterVerification> {
    const { encounterDate } = specialistEncounterVerification;
    return this.http.post<SpecialistEncounterVerification>(buildUrl(patientAttachmentId), {
      specialistEncounterVerification: { verifyingCaptureId, encounterDate },
    });
  }

  update(
    patientAttachmentId: number,
    verifyingCaptureId: number,
    specialistEncounterVerification: SpecialistEncounterVerification
  ): Observable<SpecialistEncounterVerification> {
    const { id, encounterDate } = specialistEncounterVerification;
    return this.http.patch<SpecialistEncounterVerification>(`${buildUrl(patientAttachmentId)}/${id}`, {
      specialistEncounterVerification: { verifyingCaptureId, encounterDate },
    });
  }

  destroy(
    patientAttachmentId: number,
    specialistEncounterVerification: SpecialistEncounterVerification
  ): Observable<SpecialistEncounterVerification> {
    const url = `${buildUrl(patientAttachmentId)}/${specialistEncounterVerification.id}`;
    return this.http.delete<SpecialistEncounterVerification>(url, {});
  }
}
