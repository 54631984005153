<div class="review">
  <div>
    <label>Reviewed:</label>
    <mat-slide-toggle
      name="reviewed"
      [checked]="reviewed"
      [color]="'primary'"
      (change)="onReviewChange($event)"
      [disableRipple]="true" />
  </div>
</div>
