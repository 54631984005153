import { Component, Input, OnInit } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { CaptureStatus } from '../../../core/enums/capture-status.enum';
import { CaptureService } from 'app/core/services/capture.service';
import { UserRoleName } from 'app/core/enums/user-role-name.enum';

@Component({
  selector: 'app-capture-header',
  templateUrl: './capture-header.component.html',
  styleUrls: ['./capture-header.component.scss'],
})
export class CaptureHeaderComponent implements OnInit {
  private _capture: Capture;
  private captureChangedSubscription = null;

  canSnapshot = false;
  canReprocess = false;
  canViewHistory = false;
  canUnverify = false;
  canGoToNext = false;
  canViewFax = false;
  canViewPatient = false;
  canViewClient = false;
  showEstimatedValue = false;
  showClientReviewTask = false;
  isHidden = false;

  constructor(
    private authService: AuthenticationService,
    private captureService: CaptureService
  ) {}

  @Input()
  set capture(capture: Capture) {
    this._capture = capture;
    this.isHidden = capture.status === CaptureStatus.needsPatientMatchApproval && this.authService.isCaptureAdminUser;
    this.showClientReviewTask = this.authService.isCaptureAdminUser;
    this.canUnverify = this.authService.isCaptureAdminUser &&
                       this.authService.hasPermissionTo(UserRoleName.editVerifiedCaptures) &&
                       this.capture.unverifiable;
    this.canViewClient = this.authService.isCaptureAdminUser;
  }

  get capture(): Capture {
    return this._capture;
  }

  ngOnInit() {
    this.canSnapshot = this.authService.isCaptureAdminUser;
    this.canViewHistory = this.authService.isCaptureAdminUser;
    this.canViewFax = this.authService.isCaptureAdminUser;
    this.showEstimatedValue = this.authService.isCaptureAdminUser;
    this.canReprocess = this.authService.isCaptureAdminUser;
    this.canGoToNext = this.authService.isCaptureAdminUser;
    this.canViewPatient = this.authService.isCaptureAdminUser;

    this.captureChangedSubscription = this.captureService.captureChanged.subscribe(({ capture }) => {
      if (capture.id === this.capture.id) {
        this.capture = capture;
      }
    });
  }

  onShowClick() {
    this.isHidden = false;
  }
}
