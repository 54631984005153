import { Component, OnInit } from '@angular/core';
import { AuditsService } from 'app/core/services/audits.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { Audit } from 'app/core/models/audit.model';
import { LimitOffsetPaging } from 'app/core/models/paged-results/limit-offset-paging.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-capture-admin-audit',
  templateUrl: './capture-admin-audit.component.html',
  styleUrls: ['./capture-admin-audit.component.scss'],
})
export class CaptureAdminAuditComponent implements OnInit {
  auditableType: string;
  auditableId: number;
  audits: Audit[] = [];
  paging: LimitOffsetPaging;
  loading = true;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private auditsService: AuditsService,
  ) {}

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.loadAudits(this.paging.currentPage);
  }

  onPageChange(page) {
    this.loadAudits(page);
  }

  newValue(changes) {
    return this.prettyChanges(Array.isArray(changes) ? changes[1] : changes);
  }

  previousValue(changes) {
    return this.prettyChanges(Array.isArray(changes) ? changes[0] : "");
  }

  private prettyChanges(changes) {
    if (typeof changes === "object") {
      return JSON.stringify(changes);
    } else {
      return changes;
    }
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.paging.currentPage = 1;
    this.auditableId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.auditableType = this.route.snapshot.paramMap.get('type');

    this.loadAudits(this.paging.currentPage)
  }

  private loadAudits(page) {
    const paging = { page, pageSize: this.paging.pageSize};

    this.auditsService.getList(paging, this.auditableType, [this.auditableId]).subscribe(result => {
      this.paging = result.meta.paging;
      this.audits = result.audits || [];
      this.loading = false;
    });
  }
}
