import { DateTime } from 'luxon';

export class DateUtils {
  static toServerFormat(date: string | Date): string | null {
    if (!date) {
      return null;
    }

    if (typeof(date) === "string" && !this.isValid(date)) {
      return null;
    }

    return DateTime.fromJSDate(new Date(date)).toISODate();
  }

  static isValid(date: string): boolean {
    return !!date && !isNaN(new Date(date).valueOf());
  }

  static formatDateTime(dt: Date): string {
    return dt ? dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString() : '?';
  }
}
