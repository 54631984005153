import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { Patient } from 'app/core/models/patient.model';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-ehr-consult-note-reference-new',
  templateUrl: './ehr-consult-note-reference-new.component.html',
})
export class EhrConsultNoteReferenceNewComponent {
  @Input() patient: Patient;

  @Output() canceled = new EventEmitter<void>();
  @Output() newPatientAttachment = new EventEmitter<PatientAttachment>();

  editing = false;
  ehrConsultNoteReferenceErrors: any;

  constructor(
    private patientAttachmentsService: PatientAttachmentsService
  ) { }

  onCancel() {
    this.canceled.emit();
  }

  onSave(formData: any) {
    this.patientAttachmentsService.createReference(this.patient.id, formData).
      subscribe(
        (patientAttachment: PatientAttachment) => {
          this.newPatientAttachment.emit(patientAttachment);
        },
        (response: HttpErrorResponse) => {
          if (response.status === 400) {
            this.ehrConsultNoteReferenceErrors = response.error.errors.ehrConsultNoteReference;
          } else {
            console.error(response);
          }
        }
      );
  }
}
