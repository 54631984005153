<td>{{ audit.createdAt | date: 'M/d/yyyy h:mm a' }}</td>
<td>
  Updated Provider Office Association
  <div *ngIf="!loadingProviderOffice; else: spinner">
    <span *ngIf="displayNpi">
      NPI: {{ providerOffice.provider.npi }}
      <app-copy-to-clipboard [text]="providerOffice.provider.npi" />
      <a target="_blank" href="/capture-admin/providers/{{ providerOffice.provider.npi }}" *ngIf="providerOffice.provider.npi">
        <fa-icon [icon]="['fas', 'external-link-alt']" size="sm" />
      </a>
    </span>
    <span *ngIf="displayFax">
      Fax: <app-office-fax [office]="providerOffice.office" [allowLinkToOffice]="false" />
    </span>
  </div>
</td>
<td>
  <div class="overflow-auto" style="max-height: 600px;" [innerHTML]="newValueHtml"></div>
</td>
<td>
  <div class="overflow-auto" style="max-height: 600px;" [innerHTML]="previousValueHtml"></div>
</td>
<td>{{ audit.user?.email | blankValueDash }}</td>
<ng-template #spinner> <mat-spinner class="mt-2" [diameter]="30" /> </ng-template>