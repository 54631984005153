import { Prescriber } from './prescriber.model';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';
import { MinimalUser } from 'app/core/models/minimal-user.model';

export class EhrReferralVerification {
  id: number;
  frozen: boolean;
  referralDate: string;
  referralSpecialty: string;
  reasons: ReferralVerificationReason[] = [];
  otherReason: string;
  notes: string;
  createdAt: Date;
  prescriber: Prescriber;
  user: MinimalUser;
}
