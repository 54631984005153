import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../core/models/user.model';
import { UserStatus } from '../../../core/enums/user-status.enum';

@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss'],
})
export class UserStatusComponent implements OnInit {
  userStatuses = UserStatus;

  @Input() user: User;

  constructor() {}

  ngOnInit() {}
}
