import { Component, Input } from '@angular/core';
import { debounce } from 'lodash-es';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientMatchVerification } from '../../../core/models/patient-match-verification.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PatientMatchVerificationsService } from '../../../core/services/patient-match-verifications.service';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { isConsultNoteVerificationFrozen } from '../../../core/lib/verification-utils';
import { VerificationType } from '../../../core/enums/verification-type.enum';

@Component({
  selector: 'app-validate-patient-match-form',
  templateUrl: './validate-patient-match-form.component.html',
  styleUrls: ['./validate-patient-match-form.component.scss'],
})
export class ValidatePatientMatchFormComponent {
  _patientAttachment: PatientAttachment;
  _collapsed: boolean;

  hideAdditionalDetails = false;
  verificationFrozen = false;
  patientMatchVerification: PatientMatchVerification = null;

  debouncedSaveVerification = debounce(() => this.savePatientMatchVerification(), 250, { maxWait: 1000 });

  constructor(
    private patientAttachmentsService: PatientAttachmentsService,
    private patientMatchVerificationService: PatientMatchVerificationsService,
    private authService: AuthenticationService
  ) {}

  get patientAttachment(): PatientAttachment {
    return this._patientAttachment;
  }

  @Input()
  set patientAttachment(pa: PatientAttachment) {
    this._patientAttachment = pa;
    this.initPatientMatchVerification();
  }

  get collapsed(): boolean {
    return this._collapsed;
  }

  @Input()
  set collapsed(val: boolean) {
    this._collapsed = val;
    this.hideAdditionalDetails = this.collapsed && !this.patientMatchVerification.notes;
  }

  onPatientMatchesChange() {
    this.debouncedSaveVerification();
  }

  onAdditionalTextChange(value) {
    this.patientMatchVerification.notes = value;
    this.debouncedSaveVerification();
  }
  private savePatientMatchVerification() {
    if (this.patientMatchVerification.id) {
      this.updatePatientMatchVerification();
    } else {
      this.createPatientMatchVerification();
    }
  }

  private createPatientMatchVerification() {
    this.patientMatchVerificationService
      .create(this.patientAttachment.id, null, this.patientMatchVerification)
      .subscribe((patientMatchVerification: PatientMatchVerification) => {
        this.handleSave(patientMatchVerification);
      });
  }

  private updatePatientMatchVerification() {
    this.patientMatchVerificationService
      .update(this.patientAttachment.id, this.patientMatchVerification.id, null, this.patientMatchVerification)
      .subscribe((patientMatchVerification: PatientMatchVerification) => {
        this.handleSave(patientMatchVerification);
      });
  }

  private handleSave(patientMatchVerification: PatientMatchVerification) {
    const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);
    modifiedPatientAttachment.patientMatchVerification = patientMatchVerification;
    this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
  }

  private initPatientMatchVerification() {
    this.patientMatchVerification = this.patientAttachment.patientMatchVerification || new PatientMatchVerification();
    this.verificationFrozen = isConsultNoteVerificationFrozen(
      this.authService,
      this.patientAttachment,
      VerificationType.patientMatch,
      this.patientMatchVerification
    );
  }
}
