import { Component, Input, OnInit } from '@angular/core';
import { Client } from '../../../core/models/client.model';
import { ClientsService } from '../../../core/services/clients.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-send-direct-test-message',
  templateUrl: './send-direct-test-message.component.html',
  styleUrls: ['./send-direct-test-message.component.scss'],
})
export class SendDirectTestMessageComponent implements OnInit {
  @Input() client: Client;

  sendingMessage = false;
  errorMessage = null;

  constructor(private clientsService: ClientsService) {}

  ngOnInit() {}

  sendMessageClick() {
    this.sendingMessage = true;
    this.errorMessage = null;

    this.clientsService.sendTestDirectMessage(this.client.id).subscribe(
      () => {
        this.sendingMessage = false;
      },
      (err: HttpErrorResponse) => {
        this.sendingMessage = false;
        this.errorMessage = err.error.error;
      }
    );
  }
}
