import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LlmPlaygroundProjectsService } from '../../../core/services/llm-playground-projects.service';
import { Project, CollaboratorPermission } from '../../../core/models/llm-playground.model';

@Component({
  selector: 'app-llm-playground-projects',
  templateUrl: './llm-playground-projects.component.html',
})
export class LlmPlaygroundProjectsComponent implements OnInit {
  @Input() project: Project = new Project('', [], []);

  createTabPath: string;
  indexTabPath: string;
  projects: Project[] = [];
  loadingProjects = false;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public router: Router,
    private llmPlaygroundProjectsService: LlmPlaygroundProjectsService,
  ) {}

  ngOnInit(): void {
    const pathMatch = this.router.url.match(/(.+)(\/new)$/);
    this.titleService.setTitle("LLM Playground");

    if (pathMatch) {
      this.createTabPath = this.router.url;
      this.indexTabPath = pathMatch[1];
    } else {
      this.loadingProjects = true;
      this.loadProjects();
      this.createTabPath = `${this.router.url}/new`;
      this.indexTabPath = this.router.url;
    }
  }

  getCollaboratorsFullNameList(permissions: CollaboratorPermission[]): string {
    if (permissions && permissions.length) {
      return permissions.map(permission => permission.userName).join(", ");
    }
    return '-';
  }

  onSubmit(form: NgForm) {
    return this.llmPlaygroundProjectsService.create(this.project.name).subscribe(
      project => {
        this.router.navigate(['../', project.id], { relativeTo: this.route });
      },
      errorResponse => {
        form.form.setErrors(errorResponse.error);
      }
    );
  }

  private loadProjects() {
    this.llmPlaygroundProjectsService.getList().subscribe(result => {
      this.projects = result;
      this.loadingProjects = false;
    });
  }
}
