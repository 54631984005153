import { Component, Input, OnInit } from '@angular/core';
import { ProviderOffice } from 'app/core/models/provider-office.model';

@Component({
  selector: 'app-provider-office-list-basic',
  templateUrl: './provider-office-list-basic.component.html',
  styleUrls: ['./provider-office-list-basic.component.scss'],
})
export class ProviderOfficeListBasicComponent {
  @Input() providerOffices: ProviderOffice[];
  @Input() loading: boolean;
}
