import { Component, OnInit } from '@angular/core';
import { MeService } from '../../../core/services/me.service';
import { NgForm } from '@angular/forms';
import { supportEmailAddress } from '../../../core/constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  supportEmailAddress = supportEmailAddress;
  email: string = null;
  submitted = false;

  // service endpoint called in MeService.changePassword
  // for viewing emails: install the mailcatcher gem locally and goto http://localhost:1080
  constructor(private meService: MeService) {}

  ngOnInit() {}

  onSubmit(forgotPasswordForm: NgForm) {
    if (forgotPasswordForm.valid) {
      this.meService.resetPassword(this.email).subscribe(resp => {
        this.submitted = true;
      });
    }
  }
}
