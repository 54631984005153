import { Component, Input } from '@angular/core';
import { Report, AppliedReportOptions, AppliedReportOptionAssociations } from 'app/core/models/report.model';

@Component({
  selector: 'app-icc-report-applied-claim-import-option',
  styleUrls: ['./icc-report-applied-claim-import-option.component.scss'],
  templateUrl: './icc-report-applied-claim-import-option.component.html',
})
export class IccReportAppliedClaimImportOptionComponent {
  @Input() name = 'iccClaimImportId';
  @Input() label = 'Claims File';

  @Input() options: AppliedReportOptions;
  @Input() optionAssociations: AppliedReportOptionAssociations;

  get claimImport() {
    const claimImportId = this.options[this.name];

    if (claimImportId) {
      return this.optionAssociations[this.name][claimImportId];
    }
  }
}
