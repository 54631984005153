import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../../core/services/navigation.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LogoutReason } from '../../../core/enums/logout-reason.enum';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { isCePortalConsultantUser } from '../../../core/enums/user-type.enum';
import { MeService } from '../../../core/services/me.service';
import { User } from '../../../core/models/user.model';
import { Client } from '../../../core/models/client.model';
import { VersionService } from '../../../core/services/version.service';
import { VersionUpdateModalComponent } from '../version-update-modal/version-update-modal.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  isConsultant = false;
  showPartnerPortalLink = false;
  showClientPortalLink = false;
  showCaptureAdminLink = false;
  showPreferencesLink = false;
  viewingAsClient: Client = null;
  availableClients: { id: number; name: string; humanizedName: string }[] = [];
  defaultAllowedClientId: number;
  loginSubscription = null;
  logoutSubscription = null;
  startedViewingAsClientSubscription = null;
  stoppedViewingAsClientSubscription = null;
  showVersionAlert = false;
  updateVersionModal: NgbModalRef = null;
  versionAlertSub: Subscription;

  constructor(
    private meService: MeService,
    private authService: AuthenticationService,
    private navigationService: NavigationService,
    private versionService: VersionService,
    private modalService: NgbModal
  ) {
    this.loginSubscription = this.authService.userLogin.subscribe(() => {
      this.refresh();
    });

    this.logoutSubscription = this.authService.userLogout.subscribe(() => {
      this.refresh();
    });

    this.startedViewingAsClientSubscription = this.authService.startedViewingAsClient.subscribe(() => {
      this.refresh();
    });

    this.stoppedViewingAsClientSubscription = this.authService.stoppedViewingAsClient.subscribe(() => {
      this.refresh();
    });
  }

  ngOnInit() {
    this.refresh();
    this.versionAlertSub = this.versionService.versionAlertState$.subscribe(alertState => {
      this.showVersionAlert = alertState;
    });
  }

  ngOnDestroy() {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }

    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }

    if (this.startedViewingAsClientSubscription) {
      this.startedViewingAsClientSubscription.unsubscribe();
    }

    if (this.stoppedViewingAsClientSubscription) {
      this.stoppedViewingAsClientSubscription.unsubscribe();
    }

    if (this.versionAlertSub) {
      this.versionAlertSub.unsubscribe();
    }

    this.refresh();
  }

  onVersionWarningClick($event: Event) {
    $event.preventDefault();
    this.modalService.open(VersionUpdateModalComponent);
  }

  onViewPortalClick($event: Event) {
    $event.preventDefault();
    this.authService.stopViewingAsClient();
    this.navigationService.goToUrl('/capture-admin/client-management/clients');
  }

  onLogoutClick($event: Event) {
    $event.preventDefault();
    this.authService.logout(LogoutReason.userLoggedOut);
  }

  onClientChange() {
    this.meService.updateSelectedClient(this.defaultAllowedClientId).subscribe(() => {
      this.navigationService.navigateHome();
    });
  }

  private refresh() {
    this.isLoggedIn = this.authService.isLoggedIn;
    this.isConsultant = this.determineIsConsultant();
    this.viewingAsClient = this.authService.viewingAsClient;
    this.showPartnerPortalLink = this.isLoggedIn && this.authService.isPartnerUser;
    this.showClientPortalLink = this.isLoggedIn && this.authService.isClientUser;
    this.showCaptureAdminLink = this.isLoggedIn && this.authService.isCaptureAdminUser;
    this.showPreferencesLink = this.isLoggedIn && this.authService.isClientUser;

    if (this.isConsultant) {
      this.meService.get().subscribe((me: User) => {
        this.availableClients = me.availableClients;
        this.defaultAllowedClientId = me.defaultAllowedClientId;
      });
    } else {
      this.availableClients = [];
      this.defaultAllowedClientId = null;
    }
  }

  private determineIsConsultant() {
    return (
      this.isLoggedIn && this.authService.currentUser && isCePortalConsultantUser(this.authService.currentUser.userType)
    );
  }
}
