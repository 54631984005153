import { Component, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { DownloadService } from '../../../core/services/download.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-consult-note-document',
  templateUrl: './consult-note-document.component.html',
  styleUrls: ['./consult-note-document.component.scss'],
})
export class ConsultNoteDocumentComponent implements OnInit {
  _patientAttachment: PatientAttachment;

  @Output() markedAsInvalidChanged = new EventEmitter<PatientAttachment>();

  markingAsInvalid = false;
  markAsInvalidDisabled = false;
  initialPage = null;
  activePage = null;
  usePdfKitViewer: boolean;
  canAdmin: boolean;

  @ViewChild('validatePatientMatch', { static: true })
  validatePatientMatch;

  @ViewChild('validateSpecialistEncounter', { static: true })
  validateSpecialistEncounter;

  @ViewChild('validatePrescriberMatch', { static: true })
  validatePrescriberMatch;

  @ViewChild('validateDrugMatch', { static: true })
  validateDrugMatch;

  pdfFileType: PdfFileType = PdfFileType.patientAttachment;

  constructor(
    private patientAttachmentsService: PatientAttachmentsService,
    private downloadService: DownloadService,
    private authService: AuthenticationService) {}

  get patientAttachment(): PatientAttachment {
    return this._patientAttachment;
  }

  @Input()
  set patientAttachment(pa: PatientAttachment) {
    this._patientAttachment = pa;
    this.initialPage = (pa && pa.medicationsPage) || 1;
    this.activePage = this.initialPage;
    this.markAsInvalidDisabled = this.patientAttachment.hasVerifiedCapture;
  }

  public onPageChange(page) {
    this.activePage = page;
  }

  public onUrlChange(url: string) {
    this.patientAttachment.url = url;
  }

  onExpandAllClick($event) {
    $event.preventDefault();
    this.validatePatientMatch.expand();
    this.validateSpecialistEncounter.expand();
    this.validatePrescriberMatch.expand();
    this.validateDrugMatch.expand();
  }

  onCollapseAllClick($event) {
    $event.preventDefault();
    this.validatePatientMatch.collapse();
    this.validateSpecialistEncounter.collapse();
    this.validatePrescriberMatch.collapse();
    this.validateDrugMatch.collapse();
  }

  onVerificationLinkClick($event, verificationPanelId) {
    $event.preventDefault();
    const questionsWrapperElement = document.getElementById('questionsWrapper');
    const questionsNavbar = document.getElementById('questionsNavbar');
    const verificationElement = document.getElementById(verificationPanelId);

    const offset = questionsNavbar.getBoundingClientRect().height;
    const position = verificationElement.offsetTop - offset;
    questionsWrapperElement.scrollTo({ top: position, behavior: 'smooth' });
    verificationElement.focus();
  }

  onMarkedAsInvalidChanged(pa: PatientAttachment) {
    this.patientAttachment = pa;
  }

  onMarkAsInvalidClick($event) {
    $event.preventDefault();
    this.markingAsInvalid = true;
    const invalid = !this.patientAttachment.markedAsInvalid;

    this.patientAttachmentsService.markAsInvalid(this.patientAttachment.id, invalid, true).subscribe(
      pa => {
        this.markedAsInvalidChanged.emit(pa);
        this.markingAsInvalid = false;
      },
      () => {
        this.markingAsInvalid = false;
      }
    );
  }

  ngOnInit() {
    this.usePdfKitViewer = environment.featureData?.usePdfKitViewer && this.authService.isCaptureAdminUser;
    this.canAdmin = this.authService.isCaptureAdminUser;
  }
}
